import { useSelector } from "react-redux";
import { IEmployee } from "../";
import { useSignUp } from "./useSignUp";
import { useSignIn } from "./useSignIn";
import { useLogout } from "./useLogout";
import { employeeSelector } from "../selectors";
import { useResetPassword } from "./useResetPassword";
import { useChangePassword } from "./useChangePassword";
import { useChangePin } from "./useChangePin"
import { useAuthorizeFromApp } from "./useAuthorizeFromApp";
import { useSetAuthorized } from "./useSetAuthorized";
import { useUpdateFrtWeb } from "./useUpdateFrtWeb";
import { useUpdateFrtMobile } from "./useUpdateFrtMobile";
import { useSellerConfirmEmail } from "./useSellerConfirmEmail";
import { usePartnerConfirmEmail } from "./usePartnerConfirmEmail";
import { useResendOtpCode } from "./useResendOtpCode";
import { useResendConfirmationLink } from "./useResendConfirmationLink";

export const useEmployee = (): IEmployee => {
  const { signUp } = useSignUp();
  const { signIn } = useSignIn();
  const { resendOtpCode } = useResendOtpCode();
  const { resendConfirmationLink } = useResendConfirmationLink()
  const { logout } = useLogout();

  const { resetPassword } = useResetPassword();
  const { changePassword } = useChangePassword();
  const { changePin } = useChangePin();

  const employee = useSelector(employeeSelector);

  const { confirmEmailSeller } = useSellerConfirmEmail();
  const { confirmEmailPartner } = usePartnerConfirmEmail();

  const { authorizeFromApp } = useAuthorizeFromApp();
  const { setAuthorized } = useSetAuthorized();

  const { updateFrtWeb } = useUpdateFrtWeb();
  const { updateFrtMobile } = useUpdateFrtMobile();

  return {
    signUp,
    signIn,
    resendOtpCode,
    resendConfirmationLink,
    logout,
    employee,

    resetPassword,
    changePassword,
    changePin,

    confirmEmailSeller,
    confirmEmailPartner,

    authorizeFromApp,
    setAuthorized,

    updateFrtWeb,
    updateFrtMobile,
  };
};
