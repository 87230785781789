import { Col, Row } from "antd";
import { QuestionOutlined } from "@ant-design/icons";
import { Styled } from "./styles";
import { history } from "data-layer/../router/AppRouter";
import "./style.less";

export const Controls: React.FC = () => {
  return (
    <Row wrap={false}>
      <Col>
        <Styled.Control onClick={() => history.push('/dashboard/support/faq')}>
          <QuestionOutlined />
        </Styled.Control>
      </Col>
    </Row>
  );
};
