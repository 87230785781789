import styled from "styled-components"
import { Fonts } from "styles/fonts"

export const Option = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
`

export const OptionTitle = styled.div`
  font-family: ${Fonts.NexaRegular};
  font-size: 16px;
  line-height: 23px;
  text-transform: uppercase;
  color: #283549;
`

export const OptionDescription = styled.div`
  font-family: ${Fonts.NexaRegular};
  font-size: 16px;
  line-height: 23px;
  color: #283549;
  color: #3C3C4366;
`

export const Styled = {
    Option,
    OptionTitle,
    OptionDescription,
}