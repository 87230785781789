import { Spin } from "antd";
import { usePayouts } from "data-layer/payouts";
import { TableSc } from "scenarios/common";
import { PayoutsParams } from "data-layer/payouts/types/PayoutsParams";
import { Styled } from "./styles";
import { currencySymbolsMap } from "constants/currencies";
import { PayoutDTO } from "generatedCrate";

function formatAmount(amount: number) {
  return `${amount.toFixed(2)}`;
}

const columns = [
  {
    title: "Payout ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: () => <Styled.AmountTitle>Amount</Styled.AmountTitle>,
    key: "amount",
    dataIndex: "amount",
    render: (record: PayoutDTO) => (
      <Styled.AmountText>
        <Styled.AmountTextCurrency>
          {currencySymbolsMap[record.merchant?.currency!]}
        </Styled.AmountTextCurrency>
        <span>{formatAmount(record.amount!)}</span>
      </Styled.AmountText>
    ),
  },
  {
    title: "status",
    dataIndex: "status",
  },
  {
    title: "created",
    dataIndex: "created",
    render: (date: Date) => {
      const dateFormated = new Date(date).toLocaleDateString("en-us", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      const timeFormated = new Date(date).toLocaleTimeString();

      return (
        <span>
          {dateFormated} {timeFormated}
        </span>
      );
    },
  },
  {
    title: "firstDate",
    dataIndex: "firstDate",
    render: (date: Date) => {
      const dateFormated = new Date(date).toLocaleDateString("en-us", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      const timeFormated = new Date(date).toLocaleTimeString();

      return (
        <span>
          {dateFormated} {timeFormated}
        </span>
      );
    },
  },
  {
    title: "lastDate",
    dataIndex: "lastDate",
    render: (date: Date) => {
      const dateFormated = new Date(date).toLocaleDateString("en-us", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      const timeFormated = new Date(date).toLocaleTimeString();

      return (
        <span>
          {dateFormated} {timeFormated}
        </span>
      );
    },
  },
];

type TableProps = {
  filters: PayoutsParams;
};

export const Table: React.FC<TableProps> = (props) => {
  const { filters } = props;
  const { data, isFetching } = usePayouts();

  if ((!data || !data.length) && isFetching) {
    return <Spin />;
  }

  if (!filters) {
    return <Spin />;
  }

  return (
    <TableSc
      data={data}
      filters={filters}
      columns={columns}
      scroll={{ x: 1350 }}
    />
  );
};
