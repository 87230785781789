import { appClient } from "services";
import { IProfileApi } from "./index";

export const api: IProfileApi = {
  getProfile: () => {
    return appClient.profileController.getAllUsingGet();
  },

  createProfile: (body) => {
    return appClient.profileController.createUsingPut(body);
  },

  editProfile: (id, body) => {
    return appClient.profileController.updateUsingPost(id, body);
  },

  selectProfile: (profileId) => {
    return appClient.profileController.selectUsingPost(profileId);
  },

  getPartherProfile: (
    states,
    partnerId,
    limit,
    offset,
    from,
    to,
    companyName?
  ) => {
    return appClient.profileController.getAllPartnerUsingGet(
      states,
      partnerId,
      limit,
      offset,
      from,
      to,
      companyName
    );
  },
};
