/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateDocumentRequestDTO } from '../models/CreateDocumentRequestDTO';
import type { DocumentRequestDTO } from '../models/DocumentRequestDTO';
import type { ReviewDocumentRequestDTO } from '../models/ReviewDocumentRequestDTO';
import type { SendToReviewDocumentRequestDTO } from '../models/SendToReviewDocumentRequestDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { network } from 'services/network';

export class DocumentRequestControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all documents requests for user
     * Get all documents requests for user
     * @returns DocumentRequestDTO
     * @throws ApiError
     */
    public findAssignedUsingGet(): CancelablePromise<Array<DocumentRequestDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/document-request',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Create new document request
     * Creates new document request (available only for compliance officer)
     * @param requestBody
     * @returns DocumentRequestDTO
     * @returns any Created
     * @throws ApiError
     */
    public createUsingPost(
        requestBody?: CreateDocumentRequestDTO,
    ): CancelablePromise<DocumentRequestDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/document-request',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Find by ID
     * Find by ID
     * @param id id
     * @returns DocumentRequestDTO
     * @throws ApiError
     */
    public findByIdUsingGet(
        id: number,
    ): CancelablePromise<DocumentRequestDTO> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/document-request/find/{id}',
                path: {
                    'id': id,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Approve or reject document request
     * Approve or reject document request, depends on 'approved' property in request
     * @param requestBody
     * @returns DocumentRequestDTO
     * @returns any Created
     * @throws ApiError
     */
    public approveOrRejectUsingPost(
        requestBody?: ReviewDocumentRequestDTO,
    ): CancelablePromise<DocumentRequestDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/document-request/review',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Send document request to review
     * Send document request to review
     * @param requestBody
     * @returns DocumentRequestDTO
     * @returns any Created
     * @throws ApiError
     */
    public sendToReviewUsingPost(
        requestBody?: SendToReviewDocumentRequestDTO,
    ): CancelablePromise<DocumentRequestDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/document-request/send-to-review',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Get all document requests for profile
     * Return all document request for specific profile (available only for compliance officer)
     * @param profileId profileId
     * @returns DocumentRequestDTO
     * @throws ApiError
     */
    public findForProfileUsingGet(
        profileId: number,
    ): CancelablePromise<Array<DocumentRequestDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/document-request/{profileId}',
                path: {
                    'profileId': profileId,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

}