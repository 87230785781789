import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserDTO } from "generated";
import { JwtResponse } from "generatedSunset";

export type Employee = null | JwtResponse;

export type EmployeeInitialstate = {
  signInPending: boolean;
  signUpPending: boolean;
  authorized: boolean;
  authorizedFromApp: boolean;
  employee: Employee;
  user: UserDTO | null;
  isNeedOTPCode: boolean;
  isResendingOTPCode: boolean;
  isResendingConfirmationLink: boolean;
};

const initialState: EmployeeInitialstate = {
  signInPending: false,
  signUpPending: false,
  authorized: false,
  authorizedFromApp: false,
  employee: null,
  user: null,
  isNeedOTPCode: false,
  isResendingOTPCode: false,
  isResendingConfirmationLink: false,
};

export const employeeSlice = createSlice({
  name: "employee",
  initialState: initialState,
  reducers: {
    signUpRequest: (state) => {
      state.signUpPending = true;
    },
    signUpSuccess: (state) => {
      state.signUpPending = false;
    },
    signUpFailure: (state) => {
      state.signUpPending = false;
    },

    signInRequest: (state) => {
      state.signInPending = true;
    },
    signInSuccess: (state, action: PayloadAction<JwtResponse>) => {
      state.signInPending = false;
      state.authorized = true;
      state.employee = action.payload;
    },
    signInFailure: (state) => {
      state.signInPending = false;
    },
  
    setIsNeedOTPCode: (state) => {
      state.isNeedOTPCode = true;
    },
    clearIsNeedOTPCode: (state) => {
      state.isNeedOTPCode = false;
    },

    logout: (state) => {
      state.employee = null;
      state.authorized = false;
      state.authorizedFromApp = false;
      state.user = null;
    },

    setAuthorized: (state) => {
      state.authorized = true;
    },

    setAuthorizedFromApp: (state) => {
      state.authorizedFromApp = true;
    },

    userMeRequest: (state) => {
      state.user = null;
    },

    userMeSuccess: (state, action: PayloadAction<UserDTO>) => {
      state.user = action.payload;
    },

    setIsFetchingOTPCode: (state, action: PayloadAction<boolean>) => {
      state.isResendingOTPCode = action.payload
    },
    setIsFetchingConfirmationLink: (state, action: PayloadAction<boolean>) => {
      state.isResendingConfirmationLink = action.payload
    }
  },
});

export const employeeActions = employeeSlice.actions;
export const employeeReducer = employeeSlice.reducer;
