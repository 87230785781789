import styled from "styled-components";
import { Fonts } from "styles/fonts";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 16px;

  border-bottom: 1px solid #00000040;

  @media (max-width: 1919px) {
    width: calc(100% + 78px);
    margin-left: -78px;
    padding-left: 78px;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-left: 0;
    padding: 0 20px;
  }

  @media (max-width: 575px) {
    padding: 0 15px;
  }
`;

const Input = styled.input`
  flex-grow: 1;

  height: 50px;
  padding: 0;

  font-family: ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 100%;

  color: #000;

  background-color: transparent;
  box-shadow: none;
  border: none;
  outline: none;

  @media (max-width: 1450px) {
    height: 40px;
    font-size: 14px;
  }

  ::placeholder {
    font-family: ${Fonts.NexaRegular};
    font-size: 17px;
    line-height: 100%;

    color: #00000040;

    @media (max-width: 1450px) {
      font-size: 14px;
    }
  }
`;

const Button = styled.button`
  flex-shrink: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 50px;
  margin-bottom: 0px;
  margin-left: auto;
  padding: 0;

  font-size: 24px;

  border: none;

  background-color: transparent;
  color: #8096A7;

  cursor: pointer;

  @media (max-width: 1450px) {
    height: 40px;
    font-size: 20px;
  }
`;

export const Styled = {
  Wrapper,
  Input,
  Button,
};
