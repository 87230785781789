import { Styled } from "./styles";
import { ClassNameProp } from "ui/types";

import dapioLogo from "assets/dapio-logo.svg";
import decimalFactorLogo from "assets/decimal-factor-logo.png";
import arivPayLogo from "assets/arivPay-logo.png";
import { usePartner } from "data-layer/partner";
import { sekumpulApiPath } from "services/network/api";

const DECIMAL_FACTOR_ID = "26950";
const ARIV_PAY_ID = "54469";

type SimpleLogoProps = ClassNameProp & {
  partnerId?: string | number | null;
};

export const SimpleLogo: React.FC<SimpleLogoProps> = (props) => {
  const { partnerId } = props;

  const { partnerState } = usePartner();

  const _partnerId = localStorage.getItem("partnerRefId");
  let _logoImageFilename = null;
  try {
    let img = localStorage.getItem("logoImageFilename")
    if (img) {
      _logoImageFilename = JSON.parse(img);
    }
  } catch(e) {}
  

  if (partnerState.invitedPartnerData?.logoImageFilename || _logoImageFilename) {
    return (
      <Styled.BrandedLogo src={`${sekumpulApiPath}/?fileName=${partnerState.invitedPartnerData?.logoImageFilename || _logoImageFilename}`} />
    );
  }

  if (partnerId || _partnerId) {
    if (partnerId == ARIV_PAY_ID || _partnerId == ARIV_PAY_ID) {
      return <Styled.ArivPayLogo src={arivPayLogo} />;
    }

    if (partnerId == DECIMAL_FACTOR_ID || _partnerId == DECIMAL_FACTOR_ID) {
      return <Styled.DCLogo src={decimalFactorLogo} />;
    }
  }

  return <Styled.Logo className={props.className} src={dapioLogo} />;
};
