/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SetUserStatusDTO = {
    userId: number;
    userStatus: SetUserStatusDTO.userStatus;
};

export namespace SetUserStatusDTO {

    export enum userStatus {
        ACTIVATED = 'ACTIVATED',
        FAILED_LOGIN = 'FAILED_LOGIN',
        INVITED = 'INVITED',
        SUSPENDED = 'SUSPENDED',
    }


}
