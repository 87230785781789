import { useEmployees } from "data-layer/employees";
import { EmployeesParams } from "data-layer/employees/types/EmployeesParams";
import { useEffect } from "react";

type UseGetEmployees = (filters: EmployeesParams | null) => void;

export const useGetEmployees: UseGetEmployees = (filters) => {
  const { getEmployees } = useEmployees();

  useEffect(() => {
    if (!filters) {
      return;
    }

    getEmployees(filters);
  }, [filters]);
};
