import { Outlet } from "react-router-dom";
import { HeaderSc } from "scenarios/partner/HeaderSc";
import { FooterSc } from "scenarios/partner"
import { Styled } from "./styles";

export const DashBoardSc = () => {
  return (
    <Styled.Wrapper>
      <HeaderSc />
      <Outlet />
      <FooterSc />
    </Styled.Wrapper>
  );
};
