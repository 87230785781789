import { notification } from "antd";

const style = {
  width: 15,
  height: 15,
  borderRadius: "50%",
  backgroundColor: "#FF9500",
};

export const showWarning = (title: string, description?: string) => {
  notification.open({
    message: title,
    description: description,
    type: "warning",
    icon: <div style={style} />,
    duration: 10,
  });
};
