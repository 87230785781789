import styled from "styled-components";
import { Button } from "antd";
import { Colors } from "styles/colors";

const PrimaryButton = styled(Button)`
  border: none;
  border-color: ${Colors.Primary};
  border-width: 2px;

  background-color: ${Colors.Primary};
  color: #fff;

  &:hover,
  &:focus {
    border-color: ${Colors.PrimaryDark};
    background: ${Colors.PrimaryDark};
    color: #fff;
  }
`;

const PrimaryDotsButton = styled(Button)`
  border: 2px dashed ${Colors.Primary};

  color: ${Colors.Primary};

  &:hover,
  &:focus {
    border-color: #ffb39e;
    background: #ffb39e;
    color: #fff;
  }
`;

const PrimaryTransparentButton = styled(Button)`
  border: 1px solid ${Colors.Primary};

  color: ${Colors.Primary};

  &:hover,
  &:focus {
    background: ${Colors.Primary};
    border-color: ${Colors.Primary};
    color: #fff;
  }
`;

const SecondaryButton = styled(Button)`
  border: none;
  border-color: ${Colors.Secondary};
  border-width: 2px;

  background-color: ${Colors.Secondary};
  color: #fff;

  &:hover,
  &:focus {
    border-color: ${Colors.Secondary};
    background: ${Colors.Secondary};
    color: #fff;
  }
`;

const SecondaryDotsButton = styled(Button)`
  border: 2px dashed ${Colors.Secondary};

  color: ${Colors.Secondary};

  &:hover,
  &:focus {
    border-color: ${Colors.Secondary};
    background: ${Colors.Secondary};
    color: #fff;
  }
`;

const SecondaryTransparentButton = styled(Button)`
  border: 1px solid ${Colors.Secondary};

  color: ${Colors.Secondary};

  &:hover,
  &:focus {
    border-color: ${Colors.Secondary};
    background: ${Colors.Secondary};
    color: #fff;
  }
`;

const GrayTransparentButton = styled(Button)`
  border: 1px solid ${Colors.GrayBlue};

  color: ${Colors.GrayBlue};

  &:hover,
  &:focus {
    border-color: ${Colors.GrayBlue};
    background: ${Colors.GrayBlue};
    color: #fff;
  }
`;

export const Styled = {
  PrimaryButton,
  PrimaryDotsButton,
  PrimaryTransparentButton,

  SecondaryButton,
  SecondaryDotsButton,
  SecondaryTransparentButton,

  GrayTransparentButton,
};
