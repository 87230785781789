import { Row, Col, DatePicker, Radio } from "antd";
import { useCallback, useState } from "react";
import { DateFilters, useChangeDate } from "../../hooks/useChangeDate";
import { Styled } from "./styles";
import { useToggle } from "common/useToggle";
import { PayoutsParams } from "data-layer/payouts/types/PayoutsParams";
import moment from "moment";

type FiltersProps = {
  filters: PayoutsParams;
};

export const Filters: React.FC<FiltersProps> = (props) => {
  const { filters } = props;

  const [value, setValue] = useState(null);

  const { active, handleToggle } = useToggle();

  const { handleChangeDate, handleChangeDatePicker: _handleChangeDatePicker } = useChangeDate();

  const handleChangeDateGroup = useCallback((e) => {
    handleChangeDate(e);
    setValue(e.target.value);
  }, [handleChangeDate, setValue]);

  const handleChangeDatePicker = useCallback((e) => {
    _handleChangeDatePicker(e)
    setValue(null);
  }, [_handleChangeDatePicker, setValue])

  return (
    <div>
      <Styled.HeaderMobile onClick={handleToggle} active={active}>Show Filters</Styled.HeaderMobile>
      <Styled.Content active={active}>
        <Row gutter={[0, {xs: 25, sm: 25, lg: 42 }]}>
          <Col span={24}>
            <Styled.Title>Show for</Styled.Title>
            <Styled.FiltersRadioGroup>
              <Radio.Group
                onChange={handleChangeDateGroup}
                size={"small"}
                value={value}
              >
                <Row>
                  <Col span={24}>
                    <Radio.Button value={DateFilters.Today}>
                      <Styled.ListItem>Today</Styled.ListItem>
                    </Radio.Button>
                  </Col>
                  <Col span={24}>
                    <Radio.Button value={DateFilters.This_week}>
                      <Styled.ListItem>This week</Styled.ListItem>
                    </Radio.Button>
                  </Col>
                  <Col span={24}>
                    <Radio.Button value={DateFilters.This_month}>
                      <Styled.ListItem>This month</Styled.ListItem>
                    </Radio.Button>
                  </Col>
                  <Col span={24}>
                    <Radio.Button value={DateFilters.Last_7_days}>
                      <Styled.ListItem>Last 7 days</Styled.ListItem>
                    </Radio.Button>
                  </Col>
                  <Col span={24}>
                    <Radio.Button value={DateFilters.Last_30_days}>
                      <Styled.ListItem>Last 30 days</Styled.ListItem>
                    </Radio.Button>
                  </Col>
                </Row>
              </Radio.Group>
            </Styled.FiltersRadioGroup>
          </Col>

          <Col span={24}>
            <Styled.Period>
              <Styled.Title>Period</Styled.Title>

              <Styled.FiltersDatepicker selected={filters.from && filters.to ? true : false}>
                <DatePicker.RangePicker
                  size={"small"}
                  onChange={handleChangeDatePicker}
                  format={"DD.MM.YY"}
                  value={[
                    moment(filters.from, moment.ISO_8601),
                    moment(filters.to, moment.ISO_8601),
                  ]}
                  defaultValue={[
                    moment(filters.from, moment.ISO_8601),
                    moment(filters.to, moment.ISO_8601),
                  ]}
                  clearIcon={false}
                />
              </Styled.FiltersDatepicker>
            </Styled.Period>
          </Col>
        </Row>
      </Styled.Content>
    </div>
  );
};
