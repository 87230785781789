/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EmployeePermissionEntity } from './EmployeePermissionEntity';
import type { MerchantEntity } from './MerchantEntity';
import type { UserEntity } from './UserEntity';

export type EmployeeEntity = {
    address?: string;
    cctExternalId?: number;
    cctTerminalExternalId?: number;
    city?: string;
    created?: string;
    fullName?: string;
    id?: number;
    merchant?: MerchantEntity;
    modified?: string;
    originType?: EmployeeEntity.originType;
    permissions?: Array<EmployeePermissionEntity>;
    postalCode?: string;
    status?: EmployeeEntity.status;
    tid?: string;
    user?: UserEntity;
    vacStoreProfileId?: string;
    vacStoreProfileName?: string;
    version?: number;
};

export namespace EmployeeEntity {

    export enum originType {
        EXTERNAL = 'EXTERNAL',
        INTERNAL = 'INTERNAL',
    }

    export enum status {
        ACTIVATED = 'ACTIVATED',
        EXPORTED = 'EXPORTED',
        EXTERNAL_SYSTEM_NOT_READY = 'EXTERNAL_SYSTEM_NOT_READY',
        NEW = 'NEW',
        SUSPENDED = 'SUSPENDED',
        ZERO_TRANSACTION = 'ZERO_TRANSACTION',
    }


}
