import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { resendOtpCodeThunk } from "data-layer/employee/thunks";

type UseResendOtpCode = () => {
  resendOtpCode: (email: string) => void;
};

export const useResendOtpCode: UseResendOtpCode = () => {
  const dispatch = useDispatch();

  const resendOtpCode = useCallback(
    (email: string) => {
      dispatch(resendOtpCodeThunk(email));
    },
    [dispatch]
  );

  return {
    resendOtpCode,
  };
};
