import * as Yup from "yup";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { Styled } from "./styles";
import { PasswordChangeForm } from "./PasswordChangeForm";
import { PasswordChangeConfirm } from "./PasswordChangeConfirm";
import { PageLogo } from "ui";
import { Role } from "data-layer/common/testRoleSlice";

export interface Values {
  password: string;
  password_repeated: string;
}

export const PasswordChangeSchema = Yup.object().shape({
  password: Yup.string()
    .required("Required")
    .min(12, "Password should be 12 chars minimum."),
  password_repeated: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const PasswordChangeByEmployeeSchema = Yup.object().shape({
  password: Yup.string()
    .required()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(4, "Must be exactly 4 digits")
    .max(4, "Must be exactly 4 digits"),
  password_repeated: Yup.string()
    .required()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(4, "Must be exactly 4 digits")
    .max(4, "Must be exactly 4 digits"),
});

export const initialValues = {
  password: "",
  password_repeated: "",
};

const PasswordChangeWrapper: React.FC<{ role: Role }> = (props) => {
  return (
    <Styled.Wrapper>
      <Styled.Background />
      <Styled.Content>
        <Styled.ContentContainer>
          <Link to="/dashboard">
            <PageLogo role={props.role} />
          </Link>

          <Outlet />
        </Styled.ContentContainer>
      </Styled.Content>
    </Styled.Wrapper>
  );
};

export const PasswordChangeSc = {
  Wrapper: PasswordChangeWrapper,
  Form: PasswordChangeForm,
  Confirm: PasswordChangeConfirm,
};
