/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CurrencyEntity } from './CurrencyEntity';
import type { EmployeeEntity } from './EmployeeEntity';
import type { PayoutEntity } from './PayoutEntity';
import type { TransactionCardData } from './TransactionCardData';
import type { TransactionGeoData } from './TransactionGeoData';

export type TransactionEntity = {
    amount?: number;
    authorizationCode?: string;
    cardData?: TransactionCardData;
    created?: string;
    currency?: CurrencyEntity;
    dateTime?: string;
    decimalAmount?: number;
    employee?: EmployeeEntity;
    externalId?: string;
    externalStatusCode?: string;
    geoData?: TransactionGeoData;
    id?: number;
    modified?: string;
    payout?: PayoutEntity;
    provider?: TransactionEntity.provider;
    rrn?: string;
    sourceExternalPaymentId?: string;
    status?: TransactionEntity.status;
    timeZone?: string;
    type?: TransactionEntity.type;
    version?: number;
};

export namespace TransactionEntity {

    export enum provider {
        CCT_TTP_CREDORAX = 'CCT_TTP_CREDORAX',
        CREDORAX_PBL = 'CREDORAX_PBL',
    }

    export enum status {
        CANCELLED = 'CANCELLED',
        FAILED = 'FAILED',
        SUCCESS = 'SUCCESS',
    }

    export enum type {
        CANCEL = 'CANCEL',
        CHARGEBACK = 'CHARGEBACK',
        MANUAL = 'MANUAL',
        PARTIAL_REFUND = 'PARTIAL_REFUND',
        PAYMENT = 'PAYMENT',
        REFUND = 'REFUND',
    }


}
