import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 100vh;

  padding-top: 80px;

  @media (max-width: 1450px) {
    padding-top: 60px;
  }
`;

export const Styled = {
  Wrapper,
};
