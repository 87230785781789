import { api } from "./api";
import { CancelablePromise } from "generated/core/CancelablePromise";
import { DocumentRequestDTO, SendToReviewDocumentRequestDTO } from "generated";

export interface IDocumentRequestApi {
  sendToReview: (
    body: SendToReviewDocumentRequestDTO
  ) => CancelablePromise<DocumentRequestDTO | any>;
  getDocumentsByProfileToRequest: (
    profileId: number
  ) => CancelablePromise<Array<DocumentRequestDTO>>;
  getDocumentRequestById: (id: number) => CancelablePromise<DocumentRequestDTO>;
}

class DocumentRequestApi {
  api: IDocumentRequestApi = api;

  constructor() {
    if (process.env.REACT_APP_MOCK_ENABLED === "true") {
      this.api = api;
      return;
    }

    this.api = api;
  }
}

export const documentRequestApi = new DocumentRequestApi();
