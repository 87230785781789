import { CancelError } from "generated";
import { useCallback } from "react";
import { сompanyApi } from "../api";

type UseSearch = () => {
  searchCompany: (name: string, number: string) => void;
};

export const useSearch: UseSearch = () => {
  const searchCompany = useCallback(async (name, number) => {
    try {
      const result = await сompanyApi.api.search(name, number);
      return result;
    } catch (e) {
    } finally {
    }
  }, []);

  return {
    searchCompany,
  };
};
