import { Col, Row } from "antd";
import { Styled } from "./styles";
import { QuestionOutlined, UploadOutlined } from "@ant-design/icons";
import { useEmployee } from "data-layer/employee";
import { SimpleLogo } from "ui";

export const Header: React.FC = () => {
  const { logout, employee } = useEmployee();

  return (
    <Styled.Header>
      <div className="container">
        <Row
          justify="space-between"
          align="middle"
          gutter={{ xs: 10, sm: 10, lg: 20 }}
        >
          <Col>
            <SimpleLogo partnerId={employee?.user?.inviterPartnerId} />
          </Col>

          <Col>
            <Row gutter={[{ xs: 10, sm: 10, xxl: 15 }, 0]} wrap={false}>
              <Col>
                <Styled.Control
                  // onClick={() => {
                    // window.open(`/support/faq`);
                  // }}
                >
                  <QuestionOutlined />
                </Styled.Control>
              </Col>

              <Col>
                <Styled.Control onClick={logout}>
                  <UploadOutlined style={{ transform: "rotate(90deg)" }} />
                </Styled.Control>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Styled.Header>
  );
};
