import { Formik, Form } from "formik";
import { Row, Col, Spin } from "antd";
import { Input } from "formik-antd";
import { Button, FormItem } from "components";
import { Styled } from "./styles";
import { LoginRequest } from "generatedSunset";
import {
  Values,
  SigninSchema,
  SigninSchemaWithOptCode,
  initialValues,
} from "./LoginSc";
import { history } from "data-layer/../router/AppRouter";
import { Typography, PageLogo } from "ui";
import { Role } from "data-layer/common/testRoleSlice";
import { useEmployee } from "data-layer/employee";
import { OTPInput } from "components/OTPInput";
import "./styles.css";
import bgSvg from "./bg.svg";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from "react";

type LoginScViewProps = {
  onSubmit: (data: LoginRequest) => void;
};

export const LoginScView: React.FC<LoginScViewProps> = (props) => {
  const { employee, resendOtpCode } = useEmployee();
  const { onSubmit } = props;

  const recaptchaRef = useRef(null);

  const handleResendOTPCode = (email?: string) => {
    if (!email) {
      return;
    }

    resendOtpCode(email);
  };

  return (
    <Styled.Wrapper>
      <Styled.Background>
        <Styled.BackgroundSvg src={bgSvg} />
      </Styled.Background>
      <Styled.Content>
        <Styled.ContentContainer>
          <PageLogo role={Role.Merchant} />
          <Typography.H1>Sign in</Typography.H1>

          <Formik
            initialValues={initialValues}
            validationSchema={
              employee.isNeedOTPCode ? SigninSchemaWithOptCode : SigninSchema
            }
            onSubmit={async (values: Values) => {
              const recaptchaResponse = await recaptchaRef?.current?.executeAsync();
  
              onSubmit({
                ...values,
                recaptchaResponse
              });

              recaptchaRef?.current?.reset();
            }}
            validateOnChange={true}
            validateOnBlur={true}
          >
            {({ handleSubmit, errors, values, setFieldValue }) => (
              <Form
                onSubmit={handleSubmit}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
              >
                <Row gutter={[0, { xl: 5 }]}>
                  <Col span={24}>
                    <FormItem label="Email address" error={errors.email}>
                      <Input name="email" placeholder="Email" />
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <FormItem
                      label="Password"
                      topAction={
                        <Styled.ForgotPassword
                          onClick={() => history.push("/password-recovery")}
                        >
                          Reset password
                        </Styled.ForgotPassword>
                      }
                      error={errors.password}
                    >
                      <Input.Password name="password" type="password" />
                    </FormItem>
                  </Col>
                  {employee.isNeedOTPCode && (
                    <Col span={24}>
                      <Styled.OTPTitle>Enter OTP code</Styled.OTPTitle>
                      <Styled.OTPDescription>
                        An 6 digit code has been sent to{" "}
                        <span>{values.email}</span>
                      </Styled.OTPDescription>
                      <Styled.OTPInputWrapper>
                        <FormItem
                          label={null}
                          error={errors.otpCode}
                          showError={false}
                        >
                          <Row justify="end">
                            <Col span={24}>
                              <OTPInput
                                value={values.otpCode || ""}
                                onChange={(value) => {
                                  setFieldValue("otpCode", value);
                                }}
                                numInputs={6}
                                separator={null}
                                containerStyle="OTPContainer"
                                inputStyle="OTPCol"
                              />
                            </Col>
                            <Col span={24}>
                              <Row justify="end">
                                <Col>
                                  <Styled.ResendOTP
                                    onClick={() =>
                                      handleResendOTPCode(values.email)
                                    }
                                    disabled={employee.isResendingOTPCode}
                                  >
                                    Resend code
                                    {employee.isResendingOTPCode && <Spin />}
                                  </Styled.ResendOTP>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </FormItem>
                      </Styled.OTPInputWrapper>
                    </Col>
                  )}
                </Row>

                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
                />

                <Styled.SubmitButton isNeedOTPCode={employee.isNeedOTPCode}>
                  <Button
                    type="primary-transparent"
                    block
                    onClick={() => handleSubmit()}
                  >
                    Point of Sale Sign in
                  </Button>
                </Styled.SubmitButton>

                <Styled.SubmitButton isNeedOTPCode={employee.isNeedOTPCode}>
                  <Button
                      type="primary-transparent"
                      block
                      onClick={() => {
                        window.open(
                            'https://ecom.dapio.com/',
                            '_blank'
                        );
                      }}
                  >
                    E-commerce Sign in
                  </Button>
                </Styled.SubmitButton>

                <Styled.Footer>
                  <p>
                    New to Dapio?{" "}
                    <span onClick={() => history.push("/signup")}>Sign up</span>
                    .
                  </p>
                  <p>
                    Trouble signing in? Contact{" "}
                    <a href="mailto:help@dapio.com">help@dapio.com</a>.
                  </p>
                </Styled.Footer>
              </Form>
            )}
          </Formik>
        </Styled.ContentContainer>
      </Styled.Content>
    </Styled.Wrapper>
  );
};
