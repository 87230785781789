/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReportDTO } from '../models/ReportDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { network } from 'services/network';

export class ReportControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Report by filter (Admin)
     * Get Report by filter (Admin)
     * @param partnerId partnerId
     * @param reportSummaryGroupBy reportSummaryGroupBy
     * @param from from
     * @param to to
     * @returns ReportDTO
     * @throws ApiError
     */
    public getReportByFilterUsingGet(
        partnerId?: number,
        reportSummaryGroupBy?: 'EMPLOYEE' | 'MERCHANT' | 'PARTNER',
        from?: string,
        to?: string,
    ): CancelablePromise<Array<ReportDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/report',
                query: {
                    'partnerId': partnerId,
                    'reportSummaryGroupBy': reportSummaryGroupBy,
                    'from': from,
                    'to': to,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

}