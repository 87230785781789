export function formatTime(ms: number): string {
  // 1- Convert to seconds:
  let seconds = ms / 1000;

  if (seconds < 60) {
    return "Now";
  }

  // 3- Extract minutes:
  const minutes = parseInt((String(seconds / 60))); // 60 seconds in 1 minute

  if (minutes < 60) {
    return `${minutes} min ago`;
  }

  // 2- Extract hours:
  const hours = parseInt(String(seconds / 3600)); // 3,600 seconds in 1 hour
  if (hours < 24) {
    return `${hours} hours ago`;
  }

  const days = parseInt(String(seconds / (3600 * 24)));

  return `${days} days ago`;
}
