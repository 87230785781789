import { useSelector } from "react-redux";
import { IDictionary } from "../";
import { useGetDictionary } from "./useGetDictionary";
import {
  dictionariesSelector,
  businessTypesDictionarySelector,
  countriesDictionarySelector,
  sizesDictionarySelector,
} from "../selectors";

export const useDictionary = (): IDictionary => {
  const { getDictionary } = useGetDictionary();
  const dictionaries = useSelector(dictionariesSelector);
  const businessTypes = useSelector(businessTypesDictionarySelector);
  const countries = useSelector(countriesDictionarySelector);
  const sizes = useSelector(sizesDictionarySelector);

  return {
    getDictionary,
    dictionaries,
    businessTypes,
    countries,
    sizes,
  };
};
