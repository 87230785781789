import { useMerchantImport } from "data-layer/merchantImport";
import { ParsedResult } from "data-layer/merchantImport/types/ParsedResult";
import { FlatApplicationDTO } from "generated";
import { useCallback } from "react";

type UseSubmit = () => {
  handleSubmit: (
    data: FlatApplicationDTO[],
    onParseResult: (data: ParsedResult) => void
  ) => void;
};

export const useSubmit: UseSubmit = () => {
  const { importFile } = useMerchantImport();

  const handleSubmit = useCallback((data, onParseResult) => {
    importFile({ input: data }, onParseResult);
  }, []);

  return {
    handleSubmit,
  };
};
