import { useCallback, useState } from "react";

type UseToggle = (_active?: boolean) => {
  active: boolean;
  handleToggle: () => void;
  handleClose: () => void;
};

export const useToggle: UseToggle = (_active = false) => {
  const [active, setActive] = useState(_active);

  const handleToggle = useCallback(() => {
    setActive(!active);
  }, [setActive, active]);

  const handleClose = useCallback(() => {
    setActive(false);
  }, [setActive]);

  return {
    active,
    handleToggle,
    handleClose,
  };
};
