import { BusinessTypeDTO } from "generated";

type TypeInfo = {
  id: string;
  text: string;
};

type RootTypeInfo = TypeInfo & {
  subCategories: TypeInfo[] | [];
};

export type FormattedBusinessType = RootTypeInfo[] | [];

const mapBusinessType: { [key: string]: string } = {
  RETAIL: "Retail",
  FOOD_DRINKS_HOSPITALITY: "Food, Drinks & Hospitality",
  HEALTH_BEAUTY: "Health & Beauty",
  SERVICES: "Services",
  LEISURE_ENTERTAINMENT: "Leisure & Entertainment",
  NON_PROFIT_ORGANISATIONS: "Non Profit Organisations",
};

function formatString(str: string) {
  if (!str.length) {
    return "";
  }

  if (mapBusinessType[str]) {
    return mapBusinessType[str];
  }

  const formatedText = str.toLowerCase().split("_").join(" ");
  const resultText = formatedText[0].toUpperCase() + formatedText.slice(1);

  return resultText;
}

export const formatBusinessType = (
  businessType: BusinessTypeDTO[] | []
): FormattedBusinessType | [] => {
  if (!businessType?.length) {
    return [];
  }

  let result: FormattedBusinessType = [];

  businessType.forEach((item) => {
    result.push({
      id: item.id!,
      text: formatString(item.id!),

      subCategories: (item.subCategories || []).map(
        (item: string): TypeInfo => {
          return {
            id: item,
            text: formatString(item),
          };
        }
      ),
    });
  });

  return result;
};
