import * as Yup from "yup";

const digitsOnly = (value: string) => /^\d+$/.test(value);
const digitsAndLettersOnly = (value: string) => /^[A-Z0-9]+$/.test(value);
const holderNameCheck = (value: string) => /^[A-Za-z. ]+$/.test(value);

export const settlementDetailsSchema = Yup.object().shape({
  bankAccountHolderName: Yup.string()
      .required("Required")
      .test("Holder name check", "The field should have digits, letters and symbols '.', ' '", holderNameCheck),
  // todo
  // Сорткоды это 000000, 6 цифр максимум
  sortCode: Yup.string()
    .required("Required")
    .min(6)
    .max(11)
    .test("Digits and Letters only", "The field should have digits and Capital letters only", digitsAndLettersOnly),
    //.test("len", "Must be exactly 6 digits", (val) => val?.length === 6),

  // Аккаунт - Account number must be 8 digits in length
  bankAccountNumber: Yup.string()
    .required("Required")
    .min(8)
    .max(34)
    .test("Digits and Letters only", "The field should have digits and Capital letters only", digitsAndLettersOnly),
    //.test("Field length", "Must be 8 digits", (val) => val?.length === 8),
});

// export const settlementPartnerDetailsSchema = Yup.object()
//   .shape({
//     iban: Yup.string().required("Required"),
//   })
//   .concat(settlementDetailsSchema);
