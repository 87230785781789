export { useNotifications } from "./hooks";
export type { UseNotifications } from "./types";
export { notificationsReducer } from "./reducer";

export const whiteListToShow = [
  // "INIT_APPLICATION",
  "APPROVE_APPLICATION",
  // "UPDATE_APPLICATION",
  "REJECT_APPLICATION",
  // "SENT_TO_REVIEW_APPLICATION",

  // "SIGN_IN",
  // "SIGN_UP",
  // "RESET_PASSWORD_REQUEST",
  // "RESET_PASSWORD",

  "CREATED_DOCUMENT_REQUEST",
  // "SENT_TO_REVIEW_DOCUMENT_REQUEST",
  // "APPROVED_DOCUMENT_REQUEST",
  "REJECTED_DOCUMENT_REQUEST",

  "EMPLOYEE_CREATED",
  "EMPLOYEE_EXPORTED",
  // "EMPLOYEE_DEVICE_ACTIVATED",
  // "EMPLOYEE_OPERATOR_PIN_CHANGED",

  // "MERCHANT_CREATED",

  // "PARTNER_INVITED_USER",

  // "PAYOUT_CREATED",
  // "PAYOUT_APPROVED",
  "PAYOUT_IN_PROGRESS",
  "PAYOUT_PAID",
  "PAYOUT_REJECTED",
  "PAYOUT_CANCELLED",

  // "PROOF_PERSON_REQUESTED",
  // "PROOF_PERSON_UPLOADED",
  // "PROOF_PERSON_APPROVED",
  // "PROOF_PERSON_REJECTED",
];

export const whiteListToBell = [
  "APPROVE_APPLICATION",
  "REJECT_APPLICATION",

  // "CREATED_DOCUMENT_REQUEST",
  // "REJECTED_DOCUMENT_REQUEST",

  "PAYOUT_IN_PROGRESS",
  "PAYOUT_PAID",
];
