import { Paper } from "components";
import { Styled } from "./styles";
import { useToggle } from "../../../../../common/useToggle";
import { history } from "data-layer/../router/AppRouter";
import { Col, Row } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

type HelpProps = {
  onlyActive?: boolean;
};

export const Help: React.FC<HelpProps> = (props) => {
  const { active, handleToggle } = useToggle();

  const goToSupportPage = () => {
    history.push("/dashboard/support/faq");
  };

  return (
    <Paper style={{ height: "100%" }}>
      <Styled.Title
        onClick={handleToggle}
        active={true}
        onlyActive={props.onlyActive}
      >
        Hi. How can we help?
      </Styled.Title>
      <Styled.Content active={true}>
        <Styled.Description>How to get started with Dapio</Styled.Description>

        <Styled.List>
          <li>
            <Row wrap={false} justify="space-between" align="middle">
              <Col>
                <Styled.ListItemTitle>
                  Go to our help center
                </Styled.ListItemTitle>
              </Col>
              <Col>
                <Styled.ListItemIcon onClick={goToSupportPage}>
                  <ArrowRightOutlined />
                </Styled.ListItemIcon>
              </Col>
            </Row>
          </li>
          <li>
            <Row wrap={false} justify="space-between" align="middle">
              <Col>
                <Styled.ListItemTitle>Contact our support</Styled.ListItemTitle>
              </Col>
              <Col>
                <a href="mailto:help@dapio.com">
                  <Styled.ListItemIcon>
                    <ArrowRightOutlined />
                  </Styled.ListItemIcon>
                </a>
              </Col>
            </Row>
          </li>
          <li>
            <Row wrap={false} justify="space-between" align="middle">
              <Col>
                <Styled.ListItemTitle>Join our community</Styled.ListItemTitle>
              </Col>
              <Col>
                <a
                  href="https://discordapp.com/channels/938814250477047831/939137553490141275"
                  target="_blank"
                >
                  <Styled.ListItemIcon>
                    <ArrowRightOutlined />
                  </Styled.ListItemIcon>
                </a>
              </Col>
            </Row>
          </li>
        </Styled.List>
      </Styled.Content>
    </Paper>
  );
};
