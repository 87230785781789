import React, { useMemo } from "react";
import { Select, Tabs } from "antd";
import { Styled } from "./styled";
import moment from "moment";
import { useChangeDate } from "../../useChangeDate";

interface IPeriod {
  from?: string;
  to?: string;
}

export const Period: React.FC<IPeriod> = ({ from, to }) => {
  const { TabPane } = Tabs;
  const { Option } = Select;

  const { handleChangeDatePicker } = useChangeDate();

  const overall = [
    moment(20190603).toISOString(),
    moment().toISOString(),
  ];
  const thisWeek = [
    moment().startOf("week").toISOString(),
    moment().endOf("week").toISOString(),
  ];
  const lastWeek = [
    moment().subtract(1, "week").toISOString(),
    moment().toISOString(),
  ];
  const thisMonth = [
    moment().startOf("month").toISOString(),
    moment().endOf("month").toISOString(),
  ];
  const lastMonth = [
    moment().subtract(1, "month").toISOString(),
    moment().toISOString(),
  ];
  const lastYear = [
    moment().subtract(1, "year").toISOString(),
    moment().toISOString(),
  ];

  const periods: {
    [key: string]: string[];
  } = {
    overall,
    thisWeek,
    lastWeek,
    thisMonth,
    lastMonth,
    lastYear,
  };

  const getCurrentPeriodTab = (from?: string, to?: string) => {
    let result = "";

    for (const date in periods) {
      if (
        moment(from).isSame(periods[date][0], "date") &&
        moment(to).isSame(periods[date][1], "date")
      ) {
        result = date;
      }
    }

    return result;
  };

  const currentPeriodTab = useMemo(
    () => getCurrentPeriodTab(from, to),
    [from, to]
  );

  return (
    <Styled.Wrapper>
      {/* <Styled.LargeGrid> */}
        <Tabs
          activeKey={currentPeriodTab}
          tabPosition="top"
          className="button-tabs revenue-tabs"
          style={{ margin: "0 auto" }}
          onChange={(key) => handleChangeDatePicker(periods[key])}
        >
          <TabPane tab="Overall" key="overall"></TabPane>
          <TabPane tab="This week" key="thisWeek"></TabPane>
          <TabPane tab="Last week" key="lastWeek"></TabPane>
          <TabPane tab="This month" key="thisMonth"></TabPane>
          <TabPane tab="Last month" key="lastMonth"></TabPane>
          <TabPane tab="Last year" key="lastYear"></TabPane>
        </Tabs>
      {/* </Styled.LargeGrid> */}
      {/* <Styled.MobileGrid>
        <Select
          defaultValue="thisWeek"
          value={currentPeriodTab}
          onSelect={(value: string) => handleChangeDatePicker(periods[value])}
        >
          <Option value="overall">Overall</Option>
          <Option value="thisWeek">This week</Option>
          <Option value="lastWeek">Last week</Option>
          <Option value="thisMonth">This month</Option>
          <Option value="lastMonth">Last month</Option>
          <Option value="lastYear">Last year</Option>
        </Select>
      </Styled.MobileGrid> */}
    </Styled.Wrapper>
  );
};
