import { BusinessTypes } from "common/onboarding/schema";
import { useApplication } from "data-layer/application";
import {
  TypeOfBusinessCompanyValues,
  TypeOfBusinessSoloTraderValues,
  TypeOfBusinessValues,
} from "data-layer/application/types";
import { useProfile } from "data-layer/profile";
import { useCompanyProfile } from "./useCompanyProfile";
import { usePersist } from "./usePersist";
import { useSoloTraderProfile } from "./useSoloTraderProfile";
import {
  excludeCompanyValues,
  excludeSoloTraderValues,
  isTheSameProfileKind,
} from "./utils";

type UseSubmit = () => {
  handleSubmit: (values: TypeOfBusinessValues, nextStep: VoidFunction) => void;
};

export const useSubmit: UseSubmit = () => {
  const { selectedProfile } = useProfile();
  const { persist } = usePersist();
  const { newApplication, applications } = useApplication();
  const { createCompanyProfile, editCompanyProfile } = useCompanyProfile();
  const { createSoloTraderProfile, editSoloTraderProfile } =
    useSoloTraderProfile();

  const handleSubmit = (
    values: TypeOfBusinessValues,
    nextStep: VoidFunction
  ) => {
    // Создаем профиль если:
    // 1. нет профиля (новый аккаунт)
    // 2. перешли на страницу по кнопке "Start registration"
    // 3. если есть профиль и у него есть applications и оно не текущее - wtf???
    if (
      !selectedProfile ||
      (applications.length && !newApplication)
      // isNewProfile ||
    ) {
      createNewProfile(values, nextStep);
      return;
    }

    // Редактируем профиль если:
    // 1. на текущем поменяли тип
    if (!isTheSameProfileKind(selectedProfile, values)) {
      // createNewProfile(values, nextStep);
      editNewProfile(values, nextStep);
      return;
    }

    persist(values, nextStep);
  };

  const createNewProfile = (
    values: TypeOfBusinessValues,
    nextStep: VoidFunction
  ) => {
    switch (values.typeOfBusiness) {
      case BusinessTypes.Company: {
        const data = excludeCompanyValues(
          values as TypeOfBusinessCompanyValues
        );
        createCompanyProfile(data, nextStep);
        break;
      }

      case BusinessTypes.SoleTrader: {
        const data = excludeSoloTraderValues(
          values as TypeOfBusinessSoloTraderValues
        );
        createSoloTraderProfile(data, nextStep);
        break;
      }

      default: {
      }
    }
  };

  const editNewProfile = (
    values: TypeOfBusinessValues,
    nextStep: VoidFunction
  ) => {
    switch (values.typeOfBusiness) {
      case BusinessTypes.Company: {
        const data = excludeCompanyValues(
          values as TypeOfBusinessCompanyValues
        );
        editCompanyProfile(data, nextStep);
        break;
      }

      case BusinessTypes.SoleTrader: {
        const data = excludeSoloTraderValues(
          values as TypeOfBusinessSoloTraderValues
        );
        editSoloTraderProfile(data, nextStep);
        break;
      }

      default: {
      }
    }
  };

  return {
    handleSubmit,
  };
};
