import { Row } from "antd";
import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

const Wrapper = styled(Row)`
  border-bottom: 1px solid #bbb;
  padding: 0 15px;
`;

const Field = styled.div`
  position: relative;

  display: flex;
  align-items: center;

  width: 100%;
  height: 50px;

  font-family: ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 22px;

  color: #4d4d4d;
`;

const FieldText = styled.div`
  position: absolute;

  top: 50%;
  left: 0;

  width: calc(100%);
  padding-right: 44px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  transform: translateY(-50%) translateY(2px);
`;

const RemoveButton = styled.button`
  position: relative;

  width: 20px;
  height: 20px;

  border: none;
  border-radius: 50%;

  background-color: #bbbbbb;

  cursor: pointer;

  @media (max-width: 1199px) {
    width: 20px;
    height: 20px;
  }

  &:hover {
    background-color: ${Colors.Secondary};
  }

  &::after,
  &::before {
    content: "";

    position: absolute;

    top: 50%;
    left: 50%;

    width: 12px;
    height: 1px;

    background-color: #fff;

    @media (max-width: 1199px) {
      width: 12px;
    }
  }

  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

export const Styled = {
  Wrapper,
  Field,
  FieldText,
  RemoveButton,
};
