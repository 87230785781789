import { NotificationType } from "data-layer/notifications/getNotificationData";
import styled from "styled-components";
import { Fonts } from "styles/fonts";

type TypeProp = {
  type: NotificationType;
};

type ReadedProp = {
  readed: boolean;
};

const Wrapper = styled.div<TypeProp & ReadedProp>`
  position: relative;

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  min-height: 156px;
  padding: 18px 20px;

  border-bottom: 1px solid #0000001a;

  background: ${props => props.readed ? "#ffffff" : "#f2f2f2"};

  @media (max-width: 575px) {
    min-height: 146px;
    padding: 18px 36px;
  }
`;

const Icon = styled.span<TypeProp>`
  position: absolute;
  top: 18px;
  left: 20px;

  width: 15px;
  height: 15px;

  border-radius: 50%;

  background: ${(props) => {
    switch (props.type) {
      case NotificationType.Default: {
        return "#e5e5e5";
      }
      case NotificationType.Warning: {
        return "#FF9500";
      }
      case NotificationType.Success: {
        return "#30D158";
      }
      case NotificationType.Error: {
        return "#FF3B30";
      }
      default: {
      }
    }

    return "#e5e5e5";
  }};

  @media (max-width: 575px) {
    left: 36px;
  }
`;

const Content = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  width: 100%;

  padding-left: 25px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 15px;
`;

const Title = styled.p`
  max-width: 300px;
  margin-bottom: 0;

  font-family: ${Fonts.NexaRegular};
  font-size: 16px;
  line-height: 16px;

  color: #08243b;
`;

const Time = styled.p`
  flex-shrink: 0;

  margin-bottom: 0;
  margin-left: 15px;

  font-family: ${Fonts.NexaRegular};
  font-size: 14px;
  line-height: 14px;

  color: #08243b;

  color: #3c3c4366;
`;

const Description = styled.p`
  max-width: 300px;

  margin-bottom: 0;

  font-family: ${Fonts.NexaRegular};
  font-size: 16px;
  line-height: 23px;

  color: #8096a7;

  & + button {
    margin-top: 15px;
  }

  @media (max-width: 575px) {
    font-size: 14px;
    line-height: 20pxx;
  }
`;

const Action = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 150px;
  height: 30px;

  font-family: "Nexa Regular";
  font-size: 16px;
  line-height: 30px;

  border: 1px solid #fe8e75;
  border-radius: 25px;

  background: #ffffff;
  color: #fe8e75;

  cursor: pointer;
`;

export const Styled = {
  Wrapper,

  Icon,

  Content,
  Header,
  Title,
  Time,

  Description,

  Action,
};
