import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

const Wrapper = styled.div`
  max-width: 649px;
  margin: 0 auto;
  padding: 130px 0 50px;

  @media (max-width: 1450px) {
    padding: 100px 0 50px;
  }
`;

const Title = styled.p`
  margin-bottom: 30px;

  font-family: ${Fonts.NexaRegular};
  font-size: 25px;
  line-height: 100%;
  text-align: center;

  color: ${Colors.Secondary};
`;

const Content = styled.div`
  padding: 20px;
  border-radius: 8px;
  background: #F6F6F699;
`;

const ContentTitle = styled.p`
  margin-bottom: 15px;

  font-family: ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 23px;
  text-align: center;

  color: ${Colors.Primary};
`;

const ContentDescription = styled.p`
  margin-bottom: 15px;

  font-family: ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 25px;
  text-align: center;

  color: #000000d9;
`;

const FilesTitle = styled.p`
  margin: 0 auto 5px;

  font-family: ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 20px;

  color: ${Colors.Primary};
`

export const Styled = {
  Wrapper,
  Title,
  Content,
  ContentTitle,
  ContentDescription,
  FilesTitle,
};
