import { ParseImportFileResultDTO } from "generated";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { parseFileThunk } from "../thunks";

type UseParseFile = () => {
  parseFile: (
    formData: { files?: Array<Blob>; token?: string },
    format: string,
    onParse: (data: ParseImportFileResultDTO) => void
  ) => void;
};

export const useParseFile: UseParseFile = () => {
  const dispatch = useDispatch();

  const parseFile = useCallback(
    (formData, format, onParse) => {
      dispatch(parseFileThunk(formData, format, onParse));
    },
    [dispatch]
  );

  return {
    parseFile,
  };
};
