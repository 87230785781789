import { Col, Row } from "antd";
import { Button, Paper } from "components";
import { Styled } from "./styles";
import { Tabs } from "antd";
import { useToggle } from "common/useToggle";
import { history } from "data-layer/../router/AppRouter";
const { TabPane } = Tabs;

export const Employees = () => {
  const { active, handleToggle } = useToggle();

  return (
    <Paper style={{ height: "100%" }}>
      <Styled.HeaderRow justify="space-between" align="middle">
        <Styled.TitleCol>
          <Styled.Title onClick={handleToggle} active={active}>
            Employees
          </Styled.Title>
        </Styled.TitleCol>
        <Styled.ButtonCol sm={{ flex: 1 }}>
          <Button
            type="primary-transparent"
            size="small"
            block
            style={{ maxWidth: 129 }}
            onClick={() => {
              history.push("/dashboard/employees");
            }}
          >
            Create
          </Button>
        </Styled.ButtonCol>
      </Styled.HeaderRow>

      <Styled.Content active={active}>
        <Tabs
          defaultActiveKey="1"
          tabPosition="bottom"
          className="button-tabs button-tabs--bottom"
          style={{ margin: "0 auto" }}
        >
          <TabPane tab="Amount" key="1">
            <Styled.List>
              <li>
                <Row wrap={false} justify="space-between">
                  <Col>Alexander Valley</Col>
                  <Col>£3,460.12</Col>
                </Row>
              </li>
              <li>
                <Row wrap={false} justify="space-between">
                  <Col>Bob Anderson</Col>
                  <Col>£1,123.99</Col>
                </Row>
              </li>
              <li>
                <Row wrap={false} justify="space-between">
                  <Col>Atlas Peak</Col>
                  <Col>£886.12</Col>
                </Row>
              </li>
              <li>
                <Row wrap={false} justify="space-between">
                  <Col>Alice Merton</Col>
                  <Col>£300.00</Col>
                </Row>
              </li>
              <li>
                <Row wrap={false} justify="space-between">
                  <Col>Bill Clinton</Col>
                  <Col>£32.12</Col>
                </Row>
              </li>
            </Styled.List>
          </TabPane>
          <TabPane tab="Quantity" key="2">
            <Styled.List>
              <li>
                <Row wrap={false} justify="space-between">
                  <Col>Alexander Valley</Col>
                  <Col>£3,460.12</Col>
                </Row>
              </li>
              <li>
                <Row wrap={false} justify="space-between">
                  <Col>Bob Anderson</Col>
                  <Col>£1,123.99</Col>
                </Row>
              </li>
              <li>
                <Row wrap={false} justify="space-between">
                  <Col>Atlas Peak</Col>
                  <Col>£886.12</Col>
                </Row>
              </li>
              <li>
                <Row wrap={false} justify="space-between">
                  <Col>Alice Merton</Col>
                  <Col>£300.00</Col>
                </Row>
              </li>
              <li>
                <Row wrap={false} justify="space-between">
                  <Col>Bill Clinton</Col>
                  <Col>£32.12</Col>
                </Row>
              </li>
            </Styled.List>
          </TabPane>
        </Tabs>
      </Styled.Content>
    </Paper>
  );
};
