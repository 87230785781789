import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "../../../../../styles/fonts";

const Wrapper = styled.div`
  @media (max-width: 1550px) {
    max-width: 377px;
  }
`

const MainTitle = styled.h2`
  font-family: ${Fonts.NexaRegular};
  font-size: 25px;
  line-height: 160%;
  margin-bottom: 14px;

  @media (max-width: 1550px) {
    font-size: 20px;
    line-height: 25px;
  }
`;

const ActivityItemWrapper = styled.div`
  padding: 16px;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  font-family: ${Fonts.NexaRegular};
`;

const ActivityItemTitle = styled.h2`
  margin-bottom: 0;

  font-family: ${Fonts.NexaLight};
  font-size: 50px;
  line-height: 100%;
  letter-spacing: 0px;

  color: #fe8e75;

  @media (max-width: 1550px) {
    font-size: 30px;
  }
`;

const ActivityItemLabel = styled.p`
  margin-bottom: 12px;

  font-size: 20px;
  line-height: 25px;

  color: #283549;

  @media (max-width: 1550px) {
    font-size: 17px;
    line-height: 23px;
  }
`;

const StatisticCountWrapper = styled.div`
  display: inline-block;

  height: 20px;
  margin-right: 5px;
  padding: 0 16px;

  border: 1px solid #E5E5E5;
  border-radius: 10px;
`;

const StatisticCountText = styled.span`
  font-family: ${Fonts.NexaRegular};
  font-size: 13px;
  line-height: 150%;
  letter-spacing: 0px;

  color: ${Colors.Green};

  @media (max-width: 1550px) {
    font-size: 12px;
  }
`;

const StatisticDateText = styled.span`
  letter-spacing: 0px;
  color: #80868b;
`;

const List = styled.div`
  padding-left: 20px;

  @media (max-width: 767px) {
    padding-left: 0;
  }
`

export const Styled = {
  Wrapper,
  ActivityItemWrapper,
  ActivityItemTitle,
  StatisticCountWrapper,
  StatisticCountText,
  ActivityItemLabel,
  StatisticDateText,
  MainTitle,
  List,
};
