import { CancelablePromise, CreateEmployeeDTO, ToggleEmployeePermissionDTO } from "generated";
import { EmployeeDTO } from "generated/models/EmployeeDTO";
import { api } from "./api";

export interface IEmployeesApi {
  getEmployees: (limit: number, offset: number) => CancelablePromise<Array<EmployeeDTO>>;
  createEmployee: (body: CreateEmployeeDTO) => CancelablePromise<EmployeeDTO | any>;
  togglePermission: (body: ToggleEmployeePermissionDTO) => CancelablePromise<EmployeeDTO | any>;
}

class EmployeesApi {
  api: IEmployeesApi = api;

  constructor() {
    if (process.env.REACT_APP_MOCK_ENABLED === "true") {
      this.api = api;
      return;
    }

    this.api = api;
  }
}

export const employeesApi = new EmployeesApi();
