import { Button, Modal, FormItem } from "components";
import { Styled } from "./styles";
import { LockOutlined } from "@ant-design/icons";
import { useToggle } from "common/useToggle";
import { Formik, FormikValues } from "formik";
import { Input } from "formik-antd";
import * as Yup from "yup";
import "./styles.css";
import { getFormikError } from "utils/getFormikError";

type ConfirmPasswordProps = {
  values?: FormikValues;
  handleSubmit: (password: string, cb: VoidFunction) => void;
  render: ({ handleToggle }: { handleToggle: VoidFunction }) => JSX.Element;
};

export const attemptSchema = Yup.object().shape({
  password: Yup.string().required("Required"),
});

export type Values = {
  password: string;
};

const initialValues: Values = {
  password: "",
};

export const ConfirmPasswordSc: React.FC<ConfirmPasswordProps> = (props) => {
  const { handleSubmit } = props;
  const { active, handleToggle } = useToggle();

  return (
    <>
      <Modal
        visible={active}
        onCancel={handleToggle}
        footer={null}
        className="confirm-password-modal"
      >
        <Styled.Wrapper>
          <Styled.Icon>
            <Styled.Svg>
              <LockOutlined />
            </Styled.Svg>
          </Styled.Icon>
          <Styled.Title>Confirm password</Styled.Title>
          <Formik
            initialValues={initialValues}
            validationSchema={attemptSchema}
            validateOnChange={true}
            validateOnMount={true}
            enableReinitialize
            onSubmit={(values) => {
              handleSubmit(values.password, handleToggle);
            }}
          >
            {({ handleSubmit, errors, touched, isValid }) => (
              <>
                <FormItem
                  label="Password"
                  error={getFormikError("password", errors, touched)}
                >
                  <Input.Password name="password" type="password" />
                </FormItem>
                <Styled.Button>
                  <Button
                    type="primary"
                    block
                    onClick={() => handleSubmit()}
                    disabled={!isValid}
                  >
                    Confirm
                  </Button>
                </Styled.Button>
              </>
            )}
          </Formik>
        </Styled.Wrapper>
      </Modal>

      {props.render({ handleToggle })}
    </>
  );
};
