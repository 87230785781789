import { Row } from "antd";
import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

const AddEmployeeButton = styled.div`
  width: 100%;
  max-width: 377px;

  @media (max-width: 767px) {
    max-width: 100%;
  }
`

const Title = styled.div`
  font-family: ${Fonts.NexaRegular};
  font-size: 25px;
  line-height: 16px;
  text-align: center;

  color: ${Colors.Primary};

  @media (max-width: 575px) {
    font-size: 20px;
  }
`;

const Icon = styled.div`
  position: relative;

  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 65px;
  height: 65px;

  margin: 0 auto 21px;

  font-size: 25px;

  border-radius: 15px;

  background: #fff;

  box-shadow: 0px 3px 3px #0000001a;

  @media (max-width: 575px) {
    width: 55px;
    height: 55px;
    margin-bottom: 20px;
  }

  &::before {
    z-index: -1;

    content: "";

    position: absolute;

    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;

    background-color: #0000000d;

    border-radius: 10px;
  }
`;

const PermissionsRow = styled(Row)`
  margin-bottom: 65px;

  padding-top: 10px;

  @media (max-width: 575px) {
    margin-bottom: 45px;

    padding-top: 0;
  }
`

const CheckboxGroup = styled.div`
  width: 100%;

  .ant-checkbox-group {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 -15px;
    padding-top: 5px;
  }

  .ant-checkbox-wrapper {
    margin: 0 15px;
  }
`;


export const Styled = {
  AddEmployeeButton,
  Title,
  Icon,
  PermissionsRow,
  CheckboxGroup,
};
