import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { TransactionRestDTO } from "generated";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

type UseHandleChangeType = () => {
  handleChangeType: ((checkedValue: CheckboxValueType[]) => void) | undefined;
};

export const useHandleChangeType: UseHandleChangeType = () => {
  const [searchParams, setSearchParams] = useSearchParams({});

  const handleChangeType = useCallback(
    (value) => {
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        offset: "0",
        types:
          value.join(",") ||
          [
            TransactionRestDTO.type.CANCEL,
            TransactionRestDTO.type.CHARGEBACK,
            TransactionRestDTO.type.MANUAL,
            TransactionRestDTO.type.PARTIAL_REFUND,
            TransactionRestDTO.type.PAYMENT,
            TransactionRestDTO.type.REFUND,
          ].join(","),
      });
    },
    [searchParams, setSearchParams]
  );

  return { handleChangeType };
};
