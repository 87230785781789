import { SettlementDetailsAttemptUpdateDTO } from "generated";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { attemptSettlementsThunk } from "../thunks";

type UseAttemptSettlements = () => {
  attemptSettlements: (
    body: SettlementDetailsAttemptUpdateDTO,
    cb?: VoidFunction
  ) => void;
};

export const useAttemptSettlements: UseAttemptSettlements = () => {
  const dispatch = useDispatch();

  const attemptSettlements = useCallback(
    (body, cb) => {
      dispatch(attemptSettlementsThunk(body, cb));
    },
    [dispatch]
  );

  return {
    attemptSettlements,
  };
};
