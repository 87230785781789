import { SendToReviewDocumentRequestDTO } from "generated";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { sendToReviewThunk } from "../thunks";

type UseSendToReview = () => {
  sendToReview: (
    body: SendToReviewDocumentRequestDTO,
    cb?: VoidFunction
  ) => void;
};

export const useSendToReview: UseSendToReview = () => {
  const dispatch = useDispatch();

  const sendToReview = useCallback(
    (body, cb) => {
      dispatch(sendToReviewThunk(body, cb));
    },
    [dispatch]
  );

  return {
    sendToReview,
  };
};
