/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TerminalDTO } from '../models/TerminalDTO';
import type { TerminalStatusRequest } from '../models/TerminalStatusRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { network } from 'services/network';

export class TerminalControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Activate terminal
     * Activate terminal
     * @param serialNumber serialNumber
     * @param vacDeviceId vacDeviceId
     * @returns TerminalDTO
     * @returns any Created
     * @throws ApiError
     */
    public activateTerminalUsingPost(
        serialNumber: string,
        vacDeviceId?: string,
    ): CancelablePromise<TerminalDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/terminals/activate',
                query: {
                    'serialNumber': serialNumber,
                    'vacDeviceId': vacDeviceId,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Find terminals (Admin)
     * Find terminals (Admin)
     * @param limit limit
     * @param offset offset
     * @param tid tid
     * @param statuses statuses
     * @param serialNumber serialNumber
     * @param cctTid cctTid
     * @returns TerminalDTO
     * @throws ApiError
     */
    public findByAsAdminUsingGet(
        limit: number,
        offset: number,
        tid?: string,
        statuses?: 'ACTIVATED' | 'EXPORTED' | 'EXTERNAL_SYSTEM_NOT_READY' | 'NEW' | 'SUSPENDED' | 'ZERO_TRANSACTION',
        serialNumber?: string,
        cctTid?: string,
    ): CancelablePromise<Array<TerminalDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/terminals/admin',
                query: {
                    'tid': tid,
                    'statuses': statuses,
                    'serialNumber': serialNumber,
                    'cctTid': cctTid,
                    'limit': limit,
                    'offset': offset,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Find by SN for current user
     * Find by SN for current user
     * @param sn sn
     * @returns TerminalDTO
     * @throws ApiError
     */
    public findTerminalBySnUsingGet(
        sn?: string,
    ): CancelablePromise<TerminalDTO> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/terminals/find-by-sn',
                query: {
                    'sn': sn,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Find terminal and update by SN
     * Find's terminal by SN, checks from external service and updates its status
     * @param requestBody
     * @returns TerminalDTO
     * @returns any Created
     * @throws ApiError
     */
    public findOrCreateTerminalBySnUsingPost(
        requestBody?: TerminalStatusRequest,
    ): CancelablePromise<TerminalDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/terminals/find-or-create-by-sn',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Update terminal info
     * Update terminal info
     * @param requestBody
     * @returns TerminalDTO
     * @returns any Created
     * @throws ApiError
     */
    public updateTerminalAsAdminUsingPost(
        requestBody?: TerminalDTO,
    ): CancelablePromise<TerminalDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/terminals/update/admin',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

}