import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

const Icon = styled.div`
  position: relative;

  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 65px;
  height: 65px;

  margin: 0 auto 21px;

  font-size: 25px;

  border-radius: 15px;

  background: #fff;

  box-shadow: 0px 3px 3px #0000001a;

  @media (max-width: 1199px) {
    width: 55px;
    height: 55px;
    margin-bottom: 20px;
  }

  &::before {
    z-index: -1;

    content: "";

    position: absolute;

    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;

    background-color: #0000000d;

    border-radius: 10px;
  }
`;

const Svg = styled.div`
  font-size: 25px;

  color: #283549;
`;

const Title = styled.p`
  margin-bottom: 40px;

  font-family: ${Fonts.NexaRegular};
  font-size: 25px;
  line-height: 100%;

  text-align: center;

  color: ${Colors.Primary};

  @media (max-width: 1199px) {
    margin-bottom: 25px;
    font-size: 20px;
  }
`;

const Scroll = styled.div`
  max-height: 600px;
  overflow-y: auto;
`

const CheckboxGroup = styled.div`
  .ant-radio-group,
  .ant-checkbox-group {
    width: 100%;
  }

  .ant-radio-wrapper,
  .ant-checkbox-wrapper {
    flex-direction: row-reverse;

    width: 100%;

    padding: 12px 20px 15px 16px;

    border-bottom: 1px solid #e5e5e5;

    color: #283549;

    @media (max-width: 1199px) {
      padding: 9px 20px 8px 16px;
    }
  }

  .ant-checkbox-group .ant-checkbox-wrapper {
    margin-bottom: 0;
  }

  span.ant-radio + *,
  span.ant-checkbox + * {
    flex: 1;
    padding-left: 0;
  }
`;

const Label = styled.div`
  width: 100%;

  padding-bottom: 5px;
  padding-left: 16px;

  font-family: ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 23px;

  border-bottom: 1px solid #e5e5e5;

  color: ${Colors.GrayBlue};

  @media (max-width: 1199px) {
    padding-bottom: 3px;
    font-size: 14px;
    line-height: 20px;
  }
`

export const Styled = {
  Icon,
  Svg,
  Title,

  Scroll,

  CheckboxGroup,
  Label,
};
