import { useTransactions } from "data-layer/transaction";
import { TransactionParams } from "data-layer/transaction/types/TransactionParams";
import { TransactionRestDTO } from "generated";
import moment from "moment";
import { useEffect } from "react";

type UseGetTransactions = () => void;

export const useGetTransactions: UseGetTransactions = () => {
  const { getTransactions } = useTransactions();

  useEffect(() => {
    const params: TransactionParams = {
      from: moment().subtract(1, "year").toISOString(),
      to: moment().toISOString(),
      types: [
        TransactionRestDTO.type.CANCEL,
        TransactionRestDTO.type.CHARGEBACK,
        TransactionRestDTO.type.MANUAL,
        TransactionRestDTO.type.PARTIAL_REFUND,
        TransactionRestDTO.type.PAYMENT,
        TransactionRestDTO.type.REFUND,
      ],
      limit: 4,
      offset: 0,
    };

    getTransactions(params);
  }, []);
};
