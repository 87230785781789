import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaymentLinkDTO } from "generated";

export type Payments = PaymentLinkDTO[] | [];

export type PaymentsInitialState = {
  data: Payments;
  isFetching: boolean;
};

const initialState: PaymentsInitialState = {
  data: [],
  isFetching: false,
};

export const paymentsSlice = createSlice({
  name: "payments",
  initialState: initialState,
  reducers: {
    getPaymentsSuccess: (
      state,
      action: PayloadAction<Array<PaymentLinkDTO>>
    ) => {
      state.data = action.payload;
      state.isFetching = false;
    },

    createPaymentLinkSuccess: (state, action: PayloadAction<PaymentLinkDTO>) => {
      state.data.push(action.payload)
    }
  },
});

export const paymentsActions = paymentsSlice.actions;
