import { useInitNotifications } from "./useInitNotifications";
import { useCheckEmployee } from "./useCheckEmployee";
import { useCheckAuthToken } from "./useCheckAuthToken";
import { UserRole } from "data-layer/employee";

type InitComponentScProps = {
  roleAccess: UserRole;
};

export const InitComponentSc: React.FC<InitComponentScProps> = (props) => {
  const { roleAccess } = props;
  const { isAuthTokenChecked, authToken } = useCheckAuthToken();
  const { isCacheChecked } = useCheckEmployee(authToken, roleAccess);

  useInitNotifications(isCacheChecked, isAuthTokenChecked);

  if (!isCacheChecked || !isAuthTokenChecked) {
    return null;
  }

  return <div className="App">{props.children}</div>;
};
