/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdatePayoutsStatusRequest = {
    ids?: Array<number>;
    status?: UpdatePayoutsStatusRequest.status;
};

export namespace UpdatePayoutsStatusRequest {

    export enum status {
        APPROVED = 'APPROVED',
        CANCELLED = 'CANCELLED',
        IN_PROGRESS = 'IN_PROGRESS',
        NEW = 'NEW',
        PAID = 'PAID',
        REJECTED = 'REJECTED',
    }


}
