import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

type UseSearchByAmount = () => {
  handleSearch: (amount: string) => void;
};

export const useSearchByAmount: UseSearchByAmount = () => {
  const [searchParams, setSearchParams] = useSearchParams({});

  const handleSearch = useCallback(
    (amount = "") => {
      if (!amount) {
        searchParams.delete("amount");
        setSearchParams({
          ...Object.fromEntries([...searchParams]),
        });
        return;
      }

      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        amount,
      });
    },
    [searchParams, setSearchParams]
  );

  return {
    handleSearch,
  };
};
