import { useCallback } from "react";
import { showError } from "utils/showError";
import { sekumpulApi } from "data-layer/sekumpul/api";
import { handleNetworkError } from "utils/handleNetworkError";

type UseHandleFile = (
  setLoading: (value: boolean) => void,
  setFile: (file: string) => void,
) => {
  handleFile: (e: any) => void;
};

export const useHandleFile: UseHandleFile = (setLoading, setFile) => {
  const handleFile = useCallback(
    async (e) => {
      const file = e.target.files[0];
      const type = file.type;
      const fileSize = file.size / 1024 / 1024; // in MiB

      if (fileSize > 20) {
        showError("Load file error", "File must be less than 2mb");
        return;
      }

      setLoading(true);

      try {
        const result = await sekumpulApi.api.upload({
          file: file,
        });
        setFile(result);
      } catch (e: unknown) {
        handleNetworkError(e, "Load file error");
      } finally {
        setLoading(false);
      }
    },
    [setLoading]
  );

  return {
    handleFile,
  };
};
