/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AttachmentDTO } from './AttachmentDTO';

export type DocumentRequestDTO = {
    attachments: Array<AttachmentDTO>;
    created?: string;
    id: number;
    message: string;
    state: DocumentRequestDTO.state;
};

export namespace DocumentRequestDTO {

    export enum state {
        APPROVED = 'APPROVED',
        NEW = 'NEW',
        REJECTED = 'REJECTED',
        REVIEWING = 'REVIEWING',
    }


}
