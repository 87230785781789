import { useTransactions } from "data-layer/transaction";
import { TransactionParams } from "data-layer/transaction/types/TransactionParams";
import { useEffect } from "react";

type UseGetTransactions = (filters: TransactionParams | null) => void;

export const useGetTransactions: UseGetTransactions = (filters) => {
  const { getTransactions } = useTransactions();

  useEffect(() => {
    if (!filters) {
      return;
    }

    getTransactions(filters);
  }, [filters]);
};
