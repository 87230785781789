import React from "react";
import { BusinessActivitesStep } from "./components/BusinessActivitiesStep";
import { BusinessOwnersStep } from "./components/BusinessOwnersStep";
import { CompanyDescriptionStep } from "./components/DescriptionStep";
import { CompanyStepProps, StepProps } from "./OnboardingSc";
import { ReviewCompanyStep } from "./components/ReviewStep/ReviewStepCompany";
import { IdentityVerificationCompanyStep } from "./components/IdentityVerificationStep";

type ContentProps = CompanyStepProps &
  StepProps & {
    current: number;
    changeStep: (index: number) => void;
  };

const Content: React.FC<ContentProps> = (props) => {
  const { initData, prevStep, nextStep, changeStep, current } = props;

  return (
    <>
      {current === 1 && (
        <CompanyDescriptionStep
          initData={initData}
          prevStep={prevStep}
          nextStep={nextStep}
        />
      )}
      {current === 2 && (
        <BusinessActivitesStep
          initData={initData}
          prevStep={prevStep}
          nextStep={nextStep}
        />
      )}
      {current === 3 && (
        <BusinessOwnersStep
          initData={initData}
          prevStep={prevStep}
          nextStep={nextStep}
        />
      )}
      {current === 4 && (
        <IdentityVerificationCompanyStep
          initData={initData}
          prevStep={prevStep}
          nextStep={nextStep}
        />
      )}
      {current === 5 && (
        <ReviewCompanyStep
          initData={initData}
          changeStep={changeStep}
          prevStep={prevStep}
          nextStep={nextStep}
        />
      )}
    </>
  );
};

export const OnboardingCompanySteps = {
  Content: Content,
};
