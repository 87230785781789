import { Formik, FieldArray } from "formik";
import { Col, Row } from "antd";
import { businessOwnersSchema } from "common/onboarding/schema";
import { Styled as OnboardingStyled } from "../../onboardingStyles";
import { AppInputs } from "generated/modelsWrapper/AppInputs";
import { CompanyStepProps, StepProps } from "../../OnboardingSc";
import { BusinessOwnersView } from "./BusinessOwnersView";
import { Button, Modal } from "components";
import { initialOwnerData } from "./mock";
import { useState } from "react";
import { BusinessOwnersEdit } from "./BusinessOwnersEdit";
import { usePersist } from "../../usePersist";
import { BusinessOwnerValues } from "data-layer/application/types";

export const BusinessOwnersStep: React.FC<CompanyStepProps & StepProps> = (
  props
) => {
  const { initData, nextStep, prevStep } = props;
  const [selectedOwner, setSelectedOwner] = useState<number | null>(null);
  const [isCreatingOwner, setIsCreatingOwner] = useState(false);
  const [initedEditViewValues, setInitedEditViewValues] =
    useState<BusinessOwnerValues | null>(null);

  const { persist } = usePersist();

  return (
    <div>
      <Formik
        initialValues={initData}
        validationSchema={businessOwnersSchema}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize
        onSubmit={(values) => {
          persist(values, nextStep);
        }}
      >
        {({ values, errors, validateForm, handleSubmit, setFieldValue }) => {
          return (
            <FieldArray
              name={AppInputs.PERSONS}
              render={(arrayHelpers) => {
                return (
                  <>
                    <OnboardingStyled.Title>
                      Add business owners and officers
                    </OnboardingStyled.Title>

                    {(values.PERSONS || []).map((owner, index) => {
                      return (
                        <BusinessOwnersView
                          owner={owner}
                          selectOwner={() => {
                            setInitedEditViewValues({
                              ...owner,
                            });
                            setSelectedOwner(index);
                          }}
                          removeOwner={() => arrayHelpers.remove(index)}
                          errors={
                            errors?.PERSONS ? errors.PERSONS[index] : undefined
                          }
                        />
                      );
                    })}

                    <Row>
                      <Col span={24} style={{ marginBottom: 10 }}>
                        <Button
                          type="secondary-transparent"
                          block
                          onClick={() => {
                            setIsCreatingOwner(true);
                            setSelectedOwner(values.PERSONS?.length || 0);
                            arrayHelpers.push({ ...initialOwnerData });
                          }}
                        >
                          Add person
                        </Button>
                      </Col>

                      <Col span={24}>
                        <OnboardingStyled.Footer>
                          <Row gutter={{ xs: 10, sm: 10, md: 25 }}>
                            <Col>
                              <Button
                                type="secondary-transparent"
                                onClick={() => prevStep()}
                              >
                                Back
                              </Button>
                            </Col>

                            <Col flex={1}>
                              <Button
                                type="primary-transparent"
                                block
                                onClick={() => handleSubmit()}
                                disabled={
                                  !values.PERSONS || values.PERSONS.length < 1
                                }
                              >
                                Next
                              </Button>
                            </Col>
                          </Row>
                        </OnboardingStyled.Footer>
                      </Col>
                    </Row>

                    <Modal
                      visible={selectedOwner !== null}
                      onCancel={() => {
                        if (isCreatingOwner) {
                          arrayHelpers.remove(selectedOwner!);
                        } else {
                          arrayHelpers.replace(
                            selectedOwner!,
                            initedEditViewValues
                          );
                        }

                        setSelectedOwner(null);
                        setIsCreatingOwner(false);
                      }}
                      footer={null}
                    >
                      {selectedOwner !== null && (
                        <BusinessOwnersEdit
                          isCreating={isCreatingOwner}
                          owner={values.PERSONS[selectedOwner!]}
                          nameBase={`${AppInputs.PERSONS}[${selectedOwner}].`}
                          errors={errors}
                          onContinue={async () => {
                            validateForm().then((test) => {
                              if (
                                !test.PERSONS ||
                                !test.PERSONS[selectedOwner!]
                              ) {
                                setSelectedOwner(null);
                                setIsCreatingOwner(false);
                                // save data
                                persist(values);
                              }
                            });
                          }}
                          setFieldValue={setFieldValue}
                        />
                      )}
                    </Modal>
                  </>
                );
              }}
            />
          );
        }}
      </Formik>
    </div>
  );
};
