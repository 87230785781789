import { appClient } from "services";
import { IMerchantApi } from "./index";

export const api: IMerchantApi = {
  getMerchantsPartnerCount: () => {
    return appClient.merchantController.getCountByPartnerUsingGet();
  },
  me: () => {
    return appClient.merchantController.getMeUsingGet();
  },
  getMerchants: (limit: number, offset: number, name?: string) => {
    return appClient.merchantController.findMerchantsPartnerUsingGet(
      limit,
      offset,
      name
    );
  },
  updateMerchant: (body) => {
    return appClient.merchantController.updateAsMerchantUsingPut(body);
  },
  updateMerchantSettlement: (token) => {
    return appClient.merchantController.settlementDetailsConfirmUpdateUsingPost(token);
  },
  attemptMerchantSettlement: (body) => {
    return appClient.merchantController.settlementDetailsAttemptUpdateUsingPut(body);
  }
};
