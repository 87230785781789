import { loaderSlice } from "data-layer/common/loaderSlice";
import { AppDispatch } from "data-layer/store/store";
import { showError } from "utils/showError";
import { employeePermissionsApi } from "./api";
import { employeePermissionsSlice } from "./slice";

export const getEmployeePermissionsThunk = () => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(
        employeePermissionsSlice.actions.getEmployeePermissionsRequest()
      );
      
      const result = await employeePermissionsApi.api.getEmployeePermissions();

      dispatch(
        employeePermissionsSlice.actions.getEmployeePermissionsSuccess(result)
      );
    } catch (e) {
      showError("Employee loading error", e?.body?.error || e.message);
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};
