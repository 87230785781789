import React from "react";
import { useInitParams } from "./hooks/useInitParams";
import { useGetPayouts } from "./hooks/useGetPayouts";
import { Filters, Table } from "./components";
import { Styled } from "./styles";

export const PayoutsSc: React.FC = () => {
  const { filters } = useInitParams();
  useGetPayouts(filters);

  if (!filters) {
    return null;
  }

  return (
    <Styled.Container className="container">
      <Styled.Wrapper>
        <Styled.Sidebar>
          <Filters filters={filters} />
        </Styled.Sidebar>
        <Styled.Content>
          <Styled.Table>
            <Table filters={filters} />
          </Styled.Table>
        </Styled.Content>
      </Styled.Wrapper>
    </Styled.Container>
  );
};
