/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DevTransactionDTO } from '../models/DevTransactionDTO';
import type { TransactionEntity } from '../models/TransactionEntity';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { network } from 'services/network';

export class TransactionDevControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * exportToCSV
     * @param requestBody
     * @returns TransactionEntity OK
     * @returns any Created
     * @throws ApiError
     */
    public exportToCsvUsingPost(
        requestBody?: DevTransactionDTO,
    ): CancelablePromise<TransactionEntity | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/dev/transactions/add',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

}