import { ImportFlatApplicationRequestDTO } from "generated";
import { appClient } from "services";
import { IMerchantImportApi } from "./index";

export const api: IMerchantImportApi = {
  parseXLS: (formData: { files: Array<Blob>; token?: string }) => {
    return appClient.merchantImportController.parseXlsUsingPost(formData);
  },
  parseXLSX: (formData: { files: Array<Blob>; token?: string }) => {
    return appClient.merchantImportController.parseXlsxUsingPost(formData);
  },
  parseCSV: (formData: { files: Array<Blob>; token?: string }) => {
    return appClient.merchantImportController.parseCsvUsingPost(formData);
  },

  import: (requestBody: ImportFlatApplicationRequestDTO) => {
    return appClient.merchantImportController.importDataUsingPost(requestBody)
  }
};
