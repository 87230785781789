import { PaymentsParams } from "data-layer/payments/types/PaymentsParams";
import { usePayments } from "data-layer/payments";
import { useEffect } from "react";

type UseGetPayments = (filters: PaymentsParams | null) => void;

export const useGetPayments: UseGetPayments = (filters) => {
  const { getPayments } = usePayments();

  useEffect(() => {
    if (!filters) {
      return;
    }

    getPayments(filters);
  }, [filters]);
};
