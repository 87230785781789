import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PayoutDTO } from "generatedCrate";

export type Payouts = PayoutDTO[] | [];

export type PayoutsInitialState = {
  data: Payouts;
  isFetching: boolean;
};

const initialState: PayoutsInitialState = {
  data: [],
  isFetching: false,
};

export const payoutsSlice = createSlice({
  name: "payouts",
  initialState: initialState,
  reducers: {
    getPayoutsSuccess: (
      state,
      action: PayloadAction<Array<PayoutDTO>>
    ) => {
      state.data = action.payload;
      state.isFetching = false;
    },
  },
});

export const payoutsActions = payoutsSlice.actions;
