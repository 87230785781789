/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SetMerchantStatusDTO = {
    merchantId: number;
    merchantStatus: SetMerchantStatusDTO.merchantStatus;
};

export namespace SetMerchantStatusDTO {

    export enum merchantStatus {
        ACTIVATED = 'ACTIVATED',
        NEW = 'NEW',
        SUSPENDED = 'SUSPENDED',
    }


}
