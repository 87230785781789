import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { getTransactionsAmountThunk } from "../thunks";
import { TransactionParamsAmount } from "../types/TransactionParams";

type UseGetTransactionsAmount = () => {
  getTransactionsAmount: (params: TransactionParamsAmount) => void;
};

export const useGetTransactionsAmount: UseGetTransactionsAmount = () => {
  const dispatch = useDispatch();

  const getTransactionsAmount = useCallback(
    (params) => {
      dispatch(getTransactionsAmountThunk(params));
    },
    [dispatch]
  );

  return {
    getTransactionsAmount,
  };
};
