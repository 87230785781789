import { Spin } from "antd";
import { TableSc } from "scenarios";
import { Filters } from "../../hooks/useInitParams";

const columns = [
  {
    title: "Client ID",
    dataIndex: "Client ID",
    key: "Client ID",
    width: "12%",
  },
  {
    title: "Type",
    dataIndex: "Type",
    key: "Type",
    width: "12%",
  },
  {
    title: "Country",
    dataIndex: "Country",
    key: "Country",
    width: "12%",
  },
  {
    title: "Company name",
    dataIndex: "Company name",
    key: "Company name",
    width: "12%",
  },
  {
    title: "Turnover",
    dataIndex: "Turnover",
    key: "Turnover",
    width: "12%",
  },
  {
    title: "Number of tx",
    dataIndex: "Number of tx",
    key: "Number of tx",
    width: "12%",
  },
  {
    title: "Average",
    dataIndex: "Average",
    key: "Average",
    width: "12%",
  },
  {
    title: "Manager",
    dataIndex: "Manager",
    key: "Manager",
    width: "12%",
  },
];

type TableProps = {
  filters: Filters;
};

export const Table: React.FC<TableProps> = (props) => {
  const { filters } = props;
  // const { data, isFetching } = useTransactions();

  // if ((!data || !data.length) && isFetching) {
  // return <Spin />;
  // }

  if (!filters) {
    return <Spin />;
  }

  return (
    <TableSc
      data={[]}
      filters={filters}
      columns={columns}
      scroll={{ x: 1400 }}
    />
  );
};
