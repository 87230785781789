import { loaderSlice } from "data-layer/common/loaderSlice";
import { AppDispatch } from "data-layer/store/store";
import { handleNetworkError } from "utils/handleNetworkError";
import { payoutsApi } from "./api";
import { payoutsSlice } from "./slice";
import { PayoutsParams } from "./types/PayoutsParams";

export const getPayoutsThunk = (params: PayoutsParams) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      const { from, to, limit, offset, statuses } = params;
      const result = await payoutsApi.api.getPayouts(
        from,
        to,
        limit,
        offset,
        statuses
      );

      dispatch(payoutsSlice.actions.getPayoutsSuccess(result));
    } catch (e) {
      handleNetworkError(e, "Payouts loading error");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};
