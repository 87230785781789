/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateEmployeeAsAdminDTO } from '../models/CreateEmployeeAsAdminDTO';
import type { CreateEmployeeDTO } from '../models/CreateEmployeeDTO';
import type { EmployeeDTO } from '../models/EmployeeDTO';
import type { EmployeesCountDTO } from '../models/EmployeesCountDTO';
import type { SetEmployeeStatusDTO } from '../models/SetEmployeeStatusDTO';
import type { ToggleEmployeePermissionDTO } from '../models/ToggleEmployeePermissionDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { network } from 'services/network';

export class EmployeeControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get employees
     * Get employees
     * @param limit limit
     * @param offset offset
     * @returns EmployeeDTO
     * @throws ApiError
     */
    public findEmployeesUsingGet(
        limit: number,
        offset: number,
    ): CancelablePromise<Array<EmployeeDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/employees',
                query: {
                    'limit': limit,
                    'offset': offset,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Update employee
     * Update employee
     * @param requestBody
     * @returns EmployeeDTO
     * @returns any Created
     * @throws ApiError
     */
    public updateEmployeeUsingPut(
        requestBody?: EmployeeDTO,
    ): CancelablePromise<EmployeeDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'PUT',
                url: '/employees',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Create new employee
     * Create new employee
     * @param requestBody
     * @returns EmployeeDTO
     * @returns any Created
     * @throws ApiError
     */
    public createEmployeeUsingPost(
        requestBody?: CreateEmployeeDTO,
    ): CancelablePromise<EmployeeDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/employees',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Create new employee (as admin)
     * Create new employee (as admin)
     * @param requestBody
     * @returns EmployeeDTO
     * @returns any Created
     * @throws ApiError
     */
    public createEmployeeAsAdminUsingPost(
        requestBody?: CreateEmployeeAsAdminDTO,
    ): CancelablePromise<EmployeeDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/employees/admin',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Count employees for current Merchant
     * Count employees for current Merchant
     * @returns EmployeesCountDTO
     * @throws ApiError
     */
    public countEmployeesForMerchantUsingGet(): CancelablePromise<EmployeesCountDTO> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/employees/count',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Find employees (admin)
     * Find employees (admin)
     * @param limit limit
     * @param offset offset
     * @param merchantIds merchantIds
     * @param partnerIds partnerIds
     * @param nameLike nameLike
     * @param emailLike emailLike
     * @param statuses statuses
     * @param tid tid
     * @returns EmployeeDTO
     * @throws ApiError
     */
    public findEmployeesAsAdminUsingGet(
        limit: number,
        offset: number,
        merchantIds?: number,
        partnerIds?: number,
        nameLike?: string,
        emailLike?: string,
        statuses?: 'ACTIVATED' | 'EXPORTED' | 'EXTERNAL_SYSTEM_NOT_READY' | 'NEW' | 'SUSPENDED' | 'ZERO_TRANSACTION',
        tid?: string,
    ): CancelablePromise<Array<EmployeeDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/employees/find',
                query: {
                    'merchantIds': merchantIds,
                    'partnerIds': partnerIds,
                    'nameLike': nameLike,
                    'emailLike': emailLike,
                    'statuses': statuses,
                    'tid': tid,
                    'limit': limit,
                    'offset': offset,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Get current employee
     * Get current employee
     * @returns EmployeeDTO
     * @throws ApiError
     */
    public getMeUsingGet(): CancelablePromise<EmployeeDTO> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/employees/me',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Reset Employer pin in external service
     * Reset pin of current employee in external system as required
     * @returns EmployeeDTO
     * @returns any Created
     * @throws ApiError
     */
    public resetToDefaultPinUsingPut(): CancelablePromise<EmployeeDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'PUT',
                url: '/employees/reset-to-default-pin',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Set status of an Employee
     * Set status of an Employee. For example: set status to block-unblock employee and etc.
     * @param requestBody
     * @returns EmployeeDTO
     * @returns any Created
     * @throws ApiError
     */
    public setStatusUsingPut(
        requestBody?: SetEmployeeStatusDTO,
    ): CancelablePromise<EmployeeDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'PUT',
                url: '/employees/set-status',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Toggle employee permissions as merchant
     * Toggle employee permissions as merchant
     * @param requestBody
     * @returns EmployeeDTO
     * @returns any Created
     * @throws ApiError
     */
    public togglePermissionUsingPost(
        requestBody?: ToggleEmployeePermissionDTO,
    ): CancelablePromise<EmployeeDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/employees/toggle-permissions',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

}