import { useState } from "react";
import { useSearchByCustomerName } from "./useSearchByCustomerName";
import { Styled } from "./styles";
import { SearchOutlined } from "@ant-design/icons";

export const SearchByCustomerName = () => {
  const [value, setValue] = useState("");
  const { handleSearch } = useSearchByCustomerName();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setValue(value);
  };

  return (
    <Styled.Wrapper>
      <Styled.Input
        value={value}
        onChange={handleChange}
        placeholder="Enter name"
      />
      <Styled.Button onClick={() => handleSearch(value)}>
        <SearchOutlined />
      </Styled.Button>
    </Styled.Wrapper>
  );
};
