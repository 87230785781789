export enum Colors {
  Primary = "#FE8E75",
  PrimaryDark = "#E3806A",

  Secondary = "#283549",
  GrayBlue = "#8096A7",
  Black = "#000000",

  Green = "#30D158",
  Red = "#FF3B30",
  Yellow = "#FF9500",
  Blue = "#2950AF",
  LightBlue = "#007AFF",

  Gray = "#80868B",
  LightGray = "#E5E5E5",

  Primary70 = "rgba(254, 142, 117, .70)",
  Primary40 = "rgba(254, 142, 117, .40)",
  Primary20 = "rgba(254, 142, 117, .20)",

  Black25 = "rgba(0, 0, 0, .25)",
  Black10 = "rgba(0, 0, 0, .10)",
}
