import styled from "styled-components";
import { Button as ButtonComponent } from "components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

const Wrapper = styled.div`
  margin-bottom: 20px;

  border: 1px solid #e5e5e5;
  border-radius: 10px;

  background: #ffffff;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 15px 16px;

  @media (max-width: 1199px) {
    padding: 8px 16px 7px;
  }
`;

const Title = styled.div`
  font-family: ${Fonts.NexaRegular};
  font-size: 20px;
  line-height: 25px;

  color: #000;

  @media (max-width: 1199px) {
    font-size: 17px;
    line-height: 23px;
  }
`;

const Controls = styled.div``;

const Control = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;

  border: 1px solid ${Colors.Primary};
  border-radius: 50%;

  cursor: pointer;

  @media (max-width: 767px) {
    width: 25px;
    height: 25px;
  }

  &:hover {
    background-color: ${Colors.Primary};

    svg {
      color: #fff;
    }
  }

  svg {
    color: ${Colors.Primary};

    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
`;

const Button = styled(ButtonComponent)`
  width: 129px !important;

  @media (max-width: 1199px) {
    width: 100px !important;
  }
`;

const Content = styled.div`
`;

const ContentItem = styled.div`
  padding: 15px 16px 11px;

  font-size: 17px;
  line-height: 23px;

  border-bottom: 1px solid ${Colors.LightGray};

  @media (max-width: 1199px) {
    padding: 12px 16px 8px;
    font-size: 14px;
    line-height: 20px;
  }
`;

const ContentItemTitle = styled.div`
  padding-right: 15px;
  font-family: ${Fonts.NexaRegular};

  color: #8096a7;
`;

const ContentItemText = styled.span`
`;

const ContentItemError = styled.div`
  font-family: ${Fonts.NexaRegular};
  font-size: 14px;
  line-height: 18px;
  text-align: left;

  color: #ea4236;

  @media (max-width: 1199px) {
    font-size: 14px;
  }
`;

export const Styled = {
  Wrapper,
  Header,
  Title,
  Controls,
  Control,
  Button,

  Content,
  ContentItem,
  ContentItemTitle,
  ContentItemText,
  ContentItemError,
};
