import { appClient } from "services";
import { ISys18Api } from "./index";

export const api: ISys18Api = {
  get: (limit, offset, id, locale, value) => {
    return appClient.sysI18NController.findUsingGet(
      limit,
      offset,
      id,
      locale,
      value
    );
  },
};
