/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { JsonNode } from './JsonNode';
import type { MerchantDTO } from './MerchantDTO';
import type { PartnerDTO } from './PartnerDTO';
import type { ProfileDTO } from './ProfileDTO';
import type { RoleDTO } from './RoleDTO';

export type UserConnectionsDTO = {
    countryId?: number;
    created?: string;
    email: string;
    extraData?: JsonNode;
    id: number;
    inviterPartnerId?: number;
    merchants?: Array<MerchantDTO>;
    name: string;
    partner?: PartnerDTO;
    profiles?: Array<ProfileDTO>;
    roles?: Array<RoleDTO>;
    status: UserConnectionsDTO.status;
    subscribe: boolean;
    surname: string;
    verified?: boolean;
};

export namespace UserConnectionsDTO {

    export enum status {
        ACTIVATED = 'ACTIVATED',
        FAILED_LOGIN = 'FAILED_LOGIN',
        INVITED = 'INVITED',
        SUSPENDED = 'SUSPENDED',
    }


}
