import styled from "styled-components";
import { Colors } from "styles/colors";

const Logo = styled.img`
  width: 160px;
  margin-bottom: 81px;

  @media (max-width: 1199px) {
    width: 123px;
    margin-bottom: 32px;
  }

  @media (max-width: 575px) {
    width: 100px;
  }
`;

const PartnerLogo = styled.img`
  width: 305px;
  margin-bottom: 76px;

  @media (max-width: 1199px) {
    width: 232px;
    margin-bottom: 32px;
  }

  @media (max-width: 575px) {
    width: 223px;
  }
`;

const SellerLogo = styled.img`
  width: 305px;
  margin-bottom: 76px;

  @media (max-width: 1199px) {
    width: 232px;
    margin-bottom: 32px;
  }

  @media (max-width: 575px) {
    width: 223px;
  }
`;

const ImagesBorder = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 1px;
  height: 50px;
  margin: 0 20px;
  background-color: ${Colors.LightGray};

  @media (max-width: 1199px) {
    height: 38px;
    margin: 0 15px;
  }

  @media (max-width: 575px) {
    margin: 0 13px;
  }
`;

const DCLogo = styled.div`
  margin-bottom: 75px;

  img:first-child {
    width: 194px;
  }

  img:last-child {
    width: 110px;
    margin-top: 2px;
  }

  @media (max-width: 1199px) {
    margin-bottom: 47px;

    img:first-child {
      width: 194px;
    }
  
    img:last-child {
      width: 80px;
      margin-top: 3px;
    }
  }

  @media (max-width: 575px) {
    img:first-child {
      width: 179px;
    }
  
    img:last-child {
      width: 73px;
      margin-top: 3px;
    }
  }
`;

const ArivPayLogo = styled.div`
  margin-bottom: 75px;

  img:first-child {
    width: 194px;
  }

  img:last-child {
    width: 110px;
    margin-top: 2px;
  }

  @media (max-width: 1199px) {
    margin-bottom: 47px;

    img:first-child {
      width: 194px;
    }
  
    img:last-child {
      width: 80px;
      margin-top: 3px;
    }
  }

  @media (max-width: 575px) {
    img:first-child {
      width: 150px;
    }
  
    img:last-child {
      width: 73px;
      margin-top: 3px;
    }
  }
`

const BrandedLogo = styled.div`
  margin-bottom: 75px;

  img:first-child {
    max-width: 194px;
    max-height: 50px;
    width: auto;
    height: auto;
  }

  img:last-child {
    max-width: 110px;
    margin-top: 2px;
  }

  @media (max-width: 1199px) {
    margin-bottom: 47px;

    img:first-child {
      // width: 194px;
      max-height: 38px;
    }
  
    img:last-child {
      width: 80px;
      margin-top: 3px;
    }
  }

  @media (max-width: 575px) {
    img:first-child {
      // width: 150px;
      max-width: 150px;
    }
  
    img:last-child {
      width: 73px;
      margin-top: 3px;
    }
  }
`

export const Styled = {
  Logo,
  PartnerLogo,
  SellerLogo,

  ImagesBorder,

  BrandedLogo,
  DCLogo,
  ArivPayLogo,
};
