import { Col, Row, Spin } from "antd";
import { useTransactions } from "data-layer/transaction";
import { TableSc } from "scenarios/common";
import { Styled } from "./styles";
import { TransactionDetail } from "../TransactionDetail";
import { TransactionRestDTO } from "generated";
import { TransactionParams } from "data-layer/transaction/types/TransactionParams";
import { useTransactionDetail } from "../useTransactionDetail";
import { formatMoney } from "utils/numberFormatter";

function formatAmount(amount: number) {
  return `${amount.toFixed(2)}`;
}

const columns = [
  {
    title: "Operation",
    dataIndex: "type",
    key: "type",
    render: (type: TransactionRestDTO["type"]) => (
      <Styled.Type>{type}</Styled.Type>
    ),
    width: "10%",
  },
  {
    title: "Date",
    dataIndex: "dateTime",
    key: "date",
    render: (date: TransactionRestDTO["dateTime"]) => {
      const dateFormated = new Date(date!).toLocaleDateString("en-us", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });

      return <span>{dateFormated}</span>;
    },
    width: "10%",
  },
  {
    title: "Time",
    dataIndex: "dateTime",
    key: "time",
    render: (date: TransactionRestDTO["dateTime"]) => {
      const timeFormated = new Date(date!).toLocaleTimeString();

      return <span>{timeFormated}</span>;
    },
    width: "10%",
  },
  {
    title: () => <Styled.AmountTitle>Amount</Styled.AmountTitle>,
    key: "amount",
    render: (record: TransactionRestDTO) => (
      <Styled.AmountText>
        {(record.type === TransactionRestDTO.type.CHARGEBACK ||
          record.type === TransactionRestDTO.type.REFUND ||
          record.type === TransactionRestDTO.type.PARTIAL_REFUND ||
          record.type === TransactionRestDTO.type.CANCEL) && <span>-</span>}
        <span>{formatMoney({ value: record.amount || 0, currency: record.currency })}</span>
      </Styled.AmountText>
    ),
    width: "10%",
  },
  {
    title: "Transaction ID",
    dataIndex: "id",
    key: "id",
    width: "10%",
    render: (id: TransactionRestDTO["id"]) => {
      return <span>{id}</span>;
    },
  },
  {
    title: "Employee",
    dataIndex: "employeeName",
    key: "employeeName",
    width: "40%",
    render: (employeeName: TransactionRestDTO["employeeName"]) => {
      return <span>{employeeName}</span>;
    },
  },
  {
    title: "",
    key: "transactionButtonInfo",
    render: () => (
      <span>
        <Row justify="center">
          <Col>
            <Styled.Arrow />
          </Col>
        </Row>
      </span>
    ),
    width: "10%",
  },
];

type TableProps = {
  filters: TransactionParams;
};

export const Table: React.FC<TableProps> = (props) => {
  const { filters } = props;
  const { data, isFetching } = useTransactions();

  const {
    data: detailData,
    visible,
    showDetailModal,
    hideModal,
  } = useTransactionDetail();

  if ((!data || !data.length) && isFetching) {
    return <Spin />;
  }

  if (!filters) {
    return <Spin />;
  }

  return (
    <>
      <Styled.TableWrapper>
        <TableSc
          data={data}
          filters={filters}
          columns={columns}
          scroll={{ x: 1400 }}
          onRow={(record: TransactionRestDTO) => {
            return {
              onClick: () => {
                showDetailModal(record);
                console.log(record.id);
              },
            };
          }}
        />
      </Styled.TableWrapper>

      <TransactionDetail
        data={detailData}
        visible={visible}
        hideModal={hideModal}
      />
    </>
  );
};
