/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WebhookDTO } from '../models/WebhookDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { network } from 'services/network';

export class WebhooksControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all (WebhookEntity)
     * Get all (WebhookEntity)
     * @param limit limit
     * @param offset offset
     * @returns WebhookDTO
     * @throws ApiError
     */
    public findAllUsingGet(
        limit: number,
        offset: number,
    ): CancelablePromise<Array<WebhookDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/webhook',
                query: {
                    'limit': limit,
                    'offset': offset,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Update webhook
     * Update webhook
     * @param requestBody
     * @returns WebhookDTO
     * @returns any Created
     * @throws ApiError
     */
    public updateUsingPut(
        requestBody?: WebhookDTO,
    ): CancelablePromise<WebhookDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'PUT',
                url: '/webhook',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Create webhook
     * Create webhook
     * @param requestBody
     * @returns WebhookDTO
     * @returns any Created
     * @throws ApiError
     */
    public createUsingPost(
        requestBody?: WebhookDTO,
    ): CancelablePromise<WebhookDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/webhook',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Delete webhook
     * Delete webhook
     * @param id id
     * @returns any
     * @throws ApiError
     */
    public deleteUsingDelete(
        id: number,
    ): CancelablePromise<any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'DELETE',
                url: '/webhook',
                query: {
                    'id': id,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                },
            })
        });
    }

    /**
     * Add event handler
     * Add event handle
     * @param webhookId webhookId
     * @param webhookSubscriptionId webhookSubscriptionId
     * @returns WebhookDTO
     * @returns any Created
     * @throws ApiError
     */
    public addEventHandlerUsingPut(
        webhookId: number,
        webhookSubscriptionId: number,
    ): CancelablePromise<WebhookDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'PUT',
                url: '/webhook/{webhookId}/subscribe/{webhookSubscriptionId}',
                path: {
                    'webhookId': webhookId,
                    'webhookSubscriptionId': webhookSubscriptionId,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

}