/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MerchantCountDTO } from '../models/MerchantCountDTO';
import type { MerchantDTO } from '../models/MerchantDTO';
import type { SetMerchantStatusDTO } from '../models/SetMerchantStatusDTO';
import type { SettlementDetailsAttemptUpdateDTO } from '../models/SettlementDetailsAttemptUpdateDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { network } from 'services/network';

export class MerchantControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Edit merchant details
     * Can be used by merchants to edit their details
     * @param requestBody
     * @returns MerchantDTO
     * @returns any Created
     * @throws ApiError
     */
    public updateAsMerchantUsingPut(
        requestBody?: MerchantDTO,
    ): CancelablePromise<MerchantDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'PUT',
                url: '/merchants',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Update merchant data (admin)
     * Update merchant data (admin)
     * @param requestBody
     * @returns MerchantDTO
     * @returns any Created
     * @throws ApiError
     */
    public updateMerchantAsAdminUsingPut(
        requestBody?: MerchantDTO,
    ): CancelablePromise<MerchantDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'PUT',
                url: '/merchants/admin',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Get count merchants (partner)
     * Get count merchants (partner)
     * @returns MerchantCountDTO
     * @throws ApiError
     */
    public getCountByPartnerUsingGet(): CancelablePromise<MerchantCountDTO> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/merchants/count/partner',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Find merchants
     * Search merchants by name (like)
     * @param limit limit
     * @param offset offset
     * @param partnerIds partnerIds
     * @param name name
     * @param mid mid
     * @returns MerchantDTO
     * @throws ApiError
     */
    public findMerchantsUsingGet(
        limit: number,
        offset: number,
        partnerIds?: number,
        name?: string,
        mid?: string,
    ): CancelablePromise<Array<MerchantDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/merchants/find',
                query: {
                    'partnerIds': partnerIds,
                    'name': name,
                    'mid': mid,
                    'limit': limit,
                    'offset': offset,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Find merchants (partner)
     * Search merchants by name (like)
     * @param limit limit
     * @param offset offset
     * @param name name
     * @returns MerchantDTO
     * @throws ApiError
     */
    public findMerchantsPartnerUsingGet(
        limit: number,
        offset: number,
        name?: string,
    ): CancelablePromise<Array<MerchantDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/merchants/find/partner',
                query: {
                    'name': name,
                    'limit': limit,
                    'offset': offset,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Get current merchant
     * Get current merchant
     * @returns MerchantDTO
     * @throws ApiError
     */
    public getMeUsingGet(): CancelablePromise<MerchantDTO> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/merchants/me',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Edit merchant details as partner
     * Can be used by partners to edit selected merchant details
     * @param requestBody
     * @returns MerchantDTO
     * @returns any Created
     * @throws ApiError
     */
    public updateMerchantAsPartnerUsingPut(
        requestBody?: MerchantDTO,
    ): CancelablePromise<MerchantDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'PUT',
                url: '/merchants/partner',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Set status of a Merchant
     * Set status of a Merchant. For example: set status to block-unblock merchant and etc.
     * @param requestBody
     * @returns MerchantDTO
     * @returns any Created
     * @throws ApiError
     */
    public setStatusUsingPut(
        requestBody?: SetMerchantStatusDTO,
    ): CancelablePromise<MerchantDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'PUT',
                url: '/merchants/set-status',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Attempt Update merchant`s Settlement details
     * Attempt Update merchant`s Settlement details
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public settlementDetailsAttemptUpdateUsingPut(
        requestBody?: SettlementDetailsAttemptUpdateDTO,
    ): CancelablePromise<any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'PUT',
                url: '/merchants/settlement-details',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Update merchant`s Settlement details
     * Update merchant`s Settlement details
     * @param token token
     * @returns MerchantDTO
     * @returns any Created
     * @throws ApiError
     */
    public settlementDetailsConfirmUpdateUsingPost(
        token: string,
    ): CancelablePromise<MerchantDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/merchants/settlement-details',
                query: {
                    'token': token,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Get Merchant by ID
     * @param id id
     * @returns MerchantDTO
     * @throws ApiError
     */
    public getMerchantByIdUsingGet(
        id: number,
    ): CancelablePromise<MerchantDTO> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/merchants/{id}',
                path: {
                    'id': id,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

}