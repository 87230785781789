import React from "react";
import { Styled } from "./styles";
import { BulkUploadSc } from "scenarios/partner/BulkUploadSc";
import { DownloadOutlined } from "@ant-design/icons";

type ViewProps = {
  onClick: VoidFunction;
};

const View: React.FC<ViewProps> = (props) => {
  return (
    <Styled.Button {...props}>
      <span>Bulk upload</span>
      <DownloadOutlined />
    </Styled.Button>
  );
};

export const BulkUpload: React.FC = () => {
  return <BulkUploadSc ViewComponent={View} />;
};
