import {
  BusinessOfficerValues,
  BusinessOwnerValues,
  BusinessUlitmateOwnerValues,
  OwnerTypeEnum,
} from "data-layer/application/types";
import { CountryDTO } from "generated";
import { AppInputs } from "generated/modelsWrapper/AppInputs";

type SetFieldValue = (
  field: string,
  value: any,
  shouldValidate?: boolean | undefined
) => void;

type Schema = {
  addressLine1?: string;
  addressLine2?: string;
  accountNumber?: string;
  city?: string;
  companyName?: string;
  companyNumber?: string;
  country?: string;
  officers?: PersonItem[];
  postalCode?: string;
  registrationDate?: string;
  shareholders?: PersonItem[];
  sortCode?: string;
  vatID?: string;
};

type PersonItem = {
  cityBirth?: string;
  companyName?: string;
  countryBirth?: string;
  kind?: string;
  monthOfBirth?: number;
  name?: string;
  percentOfShares?: number;
  regionBirth?: string;
  residence?: string;
  surname?: string;
  yearOfBirth?: number;
  nationality?: string;
  addressLine1?: string;
  addressLine2?: string;
  postalCode?: string;
  officerRole?: string;
};

function isFindTheSamePerson(
  officer: PersonItem,
  shareholders: PersonItem[]
): boolean {
  let theSame = false;

  shareholders.map((shareholder) => {
    if (isTheSamePerson(shareholder, officer)) {
      theSame = true;
    }
  });

  return theSame;
}

function isTheSamePerson(person1: PersonItem, person2: PersonItem): boolean {
  return (
    person1?.name?.toLowerCase() === person2?.name?.toLowerCase() &&
    person1?.surname?.toLowerCase() === person2?.surname?.toLowerCase()
  );
}

function parseDublicate(array: PersonItem[]): PersonItem[] {
  let data: PersonItem[] = [];

  array.forEach((item) => {
    const dublicate = data.findIndex((_item) => isTheSamePerson(item, _item));

    if (dublicate !== -1) {
      data[dublicate] = mergeDublicate(data[dublicate], item);
    } else {
      data.push(item);
    }
  });

  return data;
}

function mergeDublicate(item: PersonItem, _item: PersonItem): PersonItem {
  let person: PersonItem = {
    ...item,
  };

  if (!person.cityBirth && _item.cityBirth) {
    person.cityBirth = _item.cityBirth;
  }
  if (!person.companyName && _item.companyName) {
    person.companyName = _item.companyName;
  }
  if (!person.countryBirth && _item.countryBirth) {
    person.countryBirth = _item.countryBirth;
  }
  if (!person.kind && _item.kind) {
    person.kind = _item.kind;
  }
  if (!person.monthOfBirth && _item.monthOfBirth) {
    person.monthOfBirth = _item.monthOfBirth;
  }
  if (!person.name && _item.name) {
    person.name = _item.name;
  }
  if (!person.percentOfShares && _item.percentOfShares) {
    person.percentOfShares = _item.percentOfShares;
  }
  if (!person.regionBirth && _item.regionBirth) {
    person.regionBirth = _item.regionBirth;
  }
  if (!person.residence && _item.residence) {
    person.residence = _item.residence;
  }
  if (!person.surname && _item.surname) {
    person.surname = _item.surname;
  }
  if (!person.yearOfBirth && _item.yearOfBirth) {
    person.yearOfBirth = _item.yearOfBirth;
  }
  if (!person.nationality && _item.nationality) {
    person.nationality = _item.nationality;
  }
  if (!person.addressLine1 && _item.addressLine1) {
    person.addressLine1 = _item.addressLine1;
  }
  if (!person.addressLine2 && _item.addressLine2) {
    person.addressLine2 = _item.addressLine2;
  }
  if (!person.postalCode && _item.postalCode) {
    person.postalCode = _item.postalCode;
  }

  if (person.officerRole && _item.officerRole) {
    person.officerRole = person.officerRole + ", " + _item.officerRole;
  } else if (!person.officerRole && _item.officerRole) {
    person.officerRole = _item.officerRole;
  }

  return person;
}

function parseOfficer(person: PersonItem): BusinessOfficerValues {
  let values = {} as BusinessOfficerValues;

  values[AppInputs.PERSON_STATUS] = [OwnerTypeEnum.Officer];

  if (person.name) {
    values[AppInputs.PERSON_FIRST_NAME] = person.name;
  }

  if (person.surname) {
    values[AppInputs.PERSON_LAST_NAME] = person.surname;
  }

  if (person.cityBirth) {
    values[AppInputs.PERSON_CITY] = person.cityBirth;
  }

  if (person.nationality) {
    values[AppInputs.PERSON_NATIONALITY] = person.nationality;
  }

  if (person.addressLine1) {
    values[AppInputs.PERSON_ADDRESS_LINE_1] = person.addressLine1;
  }

  if (person.addressLine2) {
    values[AppInputs.PERSON_ADDRESS_LINE_2] = person.addressLine2;
  }

  if (person.postalCode) {
    values[AppInputs.PERSON_POSTAL_CODE] = person.postalCode;
  }

  if (person.officerRole) {
    values[AppInputs.PERSON_POSITION] = person.officerRole;
  }

  return values;
}

function parseUBO(person: PersonItem): BusinessUlitmateOwnerValues {
  let values = {} as BusinessUlitmateOwnerValues;

  values[AppInputs.PERSON_STATUS] = [OwnerTypeEnum.UltimateOwner];

  if (person.name) {
    values[AppInputs.PERSON_FIRST_NAME] = person.name;
  }

  if (person.surname) {
    values[AppInputs.PERSON_LAST_NAME] = person.surname;
  }

  if (person.cityBirth) {
    values[AppInputs.PERSON_CITY] = person.cityBirth;
  }

  if (person.nationality) {
    values[AppInputs.PERSON_NATIONALITY] = person.nationality;
  }

  if (person.percentOfShares) {
    values[AppInputs.PERSON_OWNERSHIP] = person.percentOfShares;
  }

  if (person.addressLine1) {
    values[AppInputs.PERSON_ADDRESS_LINE_1] = person.addressLine1;
  }

  if (person.addressLine2) {
    values[AppInputs.PERSON_ADDRESS_LINE_2] = person.addressLine2;
  }

  if (person.postalCode) {
    values[AppInputs.PERSON_POSTAL_CODE] = person.postalCode;
  }

  return values;
}

function parseUBOValues(_shareholders: PersonItem[]): {
  shareholders: BusinessUlitmateOwnerValues[];
} {
  let shareholders: BusinessUlitmateOwnerValues[] = [];

  if (_shareholders.length) {
    _shareholders.forEach((shareholder) => {
      const person = parseUBO(shareholder);
      shareholders.push(person);
    });
  }

  return {
    shareholders,
  };
}

function parseOfficerValues(
  _officers: PersonItem[],
  _shareholders: PersonItem[]
): {
  officers: BusinessOfficerValues[];
} {
  let officers: BusinessOfficerValues[] = [];

  if (_officers.length) {
    _officers.forEach((officer) => {
      if (isFindTheSamePerson(officer, _shareholders)) {
        return;
      }

      const person = parseOfficer(officer);

      officers.push(person);
    });
  }

  return {
    officers,
  };
}

export function setPersons(
  setFieldValue: SetFieldValue,
  _shareholders: PersonItem[],
  _officers: PersonItem[]
) {
  const { shareholders: parsedShareholders } = parseUBOValues(
    parseDublicate(_shareholders)
  );
  const { officers: parsedOfficers } = parseOfficerValues(
    parseDublicate(_officers),
    _shareholders
  );

  // console.log("setPersons parsedShareholders", parsedShareholders);
  // console.log("setPersons parsedOfficers", parsedOfficers);

  const data: BusinessOwnerValues[] = parsedShareholders.concat(parsedOfficers);

  setFieldValue(AppInputs.PERSONS, data);
}

export function setCompanyData(
  company: Schema | undefined,
  setFieldValue: SetFieldValue,
  countries?: CountryDTO[]
) {
  console.log("company", company);

  if (!company || typeof company !== "object") {
    return null;
  }

  if (company.accountNumber) {
    setFieldValue(AppInputs.ACCOUNT_NUMBER, company.accountNumber);
  }

  if (company.companyName) {
    setFieldValue(AppInputs.COMPANY_NAME, company.companyName);
  }

  if (company.companyNumber) {
    setFieldValue(AppInputs.COMPANY_NUMBER, company.companyNumber);
  }

  if (company.country) {
    const country = countries?.find(
      (country) => country.name === company.country
    );
    if (country) {
      setFieldValue(AppInputs.COUNTRY, country.id);
    }
  }

  if (company.city) {
    setFieldValue(AppInputs.CITY, company.city);
  }

  if (company.postalCode) {
    setFieldValue(AppInputs.POSTAL_CODE, company.postalCode);
  }

  // if (company.registrationDate) {
  //   setFieldValue(AppInputs.REGISTRATION_DATE, company.registrationDate);
  // }

  if (company.sortCode) {
    setFieldValue(AppInputs.SORT_CODE, company.sortCode);
  }

  if (company.addressLine1) {
    setFieldValue(AppInputs.ADDRESS_LINE_1, company.addressLine1);
  }

  if (company.addressLine2) {
    setFieldValue(AppInputs.ADDRESS_LINE_2, company.addressLine2);
  }

  // if (company.vatID) {
  //   setFieldValue(AppInputs.VAT_ID, company.vatID);
  // }

  if (company.shareholders?.length || company.officers?.length) {
    setPersons(
      setFieldValue,
      company.shareholders || [],
      company.officers || []
    );
  }
}
