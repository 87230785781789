import { Formik } from "formik";
import { Col, Popover, Row } from "antd";
import React from "react";
import { Styled as OnboardingStyled } from "../../onboardingStyles";
import { AppInputs } from "generated/modelsWrapper/AppInputs";
import { OnboardingFormItem } from "../OnboardingFormItem";
import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { StepProps, SoloTraderStepProps } from "../../OnboardingSc";
import { usePersist } from "../../usePersist";
import {
  Button,
  FormikPatchTouched,
  FormItemHintAlign,
  InputFile,
  InputFileType,
} from "components";
import { getFormikError } from "utils/getFormikError";
import { identityVerificationSoloTraderSchema } from "common/onboarding/schema/identityVerificationSchema";
import { Styled } from "./styles";
import "./styles.less";

const Content: React.FC = () => {
  return (
    <Styled.hintWrapper>
      <Styled.hintHeader>Document list</Styled.hintHeader>
      <Styled.hintContent>
        <Styled.hintContentGroup>
          <Styled.hintContentGroupTitle>
            Current signed passport
          </Styled.hintContentGroupTitle>
          <Styled.hintContentGroupText>
            Utility bill (gas, electric, satellite television, landline phone
            bill) issued within the last three months
          </Styled.hintContentGroupText>
        </Styled.hintContentGroup>

        <Styled.hintContentGroup>
          <Styled.hintContentGroupTitle>
            Original birth certificate (UK birth certificate issued within 12
            months of the date of birth in full form including those issued by
            UK authorities overseas such as Embassies High Commissions and HM
            Forces)
          </Styled.hintContentGroupTitle>
          <Styled.hintContentGroupText>
            Local authority council tax bill for the current council tax year
          </Styled.hintContentGroupText>
        </Styled.hintContentGroup>
      </Styled.hintContent>
    </Styled.hintWrapper>
  );
};

export const IdentityVerificationSoloTraderStep: React.FC<
  SoloTraderStepProps & StepProps
> = (props) => {
  const { persist } = usePersist();
  const { initData, prevStep, nextStep } = props;

  return (
    <div>
      <OnboardingStyled.Title>Identity verification</OnboardingStyled.Title>

      <Popover
        content={<Content />}
        trigger="click"
        placement="right"
        overlayClassName="identity-verification-popover"
      >
        <Styled.Link>What documents need to verification?</Styled.Link>
      </Popover>
      <Styled.Subtitle>
        Documents we will not accept include, but are not limited to
      </Styled.Subtitle>
      <Styled.List>
        <Styled.ListItem>- Provisional driving licence</Styled.ListItem>
        <Styled.ListItem>- Mobile phone bills</Styled.ListItem>
        <Styled.ListItem>- Credit card statements</Styled.ListItem>
      </Styled.List>

      <Formik
        initialValues={initData}
        validationSchema={identityVerificationSoloTraderSchema}
        validateOnChange={true}
        validateOnMount={true}
        enableReinitialize
        onSubmit={(values) => {
          persist(values, nextStep);
        }}
      >
        {({
          errors,
          values,
          touched,
          handleSubmit,
          isValid,
          setFieldValue,
          setFieldTouched,
        }) => {
          return (
            <>
              <FormikPatchTouched />

              <Styled.Group>
                <Styled.GroupHeader>
                  <Row justify="space-between">
                    <Col>
                      <Styled.GroupTitle>
                        {values.COMPANY_NAME}
                      </Styled.GroupTitle>
                      <Styled.GroupSubtitle></Styled.GroupSubtitle>
                    </Col>
                    <Col>
                      <Popover
                        content={<Content />}
                        trigger="click"
                        placement="right"
                        overlayClassName="identity-verification-popover"
                      >
                        <InfoCircleOutlined
                          style={{ fontSize: 30, color: "#8095a7" }}
                        />
                      </Popover>
                    </Col>
                  </Row>
                </Styled.GroupHeader>

                <Styled.GroupContent>
                  <Row gutter={[0, { xs: 15, sm: 15, md: 20 }]}>
                    <Styled.GroupContentItem span={24}>
                      <OnboardingFormItem
                        label={"Proof of identity"}
                        error={getFormikError(
                          AppInputs.PROOF_IDENTITY_FIRST,
                          errors,
                          touched
                        )}
                        hintAlign={FormItemHintAlign.Left}
                      >
                        <InputFile
                          name={AppInputs.PROOF_IDENTITY_FIRST}
                          value={values.PROOF_IDENTITY_FIRST}
                          handleChange={(value: number) => {
                            setFieldValue(AppInputs.PROOF_IDENTITY_FIRST, value, true);
                          }}
                          type={InputFileType.Primary}
                          handleRemove={() => {
                            setFieldValue(AppInputs.PROOF_IDENTITY_FIRST, undefined, true);
                            setFieldTouched(AppInputs.PROOF_IDENTITY_FIRST, true, false);
                          }}
                        />
                      </OnboardingFormItem>
                    </Styled.GroupContentItem>

                    <Styled.GroupContentItem span={24}>
                      <OnboardingFormItem
                        label={"Proof of address"}
                        error={getFormikError(
                          AppInputs.PROOF_ADDRESS,
                          errors,
                          touched
                        )}
                        hintAlign={FormItemHintAlign.Left}
                      >
                        <InputFile
                          name={AppInputs.PROOF_ADDRESS}
                          value={values.PROOF_ADDRESS}
                          handleChange={(value: number) =>
                            setFieldValue(AppInputs.PROOF_ADDRESS, value, true)
                          }
                          type={InputFileType.Primary}
                          handleRemove={() => {
                            setFieldValue(AppInputs.PROOF_ADDRESS, undefined, true)
                            setFieldTouched(AppInputs.PROOF_ADDRESS, true, false)
                          }}
                        />
                      </OnboardingFormItem>
                    </Styled.GroupContentItem>
                  </Row>
                </Styled.GroupContent>
              </Styled.Group>

              <OnboardingStyled.Footer>
                <Row gutter={{ xs: 10, sm: 10, md: 25 }}>
                  <Col>
                    <Button
                      type="secondary-transparent"
                      onClick={() => prevStep()}
                    >
                      Back
                    </Button>
                  </Col>
                  <Col flex={1}>
                    <Button
                      type="primary-transparent"
                      onClick={() => handleSubmit()}
                      disabled={!isValid}
                      block
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </OnboardingStyled.Footer>
            </>
          );
        }}
      </Formik>
    </div>
  );
};
