import styled from "styled-components";
import { Fonts } from "styles/fonts";
import { Colors } from "styles/colors";
import bg_confirm from "./bg_confirm.jpg";

const Wrapper = styled.div`
  display: flex;

  min-height: 100vh;
`;

const BackgroundShared = `
  flex-shrink: 0;

  position: relative;

  width: 47%;
  min-height: 100vh;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center left 50%;

  overflow: hidden;

  @media (max-width: 1199px) {
    width: 550px;
  }

  @media (max-width: 991px) {
    display: none;
  }
`;

const Background = styled.div`
  ${BackgroundShared}

  background-image: url(${bg_confirm});

  &::after {
    content: "";

    position: absolute;

    bottom: -500px;
    right: 240px;

    width: 1000px;
    height: 880px;

    border-radius: 50%;

    background-color: ${Colors.Primary};

    opacity: 0.7;

    @media (max-width: 1199px) {
      right: 20px;
      bottom: -300px;
      width: 600px;
      height: 600px;
    }
  }
`;

const Content = styled.div`
  flex-shrink: 0;

  display: flex;
  flex-direction: column;

  width: 53%;
  min-height: 100vh;

  padding: 100px 72px;

  @media (max-width: 1199px) {
    flex-grow: 1;
    width: auto;
    padding: 50px 55px;
  }

  @media (max-width: 991px) {
    width: 100%;
  }

  @media (max-width: 575px) {
    padding: 50px 25px;
  }
`;

const ContentContainer = styled.div`
  max-width: 484px;

  @media (max-width: 1199px) {
    max-width: 387px;
  }

  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;

const Description = styled.p`
  max-width: 370px;

  margin-bottom: 45px;

  font-family: ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 135%;

  color: ${Colors.Black};

  @media (max-width: 1199px) {
    margin-bottom: 35px;
    font-size: 14px;
    line-height: 20px;
  }

  @media (max-width: 575px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

const SubmitButton = styled.div`
  max-width: 273px;

  @media (max-width: 1199px) {
    max-width: 225px;
  }

  @media (max-width: 575px) {
    max-width: 100%;
  }
`;

export const Styled = {
  Wrapper,
  Background,
  Content,
  ContentContainer,
  Description,
  SubmitButton,
};
