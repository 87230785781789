import {Col, Row} from "antd";
import {Modal} from "components";
import {Styled} from "./styles";
import {HomeOutlined} from "@ant-design/icons";
import {TransactionRestDTO} from "generated";
import {formatMoney} from "utils/numberFormatter";

type TransactionDetailProps = {
  data: TransactionRestDTO | null;
  visible: boolean;
  hideModal: VoidFunction;
};

const formatDate = (date: TransactionRestDTO["dateTime"]) => {
  const dateFormated = new Date(date!).toLocaleDateString("en-US", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

  const timeFormated = new Date(date!).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  return `${dateFormated} / ${timeFormated}`;
};

export const TransactionDetail: React.FC<TransactionDetailProps> = (props) => {
  const { visible, hideModal, data } = props;

  if (data === null) {
    return null;
  }

  return (
    <Modal visible={visible} onCancel={hideModal} footer={null}>
      <Styled.Title>
        <span>Transaction details</span>
      </Styled.Title>

      <Styled.CompanyDetails>
        <Styled.CompanyDetailsIcon>
          <HomeOutlined />
        </Styled.CompanyDetailsIcon>
        <Styled.CompanyDetailsContent>
          <Styled.CompanyDetailsTitle>
            Company details
          </Styled.CompanyDetailsTitle>
          <Styled.CompanyDetailsText>
            <p>{data.merchant.merchantName}</p>
            <p>{data.merchant.dba}</p>
          </Styled.CompanyDetailsText>
        </Styled.CompanyDetailsContent>
      </Styled.CompanyDetails>

      <Styled.Table>
        <Row wrap={false} justify="space-between">
          <Col>Operation:</Col>
          <Col>
            <span style={{ color: "#34C759" }}>{data.type}</span>
          </Col>
        </Row>
        <Row wrap={false} justify="space-between">
          <Col>Transaction ID:</Col>
          <Col>{data.id}</Col>
        </Row>
        <Row wrap={false} justify="space-between">
          <Col>Authorization code:</Col>
          <Col>{data.authorizationCode}</Col>
        </Row>
        <Row wrap={false} justify="space-between">
          <Col>RRN code:</Col>
          <Col>{data.rrn}</Col>
        </Row>
        <Row wrap={false} justify="space-between">
          <Col>Terminal ID:</Col>
          <Col>{data.tid}</Col>
        </Row>
        <Row wrap={false} justify="space-between">
          <Col>Payment time:</Col>
          <Col>{formatDate(data.dateTime)}</Col>
        </Row>
        <Row wrap={false} justify="space-between">
          <Col>Employee:</Col>
          <Col>{data.employeeName}</Col>
        </Row>
      </Styled.Table>

      <Styled.AmountRow>
        <Row wrap={false} justify="space-between">
          <Col>
            <Styled.AmountTitle>Amount:</Styled.AmountTitle>
          </Col>
          <Col>
            <Styled.AmountValue>
              {(data.type === TransactionRestDTO.type.CHARGEBACK ||
                data.type === TransactionRestDTO.type.REFUND ||
                data.type === TransactionRestDTO.type.PARTIAL_REFUND ||
                data.type === TransactionRestDTO.type.CANCEL) && <span>-</span>}
              {formatMoney({
                value: data.amount || 0,
                currency: data.currency,
              })}
            </Styled.AmountValue>
          </Col>
        </Row>
      </Styled.AmountRow>

      {/*<Button type="primary" block disabled>*/}
      {/*  Send via email*/}
      {/*</Button>*/}
    </Modal>
  );
};
