/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminUserDTO } from '../models/AdminUserDTO';
import type { SetUserStatusDTO } from '../models/SetUserStatusDTO';
import type { UserConnectionsDTO } from '../models/UserConnectionsDTO';
import type { UserDTO } from '../models/UserDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { network } from 'services/network';

export class UserControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Find users
     * Search users + roles by name, surname, email (like)
     * @param limit
     * @param offset
     * @param email
     * @param from
     * @param name
     * @param orderBy0Direction
     * @param orderBy0Field
     * @param surname
     * @param to
     * @returns AdminUserDTO
     * @throws ApiError
     */
    public findUsersUsingGet(
        limit: number,
        offset: number,
        email?: string,
        from?: string,
        name?: string,
        orderBy0Direction?: 'ASC' | 'DESC',
        orderBy0Field?: string,
        surname?: string,
        to?: string,
    ): CancelablePromise<Array<AdminUserDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/users',
                query: {
                    'email': email,
                    'from': from,
                    'limit': limit,
                    'name': name,
                    'offset': offset,
                    'orderBy[0].direction': orderBy0Direction,
                    'orderBy[0].field': orderBy0Field,
                    'surname': surname,
                    'to': to,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Update user
     * Update user
     * @param requestBody
     * @returns UserDTO
     * @returns any Created
     * @throws ApiError
     */
    public updateUserUsingPut(
        requestBody?: UserDTO,
    ): CancelablePromise<UserDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'PUT',
                url: '/users',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Update user (admin)
     * Update user (admin)
     * @param requestBody
     * @returns UserDTO
     * @returns any Created
     * @throws ApiError
     */
    public updateUserAdminUsingPut(
        requestBody?: UserDTO,
    ): CancelablePromise<UserDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'PUT',
                url: '/users/admin',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Gets user (who exactly authorized)
     * Gets user (who exactly authorized)
     * @returns UserDTO
     * @throws ApiError
     */
    public getMeUsingGet(): CancelablePromise<UserDTO> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/users/me',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Set status of a User
     * Set status of a User. For example: set status to block-unblock users and etc.
     * @param requestBody
     * @returns AdminUserDTO
     * @returns any Created
     * @throws ApiError
     */
    public setStatusUsingPut(
        requestBody?: SetUserStatusDTO,
    ): CancelablePromise<AdminUserDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'PUT',
                url: '/users/set-status',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Update user's last frt-web
     * Update user's firebase registration token with more recent (web)
     * @param frt frt
     * @returns UserDTO
     * @returns any Created
     * @throws ApiError
     */
    public updateUserFirebaseRegistrationTokenWebUsingPut(
        frt: string,
    ): CancelablePromise<UserDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'PUT',
                url: '/users/update-frt-web/{frt}',
                path: {
                    'frt': frt,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Update user's last frt
     * Update user's firebase registration token with more recent
     * @param frt frt
     * @returns UserDTO
     * @returns any Created
     * @throws ApiError
     */
    public updateUserFirebaseRegistrationTokenUsingPut(
        frt: string,
    ): CancelablePromise<UserDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'PUT',
                url: '/users/update-frt/{frt}',
                path: {
                    'frt': frt,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Find user by id (admin)
     * Find user by id (admin)
     * @param id id
     * @returns UserDTO
     * @throws ApiError
     */
    public findUserByIdUsingGet(
        id: number,
    ): CancelablePromise<UserDTO> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/users/{id}',
                path: {
                    'id': id,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Find user connections by id (admin)
     * Find user connections by id (admin)
     * @param id id
     * @returns UserConnectionsDTO
     * @throws ApiError
     */
    public findUserConnectionsByIdUsingGet(
        id: number,
    ): CancelablePromise<UserConnectionsDTO> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/users/{id}/connections',
                path: {
                    'id': id,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Save user role after edit
     * Save user role after edit
     * @param userId userId
     * @param roleId roleId
     * @returns UserDTO
     * @returns any Created
     * @throws ApiError
     */
    public saveUserRoleAfterEditUsingPut(
        userId: number,
        roleId: number,
    ): CancelablePromise<UserDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'PUT',
                url: '/users/{userId}/role/{roleId}',
                path: {
                    'userId': userId,
                    'roleId': roleId,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Delete user role after edit
     * Delete user role after edit
     * @param userId userId
     * @param roleId roleId
     * @returns UserDTO
     * @throws ApiError
     */
    public deleteUserRoleAfterEditUsingDelete(
        userId: number,
        roleId: number,
    ): CancelablePromise<UserDTO> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'DELETE',
                url: '/users/{userId}/role/{roleId}',
                path: {
                    'userId': userId,
                    'roleId': roleId,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                },
            })
        });
    }

}