/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * DTO to create Profile
 */
export type CreateUpdateProfileDTO = {
    /**
     * Kind of profile
     */
    kind: CreateUpdateProfileDTO.kind;
    /**
     * Name of profile
     */
    name: string;
};

export namespace CreateUpdateProfileDTO {

    /**
     * Kind of profile
     */
    export enum kind {
        COMPANY = 'COMPANY',
        SOLE_TRADER = 'SOLE_TRADER',
    }


}
