import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { keysEqual } from "utils/keysEqual";

type Params = {
  from: string;
  to: string;
}

type SearchParamsFullfiled = {
  from: string;
  to: string;
};

export type UseInitParams = () => {
  filters: Params | null;
};

export const DEFAULT_LIMIT = 20;

export const useInitParams: UseInitParams = () => {
  const [filters, setFilters] = useState<Params | null>(null);
  const [searchParams, setSearchParams] = useSearchParams({});

  const hasEmptyRequiredParam = (params: { [key: string]: string | null }) => {
    let hasEmptyParams = false;

    Object.keys(params).forEach((key: string) => {
      if (!params[key]) {
        hasEmptyParams = true;
      }
    });

    return hasEmptyParams;
  };

  const hasDifferent = (filters: Params, nextFilters: Params) => {
    if (!keysEqual(filters, nextFilters)) {
      return true;
    }

    let hasDifferent = false;

    Object.keys(nextFilters).map((key) => {
      if (nextFilters[key] !== filters[key]) {
        hasDifferent = true;
      }
    });

    return hasDifferent;
  };

  const formatDataToState = (params: SearchParamsFullfiled): Params => {
    return { ...params } as Params;
  };

  useEffect(() => {
    const from = searchParams.get("from");
    const to = searchParams.get("to");

    const nextSearchParams: SearchParamsFullfiled = {
      from: from || moment().startOf("week").toISOString(),
      to: to || moment().endOf("week").toISOString(),
    };


    if (
      hasEmptyRequiredParam({
        from,
        to,
      })
    ) {
      setSearchParams(nextSearchParams);
      return;
    }

    if (!filters) {
      setFilters(formatDataToState(nextSearchParams));
      return;
    }

    if (hasDifferent(filters, formatDataToState(nextSearchParams))) {
      setFilters(formatDataToState(nextSearchParams));
    }
  }, [searchParams]);

  return {
    filters,
    searchParams,
  };
};
