import { useSelector } from "react-redux";
import { IPayouts } from "../";
import { isFetchingSelector, payoutsDataSelector } from "../selectors";
import { useGetPayouts } from "./useGetPayouts";

export const usePayouts = (): IPayouts => {
  const { getPayouts } = useGetPayouts();
  const data = useSelector(payoutsDataSelector);
  const isFetching = useSelector(isFetchingSelector);

  return {
    getPayouts,
    data,
    isFetching,
  };
};
