import { useEffect, useState } from "react";
import { useApplication } from "data-layer/application";
import { BusinessTypes } from "common/onboarding/schema";
import { useProfile } from "data-layer/profile";
import { ProfileDTO } from "generated";
import { ApplicationSoloTraderValues, ApplicationValues } from "data-layer/application/types";
import { useEmployee } from "data-layer/employee";

type UseInitData = (isNewProfile: boolean) => {
  initData: ApplicationValues;
  initialized: boolean;
};

export const useInitData: UseInitData = (isNewProfile) => {
  const [initData, setInitData] = useState<ApplicationValues>(
    {} as ApplicationValues
  );
  const [initialized, setInitialized] = useState(false);
  const { newApplication } = useApplication();
  const { selectedProfile } = useProfile();
  const { employee } = useEmployee();

  const isTheSameAddress = (data: ApplicationValues): boolean => {
    // by default checked cuz in usePersist data is initialized
    if (

      !data.OFFICE_ADDRESS_LINE_1 &&
      !data.OFFICE_ADDRESS_LINE_2 &&
      !data.OFFICE_COUNTRY &&
      !data.OFFICE_COUNTY &&
      !data.OFFICE_CITY &&
      !data.OFFICE_POSTAL_CODE
    ) {
      return true;
    }

    if (
      data.ADDRESS_LINE_1 === data.OFFICE_ADDRESS_LINE_1 &&
      data.ADDRESS_LINE_2 === data.OFFICE_ADDRESS_LINE_2 &&
      data.CITY === data.OFFICE_CITY &&
      data.POSTAL_CODE === data.OFFICE_POSTAL_CODE &&
      data.COUNTRY === data.OFFICE_COUNTRY &&
      data.COUNTY === data.OFFICE_COUNTY
    ) {
      return true;
    }

    return false;
  };

  const isTheSameContactPerson = (data: ApplicationValues): boolean => {
    // by default checked cuz in usePersist data is initialized
    if (
      !data.PRIMARY_CONTACT_FIRST_NAME &&
      !data.PRIMARY_CONTACT_LAST_NAME &&
      !data.PRIMARY_CONTACT_PHONE_NUMBER
    ) {
      return true;
    }

    if (
      employee.user?.name === data.PRIMARY_CONTACT_FIRST_NAME &&
      employee.user?.surname === data.PRIMARY_CONTACT_LAST_NAME &&
      data.PHONE_NUMBER === data.PRIMARY_CONTACT_PHONE_NUMBER
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (!selectedProfile || isNewProfile) {
      setInitialized(true);
      return;
    }

    let data = {} as ApplicationValues;

    // if application already started
    if (newApplication) {
      data = {
        ...newApplication?.data,
      };

      data.COUNTRY = selectedProfile.countryId;
      data.sameAddress = isTheSameAddress(data);
      data.sameContactPerson = isTheSameContactPerson(data);
    }

    if (selectedProfile?.kind === ProfileDTO.kind.COMPANY) {
      data.typeOfBusiness = BusinessTypes.Company;
    }
    if (selectedProfile?.kind === ProfileDTO.kind.SOLE_TRADER) {
      data.typeOfBusiness = BusinessTypes.SoleTrader;

      if (!(data as ApplicationSoloTraderValues).PERSON_FIRST_NAME) {
        if (employee?.user?.name) {
          (data as ApplicationSoloTraderValues).PERSON_FIRST_NAME = employee.user?.name
        }
      }
  
      if (!(data as ApplicationSoloTraderValues).PERSON_LAST_NAME) {
        if (employee?.user?.surname) {
          (data as ApplicationSoloTraderValues).PERSON_LAST_NAME = employee.user?.surname
        }
      }
    }

    if (!data.EMAIL) {
      if (employee?.user?.email) {
        data.EMAIL = employee?.user?.email;
      }
    }

    if (!data.TOTAL_SALES) {
      data.TOTAL_SALES = 100;
    }

    setInitData(data);
    setInitialized(true);
  }, [newApplication?.data, isNewProfile]);

  return {
    initData,
    initialized,
  };
};
