import { combineReducers } from "@reduxjs/toolkit";
import { syncSlice } from "./syncSlice";
import { loaderSlice } from "./loaderSlice";
import { commingSoonModalSlice } from "./commingSoonModalSlice";
import { notificactionsSlice } from "./notificactionsSlice";
import { testRoleSlice } from "./testRoleSlice";

export const commonReducer = combineReducers({
  sync: syncSlice.reducer,
  loader: loaderSlice.reducer,
  commingSoonModal: commingSoonModalSlice.reducer,
  notifications: notificactionsSlice.reducer,
  testRole: testRoleSlice.reducer,
});
