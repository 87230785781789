/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AttachmentDTO } from './AttachmentDTO';
import type { ProfileDTO } from './ProfileDTO';

export type ProofPersonRequestDTO = {
    attachments?: Array<AttachmentDTO>;
    email?: string;
    id?: number;
    profile?: ProfileDTO;
    state?: ProofPersonRequestDTO.state;
};

export namespace ProofPersonRequestDTO {

    export enum state {
        APPROVED = 'APPROVED',
        NEW = 'NEW',
        REJECTED = 'REJECTED',
        REVIEWING = 'REVIEWING',
    }


}
