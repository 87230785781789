import { useEmployee } from "data-layer/employee";
import { useProfile } from "data-layer/profile";
import { ProfileDTO } from "generated";
import { OnboardingInReviewWithNotVerifiedUser } from "./components/OnboardingInReviewWithNotVerifiedUser";
import { OnboardingInReviewWithVerifiedUser } from "./components/OnboardingInReviewWithVerifiedUser";
import { OnboardingRejected } from "./components/OnboardingRejected";
import { OnboardingDocumentRequest } from "./components/OnboardingDocumentRequest"
import { AccessDeniedSc } from "scenarios/common";

export const OnboardingFinishSc: React.FC = () => {
  const { employee } = useEmployee();
  const { selectedProfile } = useProfile();

  if (selectedProfile?.status === ProfileDTO.status.UNDER_REVIEW) {
    if (employee.user?.verified) {
      return <OnboardingInReviewWithVerifiedUser />;
    } else {
      return <OnboardingInReviewWithNotVerifiedUser />;
    }
  }

  if (selectedProfile?.status === ProfileDTO.status.DOCUMENT_REQUESTED) {
    return <OnboardingDocumentRequest />;
  }

  if (selectedProfile?.status === ProfileDTO.status.REJECTED) {
    return <OnboardingRejected />;
  }

  if (selectedProfile?.status === ProfileDTO.status.BLOCKED) {
    return <AccessDeniedSc />
  }

  return null;
};
