import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { createProfileThunk } from "data-layer/profile/thunks";
import { CreateUpdateProfileDTO } from "generated/models/CreateUpdateProfileDTO";

type UseCreateProfile = () => {
  createProfile: (
    data: CreateUpdateProfileDTO & { values: any },
    cb?: VoidFunction
  ) => void;
};

export const useCreateProfile: UseCreateProfile = () => {
  const dispatch = useDispatch();

  const createProfile = useCallback(
    (data: CreateUpdateProfileDTO & { values: any }, cb?: VoidFunction) => {
      dispatch(createProfileThunk(data, cb));
    },
    [dispatch]
  );

  return {
    createProfile,
  };
};
