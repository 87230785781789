import { TransactionRestDTO } from "generated";
import { useCallback, useState } from "react";

type UseTransactionDetail = () => {
  data: TransactionRestDTO | null;
  visible: boolean;
  showDetailModal: (data: TransactionRestDTO) => void;
  hideModal: VoidFunction;
};

export const useTransactionDetail: UseTransactionDetail = () => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<TransactionRestDTO | null>(null);

  const showDetailModal = useCallback(
    (data: TransactionRestDTO) => {
      setData(data);
      setVisible(true);
    },
    [setData, setVisible]
  );

  const hideModal = useCallback(() => {
    setData(null);
    setVisible(false);
  }, [setData, setVisible]);

  return {
    data,
    visible,
    showDetailModal,
    hideModal,
  };
};
