import { appClient } from "services";
import { IDocumentRequestApi } from "./index";

export const api: IDocumentRequestApi = {
  sendToReview: (body) => {
    return appClient.documentRequestController.sendToReviewUsingPost(body);
  },

  getDocumentsByProfileToRequest: (profileId) => {
    return appClient.documentRequestController.findForProfileUsingGet(
      profileId
    );
  },

  getDocumentRequestById: (id) => {
    return appClient.documentRequestController.findByIdUsingGet(id);
  },
};
