import { PaymentLinkDTO } from "generated";
import { useCallback, useState } from "react";

type UsePaymentDetail = () => {
  data: PaymentLinkDTO | null;
  visible: boolean;
  showDetailModal: (data: PaymentLinkDTO) => void;
  hideModal: VoidFunction;
};

export const usePaymentDetail: UsePaymentDetail = () => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<PaymentLinkDTO | null>(null);

  const showDetailModal = useCallback(
    (data: PaymentLinkDTO) => {
      setData(data);
      setVisible(true);
    },
    [setData, setVisible]
  );

  const hideModal = useCallback(() => {
    setData(null);
    setVisible(false);
  }, [setData, setVisible]);

  return {
    data,
    visible,
    showDetailModal,
    hideModal,
  };
};
