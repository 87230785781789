import { Col, Row } from "antd";
import { Button, FormItem } from "components";
import { usePartner } from "data-layer/partner";
import { useState } from "react";
import { sekumpulApiPath } from "services/network/api";
import { Styled as SharedStyles } from "../../sharedStyles";
import { Styled } from "./styles";
import { Upload } from "./components/Upload";

export const General: React.FC = () => {
  const { partnerState, updatePartnerLogo } = usePartner();
  const [fileName, setFileName] = useState<string | null>(null);

  const save = () => {
    updatePartnerLogo(fileName);
  };

  return (
    <div>
      <SharedStyles.ContentTitle>General</SharedStyles.ContentTitle>
      <SharedStyles.ContentSubtitle>
        Manage account password and branding settings
      </SharedStyles.ContentSubtitle>

      <SharedStyles.Line />

      <SharedStyles.GroupTitle>Branding</SharedStyles.GroupTitle>
      <SharedStyles.GroupSubtitle>
        Add logo to platforms and emails, to show the customer
      </SharedStyles.GroupSubtitle>

      <FormItem label="Upload your logo" error={undefined}>
        <Upload
          onChange={setFileName}
          value={partnerState.data?.logoImageFilename}
        />
      </FormItem>

      <Row gutter={[30, { xs: 23, sm: 0 }]}>
        <Col xs={24} sm={12}>
          {fileName && (
            <Styled.Img src={`${sekumpulApiPath}/?fileName=${fileName}`} />
          )}
        </Col>

        <Col xs={24} sm={12}>
          <Row gutter={[0, { xs: 10, sm: 24 }]}>
            <Col span={24}>
              <Button
                type="primary-transparent"
                block
                size="large"
                disabled={true}
              >
                Send test email
              </Button>
            </Col>
            <Col span={24}>
              <Button
                type="primary-transparent"
                block
                size="large"
                onClick={save}
                disabled={partnerState.data?.logoImageFilename === fileName}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <SharedStyles.Line />
    </div>
  );
};
