import { AppDispatch } from "data-layer/store/store";
import { partnerApi } from "data-layer/partner/api";
import { loaderSlice } from "data-layer/common/loaderSlice";
import { partnerSlice } from "./slice";
import {
  InvitedUserRequestDTO,
  SettlementDetailsAttemptUpdateDTO,
  UpdatePartnerDTO,
} from "generated";
import { showSuccess } from "utils/showSuccess";
import { handleNetworkError } from "utils/handleNetworkError";
import { showWarning } from "utils/showWarning";

export const getPartnerMeThunk = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      const result = await partnerApi.api.me();

      dispatch(partnerSlice.actions.meSuccess(result));
    } catch (e) {
      throw e;
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};

export const turnoverPercentageThunk = (from: string, to: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());
    dispatch(partnerSlice.actions.turnoverPercentageRequest());

    try {
      const result = await partnerApi.api.turnoverPercentage(from, to);

      dispatch(partnerSlice.actions.turnoverPercentageSuccess(result));
    } catch (e) {
      handleNetworkError(e, "Turnover percentage partner error");
      dispatch(partnerSlice.actions.turnoverPercentageFailure());
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};

export const getStatsThunk = (from: string, to: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());
    dispatch(partnerSlice.actions.statsRequest());

    try {
      const result = await partnerApi.api.stats(from, to);

      dispatch(partnerSlice.actions.statsSuccess(result));
    } catch (e) {
      handleNetworkError(e, "Stats partner error");
      dispatch(partnerSlice.actions.statsFailure());
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};

export const inviteThunk = (data: InvitedUserRequestDTO, cb?: VoidFunction) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      await partnerApi.api.invite(data);
      showSuccess("The invitation is sent");
      if (cb && typeof cb === "function") {
        cb();
      }
    } catch (e) {
      handleNetworkError(e, "Merchant invitation error");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};

export const updateSettlementsThunk = (token: string, cb?: VoidFunction) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      const result = await partnerApi.api.updatePartnerSettlement(token);

      dispatch(partnerSlice.actions.updateMeSuccess(result));
      showSuccess("Bank account information updated");
    } catch (e) {
      handleNetworkError(e, "Update error");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
      if (cb) {
        cb();
      }
    }
  };
};

export const updatePartnerThunk = (body: UpdatePartnerDTO) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      const result = await partnerApi.api.updatePartner(body);

      dispatch(partnerSlice.actions.updatePartnerSuccess(result));
      showSuccess("Partner information updated");
    } catch (e) {
      handleNetworkError(e, "Update error");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
}

export const attemptSettlementsThunk = (
  body: SettlementDetailsAttemptUpdateDTO,
  cb?: VoidFunction
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      await partnerApi.api.attemptPartnerSettlement(body);

      if (cb) {
        cb();
      }
      showWarning(
        "Check your email to complete verification of your new bank account"
      );
    } catch (e) {
      handleNetworkError(e, "Update error");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};

export const findParterByIdThunk = (id: number) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      const result = await partnerApi.api.findPartnerById(id);
      dispatch(partnerSlice.actions.setInvitedPartnerData(result))

    } catch (e) {
      handleNetworkError(e, "Error");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
}