import { useRoutes, Outlet, Navigate } from "react-router-dom";
import {
  SupportSc,
  PasswordChangeSc,
  PasswordRecoverySc,
  StyleGuideSc,
  InitComponentSc,
  ConfirmBankDetailsSc,
} from "scenarios/common";
import {
  DashBoardSc as PartnerDashBoardSc,
  OverviewSc as PartnerOverviewSc,
  OnboardingSc as PartnerOnboardingSc,
  SyncSc as PartnerSyncSc,
  TurnoverSc,
  SignUpSc,
  MerchantsSc,
  ConfirmEmailSc,
  ConfirmedEmailSc,
  LoginSc,
  SettingsSc,
  PrivatePartnerRoute,
} from "scenarios/partner";
import { FAQ } from "scenarios/common/SupportSc/components";
import { PrivateRoute } from "./PrivateRoute";

import { UserRole } from "data-layer/employee";
import { Helmet } from "react-helmet";
import { Role } from "data-layer/common/testRoleSlice";

export const PartnerConfig = () => {
  const partnerRoutes = [
    {
      path: "sync",
      element: <PartnerSyncSc />,
    },
    {
      path: "/",
      element: <Navigate to="/dashboard" />,
    },
    {
      path: "dashboard",
      element: (
        <PrivatePartnerRoute>
          <PartnerDashBoardSc />
        </PrivatePartnerRoute>
      ),
      children: [
        {
          element: <PartnerOverviewSc />,
          index: true,
        },
        {
          path: "onboarding",
          element: (
            <>
              <Helmet>
                <title>Onboarding | Dapio</title>
              </Helmet>
              <PartnerOnboardingSc />
            </>
          ),
        },
        {
          path: "turnover",
          element: (
            <>
              <Helmet>
                <title>Turnover | Dapio</title>
              </Helmet>
              <TurnoverSc />
            </>
          ),
        },
        {
          path: "merchants",
          element: (
            <>
              <Helmet>
                <title>Merchants | Dapio</title>
              </Helmet>
              <MerchantsSc />
            </>
          ),
        },
        {
          path: "support",
          element: (
            <>
              <Helmet>
                <title>Support | Dapio</title>
              </Helmet>
              <SupportSc />
            </>
          ),
          children: [
            {
              path: "faq",
              element: <FAQ />,
            },
            // {
            //   path: "video",
            //   element: <Video />,
            // },
            // {
            //   path: "for-developers",
            //   element: <ForDevelopers />,
            // },
          ],
        },
        {
          path: "settings",
          element: (
            <>
              <Helmet>
                <title>Settings | Dapio</title>
              </Helmet>
              <SettingsSc.Wrapper />
            </>
          ),
          children: [
            {
              path: "",
              element: <Navigate to="general" />,
            },
            {
              path: "general",
              element: <SettingsSc.General />,
            },
            {
              path: "bank-details",
              element: <SettingsSc.Settlement />,
            },
          ],
        },
      ],
    },
  ];

  let config = useRoutes([
    {
      path: "/",
      element: (
        <>
          <Helmet>
            <title>Partner Dashboard | Dapio</title>
          </Helmet>
          <InitComponentSc roleAccess={UserRole.PARTNER}>
            <PrivateRoute accessRole={UserRole.PARTNER}>
              <Outlet />
            </PrivateRoute>
          </InitComponentSc>
        </>
      ),
      children: partnerRoutes,
    },
    {
      path: "/signin",
      element: (
        <>
          <Helmet>
            <title>Login | Dapio</title>
          </Helmet>
          <LoginSc />
        </>
      ),
    },
    {
      path: "/signup",
      element: (
        <>
          <Helmet>
            <title>Sign up | Dapio</title>
          </Helmet>
          <SignUpSc.Wrapper />
        </>
      ),
      children: [
        {
          element: <SignUpSc.Form />,
          index: true,
        },
        {
          path: "confirm",
          element: <SignUpSc.Confirm />,
        },
      ],
    },
    {
      path: "/confirm-email",
      element: (
        <>
          <Helmet>
            <title>Confirm email | Dapio</title>
          </Helmet>
          <ConfirmEmailSc />
        </>
      ),
    },
    {
      path: "/confirmed-email",
      element: (
        <>
          <Helmet>
            <title>Confirm email | Dapio</title>
          </Helmet>
          <ConfirmedEmailSc />
        </>
      ),
    },
    {
      path: "/password-recovery",
      element: (
        <>
          <Helmet>
            <title>Reset password | Dapio</title>
          </Helmet>
          <PasswordRecoverySc.Wrapper role={Role.Partner} />
        </>
      ),
      children: [
        {
          element: <PasswordRecoverySc.Form isPartner={true} />,
          index: true,
        },
        {
          path: "confirm",
          element: <PasswordRecoverySc.Confirm />,
        },
      ],
    },
    {
      path: "/password-reset",
      element: (
        <>
          <Helmet>
            <title>Reset password | Dapio</title>
          </Helmet>
          <PasswordChangeSc.Wrapper role={Role.Partner} />
        </>
      ),
      children: [
        {
          element: <PasswordChangeSc.Form isPartner={true} />,
          index: true,
        },
        {
          path: "confirm",
          element: <PasswordChangeSc.Confirm />,
        },
      ],
    },
    {
      path: "/style-guide",
      element: (
        <>
          <Helmet>
            <title>Style guide | Dapio</title>
          </Helmet>
          <StyleGuideSc />
        </>
      ),
    },
    {
      path: "/settlement-details-update-confirm",
      element: <ConfirmBankDetailsSc />,
    },
  ]);

  return config;
};
