interface FormatOptions extends Intl.NumberFormatOptions {}

const format = (number: string | number, options: FormatOptions): string => {
    let value = 0;
    if (typeof number === "string" && !Number.isNaN(Number(number))) {
        value = Number(number);
    } else if (typeof number === "number") {
        value = number;
    }

    try {
        return value.toLocaleString(undefined, {
            ...options,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    } catch (error) {
        console.error(error);
        return number.toString();
    }
}

interface FormatNumber {
    value: string | number
}

interface FormatMoney extends FormatNumber {
    currency?: string;
}

export const formatMoney = (money: FormatMoney): string => {
    const formatOptions: FormatOptions = {
        style: "currency",
        currency: money.currency || "GBP",
        useGrouping: true,
    };

    return format(money.value, formatOptions);
}

interface FormatPercent extends FormatNumber {}

export const formatPercent = (percent: FormatPercent): string => {
    const formatOptions: FormatOptions = {
        style: "percent",
        useGrouping: false,
    };

    return format(percent.value, formatOptions);
};

interface FormatDecimal extends FormatNumber {}

export const formatDecimal = (decimal: FormatDecimal): string => {
    const formatOptions: FormatOptions = {
        style: "decimal",
        useGrouping: false,
    };

    return format(decimal.value, formatOptions);
};