import { Col } from "antd";
import styled from "styled-components";

const Wrapper = styled.div`
  position: fixed;

  z-index: 105;

  top: 0;
  left: 0;

  display: flex;
  align-items: center;

  width: 100%;
  height: 80px;

  box-shadow: 0px 0.5px 0px #0000004D;
  background: #FFFFFF;

  @media (max-width: 1450px) {
    height: 60px;
  }
`;

const NavigatorCol = styled(Col)`
  position: static;

  @media (max-width: 1450px) {
    margin-left: 27px;
  }

  @media (max-width: 991px) {
    order: 1;
    margin-left: 0;
  }
`

const NavigatorMobileButton = styled.div`
  display: none;
  justify-content: center;
  align-items: center;

  width: 26px;
  height: 26px;

  cursor: pointer;

  @media (max-width: 991px) {
    display: flex;
  }

  @media (max-width: 575px) {
    width: 30px;
    height: 30px;
  }

  div {
    position: relative;

    width: 26px;
    height: 2px;

    border-radius: 5px;

    background-color: #000;

    &::before,
    &::after {
      content: '';

      position: absolute;

      width: 100%;
      height: 2px;

      border-radius: 5px;

      background-color: #000;
    }

    &::before {
      bottom: calc(100% + 6px);

      @media (max-width: 575px) {
        bottom: calc(100% + 6px);
      }
    }

    &::after {
      top: calc(100% + 6px);
    }
  }
`

const ControlsCol = styled(Col)`
  margin-left: auto;
`

const ProfileCol = styled(Col)`
  @media (max-width: 1450px) {
    margin-left: 5px;
  }

  @media (max-width: 991px) {
    display: none;
  }
`

export const Styled = {
  Wrapper,
  NavigatorCol,
  NavigatorMobileButton,
  ControlsCol,
  ProfileCol,
};
