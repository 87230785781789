import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Row, Col } from "antd";
import { Input } from "formik-antd";
import { Button, FormItem } from "components";
import { Styled } from "./styles";
import { useEmployee } from "data-layer/employee";
import { validateEmail } from "utils/validateEmail";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from "react";

export interface Values {
  email: string;
}

export const PasswordRecoverySchema = Yup.object().shape({
  email: Yup.string()
      .required("Required")
      .max(255, "Max length must be 255 symbols")
      .test("email", "Invalid email", (value?: string) => {
      return validateEmail(value);
    }),
});

export const initialValues = {
  email: "",
};

export const PasswordRecoveryForm: React.FC<{ isPartner?: boolean }> = (
  props
) => {
  const { resetPassword } = useEmployee();
  const recaptchaRef = useRef(null);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={PasswordRecoverySchema}
      onSubmit={async (values) => {
        const recaptchaResponse = await recaptchaRef?.current?.executeAsync();

        resetPassword(recaptchaResponse, values.email, props.isPartner);

        recaptchaRef?.current?.reset();
      }}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ handleSubmit, errors }) => (
        <Styled.FormContainer>
          <Form
            onSubmit={handleSubmit}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
          >
            <Row>
              <Col span={24}>
                <FormItem label="Email" error={errors.email}>
                  <Input name="email" placeholder="example@domain.com" />
                </FormItem>
              </Col>
            </Row>

            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
            />

            <Styled.SubmitButton>
              <Button
                block
                onClick={() => handleSubmit()}
                type="primary-transparent"
              >
                Reset password
              </Button>
            </Styled.SubmitButton>

            <Styled.Footer>
              If you're having trouble recovering your password, contact our{" "}
              <a href="mailto:help@dapio.com">support team</a>.
            </Styled.Footer>
          </Form>
        </Styled.FormContainer>
      )}
    </Formik>
  );
};
