import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";
import { StyledCss } from "../../styles";

type ActiveProp = {
  active?: boolean;
};

type OnlyActiveProp = {
  onlyActive?: boolean;
};

const Title = styled.p<ActiveProp & OnlyActiveProp>`
  ${StyledCss.Title}

  color: ${Colors.Primary};

  @media (max-width: 767px) {
    margin-bottom: ${(props) => (props.active ? "5px" : "0")};

    cursor: pointer;
  }

  &::after {
    @media (max-width: 767px) {
      content: ${(props) => (props.onlyActive ? "none" : "")};

      position: absolute;

      top: ${(props) => (props.active ? "7px" : "3px")};
      right: 5px;

      width: 12px;
      height: 12px;

      border-right: 3px solid #3c3c434d;
      border-bottom: 3px solid #3c3c434d;

      transform: ${(props) => props.active ? "rotate(-135deg)" : "rotate(45deg)"};
    }
  }
`;

const Content = styled.div<ActiveProp>`
  margin-bottom: 5px;

  @media (max-width: 767px) {
    display: ${(props) => (props.active ? "block" : "none")};
  }
`;

const Description = styled.p`
  ${StyledCss.Description}
  max-width: 330px;

  @media (max-width: 767px) {
    max-width: 310px;
  }
`;

const List = styled.ul`
  list-style-type: none;

  margin-bottom: 0;
  padding-left: 0;

  font-family: ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 22px;

  color: ${Colors.Black};

  @media (max-width: 1450px) {
    font-size: 14px;
    line-height: 1em;
  }

  li {
    padding: 11px 9px 9px;

    font-family: Nexa Regular;
    text-decoration: none;

    border-bottom: 1px solid #c4c3c6;

    @media (max-width: 1450px) {
      padding: 7px 6px 7px;
    }
  }
`;

const ListItemTitle = styled.div`
  padding-top: 5px;

  @media (max-width: 1450px) {
    padding-top: 1px;
  }
`;

type CompletedProp = {
  completed?: boolean;
};

const ListItemIcon = styled.div<CompletedProp>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;

  border-radius: 22px;

  background-color: ${(props) =>
    props.completed ? Colors.Green : Colors.Black10};
  cursor: ${(props) => (props.completed ? "auto" : "pointer")};
  pointer-events: ${(props) => (props.completed ? "none" : "inherit")};

  @media (max-width: 1450px) {
    width: 25px;
    height: 25px;
  }

  &:hover {
    background-color: ${(props) =>
      props.completed ? Colors.Green : Colors.Primary};
  }

  svg {
    color: #fff;
  }
`;

export const Styled = {
  Title,
  Description,
  Content,
  List,
  ListItemTitle,
  ListItemIcon,
};
