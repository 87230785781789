import { AppRouter } from "./router";
import { CommingSoonSc, LoaderSc } from "scenarios";
import { useEffect } from "react";
import { useSys18 } from "data-layer/sys18";

export const App: React.FC = () => {
  const { getSys18 } = useSys18();

  useEffect(() => {
    getSys18(9999999, 0)
  }, [])

  return (
    <div>
      <AppRouter />
      <LoaderSc />
      <CommingSoonSc />
    </div>
  );
};
