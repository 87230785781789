/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PaymentLinkDTO = {
    amount: number;
    created: string;
    currency?: string;
    customerName: string;
    expired?: string;
    id?: number;
    status?: PaymentLinkDTO.status;
    target?: string;
};

export namespace PaymentLinkDTO {

    export enum status {
        CREATED = 'CREATED',
        EXPIRED = 'EXPIRED',
        PAID = 'PAID',
    }


}
