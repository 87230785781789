import { NavLink } from "react-router-dom";
import { Styled } from "./styles";
import { Link } from "react-router-dom";
import { ProfileMenuSc } from "scenarios/seller/ProfileMenuSc";
import { SimpleLogo } from "ui";
import { useEmployee } from "data-layer/employee";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";

type NavigatorMobileProps = {
  onClose: VoidFunction;
  mobileMenuVisible: boolean;
};

export const NavigatorMobile: React.FC<NavigatorMobileProps> = (props) => {
  const { employee } = useEmployee();
  const { onClose, mobileMenuVisible } = props;

  return (
    <Styled.Wrapper mobileMenuVisible={mobileMenuVisible}>
      <Styled.Header>
        <Link to="/dashboard" onClick={onClose}>
          <SimpleLogo partnerId={employee?.user?.inviterPartnerId} />
        </Link>
        <Styled.NavigatorMobileCloseButton onClick={onClose} />
      </Styled.Header>

      <Styled.List>
        <Styled.Item>
          <NavLink
            end
            to="/dashboard"
            className={({ isActive }) => (isActive ? "active" : "")}
            onClick={onClose}
          >
            <Row justify="space-between">
              <Col>Overview</Col>
              <Col>
                <Styled.ItemIcon>
                  <ArrowRightOutlined />
                </Styled.ItemIcon>
              </Col>
            </Row>
          </NavLink>
        </Styled.Item>
        <Styled.Item>
          <NavLink
            to="/dashboard/reports"
            className={({ isActive }) => (isActive ? "active" : "")}
            onClick={onClose}
          >
            <Row justify="space-between">
              <Col>Transactions</Col>
              <Col>
                <Styled.ItemIcon>
                  <ArrowRightOutlined />
                </Styled.ItemIcon>
              </Col>
            </Row>
          </NavLink>
        </Styled.Item>
        {/* <Styled.Item>
          <NavLink
            to="/dashboard/payouts"
            className={({ isActive }) => (isActive ? "active" : "")}
            onClick={handleClick}
          >
            Payouts
          </NavLink>
        </Styled.Item> */}
        <Styled.Item>
          <NavLink
            to="/dashboard/employees"
            className={({ isActive }) => (isActive ? "active" : "")}
            onClick={onClose}
          >
            <Row justify="space-between">
              <Col>Employees</Col>
              <Col>
                <Styled.ItemIcon>
                  <ArrowRightOutlined />
                </Styled.ItemIcon>
              </Col>
            </Row>
          </NavLink>
        </Styled.Item>
        {/* <Styled.Item>
          <NavLink
            to="/dashboard/payment-links"
            className={({ isActive }) => (isActive ? "active" : "")}
            onClick={handleClick}
          >
            Payment links
          </NavLink>
        </Styled.Item> */}
        <Styled.Item>
          <NavLink
            to="/dashboard/support/faq"
            className={({ isActive }) => (isActive ? "active" : "")}
            onClick={onClose}
          >
            <Row justify="space-between">
              <Col>Support page</Col>
              <Col>
                <Styled.ItemIcon>
                  <ArrowRightOutlined />
                </Styled.ItemIcon>
              </Col>
            </Row>
          </NavLink>
        </Styled.Item>
      </Styled.List>

      <Styled.Profile>
        <ProfileMenuSc closeMenu={onClose} />
      </Styled.Profile>
    </Styled.Wrapper>
  );
};
