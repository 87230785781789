import { Checkbox, Col, DatePicker, Radio, Row } from "antd";
import { useHandleChangeType } from "../../hooks/useHandleChangeType";
import { DateFilters, useChangeDate } from "../../hooks/useChangeDate";
import { TransactionRestDTO } from "generated";
import moment from "moment";
import { Styled } from "./styles";
import { useCallback, useState } from "react";
import { useToggle } from "common/useToggle";
import { TransactionParams } from "data-layer/transaction/types/TransactionParams";

const typeOptions = [
  { label: "Payment", value: TransactionRestDTO.type.PAYMENT },
  { label: "Cancelation", value: TransactionRestDTO.type.CANCEL },
  { label: "Chargeback", value: TransactionRestDTO.type.CHARGEBACK },
  { label: "Refund", value: TransactionRestDTO.type.REFUND },
  { label: "Partial refund", value: TransactionRestDTO.type.PARTIAL_REFUND },
];

const timeOptions = [
  { label: "Today", value: DateFilters.Today },
  { label: "This week", value: DateFilters.This_week },
  { label: "This month", value: DateFilters.This_month },
  { label: "Last 7 days", value: DateFilters.Last_7_days },
  { label: "Last 30 days", value: DateFilters.Last_30_days },
];

type FiltersProps = {
  filters: TransactionParams;
};

export const Filters: React.FC<FiltersProps> = (props) => {
  const { filters } = props;

  const [value, setValue] = useState(null);

  const { active, handleToggle } = useToggle();

  const { handleChangeType } = useHandleChangeType();
  const { handleChangeDate, handleChangeDatePicker: _handleChangeDatePicker } =
    useChangeDate();

  const handleChangeDateGroup = useCallback(
    (e) => {
      handleChangeDate(e);
      setValue(e.target.value);
    },
    [handleChangeDate, setValue]
  );

  const handleChangeDatePicker = useCallback(
    (e) => {
      _handleChangeDatePicker(e);
      setValue(null);
    },
    [_handleChangeDatePicker, setValue]
  );

  return (
    <div style={{ position: "relative" }}>
      <Styled.HeaderMobile onClick={handleToggle} active={active}>
        Filters
      </Styled.HeaderMobile>
      <Styled.Content active={active}>
        <Row gutter={[0, { xs: 32, sm: 32, xl: 42 }]}>
          <Col span={24}>
            <Styled.Title>Show</Styled.Title>

            <Styled.FiltersRadioGroup>
              <Radio.Group
                options={timeOptions}
                value={value}
                onChange={handleChangeDateGroup}
              />
            </Styled.FiltersRadioGroup>
          </Col>

          <Col span={24}>
            <Styled.Title>Operation type</Styled.Title>

            <Styled.FiltersCheckboxGroup>
              <Checkbox.Group
                options={typeOptions}
                defaultValue={filters?.types}
                value={filters?.types}
                onChange={handleChangeType}
              />
            </Styled.FiltersCheckboxGroup>
          </Col>
          <Col span={24}>
            <Styled.Period>
              <Styled.Title>Period</Styled.Title>

              <Styled.FiltersDatepicker>
                <DatePicker.RangePicker
                  size={"small"}
                  onChange={handleChangeDatePicker}
                  format={"DD.MM.YY"}
                  value={[
                    moment(filters.from, moment.ISO_8601),
                    moment(filters.to, moment.ISO_8601),
                  ]}
                  defaultValue={[
                    moment(filters.from, moment.ISO_8601),
                    moment(filters.to, moment.ISO_8601),
                  ]}
                  clearIcon={false}
                />
              </Styled.FiltersDatepicker>
            </Styled.Period>
          </Col>
        </Row>
      </Styled.Content>
    </div>
  );
};
