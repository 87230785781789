import { PasswordRecoveryScView } from "./PasswordRecoveryScView";
import { Outlet } from "react-router-dom";
import { PasswordRecoveryForm } from "./PasswordRecoveryForm";
import { PasswordRecoveryConfirm } from "./PasswordRecoveryConfirm";
import { Role } from "data-layer/common/testRoleSlice";

const PasswordRecoveryWrapper: React.FC<{ role: Role }> = (props) => {
  return (
    <PasswordRecoveryScView role={props.role}>
      <Outlet />
    </PasswordRecoveryScView>
  );
};

export const PasswordRecoverySc = {
  Wrapper: PasswordRecoveryWrapper,
  Form: PasswordRecoveryForm,
  Confirm: PasswordRecoveryConfirm,
};
