import {
  BusinessOwnerValues,
  OwnerTypeEnum,
} from "data-layer/application/types";
import { AppInputs } from "generated/modelsWrapper/AppInputs";

export const initialOwnerData: BusinessOwnerValues = {
  [AppInputs.PERSON_STATUS]: [OwnerTypeEnum.UltimateOwner],
  [AppInputs.PERSON_FIRST_NAME]: "",
  [AppInputs.PERSON_LAST_NAME]: "",
  [AppInputs.PERSON_DOB]: "",
  [AppInputs.PERSON_EMAIL]: "",
  [AppInputs.PERSON_PHONE_NUMBER]: "",
  [AppInputs.PERSON_ADDRESS_LINE_1]: "",
  [AppInputs.PERSON_CITY]: "",
  [AppInputs.PERSON_POSTAL_CODE]: "",
  [AppInputs.PERSON_AUTHORIZED]: "",
  [AppInputs.PERSON_NATIONALITY]: "",
  [AppInputs.PERSON_OWNERSHIP]: 0,
};
