import { ICompany } from '../types';
import { useGetCompany } from './useGetCompany';
import { useSearch } from './useSearch';

export const useCompany: ICompany = () => {
  const { searchCompany } = useSearch();
  const { getCompany } = useGetCompany();

  return {
    searchCompany,
    getCompany,
  };
};
