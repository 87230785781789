import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

const StepsRow = styled.div`
  display: flex;

  height: 100vh;

  @media (max-width: 991px) {
    flex-direction: column;
    height: auto;
  }
`;

const StepsCol = styled.div`
  width: 440px;

  margin-bottom: 0px;

  padding-top: 155px;
  padding-right: 45px;

  @media (max-width: 1550px) {
    width: 250px;
  }

  @media (max-width: 1199px) {
    padding-top: 120px;
  }

  @media (max-width: 991px) {
    display: none;
  }
`;

const MobileSteps = styled.div`
  display: none;
  align-items: center;

  height: 105px;
  margin-bottom: 30px;
  padding-top: 60px;

  font-family: ${Fonts.NexaRegular};
  font-size: 14px;
  line-height: 100%;

  border-bottom: 1px solid ${Colors.LightGray};

  color: #000;

  @media (max-width: 991px) {
    display: flex;
  }
`;

const Title = styled.p<{ margin?: string }>`
  margin-bottom: ${(props) => (props.margin === "large" ? 45 : 30)}px;

  font-family: ${Fonts.NexaRegular};
  font-size: 25px;
  line-height: 120%;
  text-align: center;

  color: ${Colors.Secondary};

  @media (max-width: 1199px) {
    margin-bottom: ${(props) => (props.margin === "large" ? 35 : 30)}px;
    font-size: 20px;
    line-height: 125%;
  }
`;

const Content = styled.div<{ margin?: string }>`
  margin-bottom: ${(props) => (props.margin === "small" ? 19 : 45)}px;

  @media (max-width: 1199px) {
    margin-bottom: ${(props) => (props.margin === "small" ? 20 : 25)}px;
  }

  @media (max-width: 767px) {
    margin-bottom: 0;
  }
`;

const Footer = styled.div`
  width: 100%;

  > .ant-row {
    flex-grow: 1;
  }

  @media (max-width: 767px) {
    position: fixed;

    bottom: 0;
    left: 0;

    display: flex;
    align-items: center;

    width: 100%;
    height: 80px;

    padding: 0 20px;

    background-color: #fff;
    box-shadow: 0px -0.5px 0px #0000004d;

    @media (max-width: 575px) {
      padding: 0 15px;
    }
  }
`;

const ContentCol = styled.div`
  flex-grow: 1;

  height: 100%;

  padding: 136px 440px 60px 0;

  overflow: auto;

  @media (max-width: 1550px) {
    padding: 136px 250px 60px 0;
  }

  @media (max-width: 1199px) {
    padding: 106px 100px 60px 0;
  }

  @media (max-width: 991px) {
    height: auto;
    padding: 0 0 100px;
    overflow: visible;
  }
`;

const ContentColInner = styled.div`
  max-width: 571px;
  margin: 0 auto;

  @media (max-width: 1550px) {
    max-width: 540px;
  }

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const Styled = {
  StepsRow,
  StepsCol,
  MobileSteps,

  ContentCol,
  ContentColInner,

  Footer,

  Title,
  Content,
};
