import { Tabs } from "antd";
import { Modal } from "components";
import { Styled } from "./styles";
import { LinkOutlined } from "@ant-design/icons";
import { CreateIndividualLink } from "./CreateIndividualLink";
import { CreateCompanyLink } from "./CreateCompanyLink";
import { PaymentLinkDTO } from "generated";

const { TabPane } = Tabs;

type CreateLinkModalProps = {
  visible: boolean;
  onClose: VoidFunction;
  onCreate: (data: PaymentLinkDTO) => void;
};

export const CreateLinkModal: React.FC<CreateLinkModalProps> = (props) => {
  const { visible, onClose, onCreate } = props;

  return (
    <Modal visible={visible} onCancel={onClose} footer={null}>
      <Styled.Wrapper>
        <Styled.Icon>
          <Styled.Svg>
            <LinkOutlined />
          </Styled.Svg>
        </Styled.Icon>
        <Styled.Title>Create new payment link</Styled.Title>
        <Tabs
          defaultActiveKey="0"
          className="button-tabs button-tabs--large"
          style={{ margin: "0 auto" }}
        >
          <TabPane tab="Individual" key="0">
            <CreateIndividualLink onCreate={onCreate} />
          </TabPane>
          <TabPane tab="Company" key="1">
            <CreateCompanyLink onCreate={onCreate} />
          </TabPane>
        </Tabs>
      </Styled.Wrapper>
    </Modal>
  );
};
