import styled from "styled-components";

type PageProps = {
  active?: boolean;
};

type ButtonProps = {
  disabled?: boolean;
};

const Wrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 10px;
`;

const Block = `
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin: 0 5px;
  border: 1px solid transparent;
  background-color: #fff;
  border-radius: 5px;
`;

const Button = `
  ${Block};

  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: calc(50% - 4px);
    width: 8px;
    height: 8px;
    border-bottom: 2px solid #1d1d1f;
  }
`;

const Page = styled.div<PageProps>`
  ${Block};

  border: ${(props) => (props.active ? "1px solid #1d1d1f" : "none")};
  cursor: ${(props) => (props.active ? "auto" : "pointer")};
`;

const Dots = styled.div`
  ${Block};
`;

const PrevButton = styled.button<ButtonProps>`
  ${Button};

  cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  &::after {
    left: calc(50% - 2px);
    border-left: 2px solid #1d1d1f;
    transform: rotate(45deg);
  }
`;

const NextButton = styled.button<ButtonProps>`
  ${Button};

  cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  &::after {
    left: calc(50% - 4px);
    border-right: 2px solid #1d1d1f;
    transform: rotate(-45deg);
  }
`;

export const Styled = {
  Wrapper,
  Page,
  Dots,
  PrevButton,
  NextButton,
};
