import { Col, DatePicker, Row } from "antd";
import { useChangeDate } from "../../hooks/useChangeDate";
import { Styled } from "./styles";
import { useToggle } from "common/useToggle";
import { CreateLink } from "../CreateLink";
import { PaymentsParams } from "data-layer/payments/types/PaymentsParams";
import { SearchByCustomerName } from "./components/SearchByCustomerName";
import { SearchByAmount } from "./components/SearchByAmount/SearchByAmount";
import moment from "moment";

type FiltersProps = {
  filters: PaymentsParams;
};

export const Filters: React.FC<FiltersProps> = (props) => {
  const { filters } = props;

  const { active, handleToggle } = useToggle();

  const { handleChangeDatePicker } = useChangeDate();

  console.log("filtlers", filters);

  return (
    <div>
      <Styled.HeaderMobile onClick={handleToggle} active={active}>
        Show Filters
      </Styled.HeaderMobile>
      <Styled.Content active={active}>
        <CreateLink />
        <Styled.SearchLabel>Search</Styled.SearchLabel>
        <Row gutter={[0, { xs: 25, sm: 25, lg: 42 }]}>
          <Col span={24}>
            <Styled.Title>Company or Individual name</Styled.Title>
            <SearchByCustomerName />
          </Col>

          <Col span={24}>
            <Styled.Title>Amount</Styled.Title>
            <SearchByAmount />
          </Col>
          <Col span={24}>
            <Styled.Period>
              <Styled.Title>Creation date</Styled.Title>

              <Styled.FiltersDatepicker selected={filters.from && filters.to ? true : false}>
                <DatePicker.RangePicker
                  size={"small"}
                  onChange={handleChangeDatePicker}
                  format={"DD.MM.YY"}
                  value={[
                    moment(filters.from, moment.ISO_8601),
                    moment(filters.to, moment.ISO_8601),
                  ]}
                  defaultValue={[
                    moment(filters.from, moment.ISO_8601),
                    moment(filters.to, moment.ISO_8601),
                  ]}
                  clearIcon={false}
                />
              </Styled.FiltersDatepicker>
            </Styled.Period>
          </Col>
        </Row>
      </Styled.Content>
    </div>
  );
};
