import { NotifcationData, NotificationType } from "data-layer/notifications/getNotificationData";
import { formatTime } from "./formatTime";
import { Styled } from "./styles";

type NotificationSimpleProps = NotifcationData;

export const NotificationSimple: React.FC<NotificationSimpleProps> = (props) => {
  const { title, description, created, type, action } = props;

  return (
    <Styled.Wrapper
      type={type || NotificationType.Default}
      readed={true}
    >
      <Styled.Icon type={type || NotificationType.Default} />
      <Styled.Content>
        <Styled.Header>
          {title && <Styled.Title>{title}</Styled.Title>}
          {created && (
            <Styled.Time>
              {formatTime(+new Date() - +new Date(created))}
            </Styled.Time>
          )}
        </Styled.Header>

        {description && <Styled.Description>{description}</Styled.Description>}

        {action && (
          <Styled.Action onClick={action.cb}>{action.title}</Styled.Action>
        )}
      </Styled.Content>
    </Styled.Wrapper>
  );
};
