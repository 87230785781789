import { useState, useEffect } from "react";
import { useApplication } from "data-layer/application";
import { ProfileDTO } from "generated";
import { useProfile } from "data-layer/profile";
import {
  businessActivitiesSchema,
  businessOwnersSchema,
  companyDescriptionSchema,
  personalDetailsSchema,
  soleTraderDescriptionSchema,
  typeOfBusinessSchema,
} from "common/onboarding/schema";
import {
  ApplicationCompanyValues,
  ApplicationValues,
} from "data-layer/application/types";
import {
  identityVerificationCompanySchema,
  identityVerificationSoloTraderSchema,
} from "common/onboarding/schema/identityVerificationSchema";

export enum SoloTraderSteps {
  BusinessTypeStep = 0,
  DescriptionStep = 1,
  BusinessActivitesStep = 2,
  PersonalDetailsStep = 3,
  IdentityVerificationStep = 4,
  ReviewStep = 5,
}

export enum CompanySteps {
  BusinessTypeStep = 0,
  DescriptionStep = 1,
  BusinessActivitesStep = 2,
  BusinessOwnersStep = 3,
  IdentityVerificationStep = 4,
  ReviewStep = 5,
}

type UseSteps = (
  initData: ApplicationValues,
  initialized: boolean,
  isNewProfile: boolean,
  contentRef: React.MutableRefObject<HTMLDivElement | null>
) => {
  current: number;
  prevStep: VoidFunction;
  nextStep: VoidFunction;
  changeStep: (index: number) => void;
};

export const useSteps: UseSteps = (
  initData,
  initialized,
  isNewProfile,
  contentRef,
) => {
  const [current, setCurrent] = useState<number>(0);
  const { newApplication } = useApplication();
  const { selectedProfile } = useProfile();

  useEffect(() => {
    if (contentRef && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [current]);

  useEffect(() => {
    async function getCurrentStep() {
      let indexOfStep = current;

      if (selectedProfile?.kind === ProfileDTO.kind.COMPANY) {
        try {
          typeOfBusinessSchema.validate(initData, { strict: true });
          indexOfStep = CompanySteps.DescriptionStep;

          await companyDescriptionSchema.validate(initData, {
            strict: true,
          });
          indexOfStep = CompanySteps.BusinessActivitesStep;

          await businessActivitiesSchema.validate(initData, {
            strict: false,
          });

          indexOfStep = CompanySteps.BusinessOwnersStep;

          if ((initData as ApplicationCompanyValues).PERSONS?.length) {
            await businessOwnersSchema.validate(initData, {});
            indexOfStep = CompanySteps.IdentityVerificationStep;

            await identityVerificationCompanySchema.validate(initData, {});
            indexOfStep = CompanySteps.ReviewStep;
          }
        } catch (e) {
          console.log("validate forms e", e);
        } finally {
          setCurrent(indexOfStep);
        }
      }

      if (selectedProfile?.kind === ProfileDTO.kind.SOLE_TRADER) {
        try {
          typeOfBusinessSchema.validate(initData, { strict: true });
          indexOfStep = SoloTraderSteps.DescriptionStep;

          await soleTraderDescriptionSchema.validate(initData, {
            strict: true,
          });
          indexOfStep = SoloTraderSteps.BusinessActivitesStep;

          await businessActivitiesSchema.validate(initData, {
            strict: false,
          });

          indexOfStep = SoloTraderSteps.PersonalDetailsStep;

          await personalDetailsSchema.validate(initData, {
            strict: true,
          });
          indexOfStep = SoloTraderSteps.IdentityVerificationStep;

          await identityVerificationSoloTraderSchema.validate(initData, {});
          indexOfStep = CompanySteps.ReviewStep;
        } catch (e) {
        } finally {
          setCurrent(indexOfStep);
        }
      }
    }

    if (!newApplication || !initialized || isNewProfile) {
      return;
    }

    getCurrentStep();
  }, [initialized]);

  const prevStep = () => {
    if (current === 0) {
      return;
    }

    setCurrent(current! - 1);
  };

  const nextStep = () => {
    setCurrent(current! + 1);
  };

  const changeStep = (index: number) => {
    setCurrent(index);
  };

  return {
    current,
    prevStep,
    nextStep,
    changeStep,
  };
};
