/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MerchantAddressData } from './MerchantAddressData';
import type { PartnerDTO } from './PartnerDTO';
import type { ProfileDTO } from './ProfileDTO';
import type { SettlementDetailsData } from './SettlementDetailsData';
import type { UserDTO } from './UserDTO';
import type { MerchantDocumentsData } from "./MerchantDocumentsData";

export type MerchantDTO = {
    bankAccountHolderName: string;
    bankAccountNumber?: string;
    cctExternalId?: string;
    cdxExternalId: string;
    country: string;
    currency: string;
    email: string;
    entityOriginType?: MerchantDTO.entityOriginType;
    feePercent: number;
    iban?: string;
    id: number;
    kind: MerchantDTO.kind;
    mcc: string;
    merchantName: string;
    dba: string;
    merchantNumber?: string;
    mid: string;
    officeAddressData: MerchantAddressData;
    owner?: UserDTO;
    partner?: PartnerDTO;
    phoneNumber: string;
    profile: ProfileDTO;
    registrationAddressData: MerchantAddressData;
    settlementDetailsData?: SettlementDetailsData;
    sortCode?: string;
    status: MerchantDTO.status;
    tidPrefix: string;
    timeZone: string;
    merchantDocumentsData?: MerchantDocumentsData
};

export namespace MerchantDTO {

    export enum entityOriginType {
        EXTERNAL = 'EXTERNAL',
        INTERNAL = 'INTERNAL',
    }

    export enum kind {
        COMPANY = 'COMPANY',
        SOLE_TRADER = 'SOLE_TRADER',
    }

    export enum status {
        ACTIVATED = 'ACTIVATED',
        NEW = 'NEW',
        SUSPENDED = 'SUSPENDED',
    }


}
