import { CreateEmployeeDTO } from "generated";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { createEmployeeThunk } from "../thunks";

type UseCreateEmployee = () => {
  createEmployee: (body: CreateEmployeeDTO, cb?: VoidFunction) => void;
};

export const useCreateEmployee: UseCreateEmployee = () => {
  const dispatch = useDispatch();

  const createEmployee = useCallback(
    (body, cb) => {
      dispatch(createEmployeeThunk(body, cb));
    },
    [dispatch]
  );

  return {
    createEmployee,
  };
};
