import { BusinessTypes } from "common/onboarding/schema";
import { useApplication } from "data-layer/application";
import { TypeOfBusinessValues } from "data-layer/application/types";
import { ApplicationInputDTO } from "generated";
import { excludeCompanyValues, excludeSoloTraderValues } from "./utils";

type UsePersist = () => {
  persist: (values: TypeOfBusinessValues, nextStep: VoidFunction) => void;
};

export const usePersist: UsePersist = () => {
  const { persistApplication, newApplication } = useApplication();

  const persist = (values: TypeOfBusinessValues, nextStep: VoidFunction) => {
    let dataToSend: ApplicationInputDTO = {} as ApplicationInputDTO;

    if (newApplication) {
      dataToSend.id = newApplication.id;
      dataToSend.fields = {
        ...newApplication.data,
      };
    }

    let formData = {};

    switch (values.typeOfBusiness) {
      case BusinessTypes.Company: {
        formData = excludeCompanyValues(values);
        break;
      }

      case BusinessTypes.SoleTrader: {
        formData = excludeSoloTraderValues(values);
        break;
      }

      default: {
      }
    }

    dataToSend.fields = {
      ...dataToSend.fields,
      ...formData,
    };

    persistApplication(dataToSend, nextStep);
  };

  return {
    persist,
  };
};
