import { Checkbox, Col, DatePicker, Row } from "antd";
import { UserAddOutlined } from "@ant-design/icons";

import { useHandleChangeType } from "../../hooks/useHandleChangeType";
import { useChangeDate } from "../../hooks/useChangeDate";

import moment from "moment";

import { Styled } from "./styles";
import { useState } from "react";
import { SubmitMerchantModalSc } from "scenarios/partner/SubmitMerchantModalSc";
import { ApplicationDTO } from "generated";
import { useToggle } from "common/useToggle";
import { ParterProfileParams } from "data-layer/profile/types/ParterProfileParams";

const checkboxOptions = [
  {
    label: "Approved",
    value: ApplicationDTO.state.APPROVED,
  },
  {
    label: "Changes requested",
    value: ApplicationDTO.state.CHANGES_REQUESTED,
  },
  { label: "New", value: ApplicationDTO.state.NEW },
  {
    label: "Rejected",
    value: ApplicationDTO.state.REJECTED,
  },
  {
    label: "Reviewing",
    value: ApplicationDTO.state.REVIEWING,
  },
];

type FiltersProps = {
  filters: ParterProfileParams;
};

export const Filters: React.FC<FiltersProps> = (props) => {
  const { filters } = props;

  const { active, handleToggle } = useToggle();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const { handleChangeType } = useHandleChangeType();
  const { handleChangeDatePicker } = useChangeDate();

  return (
    <div style={{ position: "relative" }}>
      <Styled.HeaderMobile onClick={handleToggle} active={active}>
        Filters
      </Styled.HeaderMobile>
      <Styled.Content active={active}>
        <Row gutter={[0, { xs: 32, sm: 32, xl: 42 }]}>
          <Col span={24}>
            <Styled.AddButton
              onClick={() => setIsModalVisible(!isModalVisible)}
            >
              <span>Submit a merchant</span>
              <UserAddOutlined />
            </Styled.AddButton>
            <SubmitMerchantModalSc
              visible={isModalVisible}
              onHide={() => setIsModalVisible(false)}
            />
          </Col>

          <Col span={24}>
            <Styled.Title>Show</Styled.Title>

            <Styled.FiltersCheckboxGroup>
              <Checkbox.Group
                options={checkboxOptions}
                defaultValue={filters?.states}
                value={filters?.states}
                onChange={handleChangeType}
              />
            </Styled.FiltersCheckboxGroup>
          </Col>
          <Col span={24}>
            <Styled.Period>
              <Styled.Title>Period</Styled.Title>

              <Styled.FiltersDatepicker>
                <DatePicker.RangePicker
                  size={"small"}
                  onChange={handleChangeDatePicker}
                  format={"DD.MM.YY"}
                  value={[
                    moment(filters.from, moment.ISO_8601),
                    moment(filters.to, moment.ISO_8601),
                  ]}
                  defaultValue={[
                    moment(filters.from, moment.ISO_8601),
                    moment(filters.to, moment.ISO_8601),
                  ]}
                  clearIcon={false}
                />
              </Styled.FiltersDatepicker>
            </Styled.Period>
          </Col>
        </Row>
      </Styled.Content>
    </div>
  );
};
