/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PartnerDTO } from './PartnerDTO';
import type { UserDTO } from './UserDTO';

export type ProfileDTO = {
    countryId?: number;
    created?: string;
    emailVerified?: boolean;
    id?: number;
    kind?: ProfileDTO.kind;
    managerName?: string;
    name?: string;
    owner?: UserDTO;
    partner?: PartnerDTO;
    selected?: boolean;
    status?: ProfileDTO.status;
};

export namespace ProfileDTO {

    export enum kind {
        COMPANY = 'COMPANY',
        SOLE_TRADER = 'SOLE_TRADER',
    }

    export enum status {
        APPROVED = 'APPROVED',
        BLOCKED = 'BLOCKED',
        DOCUMENT_REQUESTED = 'DOCUMENT_REQUESTED',
        NOT_FINISHED = 'NOT_FINISHED',
        NOT_STARTED = 'NOT_STARTED',
        REJECTED = 'REJECTED',
        UNDER_REVIEW = 'UNDER_REVIEW',
    }


}
