import { useCallback, useState } from "react";

type UseMobileModal = () => {
  visible: boolean;
  open: VoidFunction;
  close: VoidFunction;
  handleToggle: () => void;
};

export const useMobileModal: UseMobileModal = () => {
  const [visible, setVisible] = useState(false);

  const open = useCallback(() => {
    setVisible(true);
  }, [setVisible]);

  const close = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const handleToggle = () => {
    setVisible(!visible);
  };

  return {
    visible,
    open,
    close,
    handleToggle,
  };
};
