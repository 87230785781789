import React from "react";
import { Outlet, NavLink } from "react-router-dom";
import { Settlement } from "./components/Settlement";
import { Styled } from "./styles";
import { history } from "data-layer/../router/AppRouter";
import { useToggle } from "common/useToggle";
import { General } from "./components/General";

export const Wrapper: React.FC = () => {
  const { active, handleToggle, handleClose } = useToggle();

  return (
    <Styled.Container className="container">
      <Styled.Wrapper>
        <Styled.Filters active={active}>
          <Styled.Title active={active} onClick={handleToggle}>
            Settings
          </Styled.Title>
          <Styled.List active={active}>
            <Styled.Link
              to="general"
              className={({ isActive }) => (isActive ? "active" : "")}
              onClick={handleClose}
            >
              General
            </Styled.Link>
            <Styled.Link
              to="bank-details"
              className={({ isActive }) => (isActive ? "active" : "")}
              onClick={handleClose}
            >
              Bank details
            </Styled.Link>
          </Styled.List>
        </Styled.Filters>

        <Styled.Content>
          <Outlet />
        </Styled.Content>
      </Styled.Wrapper>
    </Styled.Container>
  );
};

export const SettingsSc = {
  Wrapper,
  General,
  Settlement,
};
