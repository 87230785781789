import React from "react";
import { Paper } from "components";
import { Styled } from "./styles";

export const ComingSoon: React.FC = () => {
  return (
    <Styled.ComingSoonWrapper>
      <Paper
        style={{
          height: "100%",
          border: "2px solid #E5E5E5",
          backgroundColor: "#fff",
          boxShadow: 'none'
        }}
      >
        <Styled.Title>Coming soon</Styled.Title>
      </Paper>
    </Styled.ComingSoonWrapper>
  );
};
