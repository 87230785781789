import { NavLink } from "react-router-dom";
import { Styled } from "./styles";

export const Navigator: React.FC = () => {
  return (
    <Styled.List>
      <Styled.Item>
        <NavLink
          end
          to="/dashboard"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          Overview
        </NavLink>
      </Styled.Item>
      <Styled.Item>
        <NavLink
          to="/dashboard/reports"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          Transactions
        </NavLink>
      </Styled.Item>
      {/* <Styled.Item>
        <NavLink
          to="/dashboard/payouts"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          Payouts
        </NavLink>
      </Styled.Item> */}
      <Styled.Item>
        <NavLink
          to="/dashboard/employees"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          Employees
        </NavLink>
      </Styled.Item>
      {/* <Styled.Item>
        <NavLink
          to="/dashboard/payment-links"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          Payment links
        </NavLink>
      </Styled.Item> */}
    </Styled.List>
  );
};
