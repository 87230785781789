import { Col, Popover as AntdPopover } from "antd";
import styled from "styled-components";
import { Fonts } from "styles/fonts";

const Popover = styled(AntdPopover)`
  background: red;
`

const Link = styled.div`
  display: inline-flex;

  margin-bottom: 30px;

  font-family: ${Fonts.NexaRegular};
  font-size: 20px;
  line-height: 150%;
  text-decoration: underline;

  color: #283549;
`;

const Subtitle = styled.div`
  margin-bottom: 20px;

  font-family: ${Fonts.NexaRegular};
  font-size: 20px;
  line-height: 125%;

  color: #283549;
`;

const List = styled.div`
  margin-bottom: 52px;

  font-family: ${Fonts.NexaRegular};
  font-size: 20px;
  line-height: 175%;

  color: #8096a7;
`;

const ListItem = styled.div``;

const Group = styled.div`
  margin-bottom: 22px;
  border: 2px solid #78788033;
  border-radius: 16px;
`;

const GroupHeader = styled.div`
  padding: 20px 25px;
  border-bottom: 2px solid #78788033;
`;

const GroupTitle = styled.div`
  font-family: ${Fonts.NexaRegular};
  font-size: 20px;
  line-height: 100%;

  color: #283549;
`;

const GroupSubtitle = styled.div`
  font-family: ${Fonts.NexaRegular};
  font-size: 16px;
  line-heigh: 100%;

  color: #8096a7;
`;

const GroupContent = styled.div`
  padding-bottom: 1px;
`;

const GroupContentItem = styled(Col)`
  padding: 25px;
  border-bottom: 2px solid #78788033;

  &:last-child {
    border-bottom: none;
  }
`;

// hint

const hintWrapper = styled.div`
  max-width: 400px;
  border-radius: 13px;
  background: #f9f9f9;
  box-shadow: 0px 10px 99px #0000004d;
`;

const hintHeader = styled.div`
  display: flex;
  align-items: center;
  height: 55px;
  padding: 0 25px;

  font-family: ${Fonts.NexaRegular};
  font-size: 20px;
  line-height: 29px;

  background-color: #f0f2f4;
  color: #000000;
`;

const hintContent = styled.div`
  padding: 20px 25px 25px;
`;

const hintContentGroup = styled.div`
  padding: 10px 0;
  border-bottom: 2px solid #f0f2f4;

  &:last-child {
    border-bottom: none;
  }
`;

const hintContentGroupTitle = styled.div`
  margin-bottom: 4px;

  font-family: ${Fonts.NexaRegular};
  font-size: 16px;
  line-height: 23px;

  color: #fe8e75;
`;

const hintContentGroupText = styled.div`
  font-family: ${Fonts.NexaRegular};
  font-size: 16px;
  line-height: 25px;

  color: #000000;
`;

export const Styled = {
  Popover,

  Link,
  Subtitle,
  List,
  ListItem,

  Group,
  GroupHeader,
  GroupTitle,
  GroupSubtitle,
  GroupContent,
  GroupContentItem,

  hintWrapper,
  hintHeader,
  hintContent,
  hintContentGroup,
  hintContentGroupTitle,
  hintContentGroupText,
};
