import { PaymentLinkDetailModal } from "./components/PaymentLinkDetailModal/PaymentLinkDetailModal";
import { PaymentLinkDTO } from "generated";

type PaymentDetaillProps = {
  data: PaymentLinkDTO | null;
  visible: boolean;
  hideModal: VoidFunction;
};

export const PaymentDetail: React.FC<PaymentDetaillProps> = (props) => {
  const { visible, hideModal, data } = props;

  if (data === null) {
    return null;
  }

  return (
    <PaymentLinkDetailModal
      visible={visible}
      hideModal={hideModal}
      data={data}
    />
  );
};
