import { Formik } from "formik";
import React, { useState } from "react";
import { Styled as OnboardingStyled } from "../../onboardingStyles";
import { Styled } from "./styles";
import { Input } from "formik-antd";
import { AppInputs } from "generated/modelsWrapper/AppInputs";
import { OnboardingFormItem } from "../OnboardingFormItem";
import { typeOfBusinessSchema, BusinessTypes } from "common/onboarding/schema";
import { useSubmit } from "./useSubmit";
import { NextStepProp, StepComponentProps } from "../../OnboardingSc";
import { getFormikError } from "utils/getFormikError";
import { Button, FormikPatchTouched } from "components";
import { Col, Row } from "antd";
import { CompanySearch } from "../CompanySearch";
import { useEmployee } from "data-layer/employee";
import { ExtraData } from "data-layer/employee/types";

enum Steps {
  First = "First",
  Second = "Second",
}

export const BusinessTypeStep: React.FC<StepComponentProps & NextStepProp> = (
  props
) => {
  const [step, setStep] = useState(Steps.First);

  const { handleSubmit } = useSubmit();
  const { initData, nextStep } = props;
  const { employee } = useEmployee();

  return (
    <div>
      <Formik
        initialValues={{
          ...initData,
          COMPANY_NAME:
            initData.COMPANY_NAME ||
            (employee.user?.extraData as ExtraData)?.merchantName ||
            "",
        }}
        validationSchema={typeOfBusinessSchema}
        validateOnChange={true}
        enableReinitialize
        onSubmit={(values) => {
          handleSubmit(values, nextStep);
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleSubmit,
          isValid,
        }) => {
          return (
            <>
              <FormikPatchTouched />

              {step === Steps.First && (
                <>
                  <OnboardingStyled.Title margin="large">
                    What type of business is it?
                  </OnboardingStyled.Title>

                  <OnboardingStyled.Content>
                    <Styled.CheckboxItem>
                      <input
                        name="typeOfBusiness"
                        id={BusinessTypes.Company}
                        type="radio"
                        value={BusinessTypes.Company}
                        checked={
                          values.typeOfBusiness === BusinessTypes.Company
                        }
                        onChange={() => {
                          setFieldValue(
                            "typeOfBusiness",
                            BusinessTypes.Company
                          );
                        }}
                      />
                      <label htmlFor={BusinessTypes.Company}>
                        Limited Company
                      </label>
                    </Styled.CheckboxItem>

                    <Styled.CheckboxItem>
                      <input
                        name="typeOfBusiness"
                        id={BusinessTypes.SoleTrader}
                        type="radio"
                        value={BusinessTypes.SoleTrader}
                        checked={
                          values.typeOfBusiness === BusinessTypes.SoleTrader
                        }
                        onChange={() => {
                          setFieldValue(
                            "typeOfBusiness",
                            BusinessTypes.SoleTrader
                          );
                        }}
                      />
                      <label htmlFor={BusinessTypes.SoleTrader}>
                        Sole Trader
                      </label>
                    </Styled.CheckboxItem>
                  </OnboardingStyled.Content>

                  <OnboardingStyled.Footer>
                    <Button
                      type="primary-transparent"
                      onClick={() => setStep(Steps.Second)}
                      disabled={!values.typeOfBusiness}
                      block
                    >
                      Next
                    </Button>
                  </OnboardingStyled.Footer>
                </>
              )}

              {step === Steps.Second && (
                <>
                  <OnboardingStyled.Title>
                    Enter the company name, number or{" "}
                    <span style={{ whiteSpace: "nowrap" }}>officer name</span>
                  </OnboardingStyled.Title>

                  <OnboardingStyled.Content margin="small">
                    {values.typeOfBusiness === BusinessTypes.Company && (
                      <OnboardingFormItem
                        label="Enter the company name, number or officer name"
                        error={getFormikError(
                          AppInputs.COMPANY_NAME,
                          errors,
                          touched
                        )}
                      >
                        <CompanySearch
                          setFieldValue={setFieldValue}
                          defaultValue={values.COMPANY_NAME}
                        />
                      </OnboardingFormItem>
                    )}

                    {values.typeOfBusiness === BusinessTypes.SoleTrader && (
                      <OnboardingFormItem
                        label="Full Legal Name"
                        error={getFormikError(
                          AppInputs.COMPANY_NAME,
                          errors,
                          touched
                        )}
                      >
                        <Input name={AppInputs.COMPANY_NAME} />
                      </OnboardingFormItem>
                    )}
                  </OnboardingStyled.Content>

                  <OnboardingStyled.Footer>
                    <Row gutter={{ xs: 10, sm: 10, md: 25 }}>
                      <Col>
                        <Button
                          type="secondary-transparent"
                          onClick={() => setStep(Steps.First)}
                        >
                          Back
                        </Button>
                      </Col>
                      <Col flex={1}>
                        <Button
                          type="primary-transparent"
                          onClick={() => handleSubmit()}
                          disabled={!isValid}
                          block
                        >
                          Next
                        </Button>
                      </Col>
                    </Row>
                  </OnboardingStyled.Footer>
                </>
              )}
            </>
          );
        }}
      </Formik>
    </div>
  );
};
