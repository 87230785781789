import { BusinessTypes } from "common/onboarding/schema";
import { ApplicationDTO } from "generated/models/ApplicationDTO";
import { AppInputs } from "generated/modelsWrapper/AppInputs";

export enum ExpectedTurnover {
  SMALL = "SMALL",
  MIDDLE = "MIDDLE",
  LARGE = "LARGE",
  EXTRA_LARGE = "EXTRA_LARGE",
}

export enum PaymentHistory {
  NONE = "NONE",
  SMALL = "SMALL",
  MIDDLE = "MIDDLE",
  LARGE = "LARGE",
  EXTRA_LARGE = "EXTRA_LARGE",
}

export enum AverageTxValue {
  SMALL = "SMALL",
  MIDDLE = "MIDDLE",
  LARGE = "LARGE",
}

export enum ExpectedTurnoverMap {
  SMALL = "Less than £10,000",
  MIDDLE = "£10,000 - £25,000",
  LARGE = "£25,000 - £50,000",
  EXTRA_LARGE = "More than £50,000",
}

export enum PaymentHistoryMap {
  NONE = "I’m new to this",
  SMALL = "Less than 6 months",
  MIDDLE = "6 months to 1 year",
  LARGE = "1 year to 3 years",
  EXTRA_LARGE = "More than 3 years",
}

export enum AverageTxValueMap {
  SMALL = "Less than £50",
  MIDDLE = "£50 - £500",
  LARGE = "£500 +",
}

// ***************************************************************

// ********* first step start *********
export type TypeOfBusinessValues = TypeOfBusinessGeneralValues &
  (TypeOfBusinessCompanyValues | TypeOfBusinessSoloTraderValues);

export type TypeOfBusinessGeneralValues = {
  typeOfBusiness: BusinessTypes;
};

export type TypeOfBusinessCompanyValues = {
  typeOfBusiness: BusinessTypes.Company;
  [AppInputs.COMPANY_NAME]: string;
  [AppInputs.ACCOUNT_NUMBER]?: string;
  [AppInputs.COMPANY_NUMBER]?: string;
  [AppInputs.COUNTRY]?: number;
  [AppInputs.CITY]?: string;
  [AppInputs.POSTAL_CODE]?: string;
  [AppInputs.SORT_CODE]?: string;
  [AppInputs.ADDRESS_LINE_1]?: string;
  [AppInputs.ADDRESS_LINE_2]?: string;
  [AppInputs.PERSONS]?: BusinessOwnerValues[];
};

export type TypeOfBusinessSoloTraderValues = {
  typeOfBusiness: BusinessTypes.SoleTrader;
  [AppInputs.COMPANY_NAME]: string;
};

export type TypeOfBusinessCompanyExcludedValues = {
  [AppInputs.COMPANY_NAME]: string;
  [AppInputs.ACCOUNT_NUMBER]?: string;
  [AppInputs.COMPANY_NUMBER]?: string;
  [AppInputs.COUNTRY]?: number;
  [AppInputs.CITY]?: string;
  [AppInputs.POSTAL_CODE]?: string;
  [AppInputs.SORT_CODE]?: string;
  [AppInputs.ADDRESS_LINE_1]?: string;
  [AppInputs.ADDRESS_LINE_2]?: string;
  [AppInputs.PERSONS]?: BusinessOwnerValues[];
};

export type TypeOfBusinessSoloTraderExcludedValues = {
  [AppInputs.COMPANY_NAME]: string;
};

// ********* first step end *********

// ********* second step start *********
export type DescriptionGeneralValues = {
  [AppInputs.COMPANY_NAME]: string;
  [AppInputs.COMPANY_STATUS]?: string;
  [AppInputs.DBA]?: string;

  [AppInputs.ADDRESS_LINE_1]: string;
  [AppInputs.ADDRESS_LINE_2]?: string;
  [AppInputs.CITY]: string;
  [AppInputs.POSTAL_CODE]: string;
  [AppInputs.COUNTRY]: number;
  [AppInputs.COUNTY]: string;

  sameAddress: boolean;
  [AppInputs.OFFICE_ADDRESS_LINE_1]: string;
  [AppInputs.OFFICE_ADDRESS_LINE_2]?: string;
  [AppInputs.OFFICE_CITY]: string;
  [AppInputs.OFFICE_POSTAL_CODE]: string;
  [AppInputs.OFFICE_COUNTRY]: number;
  [AppInputs.OFFICE_COUNTY]: string;

  sameContactPerson: boolean;
  [AppInputs.PRIMARY_CONTACT_FIRST_NAME]: string;
  [AppInputs.PRIMARY_CONTACT_LAST_NAME]: string;
  [AppInputs.PRIMARY_CONTACT_PHONE_NUMBER]: string;

  [AppInputs.TYPE_OF_BUSINESS]: string;
  [AppInputs.BUSINESS_EXPLANATION]?: string;
  [AppInputs.TOTAL_SALES]?: number;
  [AppInputs.PRODUCT_SERVICE_SOLD]?: string;

  [AppInputs.PHONE_NUMBER]: string;
  [AppInputs.EMAIL]: string;
};

export type CompanyDescriptionValues = DescriptionGeneralValues & {
  [AppInputs.COMPANY_NUMBER]?: string;
};

export type SoloTraderDescriptionValues = DescriptionGeneralValues & {
  [AppInputs.VAT_NUMBER]?: string;
};

// ********* second step end *********

// ********* third step start *********
export type BusinessActivitiesValues = {
  [AppInputs.EXPECTED_TURNOVER]: string;
  [AppInputs.PAYMENT_HISTORY]: string;
  [AppInputs.AVERAGE_TX_VALUE]: string;
};
// ********* third step end *********

// ********* fifth step start *********
export enum OwnerTypeEnum {
  Officer = "Officer",
  UltimateOwner = "UBO",
}
export type OwnerType =
  | [OwnerTypeEnum.Officer]
  | [OwnerTypeEnum.UltimateOwner]
  | [OwnerTypeEnum.Officer, OwnerTypeEnum.UltimateOwner];

export type BusinessOwnersGeneralValues = {
  [AppInputs.PERSON_STATUS]: OwnerTypeEnum[];
  [AppInputs.PERSON_FIRST_NAME]: string;
  [AppInputs.PERSON_LAST_NAME]: string;
  [AppInputs.PERSON_DOB]: string;
  [AppInputs.PERSON_EMAIL]: string;
  [AppInputs.PERSON_PHONE_NUMBER]: string;
  [AppInputs.PERSON_ADDRESS_LINE_1]: string;
  [AppInputs.PERSON_ADDRESS_LINE_2]?: string;
  [AppInputs.PERSON_CITY]: string;
  [AppInputs.PERSON_POSTAL_CODE]: string;
  [AppInputs.PERSON_NATIONALITY]: string;
  [AppInputs.PERSON_AUTHORIZED]: string;
  [AppInputs.PERSON_OWNERSHIP]: number;
  [AppInputs.PERSON_POSITION]?: string;
};

export type BusinessOfficerValues = BusinessOwnersGeneralValues & {
  [AppInputs.PERSON_STATUS]: [OwnerTypeEnum.Officer];
  [AppInputs.PERSON_POSITION]: string;
};

export type BusinessUlitmateOwnerValues = BusinessOwnersGeneralValues & {
  [AppInputs.PERSON_STATUS]: [OwnerTypeEnum.UltimateOwner];
};

export type BusinessOwnerValues =
  | BusinessOfficerValues
  | BusinessUlitmateOwnerValues;

export type BusinessOwnersValues = {
  [AppInputs.PERSONS]: BusinessOwnerValues[];
};
// ********* fifth step end *********

// ********* fifth step start *********
export type PersonalDetailsValues = {
  [AppInputs.PERSON_FIRST_NAME]: string;
  [AppInputs.PERSON_LAST_NAME]: string;
  [AppInputs.PERSON_DOB]: string;
  [AppInputs.PERSON_EMAIL]: string;
  [AppInputs.PERSON_PHONE_NUMBER]: string;
  [AppInputs.PERSON_ADDRESS_LINE_1]: string;
  [AppInputs.PERSON_ADDRESS_LINE_2]?: string;
  [AppInputs.PERSON_CITY]: string;
  [AppInputs.PERSON_POSTAL_CODE]: string;
};

// ********* fifth step end *********

// ********* sixth step end *********

export type PersonalIdentityVerification = {
  [AppInputs.PERSON_FIRST_NAME]: string;
  [AppInputs.PERSON_LAST_NAME]: string;
  [AppInputs.PERSON_DOB]: string;
  [AppInputs.PERSON_ADDRESS_LINE_1]: string;
  [AppInputs.PERSON_ADDRESS_LINE_2]?: string;
  [AppInputs.PERSON_CITY]: string;
  [AppInputs.PERSON_POSTAL_CODE]: string;
};

export type CompanyIdentityVerification = {
  [AppInputs.PROOF_IDENTITY_FIRST]: number;
  [AppInputs.PROOF_ADDRESS]: number;
};

export type SoloTraderIdentityVerification = {
  [AppInputs.PROOF_IDENTITY_FIRST]: number;
  [AppInputs.PROOF_ADDRESS]: number;
};

// ********* sixth step end *********

export type ApplicationCompanyValues = TypeOfBusinessCompanyValues &
  CompanyDescriptionValues &
  BusinessActivitiesValues &
  // SettlementDetailsValues &
  BusinessOwnersValues &
  CompanyIdentityVerification & {};

export type ApplicationSoloTraderValues = TypeOfBusinessSoloTraderValues &
  SoloTraderDescriptionValues &
  BusinessActivitiesValues &
  // SettlementDetailsValues &
  PersonalDetailsValues &
  SoloTraderIdentityVerification & {};

export type ApplicationValues =
  | ApplicationCompanyValues
  | ApplicationSoloTraderValues;

export type Application = ApplicationDTO & {
  data: ApplicationValues;
};
