/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MerchantEntity } from './MerchantEntity';
import type { PartnerEntity } from './PartnerEntity';
import type { UserEntity } from './UserEntity';

export type PayoutEntity = {
    amount?: number;
    beneficiaryType?: PayoutEntity.beneficiaryType;
    created?: string;
    feeAmount?: number;
    id?: number;
    merchant?: MerchantEntity;
    modified?: string;
    modifiedBy?: UserEntity;
    netAmount?: number;
    partner?: PartnerEntity;
    partnerPayout?: PayoutEntity;
    refundAmount?: number;
    repayAmount?: number;
    sourceExternalPaymentId?: string;
    status?: PayoutEntity.status;
    statusText?: string;
    version?: number;
};

export namespace PayoutEntity {

    export enum beneficiaryType {
        MERCHANT = 'MERCHANT',
        PARTNER = 'PARTNER',
    }

    export enum status {
        APPLIED = 'APPLIED',
        APPROVED = 'APPROVED',
        CANCELLED = 'CANCELLED',
        EXPORT_FAILED = 'EXPORT_FAILED',
        IN_PROGRESS = 'IN_PROGRESS',
        NEW = 'NEW',
        PAID = 'PAID',
        RECONCILED = 'RECONCILED',
        RECONCILIATION_FAILED = 'RECONCILIATION_FAILED',
        REJECTED = 'REJECTED',
        VALIDATION_FAILED = 'VALIDATION_FAILED',
    }


}
