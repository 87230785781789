import { notification } from "antd";

const style = {
  width: 15,
  height: 15,
  borderRadius: "50%",
  backgroundColor: "#30D158",
};

export const showSuccess = (title: string, description?: string) => {
  notification.success({
    message: title,
    description: description,
    type: "success",
    icon: <div style={style} />,
    duration: 10,
  });
};
