import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media (max-width: 767px) {
    padding: 0;
  }
`

const Wrapper = styled.div`
  flex-grow: 1;

  display: flex;

  width: 100%;
  max-width: 1920px;

  margin: 0 auto;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const Sidebar = styled.div`
  position: relative;
  z-index: 1;

  flex-shrink: 0;

  width: 350px;

  padding-top: 35px;

  border-right: 1px solid #00000040;

  @media (max-width: 1919px) {
    width: calc(350px - 78px);
  }

  @media (max-width: 1800px) {
    width: calc(350px - 44px);
  }

  @media (max-width: 1450px) {
    width: calc(222px - 19px);
    padding-top: 30px;
  }

  @media (max-width: 1199px) {
    width: calc(222px - 20px);
  }

  @media (max-width: 767px) {
    width: 100%;
    padding-top: 0;
  }
`;

const Content = styled.div`
  flex-grow: 1;

  padding: 0 0 20px;

  overflow: hidden;
`;

const Table = styled.div`
  .ant-table-wrapper {
    max-height: calc(100vh - 80px - 76px - 20px);
    min-height: 50vh;

    overflow: auto;

    @media (max-width: 1450px) {
      max-height: calc(100vh - 60px - 76px - 20px);
    }
  }
`

export const Styled = {
  Container,
  Wrapper,
  Sidebar,
  Content,
  Table,
};

