import { Col, Row } from "antd";
import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";
import { StyledCss } from "../../styles";

type ActiveProp = {
  active?: boolean;
};

const Spin = styled.div`
  position: absolute;

  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.05);
`

const HeaderRow = styled(Row)`
  margin-bottom: 20px;

  @media (max-width: 767px) {
    justify-content: flex-start;

    width: 100%;

    margin-bottom: 0px;
  }
`

const Title = styled.p<ActiveProp>`
  ${StyledCss.Title}
  margin-bottom: 0;

  @media (max-width: 767px) {
    margin-bottom: ${(props) => (props.active ? "21px" : "0")};

    cursor: pointer;
  }

  &::after {
    @media (max-width: 767px) {
      content: '';

      position: absolute;

      top: ${props => props.active ? '7px' : '3px'};
      right: 5px;

      width: 12px;
      height: 12px;

      border-right: 3px solid #3C3C434D;
      border-bottom: 3px solid #3C3C434D;

      transform: ${props => props.active ? 'rotate(-135deg)' : 'rotate(45deg)'};
    }
  }
`;

const Content = styled.div<ActiveProp>`
  @media (max-width: 767px) {
    display: ${(props) => (props.active ? "block" : "none")};
  }
`;

const List = styled.ul`
  list-style-type: none;

  margin-bottom: 27px;
  padding-left: 0;

  font-family: ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 22px;

  color: #000;

  @media (max-width: 1450px) {
    margin-bottom: 10px;
  }

  @media (max-width: 767px) {
    margin-bottom: 24px;
  }

  li {
    padding: 10px 9px 8px;

    font-family: ${Fonts.NexaRegular};
    text-decoration: none;

    border-bottom: 1px solid #c4c3c6;

    @media (max-width: 1450px) {
      padding: 6px 6px 7px;
    }
  }
`;

const FirstCol = styled(Col)`
  font-family: ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 22px;
  color: #000000;

  @media (max-width: 1450px) {
    font-size: 14px;
    line-height: 20px;
  }
`

const SecondCol = styled(Col)`
  font-family: ${Fonts.NexaRegular};
  font-size: 14px;
  line-height: 20px;
  color: ${Colors.GrayBlue};

  @media (max-width: 1450px) {
    font-size: 12px;
    line-height: 16px;
  }
`

const TitleCol = styled(Col)`
  @media (max-width: 767px) {
    width: 100%;
  }
`

const ButtonCol = styled(Col)`
  text-align: right;

  @media (max-width: 767px) {
    position: absolute;
    top: 16px;
    left: 150px;
  }

  @media (max-width: 575px) {
    display: none;
  }
`

const MobileButton = styled.div`
  display: none;

  @media (max-width: 575px) {
    display: block;
  }
`

export const Styled = {
  Spin,
  HeaderRow,
  Title,
  Content,
  List,
  FirstCol,
  SecondCol,
  TitleCol,
  ButtonCol,
  MobileButton,
};
