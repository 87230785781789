import { loaderSlice } from "data-layer/common/loaderSlice";
import { AppDispatch } from "data-layer/store/store";
import { handleNetworkError } from "utils/handleNetworkError";
import { sys18Api } from "./api";
import { sys18Slice } from "./slice";

export const getSys18Thunk = (
  limit: number,
  offset: number,
  id?: string,
  locale?: string,
  value?: string
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      sys18Slice.actions.getSys18Request();
      const result = await sys18Api.api.get(limit, offset, id, locale, value);

      dispatch(sys18Slice.actions.getSys18Success(result));
    } catch (e) {
      dispatch(sys18Slice.actions.getSys18Failure());
      handleNetworkError(e, "Network error");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};
