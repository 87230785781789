import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import "./index.less";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "data-layer/store";
import { ConfigProvider } from "antd";
import enGb from 'antd/lib/locale/en_GB';

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <ConfigProvider locale={enGb}>
        <App />
      </ConfigProvider>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
