import { Col } from "antd";
import { Styled } from "./styles";
import logo from "assets/dapio-logo.svg";

export const FooterSc: React.FC = () => {
  return (
    <Styled.Wrapper>
      <div className="container">
        <Styled.Row gutter={{ xs: 0, sm: 0, md: 40, lg: 83, xl: 90 }} wrap={false}>
          <Col style={{ flexShrink: 0 }}>
            <Styled.Logo src={logo} />
          </Col>

          <Col style={{ flexShrink: 0 }}>
            <Styled.List>
              <Styled.ListItem>
                <a href="https://dapio.com/security" target="_blank">
                  Security
                </a>
              </Styled.ListItem>
              <Styled.ListItem>
                <a href="https://dapio.com/privacy" target="_blank">
                  Privacy
                </a>
              </Styled.ListItem>
            </Styled.List>
          </Col>

          <Styled.DescriptionCol>
            <Styled.Description>
              Paymob Technology Ltd, trading as Dapio registered in England and
              Wales at 71-75 Shelton Street, Covent Garden, London, WC2H 9JQ.
              Company registration number 11965837. Dapio is registered with the
              FCA as a Small Payments Institution under Reference number:
              917808. You may be able to complain about this firm to the
              Financial Ombudsman Service at
              https://www.financial-ombudsman.org.uk
            </Styled.Description>
          </Styled.DescriptionCol>
        </Styled.Row>
      </div>
    </Styled.Wrapper>
  );
};
