import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

const ContentTitle = styled.p`
  margin: 0;
  margin-bottom: 7px;

  font-family: ${Fonts.NexaRegular};
  font-size: 25px;
  line-height: 120%;

  color: ${Colors.Primary};

  @media (max-width: 776px) {
    margin-bottom: 5px;
    font-size: 20px;
    line-height: 125%;
  }
`;

const ContentSubtitle = styled.p`
  margin: 0;
  margin-bottom: 45px;

  font-family: ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 135%;

  @media (max-width: 767px) {
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 142%;
  }
`

const Line = styled.div`
  width: 100%;
  height: 0.5px;
  margin: 30px 0;
  background-color: #3C3C435C;
`

const GroupTitle = styled.p`
  margin: 0;
  margin-bottom: 12px;

  font-family: ${Fonts.NexaRegular};
  font-size: 20px;
  line-height: 125%;

  @media (max-width: 776px) {
    margin-bottom: 7px;
    font-size: 17px;
  }
`

const GroupSubtitle = styled.p`
  margin: 0;
  margin-bottom: 30px;

  font-family: ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 135%;

  color: ${Colors.GrayBlue};

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 142%;
  }
`

export const Styled = {
  ContentTitle,
  ContentSubtitle,
  Line,

  GroupTitle,
  GroupSubtitle,
};
