import { InvitedUserRequestDTO } from "generated";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { inviteThunk } from "../thunks";

type UseInvite = () => {
  invite: (data: InvitedUserRequestDTO, cb?: VoidFunction) => void;
};

export const useInvite: UseInvite = () => {
  const dispatch = useDispatch();

  const invite = useCallback((data: InvitedUserRequestDTO, cb?: VoidFunction) => {
    dispatch(inviteThunk(data, cb));
  }, [dispatch]);

  return {
    invite,
  };
};
