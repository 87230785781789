import styled from "styled-components";
import { Paper as UIPaper } from "components";
import { Col } from "antd";
import { Fonts } from "styles/fonts";
import { Colors } from "styles/colors";
import { StyledCss } from "../../styles";

const Paper = styled(UIPaper)`
  min-height: 378px;

  @media (max-width: 1450px) {
    min-height: 311px;
  }

  @media (max-width: 767px) {
    min-height: auto;
  }
`;

const RevenueWrapper = styled.div`
  .ant-tabs-nav {
    justify-content: flex-end;
    margin-left: auto;
  }

  > .ant-row {
    @media (max-width: 991px) {
      flex-direction: column;
    }
  }
`;

const Spin = styled.div`
  position: absolute;

  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.05);
`

const ReveneuInfoCol = styled(Col)`
  width: 400px;

  @media (max-width: 1450px) {
    width: 305px;
  }

  @media (max-width: 991px) {
    margin-bottom: 30px;
  }

  @media (max-width: 767px) {
    margin-bottom: 0;
  }
`;

const ReveneuChartCol = styled(Col)`
  flex-grow: 1;
  flex-shrink: 0;

  display: flex;
  flex-direction: column;

  width: 50%;

  @media (max-width: 991px) {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

const Chart = styled.div`
  height: 270px;

  @media (max-width: 1450px) {
    height: 230px;
  }
`

const Title = styled.p`
  ${StyledCss.Title}
`;

const StatsRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 52px;

  @media (max-width: 991px) {
    margin-bottom: 20px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 45px;
  }
`;

const StatBlock = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  min-height: 90px;

  margin-bottom: 10px;
  padding: 16px 50px 13px 15px;

  border-radius: 8px;

  background: ${Colors.Primary};

  @media (max-width: 1450px) {
    min-height: 77px;
    margin-bottom: 5px;
    padding: 10px 45px 10px 15px;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 11px 15px 8px;
    border-radius: 5px;
  }

  &:last-child {
    width: 320px;
    margin-bottom: 0;

    @media (max-width: 1450px) {
      width: 233px;
    }  
  }

  span:first-child {
    font-family: ${Fonts.NexaRegular};
    font-size: 40px;
    line-height: 100%;

    color: #fff;

    @media (max-width: 1450px) {
      font-size: 30px;
    }
  }

  span:last-child {
    font-family: ${Fonts.NexaRegular};
    font-size: 20px;
    line-height: 100%;

    color: ${Colors.Secondary};

    @media (max-width: 1450px) {
      font-size: 17px;
    }
  }
`;

const ReportDate = styled.p`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-top: auto !important;
  margin-bottom: 0;
  padding-bottom: 6px;

  font-size: 14px;
  font-family: ${Fonts.Circe};

  line-height: 16px;

  color: #1d1d1f;

  @media (max-width: 1450px) {
    padding-bottom: 0;
  }

  & > span:first-child {
    position: relative;
    top: 2px;
  }
`;

const Icon = styled.span`
  margin-left: 5px;

  font-size: 25px;
`;

const ChartWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  margin-bottom: 15px;
`;

type ChartBarWrapperProps = {
  length: number;
};

type ChartBarProps = {
  height: number;
};

const ChartBarWrapper = styled.div<ChartBarWrapperProps>`
  width: ${(props) => `calc(100% / ${props.length} - 20px)`};

  @media (max-width: 1199px) {
    width: ${(props) => `calc(100% / ${props.length} - 10px)`};
  }
`;

const ChartBar = styled.p<ChartBarProps>`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  height: 100%;

  background-image: ${(props) =>
    props.height != 0
      ? "linear-gradient(180deg, #fe8e75 0%, #ffffff00 100%)"
      : "none"};

  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
`;

const ChartValue = styled.span`
  padding-top: 14px;

  font-family: ${Fonts.Circe};
  font-size: 16px
  line-height: 23px;

  color: #fff;
`;

const ChartLabel = styled.p`
  display: flex;
  justify-content: center;

  padding-top: 3px;

  font-family: ${Fonts.Circe};
  font-size: 16px;
  line-height: 23px;

  color: #1d1d1f;
`;

// const Buttons = styled.div`
//   @media (max-width: 991px) {
//     padding-top: 75px;
//   }
// `;

// const Button = styled.div`
//   min-width: 119px;
// `;

export const Styled = {
  Paper,

  RevenueWrapper,
  Spin,
  ReveneuInfoCol,
  ReveneuChartCol,
  Chart,

  Title,

  StatsRow,
  StatBlock,

  ReportDate,
  Icon,

  ChartWrapper,
  ChartBarWrapper,
  ChartBar,
  ChartLabel,
  ChartValue,

  // Buttons,
  // Button,
};
