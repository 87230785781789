import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

interface TextProps {
  isDrag?: boolean;
}

const DropWrapper = styled.div`
  height: 230px;
  max-width: 100%;
  border: 1px dashed #707070;
  border-radius: 8px;
`;

const CenterBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 10px;
`;

const InfoCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Text = styled.span<TextProps>`
  font-family: ${Fonts.NexaRegular};
  font-size: 14px;
  line-height: 20px;
  // color: ${(props) => (props.isDrag ? "#FFF" : "#1d1d1f")};
  color: #000000D9;
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 129px;
  height: 30px;

  margin-top: 20px;
  padding-top: 2px;

  font-family: ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 22px;

  border: 1px solid #FE8E75;
  border-radius: 25px;

  color: ${Colors.Primary};

  cursor: pointer;

  &:hover {
    background-color: ${Colors.Primary};
    color: #fff;
  }
`

const Paper = styled.div`
  opacity: 0.6;
  background: #000000;
  width: 100%;
  height: 100%;
`;

export const Styled = {
  DropWrapper,
  InfoCenter,
  Text,
  Button,
  Paper,
  CenterBlock,
};
