import { CancelablePromise } from "generated/core/CancelablePromise";
import { api } from "./api";
import { PayoutDTO } from "generatedCrate";

export interface IPayoutsApi {
  getPayouts: (
    from: string,
    to: string,
    limit: number,
    offset: number,
    statuses?: 'APPROVED' | 'CANCELLED' | 'IN_PROGRESS' | 'NEW' | 'PAID' | 'REJECTED',
  ) => CancelablePromise<Array<PayoutDTO>>;
}

class PayoutsApi {
  api: IPayoutsApi = api;

  constructor() {
    if (process.env.REACT_APP_MOCK_ENABLED === "true") {
      this.api = api;
      return;
    }

    this.api = api;
  }
}

export const payoutsApi = new PayoutsApi();
