import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

const GroupWrapper = styled.div`
  .ant-radio-wrapper {
    flex-direction: row-reverse;

    width: 100%;

    padding: 12px 20px 15px 16px;

    border-bottom: 1px solid #e5e5e5;

    color: #283549;

    @media (max-width: 1199px) {
      padding: 11px 20px 8px 16px;
    }
  }

  span.ant-radio + * {
    flex: 1;
    padding-left: 0;
  }
`;

const Label = styled.div`
  width: 100%;

  padding-bottom: 5px;
  padding-left: 16px;

  font-family: ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 23px;

  border-bottom: 1px solid #e5e5e5;

  color: ${Colors.GrayBlue};

  @media (max-width: 1199px) {
    padding-bottom: 3px;

    font-family: ${Fonts.NexaRegular};
    font-size: 14px;
    line-height: 20px;

    color: #8096A7;
  }

  span {
    display: block;
    max-width: 450px;

    @media (max-width: 1199px) {
      max-width: 100%;
    }
  }
`

export const Styled = {
  GroupWrapper,
  Label,
};
