import { useMerchant } from "data-layer/merchant";
import { useEffect } from "react";
import { Filters } from "./useInitParams";

type UseGetMerchants = (filters: Filters | null) => void;

export const useGetMerchants: UseGetMerchants = (filters) => {
  const { getMerchants } = useMerchant();

  useEffect(() => {
    if (!filters) {
      return;
    }

    getMerchants(filters);
  }, [filters]);
};
