import styled from "styled-components";
import { Colors } from "styles/colors";

const Control = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 50px;
  height: 50px;

  border: 1px solid ${Colors.LightGray};
  border-radius: 50%;

  cursor: pointer;

  @media (max-width: 1450px) {
    width: 30px;
    height: 30px;
  }

  @media (max-width: 575px) {
    display: none;
  }

  &:hover {
    background-color: ${Colors.Secondary};

    svg {
      color: #fff;
    }
  }

  svg {
    font-size: 24px;
    color: ${Colors.Secondary};

    @media (max-width: 1450px) {
      font-size: 15px;
    }
  }
`;

export const Styled = {
  Control,
};
