import { useEffect, useRef, useState } from "react";
import { useHandleFile } from "./useHandleFile";
import { Col, Row, Spin } from "antd";
import { Styled } from "./styles";
import { Button } from "components";

type UploadProps = {
  onChange: (value: string | null) => void;
  value?: string | null;
};

export const Upload: React.FC<UploadProps> = (props) => {
  const { onChange, value } = props;
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<string | null>(null);

  useEffect(() => {
    setFile(value || null);
  }, [value]);

  useEffect(() => {
    onChange(file);
  }, [file, onChange]);

  const ref = useRef();

  const removeFile = () => {
    ref.current.value = "";
    setFile(null);
  };

  const { handleFile } = useHandleFile(setLoading, setFile);

  return (
    <Styled.Wrapper>
      <input type="hidden" name="file" disabled={loading} />

      <Row gutter={[{ xs: 16, sm: 30 }, 0]} align="middle">
        <Col style={{ position: "relative" }}>
          <Button type="primary-transparent" size="small">
            Browse
            <Styled.Input
              type="file"
              name="file_"
              onChange={handleFile}
              disabled={loading}
              ref={ref}
              accept="image/*"
            />
          </Button>
        </Col>

        <Col flex={1}>
          <Styled.Field>
            <Styled.FieldText>
              {file || "Choose file to uploading"}
            </Styled.FieldText>
          </Styled.Field>
        </Col>

        <Col>
          <Styled.RemoveButton onClick={removeFile} />
        </Col>
      </Row>

      {loading && (
        <Styled.Loader>
          <Spin />
        </Styled.Loader>
      )}
    </Styled.Wrapper>
  );
};
