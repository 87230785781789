/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MerchantDTO } from './MerchantDTO';

export type TerminalDTO = {
    activationDate?: string;
    cctExternalDeviceId?: number;
    id: number;
    merchant?: MerchantDTO;
    serialNumber?: string;
    status?: TerminalDTO.status;
    vacDeviceId?: string;
};

export namespace TerminalDTO {

    export enum status {
        ACTIVATED = 'ACTIVATED',
        EXPORTED = 'EXPORTED',
        EXTERNAL_SYSTEM_NOT_READY = 'EXTERNAL_SYSTEM_NOT_READY',
        NEW = 'NEW',
        SUSPENDED = 'SUSPENDED',
        ZERO_TRANSACTION = 'ZERO_TRANSACTION',
    }


}
