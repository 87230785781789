import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { resendConfirmationLinkThunk } from "data-layer/employee/thunks";

type UseResendConfirmationLink = () => {
  resendConfirmationLink: (userId: number) => void;
};

export const useResendConfirmationLink: UseResendConfirmationLink = () => {
  const dispatch = useDispatch();

  const resendConfirmationLink = useCallback(
    (id) => {
      dispatch(resendConfirmationLinkThunk(id));
    },
    [dispatch]
  );

  return {
    resendConfirmationLink,
  };
};
