import {AppInputs} from "generated/modelsWrapper/AppInputs";
import * as Yup from "yup";

export enum BusinessTypes {
  Company = "Limited Company",
  SoleTrader = "Sole Trader",
  Partnership = "partnership",
  Other = "Other",
}

function checkQuotes(val : string) {
    var quotes = ['\'', '\`', '\"'];
    var counter = 0;
    quotes.forEach(function (elem) {
        if(val.indexOf(elem) > -1) {
            counter++;
        }
    });

    return counter == 0;
}

export const typeOfBusinessSchema = Yup.object().shape({
  typeOfBusiness: Yup.string().required("Required"),

  [AppInputs.COMPANY_NAME]: Yup.string().when("typeOfBusiness", {
    is: (value: string) => {
      return (
        value === BusinessTypes.Company || value === BusinessTypes.SoleTrader
      );
    },
    then: Yup.string()
        .required("Required")
        .test('Check input value length', '', val => {
          return val ? val.length > 4 : false;
        })
        .test('Check input value', 'You need to select company from list', val => {
          return val ? !Number(val.substring(2)) : false;
        })
        .test('Check quotes existing in value', 'Please do not use quotes in this field', val => {
            return val ? checkQuotes(val) : false;
        }),

    otherwise: Yup.string().notRequired(),
  }),

  // [AppInputs.COMPANY_NAME]: Yup.string().when("typeOfBusiness", {
  //   is: (value: string) => {
  //     return value === BusinessTypes.SoleTrader;
  //   },
  //   then: Yup.string().required("Required"),
  //   otherwise: Yup.string().notRequired(),
  // }),
});
