import { appClient } from "services";
import { IApplicationApi } from "./index";

export const api: IApplicationApi = {
  getApplications: () => {
    return appClient.applicationController.getAllUsingGet();
  },

  // autocompleteField: (body) => {
  //   return appClient.applicationController.autocompleteFieldUsingPost(body);
  // },

  finish: (body) => {
    return appClient.applicationController.finishUsingPost(body);
  },

  // deprecated
  init: () => {
    return appClient.applicationController.initUsingPost();
  },

  persist: (body) => {
    return appClient.applicationController.persistUsingPost(body);
  },

  getCountByPartner: () => {
    return appClient.applicationController.getCountByPartnerUsingGet();
  },
};
