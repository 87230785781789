import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { updateSettlementsThunk } from "../thunks";

type UseUpdateSettlements = () => {
  updateSettlements: (token: string, cb?: VoidFunction) => void;
};

export const useUpdateSettlements: UseUpdateSettlements = () => {
  const dispatch = useDispatch();

  const updateSettlements = useCallback(
    (token, cb) => {
      dispatch(updateSettlementsThunk(token, cb));
    },
    [dispatch]
  );

  return {
    updateSettlements,
  };
};
