import { AutoComplete, Input } from "formik-antd";
import { useSearch } from "./useSearch";
import { SearchOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { AppInputs } from "generated/modelsWrapper/AppInputs";
import { useDictionary } from "data-layer/dictionary";
import { Styled } from "./styles";
import { useEffect } from "react";
import { useEmployee } from "data-layer/employee";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

type CompanySearchProps = {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  defaultValue: string;
};

export const CompanySearch: React.FC<CompanySearchProps> = (props) => {
  const { setFieldValue, defaultValue } = props;
  const { countries } = useDictionary();
  const { employee } = useEmployee();

  const {
    handleSearch,
    handleChange,
    value,
    data,
    loadingSearch,
    loadingProfile,
  } = useSearch(setFieldValue, countries);

  useEffect(() => {
    try {
      if (
        (employee.user?.extraData as ObjectType)?.merchantName &&
        (employee.user?.extraData as ObjectType)?.merchantName === defaultValue
      ) {
        handleSearch((employee.user!.extraData! as ObjectType).merchantName);
      }
    } catch (e) {}
  }, []);

  const isSelectOpen = Boolean(
    (employee.user?.extraData as ObjectType)?.merchantName &&
      (employee.user?.extraData as ObjectType)?.merchantName === defaultValue
  );

  const options = data.map((item) => ({
    value: item.company_number,
    label: (
      <Styled.Option>
        <Styled.OptionTitle>{item.company_name}</Styled.OptionTitle>
        <Styled.OptionDescription>{item.description}</Styled.OptionDescription>
        <Styled.OptionDescription>
          {item?.address?.premises} {item?.address?.address_line_1},{" "}
          {item?.address?.locality}, {item?.address?.country},{" "}
          {item?.address?.postal_code}
        </Styled.OptionDescription>
      </Styled.Option>
    ),
  }));

  return (
    <AutoComplete
      showSearch
      onSearch={handleSearch}
      value={value || defaultValue}
      defaultActiveFirstOption={false}
      onSelect={handleChange}
      filterOption={false}
      disabled={loadingProfile}
      options={options}
      name={AppInputs.COMPANY_NAME}
      defaultOpen={isSelectOpen}
    >
      <Input
        name={AppInputs.COMPANY_NAME}
        size="large"
        placeholder="Please select..."
        suffix={
          loadingSearch || loadingProfile ? (
            <Spin indicator={antIcon} />
          ) : (
            <SearchOutlined style={{ fontSize: 20, color: "#FE8E75" }} />
          )
        }
      />
    </AutoComplete>
  );
};
