import React from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { GeneralStyled } from "../../styled";
import { BulkUploadSc } from "scenarios/partner/BulkUploadSc";

type ViewProps = {
  onClick: VoidFunction;
};

const View: React.FC<ViewProps> = (props) => {
  return (
    <GeneralStyled.ButtonWrapper {...props}>
      <GeneralStyled.ButtonWrapperText>Bulk upload</GeneralStyled.ButtonWrapperText>
      <DownloadOutlined />
    </GeneralStyled.ButtonWrapper>
  );
};

export const BulkUploadButton: React.FC = () => {
  return <BulkUploadSc ViewComponent={View} />;
};
