import styled from 'styled-components';

const Button = styled.button`
    position: relative;

    display: block;

    width: 25px;
    height: 25px;

    border: 2px solid #1D1D1F;
    border-radius: 50%;
    background-color: transparent;
    cursor: pointer;
    opacity: 0.6;

    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 14px;
        height: 2px;
        background-color: #1D1D1F;
    }

    &::before {
        transform: translate(-50%, -50%) rotate(-45deg);
    }

    &::after {
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &:hover {
        opacity: 1;
    }
`

export const Styled = {
    Button,
}