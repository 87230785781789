import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EmployeePermissionDTO } from "generated";

export type EmployeePermissions = EmployeePermissionDTO[] | [];

export type EmployeePermissionsInitialState = {
  data: EmployeePermissions;
  isFetching: boolean;
};

const initialState: EmployeePermissionsInitialState = {
  data: [],
  isFetching: false,
};

export const employeePermissionsSlice = createSlice({
  name: "employeePermissions",
  initialState: initialState,
  reducers: {
    getEmployeePermissionsRequest: (state) => {
      state.isFetching = true;
    },
    getEmployeePermissionsSuccess: (
      state,
      action: PayloadAction<Array<EmployeePermissionDTO>>
    ) => {
      state.data = action.payload;
      state.isFetching = false;
    },
    getEmployeePermissionsFailure: (state) => {
      state.isFetching = false;
    },
  },
});

export const employeePermissionsActions = employeePermissionsSlice.actions;
