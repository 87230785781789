import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { getDocumentsByProfileThunk } from "../thunks";

type UseGetDocumentsByProfile = () => {
  getDocumentsByProfile: (profileId: number) => void;
};

export const useGetDocumentsByProfile: UseGetDocumentsByProfile = () => {
  const dispatch = useDispatch();

  const getDocumentsByProfile = useCallback((profileId: number) => {
    dispatch(getDocumentsByProfileThunk(profileId));
  }, [dispatch]);

  return {
    getDocumentsByProfile,
  };
};
