import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { getPaymentsThunk } from "../thunks";
import { PaymentsParams } from "../types/PaymentsParams";

type UseGetPayments = () => {
  getPayments: (params: PaymentsParams) => void;
};

export const useGetPayments: UseGetPayments = () => {
  const dispatch = useDispatch();

  const getPayments = useCallback(
    (params) => {
      dispatch(getPaymentsThunk(params));
    },
    [dispatch]
  );

  return {
    getPayments,
  };
};
