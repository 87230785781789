import styled from "styled-components";
import { Fonts } from "styles/fonts";
import { Colors } from "styles/colors";
import bg from "./bg.jpg";

const Wrapper = styled.div`
  display: flex;

  min-height: 100vh;
`;

const Background = styled.div`
  flex-shrink: 0;

  position: relative;

  width: 43%;
  min-height: 100vh;

  background-color: ${Colors.LightGray};

  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center;

  overflow: hidden;

  background-image: url(${bg});

  @media (max-width: 1199px) {
    width: 550px;
  }

  @media (max-width: 991px) {
    display: none;
  }
`;

const Content = styled.div`
  flex-shrink: 0;

  display: flex;
  flex-direction: column;

  width: 57%;
  min-height: 100vh;

  padding: 100px 72px;

  @media (max-width: 1199px) {
    width: 50%;
    padding: 50px 55px;
  }

  @media (max-width: 991px) {
    width: 100%;
  }

  @media (max-width: 575px) {
    padding: 50px 25px;
  }
`;

const ContentContainer = styled.div`
  max-width: 385px;

  @media (max-width: 1199px) {
    max-width: 356px;
  }

  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;

const Description = styled.div`
  margin-bottom: 45px;

  font-family: ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 147%;

  color: ${Colors.Black};

  @media (max-width: 1199px) {
    margin-bottom: 35px;
    font-size: 14px;
    line-height: 20px;
  }

  @media (max-width: 575px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

const SubmitButton = styled.div`
  max-width: 273px;

  @media (max-width: 1199px) {
    max-width: 225px;
  }

  @media (max-width: 575px) {
    max-width: 100%;
  }
`;

export const Styled = {
  Wrapper,
  Background,
  Content,
  ContentContainer,
  Description,
  SubmitButton,
};
