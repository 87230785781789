import { combineReducers } from "@reduxjs/toolkit";
import { applicationReducer } from "data-layer/application/slice";
import { commonReducer } from "data-layer/common/reducer";
import { documentsReducer } from "data-layer/documentRequest/slice";
import { employeeReducer } from "data-layer/employee/reducer";
import { profileReducer } from "data-layer/profile/slice";
import { transactionsReducer } from "data-layer/transaction";
import { payoutsReducer } from "data-layer/payouts";
import { paymentsReducer } from "data-layer/payments";
import { dictionaryReducer } from "data-layer/dictionary";
import { employeesReducer } from "data-layer/employees";
import { partnerReducer } from "data-layer/partner/slice";
import { notificationsReducer } from "data-layer/notifications";
import { merchantReducer } from "../merchant";
import { employeePermissionsReducer } from "data-layer/employee-permissions/reducer";
import { sys18Reducer } from "data-layer/sys18/reducer";

export const rootReducer = combineReducers({
  employee: employeeReducer,
  profile: profileReducer,
  common: commonReducer,
  application: applicationReducer,
  dictionaries: dictionaryReducer,
  documents: documentsReducer,
  transactions: transactionsReducer,
  payouts: payoutsReducer,
  payments: paymentsReducer,
  employees: employeesReducer,
  partner: partnerReducer,
  merchant: merchantReducer,
  notifications: notificationsReducer,
  employeePermissions: employeePermissionsReducer,
  sys18: sys18Reducer,
});
