import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 1920px;

  margin: 0 auto;
  padding-bottom: 20px;
`;

const Table = styled.div`
  margin: 0 -15px;

  .ant-table-wrapper {
    // 100vh - headerHeight - pagination - content paddingBottom
    max-height: calc(100vh - 76px - 20px);
    min-height: 50vh;
    overflow: auto;
  }

  .general-table .ant-table-tbody {
    left: 0;
  }

  .general-table .ant-table-thead .ant-table-cell:first-child {
    padding-left: 15px !important;
  }

  .general-table .ant-table-tbody .ant-table-cell {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const Styled = {
  Wrapper,
  Table,
};

export const TableLinkButton = styled.button`
  border: solid 1px red;
`;
