import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

const Wrapper = styled.div`
  max-width: 566px;

  margin: 0 auto;

  text-align: center;

  border-radius: 10px;
`;

const Icon = styled.div`
  position: relative;

  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 65px;
  height: 65px;

  margin: 0 auto 21px;

  font-size: 25px;

  border-radius: 15px;

  background: #fff;

  box-shadow: 0px 3px 3px #0000001a;

  @media (max-width: 575px) {
    width: 55px;
    height: 55px;
    margin-bottom: 20px;
  }

  &::before {
    z-index: -1;

    content: "";

    position: absolute;

    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;

    background-color: #0000000d;

    border-radius: 10px;
  }
`;

const Svg = styled.div`
  font-size: 35px;
  color: ${Colors.Primary};

  @media (max-width: 575px) {
    font-size: 30px;
  }
`

const Title = styled.p`
  margin-bottom: 20px;

  font-family: ${Fonts.NexaRegular};
  font-size: 25px;
  line-height: 16px;

  color: ${Colors.Primary};

  @media (max-width: 575px) {
    font-size: 20px;
  }
`;

const Description = styled.p`
  max-width: 270px;

  margin: 0 auto;
  margin-bottom: 80px;

  font-size: 16px;
  line-height: 23px;
  font-family: ${Fonts.NexaRegular};

  color: #4d4d4d;

  @media (max-width: 575px) {
    margin-bottom: 50px;
    font-size: 14px;
  }
`;

export const Styled = {
  Wrapper,
  Icon,
  Svg,
  Title,
  Description,
};
