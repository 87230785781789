import { useMerchant } from "data-layer/merchant";
import { useProfile } from "data-layer/profile";
import { ProfileDTO } from "generated";
import { Navigate } from "react-router-dom";

export const PrivateSellerRoute: React.FC<any> = (props) => {
  const { children } = props;
  const { me } = useMerchant();
  const { selectedProfile } = useProfile();

  // если апрувнутый мерч, то есть "me"
  if (me) {
    return children;
  }

  if (!selectedProfile) {
    return <Navigate to="/onboarding-guide" />;
  }

  if (selectedProfile.status === ProfileDTO.status.NOT_STARTED) {
    return <Navigate to="/onboarding" />;
  }

  if (selectedProfile.status === ProfileDTO.status.NOT_FINISHED) {
    return <Navigate to="/onboarding" />;
  }

  if (selectedProfile.status === ProfileDTO.status.UNDER_REVIEW) {
    return <Navigate to="/onboarding-finish" />;
  }

  if (selectedProfile.status === ProfileDTO.status.REJECTED) {
    return <Navigate to="/onboarding-finish" />;
  }

  if (selectedProfile.status === ProfileDTO.status.BLOCKED) {
    return <Navigate to="/onboarding-finish" />;
  }

  if (selectedProfile.status === ProfileDTO.status.DOCUMENT_REQUESTED) {
    return <Navigate to="/onboarding-finish" />;
  }

  if (selectedProfile.status === ProfileDTO.status.APPROVED) {
    return children;
  }

  return null;
};
