import { useSelector } from "react-redux";
import {
  isFetchingAmountSelector,
  isFetchingAverageSelector,
  isFetchingSelector,
  transactionsDataAmountSelector,
  transactionsDataAverageSelector,
  transactionsDataSelector,
} from "../selectors";
import { useGetTransactions } from "./useGetTransactions";
import { useGetTransactionsAmount } from "./useGetTransactionsAmount";
import { UseTransactions } from "../types";
import { useGetTransactionsAverage } from "./useGetTransactionsAverage";

export const useTransactions = (): UseTransactions => {
  const { getTransactions } = useGetTransactions();
  const { getTransactionsAmount } = useGetTransactionsAmount();
  const { getTransactionsAverage } = useGetTransactionsAverage();

  const data = useSelector(transactionsDataSelector);
  const isFetching = useSelector(isFetchingSelector);

  const dataAmount = useSelector(transactionsDataAmountSelector);
  const isFetchingAmount = useSelector(isFetchingAmountSelector);

  const dataAverage = useSelector(transactionsDataAverageSelector);
  const isFetchingAverage = useSelector(isFetchingAverageSelector);

  return {
    getTransactions,
    getTransactionsAmount,
    getTransactionsAverage,
  
    data,
    isFetching,
  
    dataAmount,
    isFetchingAmount,
  
    dataAverage,
    isFetchingAverage,
  };
};
