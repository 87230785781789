import { createSlice } from "@reduxjs/toolkit";

export type NotificationsInitialstate = {
  isModalStartVisible: boolean;
};

const initialState: NotificationsInitialstate = {
  isModalStartVisible: false,
};

export const notificactionsSlice = createSlice({
  name: "sync",
  initialState: initialState,
  reducers: {
    showModalStartNotification: (state) => {
      state.isModalStartVisible = true;
    },
    hideModalStartNotification: (state) => {
      state.isModalStartVisible = false;
    },
  },
});

export const notificactionsActions = notificactionsSlice.actions;
export const notificactionsReducer = notificactionsSlice.reducer;
