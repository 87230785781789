import moment from "moment";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

type UseChangeDate = () => {
  handleChangeDate: (date: any) => void;
  handleChangeDatePicker: (date: any) => void;
};

export enum DateFilters {
  Today = "Today",
  This_week = "This_week",
  This_month = "This_month",
  Last_7_days = "Last_7_days",
  Last_30_days = "Last_30_days",
}

export const useChangeDate: UseChangeDate = () => {
  const [searchParams, setSearchParams] = useSearchParams({});

  const handleChangeDate = useCallback(
    (e) => {
      const otherParams = {
        ...Object.fromEntries([...searchParams]),
        offset: "0",
      };

      switch (e.target.value) {
        case DateFilters.Today: {
          setSearchParams({
            ...otherParams,
            from: moment().startOf("day").toISOString(),
            to: moment().endOf("day").toISOString(),
          });
          break;
        }
        case DateFilters.This_week: {
          setSearchParams({
            ...otherParams,
            from: moment().startOf("week").toISOString(),
            to: moment().endOf("week").toISOString(),
          });
          break;
        }
        case DateFilters.This_month: {
          setSearchParams({
            ...otherParams,
            from: moment().startOf("month").toISOString(),
            to: moment().endOf("month").toISOString(),
          });
          break;
        }
        case DateFilters.Last_7_days: {
          setSearchParams({
            ...otherParams,
            from: moment().subtract(7, "d").startOf("day").toISOString(),
            to: moment().endOf("day").toISOString(),
          });
          break;
        }
        case DateFilters.Last_30_days: {
          setSearchParams({
            ...otherParams,
            from: moment().subtract(30, "d").startOf("day").toISOString(),
            to: moment().endOf("day").toISOString(),
          });
          break;
        }
      }
    },
    [searchParams, setSearchParams]
  );

  const handleChangeDatePicker = useCallback(
    (date) => {
      if (date === null) {
        return;
      }

      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        offset: "0",
        from: date[0].toISOString(),
        to: date[1].toISOString(),
      });
    },
    [searchParams, setSearchParams]
  );

  return {
    handleChangeDate,
    handleChangeDatePicker,
  };
};
