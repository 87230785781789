import { useEffect } from "react";
import { useCommon } from "data-layer/common";
import { history } from "data-layer/../router/AppRouter";
import { useLocation } from "react-router-dom";

const hasFromString = (value: unknown): value is { from: string } => {
  return typeof value === 'object' && value !== null && 'from' in value;
}

export const SyncSc: React.FC = () => {
  const { syncMerchant } = useCommon();
  const { state } = useLocation();

  let url = hasFromString(state) ? state.from : "/dashboard";

  useEffect(() => {
    syncMerchant(() => {
      history.push(url);
    });
  }, []);

  return null;
};
