/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { SchemeInfo } from './SchemeInfo';

export type MDLRPayoutEventDTO = {
    AccountId?: string;
    Amount?: string;
    DateTime?: string;
    EventId?: string;
    EventName?: MDLRPayoutEventDTO.EventName;
    EventTime?: string;
    ExternalReference?: string;
    PaymentId?: string;
    PaymentSubmittedTime?: string;
    Reference?: string;
    SchemeInfo?: SchemeInfo;
    Status?: MDLRPayoutEventDTO.Status;
    TransactionId?: string;
};

export namespace MDLRPayoutEventDTO {

    export enum EventName {
        ACCOUNT_STATEMENT = 'ACCOUNT_STATEMENT',
        ACCOUNT_SWITCH_UPDATE = 'ACCOUNT_SWITCH_UPDATE',
        BALANCE = 'BALANCE',
        BALANCE_HIGH = 'BALANCE_HIGH',
        BALANCE_LOW = 'BALANCE_LOW',
        CARD_AUTH = 'CARD_AUTH',
        CARD_CREATION = 'CARD_CREATION',
        CARD_STATUS_UPDATE = 'CARD_STATUS_UPDATE',
        CARD_TOKEN_PROVISIONING = 'CARD_TOKEN_PROVISIONING',
        CUSTVSTAT = 'CUSTVSTAT',
        DDMANDATE = 'DDMANDATE',
        DD_COLLECTION_STATUS = 'DD_COLLECTION_STATUS',
        DD_FAILED_CLAIM = 'DD_FAILED_CLAIM',
        DD_FUNDS_RETURNED = 'DD_FUNDS_RETURNED',
        DD_INCOMING_DEBIT = 'DD_INCOMING_DEBIT',
        PAYIN = 'PAYIN',
        PAYMENT_COMPLIANCE_STATUS = 'PAYMENT_COMPLIANCE_STATUS',
        PAYOUT = 'PAYOUT',
        PENDING_PAYMENTS = 'PENDING_PAYMENTS',
        UPCOMING_COLLECTION_CREDIT = 'UPCOMING_COLLECTION_CREDIT',
        UPCOMING_COLLECTION_DEBIT = 'UPCOMING_COLLECTION_DEBIT',
        UPCOMING_CREDIT = 'UPCOMING_CREDIT',
    }

    export enum Status {
        APPROVED = 'APPROVED',
        CANCELLED = 'CANCELLED',
        DELETED = 'DELETED',
        ER_BATCH = 'ER_BATCH',
        ER_EXPIRED = 'ER_EXPIRED',
        ER_EXTCONN = 'ER_EXTCONN',
        ER_EXTSYS = 'ER_EXTSYS',
        ER_GENERAL = 'ER_GENERAL',
        ER_INVALID = 'ER_INVALID',
        NOTNEEDED = 'NOTNEEDED',
        PENDING = 'PENDING',
        PENDING_FOR_DATE = 'PENDING_FOR_DATE',
        PENDING_FOR_FUNDS = 'PENDING_FOR_FUNDS',
        PROCESSED = 'PROCESSED',
        REJECTED = 'REJECTED',
        SUBMITTED = 'SUBMITTED',
        VALIDATED = 'VALIDATED',
    }


}
