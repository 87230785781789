import React from "react";

type TabsNavProps = {
  active: number;
  handleChangeTab: (key: number) => void;
};

const TabsNavWrapper: React.FC<TabsNavProps> = (props) => {
  const { active, handleChangeTab } = props;

  const childrenWithProps = React.Children.map(props.children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { active, handleChangeTab });
    }

    return child;
  });

  return (
    <div className="ant-tabs button-tabs">
      <div role="tablist" className="ant-tabs-nav" style={{ margin: 0 }}>
        <div className="ant-tabs-nav-wrap">
          <div className="ant-tabs-nav-list">{childrenWithProps}</div>
        </div>
      </div>
    </div>
  );
};

type ButtonProps = {
  keyIndex: number;
  active: number;
  handleChangeTab: (key: number) => void;
};

const TabsNavButton: React.FC<ButtonProps> = (props) => {
  const { keyIndex, active, handleChangeTab } = props;

  return (
    <div
      className={`ant-tabs-tab ${keyIndex === active ? "ant-tabs-tab-active" : ""}`}
      onClick={() => handleChangeTab(keyIndex)}
    >
      <div className="ant-tabs-tab-btn">{props.children}</div>
    </div>
  );
};

export const TabsNav = {
  Wrapper: TabsNavWrapper,
  Button: TabsNavButton
}
