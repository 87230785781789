import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { history } from "data-layer/../router/AppRouter";

export const ConfirmBankDetailsSc = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get("token");

    if (token) {
      history.push(`/dashboard/settings/bank-details?token=${token}`);
    } else {
      history.push("/");
    }
  }, []);

  return null;
};
