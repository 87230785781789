import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { persistApplicationThunk } from "data-layer/application/thunks";
import { ApplicationInputDTO } from "generated/models/ApplicationInputDTO";

type UsePersistApplication = () => {
  persistApplication: (data: ApplicationInputDTO, cb?: Function) => void;
};

export const usePersistApplication: UsePersistApplication = () => {
  const dispatch = useDispatch();

  const persistApplication = useCallback(
    (data: ApplicationInputDTO, cb?: Function) => {
      dispatch(persistApplicationThunk(data, cb));
    },
    [dispatch]
  );

  return {
    persistApplication,
  };
};
