import { AppClient } from "generated/AppClient";
import { AppClient as AppClientSunset } from "generatedSunset/AppClient";
import { AppClient as AppClientCrate } from "generatedCrate/AppClient";
import { OpenAPI } from "generated";
import { OpenAPI as OpenAPISunset } from "generatedSunset";
import { OpenAPI as OpenAPICrate } from "generatedCrate";
import { baseApiPath, crateApiPath, authApiPath, sekumpulApiPath } from "./api";

// костыль - копия dautiga с подмененным урлом
export const appClientSekumpul = new AppClient({
  BASE: sekumpulApiPath,
  TOKEN: () => OpenAPI.TOKEN,
});

export const appClient = new AppClient({
  BASE: baseApiPath,
  TOKEN: () => OpenAPI.TOKEN,
});

export const appClientSunset = new AppClientSunset({
  BASE: authApiPath,
  TOKEN: () => OpenAPISunset.TOKEN,
});

export const appClientCrate = new AppClientCrate({
  BASE: crateApiPath,
  TOKEN: () => OpenAPICrate.TOKEN,
});
