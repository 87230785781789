/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NotificationCountDTO } from '../models/NotificationCountDTO';
import type { NotificationDTO } from '../models/NotificationDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { network } from 'services/network';

export class NotificationControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all (user)
     * Get all (user)
     * @param limit limit
     * @param offset offset
     * @returns NotificationDTO
     * @throws ApiError
     */
    public getAllUsingGet(
        limit: number,
        offset: number,
    ): CancelablePromise<Array<NotificationDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/notifications',
                query: {
                    'limit': limit,
                    'offset': offset,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Get all (admin)
     * Get all (admin)
     * @param limit limit
     * @param offset offset
     * @returns NotificationDTO
     * @throws ApiError
     */
    public getAllAsAdminUsingGet(
        limit: number,
        offset: number,
    ): CancelablePromise<Array<NotificationDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/notifications/admin',
                query: {
                    'limit': limit,
                    'offset': offset,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Get unread notification count (user)
     * Get unread notification count (user)
     * @returns NotificationCountDTO
     * @throws ApiError
     */
    public getUnreadNotificationsCountUsingGet(): CancelablePromise<NotificationCountDTO> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/notifications/count',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Mark as READ (All / Several notifications)
     * Mark as READ (All / Several notifications)
     * @param notificationIds notificationIds
     * @returns any
     * @throws ApiError
     */
    public markAsReadAllUsingPost(
        notificationIds?: number,
    ): CancelablePromise<any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/notifications/read',
                query: {
                    'notificationIds': notificationIds,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Mark as READ
     * Mark as READ
     * @param id id
     * @returns NotificationDTO
     * @returns any Created
     * @throws ApiError
     */
    public markAsReadUsingPost(
        id: number,
    ): CancelablePromise<NotificationDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/notifications/{id}',
                path: {
                    'id': id,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Remove notification
     * Remove notification
     * @param id id
     * @returns any
     * @throws ApiError
     */
    public removeUsingDelete(
        id: number,
    ): CancelablePromise<any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'DELETE',
                url: '/notifications/{id}',
                path: {
                    'id': id,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                },
            })
        });
    }

}