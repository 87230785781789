/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DLQMessageDTO = {
    consumerId?: string;
    created?: string;
    details?: string;
    id?: number;
    messageId?: string;
    payload?: string;
    queueId?: string;
    serviceId?: string;
    source?: DLQMessageDTO.source;
};

export namespace DLQMessageDTO {

    export enum source {
        EXTERNAL_QUEUE = 'EXTERNAL_QUEUE',
        INTERNAL_QUEUE = 'INTERNAL_QUEUE',
    }


}
