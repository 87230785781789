import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import moment from "moment";

export type Filters = {
  from: string;
  to: string;
  limit: number;
  offset: number;
};

type SearchParams = {
  from: string | null;
  to: string | null;
  limit: string | null;
  offset: string | null;
};

type SearchParamsFullfiled = {
  from: string;
  to: string;
  limit: string;
  offset: string;
};

export type UseInitParams = () => {
  filters: null | Filters;
};

export const DEFAULT_LIMIT = 20;

export const useInitParams: UseInitParams = () => {
  const [filters, setFilters] = useState<Filters | null>(null);
  const [searchParams, setSearchParams] = useSearchParams({});

  const hasDifferent = (filters: Filters, nextFilters: Filters) => {
    let hasDifferent = false;

    Object.keys(nextFilters).map((key) => {
      if (nextFilters[key] !== filters[key]) {
        hasDifferent = true;
      }
    });

    return hasDifferent;
  };

  const hasEmptyParam = (params: SearchParams) => {
    let hasEmptyParams = false;

    Object.keys(params).forEach((key: string) => {
      if (!params[key]) {
        hasEmptyParams = true;
      }
    });

    return hasEmptyParams;
  };

  const formatDataToState = (params: SearchParamsFullfiled): Filters => {
    return {
      ...params,
      limit: Number(params.limit),
      offset: Number(params.offset),
    };
  };

  useEffect(() => {
    const from = searchParams.get("from");
    const to = searchParams.get("to");
    const limit = searchParams.get("limit");
    const offset = searchParams.get("offset");

    const nextSearchParams: SearchParamsFullfiled = {
      from: from || moment().subtract(7, "d").startOf("day").toISOString(),
      to: to || moment().endOf("day").toISOString(),
      limit: String((limit && Number(limit)) || DEFAULT_LIMIT),
      offset: String((offset && Number(offset)) || 0),
    };

    if (
      hasEmptyParam({
        from,
        to,
        limit,
        offset,
      })
    ) {
      setSearchParams(nextSearchParams);
      return;
    }

    if (!filters) {
      setFilters(formatDataToState(nextSearchParams));
      return;
    }

    if (hasDifferent(filters, formatDataToState(nextSearchParams))) {
      setFilters(formatDataToState(nextSearchParams));
    }
  }, [searchParams]);

  return {
    filters,
    searchParams,
  };
};
