import { Link } from "react-router-dom";
import { useEmployee } from "data-layer/employee";
import { Styled } from "../../styles";
import { PageLogo, Typography } from "ui";
import styled from "styled-components";
import { Role } from "data-layer/common/testRoleSlice";

const H1 = styled(Typography.H1)`
  span {
    display: block;
  }
`

export const OnboardingInReviewWithVerifiedUser: React.FC = () => {
  const { employee } = useEmployee();

  return (
    <Styled.Wrapper>
      <Styled.Background />
      <Styled.Content>
        <Styled.ContentContainer>
          <Link to="/dashboard">
            <PageLogo role={Role.Merchant} partnerId={employee?.user?.inviterPartnerId} />
          </Link>
          <H1>Your application <span>has been submitted!</span></H1>
          <Styled.Description>
            <p>
              We are reviewing your application at the moment. We will email you
              at <span>{employee.user?.email}</span> when your account is set
              up.
            </p>
          </Styled.Description>

          {/* <Styled.Description>
              <Row gutter={10} align="top" wrap={false}>
                <Col>
                  <CheckCircleOutlined
                    style={{ fontSize: 30, color: "#30D158" }}
                  />
                </Col>
                <Col style={{ paddingTop: 2 }}>
                  Your email {employee.user?.email} has been verified
                </Col>
              </Row>
            </Styled.Description> */}
        </Styled.ContentContainer>
      </Styled.Content>
    </Styled.Wrapper>
  );
};
