import styled from "styled-components";
import { Divider as AntdDivider } from "antd";
import { Fonts } from "styles/fonts";
import { Colors } from "styles/colors";
import bg from "./bg.jpg";
import bg_confirm from "./bg_confirm.jpg";

const Wrapper = styled.div`
  display: flex;

  min-height: 100vh;
`;

const BackgroundShared = `
  flex-shrink: 0;

  position: relative;

  width: 47%;
  min-height: 100vh;

  background-repeat: no-repeat;
  background-size: cover;

  overflow: hidden;

  @media (max-width: 1199px) {
    width: 440px;
  }

  @media (max-width: 991px) {
    display: none;
  }
`;

const Background = styled.div`
  ${BackgroundShared}

  background-image: url(${bg});
`;

const Content = styled.div`
  flex-shrink: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 53%;
  min-height: 100vh;

  padding: 100px 72px;

  @media (max-width: 1199px) {
    flex-grow: 1;
    width: auto;
    padding: 50px 55px;
  }

  @media (max-width: 991px) {
    width: 100%;
  }

  @media (max-width: 575px) {
    padding: 50px 25px;
  }
`;

const ContentContainer = styled.div`
  max-width: 571px;

  @media (max-width: 1199px) {
    max-width: 466px;
  }

  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;

const Divider = styled(AntdDivider)`
  margin: 30px 0 25px;

  @media (max-width: 1199px) {
    margin-bottom: 15px;
  }

  @media (max-width: 575px) {
    margin-top: 10px;
  }
`;

const Error = styled.p`
  margin-bottom: 0;
  padding-top: 6px;

  font-family: ${Fonts.NexaRegular};
  font-size: 14px;
  line-height: 128%;

  color: ${Colors.Red};

  @media (max-width: 575px) {
    font-size: 12px;
  }
`;

const SubmitButton = styled.div`
  margin-top: 45px;
  margin-bottom: 33px;

  @media (max-width: 1199px) {
    margin-top: 35px;
    margin-bottom: 27px;
  }
`;

const Footer = styled.div`
  font-family: ${Fonts.NexaRegular};
  font-size: 14px;
  line-height: 20px;

  color: ${Colors.Gray};

  @media (max-width: 1199px) {
    font-size: 12px;
    line-height: 16px;
  }

  @media (max-width: 575px) {
    margin: 0 auto;
    text-align: center;
  }

  p {
    margin-bottom: 20px;

    @media (max-width: 1199px) {
      margin-bottom: 12px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  a,
  span {
    white-space: nowrap;
  }

  span {
    text-decoration: underline;
    color: #2950af;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
`;

// CONFIRM PAGES STYLE

const BackgroundConfirm = styled.div`
  ${BackgroundShared}

  background-image: url(${bg_confirm});

  @media (max-width: 1199px) {
    width: 550px;
  }

  &::after {
    content: "";

    position: absolute;

    bottom: -500px;
    right: 240px;

    width: 1000px;
    height: 880px;

    border-radius: 50%;

    background-color: ${Colors.Primary};

    opacity: 0.7;

    @media (max-width: 1199px) {
      right: 20px;
      bottom: -300px;
      width: 600px;
      height: 600px;
    }
  }
`

const ConfirmDescription = styled.p`
  fmax-width: 370px;

  margin-bottom: 45px;

  font-family: ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 135%;

  color: ${Colors.Black};

  @media (max-width: 1199px) {
    margin-bottom: 35px;
    font-size: 14px;
    line-height: 20px;
  }

  @media (max-width: 575px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const Styled = {
  Wrapper,
  Background,
  BackgroundConfirm,
  Content,
  ContentContainer,

  Divider,
  Error,
  SubmitButton,
  Footer,

  ConfirmDescription,
};
