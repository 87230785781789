import { Styled } from "./styles";
import { Typography, PageLogo } from "ui";
import { Role } from "data-layer/common/testRoleSlice";

export const SignUpConfirm: React.FC = () => {
  return (
    <Styled.Wrapper>
      <Styled.BackgroundConfirm />

      <Styled.Content>
        <Styled.ContentContainer>
          <PageLogo role={Role.Partner} />
          <Typography.H1>Confirm your email address to get started with Dapio</Typography.H1>

          <Styled.ConfirmDescription>
            We have sent a verification link to your email
          </Styled.ConfirmDescription>
        </Styled.ContentContainer>
      </Styled.Content>
    </Styled.Wrapper>
  );
};
