import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

const CheckboxItem = styled.div`
  margin-bottom: 25px;

  border-bottom: 1px solid #e5e5e5;

  @media (max-width: 1199px) {
    margin-bottom: 16px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  input {
    &[type="radio"] {
      display: none;
    }

    &:checked + label {
      &::before {
        border-color: ${Colors.Primary};
      }

      &::after {
        content: "";

        position: absolute;

        top: 3px;
        right: 28px;

        width: 12px;
        height: 12px;

        border-radius: 50%;
        background-color: ${Colors.Primary};

        @media (max-width: 1199px) {
          top: 5px;
          right: 25px;

          width: 10px;
          height: 10px;
        }
      }
    }

    &:checked ~ div {
      display: block;
    }
  }

  label {
    position: relative;

    display: block;

    padding: 0px 16px 18px;

    font-family: ${Fonts.NexaRegular};
    font-size: 20px;
    line-height: 23px;

    color: #000;

    cursor: pointer;

    @media (max-width: 1199px) {
      padding: 0px 16px 11px;
      font-size: 17px;
      line-height: 23px;
    }

    &:hover {
      &::before {
        border-color: ${Colors.GrayBlue};
      }
    }

    &::before {
      content: "";

      position: absolute;

      top: -3px;
      right: 22px;

      width: 24px;
      height: 24px;

      border: 2px solid ${Colors.LightGray};
      border-radius: 50%;

      background: #ffffff;

      @media (max-width: 1199px) {
        top: -0px;
        right: 20px;
        width: 20px;
        height: 20px;
      }
    }
  }
`;

export const Styled = {
  CheckboxItem,
};
