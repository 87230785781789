import { useDispatch } from "react-redux";
import { employeeSlice } from "../slice";

type UseSetAuthorized = () => {
  setAuthorized: VoidFunction;
};

export const useSetAuthorized: UseSetAuthorized = () => {
  const dispatch = useDispatch();

  const setAuthorized = () => {
    dispatch(employeeSlice.actions.setAuthorized());
  };

  return {
    setAuthorized,
  };
};
