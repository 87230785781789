import { useSelector } from "react-redux";
import { IPayments } from "../";
import { isFetchingSelector, paymentsDataSelector } from "../selectors";
import { useCreatePaymentLink } from "./useCreatePaymentLink";
import { useGetPayments } from "./useGetPayments";

export const usePayments = (): IPayments => {
  const { getPayments } = useGetPayments();
  const { createPaymentLink } = useCreatePaymentLink();
  const data = useSelector(paymentsDataSelector);
  const isFetching = useSelector(isFetchingSelector);

  return {
    getPayments,
    createPaymentLink,
    data,
    isFetching,
  };
};
