import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

type ActiveProp = {
  active: boolean;
};

const HeaderMobile = styled.div<ActiveProp>`
  display: none;

  padding: 13px 20px 10px;

  font-size: 14px;

  font-family: ${Fonts.NexaRegular};

  overflow: hidden;

  color: #000;

  cursor: pointer;

  @media (max-width: 767px) {
    display: block;
  }

  @media (max-width: 575px) {
    padding: 13px 15px 10px;
  }

  &::after {
    content: "";

    position: absolute;

    top: ${(props) => (props.active ? "15px" : "22px")};
    right: 20px;

    width: 12px;
    height: 12px;

    border-bottom: 2px solid ${Colors.Primary};
    border-right: 2px solid ${Colors.Primary};

    transform: ${(props) =>
      props.active ? "rotate(45deg)" : "rotate(-135deg)"};
  }
`;

const Content = styled.div<ActiveProp>`
  @media (max-width: 767px) {
    z-index: 10;

    position: absolute;

    top: 45px;
    left: 0;

    display: ${(props) => (props.active ? "block" : "none")};

    width: 100%;
    max-height: calc(100vh - 200px);

    padding-top: 6px;
    padding-bottom: 90px;

    border-top: 1px solid #00000040;
    border-bottom: 1px solid #00000040;

    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
    background-color: #fff;

    overflow-y: auto;
  }
`;

const Title = styled.p`
  margin-bottom: 0;
  padding-bottom: 3px;

  font-family: ${Fonts.NexaRegular};
  font-size: 17px;

  color: #8096a7;

  border-bottom: 1px solid #00000040;

  @media (max-width: 1919px) {
    width: calc(100% + 78px);
    margin-left: -78px;
    padding-left: 78px;
  }

  @media (max-width: 1450px) {
    padding-bottom: 2px;
    font-size: 14px;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-left: 0;
    padding: 0 20px;
  }

  @media (max-width: 575px) {
    padding: 0 15px;
  }
`;

export const Styled = {
  HeaderMobile,
  Content,
  Title,
};
