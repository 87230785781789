import {useToggle} from "common/useToggle";
import React from "react";
import {Outlet} from "react-router-dom";
import {Settlement} from "./components/Settlement";
import {MerchantDocuments} from "./components/MerchantDocuments";
import {Styled} from "./styles";
import {history} from "data-layer/../router/AppRouter";

export const Wrapper: React.FC = () => {
    const {active, handleToggle, handleClose} = useToggle();

    return (
        <Styled.Container className="container">
            <Styled.Wrapper>
                <Styled.Filters active={active}>
                    <Styled.Title active={active} onClick={handleToggle}>
                        Settings
                    </Styled.Title>
                    <Styled.List active={active}>
                        {/* <Styled.Link>General</Styled.Link> */}
                        <Styled.Link
                            onClick={() => {
                                history.push("/dashboard/settings/bank-details");
                                handleClose();
                            }}
                        >
                            Bank details
                        </Styled.Link>
                        <Styled.Link
                            onClick={() => {
                                history.push("/dashboard/settings/merchant-documents");
                                handleClose();
                            }}
                        >
                            Documents
                        </Styled.Link>
                    </Styled.List>
                </Styled.Filters>

                <Styled.Content>
                    <Outlet/>
                </Styled.Content>
            </Styled.Wrapper>
        </Styled.Container>
    );
};

export const SettingsSc = {
    Wrapper,
    Settlement,
    MerchantDocuments,
};
