import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Fonts } from "styles/fonts";

const Link = styled(NavLink)`
  display: block;

  padding: 20px 0 16px;

  font-family: ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.41px;

  text-decoration: none;

  border-bottom: 1px solid #c4c3c6;

  color: #000;

  @media (max-width: 767px) {
    padding: 15px 0 12px;
    font-size: 16px;
    letter-spacing: -0.38px;
  }

  svg {
    color: #c4c3c6;
  }
`;

export const Styled = {
  Link,
};
