import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getProfile as getProfileThunk } from 'data-layer/profile/thunks';

type UseGetProfile = () => {
    getProfile: VoidFunction;
}

export const useGetProfile: UseGetProfile = () => {
    const dispatch = useDispatch();

    const getProfile = useCallback(() => {
        dispatch(getProfileThunk());
    }, [dispatch]);

    return {
        getProfile,
    }
}
