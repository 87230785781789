import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { commingSoonModalSlice } from "../commingSoonModalSlice";
import { isCommingSoonModalVisibleSelector } from "../selectors";

export type UseCommingSoonModal = () => {
  isCommingSoonModalVisible: boolean;
  showCommingSoonModal: VoidFunction;
  hideCommingSoonModal: VoidFunction;
};

export const useCommingSoonModal: UseCommingSoonModal = () => {
  const dispatch = useDispatch();

  const isCommingSoonModalVisible = useSelector(
    isCommingSoonModalVisibleSelector
  );

  const showCommingSoonModal = useCallback(() => {
    dispatch(commingSoonModalSlice.actions.showModal());
  }, [dispatch]);

  const hideCommingSoonModal = useCallback(() => {
    dispatch(commingSoonModalSlice.actions.hideModal());
  }, [dispatch]);

  return {
    isCommingSoonModalVisible,
    showCommingSoonModal,
    hideCommingSoonModal,
  };
};
