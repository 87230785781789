import { appClientSekumpul, network } from "services/network";
import { ISekumpulApi } from "./index";

export const api: ISekumpulApi = {
  upload: (formData) => {
    return network.request(() => {
      return appClientSekumpul.request.request({
        method: "POST",
        url: "/upload",
        formData: formData,
        mediaType: "multipart/form-data",
        headers: {
          Accept: '*/*',
        },
        errors: {
          401: `Unauthorized`,
          403: `Forbidden`,
          404: `Not Found`,
        },
      });
    });
  },

  // upload: (formData) => {
  //   return network.request(() => {
  //     return appClientSekumpul.request.request({
  //       method: "POST",
  //       url: "/upload",
  //       formData: formData,
  //       mediaType: "multipart/form-data",
  //       headers: {
  //         Accept: '*/*',
  //       },
  //       errors: {
  //         401: `Unauthorized`,
  //         403: `Forbidden`,
  //         404: `Not Found`,
  //       },
  //     });
  //   });
  // },
};
