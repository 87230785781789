import { CancelablePromise } from "generated/core/CancelablePromise";
import { api } from "./api";
import { TransactionRestDTO } from "generated/models/TransactionRestDTO";
import { TransactionsSellerDashboardDTO } from "generated";

export interface ITransactionsApi {
  getTransactions: (
    limit: number,
    offset: number,
    // types: TransactionRestDTO.type[],
    types: any,
    amountMax?: number,
    amountMin?: number,
    employeeIds?: Array<number>,
    employeeName?: string,
    from?: string,
    merchantIds?: Array<number>,
    orderBy0Direction?: 'ASC' | 'DESC',
    orderBy0Field?: string,
    partnerIds?: Array<number>,
    payoutIds?: Array<number>,
    to?: string,
  ) => CancelablePromise<Array<TransactionRestDTO>>;
  getTransactionsAmount: (
    merchantIds: number,
    from: string,
    to: string,
    aggregateBy: 'EMPLOYEE' | 'PROVIDER',
    aggregateTime: 'DAY' | 'DAY_OF_WEEK' | 'MONTH' | 'YEAR',
  ) => CancelablePromise<Array<TransactionsSellerDashboardDTO>>;
  getTransactionsAverage: (
    merchantIds: number,
    from: string,
    to: string,
    aggregateBy: 'EMPLOYEE' | 'PROVIDER',
    aggregateTime: 'DAY' | 'DAY_OF_WEEK' | 'MONTH' | 'YEAR',
  ) => CancelablePromise<Array<TransactionsSellerDashboardDTO>>;
}

class TransactionsApi {
  api: ITransactionsApi = api;

  constructor() {
    if (process.env.REACT_APP_MOCK_ENABLED === "true") {
      this.api = api;
      return;
    }

    this.api = api;
  }
}

export const transactionsApi = new TransactionsApi();
