import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

type ActiveProp = {
  active: boolean;
}

const HeaderMobile = styled.div<ActiveProp>`
  display: none;

  padding: 30px 20px 25px;

  font-size: 20px;
  line-height: 14px;

  font-family: ${Fonts.NexaRegular};

  overflow: hidden;

  color: #8096A7;

  cursor: pointer;

  @media (max-width: 575px) {
    padding-left: 15px;
  }

  &::after {
    content: '';

    position: absolute;

    top: ${props => props.active ? '25px' : '32px'};
    right: 20px;

    width: 12px;
    height: 12px;

    border-bottom: 2px solid ${Colors.Primary};
    border-right: 2px solid ${Colors.Primary};

    transform: ${props => props.active ? 'rotate(45deg)' : 'rotate(-135deg)'};

    @media (max-width: 575px) {
      right: 15px;
    }
  }

  @media (max-width: 991px) {
    display: block;
  }
`

const Content = styled.div<ActiveProp>`
  @media (max-width: 991px) {
    display: ${props => props.active ? "block" : "none"};
  }
`

const Title = styled.p`
  margin-bottom: 0;

  font-family: ${Fonts.NexaRegular};
  font-size: 20px;

  color: #8096a7;

  @media (max-width: 991px) {
    padding-left: 20px;
  }

  @media (max-width: 575px) {
    padding-left: 15px;
  }
`;

const FiltersRadioGroup = styled.div`
  .ant-radio-group {
    width: 100%;
  }

  .ant-radio-button-wrapper {
    width: 100%;

    padding: 16px 0 32px !important;

    border-top: 1px solid #1a2233;

    opacity: 1;

    @media (max-width: 1919px) {
      width: calc(100% + 78px);
      margin-left: -78px;
      padding-left: 78px !important;
    }

    @media (max-width: 991px) {
      width: 100%;
      margin-left: 0;
      padding: 16px 20px 32px !important;
    }

    @media (max-width: 575px) {
      padding-left: 15px !important;
    }
  }

  .ant-col:last-child {
    .ant-radio-button-wrapper {
      border-bottom: 1px solid #1a2233;
    }
  }

  .ant-radio-button-wrapper-checked {
    background-color: #8096A7;

    &::before {
      content: "";

      z-index: -1;

      position: absolute;

      top: 0;
      right: 0;
      left: -2000px;

      height: 100%;

      background: #8096A7;

      @media (max-width: 1919px) {
        content: none;
      }
    }
  }
`;

const ListItem = styled.p`
  margin-bottom: 105px;

  font-family: ${Fonts.NexaRegular};
  font-size: 20px;

  color: #fff;

  opacity: 1;
`;

const Period = styled.div`
  padding-bottom: 20px;
`

const FiltersDatepicker = styled.div<{selected: boolean}>`
  border-top: 1px solid #1a2233;
  border-bottom: 1px solid #1a2233;

  @media (max-width: 1919px) {
    width: calc(100% + 78px);
    margin-left: -78px;
    padding-left: 78px;
  }

  @media (max-width: 991px) {
    width: 100%;
    margin-left: 0;
    padding-left: 20px;
  }

  @media (max-width: 575px) {
    padding-left: 15px;
  }

  .ant-picker {
    justify-content: flex-start;

    width: 100%;

    padding: 0;

    border: none;

    background-color: transparent;
  }

  .ant-picker-range-separator {
    width: 7px;
    height: 2px;

    margin: 0 3px;
    padding: 0;

    background-color: #fff;

    span {
      display: none;
    }
  }

  .ant-picker-input {
    width: 95px;
    padding: 12px 0 7px;
  }

  input {
    font-family: ${Fonts.NexaRegular};
    font-size: 20px;

    color: #fff;
  }

  .ant-picker-active-bar {
    display: none;
  }

  .ant-picker-suffix {
    flex-shrink: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 58px;
    height: 50px;
    margin-bottom: 0px;
    margin-left: auto;

    font-size: 30px;
    color: #76899b;

    background-color: #0000001A;
  }

  .ant-picker-clear {
    right: 0 !important;
  
    display: flex;
    justify-content: center;
    align-items: center;

    width: 58px;
    height: 50px;

    background-color: #0000001a;

    color: #76899b;

    opacity: ${props => props.selected ? '1' : '0'};
  }
`

export const Styled = {
  HeaderMobile,
  Content,
  Title,
  FiltersRadioGroup,
  ListItem,
  Period,
  FiltersDatepicker
};
