import React from "react";
import { Styled } from "./styled";
import { Spin } from "antd";
import { formatMoney } from "utils/numberFormatter";

interface IActivityItem {
  title?: string | number;
  text?: string | number;
  isLoading: boolean;
}

export const ActivityItem: React.FC<IActivityItem> = ({
  title,
  text,
  isLoading,
}) => {
  return (
    <>
      <Styled.ActivityItemWrapper>
        <Styled.ActivityItemTitle>
          {isLoading ? (
            <Spin style={{ textAlign: "left" }} />
          ) : title !== undefined && title !== null ? (
            formatMoney({ value: title })
          ) : (
            "Unknown"
          )}
        </Styled.ActivityItemTitle>
        <Styled.ActivityItemLabel>{text}</Styled.ActivityItemLabel>
        <Styled.StatisticCountWrapper>
          <Styled.StatisticCountText>-</Styled.StatisticCountText>
        </Styled.StatisticCountWrapper>
        <Styled.StatisticDateText>last week</Styled.StatisticDateText>
      </Styled.ActivityItemWrapper>
    </>
  );
};
