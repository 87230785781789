import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { getPartnerMeThunk } from "../thunks";

type UseMe = () => {
  me: () => void;
};

export const useMe: UseMe = () => {
  const dispatch = useDispatch();

  const me = useCallback(() => {
    dispatch(getPartnerMeThunk());
  }, [dispatch]);

  return {
    me,
  };
};
