/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * 'value' or 'complexValue' should be filled
 */
export type ApplicationFieldInputDTO = {
    id: ApplicationFieldInputDTO.id;
    /**
     * More complex object, example: person
     */
    objectValue?: Record<string, string>;
    value?: string;
};

export namespace ApplicationFieldInputDTO {

    export enum id {
        ACCEPT_ONLINE_PAYMENTS = 'ACCEPT_ONLINE_PAYMENTS',
        ACCEPT_ONLINE_PAYMENTS_EXPERIENCE = 'ACCEPT_ONLINE_PAYMENTS_EXPERIENCE',
        ACCEPT_ONLINE_PAYMENTS_MCC_CODE = 'ACCEPT_ONLINE_PAYMENTS_MCC_CODE',
        ACCEPT_ONLINE_PAYMENTS_PROVIDER = 'ACCEPT_ONLINE_PAYMENTS_PROVIDER',
        ACCOUNT_HOLDER_NAME = 'ACCOUNT_HOLDER_NAME',
        ACCOUNT_NUMBER = 'ACCOUNT_NUMBER',
        ADDRESS_LINE_1 = 'ADDRESS_LINE_1',
        ADDRESS_LINE_2 = 'ADDRESS_LINE_2',
        ANNUAL_FINANCIAL_REPORT = 'ANNUAL_FINANCIAL_REPORT',
        AVERAGE_TX_VALUE = 'AVERAGE_TX_VALUE',
        BUSINESS_CATEGORY = 'BUSINESS_CATEGORY',
        BUSINESS_EXPLANATION = 'BUSINESS_EXPLANATION',
        BUSINESS_OPERATE_AREA = 'BUSINESS_OPERATE_AREA',
        TOTAL_SALES = 'TOTAL_SALES',
        PRODUCT_SERVICE_SOLD = 'PRODUCT_SERVICE_SOLD',
        CITY = 'CITY',
        COMPANY_NAME = 'COMPANY_NAME',
        COMPANY_NUMBER = 'COMPANY_NUMBER',
        COMPANY_STATUS = 'COMPANY_STATUS',
        COUNTRY = 'COUNTRY',
        COUNTY = 'COUNTY',
        CURRENCY = 'CURRENCY',
        DBA = 'DBA',
        DOB = 'DOB',
        EMAIL = 'EMAIL',
        EXPECTED_TURNOVER = 'EXPECTED_TURNOVER',
        FACEBOOK_LINK = 'FACEBOOK_LINK',
        FEE_PERCENT = 'FEE_PERCENT',
        GOOGLE_LINK = 'GOOGLE_LINK',
        HAVE_PAYMENT_TERMINALS = 'HAVE_PAYMENT_TERMINALS',
        IBAN = 'IBAN',
        INDIVIDUAL_NAME = 'INDIVIDUAL_NAME',
        INDIVIDUAL_SURNAME = 'INDIVIDUAL_SURNAME',
        INSTAGRAM_LINK = 'INSTAGRAM_LINK',
        LINKEDIN_LINK = 'LINKEDIN_LINK',
        MAX_PAYMENT = 'MAX_PAYMENT',
        MCC = 'MCC',
        MID_PREFIX = 'MID_PREFIX',
        MONTHLY_TURNOVER = 'MONTHLY_TURNOVER',
        NATIONALITY = 'NATIONALITY',
        NOT_POLITICIAN = 'NOT_POLITICIAN',
        OFFICERS = 'OFFICERS',
        OFFICER_DOB = 'OFFICER_DOB',
        OFFICER_NAME = 'OFFICER_NAME',
        OFFICER_RESIDENCE = 'OFFICER_RESIDENCE',
        OFFICER_SURNAME = 'OFFICER_SURNAME',
        OFFICE_ADDRESS_LINE_1 = 'OFFICE_ADDRESS_LINE_1',
        OFFICE_ADDRESS_LINE_2 = 'OFFICE_ADDRESS_LINE_2',
        OFFICE_CITY = 'OFFICE_CITY',
        OFFICE_COUNTRY = 'OFFICE_COUNTRY',
        OFFICE_COUNTY = 'OFFICE_COUNTY',
        OFFICE_POSTAL_CODE = 'OFFICE_POSTAL_CODE',
        OWNERS = 'OWNERS',
        OWNERSHIP_STRUCTURE_COMPANY_FORM = 'OWNERSHIP_STRUCTURE_COMPANY_FORM',
        OWNERSHIP_STRUCTURE_COMPANY_NAME = 'OWNERSHIP_STRUCTURE_COMPANY_NAME',
        OWNERSHIP_STRUCTURE_INDIVIDUAL_DOB = 'OWNERSHIP_STRUCTURE_INDIVIDUAL_DOB',
        OWNERSHIP_STRUCTURE_INDIVIDUAL_NAME = 'OWNERSHIP_STRUCTURE_INDIVIDUAL_NAME',
        OWNERSHIP_STRUCTURE_INDIVIDUAL_RESIDENCE = 'OWNERSHIP_STRUCTURE_INDIVIDUAL_RESIDENCE',
        OWNERSHIP_STRUCTURE_INDIVIDUAL_SURNAME = 'OWNERSHIP_STRUCTURE_INDIVIDUAL_SURNAME',
        OWNERSHIP_STRUCTURE_PERCENT = 'OWNERSHIP_STRUCTURE_PERCENT',
        OWNERSHIP_STRUCTURE_TYPE = 'OWNERSHIP_STRUCTURE_TYPE',
        PAYMENT_HISTORY = 'PAYMENT_HISTORY',
        PERSONS = 'PERSONS',
        PERSON_COUNTY = 'PERSON_COUNTY',
        PERSON_ADDRESS_LINE_1 = 'PERSON_ADDRESS_LINE_1',
        PERSON_ADDRESS_LINE_2 = 'PERSON_ADDRESS_LINE_2',
        PERSON_AUTHORIZED = 'PERSON_AUTHORIZED',
        PERSON_CITY = 'PERSON_CITY',
        PERSON_DOB = 'PERSON_DOB',
        PERSON_FIRST_NAME = 'PERSON_FIRST_NAME',
        PERSON_LAST_NAME = 'PERSON_LAST_NAME',
        PERSON_EMAIL = 'PERSON_EMAIL',
        PERSON_PHONE_NUMBER = 'PERSON_PHONE_NUMBER',
        PERSON_NAME = 'PERSON_NAME',
        PERSON_NATIONALITY = 'PERSON_NATIONALITY',
        PERSON_OWNERSHIP = 'PERSON_OWNERSHIP',
        PERSON_POSITION = 'PERSON_POSITION',
        PERSON_POSTAL_CODE = 'PERSON_POSTAL_CODE',
        PERSON_RESIDENCE = 'PERSON_RESIDENCE',
        PERSON_STATUS = 'PERSON_STATUS',
        PERSON_SURNAME = 'PERSON_SURNAME',
        PERSON_TYPE = 'PERSON_TYPE',
        PHONE_NUMBER = 'PHONE_NUMBER',
        POB_CITY = 'POB_CITY',
        POB_COUNTRY = 'POB_COUNTRY',
        POB_REGION = 'POB_REGION',
        POSTAL_CODE = 'POSTAL_CODE',
        PRIMARY_CONTACT_FIRST_NAME = 'PRIMARY_CONTACT_FIRST_NAME',
        PRIMARY_CONTACT_LAST_NAME = 'PRIMARY_CONTACT_LAST_NAME',
        PRIMARY_CONTACT_PHONE_NUMBER = 'PRIMARY_CONTACT_PHONE_NUMBER',
        PROOF_ADDRESS = 'PROOF_ADDRESS',
        PROOF_IDENTITY_FIRST = 'PROOF_IDENTITY_FIRST',
        PROOF_IDENTITY_HOLDING = 'PROOF_IDENTITY_HOLDING',
        PROOF_IDENTITY_OTHER = 'PROOF_IDENTITY_OTHER',
        PROOF_IDENTITY_SECOND = 'PROOF_IDENTITY_SECOND',
        PROOF_PASSPORT = 'PROOF_PASSPORT',
        REGISTRATION_DATE = 'REGISTRATION_DATE',
        RESIDENCE = 'RESIDENCE',
        SCHEMA_VERSION = 'SCHEMA_VERSION',
        SORT_CODE = 'SORT_CODE',
        TERMINALS_AMOUNT = 'TERMINALS_AMOUNT',
        TERMINALS_AMOUNT_EXPECTED = 'TERMINALS_AMOUNT_EXPECTED',
        TERMINALS_EXPERIENCE = 'TERMINALS_EXPERIENCE',
        TERMINALS_MCC_CODE = 'TERMINALS_MCC_CODE',
        TERMINALS_PROVIDER = 'TERMINALS_PROVIDER',
        TID_PREFIX = 'TID_PREFIX',
        TIME_ZONE = 'TIME_ZONE',
        TYPE_OF_BUSINESS = 'TYPE_OF_BUSINESS',
        VAT_ID = 'VAT_ID',
        VAT_NUMBER = 'VAT_NUMBER',
        WEBSITE_LINK = 'WEBSITE_LINK',
    }


}
