import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { getDictionaryThunk } from "../thunks";

type UseGetDictionary = () => {
  getDictionary: () => void;
};

export const useGetDictionary: UseGetDictionary = () => {
  const dispatch = useDispatch();

  const getDictionary = useCallback(() => {
    dispatch(getDictionaryThunk());
  }, [dispatch]);

  return {
    getDictionary,
  };
};
