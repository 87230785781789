import { UpdatePartnerDTO } from "generated";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { updatePartnerThunk } from "../thunks";

type UseUpdatePartner = () => {
  updatePartner: (body: UpdatePartnerDTO) => void;
};

export const useUpdatePartner: UseUpdatePartner = () => {
  const dispatch = useDispatch();

  const updatePartner = useCallback(
    (body) => {
      dispatch(updatePartnerThunk(body));
    },
    [dispatch]
  );

  return {
    updatePartner,
  };
};
