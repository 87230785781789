import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { changePasswordThunk } from "data-layer/employee/thunks";

type UseChangePassword = () => {
  changePassword: (token: string, password: string, isPartner?: boolean) => void;
};

export const useChangePassword: UseChangePassword = () => {
  const dispatch = useDispatch();

  const changePassword = useCallback(
    (token: string, password: string, isPartner?: boolean) => {
      dispatch(changePasswordThunk(token, password, isPartner));
    },
    [dispatch]
  );

  return {
    changePassword,
  };
};
