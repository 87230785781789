import { useMerchant } from "data-layer/merchant";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { history } from "data-layer/../router/AppRouter";

export const useUpdate = () => {
  const { updateSettlements } = useMerchant();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get("token");

    if (token) {
      updateSettlements(token, () => {
        history.push("/dashboard/settings/bank-details");
      });
    }
  }, []);
};
