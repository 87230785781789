import styled from "styled-components";

const Img = styled.img`
  max-width: 100%;
  max-height: 123px;
  width: auto;
  height: auto;

  @media (max-width: 1199px) {
    max-height: 103px;
  }
`

export const Styled = {
  Img,
};
