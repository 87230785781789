import { AppDispatch } from "data-layer/store/store";
import { dictionarySlice } from "./slice";
import { dictionaryApi } from "./api";
import { loaderSlice } from "data-layer/common/loaderSlice";

export const getDictionaryThunk = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      const result = await dictionaryApi.api.getDictionary();
      dispatch(dictionarySlice.actions.dictionarySuccess(result));
    } catch (e) {
      throw e;
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};
