/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ModelAndView } from '../models/ModelAndView';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { network } from 'services/network';

export class BasicErrorControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * errorHtml
     * @returns ModelAndView OK
     * @throws ApiError
     */
    public errorHtmlUsingGet(): CancelablePromise<ModelAndView> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/error',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * errorHtml
     * @returns ModelAndView OK
     * @returns any Created
     * @throws ApiError
     */
    public errorHtmlUsingPut(): CancelablePromise<ModelAndView | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'PUT',
                url: '/error',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * errorHtml
     * @returns ModelAndView OK
     * @returns any Created
     * @throws ApiError
     */
    public errorHtmlUsingPost(): CancelablePromise<ModelAndView | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/error',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * errorHtml
     * @returns ModelAndView OK
     * @throws ApiError
     */
    public errorHtmlUsingDelete(): CancelablePromise<ModelAndView> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'DELETE',
                url: '/error',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                },
            })
        });
    }

    /**
     * errorHtml
     * @returns ModelAndView OK
     * @throws ApiError
     */
    public errorHtmlUsingOptions(): CancelablePromise<ModelAndView> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'OPTIONS',
                url: '/error',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                },
            })
        });
    }

    /**
     * errorHtml
     * @returns ModelAndView OK
     * @throws ApiError
     */
    public errorHtmlUsingHead(): CancelablePromise<ModelAndView> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'HEAD',
                url: '/error',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                },
            })
        });
    }

    /**
     * errorHtml
     * @returns ModelAndView OK
     * @throws ApiError
     */
    public errorHtmlUsingPatch(): CancelablePromise<ModelAndView> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'PATCH',
                url: '/error',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                },
            })
        });
    }

}