import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

const List = styled.ul`
  list-style-type: none;

  display: flex;

  margin-bottom: 0;
  padding-left: 0;

  @media (max-width: 991px) {
    display: none;
  }
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  white-space: nowrap;

  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  a,
  span {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 38px;
    padding: 3px 50px 0;

    font-family: ${Fonts.NexaRegular};
    font-size: 17px;
    line-height: 100%;
    text-decoration: none;

    color: ${Colors.GrayBlue};

    @media (max-width: 1450px) {
      height: 30px;
      padding: 3px 30px 0;
      font-size: 14px;
      line-height: 20px;
    }

    &.active {
      display: inline-flex;

      font-family: ${Fonts.NexaRegular};

      border-radius: 28px;

      background-color: ${Colors.Primary};

      color: #fff;
    }
  }

  a[disabled] {
    opacity: 0.7;
  }
`;

export const Styled = {
  List,
  Item,
};
