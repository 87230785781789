import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

const Wrapper = styled.div`
  width: 100%;
  max-width: 571px;
`;

const Button = styled.div`
  margin-top: 20px;
  margin-bottom: 35px;

  @media (max-width: 767px) {
    margin-top: 10px;
    margin-bottom: 30px;
  }
`;

const Description = styled.div`
  max-width: 370px;

  font-size: 17px;
  font-family: ${Fonts.NexaRegular};
  line-height: 135%;

  color: ${Colors.GrayBlue};

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 142%;
  }
`;

const Svg = styled.div`
  font-size: 30px;

  color: ${Colors.GrayBlue};

  @media (max-width: 767px) {
    font-size: 25px;
  }
`;

export const Styled = {
  Wrapper,

  Button,
  Svg,
  Description,
};
