import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const WrapperImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100px;
  height: 100px;

  margin-bottom: 18px;

  border-radius: 50%;

  background-color: ${Colors.Primary20}; // #FE8E7533
`;

const Img = styled.img`
  width: 56px;
`;

const P = styled.p`
  font-family: ${Fonts.NexaRegular};
  font-size: 20px;
  line-height: 25px;
  color: #000000;
`;

export const Styled = {
  Wrapper,
  WrapperImg,
  Img,
  P,
};
