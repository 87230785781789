import { Modal } from "antd";
import { loaderSlice } from "data-layer/common/loaderSlice";
import { AppDispatch } from "data-layer/store/store";
import { PaymentLinkDTO } from "generated";
import { handleNetworkError } from "utils/handleNetworkError";
import { showError } from "utils/showError";
import { showSuccess } from "utils/showSuccess";
import { paymentsApi } from "./api";
import { paymentsSlice } from "./slice";
import { PaymentsParams } from "./types/PaymentsParams";

export const getPaymentsThunk = (params: PaymentsParams) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      const result = await paymentsApi.api.getPayments(params);

      dispatch(paymentsSlice.actions.getPaymentsSuccess(result));
    } catch (e) {
      handleNetworkError(e, "Payments loading error");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};

export const createPaymentLinkThunk = (
  data: PaymentLinkDTO,
  onCreateLinkCb: (data: PaymentLinkDTO) => void
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      const result = await paymentsApi.api.createPaymentLink(data);

      // dispatch(paymentsSlice.actions.createPaymentLinkSuccess(result));
      showSuccess("Payment link created");
      onCreateLinkCb(result);
      // dispatch(paymentsSlice.actions.getPaymentsSuccess(result));
    } catch (e) {
      handleNetworkError(e, "Payment creation error");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};
