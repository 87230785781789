import { OpenAPI } from "generated";
import { OpenAPI as OpenAPISunset } from "generatedSunset";
import { OpenAPI as OpenAPICrate } from "generatedCrate";

export const clearUser = () => {
  OpenAPI.TOKEN = "";
  OpenAPISunset.TOKEN = "";
  OpenAPICrate.TOKEN = "";
  localStorage.removeItem("employee");
};
