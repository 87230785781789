import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "../store";

export const profileSelector = (state: AppState) => state.profile;

export const profilesSelector = (state: AppState) => state.profile.profiles;

export const dataProfilesForPartnerSelector = (state: AppState) => state.profile.dataProfilesForPatner;

export const selectedProfileSelector = createSelector(
  profilesSelector,
  (profiles) => {
    const selectedProfile = profiles && profiles.find(
      (profile) => profile.selected === true
    );

    return selectedProfile;
  }
);
