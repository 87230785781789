import React from "react";
import { Col, Row } from "antd";
import * as Yup from "yup";
import { Styled as OnboardingStyled } from "../../onboardingStyles";
import { CompanyStepProps, StepProps } from "../../OnboardingSc";
import { CompanySteps } from "../../useSteps";
import { useDictionary } from "data-layer/dictionary";
import {
  AverageTxValue,
  AverageTxValueMap,
  ExpectedTurnover,
  ExpectedTurnoverMap,
  PaymentHistory,
  PaymentHistoryMap,
} from "data-layer/application/types";
import { useApplication } from "data-layer/application";
import { Styled } from "./cardStyles";
import { Button } from "components";

export const schema = Yup.object().shape({
  verify: Yup.string().required("Required"),
});

type ReviewCompantStepProps = CompanyStepProps & StepProps & {
  changeStep: (index: number) => void;
};

export const ReviewCompanyStep: React.FC<ReviewCompantStepProps> = (props) => {
  const { initData, changeStep, prevStep } = props;
  const { countries, businessTypes } = useDictionary();
  const { finishApplication } = useApplication();

  return (
    <div>
      <OnboardingStyled.Title>
        Please review and submit your application
      </OnboardingStyled.Title>

      <OnboardingStyled.Content>
        <Styled.Wrapper>
          <Styled.Header>
            <Styled.Title>Business type</Styled.Title>
            <Styled.Controls>
              <Styled.Button
                size="small"
                onClick={() => changeStep(CompanySteps.BusinessTypeStep)}
                type="primary-transparent"
                block
              >
                Edit
              </Styled.Button>
            </Styled.Controls>
          </Styled.Header>

          <Styled.Content>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Type:</Col>
                <Col span={12}>{initData.typeOfBusiness || "-"}</Col>
              </Row>
            </Styled.ContentItem>
          </Styled.Content>
        </Styled.Wrapper>

        <Styled.Wrapper>
          <Styled.Header>
            <Styled.Title>Company description</Styled.Title>
            <Styled.Controls>
              <Styled.Button
                size="small"
                onClick={() => changeStep(CompanySteps.DescriptionStep)}
                type="primary-transparent"
                block
              >
                Edit
              </Styled.Button>
            </Styled.Controls>
          </Styled.Header>

          <Styled.Content>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Company name:</Col>
                <Col span={12}>{initData.COMPANY_NAME || "-"}</Col>
              </Row>
            </Styled.ContentItem>

            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Company number:</Col>
                <Col span={12}>{initData.COMPANY_NUMBER || "-"}</Col>
              </Row>
            </Styled.ContentItem>

            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Company status:</Col>
                <Col span={12}>{initData.COMPANY_STATUS || "-"}</Col>
              </Row>
            </Styled.ContentItem>

            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Trading as:</Col>
                <Col span={12}>{initData.DBA || "-"}</Col>
              </Row>
            </Styled.ContentItem>

            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Phone number:</Col>
                <Col span={12}>{initData.PHONE_NUMBER || "-"}</Col>
              </Row>
            </Styled.ContentItem>

            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Email address:</Col>
                <Col span={12}>{initData.EMAIL || "-"}</Col>
              </Row>
            </Styled.ContentItem>

            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Contact person First Name:</Col>
                <Col span={12}>
                  {initData.PRIMARY_CONTACT_FIRST_NAME || "-"}
                </Col>
              </Row>
            </Styled.ContentItem>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Contact person Last Name:</Col>
                <Col span={12}>{initData.PRIMARY_CONTACT_LAST_NAME || "-"}</Col>
              </Row>
            </Styled.ContentItem>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Contact person phone number:</Col>
                <Col span={12}>
                  {initData.PRIMARY_CONTACT_PHONE_NUMBER || "-"}
                </Col>
              </Row>
            </Styled.ContentItem>

            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Country:</Col>
                <Col span={12}>
                  {countries?.find((country) => country.id === initData.COUNTRY)
                    ?.name || "-"}
                </Col>
              </Row>
            </Styled.ContentItem>

            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Registered address:</Col>
                <Col span={12}>
                  {initData.ADDRESS_LINE_1 || "-"}
                  {initData.ADDRESS_LINE_2 ? `, ${initData.ADDRESS_LINE_2}` : ''}
                </Col>
              </Row>
            </Styled.ContentItem>

            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Town/City:</Col>
                <Col span={12}>{initData.CITY || "-"}</Col>
              </Row>
            </Styled.ContentItem>

            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Postcode:</Col>
                <Col span={12}>{initData.POSTAL_CODE || "-"}</Col>
              </Row>
            </Styled.ContentItem>

            <Styled.ContentItem>
              <Styled.ContentItemGroupTitle>
                Trading address:
              </Styled.ContentItemGroupTitle>
            </Styled.ContentItem>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Country:</Col>
                <Col span={12}>
                  {countries?.find(
                    (country) => country.id === initData.OFFICE_COUNTRY
                  )?.name || "-"}
                </Col>
              </Row>
            </Styled.ContentItem>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Registered address:</Col>
                <Col span={12}>
                  {initData.OFFICE_ADDRESS_LINE_1 || "-"}
                  {initData.OFFICE_ADDRESS_LINE_2 ? `, ${initData.OFFICE_ADDRESS_LINE_2}` : ''}
                </Col>
              </Row>
            </Styled.ContentItem>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Town/City:</Col>
                <Col span={12}>{initData.OFFICE_CITY || "-"}</Col>
              </Row>
            </Styled.ContentItem>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Postcode:</Col>
                <Col span={12}>{initData.OFFICE_POSTAL_CODE || "-"}</Col>
              </Row>
            </Styled.ContentItem>

            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>
                  What category best describes what you'll use Dapio for?:
                </Col>
                <Col span={12}>
                  {businessTypes?.find(
                    (bt) => bt.id === initData.TYPE_OF_BUSINESS
                  )?.text || "-"}
                </Col>
              </Row>
            </Styled.ContentItem>
          </Styled.Content>
        </Styled.Wrapper>

        <Styled.Wrapper>
          <Styled.Header>
            <Styled.Title>Business activities</Styled.Title>
            <Styled.Controls>
              <Styled.Button
                size="small"
                onClick={() => changeStep(CompanySteps.BusinessActivitesStep)}
                type="primary-transparent"
                block
              >
                Edit
              </Styled.Button>
            </Styled.Controls>
          </Styled.Header>

          <Styled.Content>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>What’s your expected annual turnover?:</Col>
                <Col span={12}>
                  {ExpectedTurnoverMap[
                    initData.EXPECTED_TURNOVER as ExpectedTurnover
                  ] || "-"}
                </Col>
              </Row>
            </Styled.ContentItem>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>
                  How long have you been processing card payments?:
                </Col>
                <Col span={12}>
                  {PaymentHistoryMap[
                    initData.PAYMENT_HISTORY as PaymentHistory
                  ] || "-"}
                </Col>
              </Row>
            </Styled.ContentItem>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>
                  How much do you estimate an average card payment withDapio to
                  be?:
                </Col>
                <Col span={12}>
                  {AverageTxValueMap[
                    initData.AVERAGE_TX_VALUE as AverageTxValue
                  ] || "-"}
                </Col>
              </Row>
            </Styled.ContentItem>
          </Styled.Content>
        </Styled.Wrapper>

        {initData.PERSONS?.length
          ? initData.PERSONS.map((person) => (
              <Styled.Wrapper>
                <Styled.Header>
                  <Styled.Title>Business owner</Styled.Title>
                  <Styled.Controls>
                    <Styled.Button
                      size="small"
                      onClick={() =>
                        changeStep(CompanySteps.BusinessOwnersStep)
                      }
                      type="primary-transparent"
                      block
                    >
                      Edit
                    </Styled.Button>
                  </Styled.Controls>
                </Styled.Header>

                <Styled.Content>
                  <Styled.ContentItem>
                    <Row wrap={false} justify="space-between">
                      <Col span={12}>Name:</Col>
                      <Col span={12}>
                        {person.PERSON_FIRST_NAME} {person.PERSON_LAST_NAME}
                      </Col>
                    </Row>
                  </Styled.ContentItem>
                  <Styled.ContentItem>
                    <Row wrap={false} justify="space-between">
                      <Col span={12}>Position held in company:</Col>
                      <Col span={12}>
                        {person.PERSON_POSITION || "Director"}
                      </Col>
                    </Row>
                  </Styled.ContentItem>
                </Styled.Content>
              </Styled.Wrapper>
            ))
          : null}
      </OnboardingStyled.Content>

      <OnboardingStyled.Footer>
        <Row gutter={{ xs: 10, sm: 10, md: 25 }}>
          <Col>
            <Button type="secondary-transparent" onClick={() => prevStep()}>
              Back
            </Button>
          </Col>
          <Col flex={1}>
            <Button
              type="primary-transparent"
              onClick={finishApplication}
              block
            >
              Accept and complete
            </Button>
          </Col>
        </Row>
      </OnboardingStyled.Footer>
    </div>
  );
};
