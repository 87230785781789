/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { JsonNode } from './JsonNode';
import type { RoleDTO } from './RoleDTO';

export type AdminUserDTO = {
    countryId?: number;
    created?: string;
    email: string;
    extraData?: JsonNode;
    id: number;
    inviterPartnerId?: number;
    lastFrt?: string;
    lastFrtWeb?: string;
    name: string;
    roles?: Array<RoleDTO>;
    status: AdminUserDTO.status;
    subscribe: boolean;
    surname: string;
    verified?: boolean;
};

export namespace AdminUserDTO {

    export enum status {
        ACTIVATED = 'ACTIVATED',
        FAILED_LOGIN = 'FAILED_LOGIN',
        INVITED = 'INVITED',
        SUSPENDED = 'SUSPENDED',
    }


}
