import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EmployeeDTO } from "generated/models/EmployeeDTO";

export type Employees = EmployeeDTO[] | [];

export type EmployeesInitialState = {
  data: Employees;
  isFetching: boolean;
};

const initialState: EmployeesInitialState = {
  data: [],
  isFetching: false,
};

export const employeesSlice = createSlice({
  name: "employees",
  initialState: initialState,
  reducers: {
    getEmployeesRequest: (state) => {
      state.isFetching = true;
    },
    getEmployeesSuccess: (state, action: PayloadAction<Array<EmployeeDTO>>) => {
      state.data = action.payload;
      state.isFetching = false;
    },
    getEmployeesFailure: (state) => {
      state.isFetching = false;
    },

    createEmployeeSuccess: (state, action: PayloadAction<EmployeeDTO>) => {
      const data = [...state.data, action.payload];
      state.data = data;
    },

    updateEmployee: (state, action: PayloadAction<EmployeeDTO>) => {
      state.data = state.data.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload;
        }

        return item;
      });
    },
  },
});

export const employeesActions = employeesSlice.actions;
