import { useSelector } from "react-redux";
import { IDocumentRequest } from "../";
import { activeModalIdSelector, documentsSelector, newDocumentsDataByProfileSelector } from "../selectors";
import { useGetDocumentRequestById } from "./useGetDocumentRequestById";
import { useGetDocumentsByProfile } from "./useGetDocumentsByProfile";
import { useModal } from "./useModal";
import { useSendToReview } from "./useSendToReview";

export const useDocumentRequest: IDocumentRequest = () => {
  const documentsState = useSelector(documentsSelector);

  const { getDocumentsByProfile } = useGetDocumentsByProfile();
  const newDocumentsByProfile = useSelector(newDocumentsDataByProfileSelector);

  const activeModalId = useSelector(activeModalIdSelector);
  const { sendToReview } = useSendToReview();
  const { getDocumentRequestById } = useGetDocumentRequestById();
  const { showModal, closeModal } = useModal();

  return {
    documentsState,
    newDocumentsByProfile,
    getDocumentsByProfile,

    sendToReview,
    getDocumentRequestById,

    activeModalId,
    showModal,
    closeModal,
  };
};
