import { AppInputs } from "generated/modelsWrapper/AppInputs";
import * as Yup from "yup";

export const identityVerificationSchema = Yup.object().shape({
  [AppInputs.PROOF_IDENTITY_FIRST]: Yup.number()
    .default(null)
    .nullable()
    .required("Required"),
  [AppInputs.PROOF_ADDRESS]: Yup.number()
    .default(null)
    .nullable()
    .required("Required"),
});

export const identityVerificationSoloTraderSchema = Yup.object()
  .shape({})
  .concat(identityVerificationSchema);

export const identityVerificationCompanySchema = Yup.object().shape({
  [AppInputs.PERSONS]: Yup.array().of(
    Yup.object().shape({}).concat(identityVerificationSchema)
  ),
});
