import { Link } from "react-router-dom";
import { Styled } from "./styles";
import { Typography, PageLogo } from "ui";
import { Role } from "data-layer/common/testRoleSlice";

export const PasswordRecoveryScView: React.FC<{role: Role}> = (props) => {
  return (
    <Styled.Wrapper>
      <Styled.Background />
      <Styled.Content>
        <Styled.ContentContainer>
          <Link to="/dashboard">
            <PageLogo role={props.role} />
          </Link>

          <Typography.H1>Password recovery</Typography.H1>

          {props.children}
        </Styled.ContentContainer>
      </Styled.Content>
    </Styled.Wrapper>
  );
};
