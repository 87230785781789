/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmployeePermissionDTO } from '../models/EmployeePermissionDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { network } from 'services/network';

export class EmployeePermissionControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get list of employee permissions
     * Get list of employee permissions
     * @returns EmployeePermissionDTO
     * @throws ApiError
     */
    public getEmployeePermissionsUsingGet(): CancelablePromise<Array<EmployeePermissionDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/employee-permissions',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

}