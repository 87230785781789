import React, { useState } from "react";
import { Styled } from "./styles";
import { Fonts } from "styles/fonts";
import { Colors } from "styles/colors";
import { Button, FormItem } from "components";
import { Input } from "formik-antd";
import { Formik } from "formik";
import { Col, Menu, Row } from "antd";
import type { MenuProps } from "antd";
import { Typography } from "ui";
import { ProfileMenuSc as ProfileMenuScSeller } from "scenarios/seller";
import { ProfileMenuSc as ProfileMenuScPartner } from "scenarios/seller";

// Colors

const ColorsComponent = () => {
  return (
    <Styled.Item>
      <Row gutter={20}>
        <Col span={12}>
          <Typography.H1>Main colors</Typography.H1>
          <Styled.ColorItem color={Colors.Primary}>
            {Colors.Primary} Primary
          </Styled.ColorItem>
          <Styled.ColorItem color={Colors.PrimaryDark}>
            {Colors.PrimaryDark} PrimarDark
          </Styled.ColorItem>
          <Styled.ColorItem color={Colors.Secondary}>
            {Colors.Secondary} Secondary
          </Styled.ColorItem>
          <Styled.ColorItem color={Colors.GrayBlue}>
            {Colors.GrayBlue} GreyBlue
          </Styled.ColorItem>
          <Styled.ColorItem color={Colors.Black}>
            {Colors.Black} Black
          </Styled.ColorItem>
        </Col>
        <Col span={12}>
          <Typography.H1>System colors</Typography.H1>
          <Styled.ColorItem color={Colors.Green}>
            {Colors.Green} Green
          </Styled.ColorItem>
          <Styled.ColorItem color={Colors.Red}>
            {Colors.Red} Red
          </Styled.ColorItem>
          <Styled.ColorItem color={Colors.Yellow}>
            {Colors.Yellow} Yellow
          </Styled.ColorItem>
          <Styled.ColorItem color={Colors.Blue}>
            {Colors.Blue} Blue
          </Styled.ColorItem>
          <Styled.ColorItem color={Colors.LightBlue}>
            {Colors.LightBlue} LightBlue
          </Styled.ColorItem>
        </Col>
        <Col span={12}>
          <Typography.H1>Grayscale color</Typography.H1>
          <Styled.ColorItem color={Colors.Gray}>
            {Colors.Gray} Gray
          </Styled.ColorItem>
          <Styled.ColorItem color={Colors.LightGray}>
            {Colors.LightGray} LightGray
          </Styled.ColorItem>
        </Col>
        <Col span={12}>
          <Typography.H1>Transparency color</Typography.H1>
          <Styled.ColorItem color={Colors.Primary70}>
            {Colors.Primary70} Primary70
          </Styled.ColorItem>
          <Styled.ColorItem color={Colors.Primary40}>
            {Colors.Primary40} Primary40
          </Styled.ColorItem>
          <Styled.ColorItem color={Colors.Primary20}>
            {Colors.Primary20} Primary20
          </Styled.ColorItem>

          <Styled.ColorItem color={Colors.Black25}>
            {Colors.Black25} Black25
          </Styled.ColorItem>
          <Styled.ColorItem color={Colors.Black10}>
            {Colors.Black10} Black10
          </Styled.ColorItem>
        </Col>
      </Row>
    </Styled.Item>
  );
};

// Buttons

const ButtonsComponent = () => {
  return (
    <Styled.Item>
      <Row gutter={[0, 20]}>
        <Col span={8}>
          <Row gutter={[0, 20]} style={{ flexDirection: "column" }}>
            <Col>
              <Button type="primary">Primary noSize</Button>
            </Col>
            <Col>
              <Button type="primary" size="small">
                Primary small
              </Button>
            </Col>
            <Col>
              <Button type="primary" disabled>
                Primary noSize disabled
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row gutter={[0, 20]} style={{ flexDirection: "column" }}>
            <Col>
              <Button type="primary-dots">Primary-dots noSize</Button>
            </Col>
            <Col>
              <Button type="primary-dots" size="small">
                Primary-dots small
              </Button>
            </Col>
            <Col>
              <Button type="primary-dots" disabled>
                Primary-dots noSize disabled
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row gutter={[0, 20]} style={{ flexDirection: "column" }}>
            <Col>
              <Button type="primary-transparent">
                Primary-transparent noSize
              </Button>
            </Col>
            <Col>
              <Button type="primary-transparent" size="small">
                Primary-transparent small
              </Button>
            </Col>
            <Col>
              <Button type="primary-transparent" disabled>
                Primary-transparent noSize disabled
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row gutter={[0, 20]} style={{ flexDirection: "column" }}>
            <Col>
              <Button type="secondary">Secondary noSize</Button>
            </Col>
            <Col>
              <Button type="secondary" size="small">
                Secondary small
              </Button>
            </Col>
            <Col>
              <Button type="secondary" disabled>
                Secondary noSize disabled
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row gutter={[0, 20]} style={{ flexDirection: "column" }}>
            <Col>
              <Button type="secondary-dots">Secondary-dots noSize</Button>
            </Col>
            <Col>
              <Button type="secondary-dots" size="small">
                Secondary-dots small
              </Button>
            </Col>
            <Col>
              <Button type="secondary-dots" disabled>
                Secondary-dots noSize disabled
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row gutter={[0, 20]} style={{ flexDirection: "column" }}>
            <Col>
              <Button type="secondary-transparent">
                Secondary-transparent noSize
              </Button>
            </Col>
            <Col>
              <Button type="secondary-transparent" size="small">
                Secondary-transparent small
              </Button>
            </Col>
            <Col>
              <Button type="secondary-transparent" disabled>
                Secondary-transparent noSize disabled
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row gutter={[0, 20]} style={{ flexDirection: "column" }}>
            <Col>
              <Button type="gray-transparent">Gray-transparent noSize</Button>
            </Col>
            <Col>
              <Button type="gray-transparent" size="small">
                Gray-transparent small
              </Button>
            </Col>
            <Col>
              <Button type="gray-transparent" disabled>
                Gray-transparent noSize disabled
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Styled.Item>
  );
};

// Typography

const H1Component = () => {
  return (
    <Styled.Item>
      <Typography.H1>H1</Typography.H1>

      <Styled.CodeWrapper>
        <Styled.Code>
          font-family: '{Fonts.NexaRegular}';
          <br />
          font-size: 50px;
          <br />
          line-height: 120%;
          <br />
          color: {Colors.Black};{" "}
          <span style={{ color: Colors.Black }}>(Black)</span>
          <br />
        </Styled.Code>
      </Styled.CodeWrapper>
    </Styled.Item>
  );
};

const H2Component = () => {
  return (
    <Styled.Item>
      <Typography.H2>H2</Typography.H2>

      <Styled.CodeWrapper>
        <Styled.Code>
          font-family: '{Fonts.NexaRegular}';
          <br />
          font-size: 25px;
          <br />
          line-height: 25px;
          <br />
          color: {Colors.Black};{" "}
          <span style={{ color: Colors.Black }}>(Black)</span>
          <br />
        </Styled.Code>
      </Styled.CodeWrapper>
    </Styled.Item>
  );
};

const Body1Component = () => {
  return (
    <Styled.Item>
      <Typography.Body1>Body1</Typography.Body1>

      <Styled.CodeWrapper>
        <Styled.Code>
          font-family: '{Fonts.NexaRegular}';
          <br />
          font-size: 20px;
          <br />
          line-height: 20px;
          <br />
          color: {Colors.Black};{" "}
          <span style={{ color: Colors.Black }}>(Black)</span>
          <br />
        </Styled.Code>
      </Styled.CodeWrapper>
    </Styled.Item>
  );
};

const Body2Component = () => {
  return (
    <Styled.Item>
      <Typography.Body2>Body2</Typography.Body2>

      <Styled.CodeWrapper>
        <Styled.Code>
          font-family: '{Fonts.NexaRegular}';
          <br />
          font-size: 17px;
          <br />
          line-height: 17px;
          <br />
          color: {Colors.Black};{" "}
          <span style={{ color: Colors.Black }}>(Black)</span>
          <br />
        </Styled.Code>
      </Styled.CodeWrapper>
    </Styled.Item>
  );
};

const CaptionComponent = () => {
  return (
    <Styled.Item>
      <Typography.Caption>Caption</Typography.Caption>

      <Styled.CodeWrapper>
        <Styled.Code>
          font-family: '{Fonts.NexaRegular}';
          <br />
          font-size: 14px;
          <br />
          line-height: 14px;
          <br />
          color: {Colors.Black};{" "}
          <span style={{ color: Colors.Black }}>(Black)</span>
          <br />
        </Styled.Code>
      </Styled.CodeWrapper>
    </Styled.Item>
  );
};

// FormItem

const FormItemDefault = () => {
  return (
    <Styled.Item>
      <Formik
        initialValues={{}}
        validateOnChange={true}
        validateOnMount={true}
        enableReinitialize
        onSubmit={() => {}}
      >
        {() => {
          return (
            <FormItem label="Email" error={undefined}>
              <Input name="email" placeholder="example@domain.com" />
            </FormItem>
          );
        }}
      </Formik>
    </Styled.Item>
  );
};

const FormItemWithNullLabel = () => {
  return (
    <Styled.Item>
      <Formik
        initialValues={{}}
        validateOnChange={true}
        validateOnMount={true}
        enableReinitialize
        onSubmit={() => {}}
      >
        {() => {
          return (
            <FormItem label={null} error={undefined}>
              <Input name="email" placeholder="example@domain.com" />
            </FormItem>
          );
        }}
      </Formik>
    </Styled.Item>
  );
};

const FormItemWithError = () => {
  return (
    <Styled.Item>
      <Formik
        initialValues={{}}
        validateOnChange={true}
        validateOnMount={true}
        enableReinitialize
        onSubmit={() => {}}
      >
        {() => {
          return (
            <FormItem label="Email" error="Error">
              <Input name="email" placeholder="example@domain.com" />
            </FormItem>
          );
        }}
      </Formik>
    </Styled.Item>
  );
};

const FormItemWithLongError = () => {
  return (
    <Styled.Item>
      <Formik
        initialValues={{}}
        validateOnChange={true}
        validateOnMount={true}
        enableReinitialize
        onSubmit={() => {}}
      >
        {() => {
          return (
            <FormItem
              label="Email"
              error="Long error Long error Long error Long error Long error Long error"
              longError={true}
            >
              <Input name="email" placeholder="example@domain.com" />
            </FormItem>
          );
        }}
      </Formik>
    </Styled.Item>
  );
};

// Profile

const ProfileSeller = () => {
  return (
    <Styled.Item>
      <ProfileMenuScSeller />
    </Styled.Item>
  );
};

const ProfilePartner = () => {
  return (
    <Styled.Item>
      <ProfileMenuScPartner />
    </Styled.Item>
  );
};

// Forms

const Forms = () => {
  return (
    <>
      <Styled.Item>
        <Formik
          initialValues={{}}
          validateOnChange={true}
          validateOnMount={true}
          enableReinitialize
          onSubmit={() => {}}
        >
          {() => {
            return (
              <FormItem label="Email" error={undefined}>
                <Input name="email" placeholder="example@domain.com" />
              </FormItem>
            );
          }}
        </Formik>
      </Styled.Item>

      <Styled.Item>
        <Formik
          initialValues={{}}
          validateOnChange={true}
          validateOnMount={true}
          enableReinitialize
          onSubmit={() => {}}
        >
          {() => {
            return (
              <FormItem label="Email" error={undefined}>
                <Input name="email" placeholder="example@domain.com" />
              </FormItem>
            );
          }}
        </Formik>
      </Styled.Item>

      <Styled.Item>
        <Formik
          initialValues={{}}
          validateOnChange={true}
          validateOnMount={true}
          enableReinitialize
          onSubmit={() => {}}
        >
          {() => {
            return (
              <FormItem label="Email" error={undefined}>
                <Input name="email" placeholder="example@domain.com" />
              </FormItem>
            );
          }}
        </Formik>
      </Styled.Item>
    </>
  );
};

export const StyleGuideSc: React.FC = () => {
  const [current, setCurrent] = useState<string | null>(null);

  const onClick: MenuProps["onClick"] = (e) => {
    setCurrent(e.key);
  };

  return (
    <Styled.Wrapper>
      <Row gutter={20}>
        <Col span={4}>
          <Menu onClick={onClick}>
            <Menu.Item key="colors">Colors</Menu.Item>
            <Menu.Item key="buttons">Buttons</Menu.Item>
            <Menu.Item key="typography">Typography</Menu.Item>
            <Menu.SubMenu title="FormItem">
              <Menu.Item key="formItemDefault">FormItem default</Menu.Item>
              <Menu.Item key="formItemWithNullLabel">
                FormItem with null label
              </Menu.Item>
              <Menu.Item key="formItemWithError">FormItem with error</Menu.Item>
              <Menu.Item key="formItemWithLongError">
                FormItem with long error
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.Item key="profileSeller">Profile Seller</Menu.Item>
            <Menu.Item key="profilePartner">Profile Partner</Menu.Item>
            <Menu.Item key="forms">Forms</Menu.Item>
          </Menu>
        </Col>

        <Col span={20}>
          {current === "colors" && <ColorsComponent />}

          {current === "buttons" && <ButtonsComponent />}

          {current === "typography" && (
            <>
              <H1Component />
              <H2Component />
              <Body1Component />
              <Body2Component />
              <CaptionComponent />
            </>
          )}

          {current === "formItemDefault" && <FormItemDefault />}
          {current === "formItemWithNullLabel" && <FormItemWithNullLabel />}
          {current === "formItemWithError" && <FormItemWithError />}
          {current === "formItemWithLongError" && <FormItemWithLongError />}

          {current === "profileSeller" && <ProfileSeller />}
          {current === "profilePartner" && <ProfilePartner />}

          {current === "forms" && <Forms />}
        </Col>
      </Row>
    </Styled.Wrapper>
  );

  // <Styled.Item>
  //   <Button type="primary" block onClick={() => {}}>
  //     Sign in
  //   </Button>

  //   <Button type="secondary" block onClick={() => {}}>
  //     Sign in
  //   </Button>

  //   <code>height: {formItemHeight}px;</code>
  // </Styled.Item>
};
