import { AppDispatch } from "data-layer/store/store";
import { loaderSlice } from "data-layer/common/loaderSlice";
import { documentRequestApi } from ".";
import { SendToReviewDocumentRequestDTO } from "generated";
import { showError } from "utils/showError";
import { documentSlice } from "./slice";
import { showSuccess } from "utils/showSuccess";
import { getProfile } from "data-layer/profile/thunks";
import { handleNetworkError } from "utils/handleNetworkError";

export const getDocumenRequestByIdThunk = (id: number) => {
  return async (dispatch: AppDispatch) => {
    dispatch(documentSlice.actions.getDocumentByIdRequest(id));

    try {
      const result = await documentRequestApi.api.getDocumentRequestById(id);
      dispatch(documentSlice.actions.getDocumentByIdSuccess(result));
    } catch (e) {
      dispatch(documentSlice.actions.getDocumentByIdFailure(id));
    } finally {
    }
  };
};

export const getDocumentsByProfileThunk = (profileId: number) => {
  return async (dispatch: AppDispatch) => {
    dispatch(documentSlice.actions.getDocumentsByProfileRequest());

    try {
      const result = await documentRequestApi.api.getDocumentsByProfileToRequest(
        profileId
      );
      dispatch(documentSlice.actions.getDocumentsByProfileSuccess(result));
    } catch (e) {
      dispatch(documentSlice.actions.getDocumentsByProfileFailure());
      handleNetworkError(e, "Form submit error");
    } finally {
    }
  };
};

export const sendToReviewThunk = (
  body: SendToReviewDocumentRequestDTO,
  cb?: VoidFunction
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      const result = await documentRequestApi.api.sendToReview(body);
      dispatch(documentSlice.actions.updateDocumentByProfile(result));
      showSuccess("Documents sent for review");
      await dispatch(getProfile());

      if (cb && typeof cb === "function") {
        cb();
      }
    } catch (e) {
      handleNetworkError(e, "Form submit error");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};
