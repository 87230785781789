import { TransactionsSeller } from "../../../../data-layer/transaction/slice";
import moment from "moment";

interface UseDateRange {
  currentRange: number;
  data: TransactionsSeller;
}

export const useDateRange = ({ currentRange, data }: UseDateRange) => {
  const rangeArr = getMomentRange(currentRange);

  const rangeChartData = getDataForChart(rangeArr, data);

  return {
    rangeChartData,
  };
};

const getMomentRange = (range: number) => {
  const rangeArr = [];
  for (let i = 0; i < range; i++) {
    rangeArr.push(moment().subtract(i, "days"));
  }

  return rangeArr;
};

const getDataForChart = (
  rangeArr: moment.Moment[],
  data: TransactionsSeller
) => {
  return rangeArr.map((date) => {
    const dayOfWeek = date.format("ddd").toUpperCase();
    let payByLinkAverage = 0;
    let tapToPayAverage = 0;

    data.forEach((item) => {
      if (date.isSame(item.date, "day")) {
        item.provider === "TapToPay"
          ? (tapToPayAverage += item.value!)
          : (payByLinkAverage += item.value!);
      }
    });
    return {
      name: dayOfWeek,
      payByLinkAverage,
      tapToPayAverage,
    };
  });
};
