/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SysPropertyDTO } from '../models/SysPropertyDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { network } from 'services/network';

export class SysPropertyControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Find properties
     * Find properties by KEY (LIKE)
     * @param key key
     * @param limit limit
     * @param offset offset
     * @returns SysPropertyDTO
     * @throws ApiError
     */
    public findUsingGet(
        key: string,
        limit: number,
        offset: number,
    ): CancelablePromise<Array<SysPropertyDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/system/properties',
                query: {
                    'key': key,
                    'limit': limit,
                    'offset': offset,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Update property
     * Update property
     * @param requestBody
     * @returns SysPropertyDTO
     * @returns any Created
     * @throws ApiError
     */
    public updateUsingPut(
        requestBody?: SysPropertyDTO,
    ): CancelablePromise<SysPropertyDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'PUT',
                url: '/system/properties',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Create property
     * Create property
     * @param key key
     * @param value value
     * @returns SysPropertyDTO
     * @returns any Created
     * @throws ApiError
     */
    public createUsingPost(
        key: string,
        value: string,
    ): CancelablePromise<SysPropertyDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/system/properties',
                query: {
                    'key': key,
                    'value': value,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Delete property
     * Delete property
     * @param id id
     * @returns any
     * @throws ApiError
     */
    public deleteUsingDelete(
        id: number,
    ): CancelablePromise<any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'DELETE',
                url: '/system/properties/{id}',
                path: {
                    'id': id,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                },
            })
        });
    }

}