import { ImportFlatApplicationRequestDTO } from "generated";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { importThunk } from "../thunks";
import { ParsedResult } from "../types/ParsedResult";

type UseImport = () => {
  importFile: (
    body: ImportFlatApplicationRequestDTO,
    onParseResult: (data: ParsedResult) => void
  ) => void;
};

export const useImport: UseImport = () => {
  const dispatch = useDispatch();

  const importFile = useCallback(
    (body, onError) => {
      dispatch(importThunk(body, onError));
    },
    [dispatch]
  );

  return {
    importFile,
  };
};
