import { useEmployee } from "data-layer/employee";
import { useNotifications } from "data-layer/notifications";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useEffect } from "react";

export const useInitNotifications = (isCacheChecked: boolean, isAuthTokenChecked: boolean) => {
  const { getNotifications, showNotification } = useNotifications();
  const { employee } = useEmployee();
  const { updateFrtWeb } = useEmployee();

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FAIRBASE_API_KEY,
    authDomain: process.env.REACT_APP_FAIRBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FAIRBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FAIRBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FAIRBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FAIRBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FAIRBASE_APP_ID,
    measurementId: process.env.REACT_APP_FAIRBASE_MEASUREMENT_ID,
  };

  const registerWorker = () => {
    navigator.serviceWorker.register("/firebase-messaging-sw.js");
  };

  useEffect(() => {
    function requestPermission() {
      if (!("Notification" in window)) {
      } else if (Notification.permission === "granted") {
        console.log("Notification permission granted.");
      } else if (Notification.permission !== "denied") {
        console.log("Requesting permission...");

        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            console.log("Notification permission granted.");
          }
        });
      }
    }

    requestPermission();
  }, []);

  useEffect(() => {
    if (!isCacheChecked || !isAuthTokenChecked) {
      return;
    }

    if (!employee?.authorized) {
      return;
    }

    async function init() {
      const fapp = initializeApp(firebaseConfig);
      const messaging = getMessaging(fapp);

      if ("serviceWorker" in navigator) {
        window.addEventListener("load", registerWorker);
      }

      getToken(messaging, {
        vapidKey:
          "BBWoXBNAhAvbODZyO7nyG81TGvy4TCaCw-Dh3h9_GvsIhnh_lFZYumiU5sLuJlfEbj8g6CjkFcRTB4iFSfaIckk",
      })
        .then((currentToken) => {
          if (currentToken) {
            console.log("Firebase Token", currentToken);

            updateFrtWeb(currentToken);

            onMessage(messaging, (payload) => {
              console.log("Message received. ", payload);

              showNotification(payload);
              getNotifications({
                limit: 20,
                offset: 0,
              });
            });
          } else {
            console.log(
              "No registration token available. Request permission to generate one."
            );
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
        });
    }

    init();

    return () => {
      window.removeEventListener("load", registerWorker);
    };
  }, [isCacheChecked, isAuthTokenChecked, employee?.authorized]);
};
