import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PaymentsParams } from "data-layer/payments/types/PaymentsParams";
import { keysEqual } from "utils/keysEqual";
import moment from "moment";

type SearchParamsFullfiled = {
  from: string;
  to: string;
  limit: string;
  offset: string;
  customerName: string;
  amountMin: string;
  amountMax: string;
  status: 'CREATED' | 'EXPIRED' | 'PAID';
};

export type UseInitParams = () => {
  filters: PaymentsParams | null;
};

export const DEFAULT_LIMIT = 20;

export const useInitParams: UseInitParams = () => {
  const [filters, setFilters] = useState<PaymentsParams | null>(null);
  const [searchParams, setSearchParams] = useSearchParams({});

  const hasEmptyRequiredParam = (params: { [key: string]: string | null }) => {
    let hasEmptyParams = false;

    Object.keys(params).forEach((key: string) => {
      if (!params[key]) {
        hasEmptyParams = true;
      }
    });

    return hasEmptyParams;
  };

  const hasDifferent = (
    filters: PaymentsParams,
    nextFilters: PaymentsParams
  ) => {
    if (!keysEqual(filters, nextFilters)) {
      return true;
    }

    let hasDifferent = false;

    Object.keys(nextFilters).map((key) => {
      if (nextFilters[key] !== filters[key]) {
        hasDifferent = true;
      }
    });

    return hasDifferent;
  };

  const formatDataToState = (params: SearchParamsFullfiled): PaymentsParams => {
    const { amountMin, amountMax, ...rest } = params;

    const data: PaymentsParams = {
      ...rest,
      limit: Number(params.limit),
      offset: Number(params.offset),
    };

    if (params.amountMin) {
      data.amountMin = Number(params.amountMin);
    }

    if (params.amountMax) {
      data.amountMax = Number(params.amountMax);
    }

    return data;
  };

  useEffect(() => {
    const from = searchParams.get("from");
    const to = searchParams.get("to");
    const limit = searchParams.get("limit");
    const offset = searchParams.get("offset");
    const customerName = searchParams.get("customerName");
    const amountMin = searchParams.get("amountMin");
    const amountMax = searchParams.get("amountMax");
    const status = searchParams.get("status");

    const nextSearchParams: SearchParamsFullfiled = {
      from: from || moment().subtract(7, "d").startOf("day").toISOString(), 
      to: to || moment().endOf("day").toISOString(),
      limit: String((limit && Number(limit)) || DEFAULT_LIMIT),
      offset: String((offset && Number(offset)) || 0),
    };

    if (customerName) {
      nextSearchParams.customerName = customerName;
    }

    if (amountMin) {
      nextSearchParams.amountMin = amountMin;
    }

    if (amountMax) {
      nextSearchParams.amountMax = amountMax;
    }

    if (status) {
      nextSearchParams.status = status;
    }

    if (
      hasEmptyRequiredParam({
        from,
        to,
        limit,
        offset,
      })
    ) {
      setSearchParams(nextSearchParams);
      return;
    }

    if (!filters) {
      setFilters(formatDataToState(nextSearchParams));
      return;
    }

    if (hasDifferent(filters, formatDataToState(nextSearchParams))) {
      setFilters(formatDataToState(nextSearchParams));
    }
  }, [searchParams]);

  return {
    filters,
    searchParams,
  };
};
