import styled from "styled-components";
import { Colors } from "styles/colors";

const Primary = styled.span`
  color: ${Colors.Primary};
`;

export const Styled = {
  Primary,
};
