import { Col, Row } from "antd";
import React from "react";
import { Styled } from "./viewStyles";
import {
  BusinessOwnerValues,
  OwnerTypeEnum,
} from "data-layer/application/types";
import { AppInputs } from "generated/modelsWrapper/AppInputs";
import { CloseOutlined } from "@ant-design/icons";
import { FormikErrors } from "formik";

type BusinessOwnersViewProps = {
  owner: BusinessOwnerValues;
  selectOwner: VoidFunction;
  removeOwner: VoidFunction;
  errors?: FormikErrors<BusinessOwnerValues>;
};

const formatDate = (date: Date) => {
  const dateFormated = new Date(date!).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return dateFormated;
};

export const BusinessOwnersView: React.FC<BusinessOwnersViewProps> = (
  props
) => {
  const { owner, selectOwner, removeOwner, errors } = props;

  const fields = {
    "First name": AppInputs.PERSON_FIRST_NAME,
    "Last name": AppInputs.PERSON_LAST_NAME,
    "Date of birth": AppInputs.PERSON_DOB,
    Email: AppInputs.PERSON_EMAIL,
    "Phone number": AppInputs.PERSON_PHONE_NUMBER,
    Address: AppInputs.PERSON_ADDRESS_LINE_1,
    Postcode: AppInputs.PERSON_POSTAL_CODE,
    Nationality: AppInputs.PERSON_NATIONALITY,
    "Company ownership": AppInputs.PERSON_OWNERSHIP,
    "Authorised Signatory": AppInputs.PERSON_AUTHORIZED,
    Status: AppInputs.PERSON_STATUS,
    "Position held in company": AppInputs.PERSON_POSITION,
  };

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.Title>
          {owner.PERSON_FIRST_NAME} {owner.PERSON_LAST_NAME}
        </Styled.Title>
        <Styled.Controls>
          <Row gutter={10} wrap={false}>
            <Col>
              <Styled.Button
                size="small"
                onClick={selectOwner}
                type="primary-transparent"
                block
                style={{}}
              >
                Edit
              </Styled.Button>
            </Col>
            <Col>
              <Styled.Control onClick={removeOwner}>
                <CloseOutlined />
              </Styled.Control>
            </Col>
          </Row>
        </Styled.Controls>
      </Styled.Header>

      <Styled.Content>
        {Object.keys(fields).map((key) => {
          let strValue = owner[fields[key]]?.toString();

          switch (key) {
            case "Position held in company": {
              if (!owner.PERSON_STATUS.includes(OwnerTypeEnum.Officer)) {
                return null;
              }
              break;
            }
            case "Status": {
              strValue = owner[fields[key]]?.toString().split(",").join(", ");
              break;
            }
            case "Date of birth": {
              if (!owner[fields[key]]) {
                strValue = "-";
                break;
              }
              strValue = formatDate(owner[fields[key]]);
              break;
            }
            case "Phone number": {
              strValue = owner[fields[key]] ? `+${owner[fields[key]]}` : "-"
              break;
            }
            case "Company ownership": {
              strValue = owner[fields[key]] || "- " + "%";
              break;
            }
            case "Address": {
              strValue = owner[fields[key]];
              break;
            }
          }

          return (
            <div key={key}>
              <Styled.ContentItem>
                <Row wrap={false} justify="space-between">
                  <Col span={12}>
                    <Styled.ContentItemTitle>{key}:</Styled.ContentItemTitle>
                  </Col>
                  <Col span={12} style={{ textAlign: "right" }}>
                    {key === "Address" ? (
                      <>
                        <Styled.ContentItemText>
                          {strValue}
                        </Styled.ContentItemText>
                        {owner[AppInputs.PERSON_ADDRESS_LINE_2] && ", "}
                        <Styled.ContentItemText>
                          {owner[AppInputs.PERSON_ADDRESS_LINE_2]?.toString()}
                        </Styled.ContentItemText>
                      </>
                    ) : (
                      <Styled.ContentItemText>
                        {strValue}
                      </Styled.ContentItemText>
                    )}
                  </Col>
                </Row>

                {errors
                  ? errors[fields[key]] && (
                      <Styled.ContentItemError>
                        {errors[fields[key]]}
                      </Styled.ContentItemError>
                    )
                  : null}
              </Styled.ContentItem>
            </div>
          );
        })}
      </Styled.Content>
    </Styled.Wrapper>
  );
};
