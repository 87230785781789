import { Modal } from "components";
import { Styled } from "./styles";
import { UserAddOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "components";
import { ParseImportFileResultDTO } from "generated";
import { TableSc } from "scenarios/common";
import { useColumns } from "./useColumns";
import { useSubmit } from "./useSubmit";
import "./styles.less";
import { Col, Row, Tabs } from "antd";
import { ParsedResult } from "data-layer/merchantImport/types/ParsedResult";
const { TabPane } = Tabs;

type BulkUploadViewModalProps = {
  visible: boolean;
  onClose: VoidFunction;
  data: ParseImportFileResultDTO | null;
  dataResult: ParsedResult | null;
  onBack: VoidFunction;
  handleParseResult: (data: ParsedResult) => void;
};

export const BulkUploadViewModal: React.FC<BulkUploadViewModalProps> = (
  props
) => {
  const { visible, onClose, data, dataResult, onBack, handleParseResult } =
    props;
  const { columns } = useColumns(data);
  const { handleSubmit } = useSubmit();

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      className="bulk-upload-view-modal"
    >
      <Styled.Wrapper>
        <Styled.Icon>
          <Styled.Svg>
            <UserAddOutlined />
          </Styled.Svg>
        </Styled.Icon>

        <Styled.BackIcon onClick={onBack}>
          <Styled.BackIconSvg>
            <ArrowLeftOutlined />
          </Styled.BackIconSvg>
        </Styled.BackIcon>

        <Styled.Title>Bulk upload new merchants</Styled.Title>

        {!dataResult && data && (
          <Row gutter={[0, 70]}>
            <Col span={24}>
              <TableSc
                data={data?.parsed}
                columns={columns}
                scroll={{ x: 1400 }}
                hideApiPaginaiton={true}
              />
            </Col>
            <Col span={24}>
              <Row>
                <Styled.TotalCol>
                  <Styled.Total>{data?.parsed?.length} merchants</Styled.Total>
                </Styled.TotalCol>
                <Styled.ButtonCol>
                  <Button
                    block
                    type="primary-transparent"
                    onClick={() =>
                      handleSubmit(data.parsed!, handleParseResult)
                    }
                  >
                    Send invites
                  </Button>
                </Styled.ButtonCol>
              </Row>
            </Col>
          </Row>
        )}

        {dataResult && (
          <div style={{ position: "relative" }}>
            <Tabs
              defaultActiveKey="1"
              tabPosition="top"
              className="button-tabs bulkupload-tabs"
              style={{ margin: "0 auto" }}
            >
              <TabPane tab="Uploaded" key="1">
                <>
                  <Styled.TableTitle style={{ marginBottom: 25 }}>{dataResult.success.length} Merchants uploaded</Styled.TableTitle>
                  <TableSc
                    data={dataResult?.success}
                    columns={columns}
                    scroll={{ x: 1400 }}
                    hideApiPaginaiton={true}
                  />
                </>
              </TabPane>
              <TabPane tab="Bounced" key="2">
                <>
                  <Styled.TableTitle style={{ marginBottom: 25 }}>{dataResult.failed.length} Merchants bounced</Styled.TableTitle>
                  <TableSc
                    data={dataResult?.failed}
                    columns={columns}
                    scroll={{ x: 1400 }}
                    hideApiPaginaiton={true}
                  />
                </>
              </TabPane>
            </Tabs>
          </div>
        )}

        {/* {dataResult && (
          <div>
            <SuccessTable data={dataResult} columns={columns} />
            <FailedTable data={dataResult} columns={columns} />
          </div>
        )} */}

        {/* <Formik
          initialValues={initialValues}
          validationSchema={schema}
          validateOnChange={false}
          onSubmit={(values) => {
            console.log("values", values);
            // handleSubmit(values.file!);
          }}
        >
          {({ errors, values, setFieldValue, submitForm, handleSubmit }) => {
            return (
              <Form
                onSubmit={handleSubmit}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
              >
                <Row gutter={[0, 30]}>
                  <Col span={24}></Col>

                  <Col span={24}>
                    <Button
                      block
                      type="primary"
                      onClick={submitForm}
                    >
                      Send invite’s
                    </Button>
                  </Col>
                </Row>
                <div></div>
              </Form>
            );
          }}
        </Formik> */}
      </Styled.Wrapper>
    </Modal>
  );
};
