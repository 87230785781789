import { NavLink } from "react-router-dom";
import { Styled } from "./styles";

export const Navigator: React.FC = () => {
  return (
    <Styled.List>
      <Styled.Item>
        <NavLink
          end
          to="/dashboard"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          Overview
        </NavLink>
      </Styled.Item>
      <Styled.Item>
        <NavLink
          to="/dashboard/onboarding"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          Onboarding
        </NavLink>
      </Styled.Item>
      <Styled.Item>
        <NavLink
          to="/dashboard/merchants"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          Merchants
        </NavLink>
      </Styled.Item>
      <Styled.Item>
        <NavLink
          to="/dashboard/settings/bank-details"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          Settlement
        </NavLink>
      </Styled.Item>
    </Styled.List>
  );
};
