import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loaderSlice } from "../loaderSlice";
import { isLoaderVisibleSelector } from "../selectors";

export type UseLoader = () => {
  isLoaderVisible: boolean;
  showLoader: VoidFunction;
  hideLoader: VoidFunction;
};

export const useLoader: UseLoader = () => {
  const dispatch = useDispatch();

  const isLoaderVisible = useSelector(isLoaderVisibleSelector);

  const showLoader = useCallback(() => {
    dispatch(loaderSlice.actions.showLoader());
  }, [dispatch]);

  const hideLoader = useCallback(() => {
    dispatch(loaderSlice.actions.showLoader());
  }, [dispatch]);

  return {
    isLoaderVisible,
    showLoader,
    hideLoader,
  };
};
