import { сompanyApi } from "data-layer/company/api";
import { useCompany } from "data-layer/company/hooks/useCompay";
import { CancelError, CHCompanyItemOldVersionDTO, CountryDTO } from "generated";
import { useCallback, useEffect, useState } from "react";
import { setCompanyData } from "./parseData";

type SetFieldValue = (
  field: string,
  value: any,
  shouldValidate?: boolean | undefined
) => void;

type UseSearch = (
  setFieldValue: SetFieldValue,
  countries?: CountryDTO[]
) => {
  handleSearch: (value: string) => void;
  handleChange: (value: string) => Promise<any>;
  value: string;
  data: CHCompanyItemOldVersionDTO[];
  loadingSearch: boolean;
  loadingProfile: boolean;
};

export const useSearch: UseSearch = (setFieldValue, countries) => {
  const [data, setData] = useState<CHCompanyItemOldVersionDTO[]>([]);
  const [value, setValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [request, setRequest] = useState<any>(null);
  const { getCompany } = useCompany();

  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingProfile, setLoadingProfile] = useState(false);

  useEffect(() => {

    let timer;
    let inputField = document.getElementById('COMPANY_NAME');
    inputField.addEventListener('keyup', function () {

      window.clearTimeout(timer);

      timer = setTimeout(function () {

        setValue("");
        setSearchValue(inputField.getAttribute('value'));

      }, 300);

    });



    async function search() {
      if (!searchValue) {
        setValue(searchValue);
        setData([]);
        return;
      }

      if (request) {
        request.cancel();
        setRequest(null);
      }

      try {
        setLoadingSearch(true);
        const req = сompanyApi.api.search(searchValue);

        setRequest(req);

        const result = await req;

        setRequest(null);

        setLoadingSearch(false);
        setData(result);
      } catch (e) {
        if (e instanceof CancelError) {
        } else {
          setLoadingSearch(false);
        }
      }
    }

    search();
  }, [searchValue, setValue, setData, setLoadingSearch]);

  const handleSearch = useCallback(
    (value) => {
      //setValue(value);
      //setSearchValue(value);
    },
    //[setValue, setSearchValue]
    []
  );

  const handleChange = useCallback(
    async (value: string) => {
      // if (isFinite(+value) && data.length) {
        const item = data.find((item) => item.company_number === value);
        if (!item) {
          return;
        }

        try {
          setLoadingProfile(true);
          const company = await getCompany(item);
          //setValue(item.company_name);
          setValue("");
          setCompanyData(company, setFieldValue, countries);
        } catch (e) {
          setValue("");
        } finally {
          setLoadingProfile(false);
        }
      // }
    },
    [data, setValue, getCompany, setLoadingProfile, countries]
  );

  return {
    handleSearch,
    handleChange,
    data,
    value,
    loadingSearch,
    loadingProfile,
  };
};
