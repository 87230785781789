import { Popover as AntdPopover } from "antd";
import styled from "styled-components";
import { Fonts } from "styles/fonts";

const Popover = styled(AntdPopover)`
  background: red;
`

// hint

const hintWrapper = styled.div`
  max-width: 290px;
  padding: 20px;

  font-family: ${Fonts.NexaRegular};
  font-size: 14px;
  line-height: 20px;

  border-radius: 13px;

  background: #f9f9f9;
  color: #000000;

  box-shadow: 0px 10px 99px #0000004d;
`;

export const Styled = {
  Popover,

  hintWrapper,
};
