import { CancelablePromise } from "generated/core/CancelablePromise";
import { api } from "./api";
import { PaymentLinkDTO } from "generated";
import { PaymentsParams } from "../types/PaymentsParams";

export interface IPaymentsApi {
  getPayments: (
    params: PaymentsParams
  ) => CancelablePromise<Array<PaymentLinkDTO>>;

  createPaymentLink: (
    requestBody?: PaymentLinkDTO
  ) => CancelablePromise<PaymentLinkDTO | any>;
}

class PaymentsApi {
  api: IPaymentsApi = api;

  constructor() {
    if (process.env.REACT_APP_MOCK_ENABLED === "true") {
      this.api = api;
      return;
    }

    this.api = api;
  }
}

export const paymentsApi = new PaymentsApi();
