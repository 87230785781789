import { ICommon } from "../";
import { useLoader } from "./useLoader";
import { useCommingSoonModal } from "./useCommingSoonModal";
import { useSelector } from "react-redux";
import {
  isModalStartVisibleSelector,
  isSynchronizedSelector,
  testRoleSelector,
} from "../selectors";
import { useNotifications } from "./useNotifications";
import { useSyncMerchant } from "./useSyncMerchant";
import { useSyncPartner } from "./useSyncPartner";
import { useSetTestRole } from "./useSetTestRole";

export const useCommon = (): ICommon => {
  const { syncMerchant } = useSyncMerchant();
  const { syncPartner } = useSyncPartner();

  const isSynchronized = useSelector(isSynchronizedSelector);

  const { isLoaderVisible, showLoader, hideLoader } = useLoader();

  const {
    isCommingSoonModalVisible,
    showCommingSoonModal,
    hideCommingSoonModal,
  } = useCommingSoonModal();

  const { showModalStart, hideModalStart } = useNotifications();
  const isModalStartVisible = useSelector(isModalStartVisibleSelector);

  const testRole = useSelector(testRoleSelector);
  const { setTestRole } = useSetTestRole();

  return {
    isSynchronized,

    isLoaderVisible,
    showLoader,
    hideLoader,

    isCommingSoonModalVisible,
    showCommingSoonModal,
    hideCommingSoonModal,

    showModalStart,
    hideModalStart,
    isModalStartVisible,

    syncMerchant,
    syncPartner,

    testRole,
    setTestRole,
  };
};
