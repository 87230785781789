import { createSlice } from "@reduxjs/toolkit";

export type SyncInitialstate = {
  pending: boolean;
  synchronized: boolean;
};

const initialState: SyncInitialstate = {
  pending: false,
  synchronized: false,
};

export const syncSlice = createSlice({
  name: "sync",
  initialState: initialState,
  reducers: {
    syncRequest: (state) => {
      state.pending = true;
    },
    syncSuccess: (state) => {
      state.pending = false;
      state.synchronized = true;
    },
    syncFailure: (state) => {
      state.pending = false;
      state.synchronized = false;
    },
  },
});

export const syncActions = syncSlice.actions;
export const syncReducer = syncSlice.reducer;
