import { useNotifications } from "data-layer/notifications";
import { NotificationDTO } from "generated";
import { useRef, useEffect } from "react";
import { Rect } from "../../NotificationsSc";
import throttle from "lodash.throttle";

function isInViewport(rect: Rect, parentRect: Rect): boolean {
  return rect.top >= (parentRect.top - 5) && rect.bottom <= (parentRect.bottom + 5);
}

type UseCheckInViewport = (
  parentRect: Rect,
  notification: NotificationDTO
) => {
  inputRef: React.MutableRefObject<undefined>;
};

export const useCheckInViewport: UseCheckInViewport = (
  parentRect,
  notification
) => {
  const { markAsRead, pendingToRead } = useNotifications();

  const inputRef = useRef();

  const scrollHandler = () => {
    const rect = inputRef.current.getBoundingClientRect();

    if (!rect || !parentRect) {
      return;
    }

    const isViewport = isInViewport(rect, parentRect);

    if (
      isViewport &&
      !notification.readDate &&
      !pendingToRead.find((id) => id === notification.id)
    ) {
      markAsRead(notification.id!);
    }
  };

  const scrollHandlerThrottled = throttle(scrollHandler, 2000);

  useEffect(() => {
    scrollHandlerThrottled();
    window.addEventListener("scroll", scrollHandlerThrottled, true);

    return () => {
      window.removeEventListener("scroll", scrollHandlerThrottled, true);
    };
  }, [scrollHandler, parentRect]);

  return {
    inputRef,
  };
};
