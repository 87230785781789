import { useEffect, useRef } from "react";
import { Col, Row, Spin } from "antd";
import * as Yup from "yup";
import { Button } from "components";
import { Formik, FieldArray, Form } from "formik";
import { Styled } from "./styles";
import { useSubmit } from "./useSubmit";
import { Dropzone } from "components/Dropzone";
import { useDropzone } from "common/dropzone";
import { useDocumentRequest } from "data-layer/documentRequest";
import { useProfile } from "data-layer/profile";
import { InputFileListView } from "components/InputFile/InputFileListView";
import { Header } from "scenarios/seller/OnboardingSc/components/Header";

const styleOverlay = {
  position: "absolute",
  top: 0,
  left: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  zIndex: 2,
  backgroundColor: "rgba(255, 255, 255, 0.7)",
};

export const schema = Yup.object().shape({
  files: Yup.array().of(
    Yup.object().shape({
      id: Yup.number().required("required"),
      hashFileName: Yup.string(),
      originalFileName: Yup.string(),
    })
  ),
});

export const OnboardingDocumentRequest: React.FC = () => {
  const { selectedProfile } = useProfile();
  const { documentsState, newDocumentsByProfile, getDocumentsByProfile } =
    useDocumentRequest();

  const { handleSubmit } = useSubmit();
  const { onDropAttachemnt, loading: loadingDrop } = useDropzone();
  const formRef = useRef();

  useEffect(() => {
    getDocumentsByProfile(selectedProfile?.id!);
  }, []);

  if (documentsState.isProfileDataLoading) {
    return (
      <div className="container">
        <Styled.Wrapper>
          <Styled.Title>Document request</Styled.Title>
          <Spin style={{ margin: "0 auto" }} />
        </Styled.Wrapper>
      </div>
    );
  }

  const data = newDocumentsByProfile[0];

  return (
    <div className="container">
      <Header />

      <Styled.Wrapper>
        <Formik
          initialValues={{ files: [] }}
          validationSchema={schema}
          validateOnChange={false}
          onSubmit={(values) => {
            handleSubmit(data?.id!, values.files, formRef?.current?.resetForm);
          }}
          innerRef={formRef}
        >
          {({ errors, values, setFieldValue, submitForm, handleSubmit }) => {
            return (
              <>
                <Form
                  onSubmit={handleSubmit}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                >
                  <Styled.Title>Document request</Styled.Title>
                  <Styled.Content>
                    <Styled.ContentTitle>
                      Need to get verified
                    </Styled.ContentTitle>
                    <Styled.ContentDescription>
                      {data?.message}
                    </Styled.ContentDescription>

                    <FieldArray
                      name="files"
                      render={(arrayHelpers) => {
                        return (
                          <div style={{ position: "relative" }}>
                            <Row gutter={[0, 25]}>
                              <Col span={24}>
                                <Row justify="center">
                                  <Col span={24}>
                                    <Dropzone
                                      onDrop={(files) =>
                                        onDropAttachemnt(files, (val) => {
                                          val.forEach((item) => {
                                            arrayHelpers.push(item);
                                          });
                                        })
                                      }
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col span={24}>
                                <Row gutter={[0, 0]}>
                                  {values.files.length ? (
                                    <Styled.FilesTitle>Uploaded files</Styled.FilesTitle>
                                  ) : null}
                                  {values.files.map((file, index) => {
                                    return (
                                      <Col span={24}>
                                        <InputFileListView
                                          value={file?.id}
                                          file={file}
                                          onRemove={() => {
                                            arrayHelpers.remove(index);
                                          }}
                                          key={file?.id}
                                        />
                                      </Col>
                                    );
                                  })}
                                </Row>
                              </Col>
                            </Row>
                            {loadingDrop && (
                              <div style={styleOverlay}>
                                <Spin />
                              </div>
                            )}
                          </div>
                        );
                      }}
                    />
                  </Styled.Content>

                  <Button
                    block
                    type="primary"
                    onClick={submitForm}
                    style={{ marginTop: 20 }}
                    disabled={values.files.length === 0}
                  >
                    Send
                  </Button>
                </Form>
              </>
            );
          }}
        </Formik>
      </Styled.Wrapper>
    </div>
  );
};
