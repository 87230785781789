import { appClientCrate } from "services";
import { IPayoutsApi } from "./index";

export const api: IPayoutsApi = {
  getPayouts: (from, to, limit, offset, statuses) => {
    return appClientCrate.payoutController.getPayoutsUsingGet(
      from,
      to,
      limit,
      offset,
      statuses,
    );
  },
};
