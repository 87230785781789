import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { getMerchantsThunk } from "../thunks";
import { MerchantsParams } from "../types/MerchantsParams";

type UseGetMerchants = () => {
  getMerchants: (params: MerchantsParams) => void;
};

export const useGetMerchants: UseGetMerchants = () => {
  const dispatch = useDispatch();

  const getMerchants = useCallback(
    (params) => {
      dispatch(getMerchantsThunk(params));
    },
    [dispatch]
  );

  return {
    getMerchants,
  };
};
