import { useDispatch } from "react-redux";
import { employeeSlice } from "../slice";

type UseAuthorizeFromApp = () => {
  authorizeFromApp: VoidFunction;
};

export const useAuthorizeFromApp: UseAuthorizeFromApp = () => {
  const dispatch = useDispatch();

  const authorizeFromApp = () => {
    dispatch(employeeSlice.actions.setAuthorizedFromApp());
  };

  return {
    authorizeFromApp,
  };
};
