import { appClient } from "services";
import { IAttachemtApi } from "./index";

export const api: IAttachemtApi = {
  uploadFiles: (body) => {
    return appClient.attachmentsController.uploadUsingPost(body);
  },

  getFile: (attachmentId) => {
    return appClient.attachmentsController.findUsingGet(attachmentId);
  },
};
