import React from "react";
import { Col, Row } from "antd";
import { UserCountItem } from "./UserCountItem";
import { ReactComponent as InviteSvg } from "assets/PartnerOverview/invites.svg";
import { ReactComponent as RegisteredSvg } from "assets/PartnerOverview/userRegistered.svg";
import { ReactComponent as SubmittedSvg } from "assets/PartnerOverview/verified.svg";
import { ReactComponent as MerchantApprovedSvg } from "assets/PartnerOverview/approvedMerch.svg";
import { ReactComponent as TransactionSuccessfulSvg } from "assets/PartnerOverview/spawHoriz.svg";
import { Styled } from "./styled";
import { usePartner } from "data-layer/partner";

export const UserCount: React.FC = () => {
  const { partnerState } = usePartner();

  const { isStatsLoading } = partnerState;

  const {
    appsSubmitted,
    firstTransactions,
    invitesSent,
    merchantsApproved,
    usersRegistered,
  } = partnerState.statsData || {};

  return (
    <>
      <Styled.MainTitle>User Count</Styled.MainTitle>

      <Styled.List>
        <Row gutter={[16, { xs: 30, sm: 30, md: 40 } ]}>
          <Col span={12}>
            <UserCountItem
              title={invitesSent}
              text={"Invites sent"}
              svg={InviteSvg}
              isLoading={isStatsLoading}
            />
          </Col>
          <Col span={12}>
            <UserCountItem
              title={usersRegistered}
              text={"Users registered"}
              svg={RegisteredSvg}
              isLoading={isStatsLoading}
            />
          </Col>
          <Col span={12}>
            <UserCountItem
              title={appsSubmitted}
              text={"Applications submitted"}
              svg={SubmittedSvg}
              isLoading={isStatsLoading}
            />
          </Col>
          <Col span={12}>
            <UserCountItem
              title={merchantsApproved}
              text={"Merchants approved"}
              svg={MerchantApprovedSvg}
              isLoading={isStatsLoading}
            />
          </Col>
          <Col span={12}>
            <UserCountItem
              title={"-"}
              text={"Mobile app activated"}
              svg={TransactionSuccessfulSvg}
              isLoading={isStatsLoading}
            />
          </Col>
          <Col span={12}>
            <UserCountItem
              title={firstTransactions}
              text={"First transaction successful"}
              svg={TransactionSuccessfulSvg}
              isLoading={isStatsLoading}
            />
          </Col>
        </Row>
      </Styled.List>
    </>
  );
};
