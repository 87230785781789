import { Link } from "react-router-dom";
import { useEmployee } from "data-layer/employee";
import { Col, Row, Spin } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Styled } from "../../styles";
import { PageLogo, Typography } from "ui";
import styled from "styled-components";
import { Colors } from "styles/colors";
import { Role } from "data-layer/common/testRoleSlice";

const H1 = styled(Typography.H1)`
  span {
    display: block;
  }
`;

export const OnboardingInReviewWithNotVerifiedUser: React.FC = () => {
  const { employee, resendConfirmationLink } = useEmployee();

  return (
    <Styled.Wrapper>
      <Styled.Background />
      <Styled.Content>
        <Styled.ContentContainer>
          <Link to="/dashboard">
            <PageLogo
              role={Role.Merchant}
              partnerId={employee?.user?.inviterPartnerId}
            />
          </Link>
          <H1>
            Your application <span>has been submitted!</span>
          </H1>
          <Styled.Description>
            <p>
              Thank you for applying to Dapio! We'll review your application and
              get back to you as soon as possible. This will take no longer than
              48 hours.
            </p>
            <p>
              A verification link has been emailed to{" "}
              <span style={{ whiteSpace: "nowrap" }}>
                {employee.user?.email}
              </span>
            </p>

            <Styled.ButtonLink
              disabled={employee.isResendingConfirmationLink}
              onClick={() => resendConfirmationLink(employee.user?.id!)}
            >
              Resend verification link
              {employee.isResendingConfirmationLink && <Spin />}
            </Styled.ButtonLink>

            <Row
              gutter={10}
              align="top"
              wrap={false}
              style={{ paddingTop: 20 }}
            >
              <Col>
                <InfoCircleOutlined
                  style={{ fontSize: 24, color: Colors.Red }}
                />
              </Col>
              <Col style={{ paddingTop: 0 }}>
                If <span>{employee.user?.email}</span> is not you, please
                contact our support team via{" "}
                <a href="mailto:help@dapio.com">help@dapio.com.</a>
              </Col>
            </Row>
          </Styled.Description>
        </Styled.ContentContainer>
      </Styled.Content>
    </Styled.Wrapper>
  );
};
