import React from "react";
import { useDropzone } from "react-dropzone";
import { Styled } from "./styles";
import { UploadOutlined } from "@ant-design/icons"
import { Colors } from "styles/colors";

interface IDropzoneProps {
  accept?: string | string[];
  onDrop: any;
}

export const Dropzone: React.FC<IDropzoneProps> = ({ onDrop, accept }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
  });

  /*
    useDropzone hooks exposes two functions called getRootProps and getInputProps
    and also exposes isDragActive boolean
  */

  const DropPaper = isDragActive ? Styled.Paper : React.Fragment;

  return (
    <Styled.DropWrapper {...getRootProps()}>
      <DropPaper>
        <Styled.CenterBlock>
          <input {...getInputProps()} />
          <Styled.InfoCenter>
            <UploadOutlined style={{ marginBottom: 15, color: Colors.Primary, fontSize: 30 }} />
            <>
              <Styled.Text isDrag={isDragActive}>Drag and Drop</Styled.Text>
              <Styled.Text isDrag={isDragActive}>files to upload</Styled.Text>
              <Styled.Button>Browse</Styled.Button>
            </>
          </Styled.InfoCenter>
        </Styled.CenterBlock>
      </DropPaper>
    </Styled.DropWrapper>
  );
};
