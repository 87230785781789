import { useEmployee } from "data-layer/employee";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export const ConfirmEmailSc = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const { confirmEmailPartner } = useEmployee();

  useEffect(() => {
    confirmEmailPartner(token || "");
  }, [confirmEmailPartner, token]);

  return null;
};
