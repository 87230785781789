import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { getTransactionsThunk } from "../thunks";
import { TransactionParams } from "../types/TransactionParams";

type UseGetTransactions = () => {
  getTransactions: (params: TransactionParams) => void;
};

export const useGetTransactions: UseGetTransactions = () => {
  const dispatch = useDispatch();

  const getTransactions = useCallback(
    (params) => {
      dispatch(getTransactionsThunk(params));
    },
    [dispatch]
  );

  return {
    getTransactions,
  };
};
