import { Col, Row, Spin } from "antd";
import { Button, Paper } from "components";
import { Styled } from "./styles";
import { useToggle } from "common/useToggle";
import { history } from "data-layer/../router/AppRouter";
import { useTransactions } from "data-layer/transaction";
import { NotFound } from "ui";
import { useGetTransactions } from "./useGetTransactions";
import { TransactionRestDTO } from "generated";
import { Colors } from "styles/colors";
import { formatMoney } from "utils/numberFormatter";

const formatDate = (date: string) => {
  return new Date(date!).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const formatTime = (date: string) => {
  return new Date(date!).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
};

const getColor = (type: TransactionRestDTO.type): string => {
  if (type === TransactionRestDTO.type.PAYMENT) {
    return Colors.Green;
  }

  if (type === TransactionRestDTO.type.CANCEL) {
    return Colors.Red;
  }

  return Colors.GrayBlue;
};

export const ResentSales = () => {
  const { active, handleToggle } = useToggle();
  const { data, isFetching } = useTransactions();
  useGetTransactions();

  return (
    <Paper style={{ height: "100%", position: "relative" }}>
      <Styled.HeaderRow justify="space-between" align="middle">
        <Styled.TitleCol>
          <Styled.Title onClick={handleToggle} active={active}>
            Resent sales
          </Styled.Title>
        </Styled.TitleCol>
        <Styled.ButtonCol sm={{ flex: 1 }}>
          <Button
            type="primary-transparent"
            size="small"
            block
            style={{ maxWidth: 215 }}
            onClick={() => {
              history.push("/dashboard/reports");
            }}
          >
            View all transactions
          </Button>
        </Styled.ButtonCol>
      </Styled.HeaderRow>

      <Styled.Content active={active}>
        {isFetching && (
          <Styled.Spin>
            <Spin />
          </Styled.Spin>
        )}
        {!isFetching && data?.length ? (
          <>
            <Styled.List>
              {data.map((item) => (
                <li>
                  <Row wrap={false} justify="space-between">
                    <Col>
                      <Row style={{ flexDirection: "column" }}>
                        <Styled.FirstCol>
                          {formatMoney({ value: item.amount || 0, currency: item.currency })}
                        </Styled.FirstCol>
                        <Styled.SecondCol
                          style={{ color: getColor(item.type!) }}
                        >
                          {item?.type &&
                            item?.type.charAt(0).toUpperCase() +
                              item.type!.slice(1).toLowerCase()}
                        </Styled.SecondCol>
                      </Row>
                    </Col>
                    <Col>
                      <Row
                        style={{
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <Styled.FirstCol>
                          {formatDate(item.dateTime!)}
                        </Styled.FirstCol>
                        <Styled.SecondCol>
                          {formatTime(item.dateTime!)}
                        </Styled.SecondCol>
                      </Row>
                    </Col>
                  </Row>
                </li>
              ))}
            </Styled.List>
            <Styled.MobileButton>
              <Button
                type="primary-transparent"
                size="small"
                block
                onClick={() => {
                  history.push("/dashboard/reports");
                }}
              >
                View all transactions
              </Button>
            </Styled.MobileButton>
          </>
        ) : null}
        {!isFetching && !data.length && <NotFound />}
      </Styled.Content>
    </Paper>
  );
};
