import { CancelablePromise } from "generated/core/CancelablePromise";
import { api } from "./api";
import {
  ImportFlatApplicationRequestDTO,
  ParseImportFileResultDTO,
  ImportingFileResultDTO,
} from "generated";

export interface IMerchantImportApi {
  parseXLS: (formData: {
    files: Array<Blob>;
    token?: string;
  }) => CancelablePromise<ParseImportFileResultDTO | any>;
  parseXLSX: (formData: {
    files: Array<Blob>;
    token?: string;
  }) => CancelablePromise<ParseImportFileResultDTO | any>;
  parseCSV: (formData: {
    files: Array<Blob>;
    token?: string;
  }) => CancelablePromise<ParseImportFileResultDTO | any>;
  import: (
    requestBody: ImportFlatApplicationRequestDTO
  ) => CancelablePromise<ImportingFileResultDTO | any>;
}

class MerchantImportApi {
  api: IMerchantImportApi = api;

  constructor() {
    this.api = api;
  }
}

export const merchantImportApi = new MerchantImportApi();
