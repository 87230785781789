import { Col } from "antd";
import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

const Wrapper = styled.div`
  max-width: 1465px;

  margin: 0 auto;

  border-radius: 10px;
`;

const Icon = styled.div`
  position: relative;

  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 65px;
  height: 65px;

  margin: 0 auto 21px;

  font-size: 25px;

  border-radius: 15px;

  background: #fff;

  box-shadow: 0px 3px 3px #0000001a;

  @media (max-width: 1199px) {
    width: 55px;
    height: 55px;
    margin-bottom: 20px;
  }

  &::before {
    z-index: -1;

    content: "";

    position: absolute;

    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;

    background-color: #0000000d;

    border-radius: 10px;
  }
`;

const Svg = styled.div`
  font-size: 35px;

  color: #283549;

  @media (max-width: 1199px) {
    font-size: 30px;
  }
`;

const BackIcon = styled.div`
  position: absolute;

  top: 35px;
  left: 25px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 50px;
  height: 50px;

  border-radius: 25px;

  background: #FE8E75;

  cursor: pointer;
`

const BackIconSvg = styled.div`
  font-size: 23px;

  color: #fff;

  // @media (max-width: 1199px) {
  //   font-size: 30px;
  // }
`;

const Title = styled.p`
  margin-bottom: 30px;

  font-family: ${Fonts.NexaRegular};
  font-size: 25px;
  line-height: 100%;

  text-align: center;

  color: ${Colors.Primary};

  @media (max-width: 1199px) {
    margin-bottom: 25px;
    font-size: 20px;
  }
`;

const ButtonCol = styled(Col)`
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);

  @media (max-width: 850px) {
    position: static;
    width: 100%;
    margin-top: 10px;
    transform: none;
  }
`

const Button = styled.div`
  padding-top: 55px;

  @media (max-width: 1199px) {
    padding-top: 45px;
  }

  @media (max-width: 575px) {
    padding-top: 35px;
  }
`;

const TotalCol = styled(Col)`
  @media (max-width: 850px) {
    width: 100%;
  }
`

const Total = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 50px;
  padding: 0 53px;

  font-size: 17px;
  font-family: ${Fonts.NexaRegular};

  border-radius: 25px;

  background: #0000001A;
  color: #283549;

  @media (max-width: 1199px) {
    height: 40px;
    padding: 0 25px;
  }
`;

const AccordionTitle = styled.div`
  position: relative;
  padding: 15px 15px;

  font-family: ${Fonts.NexaRegular};
  font-size: 20px;
  line-height: 22px;

  border-top: 1px solid #dadada;

  color: #000000;

  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    top: 10px;
    right: 15px;
    width: 13px;
    height: 13px;
    border-right: 3px solid ${Colors.Primary};
    border-bottom: 3px solid ${Colors.Primary};
    transform: rotate(45deg);
  }
`

const TableTitle = styled.div`
  @media (max-width: 575px) {
    padding-top: 30px;
  }
`

export const Styled = {
  Wrapper,

  Icon,
  Svg,

  BackIcon,
  BackIconSvg,

  Title,
  ButtonCol,
  Button,
  TotalCol,
  Total,

  AccordionTitle,

  TableTitle,
};
