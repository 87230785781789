import { useCallback, useState } from "react";
import { Controls, Navigator, NavigatorMobile } from "./components";
import { Row, Col } from "antd";
import { Styled } from "./styles";
import { ProfileMenuSc } from "scenarios/partner";
import { Link } from "react-router-dom";
import { SimpleLogo } from "ui";

export const HeaderSc: React.FC = () => {
  const [mobileMenuVisible, setMobileVisible] = useState(false);

  const toggleMenu = useCallback(() => {
    setMobileVisible(!mobileMenuVisible);
  }, [mobileMenuVisible, setMobileVisible]);

  return (
    <Styled.Wrapper>
      <div className="container">
        <Row
          gutter={[{ xs: 20, sm: 25 }, 0]}
          align="middle"
          wrap={false}
        >
          <Col>
            <Link to="/dashboard">
              <SimpleLogo />
            </Link>
          </Col>
          <Styled.NavigationCol>
            <Navigator />
            <NavigatorMobile
              onClose={toggleMenu}
              mobileMenuVisible={mobileMenuVisible}
            />
            <Styled.NavigatorMobileButton onClick={toggleMenu}>
              <div />
            </Styled.NavigatorMobileButton>
          </Styled.NavigationCol>
          <Styled.ControlsCol>
            <Row gutter={[{ xs: 15, sm: 15, xxl: 25 }, 0]} wrap={false}>
              <Col>
                <Controls />
              </Col>
              <Styled.ProfileCol>
                <ProfileMenuSc />
              </Styled.ProfileCol>
            </Row>
          </Styled.ControlsCol>
        </Row>
      </div>
    </Styled.Wrapper>
  );
};
