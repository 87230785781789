import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { PayoutsParams } from "data-layer/payouts/types/PayoutsParams";
import { keysEqual } from "utils/keysEqual";

type SearchParamsFullfiled = {
  from: string;
  to: string;
  limit: string;
  offset: string;
  statuses: string;
};

export type UseInitParams = () => {
  filters: PayoutsParams | null;
};

export const DEFAULT_LIMIT = 20;

export const useInitParams: UseInitParams = () => {
  const [filters, setFilters] = useState<PayoutsParams | null>(null);
  const [searchParams, setSearchParams] = useSearchParams({});

  const hasEmptyRequiredParam = (params: { [key: string]: string | null }) => {
    let hasEmptyParams = false;

    Object.keys(params).forEach((key: string) => {
      if (!params[key]) {
        hasEmptyParams = true;
      }
    });

    return hasEmptyParams;
  };

  const hasDifferent = (filters: PayoutsParams, nextFilters: PayoutsParams) => {
    if (!keysEqual(filters, nextFilters)) {
      return true;
    }

    let hasDifferent = false;

    Object.keys(nextFilters).map((key) => {
      if (key === "statuses") {
        if (nextFilters[key].length !== filters[key].length) {
          hasDifferent = true;
        }

        if (nextFilters[key].length === 1) {
          if (nextFilters[key][0] !== filters[key][0]) {
            hasDifferent = true;
          }
        }

        return;
      }

      if (nextFilters[key] !== filters[key]) {
        hasDifferent = true;
      }
    });

    return hasDifferent;
  };

  const formatDataToState = (params: SearchParamsFullfiled): PayoutsParams => {
    return {
      ...params,
      statuses: params.statuses.split(","),
      limit: Number(params.limit),
      offset: Number(params.offset),
    } as PayoutsParams;
  };

  useEffect(() => {
    const from = searchParams.get("from");
    const to = searchParams.get("to");
    const limit = searchParams.get("limit");
    const offset = searchParams.get("offset");
    const statuses = searchParams.get("statuses");
    const statusesFull = [
      "APPROVED",
      "CANCELLED",
      "IN_PROGRESS",
      "NEW",
      "PAID",
      "REJECTED",
    ];

    const nextSearchParams: SearchParamsFullfiled = {
      from: from || moment().subtract(7, "d").startOf("day").toISOString(),
      to: to || moment().endOf("day").toISOString(),
      statuses: statuses || statusesFull.toString(),
      limit: String((limit && Number(limit)) || DEFAULT_LIMIT),
      offset: String((offset && Number(offset)) || 0),
    };

    if (
      hasEmptyRequiredParam({
        from,
        to,
        limit,
        offset,
        statuses,
      })
    ) {
      setSearchParams(nextSearchParams);
      return;
    }

    // пока нет фильтра статуса проверям чтоб были все
    if (statuses !== statusesFull.toString()) {
      setSearchParams({
        ...nextSearchParams,
        statuses: statusesFull.toString(),
      });
      return;
    }

    if (!filters) {
      setFilters(formatDataToState(nextSearchParams));
      return;
    }

    if (hasDifferent(filters, formatDataToState(nextSearchParams))) {
      setFilters(formatDataToState(nextSearchParams));
    }
  }, [searchParams]);

  return {
    filters,
    searchParams,
  };
};
