import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { selectProfile as getSelectThunk } from "data-layer/profile/thunks";

type UseSelectProfile = () => {
  selectProfile: (profileNumber: number) => void;
};

export const useSelectProfile: UseSelectProfile = () => {
  const dispatch = useDispatch();

  const selectProfile = useCallback(
    (profileNumber: number) => {
      dispatch(getSelectThunk(profileNumber));
    },
    [dispatch]
  );

  return {
    selectProfile,
  };
};
