import { loaderSlice } from "data-layer/common/loaderSlice";
import { AppDispatch } from "data-layer/store/store";
import {
  ImportFlatApplicationRequestDTO,
  ParseImportFileResultDTO,
} from "generated";
import { handleNetworkError } from "utils/handleNetworkError";
import { showError } from "utils/showError";
import { merchantImportApi } from "./api";
import { ParsedResult } from "./types/ParsedResult";

export const parseFileThunk = (
  formData: {
    files: Array<Blob>;
    token?: string;
  },
  format: string,
  onParse?: (data: ParseImportFileResultDTO) => void
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      let result = null;
      switch (format) {
        case "xls": {
          result = await merchantImportApi.api.parseXLS(formData);
          break;
        }
        case "xlsx": {
          result = await merchantImportApi.api.parseXLSX(formData);
          break;
        }
        case "csv": {
          result = await merchantImportApi.api.parseCSV(formData);
          break;
        }
        default: {
          throw new Error("parsing error");
        }
      }

      if (onParse) {
        onParse(result);
      }
    } catch (e) {
      handleNetworkError(e, "Parsing error");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};

export const importThunk = (
  body: ImportFlatApplicationRequestDTO,
  onParseResult: (data: ParsedResult) => void
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      const result = await merchantImportApi.api.import(body);
      console.log("importThunk result", result);
    } catch (e) {
      console.log("importThunk e.url", e.body);
      console.log("importThunk e.url", e.body.failed);
      console.log("importThunk e.url", e.body.success);

      onParseResult({
        success: e.body.success,
        failed: e.body.failed,
      });

      handleNetworkError(e, "Import error");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};
