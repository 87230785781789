/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PayoutDTO } from '../models/PayoutDTO';
import type { UpdatePayoutsStatusRequest } from '../models/UpdatePayoutsStatusRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { network } from 'services/network';

export class PayoutControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Find payouts
     * Find payouts
     * @param from from
     * @param to to
     * @param limit limit
     * @param offset offset
     * @param statuses statuses
     * @returns PayoutDTO
     * @throws ApiError
     */
    public getPayoutsUsingGet(
        from: string,
        to: string,
        limit: number,
        offset: number,
        statuses?: 'APPROVED' | 'CANCELLED' | 'IN_PROGRESS' | 'NEW' | 'PAID' | 'REJECTED',
    ): CancelablePromise<Array<PayoutDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/payouts',
                query: {
                    'statuses': statuses,
                    'from': from,
                    'to': to,
                    'limit': limit,
                    'offset': offset,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Find payouts (admin)
     * Find payouts
     * @param from from
     * @param to to
     * @param limit limit
     * @param offset offset
     * @param merchantIds merchantIds
     * @param statuses statuses
     * @returns PayoutDTO
     * @throws ApiError
     */
    public getPayoutsUsingGet(
        from: string,
        to: string,
        limit: number,
        offset: number,
        merchantIds?: number,
        statuses?: 'APPROVED' | 'CANCELLED' | 'IN_PROGRESS' | 'NEW' | 'PAID' | 'REJECTED',
    ): CancelablePromise<Array<PayoutDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/payouts/admin',
                query: {
                    'merchantIds': merchantIds,
                    'statuses': statuses,
                    'from': from,
                    'to': to,
                    'limit': limit,
                    'offset': offset,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * createPayouts
     * @param from from
     * @param to to
     * @returns PayoutDTO OK
     * @returns any Created
     * @throws ApiError
     */
    public createPayoutsUsingPost(
        from: string,
        to: string,
    ): CancelablePromise<Array<PayoutDTO> | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/payouts/create',
                query: {
                    'from': from,
                    'to': to,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * updatePayoutStatus
     * @param requestBody
     * @returns PayoutDTO OK
     * @returns any Created
     * @throws ApiError
     */
    public updatePayoutStatusUsingPut(
        requestBody?: UpdatePayoutsStatusRequest,
    ): CancelablePromise<Array<PayoutDTO> | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'PUT',
                url: '/payouts/update',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

}