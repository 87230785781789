import { Table as AntdTable } from "antd";
import { TableProps  } from "antd/lib/table";
import { PaginationSc } from "scenarios/common/PaginationSc";
import "./style.css";

type Filters = {
  limit: number;
  offset: number;
};

// TODO: TS
type TableScProps = TableProps<any> & {
  data: any[];
  filters?: Filters;
  style?: any;
  hideApiPaginaiton?: boolean;
};

export const TableSc: React.FC<TableScProps> = (props) => {
  const { data, columns, filters, style, hideApiPaginaiton, ...rest } = props;

  return (
    <>
      <AntdTable
        dataSource={data}
        columns={columns}
        pagination={hideApiPaginaiton ? {} : false}
        className="general-table"
        style={style}
        {...rest}
      />
      {!hideApiPaginaiton && <PaginationSc data={data} filters={filters!} />}
    </>
  );
};
