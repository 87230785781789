/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TransactionRestDTO } from '../models/TransactionRestDTO';
import type { TransactionsSellerDashboardDTO } from '../models/TransactionsSellerDashboardDTO';
import type { TransactionSumAggDTO } from '../models/TransactionSumAggDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { network } from 'services/network';

export class TransactionControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get transactions of user (merchant, seller)
     * Get transactions of user (merchant, seller)
     * @param limit
     * @param offset
     * @param types
     * @param amountMax
     * @param amountMin
     * @param employeeIds
     * @param employeeName
     * @param from
     * @param merchantIds
     * @param orderBy0Direction
     * @param orderBy0Field
     * @param partnerIds
     * @param payoutIds
     * @param to
     * @returns TransactionRestDTO
     * @throws ApiError
     */
    public findAllAsMerchantUsingGet(
        limit: number,
        offset: number,
        types: 'CANCEL' | 'CHARGEBACK' | 'MANUAL' | 'PARTIAL_REFUND' | 'PAYMENT' | 'REFUND',
        amountMax?: number,
        amountMin?: number,
        employeeIds?: Array<number>,
        employeeName?: string,
        from?: string,
        merchantIds?: Array<number>,
        orderBy0Direction?: 'ASC' | 'DESC',
        orderBy0Field?: string,
        partnerIds?: Array<number>,
        payoutIds?: Array<number>,
        to?: string,
    ): CancelablePromise<Array<TransactionRestDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/transaction',
                query: {
                    'amountMax': amountMax,
                    'amountMin': amountMin,
                    'employeeIds': employeeIds,
                    'employeeName': employeeName,
                    'from': from,
                    'limit': limit,
                    'merchantIds': merchantIds,
                    'offset': offset,
                    'orderBy[0].direction': orderBy0Direction,
                    'orderBy[0].field': orderBy0Field,
                    'partnerIds': partnerIds,
                    'payoutIds': payoutIds,
                    'to': to,
                    'types': types,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Get transactions (admin)
     * Get transactions (admin)
     * @param limit
     * @param offset
     * @param types
     * @param amountMax
     * @param amountMin
     * @param employeeIds
     * @param employeeName
     * @param from
     * @param merchantIds
     * @param orderBy0Direction
     * @param orderBy0Field
     * @param partnerIds
     * @param payoutIds
     * @param to
     * @returns TransactionRestDTO
     * @throws ApiError
     */
    public findAllAsAdminUsingGet(
        limit: number,
        offset: number,
        types: 'CANCEL' | 'CHARGEBACK' | 'MANUAL' | 'PARTIAL_REFUND' | 'PAYMENT' | 'REFUND',
        amountMax?: number,
        amountMin?: number,
        employeeIds?: Array<number>,
        employeeName?: string,
        from?: string,
        merchantIds?: Array<number>,
        orderBy0Direction?: 'ASC' | 'DESC',
        orderBy0Field?: string,
        partnerIds?: Array<number>,
        payoutIds?: Array<number>,
        to?: string,
    ): CancelablePromise<Array<TransactionRestDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/transaction/admin',
                query: {
                    'amountMax': amountMax,
                    'amountMin': amountMin,
                    'employeeIds': employeeIds,
                    'employeeName': employeeName,
                    'from': from,
                    'limit': limit,
                    'merchantIds': merchantIds,
                    'offset': offset,
                    'orderBy[0].direction': orderBy0Direction,
                    'orderBy[0].field': orderBy0Field,
                    'partnerIds': partnerIds,
                    'payoutIds': payoutIds,
                    'to': to,
                    'types': types,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Aggregate transactions (admin)
     * Aggregate transactions (admin)
     * @param limit
     * @param offset
     * @param types
     * @param amountMax
     * @param amountMin
     * @param employeeIds
     * @param employeeName
     * @param from
     * @param merchantIds
     * @param orderBy0Direction
     * @param orderBy0Field
     * @param partnerIds
     * @param payoutIds
     * @param to
     * @returns TransactionSumAggDTO
     * @throws ApiError
     */
    public aggregateAsAdminUsingGet(
        limit: number,
        offset: number,
        types: 'CANCEL' | 'CHARGEBACK' | 'MANUAL' | 'PARTIAL_REFUND' | 'PAYMENT' | 'REFUND',
        amountMax?: number,
        amountMin?: number,
        employeeIds?: Array<number>,
        employeeName?: string,
        from?: string,
        merchantIds?: Array<number>,
        orderBy0Direction?: 'ASC' | 'DESC',
        orderBy0Field?: string,
        partnerIds?: Array<number>,
        payoutIds?: Array<number>,
        to?: string,
    ): CancelablePromise<Array<TransactionSumAggDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/transaction/agg/admin',
                query: {
                    'amountMax': amountMax,
                    'amountMin': amountMin,
                    'employeeIds': employeeIds,
                    'employeeName': employeeName,
                    'from': from,
                    'limit': limit,
                    'merchantIds': merchantIds,
                    'offset': offset,
                    'orderBy[0].direction': orderBy0Direction,
                    'orderBy[0].field': orderBy0Field,
                    'partnerIds': partnerIds,
                    'payoutIds': payoutIds,
                    'to': to,
                    'types': types,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Get transactions (amount)
     * Get transactions (amount)
     * @param merchantIds merchantIds
     * @param from from
     * @param to to
     * @param aggregateBy aggregateBy
     * @param aggregateTime aggregateTime
     * @returns TransactionsSellerDashboardDTO
     * @throws ApiError
     */
    public getTransactionsAmountUsingGet(
        merchantIds: number,
        from: string,
        to: string,
        aggregateBy: 'EMPLOYEE' | 'PROVIDER',
        aggregateTime: 'DAY' | 'DAY_OF_WEEK' | 'MONTH' | 'YEAR',
    ): CancelablePromise<Array<TransactionsSellerDashboardDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/transaction/amount',
                query: {
                    'merchantIds': merchantIds,
                    'from': from,
                    'to': to,
                    'aggregateBy': aggregateBy,
                    'aggregateTime': aggregateTime,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Get transactions (average)
     * Get transactions (average)
     * @param merchantIds merchantIds
     * @param from from
     * @param to to
     * @param aggregateBy aggregateBy
     * @param aggregateTime aggregateTime
     * @returns TransactionsSellerDashboardDTO
     * @throws ApiError
     */
    public getTransactionsAverageUsingGet(
        merchantIds: number,
        from: string,
        to: string,
        aggregateBy: 'EMPLOYEE' | 'PROVIDER',
        aggregateTime: 'DAY' | 'DAY_OF_WEEK' | 'MONTH' | 'YEAR',
    ): CancelablePromise<Array<TransactionsSellerDashboardDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/transaction/average',
                query: {
                    'merchantIds': merchantIds,
                    'from': from,
                    'to': to,
                    'aggregateBy': aggregateBy,
                    'aggregateTime': aggregateTime,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Get transactions (quantity)
     * Get transactions (quantity)
     * @param merchantIds merchantIds
     * @param from from
     * @param to to
     * @param aggregateBy aggregateBy
     * @param aggregateTime aggregateTime
     * @returns TransactionsSellerDashboardDTO
     * @throws ApiError
     */
    public getTransactionsQuantityUsingGet(
        merchantIds: number,
        from: string,
        to: string,
        aggregateBy: 'EMPLOYEE' | 'PROVIDER',
        aggregateTime: 'DAY' | 'DAY_OF_WEEK' | 'MONTH' | 'YEAR',
    ): CancelablePromise<Array<TransactionsSellerDashboardDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/transaction/quantity',
                query: {
                    'merchantIds': merchantIds,
                    'from': from,
                    'to': to,
                    'aggregateBy': aggregateBy,
                    'aggregateTime': aggregateTime,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

}