import { Spin } from "antd";
import { TableSc } from "scenarios";
import { useProfile } from "data-layer/profile";
import { useDictionary } from "data-layer/dictionary";
import { CountryDTO, ProfileDTO } from "generated";
import { ParterProfileParams } from "data-layer/profile/types/ParterProfileParams";
import { Styled } from "./styles";
import { splitText } from "utils/splitText";

const getColumns = (countries?: CountryDTO[]) => {
  return [
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      render: (date: Date) => {
        const dateFormated = new Date(date).toLocaleDateString("en-us", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        const timeFormated = new Date(date).toLocaleTimeString();
  
        return (
          <span>
            {dateFormated} {timeFormated}
          </span>
        );
      },
      width: "14%",
    },
    {
      title: "Client ID",
      dataIndex: "id",
      key: "id",
      width: "14%",
      render: (id: ProfileDTO["id"]) => {
        return <span>{id}</span>;
      },
    },
    {
      title: "Country",
      dataIndex: "countryId",
      key: "countryId",
      render: (id: number) => {
        return <span>{countries?.find(country => country.id === id)?.name || id}</span>
      },
      width: "14%",
    },
    {
      title: "Onboarding Status",
      dataIndex: "status",
      key: "status",
      width: "14%",
      render: (status: ProfileDTO["status"]) => {
        if (!status) {
          return <span />;
        }

        return <span>{splitText(status)}</span>;
      },
    },
    {
      title: "Type",
      dataIndex: "kind",
      key: "kind",
      width: "14%",
      render: (kind: ProfileDTO["kind"]) => {
        if (!kind) {
          return <span />;
        }

        return <span>{splitText(kind)}</span>;
      },
    },
    {
      title: "Company name",
      dataIndex: "name",
      key: "name",
      width: "14%",
      render: (name: ProfileDTO["name"]) => {
        return <Styled.Primary>{name}</Styled.Primary>;
      },
    },
    {
      title: "Manager",
      dataIndex: "managerName",
      key: "managerName",
      width: "14%",
      render: (managerName: ProfileDTO["managerName"]) => {
        return <span>{managerName}</span>;
      },
    },
  ];
}

type TableProps = {
  filters: ParterProfileParams;
};

export const Table: React.FC<TableProps> = (props) => {
  const { filters } = props;
  const { dataProfilesForPartner, profile } = useProfile();
  const { countries } = useDictionary();
  const columns = getColumns(countries)

  if (
    (!dataProfilesForPartner || !dataProfilesForPartner.length) &&
    profile.isDataProfilesForPartnerPending
  ) {
    return <Spin />;
  }

  if (!filters) {
    return <Spin />;
  }

  return (
    <TableSc
      data={dataProfilesForPartner}
      filters={filters}
      columns={columns}
      scroll={{ x: 1400 }}
    />
  );
};
