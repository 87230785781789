import React, { useState } from "react";
import { GeneralStyled } from "../../styled";
import { CopyOutlined, CheckOutlined } from "@ant-design/icons";
import { usePartner } from "data-layer/partner";

const REFERRAL_LINK = "https://seller.dapio.com/signup";

export const ReferralButton: React.FC = () => {
  const { partnerState } = usePartner();
  const [referralLink, setReferralLink] = useState<string | null>(null);

  const onClickHandle = () => {
    const link = `${REFERRAL_LINK}?partnerId=${partnerState.data?.id}`;
    navigator.clipboard.writeText(link);
    setReferralLink(link);
    setTimeout(() => {
      setReferralLink(null);
    }, 3000);
  };

  return (
    <>
      <GeneralStyled.ButtonWrapper onClick={onClickHandle}>
        <GeneralStyled.ButtonWrapperText>
          {referralLink ? "Copied" : "Referral Link"}
        </GeneralStyled.ButtonWrapperText>
        {referralLink ? <CheckOutlined /> : <CopyOutlined />}
      </GeneralStyled.ButtonWrapper>
    </>
  );
};
