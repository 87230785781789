import { Styled } from "./styles";

export const FAQ: React.FC = () => {
  return (
    <Styled.Content>
      <Styled.Title>
        How to download and log in to the mobile app?
      </Styled.Title>
      <Styled.Description>
        <p><b>You can find us in the Google play store</b></p>
        <p><b>Step 1:</b> Head to the Google Play app on your mobile</p>
        <p><b>Step 2:</b> Tap the search field and type in <b>Dapio</b>, and select us from the results</p>
        <p><b>Step 3:</b> Tap <b>Install</b> &gt; Load the app and choose “Sign up”</p>
        <p><b>Step 4:</b> Enter your details, read and accept our T's & C's</p>
        <p><b>Step 4:</b> Get paid!</p>
        <p><b>Or, scan this QR code to go straight to the Google play store. Simple.</b></p>
      </Styled.Description>

      <Styled.Title>
        How can I check if my app is up to date and I do not use a previous version?
      </Styled.Title>
      <Styled.Description>
        <p>You can check GooglePlay or AndroidPlay for any updates</p>
      </Styled.Description>

      <Styled.Title>
        How long it will take to receive the email verification?
      </Styled.Title>
      <Styled.Description>
        <p>It takes maximum 10 minute, please check the spam inbox as well.</p>
      </Styled.Description>

      <Styled.Title>
        How can I clear my cache and data storage?
      </Styled.Title>
      <Styled.Description>
        <p>Long press the app, App Info - Scroll down to Advanced Menu - Storage - Clear Cache and Clear Storage</p>
      </Styled.Description>

      <Styled.Title>
        How many transactions per day am I able to make?
      </Styled.Title>
      <Styled.Description>
        <p>Depending on the bank's limits on the total amount, it is in the bank specification.</p>
      </Styled.Description>

      <Styled.Title>
        Can I connect a bank account which is outside of the country where I signed up for?
      </Styled.Title>
      <Styled.Description>
        <p>You can only connect a bank account which is in the same country where you signed up for</p>
      </Styled.Description>

      <Styled.Title>
        How do you handle my personal data?
      </Styled.Title>
      <Styled.Description>
        <p>If you'd like to know more about how we handle personal data, please read more in our <a href="https://dapio.com/privacy" target="_blank">Privacy Policy</a></p>
      </Styled.Description>

      <Styled.Title>
        Do I need an internet connection?
      </Styled.Title>
      <Styled.Description>
        <p>Yes, in order to process transactions you'll need an internet connections via WiFi or data coverage (minimum 3G speed)</p>
      </Styled.Description>

      <Styled.Title>
        Why can't my phone read contactless cards?
      </Styled.Title>
      <Styled.Description>
        <p>Make sure your device has NFC. If it does have NFC, you need to make sure the NFC is enabled on your device</p>
        <p>Go to Settings &gt; More - Tap on the NFC switch to activate it, the Android Beam function will also automatically turn on. If the Android Beam does not automatically turn on, just tap it and select Yes to turn it on.</p>
        <p>Smartphones' NFC capabilities operate in tandem with Android Beam. If Android Beam is disabled, it may limit NFC's sharing capacity.</p>
      </Styled.Description>

      <Styled.Title>
        Why is my payout delaying?
      </Styled.Title>
      <Styled.Description>
        <p>Depending on your bank's settlement procedures it may take up to 2-3 business days from the transaction date to the day the funds appear on you account. Please also be aware that banks only transfer funds on business days.</p>
      </Styled.Description>

      <Styled.Title>
        I made a mistake and entered the wrong amount, what should I do?
      </Styled.Title>
      <Styled.Description>
        <p>There are two possible options here: If you have entered the wrong amount and have not tapped the customers' card yet, you can easily click the button in the upper left corner on the screen that is pending for the customer to “Present card” and then start a new transaction.</p>
        <p>If you have entered the wrong amount and have already tapped the customers' card, wait the transaction to finish. Go into your “Transactions” menu and find the wrong amount transaction. Click on it and then choose the “Void” button. On the next screen you will be asked to confirm the void. Once it is successful, you can go back and make a new transaction with the right amount.</p>
      </Styled.Description>

      <Styled.Title>
        Does your solution support PIN entry?
      </Styled.Title>
      <Styled.Description>
        <p>Yes, PIN is supported. Pin on Glass is included within our SDK and SoftPOS App. Our secure PIN Entry method has been rigorously tested by a PCI Recognised Lab and reviewed by Visa, Mastercard and American Express.</p>
        <p>Source: <a href="https://www.halodot.io/faqs/tap-on-phone">https://www.halodot.io/faqs/tap-on-phone</a></p>
      </Styled.Description>

      <Styled.Title>
        How do refunds work?
      </Styled.Title>
      <Styled.Description>
        <p>Source: <a href="https://help.nomod.com/article/69-how-do-refunds-work">https://help.nomod.com/article/69-how-do-refunds-work</a></p>
      </Styled.Description>

      <Styled.Title>
        Is there a tipping function?
      </Styled.Title>
      <Styled.Description>
        <p>Source: <a href="https://www.zettle.com/gb/help/articles/2915626-tipping">https://www.zettle.com/gb/help/articles/2915626-tipping</a></p>
      </Styled.Description>

      <Styled.Title>
        What to do if a card is declined?
      </Styled.Title>
      <Styled.Description>
        <p>If the payment is declined, the sale cannot be completed. We recommend you let the buyer use another means of payment and then ask them to contact their bank for more information.</p>
        <p><a href="https://www.zettle.com/gb/help/articles/1084729-taking-card-and-contactless-payments">https://www.zettle.com/gb/help/articles/1084729-taking-card-and-contactless-payments</a></p>
      </Styled.Description>

      <Styled.Title>
        Who can open a Dapio account?
      </Styled.Title>
      <Styled.Description>
      </Styled.Description>

      <Styled.Title>
        Why can't I sign in to my account?
      </Styled.Title>
      <Styled.Description>
      </Styled.Description>

      <Styled.Title>
        How do I link a bank account?
      </Styled.Title>
      <Styled.Description>
      </Styled.Description>

      <Styled.Title>
        Do I need an internet connection?
      </Styled.Title>
      <Styled.Description>
      </Styled.Description>

      <Styled.Title>
        What browsers and devices are supported by Dapio?
      </Styled.Title>
      <Styled.Description>
        <p>Dapio can be viewed and completed using the following platforms/browsers:</p>
        <p><a href="https://www.typeform.com/help/a/what-browsers-and-devices-are-supported-by-typeform-360038717652/">https://www.typeform.com/help/a/what-browsers-and-devices-are-supported-by-typeform-360038717652/</a></p>
      </Styled.Description>
    </Styled.Content>
  );
};
