import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { getTransactionsAverageThunk } from "../thunks";
import { TransactionParamsAverage } from "../types/TransactionParams";

type UseGetTransactionsAverage = () => {
  getTransactionsAverage: (params: TransactionParamsAverage) => void;
};

export const useGetTransactionsAverage: UseGetTransactionsAverage = () => {
  const dispatch = useDispatch();

  const getTransactionsAverage = useCallback(
    (params) => {
      dispatch(getTransactionsAverageThunk(params));
    },
    [dispatch]
  );

  return {
    getTransactionsAverage,
  };
};
