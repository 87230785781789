import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { getEmployeesThunk } from "../thunks";
import { EmployeesParams } from "../types/EmployeesParams";

type UseGetEmployees = () => {
  getEmployees: (params: EmployeesParams) => void;
};

export const useGetEmployees: UseGetEmployees = () => {
  const dispatch = useDispatch();

  const getEmployees = useCallback(
    (params) => {
      dispatch(getEmployeesThunk(params));
    },
    [dispatch]
  );

  return {
    getEmployees,
  };
};
