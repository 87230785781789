import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";
import bg from './bg.jpg';

const Wrapper = styled.div`
  display: flex;

  min-height: 100vh;

  @media (max-width: 991px) {
    max-width: 520px;
    margin: 0 auto;
  }
`;

const Background = styled.div`
  flex-shrink: 0;

  position: relative;

  width: 50%;
  min-height: 100vh;

  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center left 45%;

  overflow: hidden;

  @media (max-width: 1700px) {
    background-position: center left 45%;
  }

  @media (max-width: 1450px) {
    width: 45%;
    background-position: center left 50%;
  }

  @media (max-width: 991px) {
    display: none;
  }

  &::after {
    content: "";

    position: absolute;

    left: -340px;
    bottom: -500px;

    width: 1000px;
    height: 1000px;

    border-radius: 50%;

    background-color: ${Colors.Primary};

    opacity: 0.7;

    @media (max-width: 1700px) {
      left: -272px;
      bottom: -400px;
      width: 800px;
      height: 800px;
    }
  }
`;

const Content = styled.div`
  flex-shrink: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 50%;
  min-height: 100vh;

  padding: 50px 72px;

  @media (max-width: 1599px) {
    padding: 50px 55px;
  }

  @media (max-width: 1450px) {
    width: 55%;
  }

  @media (max-width: 991px) {
    width: 100%;
  }

  @media (max-width: 575px) {
    padding: 50px 35px;
  }
`;

const ContentContainer = styled.div`
  max-width: 690px;

  @media (max-width: 575px) {
    max-width: 302px;
    margin: 0 auto;
  }
`;

const Description = styled.div`
  max-width: 422px;
  margin-bottom: 45px;

  font-family: ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 147%;

  @media (max-width: 1199px) {
    max-width: 377px;
    margin-bottom: 28px;
    font-size: 16px;
  }

  @media (max-width: 575px) {
    max-width: 302px;
  }

  p {
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  span {
    text-decoration: underline;
    white-space: nowrap;
  }

  a {
    color: #FE8E75;
    white-space: nowrap;
  }
`;

const ButtonLink = styled.button`
  position: relative;

  margin-bottom: 20px;
  padding: 0;

  text-decoration: underline;

  border: none;

  background: transparent;
  color: ${Colors.Primary};

  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  span {
    position: absolute;
    top: 1px;
    left: calc(100% + 5px);
    font-size: 18px;
  }
`

export const Styled = {
  Wrapper,
  Background,
  Content,
  ContentContainer,
  Description,
  ButtonLink,
};
