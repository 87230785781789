import { Col, Row } from "antd";
import { useMerchant } from "../../../data-layer/merchant";
import { useEffect } from "react";
import { useApplication } from "../../../data-layer/application";
import { UserCount } from "./components/UserCount";
import { Button, Paper } from "components";
import { BusinessActivity } from "./components/BusinessActivity/BusinessActivity";
import { Period } from "./components/Period/Period";
import { useInitParams } from "./useInitParams";
import { usePartner } from "data-layer/partner";
import { GeneralStyled } from "./styled";
import { InviteButton } from "./components/InviteButton";
import { ReferralButton } from "./components/ReferralButton";
import { BulkUploadButton } from "./components/BulkUploadButton";
import { history } from "data-layer/../router/AppRouter";
import bg from "./bg.svg";

export const OverviewSc = () => {
  const { getMerchantPartnerCount, dataMerchantPartnerCount } = useMerchant();
  const { getApplicationCount, applicationCount } = useApplication();
  const { filters } = useInitParams();
  const { getStats, getTurnoverPercentage } = usePartner();

  useEffect(() => {
    getMerchantPartnerCount();
    getApplicationCount();
  }, []);

  useEffect(() => {
    if (filters) {
      getTurnoverPercentage(filters?.from, filters?.to);
      getStats(filters?.from, filters?.to);
    }
  }, [filters]);

  return (
    <div className="container">
      <div className="page">
        <GeneralStyled.MainRow>
          <GeneralStyled.MainCol>
            <Paper>
              <Row
                gutter={[
                  { xs: 0, sm: 0, xl: 45, xxl: 85 },
                  { xs: 53, sm: 53, md: 0 },
                ]}
              >
                <GeneralStyled.LeftCol xs={24} sm={24} md={12}>
                  <UserCount />
                </GeneralStyled.LeftCol>
                <Col xs={24} sm={24} md={12}>
                  <BusinessActivity />
                </Col>
              </Row>
              <Period from={filters?.from} to={filters?.to} />
            </Paper>
          </GeneralStyled.MainCol>

          <GeneralStyled.InfoCol>
            <Paper style={{ position: "relative", height: "100%" }}>
              <GeneralStyled.Info>
                <Row gutter={[0, 45]}>
                  <Col span={24}>
                    <GeneralStyled.InviteWrapper>
                      <GeneralStyled.InfoTitle>
                        Invite merchants
                      </GeneralStyled.InfoTitle>
                      <GeneralStyled.InfoDescription>
                        After you invite merchants and they register using your
                        link, you will see them on the merchant page
                      </GeneralStyled.InfoDescription>
                      <InviteButton />
                      <GeneralStyled.InfoMerchantsLink>
                        <Button
                          type="primary-transparent"
                          size="small"
                          block
                          onClick={() => {
                            history.push(
                              "/dashboard/merchants"
                            );
                          }}
                        >
                          See merchants
                        </Button>
                      </GeneralStyled.InfoMerchantsLink>
                    </GeneralStyled.InviteWrapper>
                  </Col>

                  <Col span={24}>
                    <GeneralStyled.InviteWrapper>
                      <GeneralStyled.InfoTitle>
                        Referral Link
                      </GeneralStyled.InfoTitle>
                      <GeneralStyled.InfoDescription>
                        You can share referral link with your merchant
                      </GeneralStyled.InfoDescription>
                      <ReferralButton />
                    </GeneralStyled.InviteWrapper>
                  </Col>

                  <Col span={24} style={{zIndex: 1}}>
                    <GeneralStyled.BulkUploadWrapper>
                      <GeneralStyled.InfoTitle>
                        Bulk upload
                      </GeneralStyled.InfoTitle>
                      <GeneralStyled.InfoDescription>
                        Add large list merchants with using the XLS file
                      </GeneralStyled.InfoDescription>

                      <BulkUploadButton />
                    </GeneralStyled.BulkUploadWrapper>
                  </Col>
                </Row>
              </GeneralStyled.Info>

              <GeneralStyled.InfoBg src={bg} />
            </Paper>
          </GeneralStyled.InfoCol>
        </GeneralStyled.MainRow>
      </div>
    </div>
  );
};
