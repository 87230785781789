import styled from 'styled-components';
import { Fonts } from 'styles/fonts';

const Wrapper = styled.div`
  height: 100%;
  padding: 30px;
  border-radius: 15px;
  background-color: #D8D9DD;
`

const Title = styled.p`
  margin-bottom: 16px;
  font-family: ${Fonts.Gotham};
  font-size: 30px;
  line-height: 40px;
  color: #08243B;
`

const Description = styled.p`
  max-width: 190px;
  font-family: ${Fonts.Circe};
  font-size: 25px;
  line-height: 37px;
  color: #1D1D1F;
`

const ButtonWrapper = styled.div`
  margin-top: 20px;
`

export const Styled = {
  Wrapper,
  Title,
  Description,
  ButtonWrapper,
}