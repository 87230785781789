import { Styled } from "./styles";
import {DownloadLink} from "components";
import { useMerchant } from "data-layer/merchant";
import { baseApiPath } from "services/network/api";

import React from "react";


export const MerchantDocuments: React.FC = () => {
  const { me } = useMerchant();

  return (
    <Styled.Wrapper>
      <Styled.Title>Merchant documents</Styled.Title>

      <div>
        <DownloadLink
            link={`${baseApiPath}/attachments/download/${me?.merchantDocumentsData?.msaFileName}`}
            placeholder={"Merchant Service Agreement"}
        />
      </div>

    </Styled.Wrapper>
  );
};
