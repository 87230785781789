import { useEffect } from "react";
import { Filters } from "./useInitParams";

type UseGetTurnover = (filters: Filters | null) => void;

export const useGetTurnover: UseGetTurnover = (filters) => {
  // const { getOnboardigs } = useOnboardings();

  useEffect(() => {
    if (!filters) {
      return;
    }

    // getTransactions(filters);
  }, [filters]);
};
