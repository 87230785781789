import { loaderSlice } from "data-layer/common/loaderSlice";
import { AppDispatch } from "data-layer/store/store";
import { CreateEmployeeDTO, ToggleEmployeePermissionDTO } from "generated";
import { handleNetworkError } from "utils/handleNetworkError";
import { showError } from "utils/showError";
import { showSuccess } from "utils/showSuccess";
import { employeesApi } from "./api";
import { employeesSlice } from "./slice";
import { EmployeesParams } from "./types/EmployeesParams";

export const getEmployeesThunk = (params: EmployeesParams) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      dispatch(employeesSlice.actions.getEmployeesRequest());

      const { limit, offset } = params;
      const result = await employeesApi.api.getEmployees(limit, offset);

      dispatch(employeesSlice.actions.getEmployeesSuccess(result));
    } catch (e) {
      handleNetworkError(e, "Employee loading error");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
      dispatch(employeesSlice.actions.getEmployeesFailure());
    }
  };
};

export const createEmployeeThunk = (body: CreateEmployeeDTO, cb?: VoidFunction) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      const result = await employeesApi.api.createEmployee(body);

      dispatch(employeesSlice.actions.createEmployeeSuccess(result));
      showSuccess("Employee created");

      if (cb && typeof cb === "function") {
        cb();
      }
    } catch (e) {
      handleNetworkError(e, "Employee creation error");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};

export const togglePermissionThunk = (body: ToggleEmployeePermissionDTO) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      const result = await employeesApi.api.togglePermission(body);
      dispatch(employeesSlice.actions.updateEmployee(result));

      showSuccess("Employee changed");
    } catch (e) {
      showError("Employee error", e?.body?.error || e.message);
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
}