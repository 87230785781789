/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MDLRPayoutEventDTO } from '../models/MDLRPayoutEventDTO';
import type { ResponseEntity } from '../models/ResponseEntity';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { network } from 'services/network';

export class MdlrWebhookControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * responseEntity
     * @param xModNonce x-mod-nonce
     * @param authorization authorization
     * @param date date
     * @param requestBody
     * @returns ResponseEntity OK
     * @returns any Created
     * @throws ApiError
     */
    public responseEntityUsingPost(
        xModNonce: string,
        authorization: string,
        date: string,
        requestBody?: MDLRPayoutEventDTO,
    ): CancelablePromise<ResponseEntity | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/webhook/mdlr/updates/payout',
                headers: {
                    'x-mod-nonce': xModNonce,
                    'authorization': authorization,
                    'date': date,
                },
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

}