import { useEffect } from "react";
import { OpenAPI } from "generated";
import { OpenAPI as OpenAPISunset } from "generatedSunset";
import { OpenAPI as OpenAPICrate } from "generatedCrate";
import { clearUser } from "utils/clearUser";
import { useSearchParams } from "react-router-dom";
import { useCommon } from "data-layer/common";
import { useEmployee } from "data-layer/employee";

type UseAuthByToken = (
  authToken: string | null,
  setIsAuthTokenChecked: (value: boolean) => void
) => void;

export const useAuthByToken: UseAuthByToken = (
  authToken,
  setIsAuthTokenChecked
) => {
  const { authorizeFromApp, setAuthorized, employee } = useEmployee();
  const { syncMerchant } = useCommon();

  const [searchParams] = useSearchParams();  
  const fromApp = searchParams.get("fromApp");

  const authByToken = (authToken: string) => {
    clearUser();
    OpenAPI.TOKEN = authToken;
    OpenAPISunset.TOKEN = authToken;
    OpenAPICrate.TOKEN = authToken;
    syncMerchant(
      () => {
        if (fromApp == "true") {
          authorizeFromApp();
        }

        setAuthorized();
        setIsAuthTokenChecked(true);
      },
      () => {
        searchParams.delete("authToken");
        searchParams.delete("fromApp");
        setIsAuthTokenChecked(true);
      }
    );
  };

  useEffect(() => {
    if (employee?.authorized) {
      setIsAuthTokenChecked(true);
      return;
    }

    // if opened in webview
    if (authToken) {
      authByToken(authToken);
      return;
    } else {
      setIsAuthTokenChecked(true);
    }
  }, []);
};
