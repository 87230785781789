/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { AdminOverviewControllerService } from './services/AdminOverviewControllerService';
import { ApplicationControllerService } from './services/ApplicationControllerService';
import { AttachmentsControllerService } from './services/AttachmentsControllerService';
import { BasicErrorControllerService } from './services/BasicErrorControllerService';
import { CdxWebhookControllerService } from './services/CdxWebhookControllerService';
import { CompanyControllerService } from './services/CompanyControllerService';
import { ComplianceControllerService } from './services/ComplianceControllerService';
import { CrmControllerService } from './services/CrmControllerService';
import { DictionaryControllerService } from './services/DictionaryControllerService';
import { DlqControllerService } from './services/DlqControllerService';
import { DocumentRequestControllerService } from './services/DocumentRequestControllerService';
import { EmployeeControllerService } from './services/EmployeeControllerService';
import { EmployeePermissionControllerService } from './services/EmployeePermissionControllerService';
import { LoqrWebhookControllerService } from './services/LoqrWebhookControllerService';
import { MerchantControllerService } from './services/MerchantControllerService';
import { MerchantDevControllerService } from './services/MerchantDevControllerService';
import { MerchantImportControllerService } from './services/MerchantImportControllerService';
import { NotificationControllerService } from './services/NotificationControllerService';
import { OperationHandlerService } from './services/OperationHandlerService';
import { PartnerControllerService } from './services/PartnerControllerService';
import { PaymentLinkControllerService } from './services/PaymentLinkControllerService';
import { ProfileControllerService } from './services/ProfileControllerService';
import { ProofPersonRequestControllerService } from './services/ProofPersonRequestControllerService';
import { ReportControllerService } from './services/ReportControllerService';
import { RoleControllerService } from './services/RoleControllerService';
import { SysI18NControllerService } from './services/SysI18NControllerService';
import { SysPropertyControllerService } from './services/SysPropertyControllerService';
import { TerminalControllerService } from './services/TerminalControllerService';
import { TransactionControllerService } from './services/TransactionControllerService';
import { TransactionDevControllerService } from './services/TransactionDevControllerService';
import { UserControllerService } from './services/UserControllerService';
import { WebhooksControllerService } from './services/WebhooksControllerService';
import { WebhookSubscriptionControllerService } from './services/WebhookSubscriptionControllerService';
import { WebMvcLinksHandlerService } from './services/WebMvcLinksHandlerService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class AppClient {

    public readonly adminOverviewController: AdminOverviewControllerService;
    public readonly applicationController: ApplicationControllerService;
    public readonly attachmentsController: AttachmentsControllerService;
    public readonly basicErrorController: BasicErrorControllerService;
    public readonly cdxWebhookController: CdxWebhookControllerService;
    public readonly companyController: CompanyControllerService;
    public readonly complianceController: ComplianceControllerService;
    public readonly crmController: CrmControllerService;
    public readonly dictionaryController: DictionaryControllerService;
    public readonly dlqController: DlqControllerService;
    public readonly documentRequestController: DocumentRequestControllerService;
    public readonly employeeController: EmployeeControllerService;
    public readonly employeePermissionController: EmployeePermissionControllerService;
    public readonly loqrWebhookController: LoqrWebhookControllerService;
    public readonly merchantController: MerchantControllerService;
    public readonly merchantDevController: MerchantDevControllerService;
    public readonly merchantImportController: MerchantImportControllerService;
    public readonly notificationController: NotificationControllerService;
    public readonly operationHandler: OperationHandlerService;
    public readonly partnerController: PartnerControllerService;
    public readonly paymentLinkController: PaymentLinkControllerService;
    public readonly profileController: ProfileControllerService;
    public readonly proofPersonRequestController: ProofPersonRequestControllerService;
    public readonly reportController: ReportControllerService;
    public readonly roleController: RoleControllerService;
    public readonly sysI18NController: SysI18NControllerService;
    public readonly sysPropertyController: SysPropertyControllerService;
    public readonly terminalController: TerminalControllerService;
    public readonly transactionController: TransactionControllerService;
    public readonly transactionDevController: TransactionDevControllerService;
    public readonly userController: UserControllerService;
    public readonly webhooksController: WebhooksControllerService;
    public readonly webhookSubscriptionController: WebhookSubscriptionControllerService;
    public readonly webMvcLinksHandler: WebMvcLinksHandlerService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? 'https://dev.backend.dapio.com:443',
            VERSION: config?.VERSION ?? '1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.adminOverviewController = new AdminOverviewControllerService(this.request);
        this.applicationController = new ApplicationControllerService(this.request);
        this.attachmentsController = new AttachmentsControllerService(this.request);
        this.basicErrorController = new BasicErrorControllerService(this.request);
        this.cdxWebhookController = new CdxWebhookControllerService(this.request);
        this.companyController = new CompanyControllerService(this.request);
        this.complianceController = new ComplianceControllerService(this.request);
        this.crmController = new CrmControllerService(this.request);
        this.dictionaryController = new DictionaryControllerService(this.request);
        this.dlqController = new DlqControllerService(this.request);
        this.documentRequestController = new DocumentRequestControllerService(this.request);
        this.employeeController = new EmployeeControllerService(this.request);
        this.employeePermissionController = new EmployeePermissionControllerService(this.request);
        this.loqrWebhookController = new LoqrWebhookControllerService(this.request);
        this.merchantController = new MerchantControllerService(this.request);
        this.merchantDevController = new MerchantDevControllerService(this.request);
        this.merchantImportController = new MerchantImportControllerService(this.request);
        this.notificationController = new NotificationControllerService(this.request);
        this.operationHandler = new OperationHandlerService(this.request);
        this.partnerController = new PartnerControllerService(this.request);
        this.paymentLinkController = new PaymentLinkControllerService(this.request);
        this.profileController = new ProfileControllerService(this.request);
        this.proofPersonRequestController = new ProofPersonRequestControllerService(this.request);
        this.reportController = new ReportControllerService(this.request);
        this.roleController = new RoleControllerService(this.request);
        this.sysI18NController = new SysI18NControllerService(this.request);
        this.sysPropertyController = new SysPropertyControllerService(this.request);
        this.terminalController = new TerminalControllerService(this.request);
        this.transactionController = new TransactionControllerService(this.request);
        this.transactionDevController = new TransactionDevControllerService(this.request);
        this.userController = new UserControllerService(this.request);
        this.webhooksController = new WebhooksControllerService(this.request);
        this.webhookSubscriptionController = new WebhookSubscriptionControllerService(this.request);
        this.webMvcLinksHandler = new WebMvcLinksHandlerService(this.request);
    }
}
