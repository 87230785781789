/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaymentLinkDTO } from '../models/PaymentLinkDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { network } from 'services/network';

export class PaymentLinkControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create payment link
     * Create payment link
     * @param requestBody
     * @returns PaymentLinkDTO
     * @returns any Created
     * @throws ApiError
     */
    public createUsingPost(
        requestBody?: PaymentLinkDTO,
    ): CancelablePromise<PaymentLinkDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/payment-link',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Find payment links by filter (Admin)
     * Find payment links by filter (Admin)
     * @param merchantId merchantId
     * @param from from
     * @param to to
     * @param limit limit
     * @param offset offset
     * @param customerName customerName
     * @param amountMin amountMin
     * @param amountMax amountMax
     * @param status status
     * @returns PaymentLinkDTO
     * @throws ApiError
     */
    public findAdminUsingGet(
        merchantId: number,
        from: string,
        to: string,
        limit: number,
        offset: number,
        customerName?: string,
        amountMin?: number,
        amountMax?: number,
        status?: 'CREATED' | 'EXPIRED' | 'PAID',
    ): CancelablePromise<Array<PaymentLinkDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/payment-link/admin/find',
                query: {
                    'merchantId': merchantId,
                    'customerName': customerName,
                    'amountMin': amountMin,
                    'amountMax': amountMax,
                    'status': status,
                    'from': from,
                    'to': to,
                    'limit': limit,
                    'offset': offset,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Find payment links by filter
     * Find payment links by filter
     * @param from from
     * @param to to
     * @param limit limit
     * @param offset offset
     * @param customerName customerName
     * @param amountMin amountMin
     * @param amountMax amountMax
     * @param status status
     * @returns PaymentLinkDTO
     * @throws ApiError
     */
    public findUsingGet(
        from: string,
        to: string,
        limit: number,
        offset: number,
        customerName?: string,
        amountMin?: number,
        amountMax?: number,
        status?: 'CREATED' | 'EXPIRED' | 'PAID',
    ): CancelablePromise<Array<PaymentLinkDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/payment-link/find',
                query: {
                    'customerName': customerName,
                    'amountMin': amountMin,
                    'amountMax': amountMax,
                    'status': status,
                    'from': from,
                    'to': to,
                    'limit': limit,
                    'offset': offset,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

}