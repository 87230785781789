import { createSelector } from "@reduxjs/toolkit";
import { DocumentRequestDTO } from "generated";
import { AppState } from "../store";

export const documentsSelector = (state: AppState) => state.documents;

export const documentsDataByProfileDataSelector = (state: AppState) => state.documents.dataByProfile;

export const documentsByProfileLoadingSelector = (state: AppState) =>
  state.documents.isProfileDataLoading;

export const newDocumentsDataByProfileSelector = createSelector(
  documentsDataByProfileDataSelector,
  (documents) => {
    if (!documents || !documents.length) {
      return [];
    }

    const newDocuments = documents.filter(
      (document) => document.state === DocumentRequestDTO.state.NEW
    );

    return newDocuments;
  }
);

export const activeModalIdSelector = (state: AppState) =>
  state.documents.activeModalId;
