import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { keysEqual } from "utils/keysEqual";
import { MerchantsParams } from "data-layer/merchant/types/MerchantsParams";

type SearchParamsFullfiled = {
  limit: string;
  offset: string;
  name: string;
};

export type UseInitParams = () => {
  filters: MerchantsParams | null;
};

export const DEFAULT_LIMIT = 20;

export const useInitParams: UseInitParams = () => {
  const [filters, setFilters] = useState<MerchantsParams | null>(null);
  const [searchParams, setSearchParams] = useSearchParams({});

  const hasEmptyRequiredParam = (params: { [key: string]: string | null }) => {
    let hasEmptyParams = false;

    Object.keys(params).forEach((key: string) => {
      if (!params[key]) {
        hasEmptyParams = true;
      }
    });

    return hasEmptyParams;
  };

  const hasDifferent = (
    filters: MerchantsParams,
    nextFilters: MerchantsParams
  ) => {
    if (!keysEqual(filters, nextFilters)) {
      return true;
    }

    let hasDifferent = false;

    Object.keys(nextFilters).map((key) => {
      if (nextFilters[key] !== filters[key]) {
        hasDifferent = true;
      }
    });

    return hasDifferent;
  };

  const formatDataToState = (
    params: SearchParamsFullfiled
  ): MerchantsParams => {
    return {
      ...params,
      limit: Number(params.limit),
      offset: Number(params.offset),
    } as MerchantsParams;
  };

  useEffect(() => {
    const limit = searchParams.get("limit");
    const offset = searchParams.get("offset");
    const name = searchParams.get("name");

    const nextSearchParams: SearchParamsFullfiled = {
      limit: String((limit && Number(limit)) || DEFAULT_LIMIT),
      offset: String((offset && Number(offset)) || 0),
    };

    if (name) {
      nextSearchParams.name = name;
    }

    if (
      hasEmptyRequiredParam({
        limit,
        offset,
      })
    ) {
      setSearchParams(nextSearchParams);
      return;
    }

    if (!filters) {
      setFilters(formatDataToState(nextSearchParams));
      return;
    }

    if (hasDifferent(filters, formatDataToState(nextSearchParams))) {
      setFilters(formatDataToState(nextSearchParams));
    }
  }, [searchParams]);

  return {
    filters,
    searchParams,
  };
};
