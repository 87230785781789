import styled from "styled-components";
import { Colors } from "styles/colors";
import bg from './_bg.jpg';

const Wrapper = styled.div`
  display: flex;

  min-height: 100vh;
`;

const Background = styled.div`
  flex-shrink: 0;

  position: relative;

  width: 47%;
  min-height: 100vh;

  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center left 35%;

  overflow: hidden;

  @media (max-width: 1700px) {
    background-position: center left 40%;
  }

  @media (max-width: 1450px) {
    background-position: center left 45%;
  }

  @media (max-width: 1199px) {
    width: 550px;
  }

  @media (max-width: 991px) {
    display: none;
  }

  &::after {
    content: '';

    position: absolute;

    left: -510px;
    top: -510px;

    width: 1050px;
    height: 1050px;

    border-radius: 50%;

    background-color: ${Colors.Primary};

    opacity: 0.7;

    @media (max-width: 1700px) {
      left: -380px;
      top: -380px;
      width: 800px;
      height: 800px;
    }
  }
`;

const Content = styled.div`
  flex-shrink: 0;

  display: flex;
  flex-direction: column;

  width: 53%;
  min-height: 100vh;

  padding: 100px 72px;

  @media (max-width: 1199px) {
    flex-grow: 1;
    width: auto;
    padding: 50px 55px;
  }

  @media (max-width: 991px) {
    width: 100%;
  }

  @media (max-width: 575px) {
    padding: 50px 25px;
  }
`;

const ContentContainer = styled.div`
  max-width: 422px;

  @media (max-width: 1199px) {
    max-width: 372px;
  }

  @media (max-width: 991px) {
    margin: 0 auto;
  }

  @media (max-width: 575px) {
    max-width: 302px;
  }
`

export const Styled = {
  Wrapper,
  Background,
  Content,
  ContentContainer,
};
