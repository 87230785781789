import styled from "styled-components";
import { Fonts } from "styles/fonts";

const Button = styled.div`
  position: relative;
  z-index: 1;

  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 50px;
  padding-right: 16px;

  font-family: ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 100%;

  border-bottom: 1px solid #00000040;

  cursor: pointer;

  @media (max-width: 1919px) {
    width: calc(100% + 78px);
    margin-left: -78px;
    padding-left: 78px;
  }

  @media (max-width: 1450px) {
    height: 40px;
    font-size: 14px;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-left: 0;
    padding: 0 20px;
  }

  @media (max-width: 575px) {
    padding: 0 15px;
  }

  svg {
    font-size: 23px;
    color: #8096A7;

    @media (max-width: 1450px) {
      font-size: 17px;
    }
  }

  & > span:first-child {
    padding-top: 2px;
  }
`;

export const Styled = {
  Button,
};
