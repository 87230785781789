import { Col } from "antd";
import { Styled } from "./stylesFile";
// import { FolderOutlined } from "@ant-design/icons";

type InputFileProps = {
  name: string;
  handleRemove: VoidFunction;
};

export const File: React.FC<InputFileProps> = (props) => {
  const { name, handleRemove } = props;

  return (
    <Styled.Wrapper align="middle">
      <Col flex={1}>
        <Styled.Field>
          <Styled.FieldText>{name}</Styled.FieldText>
        </Styled.Field>
      </Col>
      <Col>
        <Styled.RemoveButton onClick={handleRemove} />
      </Col>
    </Styled.Wrapper>
  );

  // return (
  //   <Row gutter={{ xs: 15, sm: 15, xl: 20 }}>
  //     <Col style={{ position: "relative" }} flex={1}>
  //       <Row gutter={{ xs: 15, sm: 15, xl: 20 }}>
  //         <Col flex={1}>
  //           <Styled.Field>
  //             <Styled.FieldText>{name}</Styled.FieldText>
  //             <FolderOutlined />
  //           </Styled.Field>
  //         </Col>
  //       </Row>
  //     </Col>
  //     <Col>
  //       <Styled.RemoveButton onClick={handleRemove} />
  //     </Col>
  //   </Row>
  // );
};
