import { useSelector } from "react-redux";
import { IEmployeePermissions } from "../";
import { employeePermissionsSelector } from "../selectors";
import { useGetEmployeePermissions } from "./useGetEmployeePermissions";

export const useEmployeePermissions = (): IEmployeePermissions => {
  const { getEmployeePermissions } = useGetEmployeePermissions();
  const employeePermissions = useSelector(employeePermissionsSelector);

  return {
    getEmployeePermissions,
    employeePermissions,
  };
};
