import { AppState } from "../store";

export const transactionsSelector = (state: AppState) => state.transactions;

export const isFetchingSelector = (state: AppState) =>
  state.transactions.isFetching;

export const transactionsDataSelector = (state: AppState) =>
  state.transactions.data;

export const isFetchingAmountSelector = (state: AppState) =>
  state.transactions.isFetchingAmount;

export const transactionsDataAmountSelector = (state: AppState) =>
  state.transactions.dataAmount;

export const isFetchingAverageSelector = (state: AppState) =>
  state.transactions.isFetchingAverage;

export const transactionsDataAverageSelector = (state: AppState) =>
  state.transactions.dataAverage;
