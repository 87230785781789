import { ClassNameProp } from "ui/types";
import { Styled } from "./styles";

export const H1: React.FC<ClassNameProp> = (props) => {
  return <Styled.H1 className={props.className}>{props.children}</Styled.H1>;
};

export const H2: React.FC<ClassNameProp> = (props) => {
  return <Styled.H2 className={props.className}>{props.children}</Styled.H2>;
};

export const Body1: React.FC<ClassNameProp> = (props) => {
  return <Styled.Body1 className={props.className}>{props.children}</Styled.Body1>;
};

export const Body2: React.FC<ClassNameProp> = (props) => {
  return <Styled.Body2 className={props.className}>{props.children}</Styled.Body2>;
};

export const Caption: React.FC<ClassNameProp> = (props) => {
  return <Styled.Caption className={props.className}>{props.children}</Styled.Caption>;
};

export const Typography = {
  H1,
  H2,
  Body1,
  Body2,
  Caption,
};
