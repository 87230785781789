import { Col, Row } from "antd";
import { usePagination, Filters } from "./usePagination";

import { Styled } from "./styles";

type PaginationScProps = {
  filters: Filters;
  data: unknown[];
};

export const PaginationSc: React.FC<PaginationScProps> = (props) => {
  const { filters, data } = props;

  const { page, handleNext, handlePrev, handleFirstPage } =
    usePagination(filters);

  const isPrevDisabled = page === 1;
  const isNextDisabled = !data.length || data.length < filters.limit;

  return (
    <Styled.Wrapper>
      <Row justify="end">
        <Col>
          <Styled.PrevButton disabled={isPrevDisabled} onClick={handlePrev} />
        </Col>
        <Col>
          {page > 2 && (
            <>
              <Styled.Page onClick={handleFirstPage}>1</Styled.Page>
              <Styled.Dots>...</Styled.Dots>
            </>
          )}
        </Col>
        <Col>
          <Styled.Page active={true}>{page}</Styled.Page>
        </Col>
        <Col>
          <Styled.NextButton disabled={isNextDisabled} onClick={handleNext} />
        </Col>
      </Row>
    </Styled.Wrapper>
  );
};
