import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { updateFrtMobileThunk } from "data-layer/employee/thunks";

type UseUpdateFrtMobile = () => {
  updateFrtMobile: (token: string) => void;
};

export const useUpdateFrtMobile: UseUpdateFrtMobile = () => {
  const dispatch = useDispatch();

  const updateFrtMobile = useCallback(
    (frt: string) => {
      dispatch(updateFrtMobileThunk(frt));
    },
    [dispatch]
  );

  return {
    updateFrtMobile,
  };
};
