import { Paper } from "components";
import { Styled } from "./styles";
import { useToggle } from "../../../../../common/useToggle";
import { Col, Row } from "antd";
import { ArrowRightOutlined, CheckOutlined } from "@ant-design/icons";
import { useProfile } from "data-layer/profile";
import { ProfileDTO } from "generated";
import { useMerchant } from "data-layer/merchant";
import { history } from "data-layer/../router/AppRouter";

type RoadMapProps = {
  onlyActive?: boolean;
};

export const RoadMap: React.FC<RoadMapProps> = (props) => {
  const { selectedProfile } = useProfile();
  const { me } = useMerchant();

  return (
    <Paper style={{ height: "100%" }}>
      <Styled.Title active={true} onlyActive={props.onlyActive}>
        Welcome to Dapio
      </Styled.Title>
      <Styled.Content active={true}>
        <Styled.Description>
          Our Setup Guide will walk you through how to get start work in Dapio.
        </Styled.Description>

        <Styled.List>
          <li>
            <Row wrap={false} justify="space-between" align="middle">
              <Col>
                <Styled.ListItemTitle>Complete onboarding</Styled.ListItemTitle>
              </Col>
              <Col>
                {selectedProfile?.status === ProfileDTO.status.APPROVED ? (
                  <Styled.ListItemIcon completed={true}>
                    <CheckOutlined />
                  </Styled.ListItemIcon>
                ) : (
                  <Styled.ListItemIcon>
                    <ArrowRightOutlined />
                  </Styled.ListItemIcon>
                )}
              </Col>
            </Row>
          </li>
          <li>
            <Row wrap={false} justify="space-between" align="middle">
              <Col>
                <Styled.ListItemTitle>Download the app</Styled.ListItemTitle>
              </Col>
              <Col>
                <a
                  href="https://play.google.com/store/apps/details?id=com.dapio.taptopay"
                  target="_blank"
                >
                  <Styled.ListItemIcon>
                    <ArrowRightOutlined />
                  </Styled.ListItemIcon>
                </a>
              </Col>
            </Row>
          </li>
          <li>
            <Row wrap={false} justify="space-between" align="middle">
              <Col>
                <Styled.ListItemTitle>
                  Link your bank account
                </Styled.ListItemTitle>
              </Col>
              <Col>
                {!me?.settlementDetailsData?.bankAccountNumber ||
                !me?.settlementDetailsData?.bankAccountHolderName ||
                !me?.settlementDetailsData?.sortCode ? (
                  <Styled.ListItemIcon
                    onClick={() => {
                      history.push("/dashboard/settings");
                    }}
                  >
                    <ArrowRightOutlined />
                  </Styled.ListItemIcon>
                ) : (
                  <Styled.ListItemIcon completed={true}>
                    <CheckOutlined />
                  </Styled.ListItemIcon>
                )}
              </Col>
            </Row>
          </li>
          <li>
            <Row wrap={false} justify="space-between" align="middle">
              <Col>Take your first payment</Col>
              <Col>
                <a
                  href="https://play.google.com/store/apps/details?id=com.dapio.taptopay"
                  target="_blank"
                >
                  <Styled.ListItemIcon>
                    <ArrowRightOutlined />
                  </Styled.ListItemIcon>
                </a>
              </Col>
            </Row>
          </li>
        </Styled.List>
      </Styled.Content>
    </Paper>
  );
};
