/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AttachmentEntity } from './AttachmentEntity';
import type { ProfileEntity } from './ProfileEntity';
import type { TokenEntity } from './TokenEntity';

export type ProofPersonRequestEntity = {
    attachments?: Array<AttachmentEntity>;
    created?: string;
    email?: string;
    id?: number;
    loqrExternalIdentityId?: string;
    loqrExternalProcessId?: string;
    loqrRejectReason?: string;
    modified?: string;
    profile?: ProfileEntity;
    state?: ProofPersonRequestEntity.state;
    token?: TokenEntity;
    version?: number;
};

export namespace ProofPersonRequestEntity {

    export enum state {
        APPROVED = 'APPROVED',
        NEW = 'NEW',
        REJECTED = 'REJECTED',
        REVIEWING = 'REVIEWING',
    }


}
