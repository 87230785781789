/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CHCompanyItemOldVersionDTO } from '../models/CHCompanyItemOldVersionDTO';
import type { CompanyDataVO } from '../models/CompanyDataVO';
import type { CompanyItemDTO } from '../models/CompanyItemDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { network } from 'services/network';

export class CompanyControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * profile
     * @param requestBody
     * @returns CompanyDataVO OK
     * @returns any Created
     * @throws ApiError
     */
    public profileUsingPost(
        requestBody?: CompanyItemDTO,
    ): CancelablePromise<CompanyDataVO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/company/profile',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * search
     * @param companyName companyName
     * @returns CHCompanyItemOldVersionDTO OK
     * @throws ApiError
     */
    public searchUsingGet(
        companyName: string,
    ): CancelablePromise<Array<CHCompanyItemOldVersionDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/company/search',
                query: {
                    'companyName': companyName,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

}