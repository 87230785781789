import { NotificationSimple } from "./components";
import { history } from "data-layer/../router/AppRouter";
import { NotificationType } from "data-layer/notifications/getNotificationData";

export const NotificationNotFinished: React.FC = () => {
  return (
    <NotificationSimple
      title="Complete your onboarding application"
      description="To get the service please come through the onboarding procedure"
      type={NotificationType.Warning}
      action={{
        title: "Continue",
        cb: () => history.push("/onboarding"),
      }}
    />
  );
};
