/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DLQMessageDTO } from '../models/DLQMessageDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { network } from 'services/network';

export class DlqControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Find all DLQ messages (admin)
     * Find all DLQ messages (admin)
     * @param limit limit
     * @param offset offset
     * @returns DLQMessageDTO
     * @throws ApiError
     */
    public findAllUsingGet(
        limit: number,
        offset: number,
    ): CancelablePromise<Array<DLQMessageDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/system/dlq',
                query: {
                    'limit': limit,
                    'offset': offset,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Delete by id (admin)
     * Delete by id (admin)
     * @param id id
     * @returns any
     * @throws ApiError
     */
    public deleteByIdUsingDelete(
        id: number,
    ): CancelablePromise<any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'DELETE',
                url: '/system/dlq/{id}',
                path: {
                    'id': id,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                },
            })
        });
    }

}