import { api } from "./api";
import { CancelablePromise } from "generated/core/CancelablePromise";
import { CHCompanyItemOldVersionDTO, CompanyDataVO, CompanyItemDTO } from "generated";

export interface ICompanyApi {
  getCompany: (body: CompanyItemDTO) => CancelablePromise<CompanyDataVO | any>;

  search: (
    companyName: string,
  ) => CancelablePromise<Array<CHCompanyItemOldVersionDTO>>;
}

class CompanyApi {
  api: ICompanyApi = api;

  constructor() {
    if (process.env.REACT_APP_MOCK_ENABLED === "true") {
      this.api = api;
      return;
    }

    this.api = api;
  }
}

export const сompanyApi = new CompanyApi();
