import { PaymentLinkDTO } from "generated";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { createPaymentLinkThunk } from "../thunks";

type UseCreatePaymentLink = () => {
  createPaymentLink: (
    data: PaymentLinkDTO,
    onCreatedLinkCb: (data: PaymentLinkDTO) => void
  ) => void;
};

export const useCreatePaymentLink: UseCreatePaymentLink = () => {
  const dispatch = useDispatch();

  const createPaymentLink = useCallback(
    (PaymentLinkDTO, onCreatedLinkCb) => {
      dispatch(createPaymentLinkThunk(PaymentLinkDTO, onCreatedLinkCb));
    },
    [dispatch]
  );

  return {
    createPaymentLink,
  };
};
