import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { changePinThunk } from "data-layer/employee/thunks";

type UseChangePin = () => {
  changePin: (token: string, pin: string) => void;
};

export const useChangePin: UseChangePin = () => {
  const dispatch = useDispatch();

  const changePin = useCallback(
    (token: string, pin: string) => {
      dispatch(changePinThunk(token, pin));
    },
    [dispatch]
  );

  return {
    changePin,
  };
};
