import { CancelablePromise, NotificationDTO } from "generated";
import { api } from "./api";

export interface INotificationsApi {
  getNotifications: (
    limit: number,
    offset: number
  ) => CancelablePromise<Array<NotificationDTO>>;
  markAsRead: (id: number) => CancelablePromise<NotificationDTO | any>;
  markAllAsRead: (ids?: number[]) => CancelablePromise<any>;
}

class NotificationsApi {
  api: INotificationsApi = api;

  constructor() {
    if (process.env.REACT_APP_MOCK_ENABLED === "true") {
      this.api = api;
      return;
    }

    this.api = api;
  }
}

export const notificationsApi = new NotificationsApi();
