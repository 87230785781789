/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AttachFilesRequestVO } from '../models/AttachFilesRequestVO';
import type { ProofPersonProcessDTO } from '../models/ProofPersonProcessDTO';
import type { ProofPersonRequestDTO } from '../models/ProofPersonRequestDTO';
import type { RequestProofProfilePersonDTO } from '../models/RequestProofProfilePersonDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { network } from 'services/network';

export class ProofPersonRequestControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @deprecated
     * Attach files to proof
     * Attach files to proof
     * @param requestBody
     * @returns ProofPersonRequestDTO
     * @returns any Created
     * @throws ApiError
     */
    public attachFilesUsingPost(
        requestBody?: AttachFilesRequestVO,
    ): CancelablePromise<ProofPersonRequestDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/proof-person/attach',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Continue verification process
     * Continue verification process
     * @param token token
     * @returns ProofPersonProcessDTO
     * @throws ApiError
     */
    public continueScheduledProcessByTokenUsingGet(
        token: string,
    ): CancelablePromise<ProofPersonProcessDTO> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/proof-person/continue',
                query: {
                    'token': token,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Find all proof requests for profile
     * Find all proof requests for profile
     * @param profileId profileId
     * @returns ProofPersonRequestDTO
     * @throws ApiError
     */
    public checkProcessForProfileUsingGet(
        profileId: number,
    ): CancelablePromise<Array<ProofPersonRequestDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/proof-person/profile/{profileId}',
                path: {
                    'profileId': profileId,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Start verification process right now
     * Start verification process right now
     * @param requestBody
     * @returns ProofPersonProcessDTO
     * @returns any Created
     * @throws ApiError
     */
    public startInstantProcessUsingPost(
        requestBody?: RequestProofProfilePersonDTO,
    ): CancelablePromise<ProofPersonProcessDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/proof-person/start/instant',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Start verification process later
     * Start verification process later
     * @param requestBody
     * @returns ProofPersonProcessDTO
     * @returns any Created
     * @throws ApiError
     */
    public startScheduledProcessUsingPost(
        requestBody?: RequestProofProfilePersonDTO,
    ): CancelablePromise<ProofPersonProcessDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/proof-person/start/scheduled',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

}