import { useState, useCallback } from "react";
import { Row } from "antd";
import { SvgIcon } from "components";
import { ReactComponent as ProfileSvg } from "assets/profile.svg";
import { Styled } from "./styles";
import "./styles.less";
import { ReactComponent as SettingsSvg } from "assets/settings.svg";
import { ReactComponent as ExitSvg } from "assets/exit.svg";
import { useEmployee } from "data-layer/employee";
import { usePartner } from "data-layer/partner";
import { history } from "data-layer/../router/AppRouter";

type ProfileMenuScProps = {
  closeMenu?: Function;
};

type ContentProps = ProfileMenuScProps & {
  handleClosePopup: VoidFunction;
  visible: boolean;
};

const Content: React.FC<ContentProps> = (props) => {
  const { visible, closeMenu } = props;
  const { logout } = useEmployee();
  const { partnerState } = usePartner();

  return (
    <Styled.Content visible={visible}>
      <Styled.ContentHeader>
        <Styled.SelectedItemTitle>
          {partnerState.data?.name}
        </Styled.SelectedItemTitle>
        {/* <Styled.SelectedItemStatus>Not verified</Styled.SelectedItemStatus> */}
      </Styled.ContentHeader>

      <Styled.ContentBody>
        <Styled.ContentGroup>
          <Styled.ContentGroupItem
            onClick={() => {
              history.push("/dashboard/settings");
              if (closeMenu && typeof closeMenu === "function") {
                closeMenu();
              }
            }}
          >
            <span>Settings</span>
            <SvgIcon svg={SettingsSvg} />
          </Styled.ContentGroupItem>

          <Styled.ContentGroupItem onClick={logout}>
            <span>Logout</span>
            <SvgIcon svg={ExitSvg} />
          </Styled.ContentGroupItem>
        </Styled.ContentGroup>
      </Styled.ContentBody>
    </Styled.Content>
  );

  // return (
  //   <Styled.Content visible={visible}>
  //     {/*todo popover должен закрываться после клика по дочернему элементу*/}
  //     <Styled.Button onClick={showCommingSoonModal}>
  //       <SvgIcon svg={SettingsSvg} />
  //       <span>Settings</span>
  //     </Styled.Button>
  //     <Styled.Logout onClick={logout}>
  //       <SvgIcon svg={ExitSvg} />
  //       <span>Log out</span>
  //     </Styled.Logout>
  //   </Styled.Content>
  // );
};

export const ProfileMenuSc: React.FC<ProfileMenuScProps> = (props) => {
  const { closeMenu } = props;
  const [visible, setVisible] = useState(false);
  const { partnerState } = usePartner();

  const handleVisibleChange = () => {
    setVisible(!visible);
  };

  const handleClosePopup = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  return (
    <Styled.Wrapper onClick={handleVisibleChange} active={visible}>
      <Styled.Header active={visible}>
        <Row gutter={20} wrap={false} align="middle">
          <Styled.Icon active={visible}>
            <SvgIcon svg={ProfileSvg} />
          </Styled.Icon>
          <Styled.Title active={visible}>
            {partnerState.data?.name}
          </Styled.Title>
        </Row>
      </Styled.Header>
      <Content
        handleClosePopup={handleClosePopup}
        visible={visible}
        closeMenu={closeMenu}
      />
    </Styled.Wrapper>
  );
};
