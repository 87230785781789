import { useEmployee } from "data-layer/employee";
import { Navigate } from "react-router-dom";

export const PrivatePartnerRoute: React.FC<any> = (props) => {
  const { children } = props;
  const { employee } = useEmployee();

  if (!employee?.user?.verified) {
    return <Navigate to="/signup/confirm" />;
  }

  return children;
};
