/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ApplicationRejectEntity } from './ApplicationRejectEntity';
import type { JsonNode } from './JsonNode';
import type { ProfileEntity } from './ProfileEntity';
import type { UserEntity } from './UserEntity';

export type ApplicationEntity = {
    created?: string;
    data?: JsonNode;
    id?: number;
    kind?: ApplicationEntity.kind;
    modified?: string;
    profile?: ProfileEntity;
    rejects?: Array<ApplicationRejectEntity>;
    reviewedBy?: UserEntity;
    state?: ApplicationEntity.state;
    version?: number;
};

export namespace ApplicationEntity {

    export enum kind {
        ONBOARDING_PF = 'ONBOARDING_PF',
    }

    export enum state {
        APPROVED = 'APPROVED',
        CHANGES_REQUESTED = 'CHANGES_REQUESTED',
        NEW = 'NEW',
        REJECTED = 'REJECTED',
        REVIEWING = 'REVIEWING',
    }


}
