import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProfileDTO } from "generated/models/ProfileDTO";

export type Profile = [] | Array<ProfileDTO>;

export type ProfileInitialstate = {
  profilePending: boolean;
  profiles: Profile;
  dataProfilesForPatner: Profile;
  isDataProfilesForPartnerPending: boolean;
};

const initialState: ProfileInitialstate = {
  profilePending: false,
  profiles: [],
  dataProfilesForPatner: [],
  isDataProfilesForPartnerPending: false,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState: initialState,
  reducers: {
    getProfileRequest: (state) => {
      state.profilePending = true;
    },
    getProfileSuccess: (state, action: PayloadAction<Array<ProfileDTO>>) => {
      state.profilePending = false;
      state.profiles = action.payload;
    },
    getProfileFailure: (state) => {
      state.profilePending = false;
      state.profiles = [];
    },

    createProfileSuccess: (state, action: PayloadAction<ProfileDTO>) => {
      const oldSelectedProfile = state.profiles.find(
        (profile) => profile.selected === true
      );

      if (oldSelectedProfile) {
        oldSelectedProfile.selected = false;
      }

      state.profiles = [...state.profiles, action.payload];
    },

    editProfileSuccess: (state, action: PayloadAction<ProfileDTO>) => {
      const profiles = state.profiles.filter(
        (profile) => profile.id !== action.payload.id
      );

      state.profiles = [...profiles, action.payload];
    },

    selectProfileSuccess: (state, action: PayloadAction<{ id: number }>) => {
      const oldSelectedProfile = state.profiles.find(
        (profile) => profile.selected === true
      );
      const newSelectedProfile = state.profiles.find(
        (profile) => profile.id === action.payload.id
      );

      oldSelectedProfile!.selected = false;
      newSelectedProfile!.selected = true;
    },

    setSelectedProfileStatus: (
      state,
      action: PayloadAction<ProfileDTO.status>
    ) => {
      state.profiles = state.profiles.map((profile) => {
        if (profile.selected)
          return {
            ...profile,
            status: action.payload,
          };

        return profile;
      });
    },

    getPartnerProfileRequest: (state) => {
      state.isDataProfilesForPartnerPending = true;
    },
    getPartnerProfileSuccess: (
      state,
      action: PayloadAction<Array<ProfileDTO>>
    ) => {
      state.dataProfilesForPatner = action.payload;
      state.isDataProfilesForPartnerPending = false;
    },
    getPartnerProfileFailure: (state) => {
      state.dataProfilesForPatner = [];
      state.isDataProfilesForPartnerPending = false;
    },
  },
});

export const profileActions = profileSlice.actions;
export const profileReducer = profileSlice.reducer;
