const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

let url = "";
switch (ENVIRONMENT) {
  case "production": {
    url = "api";
    break;
  }
  case "staging": {
    url = "staging.backend";
    break;
  }
  default: {
    url = "dev.backend";
  }
}

export const sekumpulApiPath = `https://${url}.dapio.com/sekumpul`;

export const baseApiPath = `https://${url}.dapio.com/duatiga`;
export const crateApiPath = `https://${url}.dapio.com/crate`;
export const authApiPath = `https://${url}.dapio.com/sunset`;
