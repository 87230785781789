import { AppState } from "../store";

export const partnerSelector = (state: AppState) => state.partner;

export const partnerTurnoverPercentageSelector = (state: AppState) =>
  state.partner.turnoverPercentageData;

export const partnerTurnoverPercentageLoadingSelector = (state: AppState) =>
  state.partner.isTurnoverPercentageLoading;

export const partnerStatsSelector = (state: AppState) =>
  state.partner.statsData;

export const partnerStatsLoadingSelector = (state: AppState) =>
  state.partner.isStatsLoading;
