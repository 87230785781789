import { Col, Row as AntdRow } from "antd";
import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

const Wrapper = styled.div`
  margin-top: auto;
  padding: 20px 0;

  border-top: 1px solid #d4d3d6;

  @media (max-width: 1199px) {
    padding: 15px 0;
  }
`;

const Row = styled(AntdRow)`
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Logo = styled.img`
  display: block;

  width: 100%;
  max-width: 100px;

  @media (max-width: 1199px) {
    max-width: 80px;
  }

  @media (max-width: 767px) {
    margin-bottom: 25px;
  }
`;

const List = styled.ul`
  display: flex;

  list-style-type: none;

  padding-top: 5px;
  padding-left: 0;

  @media (max-width: 1199px) {
    padding-top: 0;
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
  }

  a {
    font-family: ${Fonts.NexaRegular};
    font-size: 17px;
    line-height: 24px;
    text-decoration: none;

    color: #000000;

    @media (max-width: 1199px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

const ListItem = styled.li`
  margin-right: 95px;

  @media (max-width: 1199px) {
    margin-right: 83px;
  }

  @media (max-width: 991px) {
    margin-right: 40px;
  }

  @media (max-width: 767px) {
    margin-right: 0;
    margin-bottom: 12px;
  }

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
`;

const Description = styled.p`
  max-width: 869px;

  font-size: 14px;
  font-family: ${Fonts.NexaRegular};
  line-height: 20px;

  color: ${Colors.GrayBlue};

  @media (max-width: 1199px) {
    max-width: 377px;
    font-size: 12px;
    line-height: 16px;
  }

  @media (max-width: 767px) {
    max-width: 302px;
    text-align: center;
  }
`;

const DescriptionCol = styled(Col)`
  margin-left: auto;

  @media (max-width: 767px) {
    margin-left: 0;
  }
`

export const Styled = {
  Wrapper,
  Row,

  Logo,

  List,
  ListItem,

  Description,
  DescriptionCol,
};
