import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { getSys18Thunk } from "../thunks";

type UseGetSys18 = () => {
  getSys18: (
    limit: number,
    offset: number,
    id?: string,
    locale?: string,
    value?: string
  ) => void;
};

export const useGetSys18: UseGetSys18 = () => {
  const dispatch = useDispatch();

  const getSys18 = useCallback(
    (
      limit: number,
      offset: number,
      id?: string,
      locale?: string,
      value?: string
    ) => {
      dispatch(getSys18Thunk(limit, offset, id, locale, value));
    },
    [dispatch]
  );

  return {
    getSys18,
  };
};
