import { useEffect, useState } from "react";
import { AttachmentDTO } from "generated/models/AttachmentDTO";
import { attachmentApi } from "data-layer/attachments/api";

type UseInitFile = (
  value: number,
  fileValue: AttachmentDTO,
  setLoading: (value: boolean) => void
) => {
  file: AttachmentDTO;
  setFile: (file: AttachmentDTO) => void;
};

export const useInitFile: UseInitFile = (value, fileValue, setLoading) => {
  const [file, setFile] = useState<AttachmentDTO>({});

  useEffect(() => {
    if (fileValue?.id) {
      setFile(fileValue);
      return;
    }

    // empty data
    if (!value) {
      return false;
    }

    async function getFile() {
      try {
        setLoading(true);
        const result = await attachmentApi.api.getFile(value);

        setFile({
          ...result,
        });
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }

    getFile();
  }, []);

  return {
    file,
    setFile,
  };
};
