import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum Role {
  Merchant = "Merchant",
  Partner = "Partner",
}

export type TestRole = Role | null;

export type TestRoleInitialstate = {
  testRole: TestRole;
};

const initialState: TestRoleInitialstate = {
  testRole: null,
};

export const testRoleSlice = createSlice({
  name: "testRole",
  initialState: initialState,
  reducers: {
    setTestRole: (state, action: PayloadAction<Role>) => {
      state.testRole = action.payload;
    },
  },
});

export const testRoleActions = testRoleSlice.actions;
export const testRoleReducer = testRoleSlice.reducer;
