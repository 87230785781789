import { useCallback, useState } from "react";
import { attachmentApi } from "data-layer/attachments/api";
import { AttachmentDTO } from "generated";

type Cb = (data: AttachmentDTO[]) => void;

type UseDropzone = () => {
  onDropAttachemnt: (acceptedFiles: any[], cb: Cb) => void;
  onDrop: (acceptedFiles: any[], cb: Cb) => void;
  loading: boolean;
};

export const useDropzone: UseDropzone = () => {
  const [loading, setLoading] = useState(false);

  const uploadFilesAttachment = useCallback(
    async (files: any[], cb: Cb) => {
      try {
        setLoading(true);
        const result = await attachmentApi.api.uploadFiles({
          files: [...files],
        });

        if (cb) {
          cb(result);
        }
      } catch (e) {
        console.log("load file error", e);
      } finally {
        setLoading(false);
      }
    },
    [setLoading]
  );

  const onDropAttachemnt = useCallback((acceptedFiles, cb: Cb) => {
    uploadFilesAttachment(acceptedFiles, cb);
  }, []);

  const onDrop = useCallback((acceptedFiles, cb: Cb) => {
    uploadFiles(acceptedFiles, cb);
  }, []);

  const uploadFiles = useCallback(async (files: any[], cb: Cb) => {
    files.map(cb);
  }, []);

  return {
    onDropAttachemnt,
    onDrop,
    loading,
  };
};
