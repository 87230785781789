/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AttachmentDTO } from '../models/AttachmentDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { network } from 'services/network';

export class AttachmentsControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get attachment by ids (admin)
     * Returns attachment by ids without checking ownerness, but checks roles
     * @param ids ids
     * @returns AttachmentDTO
     * @throws ApiError
     */
    public findAsAdminListUsingGet(
        ids: number,
    ): CancelablePromise<AttachmentDTO> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/attachments/admin/list',
                query: {
                    'ids': ids,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Get attachment by id (admin)
     * Returns attachment by id without checking ownerness, but checks roles
     * @param attachmentId attachmentId
     * @returns AttachmentDTO
     * @throws ApiError
     */
    public findAsAdminUsingGet(
        attachmentId: number,
    ): CancelablePromise<AttachmentDTO> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/attachments/admin/{attachmentId}',
                path: {
                    'attachmentId': attachmentId,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * download
     * @param fileHashName fileHashName
     * @returns any OK
     * @throws ApiError
     */
    public downloadUsingGet(
        fileHashName: string,
    ): CancelablePromise<any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/attachments/download/{fileHashName}',
                path: {
                    'fileHashName': fileHashName,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Upload attachments
     * Uploads attachments to internal file storage and returns DTO with data
     * @param formData
     * @returns AttachmentDTO
     * @returns any Created
     * @throws ApiError
     */
    public uploadUsingPost(
        formData?: {
            files: Array<Blob>;
            token?: string;
        },
    ): CancelablePromise<Array<AttachmentDTO> | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/attachments/upload',
                formData: formData,
                mediaType: 'multipart/form-data',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Get attachment by id
     * Returns attachment by id
     * @param attachmentId attachmentId
     * @returns AttachmentDTO
     * @throws ApiError
     */
    public findUsingGet(
        attachmentId: number,
    ): CancelablePromise<AttachmentDTO> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/attachments/{attachmentId}',
                path: {
                    'attachmentId': attachmentId,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

}