import { usePayouts } from "data-layer/payouts";
import { PayoutsParams } from "data-layer/payouts/types/PayoutsParams";
import { useEffect } from "react";

type UseGetPayouts = (filters: PayoutsParams | null) => void;

export const useGetPayouts: UseGetPayouts = (filters) => {
  const { getPayouts } = usePayouts();

  useEffect(() => {
    if (!filters) {
      return;
    }

    getPayouts(filters);
  }, [filters]);
};
