import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { getEmployeePermissionsThunk } from "../thunks";

type UseGetEmployeePermissions = () => {
  getEmployeePermissions: () => void;
};

export const useGetEmployeePermissions: UseGetEmployeePermissions = () => {
  const dispatch = useDispatch();

  const getEmployeePermissions = useCallback(() => {
    dispatch(getEmployeePermissionsThunk());
  }, [dispatch]);

  return {
    getEmployeePermissions,
  };
};
