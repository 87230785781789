import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

const Title = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 200px;

  margin-bottom: 30px;

  font-family: ${Fonts.NexaRegular};
  font-size: 25px;
  line-height: 30px;

  color: #283549;

  @media (max-width: 1199px) {
    max-width: 150px;

    font-size: 20px;
    line-height: 25px;
  }

  @media (max-width: 575px) {
    margin-bottom: 19px;

    font-size: 18px;
    line-height: 20px;
  }
`;

const CompanyDetails = styled.div`
  display: flex;

  margin-bottom: 30px;
  padding: 15px 16px 11px;

  border-radius: 12px;

  background-color: #283549;
`;

const CompanyDetailsIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  margin-right: 20px;

  border-radius: 5px;

  background-color: #8096A7;

  svg {
    font-size: 16px;
    color: #fff;
  }
`;

const CompanyDetailsContent = styled.div`
  padding: 4px 0;

  font-family: ${Fonts.NexaRegular};
  line-height: 16px;

  @media (max-width: 1199px) {
    padding 0px 0 8px;
  }
`;

const CompanyDetailsTitle = styled.div`
  margin-bottom: 10px;

  font-size: 20px;

  color: #8096a7;

  @media (max-width: 1199px) {
    margin-bottom: 5px;

    font-size: 16px;
  }
`;

const CompanyDetailsText = styled.div`
  font-family: ${Fonts.NexaRegular};
  font-size: 16px;

  color: #ffffff;

  @media (max-width: 1199px) {
    font-size: 14px;
  }

  p {
    margin-bottom: 8px;

    @media (max-width: 1199px) {
      margin-bottom: 3px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Table = styled.div`
  margin-bottom: 52px;

  font-family: ${Fonts.NexaRegular};
  font-size: 18px;
  line-height: 16px;

  color: #4d4d4d;

  @media (max-width: 1199px) {
    font-size: 16px;
  }

  .ant-row {
    padding: 13px 0 10px;
    border-bottom: 1px solid #f0f0f0;
  }

  .ant-col {
    padding: 0 16px;

    @media (max-width: 575px) {
      padding: 0;
    }
  }
`;

const Arrow = styled.span`
  display: inline-flex;

  width: 10px;
  height: 10px;

  margin-left: 50px;
  margin-right: 10px;

  border-bottom: 2px solid ${Colors.Primary};
  border-right: 2px solid ${Colors.Primary};

  transform: rotate(-45deg);

  cursor: pointer;
`;

const AmountRow = styled.div`
  margin-bottom: 40px;
  padding-bottom: 6px;

  font-family: ${Fonts.NexaRegular};
  line-height: 16px;

  border-bottom: 2px solid #f0f0f0;

  color: #283549;
`;

const AmountTitle = styled.span`
  padding: 0 16px;
  font-size: 20px;

  @media (max-width: 575px) {
    font-size: 16px;
  }
`;

const AmountValue = styled.span`
  padding: 0 16px;
  font-size: 35px;

  @media (max-width: 1199px) {
    font-size: 30px;
  }

  @media (max-width: 575px) {
    font-size: 25px;
  }
`;

export const Styled = {
  Arrow,
  Title,
  CompanyDetails,
  CompanyDetailsIcon,
  CompanyDetailsContent,
  CompanyDetailsTitle,
  CompanyDetailsText,
  Table,
  AmountRow,
  AmountTitle,
  AmountValue,
};
