import { useEffect, useState } from "react";
import { OpenAPI } from "generated";
import { JwtReposnse, OpenAPI as OpenAPISunset } from "generatedSunset";
import { OpenAPI as OpenAPICrate } from "generatedCrate";
import { employeeSlice } from "data-layer/employee/slice";
import { useDispatch } from "react-redux";
import { useCommon } from "data-layer/common";
import { clearUser } from "utils/clearUser";
import { useEmployee, UserRole } from "data-layer/employee";

type UseCheckeEmployee = (
  authToken: string | null,
  roleAccess: UserRole
) => {
  isCacheChecked: boolean;
};

export const useCheckEmployee: UseCheckeEmployee = (authToken, roleAccess) => {
  const [isCacheChecked, setIsCacheChecked] = useState(false);
  const { syncMerchant, syncPartner } = useCommon();
  const { employee } = useEmployee();
  const dispatch = useDispatch();

  let onSyncSuccess = (employee: JwtReposnse) => {
    dispatch(employeeSlice.actions.signInSuccess(employee));
    setIsCacheChecked(true);
  };

  let onSyncFailure = () => {
    setIsCacheChecked(true);
  };

  useEffect(() => {
    if (employee?.authorized) {
      setIsCacheChecked(true);
      return;
    }

    // if opened in webview
    if (authToken) {
      setIsCacheChecked(true);
      return;
    }

    const _employee = localStorage.getItem("employee");

    if (!_employee) {
      setIsCacheChecked(true);
      return;
    }

    try {
      const parsedEmployee = JSON.parse(_employee);
      OpenAPI.TOKEN = parsedEmployee.token;
      OpenAPISunset.TOKEN = parsedEmployee.token;
      OpenAPICrate.TOKEN = parsedEmployee.token;

      if (roleAccess === UserRole.MERCHANT) {
        syncMerchant(
          () => onSyncSuccess(parsedEmployee),
          onSyncFailure,
        )
      }

      if (roleAccess === UserRole.PARTNER) {
        syncPartner(
          () => onSyncSuccess(parsedEmployee),
          onSyncFailure,
        )
      }
    } catch (e) {
      clearUser();
    }
  }, []);

  return {
    isCacheChecked,
  };
};
