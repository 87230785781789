import { AppState } from "../store";
import { createSelector } from "@reduxjs/toolkit";
import { ApplicationDTO } from "generated/models/ApplicationDTO";

export const applicationsSelector = (state: AppState) =>
  state.application.applications;

export const newApplicationSelector = createSelector(
  applicationsSelector,
  (applications) => {
    const newApplication = applications.find(
      (application) => application.state === ApplicationDTO.state.NEW
    );

    return newApplication;
  }
);

export const reviewApplicationSelector = createSelector(
  applicationsSelector,
  (applications) => {
    const newApplication = applications.find(
      (application) => application.state === ApplicationDTO.state.REVIEWING
    );

    return newApplication;
  }
);

export const rejectedApplicationSelector = createSelector(
  applicationsSelector,
  (applications) => {
    const newApplication = applications.find(
      (application) => application.state === ApplicationDTO.state.REJECTED
    );

    return newApplication;
  }
);

export const applicationPartnerCountDataSelector = (state: AppState) =>
  state.application.applicationsCount;
