import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

const Wrapper = styled.div``;

const Description = styled.p`
  margin-bottom: 25px;

  font-family: ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 147%;

  color: ${Colors.Black};

  @media (max-width: 1199px) {
    margin-bottom: 20px;

    font-size: 14px;
    line-height: 20px;
  }

  a {
    white-space: nowrap;
    color: ${Colors.Primary};
  }
`;

const Note = styled.p`
  max-width: 480px;

  margin-bottom: 45px;
  padding-top: 5px;

  font-family: ${Fonts.NexaRegular};
  font-size: 14px;
  line-height: 142%;

  color: #80868b;

  @media (max-width: 1199px) {
    margin-bottom: 40px;
    padding-top: 10px;
    font-size: 12px;
    line-height: 133%;
  }
`;

export const Styled = {
  Wrapper,
  Description,
  Note,
};
