import { useCommon } from "data-layer/common";
import { Role } from "data-layer/common/testRoleSlice";
import { createBrowserHistory } from "history";
import { useEffect, useState } from "react";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { PartnerConfig } from "./PartnerAppRouter";
import { SellerConfig } from "./SellerAppRouter";

export const history = createBrowserHistory();

let sellerUrl = 'seller.dapio.com';
let myUrl = 'my.dapio.com';
let partnerUrl = 'partner.dapio.com';

export const AppRouter = () => {
  const [routerInitiated, setRouterInitiated] = useState(false);
  const { testRole, setTestRole } = useCommon();
  
  useEffect(() => {
    const href = window.location.href;

    if (href.includes(sellerUrl) || href.includes(myUrl)) {
      setTestRole(Role.Merchant);
    }

    if (href.includes(partnerUrl) || href.includes("localhost")) {
      setTestRole(Role.Partner);
    }

    setRouterInitiated(true);
  }, [testRole]);

  if (!routerInitiated) {
    return null;
  }

  return (
    <HistoryRouter history={history}>
      {testRole === Role.Merchant && (
        <SellerConfig />
      )}
      {testRole === Role.Partner && (
        <PartnerConfig />
      )}
    </HistoryRouter>
  );
};
