import type { CancelablePromise } from "generated/core/CancelablePromise";
import type { ApplicationDTO } from "generated/models/ApplicationDTO";
// import type { ApplicationFieldInputDTO } from "generated/models/ApplicationFieldInputDTO";
// import type { ApplicationAutocompleteResultDTO } from "generated/models/ApplicationAutocompleteResultDTO";
import type { ApplicationInputDTO } from "generated/models/ApplicationInputDTO";
import { api } from "./api";
import { ApplicationCountDTO } from "generated";

export interface IApplicationApi {
  getApplications: () => CancelablePromise<Array<ApplicationDTO>>;
  // autocompleteField: (
  //   body: ApplicationFieldInputDTO
  // ) => CancelablePromise<ApplicationAutocompleteResultDTO | any>;
  finish: (
    body: ApplicationInputDTO
  ) => CancelablePromise<ApplicationDTO | any>;
  init: () => CancelablePromise<ApplicationDTO | any>;
  persist: (
    body: ApplicationInputDTO
  ) => CancelablePromise<ApplicationDTO | any>;
  getCountByPartner: () => CancelablePromise<ApplicationCountDTO>;
}

class ApplicationApi {
  api: IApplicationApi = api;

  constructor() {
    if (process.env.REACT_APP_MOCK_ENABLED === "true") {
      this.api = api;
      return;
    }

    this.api = api;
  }
}

export const applicationApi = new ApplicationApi();
