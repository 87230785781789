import { Styled } from "./styles";
import { Filters } from "./components/Filters";
import { Table } from "./components/Table/Table";
import { useInitParams } from "./hooks/useInitParams";
import { useGetMerchants } from "./hooks/useGetMerchants";

export const MerchantsSc = () => {
  const { filters } = useInitParams();
  useGetMerchants(filters);

  if (!filters) {
    return null;
  }

  return (
    <Styled.Container className="container">
      <Styled.Wrapper>
        <Styled.Sidebar>
          <Filters filters={filters} />
        </Styled.Sidebar>
        <Styled.Content>
          <Styled.Table>
            <Table filters={filters} />
          </Styled.Table>
        </Styled.Content>
      </Styled.Wrapper>
    </Styled.Container>
  );
};
