import React from "react";
import { Col, Row } from "antd";
import * as Yup from "yup";
import { SoloTraderStepProps, StepProps } from "../../OnboardingSc";
import { SoloTraderSteps } from "../../useSteps";
import { useDictionary } from "data-layer/dictionary";
import {
  AverageTxValue,
  AverageTxValueMap,
  ExpectedTurnover,
  ExpectedTurnoverMap,
  PaymentHistory,
  PaymentHistoryMap,
} from "data-layer/application/types";
import { Button } from "components";
import { useApplication } from "data-layer/application";
import { Styled } from "./cardStyles";
import { Styled as OnboardingStyled } from "../../onboardingStyles";

export const schema = Yup.object().shape({
  verify: Yup.string().required("Required"),
});

type ReviewSoloTraderStep = SoloTraderStepProps &
  StepProps & {
    changeStep: (index: number) => void;
  };

export const ReviewSoloTraderStep: React.FC<ReviewSoloTraderStep> = (props) => {
  const { initData, changeStep, prevStep } = props;
  const { countries, businessTypes } = useDictionary();
  const { finishApplication } = useApplication();

  return (
    <div>
      <OnboardingStyled.Title>
        Please review and submit your application
      </OnboardingStyled.Title>

      <OnboardingStyled.Content>
        <Styled.Wrapper>
          <Styled.Header>
            <Styled.Title>Business type</Styled.Title>
            <Styled.Controls>
              <Styled.Button
                size="small"
                type="primary-transparent"
                block
                onClick={() => changeStep(SoloTraderSteps.BusinessTypeStep)}
              >
                Edit
              </Styled.Button>
            </Styled.Controls>
          </Styled.Header>

          <Styled.Content>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Type:</Col>
                <Col span={12}>{initData.typeOfBusiness || "-"}</Col>
              </Row>
            </Styled.ContentItem>
          </Styled.Content>
        </Styled.Wrapper>

        <Styled.Wrapper>
          <Styled.Header>
            <Styled.Title>Business description</Styled.Title>
            <Styled.Controls>
              <Styled.Button
                size="small"
                type="primary-transparent"
                block
                onClick={() => changeStep(SoloTraderSteps.DescriptionStep)}
              >
                Edit
              </Styled.Button>
            </Styled.Controls>
          </Styled.Header>

          <Styled.Content>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Full Legal Name:</Col>
                <Col span={12}>{initData.COMPANY_NAME || "-"}</Col>
              </Row>
            </Styled.ContentItem>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>VAT number:</Col>
                <Col span={12}>{initData.VAT_NUMBER || "-"}</Col>
              </Row>
            </Styled.ContentItem>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Business status:</Col>
                <Col span={12}>{initData.COMPANY_STATUS || "-"}</Col>
              </Row>
            </Styled.ContentItem>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Trading as</Col>
                <Col span={12}>{initData.DBA || "-"}</Col>
              </Row>
            </Styled.ContentItem>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Phone number:</Col>
                <Col span={12}>{initData.PHONE_NUMBER || "-"}</Col>
              </Row>
            </Styled.ContentItem>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Email address:</Col>
                <Col span={12}>{initData.EMAIL || "-"}</Col>
              </Row>
            </Styled.ContentItem>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Contact person First Name:</Col>
                <Col span={12}>{initData.PRIMARY_CONTACT_FIRST_NAME || "-"}</Col>
              </Row>
            </Styled.ContentItem>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Contact person Last Name:</Col>
                <Col span={12}>{initData.PRIMARY_CONTACT_LAST_NAME || "-"}</Col>
              </Row>
            </Styled.ContentItem>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Contact person phone number:</Col>
                <Col span={12}>{initData.PRIMARY_CONTACT_PHONE_NUMBER || "-"}</Col>
              </Row>
            </Styled.ContentItem>

            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Country:</Col>
                <Col span={12}>
                  {countries?.find(
                    (country) => country.id === initData.COUNTRY
                  )?.name || "-"}
                </Col>
              </Row>
            </Styled.ContentItem>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Registered address:</Col>
                <Col span={12}>
                  {initData.ADDRESS_LINE_1 || "-"}
                  {initData.ADDRESS_LINE_2 ? `, ${initData.ADDRESS_LINE_2}` : ''}
                </Col>
              </Row>
            </Styled.ContentItem>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Town/City:</Col>
                <Col span={12}>{initData.CITY || "-"}</Col>
              </Row>
            </Styled.ContentItem>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Postcode:</Col>
                <Col span={12}>{initData.POSTAL_CODE || "-"}</Col>
              </Row>
            </Styled.ContentItem>

            <Styled.ContentItem>
              <Styled.ContentItemGroupTitle>Trading address</Styled.ContentItemGroupTitle>
            </Styled.ContentItem>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Country:</Col>
                <Col span={12}>
                  {countries?.find(
                    (country) => country.id === initData.OFFICE_COUNTRY
                  )?.name || "-"}
                </Col>
              </Row>
            </Styled.ContentItem>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Registered address:</Col>
                <Col span={12}>
                  {initData.OFFICE_ADDRESS_LINE_1 || "-"}
                  {initData.OFFICE_ADDRESS_LINE_2 ? `, ${initData.OFFICE_ADDRESS_LINE_2}` : ''}
                </Col>
              </Row>
            </Styled.ContentItem>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Town/City:</Col>
                <Col span={12}>{initData.OFFICE_CITY || "-"}</Col>
              </Row>
            </Styled.ContentItem>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Postcode:</Col>
                <Col span={12}>{initData.OFFICE_POSTAL_CODE || "-"}</Col>
              </Row>
            </Styled.ContentItem>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>What category best describes what you'll use Dapio for?:</Col>
                <Col span={12}>
                  {businessTypes?.find(
                    (bt) => bt.id === initData.TYPE_OF_BUSINESS
                  )?.text || "-"}
                </Col>
              </Row>
            </Styled.ContentItem>
          </Styled.Content>
        </Styled.Wrapper>

        <Styled.Wrapper>
          <Styled.Header>
            <Styled.Title>Business activities</Styled.Title>
            <Styled.Controls>
              <Styled.Button
                size="small"
                type="primary-transparent"
                block
                onClick={() => changeStep(SoloTraderSteps.BusinessActivitesStep)}
              >
                Edit
              </Styled.Button>
            </Styled.Controls>
          </Styled.Header>

          <Styled.Content>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>What’s your expected annual turnover?:</Col>
                <Col span={12}>
                  {ExpectedTurnoverMap[
                    initData.EXPECTED_TURNOVER as ExpectedTurnover
                  ] || "-"}
                </Col>
              </Row>
            </Styled.ContentItem>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>How long have you been processing card payments?:</Col>
                <Col span={12}>
                  {PaymentHistoryMap[
                    initData.PAYMENT_HISTORY as PaymentHistory
                  ] || "-"}
                </Col>
              </Row>
            </Styled.ContentItem>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>How much do you estimate an average card payment with Dapio to be?</Col>
                <Col span={12}>
                  {AverageTxValueMap[
                    initData.AVERAGE_TX_VALUE as AverageTxValue
                  ] || "-"}
                </Col>
              </Row>
            </Styled.ContentItem>
          </Styled.Content>
        </Styled.Wrapper>

        <Styled.Wrapper>
          <Styled.Header>
            <Styled.Title>Business owner</Styled.Title>
            <Styled.Controls>
              <Styled.Button
                size="small"
                type="primary-transparent"
                block
                onClick={() => changeStep(SoloTraderSteps.PersonalDetailsStep)}
              >
                Edit
              </Styled.Button>
            </Styled.Controls>
          </Styled.Header>

          <Styled.Content>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Name:</Col>
                <Col span={12}>{initData.PERSON_FIRST_NAME} {initData.PERSON_LAST_NAME}</Col>
              </Row>
            </Styled.ContentItem>
            <Styled.ContentItem>
              <Row wrap={false} justify="space-between">
                <Col span={12}>Position held in company:</Col>
                <Col span={12}>Director</Col>
              </Row>
            </Styled.ContentItem>
          </Styled.Content>
        </Styled.Wrapper>

        {/* <Styled.ViewBlock>
          <Styled.ViewBlockTitle>Settlements details</Styled.ViewBlockTitle>
          <Styled.ViewBlockContent>
            <Row justify="space-between" wrap={false}>
              <Col>
                <Row gutter={[0, { xs: 20, sm: 31, md: 12 }]}>
                  <Col span={24}>
                    <p>Account holder name:</p>
                    <p>{initData.ACCOUNT_HOLDER_NAME || "-"}</p>
                  </Col>
                  <Col span={24}>
                    <p>Sort code:</p>
                    <p>{initData.SORT_CODE || "-"}</p>
                  </Col>
                  <Col span={24}>
                    <p>Account number:</p>
                    <p>{initData.ACCOUNT_NUMBER || "-"}</p>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Styled.Control
                  onClick={() =>
                    changeStep(SoloTraderSteps.SettlementDetailsStep)
                  }
                >
                  <EditOutlined />
                </Styled.Control>
              </Col>
            </Row>
          </Styled.ViewBlockContent>
        </Styled.ViewBlock> */}
      </OnboardingStyled.Content>

      <OnboardingStyled.Footer>
        <Row gutter={{ xs: 10, sm: 10, md: 25 }}>
          <Col>
            <Button type="secondary-transparent" onClick={() => prevStep()}>
              Back
            </Button>
          </Col>
          <Col flex={1}>
            <Button
              type="primary-transparent"
              onClick={finishApplication}
              block
            >
              Accept and complete
            </Button>
          </Col>
        </Row>
      </OnboardingStyled.Footer>
    </div>
  );
};
