import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SysI18NDTO } from "generated";

export type Sys18 = SysI18NDTO[] | [];

export type Sys18InitialState = {
  data: Sys18;
  isFetching: boolean;
};

const initialState: Sys18InitialState = {
  data: [],
  isFetching: false,
};

export const sys18Slice = createSlice({
  name: "sys18",
  initialState: initialState,
  reducers: {
    getSys18Request: (state) => {
      state.isFetching = true;
    },
    getSys18Failure: (state) => {
      state.isFetching = false;
    },
    getSys18Success: (state, action: PayloadAction<Array<SysI18NDTO>>) => {
      state.data = action.payload;
      state.isFetching = false;
    },
  },
});

export const sys18Actions = sys18Slice.actions;
