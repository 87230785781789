/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { AuthControllerService } from './services/AuthControllerService';
import { BasicErrorControllerService } from './services/BasicErrorControllerService';
import { OperationHandlerService } from './services/OperationHandlerService';
import { WebMvcLinksHandlerService } from './services/WebMvcLinksHandlerService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class AppClient {

    public readonly authController: AuthControllerService;
    public readonly basicErrorController: BasicErrorControllerService;
    public readonly operationHandler: OperationHandlerService;
    public readonly webMvcLinksHandler: WebMvcLinksHandlerService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? 'https://dev.backend.dapio.com:443',
            VERSION: config?.VERSION ?? '1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.authController = new AuthControllerService(this.request);
        this.basicErrorController = new BasicErrorControllerService(this.request);
        this.operationHandler = new OperationHandlerService(this.request);
        this.webMvcLinksHandler = new WebMvcLinksHandlerService(this.request);
    }
}
