import styled from "styled-components";
import { Fonts } from "styles/fonts";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-top: 1px solid #1a2233;
  border-bottom: 1px solid #1a2233;

  @media (max-width: 1919px) {
    width: calc(100% + 78px);
    margin-left: -78px;
    padding-left: 78px;
  }

  @media (max-width: 991px) {
    width: 100%;
    margin-left: 0;
    padding-left: 20px;
  }

  @media (max-width: 575px) {
    padding-left: 15px !important;
  }
`;

const Input = styled.input`
  flex-grow: 1;

  height: 50px;
  padding-top: 5px;

  font-family: ${Fonts.NexaRegular};
  font-size: 20px;
  line-height: 100%;

  color: #ffffff;

  background-color: transparent;
  box-shadow: none;
  border: none;
  outline: none;

  ::placeholder {
    font-family: ${Fonts.NexaRegular};
    font-size: 20px;
    line-height: 100%;

    color: #ffffff;
  }
`;

const Button = styled.button`
  flex-shrink: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 58px;
  height: 50px;
  margin-bottom: 0px;
  margin-left: auto;

  font-size: 30px;

  border: none;

  background-color: #0000001a;
  color: #76899b;

  cursor: pointer;
`;

export const Styled = {
  Wrapper,
  Input,
  Button,
};
