import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { syncPartnerThunk } from "../thunks";

type UseSyncPartner = () => {
  syncPartner: (onSuccess?: VoidFunction, onFailure?: VoidFunction) => void;
};

export const useSyncPartner: UseSyncPartner = () => {
  const dispatch = useDispatch();

  const syncPartner = useCallback(
    (onSuccess?: VoidFunction, onFailure?: VoidFunction): void => {
      dispatch(syncPartnerThunk(onSuccess, onFailure));
    },
    [dispatch]
  );

  return {
    syncPartner,
  };
};
