import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";
import { StyledCss } from "../../styles";

type ActiveProp = {
  active?: boolean;
};

const Title = styled.p<ActiveProp>`
  ${StyledCss.Title}

  @media (max-width: 767px) {
    margin-bottom: ${(props) => (props.active ? "21px" : "0")};

    font-size: 25px;
    line-height: 1em;

    cursor: pointer;
  }

  &::after {
    @media (max-width: 767px) {
      content: "";

      position: absolute;

      top: ${(props) => (props.active ? "7px" : "3px")};
      right: 5px;

      width: 12px;
      height: 12px;

      border-right: 3px solid #3c3c434d;
      border-bottom: 3px solid #3c3c434d;

      transform: ${(props) => props.active ? "rotate(-135deg)" : "rotate(45deg)"};
    }
  }
`;

const Content = styled.div<ActiveProp>`
  @media (max-width: 767px) {
    display: ${(props) => (props.active ? "block" : "none")};
  }
`;

const Description = styled.p`
  ${StyledCss.Description}

  margin-bottom: 42px;

  @media (max-width: 767px) {
    max-width: 145px;
    margin-bottom: 33px;

    font-size: 16px;
    line-height: 23px;
  }
`;

const PromoTitle = styled.p`
  margin-bottom: 10px;

  font-family ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 105%;

  color: ${Colors.GrayBlue};
`;

const Promo = styled.div`
  position: relative;

  display: flex;
  align-items: center;

  padding: 12px 15px 6px;

  font-family: ${Fonts.NexaRegular};
  font-size: 20px;

  border: 1px solid #00000014;
  border-radius: 8px;

  background: #ffffff;
  color: ${Colors.Primary};

  box-shadow: 0px 0.5px 0px #0000000F;

  svg {
    position: absolute;

    top: 14px;
    right: 18px;

    color: #80868b;

    cursor: pointer;

    &:hover {
      color: ${Colors.Primary};
    }
  }
`;

export const Styled = {
  Title,
  Description,
  PromoTitle,
  Promo,
  Content,
};
