import { Button as AntdButton, ButtonProps } from "antd";
import { Styled } from "./styles";

export interface Props extends Omit<ButtonProps, "type"> {
  type?:
    | ButtonProps["type"]
    | 'primary-transparent'
    | 'primary-dots'

    | "secondary"
    | "secondary-dots"
    | "secondary-transparent"

    | "gray-transparent"
}

export const Button: React.FC<Props> = (props) => {
  const { type, ...restProps } = props;

  // TODO: refactoring
  if (type === "primary") {
    return <Styled.PrimaryButton {...restProps} />;
  }
  if (type === "primary-transparent") {
    return <Styled.PrimaryTransparentButton {...restProps} />;
  }
  if (type === "primary-dots") {
    return <Styled.PrimaryDotsButton {...restProps} />;
  }

  if (type === "secondary") {
    return <Styled.SecondaryButton {...restProps} />;
  }
  if (type === "secondary-dots") {
    return <Styled.SecondaryDotsButton {...restProps} />;
  }
  if (type === "secondary-transparent") {
    return <Styled.SecondaryTransparentButton {...restProps} />;
  }

  if (type === "gray-transparent") {
    return <Styled.GrayTransparentButton {...restProps} />;
  }

  return (
    <AntdButton type={type} {...restProps}>
      {props.children}
    </AntdButton>
  );
};
