import { Col, Row, Steps } from "antd";
import React, { useRef } from "react";
import { Styled } from "./onboardingStyles";
import { useSteps } from "./useSteps";
import { BusinessTypeStep } from "./components/BusinessTypeStep";
import { useInitData } from "./useInitData";
import {
  ApplicationValues,
  ApplicationCompanyValues,
  ApplicationSoloTraderValues,
} from "data-layer/application/types";
import { useProfile } from "data-layer/profile";
import { ProfileDTO } from "generated";
import { OnboardingCompanySteps } from "./OnboardingCompanySteps";
import { OnboardingSoloTraderSteps } from "./OnboardingSoloTraderSteps";
import "./styles.less";
import { Header } from "./components/Header";
import { useMediaQuery } from "react-responsive";

const { Step } = Steps;

export type StepComponentProps = {
  initData: ApplicationValues;
};

export type CompanyStepProps = {
  initData: ApplicationCompanyValues;
};

export type SoloTraderStepProps = {
  initData: ApplicationSoloTraderValues;
};

export type StepProps = {
  prevStep: VoidFunction;
  nextStep: VoidFunction;
};

export type PrevStepProp = {
  prevStep: VoidFunction;
};

export type NextStepProp = {
  nextStep: VoidFunction;
};

const defaultStepTitles: { [key: number]: string } = {
  0: "Business type",
  1: "Business description",
  2: "Business activities",
  3: "Identity verification",
  4: "Review and submit",
};

const CompanyStepTitles: { [key: number]: string } = {
  0: "Business type",
  1: "Company description",
  2: "Business activities",
  3: "Business owners",
  4: "Identity verification",
  5: "Review and submit",
};

const SoloTraderStepTitles: { [key: number]: string } = {
  0: "Business type",
  1: "Business description",
  2: "Business activities",
  3: "Personal details",
  4: "Identity verification",
  5: "Review and submit",
};

export const OnboardingSc: React.FC = () => {
  const { selectedProfile } = useProfile();

  const isNewProfile = false;

  const contentRef = useRef(null);

  const { initData, initialized } = useInitData(isNewProfile);
  const { current, prevStep, nextStep, changeStep } = useSteps(
    initData,
    initialized,
    isNewProfile,
    contentRef
  );
  const is991 = useMediaQuery({ query: "(max-width: 991px)" });

  return (
    <>
      <Styled.MobileSteps>
        <div className="container">
          {!isNewProfile &&
            selectedProfile?.kind === ProfileDTO.kind.COMPANY && (
              <Row justify="space-between" align="middle">
                <Col>
                  <div>{CompanyStepTitles[current]}</div>
                </Col>
                <Col>{current + 1} / 6</Col>
              </Row>
            )}

          {!isNewProfile &&
            selectedProfile?.kind === ProfileDTO.kind.SOLE_TRADER && (
              <Row justify="space-between" align="middle">
                <Col>
                  <div>{SoloTraderStepTitles[current]}</div>
                </Col>
                <Col>{current + 1} / 6</Col>
              </Row>
            )}

          {(!selectedProfile || isNewProfile) && (
            <Row justify="space-between" align="middle">
              <Col>
                <div>{defaultStepTitles[current]}</div>
              </Col>
              <Col>{current + 1} / 5</Col>
            </Row>
          )}
        </div>
      </Styled.MobileSteps>

      <div className="container">
        <Header />

        <Styled.StepsRow>
          <Styled.StepsCol>
            <Steps
              current={current}
              direction={is991 ? "horizontal" : "vertical"}
              className="onboarding-steps"
              responsive={false}
              onChange={(_current) => {
                if (_current < current) {
                  changeStep(_current);
                  return;
                }
              }}
            >
              <Step title={"Business type"} />

              {!isNewProfile &&
                selectedProfile?.kind === ProfileDTO.kind.COMPANY && (
                  <>
                    <Step title={CompanyStepTitles[1]} />
                    <Step title={CompanyStepTitles[2]} />
                    <Step title={CompanyStepTitles[3]} />
                    <Step title={CompanyStepTitles[4]} />
                  </>
                )}

              {!isNewProfile &&
                selectedProfile?.kind === ProfileDTO.kind.SOLE_TRADER && (
                  <>
                    <Step title={SoloTraderStepTitles[1]} />
                    <Step title={SoloTraderStepTitles[2]} />
                    <Step title={SoloTraderStepTitles[3]} />
                    <Step title={SoloTraderStepTitles[4]} />
                  </>
                )}

              {(!selectedProfile || isNewProfile) && (
                <>
                  <Step title={defaultStepTitles[1]} />
                  <Step title={defaultStepTitles[2]} />
                  <Step title={defaultStepTitles[3]} />
                </>
              )}

              <Step title={"Review and submit"} />
            </Steps>
          </Styled.StepsCol>

          <Styled.ContentCol ref={contentRef}>
            <Styled.ContentColInner>
              {initialized && (
                <>
                  {current === 0 && (
                    <BusinessTypeStep initData={initData} nextStep={nextStep} />
                  )}

                  {selectedProfile?.kind === ProfileDTO.kind.COMPANY && (
                    <OnboardingCompanySteps.Content
                      current={current}
                      initData={initData}
                      prevStep={prevStep}
                      nextStep={nextStep}
                      changeStep={changeStep}
                    />
                  )}

                  {selectedProfile?.kind === ProfileDTO.kind.SOLE_TRADER && (
                    <OnboardingSoloTraderSteps.Content
                      current={current}
                      initData={initData}
                      prevStep={prevStep}
                      nextStep={nextStep}
                      changeStep={changeStep}
                    />
                  )}
                </>
              )}
            </Styled.ContentColInner>
          </Styled.ContentCol>
        </Styled.StepsRow>
      </div>
    </>
  );
};
