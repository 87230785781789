import { useSelector } from "react-redux";
import { UseNotifications } from "..";
import { haveUnreadNotificationSelector, notificationsDataSelector, pendingToReadSelector } from "../selectors";
import { useGetNotifications } from "./useGetNotificatoins";
import { useMarkAllAsRead } from "./useMarkAllAsRead";
import { useMarkAsRead } from "./useMarkAsRead";
import { useShowNotification } from "./useShowNotification";

export const useNotifications = (): UseNotifications => {
  const { getNotifications } = useGetNotifications();
  const { showNotification } = useShowNotification();
  const { markAsRead } = useMarkAsRead();
  const { markAllAsRead } = useMarkAllAsRead();
  const notifications = useSelector(notificationsDataSelector);
  const pendingToRead = useSelector(pendingToReadSelector);
  const haveUnreadNotification = useSelector(haveUnreadNotificationSelector)

  return {
    notifications,
    pendingToRead,
    getNotifications,
    showNotification,
    markAsRead,
    markAllAsRead,
    haveUnreadNotification,
  };
};
