import { OwnerTypeEnum } from "data-layer/application/types";
import { validatePhone } from "components/PhoneInput/_index";
import { AppInputs } from "generated/modelsWrapper/AppInputs";
import { validateEmail } from "utils/validateEmail";
import * as Yup from "yup";

const addressPattern = /^[-A-Za-z0-9.<(+&*),%_?:'=| ]+$/;
const cityPattern = /^[-A-Za-z0-9_.():&/*<=‘?%,+ ]+$/;
const zipPattern = /^[-A-Za-z0-9_.*():&/<=’?%,+ ]+$/;

export const ownerShemaGeneral = Yup.object().shape({
  [AppInputs.PERSON_FIRST_NAME]: Yup.string().required("Required"),
  [AppInputs.PERSON_LAST_NAME]: Yup.string().required("Required"),
  [AppInputs.PERSON_DOB]: Yup.string().required("Required"),
  [AppInputs.PERSON_STATUS]: Yup.array().of(Yup.string()).min(1),

  [AppInputs.PERSON_PHONE_NUMBER]: Yup.string()
    .required("Required")
    .test("check format", "Invalid phone number", (value?: string) => {
      return validatePhone(value);
    }),
  [AppInputs.PERSON_EMAIL]: Yup.string()
    .required("Required")
    .test("email", "Invalid email", (value?: string) => {
      return validateEmail(value);
    }),

  [AppInputs.PERSON_ADDRESS_LINE_1]: Yup.string()
    .required("Required")
    .max(50, "Max 50 characters")
    .matches(addressPattern, "Incorrect value"),
  [AppInputs.PERSON_ADDRESS_LINE_2]: Yup.string()
    .max(50, "Max 50 characters")
    .matches(addressPattern, "Incorrect value"),
  [AppInputs.PERSON_CITY]: Yup.string()
    .required("Required")
    .max(50, "Max 50 characters")
    .matches(cityPattern, "Incorrect value"),
  [AppInputs.PERSON_POSTAL_CODE]: Yup.string()
    .required("Required")
    .max(20, "Max 20 characters")
    .matches(zipPattern, "Incorrect value"),

  [AppInputs.PERSON_NATIONALITY]: Yup.string().required("Required"),

  [AppInputs.PERSON_OWNERSHIP]: Yup.number()
    .required("Required")
    .positive()
    .moreThan(0, "must be greater than 0")
    .max(100, "cannot be greater 100"),

  [AppInputs.PERSON_AUTHORIZED]: Yup.string().required("Required"),
});

const officcerSchema = Yup.object().shape({
  [AppInputs.PERSON_POSITION]: Yup.string().when(AppInputs.PERSON_STATUS, {
    is: (values: OwnerTypeEnum[]) => {
      return values.includes(OwnerTypeEnum.Officer);
    },
    then: Yup.string().required("Required"),
  }),
});

const ultimateOfficcerSchema = Yup.object().shape({});

export const businessOwnersSchema = Yup.object().shape({
  [AppInputs.PERSONS]: Yup.array()
    .of(
      Yup.object()
        .shape({})
        .concat(ownerShemaGeneral)
        .concat(officcerSchema)
        .concat(ultimateOfficcerSchema)
    )
    .test({
      message: "",
      test: (arr) => arr.length > 0,
    }),
});
