import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

const CreateLinkButton = styled.div`
  position: relative;
  z-index: 1;

  display: flex;
  align-items: center;

  height: 50px;
  margin-bottom: 48px;
  padding-left: 10px;

  font-family: ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 22px;

  background-color: ${Colors.Primary};
  color: #ffffff;

  @media (max-width: 1199px) {
    height: 40px;
    font-size: 15px;
  }

  @media (max-width: 767px) {
    margin-bottom: 15px;
  }

  cursor: pointer;

  &::before {
    content: "";

    position: absolute;
    z-index: -1;

    top: 0;
    left: -2000px;
    right: 0;

    height: 100%;

    background-color: ${Colors.Primary};

    @media (max-width: 767px) {
      display: none;
    }
  }

  svg {
    margin-right: 8px;
    font-size: 20px;

    @media (max-width: 1199px) {
      margin-right: 12px;
      font-size: 16px;
    }
  }

  & > span:last-child {
    padding-top: 7px;
  }
`;

export const Styled = {
  CreateLinkButton,
};
