import { useEffect, useMemo, useState } from "react";
import { useTransactions } from "../../../../data-layer/transaction";
import moment from "moment";
import { TransactionsSeller } from "../../../../data-layer/transaction/slice";
import {useMerchant} from "../../../../data-layer/merchant";

export type Provider = "all" | "ttp" | "pbl";

type UseGetTransactions = () => {
  revenueDateRange: number;
  handleChangeRevenueDateRange: (value: number) => void;
  handleChangeProvider: (value: Provider) => void;
  averageTransactionValue: string;
  totalSalesValue: string;
  dataAmount: TransactionsSeller;
};

export const useGetTransactions: UseGetTransactions = () => {
  const {
    getTransactionsAmount,
    getTransactionsAverage,
    dataAmount,
    dataAverage,
  } = useTransactions();

  const [revenueDateRange, setRevenueDateRange] = useState<number>(7);
  const [provider, setProvider] = useState<Provider>("all");

  const { me } = useMerchant();

  useEffect(() => {
    getTransactionsAverage({
      merchantIds: me?.id,
      from: moment().subtract(revenueDateRange, "days").toISOString(),
      to: moment().toISOString(),
      aggregateBy: "",
      aggregateTime: "YEAR",
    });
    getTransactionsAmount({
      merchantIds: me?.id,
      from: moment().subtract(revenueDateRange, "days").toISOString(),
      to: moment().toISOString(),
      aggregateBy: "",
      aggregateTime: "YEAR",
    });
  }, [revenueDateRange]);

  const getTransactionTotalSum = (data: TransactionsSeller, type: Provider) => {
    return data
      .filter((item) => {
        if (type === "all") return true;
        else return item.provider === type;
      })
      .reduce((partialSum, a) => partialSum + a.value!, 0);
  };

  const averageTransactionResult = useMemo(
    () => getTransactionTotalSum(dataAverage, provider),
    [dataAverage, provider]
  );

  const totalSalesResult = useMemo(
    () => getTransactionTotalSum(dataAmount, provider),
    [dataAmount, provider]
  );

  const averageTransactionValue = !!averageTransactionResult
    ? averageTransactionResult.toFixed(2)
    : `${0}`;

  const totalSalesValue = !!totalSalesResult
    ? totalSalesResult.toFixed(2)
    : `${0}`;

  const dataAmountFilteredByProvider = useMemo(
    () =>
      dataAmount.filter((item) => {
        if (provider === "all") return true;
        else return item.provider === provider;
      }),
    [provider, dataAmount]
  );

  const handleChangeRevenueDateRange = (value: number) => {
    setRevenueDateRange(value);
  };

  const handleChangeProvider = (value: Provider) => {
    setProvider(value);
  };

  return {
    revenueDateRange,
    handleChangeRevenueDateRange,
    handleChangeProvider,
    averageTransactionValue,
    totalSalesValue,
    dataAmount: dataAmountFilteredByProvider,
  };
};
