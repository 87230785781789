import { Col, Row } from "antd";
import { Button, FormItem } from "components";
import { Styled } from "./styles";
import { Input } from "formik-antd";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { usePayments } from "data-layer/payments";
import { useEffect, useRef, useState } from "react";
import { isNumeric } from "utils/isNumeric";
import { PaymentLinkDTO } from "generated";

type SchemaForIndividual = {
  amount: number;
  name: string;
  surname: string;
  description?: string;
};

const schemaForIndividual = Yup.object().shape({
  amount: Yup.string()
    .required("Required")
    .test("is-number", "Invalid number", (value) => isNumeric(value)),
  name: Yup.string().required("Required"),
  surname: Yup.string().required("Required"),
  description: Yup.string(),
});

const initialDataForIndividual = {
  amount: 100.0,
  name: "",
  surname: "",
};

type CreateIndividualLinkProps = {
  onCreate: (data: PaymentLinkDTO) => void;
};

export const CreateIndividualLink: React.FC<CreateIndividualLinkProps> = (
  props
) => {
  const { onCreate } = props;

  const { createPaymentLink } = usePayments();

  const amountEl = useRef();
  const [refVisible, setRefVisible] = useState(false);

  const [amountWidth, setAmountWidth] = useState(0);

  useEffect(() => {
    if (!refVisible) {
      return;
    }

    setAmountWidth(amountEl.current?.offsetWidth);
  }, [refVisible]);

  return (
    <Formik
      initialValues={initialDataForIndividual}
      validationSchema={schemaForIndividual}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
      onSubmit={(values: SchemaForIndividual) => {
        createPaymentLink(
          {
            amount: values.amount,
            customerName: `${values.name} ${values.surname}`,
          },
          onCreate
        );
      }}
    >
      {({ values, errors, submitForm, setFieldValue, handleSubmit }) => {
        return (
          <Form
            onSubmit={handleSubmit}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
          >
            <Styled.Header>
              <Styled.Row justify="space-between" wrap={false}>
                <Col>
                  <Styled.RowTitle>Amount:</Styled.RowTitle>
                </Col>
                <Col>
                  <Styled.RowValue>
                    <Styled.Currency>£</Styled.Currency>
                    <input
                      name="amount"
                      value={values.amount}
                      onChange={(e) => {
                        setFieldValue(
                          "amount",
                          e.target.value.replace(",", ".")
                        );
                        setTimeout(() => {
                          setAmountWidth(amountEl.current?.offsetWidth);
                        }, 50);
                      }}
                      style={{
                        width: `calc(${amountWidth}px + 20px)`,
                        transition: "none",
                      }}
                    />

                    <Styled.HiddenAmount
                      ref={(el) => {
                        amountEl.current = el;
                        setRefVisible(!!el);
                      }}
                    >
                      {values.amount}
                    </Styled.HiddenAmount>
                  </Styled.RowValue>
                </Col>
              </Styled.Row>

              {errors.amount && (
                <p
                  style={{
                    marginTop: 10,
                    paddingLeft: 30,
                    fontFamily: "Nexa Regular",
                    fontSize: 14,
                    lineHeight: "18px",
                    color: "#ea4236",
                  }}
                >
                  {errors.amount}
                </p>
              )}
            </Styled.Header>

            <Row gutter={25}>
              <Col xs={24} sm={12}>
                <FormItem label="Name" error={errors.name}>
                  <Input name="name" />
                </FormItem>
              </Col>
              <Col xs={24} sm={12}>
                <FormItem label="Surname" error={errors.surname}>
                  <Input name="surname" />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem label="Description">
                  <Input.TextArea name="description" rows={3} />
                </FormItem>
              </Col>
            </Row>

            <Styled.Button>
              <Button type="primary" block onClick={submitForm}>
                Create payment link
              </Button>
            </Styled.Button>
          </Form>
        );
      }}
    </Formik>
  );
};
