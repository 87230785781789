/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MerchantDTO } from './MerchantDTO';

export type TransactionRestDTO = {
    amount?: number;
    authorizationCode?: string;
    currency?: string;
    dateTime?: string;
    employeeName?: string;
    tid?: string;
    externalId?: string;
    geoLatitude?: string;
    geoLongitude?: string;
    id?: number;
    merchant?: MerchantDTO;
    rrn?: string;
    type?: TransactionRestDTO.type;
};

export namespace TransactionRestDTO {

    export enum type {
        CANCEL = 'CANCEL',
        CHARGEBACK = 'CHARGEBACK',
        MANUAL = 'MANUAL',
        PARTIAL_REFUND = 'PARTIAL_REFUND',
        PAYMENT = 'PAYMENT',
        REFUND = 'REFUND',
    }


}
