import { appClient } from "services";
import { ICompanyApi } from "./index";

export const api: ICompanyApi = {
  getCompany: (body) => {
    return appClient.companyController.profileUsingPost(body);
  },

  search: (name) => {
    return appClient.companyController.searchUsingGet(
      name,
    );
  },
};
