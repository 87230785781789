import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { markAllAsReadThunk } from "../thunks";

type UseMarkAllAsRead = () => {
  markAllAsRead: (ids?: number[]) => void;
};

export const useMarkAllAsRead: UseMarkAllAsRead = () => {
  const dispatch = useDispatch();

  const markAllAsRead = useCallback(
    (ids) => {
      dispatch(markAllAsReadThunk(ids));
    },
    [dispatch]
  );

  return {
    markAllAsRead,
  };
};
