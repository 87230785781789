import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

const Control = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 50px;
  height: 50px;

  font-size: 25px;

  border: 1px solid ${Colors.LightGray};
  border-radius: 50%;

  cursor: pointer;

  &:hover {
    background-color: ${Colors.Secondary};
  }

  svg {
    font-size: 24px;

    @media (max-width: 1450px) {
      font-size: 18px;
    }
  }

  @media (max-width: 1450px) {
    width: 30px;
    height: 30px;
  }
`;

const StatusActive = styled.div`
  position: absolute;

  top: 9px;
  right: 19px;

  width: 10px;
  height: 10px;

  border-radius: 50%;

  background-color: #ff3b30;

  @media (max-width: 1450px) {
    top: 7px;
    right: 12px;
    width: 7px;
    height: 7px;
  }
`;

const NotificationsDesktop = styled.div`
  display: block;

  @media (max-width: 575px) {
    display: none;
  }
`

const MobileNotifications = styled.div`
  display: none;

  @media (max-width: 575px) {
    display: block;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;

  width: 422px;
  height: 428px;

  border-radius: 15px;

  background-color: #fff;

  box-shadow: 0px 3px 6px #00000029;

  overflow: hidden;

  @media(max-width: 575px) {
    position: fixed;

    z-index: 5;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    padding: 78px 0;

    background: #FFFFFF;
    opacity: 1;
    backdrop-filter: blur(30px);
  }
`;

const ContentClose = styled.div`
  position: absolute;

  top: 15px;
  right: 36px;

  display: none;

  width: 28px;
  height: 28px;

  border-radius: 50%;
  background-color: #e5e5e5;

  cursor: pointer;

  @media (max-width: 575px) {
    display: block;
  }

  &::before,
  &::after {
    content: '';

    position: absolute;

    top: 50%;
    left: 50%;

    width: 12px;
    height: 2px;

    background-color: #a7a7a7;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
`

const ContentTitle = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;

  height: 60px;
  padding: 0 20px;
  padding-top: 10px;

  font-family: ${Fonts.NexaRegular};
  font-size: 20px;
  line-height: 20px;

  border-bottom: 1px solid #0000001a;

  color: #283549;

  @media (max-width: 575px) {
    padding: 0 36px;
    font-family: ${Fonts.NexaRegular};
    font-size: 25px;
    line-height: 36px;
  }
`;

const ContentScroll = styled.div`
  flex-grow: 1;
  overflow: auto;
`

const ReadButton = styled.button`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 56px;

  margin-top: auto;

  font-family: ${Fonts.NexaRegular};
  font-size: 20px;
  line-height: 29px;

  border: none;
  border-top: 1px solid #0000001a;

  background: transparent;
  color: #0000004D;

  cursor: pointer;

  &:hover {
    color: #283549;
  }
`;

export const Styled = {
  Control,
  StatusActive,

  NotificationsDesktop,
  MobileNotifications,

  ContentClose,
  Content,
  ContentTitle,
  ContentScroll,

  ReadButton,
};
