import { SignupRequestDTO, LoginRequest } from "generatedSunset";
import { AppDispatch, AppState } from "data-layer/store/store";
import { authApi } from "data-layer/employee/api";
import { employeeSlice } from "data-layer/employee/slice";
import { history } from "data-layer/../router/AppRouter";
import { loaderSlice } from "data-layer/common/loaderSlice";
import { OpenAPI } from "generated";
import { OpenAPI as OpenAPISunset } from "generatedSunset";
import { OpenAPI as OpenAPICrate } from "generatedCrate";
import { showSuccess } from "utils/showSuccess";
import { handleNetworkError } from "utils/handleNetworkError";
import { testRoleSelector } from "data-layer/common/selectors";
import { UserRole } from "./types";
import { Role } from "data-layer/common/testRoleSlice";
import { showError } from "utils/showError";

export const signIn = (data: LoginRequest, historyState?: unknown) => {
  return async (dispatch: AppDispatch, getState: () => AppState) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      const result = await authApi.api.signIn(data);

      const state = getState();
      const testRole = testRoleSelector(state);

      if (testRole === Role.Merchant && !result.roles?.includes(UserRole.MERCHANT) ||testRole === Role.Partner && !result.roles?.includes(UserRole.PARTNER)) {
        showError("Sorry you don't have access to the app");
        return;
      }

      OpenAPI.TOKEN = result.token;
      OpenAPISunset.TOKEN = result.token;
      OpenAPICrate.TOKEN = result.token;

      dispatch(employeeSlice.actions.clearIsNeedOTPCode());

      localStorage.setItem("employee", JSON.stringify(result));

      dispatch(employeeSlice.actions.signInSuccess(result));

      history.push("/sync", historyState);
    } catch (e) {
      if (e?.body?.error === "OTP_CODE_REQUIRED") {
        dispatch(employeeSlice.actions.setIsNeedOTPCode());
      }
      handleNetworkError(e, "Sign in error");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};

export const signInMobileSingular = (data: LoginRequest, frt: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      const result = await authApi.api.signIn(data);
      OpenAPI.TOKEN = result.token;
      OpenAPISunset.TOKEN = result.token;
      OpenAPICrate.TOKEN = result.token;

      localStorage.setItem("employee", JSON.stringify(result));

      dispatch(employeeSlice.actions.signInSuccess(result));

      await dispatch(updateFrtMobileThunk(frt));

      history.push("/sync");
    } catch (e) {
      handleNetworkError(e, "Sign in error");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};

export const signUp = (data: SignupRequestDTO) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      await authApi.api.signUp(data);
      dispatch(employeeSlice.actions.signUpSuccess());
      // @ts-ignore
      window.gtag &&
        window.gtag({
          event: "sign_up_success",
          email: data.email,
          firstname: data.name,
          lastname: data.surname,
          user_id: data.email,
        });

      if (data.partnerRefId) {
        localStorage.setItem("partnerRefId", JSON.stringify(data.partnerRefId));
      }

      // partner
      if (data.partnerName) {
        history.push("/signup/confirm");
        return;
      }

      // merchant
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      const frtMobile = params.frtMobile;
      const dataToSend = {
        email: data.email!,
        password: data.password!,
      };

      if (frtMobile) {
        dispatch(signInMobileSingular(dataToSend, frtMobile));
      } else {
        dispatch(signIn(dataToSend));
      }
    } catch (e) {
      handleNetworkError(e, "Sign up error");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};

export const requestResetPasswordThunk = (
  recaptchaResponse: string,
  email: string,
  isPartner?: boolean
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      await authApi.api.requestResetPassword(recaptchaResponse, email, isPartner);
      history.push("/password-recovery/confirm");
    } catch (e) {
      handleNetworkError(e, "Request error");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};

export const changePasswordThunk = (
  token: string,
  password: string,
  isPartner?: boolean
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      await authApi.api.changePassword(token, password, isPartner);
      showSuccess("Password changed");
      history.push("/signin");
    } catch (e) {
      handleNetworkError(e, "Password change error");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};

export const changePinThunk = (token: string, pin: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      await authApi.api.changePassword(token, pin);
      showSuccess("Pin changed");
      history.push("/password-reset/confirm");
    } catch (e) {
      handleNetworkError(e, "Pin change error");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};

export const confirmEmailSellerThunk = (token: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      await authApi.api.confirmEmail(token);

      // если нет кеша это новый браузер или еще что-то отображаем страницу confirmed
      const employee = localStorage.getItem("employee");
      if (!employee) {
        history.push("/confirmed-email");
        return;
      }

      const parsedEmployee = JSON.parse(employee);
      OpenAPI.TOKEN = parsedEmployee.token;
      OpenAPISunset.TOKEN = parsedEmployee.token;
      OpenAPICrate.TOKEN = parsedEmployee.token;

      try {
        dispatch(employeeSlice.actions.userMeRequest());
        const result = await authApi.api.userMe();
        dispatch(employeeSlice.actions.userMeSuccess(result));

        history.push("/");
        showSuccess("Mail successfully confirmed");
        return;
      } catch {
        history.push("/confirmed-email");
        return;
      }
    } catch (e) {
      handleNetworkError(e, "Mail confirmation error");
      history.push("/signin");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};

export const confirmEmailPartnerThunk = (token: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      await authApi.api.confirmEmail(token, true);

      history.push("/confirmed-email");
      return;
    } catch (e) {
      handleNetworkError(e, "Mail confirmation error");
      history.push("/signin");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};

export const updateFrtWebThunk = (frt: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      await authApi.api.updateFrtWeb(frt);
    } catch (e) {
    } finally {
    }
  };
};

export const updateFrtMobileThunk = (frt: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      await authApi.api.updateFrtMobile(frt);
    } catch (e) {
    } finally {
    }
  };
};

export const userMeThunk = () => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(loaderSlice.actions.showLoader());
      const result = await authApi.api.userMe();
      dispatch(employeeSlice.actions.userMeSuccess(result));
    } catch (e) {
      handleNetworkError(e, "User data fetch error");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};

export const resendOtpCodeThunk = (email: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(employeeSlice.actions.setIsFetchingOTPCode(true));
      await authApi.api.resendOtpCode(email);
      showSuccess("OTP code sent");
    } catch (e) {
      handleNetworkError(e, "OTP code sending error");
    } finally {
      dispatch(employeeSlice.actions.setIsFetchingOTPCode(false));
    }
  };
};

export const resendConfirmationLinkThunk = (userId: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(employeeSlice.actions.setIsFetchingConfirmationLink(true));
      await authApi.api.resendConfirmationLink(userId);
      showSuccess("Confirmation link sent");
    } catch (e) {
      handleNetworkError(e, "Confirmation link sending error");
    } finally {
      dispatch(employeeSlice.actions.setIsFetchingConfirmationLink(false));
    }
  };
};
