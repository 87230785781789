/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatePartnerDTO } from '../models/CreatePartnerDTO';
import type { InvitedUserRequestDTO } from '../models/InvitedUserRequestDTO';
import type { MerchantsToConnectDTO } from '../models/MerchantsToConnectDTO';
import type { PartnerCountDTO } from '../models/PartnerCountDTO';
import type { PartnerDTO } from '../models/PartnerDTO';
import type { PartnerTurnoverDTO } from '../models/PartnerTurnoverDTO';
import type { PartnerTurnoverPercentageDTO } from '../models/PartnerTurnoverPercentageDTO';
import type { SettlementDetailsAttemptUpdateDTO } from '../models/SettlementDetailsAttemptUpdateDTO';
import type { UpdatePartnerDTO } from '../models/UpdatePartnerDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { network } from 'services/network';

export class PartnerControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Update partner
     * Update new partner
     * @param requestBody
     * @returns PartnerDTO
     * @returns any Created
     * @throws ApiError
     */
    public updateUsingPut(
        requestBody?: UpdatePartnerDTO,
    ): CancelablePromise<PartnerDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'PUT',
                url: '/partners',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Create new partner
     * Create new partner
     * @param requestBody
     * @returns PartnerDTO
     * @returns any Created
     * @throws ApiError
     */
    public createUsingPost(
        requestBody?: CreatePartnerDTO,
    ): CancelablePromise<PartnerDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/partners',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Connect Merchants to Partner
     * Connect Merchants to Partner
     * @param requestBody
     * @returns PartnerDTO
     * @returns any Created
     * @throws ApiError
     */
    public connectMerchantsUsingPost(
        requestBody?: MerchantsToConnectDTO,
    ): CancelablePromise<PartnerDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/partners/connect-merchants',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Find partners
     * Find partners
     * @param limit limit
     * @param offset offset
     * @param name name
     * @returns PartnerDTO
     * @throws ApiError
     */
    public findPartnersUsingGet(
        limit: number,
        offset: number,
        name?: string,
    ): CancelablePromise<Array<PartnerDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/partners/find',
                query: {
                    'name': name,
                    'limit': limit,
                    'offset': offset,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Find partner by id
     * Find partner by id
     * @param id id
     * @returns PartnerDTO
     * @throws ApiError
     */
    public findPartnerByIdUsingGet(
        id: number,
    ): CancelablePromise<PartnerDTO> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/partners/find-by-id/{id}',
                path: {
                    'id': id,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Invite user
     * Invite user
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public inviteUserUsingPost(
        requestBody?: InvitedUserRequestDTO,
    ): CancelablePromise<any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/partners/invite',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Get current partner data
     * Get current partner data
     * @returns PartnerDTO
     * @throws ApiError
     */
    public getCurrentUsingGet(): CancelablePromise<PartnerDTO> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/partners/me',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Attempt Update partner`s Settlement details
     * Attempt Update partner`s Settlement details
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public settlementDetailsAttemptUpdateUsingPut(
        requestBody?: SettlementDetailsAttemptUpdateDTO,
    ): CancelablePromise<any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'PUT',
                url: '/partners/settlement-details',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Update partner`s Settlement details
     * Update partner`s Settlement details
     * @param token token
     * @returns PartnerDTO
     * @returns any Created
     * @throws ApiError
     */
    public settlementDetailsConfirmUpdateUsingPost(
        token: string,
    ): CancelablePromise<PartnerDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/partners/settlement-details',
                query: {
                    'token': token,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Partner statistics
     * Partner statistics
     * @param from from
     * @param to to
     * @returns PartnerCountDTO
     * @throws ApiError
     */
    public partnerStatsUsingGet(
        from?: string,
        to?: string,
    ): CancelablePromise<PartnerCountDTO> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/partners/stats',
                query: {
                    'from': from,
                    'to': to,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Partner turnover
     * Partner turnover
     * @param from from
     * @param to to
     * @param limit limit
     * @param offset offset
     * @param merchantIds merchantIds
     * @returns PartnerTurnoverDTO
     * @throws ApiError
     */
    public calculateTurnoverUsingGet(
        from: string,
        to: string,
        limit: number,
        offset: number,
        merchantIds?: number,
    ): CancelablePromise<PartnerTurnoverDTO> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/partners/turnover',
                query: {
                    'merchantIds': merchantIds,
                    'from': from,
                    'to': to,
                    'limit': limit,
                    'offset': offset,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Partner turnover with percentage
     * Partner turnover with percentage
     * @param from from
     * @param to to
     * @returns PartnerTurnoverPercentageDTO
     * @throws ApiError
     */
    public partnerTurnoverPercentageUsingGet(
        from: string,
        to: string,
    ): CancelablePromise<PartnerTurnoverPercentageDTO> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/partners/turnover-percentage',
                query: {
                    'from': from,
                    'to': to,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

}