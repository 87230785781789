import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { confirmEmailPartnerThunk } from "data-layer/employee/thunks";

type UsePartnerConfirmEmail = () => {
  confirmEmailPartner: (token: string) => void;
};

export const usePartnerConfirmEmail: UsePartnerConfirmEmail = () => {
  const dispatch = useDispatch();

  const confirmEmailPartner = useCallback(
    (token: string) => {
      dispatch(confirmEmailPartnerThunk(token));
    },
    [dispatch]
  );

  return {
    confirmEmailPartner,
  };
};
