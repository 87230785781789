import { appClient } from "services";
import { PaymentsParams } from "../types/PaymentsParams";
import { IPaymentsApi } from "./index";

export const api: IPaymentsApi = {
  getPayments: (params: PaymentsParams) => {
    const {
      from,
      to,
      limit,
      offset,
      customerName,
      amountMin,
      amountMax,
      status,
    } = params;

    return appClient.paymentLinkController.findUsingGet(
      from,
      to,
      limit,
      offset,
      customerName,
      amountMin,
      amountMax,
      status,
    );
  },

  createPaymentLink: (body) => {
    return appClient.paymentLinkController.createUsingPost(body);
  },
};
