import styled from 'styled-components';
import { Modal } from "antd";

const Wrapper = styled(Modal)`
    .ant-modal-header,
    .ant-modal-body {
        background-color: #e8eaf5;
    }

    .ant-modal-content {
        border-radius: 10px;
        overflow: hidden;
    }
`

export const Styled = {
    Wrapper,
}