/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateUpdateProfileDTO } from '../models/CreateUpdateProfileDTO';
import type { ProfileDTO } from '../models/ProfileDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { network } from 'services/network';

export class ProfileControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all users profiles
     * Get all users profiles
     * @returns ProfileDTO
     * @throws ApiError
     */
    public getAllUsingGet(): CancelablePromise<Array<ProfileDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/profile',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Create new profile
     * Create new profile
     * @param requestBody
     * @returns ProfileDTO
     * @returns any Created
     * @throws ApiError
     */
    public createUsingPut(
        requestBody?: CreateUpdateProfileDTO,
    ): CancelablePromise<ProfileDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'PUT',
                url: '/profile',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Find profiles by filter (only for admin)
     * All parameters are required, but if no need to filter by states or company name you able to send it empty
     * @param limit
     * @param offset
     * @param companyName
     * @param countryId
     * @param from
     * @param kinds
     * @param managerName
     * @param orderBy0Direction
     * @param orderBy0Field
     * @param states
     * @param to
     * @returns ProfileDTO
     * @throws ApiError
     */
    public getAllAdminUsingGet(
        limit: number,
        offset: number,
        companyName?: string,
        countryId?: number,
        from?: string,
        kinds?: 'COMPANY' | 'SOLE_TRADER',
        managerName?: string,
        orderBy0Direction?: 'ASC' | 'DESC',
        orderBy0Field?: string,
        states?: 'APPROVED' | 'CHANGES_REQUESTED' | 'NEW' | 'REJECTED' | 'REVIEWING',
        to?: string,
    ): CancelablePromise<Array<ProfileDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/profile/admin',
                query: {
                    'companyName': companyName,
                    'countryId': countryId,
                    'from': from,
                    'kinds': kinds,
                    'limit': limit,
                    'managerName': managerName,
                    'offset': offset,
                    'orderBy[0].direction': orderBy0Direction,
                    'orderBy[0].field': orderBy0Field,
                    'states': states,
                    'to': to,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Connect selected profile with partner
     * Connect selected profile with partner
     * @param partnerId partnerId
     * @returns ProfileDTO
     * @returns any Created
     * @throws ApiError
     */
    public connectWithPartnerUsingPost(
        partnerId: number,
    ): CancelablePromise<ProfileDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/profile/connect-partner/{partnerId}',
                path: {
                    'partnerId': partnerId,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Find profiles by filter (for partner)
     * All parameters are required, but if no need to filter by states or company name you able to send it empty
     * @param states states
     * @param partnerId partnerId
     * @param limit limit
     * @param offset offset
     * @param from from
     * @param to to
     * @param companyName companyName
     * @returns ProfileDTO
     * @throws ApiError
     */
    public getAllPartnerUsingGet(
        states: 'APPROVED' | 'CHANGES_REQUESTED' | 'NEW' | 'REJECTED' | 'REVIEWING',
        partnerId: number,
        limit: number,
        offset: number,
        from?: string,
        to?: string,
        companyName?: string,
    ): CancelablePromise<Array<ProfileDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/profile/partner',
                query: {
                    'states': states,
                    'from': from,
                    'to': to,
                    'companyName': companyName,
                    'partnerId': partnerId,
                    'limit': limit,
                    'offset': offset,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Set selected profile
     * Set selected profile
     * @param profileId profileId
     * @returns any
     * @throws ApiError
     */
    public selectUsingPost(
        profileId: number,
    ): CancelablePromise<any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/profile/select/{profileId}',
                path: {
                    'profileId': profileId,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Get by id (admin)
     * Get by id (admin)
     * @param id id
     * @returns ProfileDTO
     * @throws ApiError
     */
    public getByIdAdminUsingGet(
        id: number,
    ): CancelablePromise<ProfileDTO> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/profile/{id}',
                path: {
                    'id': id,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Update profile
     * Update profile
     * @param id id
     * @param requestBody
     * @returns ProfileDTO
     * @returns any Created
     * @throws ApiError
     */
    public updateUsingPost(
        id: number,
        requestBody?: CreateUpdateProfileDTO,
    ): CancelablePromise<ProfileDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/profile/{id}',
                path: {
                    'id': id,
                },
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

}