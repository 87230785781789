import { Row, Col } from "antd";
import { Rewards, Help, RoadMap, Revenue } from "./components";
import { Styled } from "./styles";
import { useGetTransactions } from "./hooks/useGetTransactions";
import { useDateRange } from "./hooks/useDateRange";
import { ComingSoon } from "./components/ComingSoon/ComingSoon";
import { ResentSales } from "./components/ResentSales";

export interface IOverview {
  handleChangeRevenueDateRange: (range: number) => void;
  // handleChangeProvider: (provider: Provider) => void;
  averageTransactionValue: string;
  totalSalesValue: string;
  revenueDateRange: number;
  rangeChartData: {
    payByLinkAverage: number;
    tapToPayAverage: number;
    name: string;
  }[];
}

const OverviewLargeGrid: React.FC<IOverview> = ({
  handleChangeRevenueDateRange,
  averageTransactionValue,
  revenueDateRange,
  totalSalesValue,
  rangeChartData,
}) => {
  return (
    <Styled.LargeGrid>
      <Styled.MainRow align="stretch">
        <Col span={24}>
          <Row gutter={[{ xs: 16, sm: 16, xl: 25 }, { xs: 16, sm: 16, xl: 20 }]} align="stretch">
            <Col span={6}>
              <RoadMap />
            </Col>
            <Col span={18}>
              <Revenue
                handleChangeRevenueDateRange={handleChangeRevenueDateRange}
                averageTransactionValue={averageTransactionValue}
                revenueDateRange={revenueDateRange}
                totalSalesValue={totalSalesValue}
                rangeChartData={rangeChartData}
              />
            </Col>
            <Col span={24}>
              <Row gutter={{ xs: 16, sm: 16, xl: 25 }} align="stretch">
                <Col span={12}>
                  <ResentSales />
                </Col>
                <Col span={6}>
                  <ComingSoon />
                </Col>
                <Col span={6}>
                  <Help />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Styled.MainRow>
    </Styled.LargeGrid>
  );
};

const OverviewMediumGrid: React.FC<IOverview> = ({
  handleChangeRevenueDateRange,
  averageTransactionValue,
  revenueDateRange,
  totalSalesValue,
  rangeChartData,
}) => {
  return (
    <Styled.MediumGrid>
      <Styled.MainRow align="stretch">
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col style={{ width: "28%" }}>
              <RoadMap />
            </Col>
            <Col style={{ width: "72%" }}>
              <Revenue
                handleChangeRevenueDateRange={handleChangeRevenueDateRange}
                averageTransactionValue={averageTransactionValue}
                revenueDateRange={revenueDateRange}
                totalSalesValue={totalSalesValue}
                rangeChartData={rangeChartData}
              />
            </Col>
            <Col span={24}>
              <Row gutter={16} align="stretch" wrap={false}>
                <Col style={{ width: "36%" }}>
                  <ResentSales />
                </Col>
                <Col style={{ width: "36%" }}>
                  <ComingSoon />
                </Col>
                <Col style={{ width: "28%" }}>
                  <Help />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Styled.MainRow>
    </Styled.MediumGrid>
  );
};

const OverviewGrid: React.FC<IOverview> = ({
  handleChangeRevenueDateRange,
  averageTransactionValue,
  revenueDateRange,
  totalSalesValue,
  rangeChartData,
}) => {
  return (
    <Styled.Grid>
      <Styled.MainRow gutter={[16, 16]} align="stretch">
        <Col span={24}>
          <RoadMap onlyActive={true} />
        </Col>
        <Col span={24}>
          <Revenue
            handleChangeRevenueDateRange={handleChangeRevenueDateRange}
            averageTransactionValue={averageTransactionValue}
            revenueDateRange={revenueDateRange}
            totalSalesValue={totalSalesValue}
            rangeChartData={rangeChartData}
          />
        </Col>
        <Col span={24}>
          <ResentSales />
        </Col>
        <Col span={24}>
          <Help onlyActive={true} />
        </Col>
        {/* <Col span={24}>
          <ComingSoon />
        </Col> */}
      </Styled.MainRow>
    </Styled.Grid>
  );
};

export const OverviewSc: React.FC = () => {
  const {
    handleChangeRevenueDateRange,
    averageTransactionValue,
    totalSalesValue,
    revenueDateRange,
    dataAmount,
  } = useGetTransactions();

  const { rangeChartData } = useDateRange({
    data: dataAmount,
    currentRange: revenueDateRange,
  });

  return (
    <div className="container">
      <OverviewLargeGrid
        handleChangeRevenueDateRange={handleChangeRevenueDateRange}
        totalSalesValue={totalSalesValue}
        averageTransactionValue={averageTransactionValue}
        // handleChangeProvider={handleChangeProvider}
        revenueDateRange={revenueDateRange}
        rangeChartData={rangeChartData}
      />
      <OverviewMediumGrid
        handleChangeRevenueDateRange={handleChangeRevenueDateRange}
        totalSalesValue={totalSalesValue}
        averageTransactionValue={averageTransactionValue}
        // handleChangeProvider={handleChangeProvider}
        revenueDateRange={revenueDateRange}
        rangeChartData={rangeChartData}
      />
      <OverviewGrid
        handleChangeRevenueDateRange={handleChangeRevenueDateRange}
        totalSalesValue={totalSalesValue}
        averageTransactionValue={averageTransactionValue}
        // handleChangeProvider={handleChangeProvider}
        revenueDateRange={revenueDateRange}
        rangeChartData={rangeChartData}
      />
    </div>
  );
};
