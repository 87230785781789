import { AppDispatch, AppState } from "data-layer/store/store";
import { profileApi } from "data-layer/profile/api";
import { syncSlice } from "data-layer/common/syncSlice";
import { profileSlice } from "data-layer/profile/slice";
import { loaderSlice } from "data-layer/common/loaderSlice";
// TODO : fix src alias
import { history } from "data-layer/../router/AppRouter";
import { getDictionaryThunk } from "data-layer/dictionary/thunks";
import { getApplicationsThunk } from "data-layer/application/thunks";
import { showError } from "utils/showError";
import { clearUser } from "utils/clearUser";
import { employeeSelector } from "data-layer/employee/selectors";
// import { UserRole } from "data-layer/employee";
import { findParterByIdThunk, getPartnerMeThunk } from "data-layer/partner/thunks";
// import { JwtReposnse } from "generatedSunset";
import { getMeThunk } from "data-layer/merchant/thunks";
import { userMeThunk } from "data-layer/employee/thunks";
import { merchantSlice } from "data-layer/merchant/slice";
import { applicationSlice } from "data-layer/application/slice";
import { employeeSlice } from "data-layer/employee/slice";
import { handleNetworkError } from "utils/handleNetworkError";
import { partnerSelector } from "data-layer/partner/selectors";
import { getSys18Thunk } from "data-layer/sys18/thunks";
// import { notificactionsActions } from "./notificactionsSlice";

// export const shouldShowModalStart = () => {
//   return (dispatch: AppDispatch, getState: () => AppState) => {
//     const state = getState();

//     const selectedProfile = selectedProfileSelector(state);

//     if (!selectedProfile || selectedProfile.status === null) {
//       dispatch(notificactionsActions.showModalStartNotification());
//       return;
//     }

//     if (
//       selectedProfile.status === ProfileDTO.status.NOT_STARTED ||
//       selectedProfile.status === ProfileDTO.status.NOT_FINISHED
//     ) {
//       dispatch(notificactionsActions.showModalStartNotification());
//     }
//   };
// };

// export const defineStartPageToMerchant = () => {
//   return (dispatch: AppDispatch, getState: () => AppState) => {
//     const state = getState();

//     const selectedProfile = selectedProfileSelector(state);

//     if (!selectedProfile) {
//       history.push('/merchant/onboarding-guide');
//       return;
//     }

//     if (selectedProfile?.status === ProfileDTO.status.APPROVED) {
//       // current route is correct
//       return;
//     }

//     if (selectedProfile.status === ProfileDTO.status.NOT_FINISHED) {
//       history.push('/merchant/onboarding');
//     }

//     if (selectedProfile.status === ProfileDTO.status.UNDER_REVIEW) {
//       history.push('/merchant/onboarding-finish');
//     }

//     if (selectedProfile.status === ProfileDTO.status.REJECTED) {
//       history.push('/merchant/onboarding-finish');
//     }
//   }
// }

export const syncPartnerThunk = (
  onSuccess?: VoidFunction,
  onFailure?: VoidFunction
) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(loaderSlice.actions.showLoader());

      await dispatch(getDictionaryThunk());

      // try {
      //   await dispatch(getSys18Thunk(9999999, 0))
      // } catch(e) {}

      await dispatch(userMeThunk());

      await dispatch(getPartnerMeThunk());

      dispatch(syncSlice.actions.syncSuccess());

      if (onSuccess) {
        onSuccess();
        return;
      }
    } catch (e) {
      handleNetworkError(e, "Sorry you've been signed out");
      dispatch(syncSlice.actions.syncFailure());
      dispatch(employeeSlice.actions.logout())
      history.push("/signin");
      clearUser();

      if (onFailure) {
        onFailure();
      }
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};

export const syncMerchantThunk = (
  onSuccess?: VoidFunction,
  onFailure?: VoidFunction
) => {
  return async (dispatch: AppDispatch, getState: () => AppState) => {
    try {
      dispatch(loaderSlice.actions.showLoader());

      await dispatch(getDictionaryThunk());

      // try {
      //   await dispatch(getSys18Thunk(9999999, 0))
      // } catch(e) {}

      await dispatch(userMeThunk());

      const state = getState();
      const employee = employeeSelector(state);

      if (employee.user?.inviterPartnerId) {
        await dispatch(findParterByIdThunk(employee.user?.inviterPartnerId))
        // кеш картинки
        const state = getState();
        const partner = partnerSelector(state);
        localStorage.setItem("logoImageFilename", JSON.stringify(partner.invitedPartnerData?.logoImageFilename));
      }

      const profileResult = await profileApi.api.getProfile();
      dispatch(profileSlice.actions.getProfileSuccess(profileResult));

      dispatch(merchantSlice.actions.clearMerchant());
      dispatch(applicationSlice.actions.clearApplication());
      if (profileResult.length !== 0) {
        await dispatch(getApplicationsThunk());
        await dispatch(getMeThunk());
      }

      dispatch(syncSlice.actions.syncSuccess());

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      handleNetworkError(e, "Sorry you've been signed out");
      dispatch(syncSlice.actions.syncFailure());
      dispatch(employeeSlice.actions.logout());
      history.push("/signin");
      clearUser();

      if (onFailure) {
        onFailure();
      }
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};
