import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationDTO } from "generated/models/ApplicationDTO";
import { ApplicationCountDTO } from "generated";

export type Application = [] | ApplicationDTO[];

export type ApplicationInitialState = {
  applications: Application;
  applicationsCount: number;
  isApplicationsCountFetching: boolean;
};

const initialState: ApplicationInitialState = {
  applications: [],
  applicationsCount: 0,
  isApplicationsCountFetching: false,
};

export const applicationSlice = createSlice({
  name: "applications",
  initialState: initialState,
  reducers: {
    getApplicationsSuccess: (
      state,
      action: PayloadAction<Array<ApplicationDTO>>
    ) => {
      state.applications = action.payload;
    },

    // deprecated
    // initApplicationRequest: (state) => {},
    // initApplicationSuccess: (state) => {},
    // initApplicationFailure: (state) => {},

    setApplications: (state, action: PayloadAction<Array<ApplicationDTO>>) => {
      state.applications = action.payload;
    },
    setApplication: (state, action: PayloadAction<ApplicationDTO>) => {
      state.applications = state.applications.map((app) => {
        if (app.id === action.payload.id) {
          return action.payload;
        }

        return app;
      });
    },
    clearApplication: (state) => {
      state.applications = [];
    },

    persistApplicationSuccess: (
      state,
      action: PayloadAction<ApplicationDTO>
    ) => {
      // for the first onboardign we don't have applicatios
      if (state.applications.length === 0) {
        state.applications = [action.payload];
        return;
      }

      state.applications = state.applications.map((app) => {
        if (app.id === action.payload.id) {
          return {
            ...action.payload,
          };
        }

        return app;
      });
    },
    getApplicationCountRequest: (state) => {
      state.isApplicationsCountFetching = true;
    },
    getApplicationCountSuccess: (
      state,
      action: PayloadAction<ApplicationCountDTO>
    ) => {
      state.applicationsCount = action.payload.total || 0;
      state.isApplicationsCountFetching = false;
    },
    getApplicationCountFailure: (state) => {
      state.isApplicationsCountFetching = false;
    },
  },
});

export const applicationActions = applicationSlice.actions;
export const applicationReducer = applicationSlice.reducer;
