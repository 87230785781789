import { Row, Col } from "antd";
import { Styled } from "./stylesList";
import { AttachmentDTO } from "generated/models/AttachmentDTO";
import React from "react";

type InputFileListViewProps = {
  value: number;
  file: AttachmentDTO;
  onRemove: (e: any) => void;
};

export const InputFileListView: React.FC<InputFileListViewProps> = (props) => {
  const { value, file, onRemove } = props;

  return (
    <>
      <Styled.Wrapper align="middle">
        <Col flex={1}>
          <Styled.Field>
            <Styled.FieldText>{file && file.originalFileName}</Styled.FieldText>
          </Styled.Field>
        </Col>
        <Col>
          <Styled.RemoveButton onClick={onRemove} disabled={!value} />
        </Col>
      </Styled.Wrapper>
    </>
  );
};
