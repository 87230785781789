import {
  InvitedUserRequestDTO,
  PartnerCountDTO,
  PartnerDTO,
  PartnerTurnoverPercentageDTO,
  ResponseEntity,
  SettlementDetailsAttemptUpdateDTO,
  UpdatePartnerDTO,
} from "generated";
import { CancelablePromise } from "generated/core/CancelablePromise";
import { api } from "./api";

export interface IPartnerApi {
  me: () => CancelablePromise<PartnerDTO>;
  turnoverPercentage: (
    from: string,
    to: string
  ) => CancelablePromise<PartnerTurnoverPercentageDTO>;
  stats: (from: string, to: string) => CancelablePromise<PartnerCountDTO>;
  invite: (body: InvitedUserRequestDTO) => CancelablePromise<ResponseEntity | any>;
  updatePartnerSettlement: (token: string) => CancelablePromise<PartnerDTO | any>;
  attemptPartnerSettlement: (requestBody: SettlementDetailsAttemptUpdateDTO) => CancelablePromise<any>;
  updatePartner: (requestBody: UpdatePartnerDTO) => CancelablePromise<PartnerDTO | any>
  findPartnerById: (id: number) => CancelablePromise<PartnerDTO>;
}

class PartnerApi {
  api: IPartnerApi = api;

  constructor() {
    if (process.env.REACT_APP_MOCK_ENABLED === "true") {
      this.api = api;
      return;
    }

    this.api = api;
  }
}

export const partnerApi = new PartnerApi();
