import { useCallback, useEffect, useState } from "react";
import { BulkUploadModal } from "./components/BulkUploadModal";
import { BulkUploadViewModal } from "./components/BulkUploadViewModal";
import { ParseImportFileResultDTO } from "generated";
import { ParsedResult } from "data-layer/merchantImport/types/ParsedResult";

type BulkUploadScProps = {
  ViewComponent: React.FC<{onClick: VoidFunction}>;
}

export const BulkUploadSc: React.FC<BulkUploadScProps> = (props) => {
  const { ViewComponent } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalViewVisible, setIsModalViewVisible] = useState(false);
  const [data, setData] = useState<null | ParseImportFileResultDTO>(null);
  const [dataResult, setDataResult] = useState<null | ParsedResult>(null);

  const handleShowBulkkUploadModal = useCallback(() => {
    setIsModalVisible(true);
  }, [setIsModalVisible]);

  const handleCloseBulkkUploadModal = useCallback(() => {
    setIsModalVisible(false);
  }, [setIsModalVisible]);

  const handleShowBulkkUploadViewModal = useCallback(() => {
    setIsModalViewVisible(true);
  }, [setIsModalViewVisible]);

  const handleCloseBulkkUploadViewModal = useCallback(() => {
    setIsModalViewVisible(false);
  }, [setIsModalViewVisible]);

  const handleParseFile = useCallback(
    (data: ParseImportFileResultDTO) => {
      handleCloseBulkkUploadModal();
      handleShowBulkkUploadViewModal();
      setData(data);
    },
    [handleCloseBulkkUploadModal, handleShowBulkkUploadViewModal, setData]
  );

  const handleBack = useCallback(() => {
    handleCloseBulkkUploadViewModal();
    handleShowBulkkUploadModal();
    setData(null);
    setDataResult(null);
  }, [handleCloseBulkkUploadViewModal, handleShowBulkkUploadModal, setData]);

  const handleParseResult = useCallback(
    (data: ParsedResult) => {
      setDataResult({
        success: data.success,
        failed: data.failed,
      });
    },
    [setDataResult]
  );

  return (
    <>
      <ViewComponent onClick={handleShowBulkkUploadModal}/>

      {isModalVisible && (
        <BulkUploadModal
          visible={isModalVisible}
          onClose={handleCloseBulkkUploadModal}
          onParse={handleParseFile}
        />
      )}

      {isModalViewVisible && (
        <BulkUploadViewModal
          visible={isModalViewVisible}
          onClose={handleCloseBulkkUploadViewModal}
          onBack={handleBack}
          data={data}
          dataResult={dataResult}
          handleParseResult={handleParseResult}
        />
      )}
    </>
  );
};
