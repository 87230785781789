import { ApiError } from "generated";
import { ApiError as ApiSunsetError } from "generatedSunset";
import { ApiError as ApiCrateError } from "generatedCrate";
import { showError } from "./showError";
import { store } from "data-layer/store";
import { Sys18 } from "data-layer/sys18/slice";

interface IErrorCode {
  body: {
    error: string;
  };
}

interface IErrorDetails {
  body: {
    details: Record<string, any>;
  };
}

interface IError {
  body: {
    error?: string;
    details?: Record<string, any>;
  };
}

const isError = (e: unknown): e is IError => {
  if (
    typeof e === "object" &&
    !!e &&
    "body" in e &&
    typeof e.body === "object" &&
    !!e.body
  ) {
    return true;
  }

  return false;
};

const errorWithErrorCode = (e: unknown): e is IErrorCode => {
  if (
    typeof e === "object" &&
    !!e &&
    "body" in e &&
    typeof e.body === "object" &&
    !!e.body &&
    "error" in e.body &&
    typeof e.body.error === "string"
  ) {
    return true;
  }

  return false;
};

const errorWithDetails = (e: unknown): e is IErrorDetails => {
  if (
    typeof e === "object" &&
    !!e &&
    "body" in e &&
    typeof e.body === "object" &&
    !!e.body &&
    "details" in e.body &&
    typeof e.body.details === "object" &&
    !!e.body.details
  ) {
    return true;
  }

  return false;
};

const getError = (
  e: ApiError | ApiSunsetError | ApiCrateError | IError,
  sys18Data: Sys18
): string | string[] | void => {
  if (!sys18Data.length) {
    return;
  }

  const ENSys18 = sys18Data.filter((item) => item.locale === "EN");

  if (!ENSys18.length) {
    return;
  }

  if (errorWithDetails(e)) {
    let keys = Object.keys(e.body.details);
    let errors = [];

    if (keys.length) {
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let err = ENSys18.find((item) => item.id === key);

        if (err) {
          errors.push(err.value);
        } else {
          errors.push(key);
        }
      }

      return errors;
    }
  }

  if (errorWithErrorCode(e)) {
    let err = ENSys18.find((item) => item.id === e.body.error);

    if (err) {
      return err.value;
    }

    return e.body.error;
  }
};

export const handleNetworkError = (e: unknown, title: string, description?: string) => {
  const state = store.getState();
  let _title;

  if (e instanceof ApiError || e instanceof ApiSunsetError || e instanceof ApiCrateError) {
    _title = getError(e, state.sys18.data);

    if (Array.isArray(_title)) {
      _title.forEach((str) => {
        showError(str);
      });
    } else {
      showError(_title || title);
    }

    return;
  }

  if (isError(e)) {
    _title = getError(e, state.sys18.data);
  }

  if (Array.isArray(_title)) {
    _title.forEach((str) => {
      showError(str);
    });
  } else {
    showError(_title || title);
  }
};
