import { createSlice } from "@reduxjs/toolkit";

export type LoaderInitialstate = {
  visible: boolean;
};

const initialState: LoaderInitialstate = {
  visible: false,
};

export const loaderSlice = createSlice({
  name: "loader",
  initialState: initialState,
  reducers: {
    showLoader: (state) => {
      state.visible = true;
    },
    hideLoader: (state) => {
      state.visible = false;
    },
  },
});

export const loaderActions = loaderSlice.actions;
export const loaderReducer = loaderSlice.reducer;
