import { Spin } from "antd";
import { TableSc } from "scenarios";
import { MerchantsParams } from "data-layer/merchant/types/MerchantsParams";
import { useMerchant } from "data-layer/merchant";
import { splitText } from "utils/splitText";
import { MerchantDTO } from "generated";
import { Styled } from "./styles";

const getColumns = () => {
  return [
    {
      title: "Client ID",
      dataIndex: "id",
      key: "id",
      width: "14%",
    },
    {
      title: "Type",
      dataIndex: "kind",
      key: "kind",
      width: "14%",
      render: (kind: MerchantDTO.kind) => {
        if (!kind) {
          return <span />;
        }

        return <span>{splitText(kind)}</span>;
      },
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      width: "14%",
    },
    {
      title: "Company name",
      dataIndex: "merchantName",
      key: "merchantName",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: MerchantDTO.status) => {
        if (!status) {
          return <span />;
        }

        return <Styled.Primary>{splitText(status)}</Styled.Primary>;
      },
    },
  ];
};

type TableProps = {
  filters: MerchantsParams;
};

export const Table: React.FC<TableProps> = (props) => {
  const { filters } = props;
  const { merchants, isFetchingMerchants } = useMerchant();
  const columns = getColumns();

  if ((!merchants || !merchants.length) && isFetchingMerchants) {
    return <Spin />;
  }

  if (!filters) {
    return <Spin />;
  }

  return (
    <TableSc
      data={merchants}
      filters={filters}
      columns={columns}
      scroll={{ x: 1400 }}
    />
  );
};
