/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ComplianceResultData } from './ComplianceResultData';
import type { ProfileDTO } from './ProfileDTO';

export type ComplianceTaskDTO = {
    profile?: ProfileDTO;
    resultData?: ComplianceResultData;
    status?: ComplianceTaskDTO.status;
    type?: ComplianceTaskDTO.type;
};

export namespace ComplianceTaskDTO {

    export enum status {
        FAILED = 'FAILED',
        FINISHED = 'FINISHED',
        IN_PROGRESS = 'IN_PROGRESS',
        NEW = 'NEW',
    }

    export enum type {
        AML_LEXIS_NEXIS = 'AML_LEXIS_NEXIS',
    }


}
