/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminOverviewMerchantsDTO } from '../models/AdminOverviewMerchantsDTO';
import type { AdminOverviewPartnersDTO } from '../models/AdminOverviewPartnersDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { network } from 'services/network';

export class AdminOverviewControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Admin overview
     * Admin overview
     * @param partnerIds partnerIds
     * @param from from
     * @param to to
     * @returns AdminOverviewMerchantsDTO
     * @throws ApiError
     */
    public overviewMerchantsUsingGet(
        partnerIds?: number,
        from?: string,
        to?: string,
    ): CancelablePromise<AdminOverviewMerchantsDTO> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/admin/overview/merchants',
                query: {
                    'partnerIds': partnerIds,
                    'from': from,
                    'to': to,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Admin (partner) overview
     * Admin (partner) overview
     * @param partnerIds partnerIds
     * @param from from
     * @param to to
     * @returns AdminOverviewPartnersDTO
     * @throws ApiError
     */
    public overviewPartnerUsingGet(
        partnerIds?: number,
        from?: string,
        to?: string,
    ): CancelablePromise<AdminOverviewPartnersDTO> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/admin/overview/partners',
                query: {
                    'partnerIds': partnerIds,
                    'from': from,
                    'to': to,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

}