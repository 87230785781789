import { AbstractEventVO, NotificationDTO } from "generated";
import { history } from "data-layer/../router/AppRouter";
// import { NotificationDocumentsRequestButton } from "scenarios/seller/NotificationsSc/NotificationDocumentsRequestButton";

export enum NotificationType {
  Default = "Default",
  Warning = "WARNING",
  Success = "Success",
  Error = "Error",
}

export type NotifcationData = {
  title: string;
  description?: string;
  created?: string;
  type: NotificationType;
  action?: {
    title?: string;
    cb?: VoidFunction;
    Element?: React.FC<{ onClose?: VoidFunction }>;
  };
};

export const getNotificationData = (
  notification?: NotificationDTO,
  _payload?: AbstractEventVO
): NotifcationData | null => {
  let process = _payload?.process || notification?.event?.process;
  let payload = _payload || notification?.event?.payload!;

  switch (process) {
    case AbstractEventVO.process.APPLICATION: {
      return getApplicactionNotificationData(payload, notification);
    }
    case AbstractEventVO.process.AUTH: {
      return getAuthNotificationData(payload, notification);
    }
    case AbstractEventVO.process.DOCUMENT_REQUEST: {
      return getDocumentRequestApplicactionNotificationData(
        payload,
        notification
      );
    }
    case AbstractEventVO.process.EMPLOYEE: {
      return getEmployeeApplicactionNotificationData(payload, notification);
    }
    case AbstractEventVO.process.MERCHANT: {
      return getMerchantApplicactionNotificationData(payload, notification);
    }
    case AbstractEventVO.process.PARTNER: {
      return getPartnerApplicactionNotificationData(payload, notification);
    }
    case AbstractEventVO.process.PAYOUT: {
      return getPayoutApplicactionNotificationData(payload, notification);
    }
    case AbstractEventVO.process.PROOF_PERSON: {
      return getProofPersonApplicactionNotificationData(payload, notification);
    }
    default: {
    }
  }

  return null;
};

const getApplicactionNotificationData = (
  payload: AbstractEventVO,
  notification?: NotificationDTO
): NotifcationData | null => {
  // if (notification.event?.action === "INIT_APPLICATION") {
  //   return {
  //     title: "Complete your onboarding application",
  //     description: "To get the service please come through the onboarding procedure",
  //     created: notification.event?.created!,
  //     type: NotificationType.Warning,
  //     action: {
  //       title: "Start",
  //       cb: () => {
  //         history.push("/onboarding");
  //       }
  //     }
  //   };
  // }

  if (payload.action === "APPROVE_APPLICATION") {
    return {
      title: "Your Dapio account is ready",
      description:
        "Your Dapio application has been approved and you can get started right away!",
      created: notification?.event?.created!,
      type: NotificationType.Success,
    };
  }

  if (payload.action === "REJECT_APPLICATION") {
    return {
      title: "Your Dapio application has not been approved",
      description:
        "Unfortunately we’re unable offer you a Dapio account at this time.",
      created: notification?.event?.created!,
      type: NotificationType.Error,
      action: {
        title: "Learn more",
        cb: () => {
          history.push("/dashboard/support/faq");
        },
      },
    };
  }

  // UPDATE_APPLICATION
  // "SENT_TO_REVIEW_APPLICATION"
  return null;
};

const getAuthNotificationData = (
  payload: AbstractEventVO,
  notification?: NotificationDTO
): NotifcationData | null => {
  //   "SIGN_IN";
  //   "SIGN_UP";
  //   "RESET_PASSWORD_REQUEST"
  //   "RESET_PASSWORD"

  return null;
};

const getDocumentRequestApplicactionNotificationData = (
  payload: AbstractEventVO,
  notification?: NotificationDTO
): NotifcationData | null => {
  if (payload.action === "CREATED_DOCUMENT_REQUEST") {
    return {
      title: "Document request",
      description:
        "Please provide documents to approve your onboarding application",
      created: notification?.event?.created!,
      type: NotificationType.Warning,
      // action: {
      //   Element: (props) => {
      //     return (
      //       <NotificationDocumentsRequestButton data={payload} onClose={props.onClose} />
      //     );
      //   },
      // },
    };
  }

  if (payload?.action === "REJECTED_DOCUMENT_REQUEST") {
    return {
      title: "Your documents rejected",
      // description: "Please provide documents to approve your onboarding application",
      created: notification?.event?.created!,
      type: NotificationType.Error,
      // action: {
      //   title: "Provide",
      //   cb: () => {
      //     //
      //   }
      // }
    };
  }

  //   "REJECTED_DOCUMENT_REQUEST"
  //   "SENT_TO_REVIEW_DOCUMENT_REQUEST"
  //   "APPROVED_DOCUMENT_REQUEST"

  return null;
};

const getEmployeeApplicactionNotificationData = (
  payload: AbstractEventVO,
  notification?: NotificationDTO
): NotifcationData | null => {
  if (payload.action === "EMPLOYEE_CREATED") {
    return {
      title: "Employee created",
      created: notification?.event?.created!,
      type: NotificationType.Success,
    };
  }

  if (payload.action === "EMPLOYEE_EXPORTED") {
    return {
      title: "Employee exported",
      created: notification?.event?.created!,
      type: NotificationType.Warning,
    };
  }

  //   "EMPLOYEE_DEVICE_ACTIVATED"
  //   "EMPLOYEE_OPERATOR_PIN_CHANGED"

  return null;
};

const getMerchantApplicactionNotificationData = (
  payload: AbstractEventVO,
  notification?: NotificationDTO
): NotifcationData | null => {
  //   "MERCHANT_CREATED"

  return null;
};

const getPartnerApplicactionNotificationData = (
  payload: AbstractEventVO,
  notification?: NotificationDTO
): NotifcationData | null => {
  //   "PARTNER_INVITED_USER"

  return null;
};

const getPayoutApplicactionNotificationData = (
  payload: AbstractEventVO,
  notification?: NotificationDTO
): NotifcationData | null => {
  if (payload.action === "PAYOUT_IN_PROGRESS") {
    return {
      title: "___,___.__ on the way",
      description:
        "Your money will be credited to your bank account within 3 business days.",
      created: notification?.event?.created!,
      type: NotificationType.Success,
    };
  }

  if (payload.action === "PAYOUT_PAID") {
    return {
      title: "£___,___.__ paid",
      description: "The money is in your account and ready to use.",
      created: notification?.event?.created!,
      type: NotificationType.Success,
    };
  }

  if (payload.action === "PAYOUT_REJECTED") {
    return {
      title: "Payout rejected",
      created: notification?.event?.created!,
      type: NotificationType.Warning,
    };
  }

  if (payload.action === "PAYOUT_CANCELLED") {
    return {
      title: "Payout cancelled",
      created: notification?.event?.created!,
      type: NotificationType.Error,
    };
  }

  //   "PAYOUT_CREATED"
  //   "PAYOUT_APPROVED"

  return null;
};

const getProofPersonApplicactionNotificationData = (
  payload: AbstractEventVO,
  notification?: NotificationDTO
): NotifcationData | null => {
  //   "PROOF_PERSON_REQUESTED"
  //   "PROOF_PERSON_UPLOADED"
  //   "PROOF_PERSON_APPROVED"
  //   "PROOF_PERSON_REJECTED"

  return null;
};
