import { IMerchantImport } from "../";
import { useImport } from "./useImport";
import { useParseFile } from "./useParseFille";

export const useMerchantImport = (): IMerchantImport => {
  const { parseFile } = useParseFile();
  const { importFile } = useImport();

  return {
    parseFile,
    importFile,
  };
};
