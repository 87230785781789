import { useState, useCallback } from "react";
import { Row } from "antd";
// import { Link } from "react-router-dom";
import { SvgIcon } from "components";
import { ReactComponent as ProfileSvg } from "assets/profile.svg";
import { Styled } from "./styles";
import "./styles.less";
import { ReactComponent as SettingsSvg } from "assets/settings.svg";
import { ReactComponent as ExitSvg } from "assets/exit.svg";
import { useEmployee } from "data-layer/employee";
import { useProfile } from "data-layer/profile";
// import { PlusOutlined } from "@ant-design/icons";
import { history } from "data-layer/../router/AppRouter";

type ProfileMenuScProps = {
  closeMenu?: Function;
};

type ContentProps = ProfileMenuScProps & {
  handleClosePopup: VoidFunction;
  visible: boolean;
};

const Content: React.FC<ContentProps> = (props) => {
  const { visible, closeMenu } = props;
  const { logout } = useEmployee();
  const { selectedProfile } = useProfile();
  // const { profiles, selectProfile } = useProfile();

  return (
    <Styled.Content visible={visible}>
      {/* <Link to="/onboarding-guide" style={{ textDecoration: "none" }}>
        <Styled.AddBusiness>
          <PlusOutlined />
          <span>Add business</span>
        </Styled.AddBusiness>
      </Link> */}

      <Styled.ContentHeader>
        <Styled.SelectedItemTitle>
          {(selectedProfile && selectedProfile.name) || "Create profile"}
        </Styled.SelectedItemTitle>
        {/* <Styled.SelectedItemStatus>Not verified</Styled.SelectedItemStatus> */}
      </Styled.ContentHeader>

      {/* <Styled.List>
        {profiles.map((profile) => (
          <Styled.ListItem
            key={profile.id}
            onClick={() => {
              selectProfile(profile.id!);
              handleClosePopup();
              if (closeMenu && typeof closeMenu === 'function') {
                closeMenu();
              }
            }}
            selected={profile.selected}
          >
            {profile.name}
          </Styled.ListItem>
        ))}
      </Styled.List> */}
      {/*todo popover должен закрываться после клика по дочернему элементу*/}
      <Styled.ContentBody>
        <Styled.ContentGroup>
          <Styled.ContentGroupItem
            onClick={() => {
              history.push("/dashboard/settings");
              if (closeMenu && typeof closeMenu === "function") {
                closeMenu();
              }
            }}
          >
            <span>Settings</span>
            <SvgIcon svg={SettingsSvg} />
          </Styled.ContentGroupItem>

          <Styled.ContentGroupItem onClick={logout}>
            <span>Logout</span>
            <SvgIcon svg={ExitSvg} />
          </Styled.ContentGroupItem>
        </Styled.ContentGroup>
      </Styled.ContentBody>
    </Styled.Content>
  );
};

export const ProfileMenuSc: React.FC<ProfileMenuScProps> = (props) => {
  const { closeMenu } = props;
  const [visible, setVisible] = useState(false);
  const { selectedProfile } = useProfile();

  const handleVisibleChange = () => {
    setVisible(!visible);
  };

  const handleClosePopup = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  return (
    <Styled.Wrapper onClick={handleVisibleChange} active={visible}>
      <Styled.Header active={visible}>
        <Row gutter={20} wrap={false} align="middle">
          <Styled.Icon active={visible}>
            <SvgIcon svg={ProfileSvg} />
          </Styled.Icon>
          <Styled.Title active={visible}>
            {(selectedProfile && selectedProfile.name) || "Create profile"}
          </Styled.Title>
        </Row>
      </Styled.Header>
      <Content
        handleClosePopup={handleClosePopup}
        visible={visible}
        closeMenu={closeMenu}
      />
    </Styled.Wrapper>
  );
};
