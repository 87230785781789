import { useSelector } from "react-redux";
import { IApplication } from "../";
import {
  newApplicationSelector,
  applicationsSelector,
  reviewApplicationSelector,
  rejectedApplicationSelector,
  applicationPartnerCountDataSelector,
} from "../selectors";
import { usePersistApplication } from "./usePersistApplication";
import { useFinishApplication } from "./useFinishApplication";
import { useGetCountApplication } from "./useGetApplicationCount";

export const useApplication = (): IApplication => {
  const newApplication = useSelector(newApplicationSelector);
  const reviewApplication = useSelector(reviewApplicationSelector);
  const rejectedApplication = useSelector(rejectedApplicationSelector);
  const applications = useSelector(applicationsSelector);
  const applicationCount = useSelector(applicationPartnerCountDataSelector);

  const { persistApplication } = usePersistApplication();
  const { finishApplication } = useFinishApplication();
  const { getApplicationCount } = useGetCountApplication();

  const haveApplications = applications.length > 0;

  return {
    applications,
    newApplication,
    reviewApplication,
    rejectedApplication,
    persistApplication,
    finishApplication,
    haveApplications,
    getApplicationCount,
    applicationCount,
  };
};
