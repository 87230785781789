import { Formik } from "formik";
import { Styled } from "./styles";
import { Input } from "formik-antd";
import { Button, FormikPatchTouched } from "components";
import { getFormikError } from "utils/getFormikError";
import { FormItem, FormItemProps } from "components";
import { useSubmit } from "./useSubmit";
import { useMerchant } from "data-layer/merchant";
import { settlementDetailsSchema } from "./settlementDetailsSchema";
import { LockOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { ConfirmPasswordSc } from "scenarios/common/ConfirmPasswordSc";
import { useUpdate } from "./useUpdate";

export const FormFormItem: React.FC<FormItemProps> = (props) => {
  const { children, ...rest } = props;

  return <FormItem {...rest}>{children}</FormItem>;
};

export type SettlementDetailsValues = {
  bankAccountHolderName: string;
  sortCode: string;
  bankAccountNumber: string;
};

export const Settlement: React.FC = () => {
  const { onSubmit } = useSubmit();
  const { me } = useMerchant();
  useUpdate();

  const initialValues: SettlementDetailsValues = {
    bankAccountHolderName:
      me?.settlementDetailsData?.bankAccountHolderName || "",
    sortCode: me?.settlementDetailsData?.sortCode || "",
    bankAccountNumber: me?.settlementDetailsData?.bankAccountNumber || "",
  };

  const isTheSameValues = (values: SettlementDetailsValues): boolean => {
    return (
      me?.settlementDetailsData?.bankAccountHolderName ===
        values.bankAccountHolderName &&
      me?.settlementDetailsData?.sortCode === values.sortCode &&
      me?.settlementDetailsData?.bankAccountNumber === values.bankAccountNumber
    );
  };

  return (
    <Styled.Wrapper>
      <Formik
        initialValues={initialValues}
        validationSchema={settlementDetailsSchema}
        validateOnChange={true}
        validateOnMount={true}
        enableReinitialize
        onSubmit={(values) => {}}
      >
        {({ values, errors, touched, handleSubmit, isValid }) => {
          return (
            <>
              <FormikPatchTouched />

              <Styled.Title>Bank details</Styled.Title>

              <Styled.Subtitle>
                Where would you like us to deposit your money?
              </Styled.Subtitle>

              <FormFormItem
                label="Account holder name"
                error={getFormikError("bankAccountHolderName", errors, touched)}
              >
                <Input name="bankAccountHolderName" placeholder="John Doe" />
              </FormFormItem>

              <FormFormItem
                label="Sort code / BIC / SWIFT"
                error={getFormikError("sortCode", errors, touched)}
              >
                <Input name="sortCode" placeholder="123456 / BKENGB54" />
              </FormFormItem>

              <FormFormItem
                label="Account number / IBAN"
                error={getFormikError("bankAccountNumber", errors, touched)}
              >
                <Input name="bankAccountNumber" placeholder="12345678 / XX12345678901234567890..." />
              </FormFormItem>

              {/* <Styled.Button>
                <Button block type="primary" onClick={handleToggle}>
                  Save
                </Button>
              </Styled.Button> */}

              <ConfirmPasswordSc
                values={values}
                handleSubmit={(password, cb) =>
                  onSubmit(
                    {
                      ...values,
                      password,
                    },
                    cb
                  )
                }
                render={(props) => {
                  return (
                    <Styled.Button>
                      <Button
                        block
                        type="primary-transparent"
                        disabled={!isValid || isTheSameValues(values)}
                        onClick={props.handleToggle}
                      >
                        Save
                      </Button>
                    </Styled.Button>
                  );
                }}
              />

              <Styled.Description>
                <Row gutter={15} align="top" wrap={false}>
                  <Col>
                    <Styled.Svg>
                      <LockOutlined />
                    </Styled.Svg>
                  </Col>
                  <Col>
                    <span>
                      All sensitive data is encrypted, using advanced
                      cryptographic algorithms, and protected by SSL
                      certificates.
                    </span>
                  </Col>
                </Row>
              </Styled.Description>
            </>
          );
        }}
      </Formik>
    </Styled.Wrapper>
  );
};
