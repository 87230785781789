import { useCallback, useState } from "react";

type UseTabs = (active: number) => {
  active: number;
  handleChangeTab: (key: number) => void;
};

export const useTabs: UseTabs = (_active: number) => {
  const [active, setActive] = useState(_active);

  const handleChangeTab = useCallback(
    (key: number) => {
      setActive(key);
    },
    [setActive]
  );

  return {
    active,
    handleChangeTab,
  };
};
