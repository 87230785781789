import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

const Content = styled.div`
  flex-grow: 1;

  padding: 40px 25px 0;

  @media (max-width: 991px) {
    padding: 40px 0 70px;
  }
`;

const Title = styled.p`
  margin-bottom: 15px;
  padding: 0 20px 2px;

  font-family: ${Fonts.NexaRegular};
  font-size: 30px;

  border-bottom: 2px solid #e4e3e6;

  color: ${Colors.Primary};

  @media (max-width: 1199px) {
    padding: 0 15px 10px;
    font-size: 20px;
    line-height: 122%;
  }

  @media (max-width: 991px) {
    padding: 0 0 10px;
  }

  @media (max-width: 767px) {
    padding-bottom: 5px;
    font-size: 18px;
  }
`;

const Description = styled.div`
  max-width: 850px;

  margin-bottom: 55px;
  padding: 0 20px 15px;

  font-family: ${Fonts.NexaRegular};
  font-size: 20px;
  line-height: 28px;

  border-bottom: 2px solid #e4e3e6;

  @media (max-width: 1199px) {
    margin-bottom: 32px;
    padding: 0 15px 5px;
    font-size: 16px;
    line-height: 25px;
  }

  @media (max-width: 991px) {
    padding: 0 0 5px;
  }

  p {
    margin-bottom: 28px;

    @media (max-width: 1199px) {
      margin-bottom: 25px;
    }
  }

  a {
    color: #2950af;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
`;

export const Styled = {
  Content,
  Title,
  Description,
};
