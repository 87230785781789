import { AppInputs } from "generated/modelsWrapper/AppInputs";
import * as Yup from "yup";

export const businessActivitiesSchema = Yup.object().shape({
  [AppInputs.EXPECTED_TURNOVER]: Yup.string().required("Required").oneOf([
    'SMALL',
    'MIDDLE',
    'LARGE',
    'EXTRA_LARGE',
  ]),
  [AppInputs.PAYMENT_HISTORY]: Yup.string().required("Required").oneOf([
    'NONE',
    'SMALL',
    'MIDDLE',
    'LARGE',
    'EXTRA_LARGE',
  ]),
  [AppInputs.AVERAGE_TX_VALUE]: Yup.string().required("Required").oneOf([
    'SMALL',
    'MIDDLE',
    'LARGE',
  ]),
});
