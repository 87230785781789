import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { showNotificationThunk } from "../thunks";

type UseShowNotification = () => {
  showNotification: (payload: unknown) => void;
};

export const useShowNotification: UseShowNotification = () => {
  const dispatch = useDispatch();

  const showNotification = useCallback((payload) => {
    dispatch(showNotificationThunk(payload));
  }, [dispatch]);

  return {
    showNotification,
  };
};
