import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

const Wrapper = styled.div`
  max-width: 100%;

  margin: 0 auto;

  border-radius: 10px;
`;

const Icon = styled.div`
  position: relative;

  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 65px;
  height: 65px;

  margin: 0 auto 21px;

  font-size: 25px;

  border-radius: 15px;

  background: #fff;

  box-shadow: 0px 3px 3px #0000001a;

  &::before {
    z-index: -1;

    content: "";

    position: absolute;

    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;

    background-color: #0000000d;

    border-radius: 10px;
  }
`;

const Fields = styled.div`
  margin-bottom: 20px;
`

const Title = styled.p`
  max-width: 300px;

  margin: 0 auto;
  margin-bottom: 18px;

  font-family: ${Fonts.NexaRegular};
  font-size: 25px;
  line-height: 32px;
  text-align: center;

  color: ${Colors.Primary};
`;

const FeesTitle = styled.p`
  
  margin: 0 auto;
  margin-bottom: 18px;

  font-family: ${Fonts.NexaRegular};

  font-size: 14px;
  line-height: 18px;
  
  //font-size: 15px;
  //line-height: 25px;
  text-align: center;

  color: ${Colors.Primary};
`;

const DividerTitle = styled.p`
  
  margin: 0 auto;
  //margin-bottom: 18px;

  font-family: ${Fonts.NexaRegular};

  font-size: 14px;
  //line-height: 18px;
  
  //font-size: 15px;
  //line-height: 25px;
  text-align: center;

  color: ${Colors.Primary};
`;

const Switcher = styled.div`

  ::after{
    background-color: #2950af;
  }
  // ::selection {
  //   color: @text-color-inverse;
  //   background: ${Colors.Primary};
  // }
  //margin: 0 auto;
  margin-bottom: 40px;
  //font-family: ${Fonts.NexaRegular};
  //height: 50px;
  //font-size: 25px;
  //background-color: ${Colors.Primary};
  color: ${Colors.Primary};
`;

const ContentTitle = styled.p`
  margin-bottom: 20px;

  font-family: ${Fonts.NexaRegular};
  font-size: 20px;
  line-height: 18px;

  color: #283549;
`;

const InFormTitle = styled.p`
  margin-bottom: 45px;

  font-family: ${Fonts.NexaRegular};
  font-size: 20px;
  line-height: 18px;

  color: #283549;
`;

const Gray = styled.div`

  font-family: ${Fonts.NexaRegular};
  font-size: 14px;
  line-height: 20px;

  color: ${Colors.GrayBlue};
  
  
`


export const Styled = {
  Wrapper,
  Icon,
  Fields,
  Title,
  ContentTitle,
  InFormTitle,
  FeesTitle,
  DividerTitle,
  Switcher,
  Gray,
};
