import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PartnerCountDTO, PartnerDTO, PartnerTurnoverPercentageDTO } from "generated";

export type PartnerInitialState = {
  isMeLoading: boolean;
  data: PartnerDTO | null;

  turnoverPercentageData: PartnerTurnoverPercentageDTO | null;
  isTurnoverPercentageLoading: boolean;

  statsData: PartnerCountDTO | null;
  isStatsLoading: boolean;

  invitedPartnerData: PartnerDTO | null;
};

const initialState: PartnerInitialState = {
  isMeLoading: false,
  data: null,
  isTurnoverPercentageLoading: false,
  isStatsLoading: false,
  turnoverPercentageData: null,
  statsData: null,
  invitedPartnerData: null,
};

export const partnerSlice = createSlice({
  name: "partner",
  initialState: initialState,
  reducers: {
    meRequest: (state) => {
      state.isMeLoading = true;
    },
    meSuccess: (state, action: PayloadAction<PartnerDTO>) => {
      state.isMeLoading = false;
      state.data = action.payload;
    },
    meFailure: (state) => {
      state.isMeLoading = false;
    },
    turnoverPercentageRequest: (state) => {
      state.isTurnoverPercentageLoading = true;
    },
    turnoverPercentageSuccess: (
      state,
      action: PayloadAction<PartnerTurnoverPercentageDTO>
    ) => {
      state.isTurnoverPercentageLoading = false;
      state.turnoverPercentageData = action.payload;
    },
    turnoverPercentageFailure: (state) => {
      state.isTurnoverPercentageLoading = false;
    },
    statsRequest: (state) => {
      state.isStatsLoading = true;
    },
    statsSuccess: (state, action: PayloadAction<PartnerCountDTO>) => {
      state.isStatsLoading = false;
      state.statsData = action.payload;
    },
    statsFailure: (state) => {
      state.isStatsLoading = false;
    },
    updateMeSuccess: (state, action: PayloadAction<PartnerDTO>) => {
      state.data = action.payload;
    },
    updatePartnerSuccess: (state, action: PayloadAction<PartnerDTO>) => {
      state.data = action.payload;
    },

    setInvitedPartnerData: (state, action: PayloadAction<PartnerDTO>) => {
      state.invitedPartnerData = action.payload;
    }
  },
});

export const partnerActions = partnerSlice.actions;
export const partnerReducer = partnerSlice.reducer;
