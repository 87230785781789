import { Link } from "react-router-dom";
import { Welcome } from "./components";
import { Styled } from "./styles";
import { PageLogo } from "ui";
import { Role } from "data-layer/common/testRoleSlice";
import { useEmployee } from "data-layer/employee";

type OnboardingGuideScProps = {};

export const OnboardingGuideSc: React.FC<OnboardingGuideScProps> = () => {
  const { employee } = useEmployee();

  return (
    <Styled.Wrapper>
      <Styled.Background />
      <Styled.Content>
        <Styled.ContentContainer>
          <Link to="/dashboard">
            <PageLogo role={Role.Merchant} partnerId={employee?.user?.inviterPartnerId} />
          </Link>

          <Welcome />
        </Styled.ContentContainer>
      </Styled.Content>
    </Styled.Wrapper>
  );
};
