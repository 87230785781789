import { useNotifications } from "data-layer/notifications";
import { useEffect } from "react";

type UseGetNotifications = () => void;

export const useGetNotifications: UseGetNotifications = () => {
  const { getNotifications } = useNotifications();

  const onMessage = (event: MessageEvent<any>): void => {
    console.log('Received a message from service worker: ', event.data);

    if (event.data.type === "FB_MESSAGE_FROM_WORKER") {
      getNotifications({
        limit: 20,
        offset: 0,
      });
    }
  };

  useEffect(() => {
    getNotifications({
      limit: 20,
      offset: 0,
    });
  }, []);

  useEffect(() => {
    navigator.serviceWorker && navigator.serviceWorker.addEventListener("message", onMessage);

    return () => {
      navigator.serviceWorker && navigator.serviceWorker.removeEventListener("message", onMessage);
    };
  }, [getNotifications]);
};
