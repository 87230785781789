import styled from "styled-components";
import { Fonts } from "styles/fonts";

const Field = styled.div`
  position: relative;

  display: flex;
  align-items: center;

  height: 50px;

  padding-left: 20px;
  padding-right: 50px;

  border-bottom: 1px solid rgba(0, 0, 0, 0.08);

  background-color: #fff;
  color: #2950af;

  @media (max-width: 1199px) {
    height: 40px;
  }

  a {
    text-decoration: none;
  }
`;

const FieldText = styled.div`
  max-width: 350px;
  padding-top: 5px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-family: ${Fonts.NexaRegular};
  font-size: 16px;
  line-height: 120%;

  a {
    color: #4D4D4D;
  }

  @media (max-width: 1599px) {
    max-width: 250px;
  }
`

const Icon = styled.div`
  position: absolute;

  right: 15px;
  top: 15px;
  
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 24px;

  color: #000000;

  cursor: pointer;

  @media (max-width: 1199px) {
    width: 40px;
    height: 40px;

    font-size: 20px;
  }
`;

export const Styled = {
  Field,
  FieldText,
  Icon,
};
