/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CountryDTO } from './CountryDTO';

export type CRMDTO = {
    companyName?: string;
    country?: CountryDTO;
    crmStatus?: CRMDTO.crmStatus;
    partnerName?: string;
    userEmail?: string;
    userId?: number;
};

export namespace CRMDTO {

    export enum crmStatus {
        APPLICATION_APPROVED = 'APPLICATION_APPROVED',
        APPLICATION_REJECTED = 'APPLICATION_REJECTED',
        APPLICATION_SUBMITTED = 'APPLICATION_SUBMITTED',
        FIRST_EMPLOYEE_SETUP_DONE = 'FIRST_EMPLOYEE_SETUP_DONE',
        FIRST_PAYOUT_DONE = 'FIRST_PAYOUT_DONE',
        FIRST_TRANSACTION_DONE = 'FIRST_TRANSACTION_DONE',
        INVITED = 'INVITED',
        NONE = 'NONE',
        SIGNED_UP = 'SIGNED_UP',
    }


}
