import { Formik } from "formik";
import { Col, Row } from "antd";
import React from "react";
import { Styled as OnboardingStyled } from "../../onboardingStyles";
import { Input, DatePicker } from "formik-antd";
import { AppInputs } from "generated/modelsWrapper/AppInputs";
import { OnboardingFormItem } from "../OnboardingFormItem";
import { personalDetailsSchema } from "common/onboarding/schema";
import { StepProps, SoloTraderStepProps } from "../../OnboardingSc";
import { usePersist } from "../../usePersist";
import { Button, FormikPatchTouched } from "components";
import { getFormikError } from "utils/getFormikError";
import { DATE_FORMAT, MASKED } from "utils/datePickerMask";
import { PhoneInput } from "components/PhoneInput";

export const PersonalDetailsStep: React.FC<SoloTraderStepProps & StepProps> = (
  props
) => {
  const { persist } = usePersist();
  const { initData, prevStep, nextStep } = props;

  return (
    <div>
      <OnboardingStyled.Title>Personal details</OnboardingStyled.Title>
      <Formik
        initialValues={initData}
        validationSchema={personalDetailsSchema}
        validateOnChange={true}
        validateOnMount={true}
        enableReinitialize
        onSubmit={(values) => {
          persist(values, nextStep);
        }}
      >
        {({ errors, touched, handleSubmit, isValid, setFieldValue }) => {
          return (
            <>
              <FormikPatchTouched />

              <OnboardingStyled.Content margin="small">
                <Row>
                  <Col span={24}>
                    <OnboardingFormItem
                      label="Legal name of person"
                      error={getFormikError(
                        AppInputs.PERSON_FIRST_NAME,
                        errors,
                        touched
                      )}
                    >
                      <Input
                        name={AppInputs.PERSON_FIRST_NAME}
                        placeholder="First name"
                      />
                    </OnboardingFormItem>
                  </Col>
                  <Col span={24}>
                    <OnboardingFormItem
                      label={null}
                      error={getFormikError(
                        AppInputs.PERSON_LAST_NAME,
                        errors,
                        touched
                      )}
                    >
                      <Input
                        name={AppInputs.PERSON_LAST_NAME}
                        placeholder="Last name"
                      />
                    </OnboardingFormItem>
                  </Col>
                  <Col span={24}>
                    <OnboardingFormItem
                      label="Date of birth"
                      error={getFormikError(
                        AppInputs.PERSON_DOB,
                        errors,
                        touched
                      )}
                    >
                      <DatePicker
                        name={AppInputs.PERSON_DOB}
                        placeholder="DD.MM.YYYY"
                        format={DATE_FORMAT}
                        onKeyDown={(
                          event: React.KeyboardEvent<HTMLInputElement>
                        ) => {
                          const input = event.target as HTMLInputElement;
                          input.value = MASKED.resolve(input.value);
                        }}
                        picker="date"
                      />
                    </OnboardingFormItem>
                  </Col>
                  <Col span={24}>
                    <OnboardingFormItem
                      label="Email"
                      error={getFormikError(
                        AppInputs.PERSON_EMAIL,
                        errors,
                        touched
                      )}
                    >
                      <Input
                        name={AppInputs.PERSON_EMAIL}
                        placeholder="example@user.com"
                      />
                    </OnboardingFormItem>
                  </Col>
                  <Col span={24}>
                    <OnboardingFormItem
                      label="Phone number"
                      error={getFormikError(
                        AppInputs.PERSON_PHONE_NUMBER,
                        errors,
                        touched
                      )}
                    >
                      <PhoneInput
                        value={initData.PERSON_PHONE_NUMBER}
                        country={"gb"}
                        onChange={(value: any) =>
                          setFieldValue(AppInputs.PERSON_PHONE_NUMBER, value)
                        }
                      />
                    </OnboardingFormItem>
                  </Col>
                  <Col span={24}>
                    <OnboardingFormItem
                      label="Home address"
                      error={getFormikError(
                        AppInputs.PERSON_ADDRESS_LINE_1,
                        errors,
                        touched
                      )}
                    >
                      <Input
                        name={AppInputs.PERSON_ADDRESS_LINE_1}
                        placeholder="E.g. 'CR0 3RL' or '36 Factory Lane'"
                      />
                    </OnboardingFormItem>
                  </Col>
                  <Col span={24}>
                    <OnboardingFormItem
                      label={null}
                      error={getFormikError(
                        AppInputs.PERSON_ADDRESS_LINE_2,
                        errors,
                        touched
                      )}
                    >
                      <Input name={AppInputs.PERSON_ADDRESS_LINE_2} />
                    </OnboardingFormItem>
                  </Col>
                  <Col span={24}>
                    <OnboardingFormItem
                      label="Town/City"
                      error={getFormikError(
                        AppInputs.PERSON_CITY,
                        errors,
                        touched
                      )}
                    >
                      <Input name={AppInputs.PERSON_CITY} />
                    </OnboardingFormItem>
                  </Col>
                  <Col span={24}>
                    <OnboardingFormItem
                      label="Postcode"
                      error={getFormikError(
                        AppInputs.PERSON_POSTAL_CODE,
                        errors,
                        touched
                      )}
                    >
                      <Input name={AppInputs.PERSON_POSTAL_CODE} />
                    </OnboardingFormItem>
                  </Col>
                </Row>
              </OnboardingStyled.Content>

              <OnboardingStyled.Footer>
                <Row gutter={{ xs: 10, sm: 10, md: 25 }}>
                  <Col>
                    <Button
                      type="secondary-transparent"
                      onClick={() => prevStep()}
                    >
                      Back
                    </Button>
                  </Col>
                  <Col flex={1}>
                    <Button
                      type="primary-transparent"
                      onClick={() => handleSubmit()}
                      disabled={!isValid}
                      block
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </OnboardingStyled.Footer>
            </>
          );
        }}
      </Formik>
    </div>
  );
};
