import { Col } from "antd";
import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "../../../styles/fonts";

const MainRow = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1300px) {
    flex-direction: column;
  }
`

const MainCol = styled.div`
  width: calc(67% - 12.5px);

  @media (max-width: 1300px) {
    width: 100%;
    margin-bottom: 16px;
  }
`

const InfoCol = styled.div`
  width: calc(33% - 12.5px);

  @media (max-width: 1300px) {
    width: 100%;
  }
`

const LeftCol = styled(Col)``;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 402px;
  height: 50px;

  padding: 5px 23px 5px 16px;

  border: 2px solid ${Colors.Primary};
  border-radius: 30px;

  color: ${Colors.Primary};

  cursor: pointer;

  @media (max-width: 1550px) {
    width: 246px;
    height: 40px;
    padding: 5px 16px;
  }

  @media (max-width: 1300px) {
    margin-top: auto;
  }

  @media (max-width: 499px) {
    width: 100%;
  }

  svg {
    font-size: 25px;

    @media (max-width: 1550px) {
      font-size: 20px;
    }
  }

  &:hover {
    color: white;
    background: #fe8e75;

    svg {
      color: #fff;
    }
  }
`;

const ButtonWrapperText = styled.span`
  display: inline-flex;
  padding-top: 4px;

  font-size: 20px;
  line-height: 22px;
  font-family: ${Fonts.NexaRegular};

  @media (max-width: 1550px) {
    font-size: 17px;
    line-height: 23px;
  }
`

const InviteWrapper = styled.div`
  position: relative;
`

const BulkUploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Info = styled.div`
  position: relative;
  height: 100%;
  padding-bottom: 20px;

  @media (max-width: 1300px) {
    padding-bottom: 0;
  }

  .ant-row {
    @media (max-width: 1300px) {
      justify-content: space-between;
    }
  }

  .ant-col {
    @media (max-width: 1300px) {
      width: calc(50% - 23px);
      max-width: calc(50% - 23px);
    }

    @media (max-width: 767px) {
      width: 100%;
      max-width: 100%;
    }
  }
`

const InfoTitle = styled.div`
  margin-bottom: 14px;

  font-family: ${Fonts.NexaRegular};
  font-size: 25px;
  line-height: 160%;

  color: ${Colors.Secondary};

  @media (max-width: 1550px) {
    margin-bottom: 5px;
    font-size: 20px;
    line-height: 25px;
  }

  @media (max-width: 767px) {
    max-width: 150px;
  }
`

const InfoDescription = styled.div`
  max-width: 340px;
  margin-bottom: 30px;

  font-family: ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 135%;

  color: ${Colors.GrayBlue};

  @media (max-width: 1550px) {
    max-width: 283px;
    margin-bottom: 45px;
    font-size: 14px;
    line-height: 20px;
  }

  @media (max-width: 767px) {
    margin-bottom: 30px;
  }
`

const InfoMerchantsLink = styled.div`
  position: absolute;
  top: 6px;
  right: 0;
  width: 215px;

  @media (max-width: 1550px) {
    top: -2px;
    width: 160px;
  }
`

const InfoBg = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;

  @media (max-width: 1300px) {
    display: none;
  }
`

export const GeneralStyled = {
  MainRow,
  MainCol,
  InfoCol,

  LeftCol,
  ButtonWrapper,
  ButtonWrapperText,

  Info,

  InviteWrapper,
  BulkUploadWrapper,

  InfoTitle,
  InfoDescription,
  InfoMerchantsLink,
  InfoBg,
};
