import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

const Type = styled.span`
  font-family: ${Fonts.NexaRegular};
`;

const AmountTitle = styled.span`
  display: flex;
  justify-content: flex-end;
`;

const AmountText = styled.span`
  display: flex;
  justify-content: flex-end;

  font-family: ${Fonts.NexaRegular};

  color: ${Colors.Primary};
`;

const AmountTextCurrency = styled.span`
  margin-right: 3px;
`

const Arrow = styled.span`
  display: inline-flex;

  width: 10px;
  height: 10px;

  margin-left: 50px;
  margin-right: 10px;

  border-bottom: 2px solid ${Colors.Primary};
  border-right: 2px solid ${Colors.Primary};

  transform: rotate(-45deg);
`;

const TableWrapper = styled.div`
  tr {
    cursor: pointer;
  }
`

export const Styled = {
  TableWrapper,
  Type,
  AmountTitle,
  AmountText,
  AmountTextCurrency,
  Arrow,
};
