import { Col, Row } from "antd";
import styled from "styled-components";
import { Fonts } from "styles/fonts";
import { StyledCss } from "../../styles";

type ActiveProp = {
  active?: boolean;
};

const HeaderRow = styled(Row)`
  margin-bottom: 34px;

  @media (max-width: 767px) {
    justify-content: flex-start;

    width: 100%;

    margin-bottom: 0px;
  }
`

const Title = styled.p<ActiveProp>`
  ${StyledCss.Title}
  margin-bottom: 0;

  @media (max-width: 767px) {
    margin-bottom: ${(props) => (props.active ? "21px" : "0")};

    font-size: 25px;
    line-height: 1em;

    cursor: pointer;
  }

  &::after {
    @media (max-width: 767px) {
      content: '';

      position: absolute;

      top: ${props => props.active ? '7px' : '3px'};
      right: 5px;

      width: 12px;
      height: 12px;

      border-right: 3px solid #3C3C434D;
      border-bottom: 3px solid #3C3C434D;

      transform: ${props => props.active ? 'rotate(-135deg)' : 'rotate(45deg)'};
    }
  }
`;

const Content = styled.div<ActiveProp>`
  @media (max-width: 767px) {
    display: ${(props) => (props.active ? "block" : "none")};
  }
`;

const List = styled.ul`
  list-style-type: none;

  margin-bottom: 37px;
  padding-left: 0;

  font-family: ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 22px;

  color: #000;

  @media (max-width: 767px) {
    padding-top: 34px;

    font-size: 16px;
    line-height: 1em;
  }

  li {
    padding: 11px 0 9px;

    font-family: ${Fonts.NexaRegular};
    text-decoration: none;

    border-bottom: 1px solid #c4c3c6;

    @media (max-width: 767px) {
      padding: 12px 0 11px;
    }
  }
`;

const TitleCol = styled(Col)`
  @media (max-width: 767px) {
    width: 100%;
  }
`

const ButtonCol = styled(Col)`
  text-align: right;

  @media (max-width: 767px) {
    position: absolute;
    top: 17px;
    left: 180px;
  }
`

export const Styled = {
  HeaderRow,
  Title,
  Content,
  List,
  TitleCol,
  ButtonCol,
};
