/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { network } from 'services/network';

export class BasicErrorControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * error
     * @returns any OK
     * @throws ApiError
     */
    public errorUsingGet(): CancelablePromise<Record<string, any>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/error',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * error
     * @returns any OK
     * @throws ApiError
     */
    public errorUsingPut(): CancelablePromise<Record<string, any>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'PUT',
                url: '/error',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * error
     * @returns any OK
     * @throws ApiError
     */
    public errorUsingPost(): CancelablePromise<Record<string, any>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/error',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * error
     * @returns any OK
     * @throws ApiError
     */
    public errorUsingDelete(): CancelablePromise<Record<string, any>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'DELETE',
                url: '/error',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                },
            })
        });
    }

    /**
     * error
     * @returns any OK
     * @throws ApiError
     */
    public errorUsingOptions(): CancelablePromise<Record<string, any>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'OPTIONS',
                url: '/error',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                },
            })
        });
    }

    /**
     * error
     * @returns any OK
     * @throws ApiError
     */
    public errorUsingHead(): CancelablePromise<Record<string, any>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'HEAD',
                url: '/error',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                },
            })
        });
    }

    /**
     * error
     * @returns any OK
     * @throws ApiError
     */
    public errorUsingPatch(): CancelablePromise<Record<string, any>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'PATCH',
                url: '/error',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                },
            })
        });
    }

}