import React from "react";
import Icon from "@ant-design/icons";
import { Styled } from "./styled";
import { Spin } from "antd";

interface ISigningInItem {
  svg: any;
  title?: string | number;
  text?: string | number;
  isLoading?: boolean;
}

export const UserCountItem: React.FC<ISigningInItem> = ({
  svg,
  title,
  text,
  isLoading,
}) => {
  return (
    <>
      <Styled.IconWrapper>
        <Icon style={{ fontSize: "24px" }} component={svg} />
      </Styled.IconWrapper>

      <Styled.CountTitle>
        {isLoading ? (
          <Spin style={{ textAlign: "left" }} />
        ) : title !== undefined && title !== null ? (
          title
        ) : (
          "Unknown"
        )}
      </Styled.CountTitle>
      <Styled.StatusLabel>{text}</Styled.StatusLabel>
    </>
  );
};
