import { CSSProperties } from "react";

import { Styled } from "./styles";

export type Size = "small" | "medium" | "large";

export type PaperProps = {
  style?: CSSProperties;
  size?: Size;
};

export const Paper: React.FC<PaperProps> = (props) => {
  const { style, children, ...restProps } = props;

  return (
    <Styled.Wrapper style={style} {...restProps}>
      {children}
    </Styled.Wrapper>
  );
};
