import { employeeSlice } from "data-layer/employee/slice";
import { ApiError } from "generated";
import { ApiError as ApiSunsetError } from "generatedSunset";
import { ApiError as ApiCrateError } from "generatedCrate";
import { store } from "data-layer/store";
import { history } from "data-layer/../router/AppRouter";
export * from "./appClient";

class Network {
  async request(call: any) {
    try {
      const result = await call();
      return result;
    } catch (e) {
      if (e instanceof ApiError || e instanceof ApiSunsetError || e instanceof ApiCrateError) {
        this.handleApiError(e);
      }

      throw e;
    }
  }

  private handleApiError = (e: ApiError | ApiSunsetError | ApiCrateError): void => {
    // authorization error
    if (e.status === 401) {
      localStorage.removeItem("employee");
      store.dispatch(employeeSlice.actions.logout());
      history.push("/signin");
    }
  };
}

export const network = new Network();
