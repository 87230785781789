import { api } from "./api";
import { CancelablePromise } from "generated/core/CancelablePromise";
import type { ProfileDTO } from "generated/models/ProfileDTO";
import { CreateUpdateProfileDTO } from "generated/models/CreateUpdateProfileDTO";
import { ResponseEntity } from "generated/models/ResponseEntity";
import { ApplicationDTO } from "generated";

export interface IProfileApi {
  getProfile: () => CancelablePromise<Array<ProfileDTO>>;
  createProfile: (body: CreateUpdateProfileDTO) => CancelablePromise<ProfileDTO | any>;
  editProfile: (id: number, requestBody?: CreateUpdateProfileDTO) => CancelablePromise<ProfileDTO | any>;
  selectProfile: (profileId: number) => CancelablePromise<ResponseEntity | any>;
  getPartherProfile: (
    states: ApplicationDTO.state[],
    partnerId: number,
    limit: number,
    offset: number,
    from?: string,
    to?: string,
    companyName?: string,
  ) => CancelablePromise<Array<ProfileDTO>>
}

class ProfileApi {
  api: IProfileApi = api;

  constructor() {
    if (process.env.REACT_APP_MOCK_ENABLED === "true") {
      this.api = api;
      return;
    }

    this.api = api;
  }
}

export const profileApi = new ProfileApi();
