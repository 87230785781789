import { loaderSlice } from "data-layer/common/loaderSlice";
import { AppDispatch } from "data-layer/store/store";
import { handleNetworkError } from "utils/handleNetworkError";
import { transactionsApi } from "./api";
import { transactionsSlice } from "./slice";
import {
  TransactionParams,
  TransactionParamsAmount,
  TransactionParamsAverage,
} from "./types/TransactionParams";

export const getTransactionsThunk = (params: TransactionParams) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());
    console.log('params', params)

    try {
      dispatch(transactionsSlice.actions.getTransactionsRequest());
      const { from, to, types, limit, offset } = params;
      const result = await transactionsApi.api.getTransactions(
        limit,
        offset,
        types,
        undefined,
        undefined,
        undefined,
        undefined,
        from,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        to
      );

      dispatch(transactionsSlice.actions.getTransactionsSuccess(result));
    } catch (e) {
      handleNetworkError(e, "Transactions loading error");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
      dispatch(transactionsSlice.actions.getTransactionsFailure());
    }
  };
};

export const getTransactionsAmountThunk = (params: TransactionParamsAmount) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(transactionsSlice.actions.getTransactionsAmountRequest());
      const { merchantIds, from, to, aggregateBy, aggregateTime } = params;
      const result = await transactionsApi.api.getTransactionsAmount(
        merchantIds,
        from,
        to,
        aggregateBy,
        aggregateTime
      );

      dispatch(transactionsSlice.actions.getTransactionsAmountSuccess(result));
    } catch (e) {
      handleNetworkError(e, "Transactions loading error");
    } finally {
      dispatch(transactionsSlice.actions.getTransactionsAmountFailure());
    }
  };
};

export const getTransactionsAverageThunk = (
  params: TransactionParamsAverage
) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(transactionsSlice.actions.getTransactionsAverageRequest());
      const { merchantIds, from, to, aggregateBy, aggregateTime } = params;
      const result = await transactionsApi.api.getTransactionsAverage(
        merchantIds,
        from,
        to,
        aggregateBy,
        aggregateTime
      );

      dispatch(transactionsSlice.actions.getTransactionsAverageSuccess(result));
    } catch (e) {
      handleNetworkError(e, "Transactions loading error");
    } finally {
      dispatch(transactionsSlice.actions.getTransactionsAverageFailure());
    }
  };
};
