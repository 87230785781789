import { useSelector } from "react-redux";
import { ISys18 } from "../";
import { sys18Selector } from "../selectors";
import { useGetSys18 } from "./useGetSys18";

export const useSys18 = (): ISys18 => {
  const { getSys18 } = useGetSys18();
  const data = useSelector(sys18Selector);

  return {
    getSys18,
    data,
  };
};
