import { AppState } from "../store";

export const notificationsDataSelector = (state: AppState) =>
  state.notifications.notifications;

export const pendingToReadSelector = (state: AppState) =>
  state.notifications.pendingToRead;

export const haveUnreadNotificationSelector = (state: AppState) =>
  state.notifications.haveUnreadNotification;
