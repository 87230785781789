import { Styled } from "./styles";
import { Button } from "components";
import { Link } from "react-router-dom";
import { Typography, PageLogo } from "ui";

export const AccessDeniedSc: React.FC = () => {
  return (
    <Styled.Wrapper>
      <Styled.Background />

      <Styled.Content>
        <Styled.ContentContainer>
          <PageLogo />
          <Typography.H1>Access Denied</Typography.H1>

          <Styled.Description>
            You don’t have access to the Seller Dashboard. Go back to the
            sign-in page or contact <a href="mailto:help@dapio.com">support</a> for assistance.
          </Styled.Description>

          <Styled.SubmitButton>
            <Link to="/signin">
              <Button type="primary-transparent" block>
                Back to Sign in
              </Button>
            </Link>
          </Styled.SubmitButton>
        </Styled.ContentContainer>
      </Styled.Content>
    </Styled.Wrapper>
  );
};
