import { useApplication } from "data-layer/application";
import { TypeOfBusinessSoloTraderExcludedValues } from "data-layer/application/types";
import { useProfile } from "data-layer/profile";
import { CreateUpdateProfileDTO } from "generated";

type UseSoloTraderProfile = () => {
  createSoloTraderProfile: (
    values: TypeOfBusinessSoloTraderExcludedValues,
    nextStep: VoidFunction
  ) => void;
  editSoloTraderProfile: (
    values: TypeOfBusinessSoloTraderExcludedValues,
    nextStep: VoidFunction
  ) => void;
};

export const useSoloTraderProfile: UseSoloTraderProfile = () => {
  const { createProfile, editProfile, selectedProfile } = useProfile();
  const { newApplication } = useApplication();

  const createSoloTraderProfile = (
    values: TypeOfBusinessSoloTraderExcludedValues,
    nextStep: VoidFunction
  ) => {
    createProfile(
      {
        kind: CreateUpdateProfileDTO.kind.SOLE_TRADER,
        name: values.COMPANY_NAME!,
        values,
      },
      nextStep
    );
  };

  const editSoloTraderProfile = (
    values: TypeOfBusinessSoloTraderExcludedValues,
    nextStep: VoidFunction
  ) => {
    editProfile(
      selectedProfile?.id!,
      {
        kind: CreateUpdateProfileDTO.kind.SOLE_TRADER,
        name: values.COMPANY_NAME!,
        values,
      },
      newApplication.id,
      nextStep
    );
  };

  return {
    createSoloTraderProfile,
    editSoloTraderProfile,
  };
};
