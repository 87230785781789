import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { getStatsThunk } from "../thunks";

type UseGetStats = () => {
  getStats: (from: string, to: string) => void;
};

export const useGetStats: UseGetStats = () => {
  const dispatch = useDispatch();

  const getStats = useCallback(
    (from, to) => {
      dispatch(getStatsThunk(from, to));
    },
    [dispatch]
  );

  return {
    getStats,
  };
};
