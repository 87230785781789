import React, { useState } from "react";
import { GeneralStyled } from "../../styled";
import { LinkOutlined } from '@ant-design/icons'
import { SubmitMerchantModalSc } from "scenarios/partner/SubmitMerchantModalSc";

export const InviteButton: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <GeneralStyled.ButtonWrapper onClick={() => setIsModalVisible(true)}>
        <GeneralStyled.ButtonWrapperText>Create invite</GeneralStyled.ButtonWrapperText>
        <LinkOutlined />
      </GeneralStyled.ButtonWrapper>
      <SubmitMerchantModalSc
        visible={isModalVisible}
        onHide={() => setIsModalVisible(false)}
      />
    </>
  );
};
