import { appClient } from "services";
import { IPartnerApi } from "./index";

export const api: IPartnerApi = {
  me: () => {
    return appClient.partnerController.getCurrentUsingGet();
  },
  invite: (body) => {
    return appClient.partnerController.inviteUserUsingPost(body);
  },
  stats: (from, to) => {
    return appClient.partnerController.partnerStatsUsingGet(from, to);
  },
  turnoverPercentage: (from, to) => {
    return appClient.partnerController.partnerTurnoverPercentageUsingGet(
      from,
      to
    );
  },
  updatePartnerSettlement: (token) => {
    return appClient.partnerController.settlementDetailsConfirmUpdateUsingPost(token);
  },
  attemptPartnerSettlement: (body) => {
    return appClient.partnerController.settlementDetailsAttemptUpdateUsingPut(body);
  },
  updatePartner: (body) => {
    return appClient.partnerController.updateUsingPut(body);
  },
  findPartnerById: (id) => {
    return appClient.partnerController.findPartnerByIdUsingGet(id)
  }
};
