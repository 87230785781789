import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { getPartherProfileThunk } from "../thunks";
import { ParterProfileParams } from "../types/ParterProfileParams";

type UseGetPartnerProfile = () => {
  getPartherProfile: (params: ParterProfileParams) => void;
};

export const useGetPartnerProfile: UseGetPartnerProfile = () => {
  const dispatch = useDispatch();

  const getPartherProfile = useCallback(
    (params) => {
      dispatch(getPartherProfileThunk(params));
    },
    [dispatch]
  );

  return {
    getPartherProfile,
  };
};
