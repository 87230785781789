import React from "react";
import { Checkbox, Spin, Row, Col } from "antd";
import { useGetEmployees } from "./hooks/useGetEmployees";
import { useInitParams } from "./hooks/useInitParams";
import { useEmployees } from "data-layer/employees";
import { AddEmployee } from "./components/AddEmployee";
import { TableSc } from "scenarios/common";
import { Styled } from "./styles";
import { Colors } from "styles/colors";
import { useGetEmployeePermissions } from "./hooks/useGetEmployeePermissions";
import { EmployeeDTO, EmployeePermissionDTO } from "generated";
import { useEmployeePermissions } from "data-layer/employee-permissions";
import { splitText } from "utils/splitText";

export const EmployeesSc: React.FC = () => {
  const { filters } = useInitParams();
  useGetEmployees(filters);
  useGetEmployeePermissions();

  const { data, isFetching, togglePermission } = useEmployees();
  const { employeePermissions } = useEmployeePermissions();

  if ((!data || !data.length) && isFetching) {
    return <Spin />;
  }

  if (!filters) {
    return <Spin />;
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Surname",
      dataIndex: "surname",
      key: "surname",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email: string) => (
        <span style={{ color: Colors.Secondary }}>{email}</span>
      ),
    },
    {
      title: "Branch address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Permissions",
      dataIndex: "permissions",
      key: "permissions",
      render: (permissions: EmployeePermissionDTO[] | [], record: EmployeeDTO) => {
        return (
          <Row gutter={15}>
            {employeePermissions.data.map((item) => (
              <Col>
                <div>{splitText(item.name!)}</div>
                <Checkbox
                  onChange={() => {
                    togglePermission({
                      employeeId: record.id!,
                      permissionId: item.id!
                    })
                  }}
                  value={item.id}
                  checked={Boolean(permissions.find((_item) => _item.id === item.id))}
                />
              </Col>
            ))}
          </Row>
        );
      },
    },
  ];

  console.log("data", data);

  return (
    <Styled.Wrapper>
      <div className="container">
        <Row gutter={[0, 20]} justify="end">
          <Col span={24}>
            <Styled.Table>
              <TableSc
                data={data}
                filters={filters}
                columns={columns}
                scroll={{ x: 1400 }}
              />
            </Styled.Table>
          </Col>
          <Col span={24}>
            <Row justify="end">
              <AddEmployee />
            </Row>
          </Col>
        </Row>
      </div>
    </Styled.Wrapper>
  );
};
