/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AbstractEventVO } from './AbstractEventVO';

export type EmployeeActivatedVO = (AbstractEventVO & {
    action?: string;
    employeeId?: number;
    eventDate?: string;
    process?: EmployeeActivatedVO.process;
});

export namespace EmployeeActivatedVO {

    export enum process {
        APPLICATION = 'APPLICATION',
        AUTH = 'AUTH',
        DOCUMENT_REQUEST = 'DOCUMENT_REQUEST',
        EMPLOYEE = 'EMPLOYEE',
        MERCHANT = 'MERCHANT',
        PARTNER = 'PARTNER',
        PAYOUT = 'PAYOUT',
        PROOF_PERSON = 'PROOF_PERSON',
        USER = 'USER',
    }


}
