import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { getMeThunk } from "../thunks";

type UseGetMe = () => {
  getMe: () => void;
};

export const useGetMe: UseGetMe = () => {
  const dispatch = useDispatch();

  const getMe = useCallback(() => {
    dispatch(getMeThunk());
  }, [dispatch]);

  return {
    getMe,
  };
};
