import { getIn, FormikErrors } from "formik";
import { Col, Row } from "antd";
import { OnboardingFormItem } from "../OnboardingFormItem";
import { Input, DatePicker, Radio, Checkbox } from "formik-antd";
import { AppInputs } from "generated/modelsWrapper/AppInputs";
import {
  ApplicationCompanyValues,
  BusinessOwnerValues,
  OwnerTypeEnum,
} from "data-layer/application/types";
import { UserAddOutlined } from "@ant-design/icons";
import { Styled as OnboardingStyled } from "../../onboardingStyles";
import { Styled as EditViewStyled } from "./editViewStyles";
import { DATE_FORMAT, MASKED } from "utils/datePickerMask";
import { PhoneInput } from "components/PhoneInput";
import { Button } from "components";

type BusinessOwnersEditProps = {
  owner: BusinessOwnerValues;
  nameBase: string;
  errors: FormikErrors<ApplicationCompanyValues>;
  onContinue: VoidFunction;
  isCreating: boolean;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
};

export const BusinessOwnersEdit: React.FC<BusinessOwnersEditProps> = (
  props
) => {
  const { owner, nameBase, errors, onContinue, isCreating, setFieldValue } =
    props;

  return (
    <>
      <EditViewStyled.Icon>
        <EditViewStyled.Svg>
          <UserAddOutlined />
        </EditViewStyled.Svg>
      </EditViewStyled.Icon>

      <EditViewStyled.Title>Add an owner</EditViewStyled.Title>

      <EditViewStyled.Scroll>
        <OnboardingStyled.Content margin="small">
          <Row>
            <Col span={24}>
              <Row gutter={[0, { xs: 9, sm: 9, xl: 15 }]}>
                <Col span={24}>
                  <OnboardingFormItem
                    label={
                      <EditViewStyled.Label>Choose status</EditViewStyled.Label>
                    }
                    error={getIn(
                      errors,
                      `${nameBase}${AppInputs.PERSON_STATUS}`
                    )}
                  >
                    <EditViewStyled.CheckboxGroup>
                      <Checkbox.Group
                        onChange={(value) => {
                          if (!value.includes(OwnerTypeEnum.Officer)) {
                            setFieldValue(
                              `${nameBase}${AppInputs.PERSON_POSITION}`,
                              undefined
                            );
                          }
                        }}
                        name={`${nameBase}${AppInputs.PERSON_STATUS}`}
                        options={[
                          {
                            label: "Ultimate Beneficial Owner",
                            value: OwnerTypeEnum.UltimateOwner,
                          },
                          {
                            label: "Company Officer",
                            value: OwnerTypeEnum.Officer,
                          },
                        ]}
                      />
                    </EditViewStyled.CheckboxGroup>
                  </OnboardingFormItem>
                </Col>
                <Col span={24}>
                  <OnboardingFormItem
                    label={
                      <EditViewStyled.Label>
                        Authorised Signatory
                      </EditViewStyled.Label>
                    }
                    error={getIn(
                      errors,
                      `${nameBase}${AppInputs.PERSON_AUTHORIZED}`
                    )}
                  >
                    <EditViewStyled.CheckboxGroup>
                      <Radio.Group
                        name={`${nameBase}${AppInputs.PERSON_AUTHORIZED}`}
                        options={[
                          { label: "Yes", value: "Yes" },
                          { label: "No", value: "No" },
                        ]}
                      >
                        {" "}
                      </Radio.Group>
                    </EditViewStyled.CheckboxGroup>
                  </OnboardingFormItem>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <OnboardingFormItem
                label="First name"
                error={getIn(
                  errors,
                  `${nameBase}${AppInputs.PERSON_FIRST_NAME}`
                )}
              >
                <Input
                  name={`${nameBase}${AppInputs.PERSON_FIRST_NAME}`}
                  placeholder="John"
                />
              </OnboardingFormItem>
            </Col>
            <Col span={24}>
              <OnboardingFormItem
                label="Last name"
                error={getIn(
                  errors,
                  `${nameBase}${AppInputs.PERSON_LAST_NAME}`
                )}
              >
                <Input
                  name={`${nameBase}${AppInputs.PERSON_LAST_NAME}`}
                  placeholder="Doe"
                />
              </OnboardingFormItem>
            </Col>
            <Col span={24}>
              <OnboardingFormItem
                label="Date of birth"
                error={getIn(errors, `${nameBase}${AppInputs.PERSON_DOB}`)}
              >
                <DatePicker
                  name={`${nameBase}${AppInputs.PERSON_DOB}`}
                  placeholder="DD.MM.YYYY"
                  format={DATE_FORMAT}
                  onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    const input = event.target as HTMLInputElement;
                    input.value = MASKED.resolve(input.value);
                  }}
                  picker="date"
                />
              </OnboardingFormItem>
            </Col>
            <Col span={24}>
              <OnboardingFormItem
                label="Email"
                error={getIn(errors, `${nameBase}${AppInputs.PERSON_EMAIL}`)}
              >
                <Input
                  name={`${nameBase}${AppInputs.PERSON_EMAIL}`}
                  placeholder="example@user.com"
                />
              </OnboardingFormItem>
            </Col>
            <Col span={24}>
              <OnboardingFormItem
                label="Phone number"
                error={getIn(
                  errors,
                  `${nameBase}${AppInputs.PERSON_PHONE_NUMBER}`
                )}
              >
                <PhoneInput
                  value={owner.PERSON_PHONE_NUMBER}
                  country={"gb"}
                  onChange={(value: any) =>
                    setFieldValue(`${nameBase}${AppInputs.PERSON_PHONE_NUMBER}`, value)
                  }
                />
              </OnboardingFormItem>
            </Col>
            <Col span={24}>
              <OnboardingFormItem
                label="Address"
                error={getIn(
                  errors,
                  `${nameBase}${AppInputs.PERSON_ADDRESS_LINE_1}`
                )}
              >
                <Input
                  name={`${nameBase}${AppInputs.PERSON_ADDRESS_LINE_1}`}
                  placeholder="E.g. 'CR0 3RL' or '36 Factory Lane'"
                />
              </OnboardingFormItem>
            </Col>
            <Col span={24}>
              <OnboardingFormItem
                label={null}
                error={getIn(
                  errors,
                  `${nameBase}${AppInputs.PERSON_ADDRESS_LINE_2}`
                )}
              >
                <Input name={`${nameBase}${AppInputs.PERSON_ADDRESS_LINE_2}`} />
              </OnboardingFormItem>
            </Col>
            <Col span={24}>
              <OnboardingFormItem
                label="Town/City"
                error={getIn(errors, `${nameBase}${AppInputs.PERSON_CITY}`)}
              >
                <Input name={`${nameBase}${AppInputs.PERSON_CITY}`} />
              </OnboardingFormItem>
            </Col>
            <Col span={24}>
              <OnboardingFormItem
                label="Postcode"
                error={getIn(
                  errors,
                  `${nameBase}${AppInputs.PERSON_POSTAL_CODE}`
                )}
              >
                <Input name={`${nameBase}${AppInputs.PERSON_POSTAL_CODE}`} />
              </OnboardingFormItem>
            </Col>
            <Col span={24}>
              <OnboardingFormItem
                label="Nationality"
                error={getIn(
                  errors,
                  `${nameBase}${AppInputs.PERSON_NATIONALITY}`
                )}
              >
                <Input name={`${nameBase}${AppInputs.PERSON_NATIONALITY}`} />
              </OnboardingFormItem>
            </Col>
            <Col span={24}>
              <OnboardingFormItem
                label="Company ownership (%)"
                error={getIn(
                  errors,
                  `${nameBase}${AppInputs.PERSON_OWNERSHIP}`
                )}
              >
                <Input name={`${nameBase}${AppInputs.PERSON_OWNERSHIP}`} />
              </OnboardingFormItem>
            </Col>
            {/* for officer */}
            {owner.PERSON_STATUS.includes(OwnerTypeEnum.Officer) && (
              <Col span={24}>
                <OnboardingFormItem
                  label="Position held in Company"
                  error={getIn(
                    errors,
                    `${nameBase}${AppInputs.PERSON_POSITION}`
                  )}
                >
                  <Input
                    name={`${nameBase}${AppInputs.PERSON_POSITION}`}
                    placeholder="Role"
                  />
                </OnboardingFormItem>
              </Col>
            )}
          </Row>
        </OnboardingStyled.Content>

        <Button type="primary-transparent" block onClick={onContinue}>
          {isCreating ? "Create" : "Save"}
        </Button>
      </EditViewStyled.Scroll>
    </>
  );
};
