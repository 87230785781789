import { appClient } from "services";
import { INotificationsApi } from "./index";

export const api: INotificationsApi = {
  getNotifications: (limit: number, offset: number) => {
    return appClient.notificationController.getAllUsingGet(limit, offset);
  },
  markAsRead: (id: number) => {
    return appClient.notificationController.markAsReadUsingPost(id);
  },
  markAllAsRead: (ids?: number[]) => {
    return appClient.notificationController.markAsReadAllUsingPost(ids);
  }
};
