import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { signUp as signUpThunk } from "data-layer/employee/thunks";
import { SignupRequestDTO } from "generatedSunset";

type UseSignUp = () => {
  signUp: (data: SignupRequestDTO) => void;
};

export const useSignUp: UseSignUp = () => {
  const dispatch = useDispatch();

  const signUp = useCallback(
    (data: SignupRequestDTO) => {
      dispatch(signUpThunk(data));
    },
    [dispatch]
  );

  return {
    signUp,
  };
};
