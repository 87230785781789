import { useSelector } from "react-redux";
import { partnerSelector } from "../selectors";
import { IPartner } from "../types";
import { useAttemptSettlements } from "./useAttemptSettlements";
import { useFindPartnerById } from "./useFindPartnerById";
import { useGetStats } from "./useGetStats";
import { useInvite } from "./useInvite";
import { useMe } from "./useMe";
import { useTurnoverPercentage } from "./useTurnoverPartner";
import { useUpdatePartner } from "./useUpdatePartner";
import { useUpdatePartnerLogo } from "./useUpdatePartnerLogo";
import { useUpdateSettlements } from "./useUpdateSettlements";

export const usePartner = (): IPartner => {
  const { me } = useMe();
  const { invite } = useInvite();
  const { getStats } = useGetStats();
  const { getTurnoverPercentage } = useTurnoverPercentage();
  const partnerState = useSelector(partnerSelector);
  const { attemptSettlements } = useAttemptSettlements();
  const { updateSettlements } = useUpdateSettlements();
  const { updatePartner } = useUpdatePartner();
  const { updatePartnerLogo } = useUpdatePartnerLogo(partnerState.data!, updatePartner);
  const { findParterById } = useFindPartnerById()

  return {
    me,
    invite,
    getStats,
    getTurnoverPercentage,
    partnerState,
    attemptSettlements,
    updateSettlements,
    updatePartner,
    updatePartnerLogo,
    findParterById,
  };
};
