import { Styled } from "./styles";
import { PlusOutlined } from "@ant-design/icons";
import { useCallback, useState } from "react";
import { CreateLinkModal } from "./components/CreateLinkModal";
import { LinkCreatedModal } from "./components/LinkCreatedModal";
import { PaymentLinkDTO } from "generated";

export const CreateLink: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalCreatedVisible, setIsModalCreatedVisible] = useState(false);

  const [createdLinkData, setCreatedLinkData] = useState<null | PaymentLinkDTO>(
    null
  );

  const handleShowCreateLinkModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseCreateLinkModal = useCallback(() => {
    setIsModalVisible(false);
  }, [setIsModalVisible]);

  const handleCreate = useCallback(
    (data: PaymentLinkDTO) => {
      handleCloseCreateLinkModal();
      setCreatedLinkData(data);
      setIsModalCreatedVisible(true);
    },
    [handleCloseCreateLinkModal, setCreatedLinkData, setIsModalCreatedVisible]
  );

  const handleCloseCreatedLinkModal = useCallback(() => {
    setIsModalCreatedVisible(false);
  }, [setIsModalCreatedVisible]);

  return (
    <>
      <Styled.CreateLinkButton onClick={handleShowCreateLinkModal}>
        <PlusOutlined />
        <span>Create new link</span>
      </Styled.CreateLinkButton>

      <CreateLinkModal
        visible={isModalVisible}
        onClose={handleCloseCreateLinkModal}
        onCreate={handleCreate}
      />

      <LinkCreatedModal
        visible={isModalCreatedVisible && Boolean(createdLinkData)}
        onClose={handleCloseCreatedLinkModal}
        data={createdLinkData}
      />
    </>
  );
};
