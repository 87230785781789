import { useCallback } from "react";
import { AttachmentDTO } from "generated/models/AttachmentDTO";

type UseRemoveFile = (
  setFile: (file: AttachmentDTO) => void,
  resetInputValue: VoidFunction,
  cb?: VoidFunction
) => {
  removeFileDefault: (e: any) => void;
};

export const useRemoveFile: UseRemoveFile = (setFile, resetInputValue, cb) => {
  const removeFileDefault = useCallback(
    (e) => {
      e.preventDefault();
      setFile({});
      resetInputValue();
      if (cb) {
        cb();
      }
    },
    [cb, setFile]
  );

  return {
    removeFileDefault,
  };
};
