import { useCallback } from "react";
import { useDocumentRequest } from "data-layer/documentRequest";
import { AttachmentDTO } from "generated";

type UseSubmit = () => {
  handleSubmit: (id: number, values: AttachmentDTO[], cb?: VoidFunction) => void;
};

export const useSubmit: UseSubmit = () => {
  const { sendToReview } = useDocumentRequest();

  const handleSubmit = useCallback(
    (id: number, values: AttachmentDTO[], cb?: VoidFunction) => {
      const ids = values.map((item) => item.id!);

      sendToReview({
        id: id,
        attachmentIds: ids,
      }, cb);
    },
    [sendToReview]
  );

  return {
    handleSubmit,
  };
};
