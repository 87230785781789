import { CopyLink, DownloadLink, Modal } from "components";
import { Styled } from "./styles";
import { UserAddOutlined } from "@ant-design/icons";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Col, Row, Spin } from "antd";
import { Dropzone } from "components/Dropzone";
import { Button } from "components";
import { ParseImportFileResultDTO } from "generated";
import { useDropzone } from "common/dropzone";
import { useSubmit } from "./useSubmit";
import { File } from "./File";

const styleOverlay = {
  position: "absolute",
  top: 0,
  left: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  zIndex: 2,
  backgroundColor: "rgba(255, 255, 255, 0.7)",
};

type BulkUploadModalProps = {
  visible: boolean;
  onClose: VoidFunction;
  onParse: (data: ParseImportFileResultDTO) => void;
};

export const schema = Yup.object().shape({
  // file: Yup.object().shape({
  //   id: Yup.number().required("required"),
  //   hashFileName: Yup.string(),
  //   originalFileName: Yup.string(),
  // }),
});

const initialValues: { file: File | null } = {
  file: null,
};

export const BulkUploadModal: React.FC<BulkUploadModalProps> = (props) => {
  const { visible, onClose, onParse } = props;
  const { onDrop, loading: loadingDrop } = useDropzone();
  const { handleSubmit } = useSubmit();

  return (
    <Modal visible={visible} onCancel={onClose} footer={null}>
      <Styled.Wrapper>
        <Styled.Icon>
          <Styled.Svg>
            <UserAddOutlined />
          </Styled.Svg>
        </Styled.Icon>

        <Styled.Title>Bulk upload new merchants</Styled.Title>

        <div style={{ marginBottom: 30 }}>
          <DownloadLink
            link={
              "https://docs.google.com/spreadsheets/d/1O_uu1Hti3_yqkFlEAffaDMfobaWHfOvBf0kfirGzLdU/edit#gid=1590412533"
            }
            placeholder={"Download XLS simple file"}
          />
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          validateOnChange={false}
          onSubmit={(values) => {
            console.log("values", values);
            const format = values?.file?.path?.split(".").pop().toLowerCase();
            handleSubmit(values.file!, format, onParse);
          }}
        >
          {({ errors, values, setFieldValue, submitForm, handleSubmit }) => {
            return (
              <Form
                onSubmit={handleSubmit}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
              >
                <Row gutter={[0, 30]}>
                  <Col span={24}>
                    <Row gutter={[0, 30]}>
                      <Col span={24}>
                        <Dropzone
                          onDrop={(files) =>
                            onDrop(files, (val) => {
                              setFieldValue("file", val);
                            })
                          }
                        />
                      </Col>
                      <Col span={24}>
                        {values.file && (
                          <File
                            name={values.file.name}
                            handleRemove={() => {
                              setFieldValue("file", undefined);
                            }}
                          />
                        )}
                      </Col>
                      {loadingDrop && (
                        <div style={styleOverlay}>
                          <Spin />
                        </div>
                      )}
                    </Row>
                  </Col>

                  <Col span={24}>
                    <Button
                      block
                      type="primary-transparent"
                      onClick={submitForm}
                      disabled={!values.file || loadingDrop}
                    >
                      Bulk Upload
                    </Button>
                  </Col>
                </Row>
                <div></div>
              </Form>
            );
          }}
        </Formik>
      </Styled.Wrapper>
    </Modal>
  );
};
