import Icon from "@ant-design/icons";

// TODO
type SvgIconType = {
    svg: any;
    style?: any;
}

export const SvgIcon: React.FC<SvgIconType> = props => {
    const { svg, ...restProps } = props;

    return <Icon component={svg} {...restProps} />;
}