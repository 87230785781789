import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

type MobileMenuVisibleProp = {
  mobileMenuVisible: boolean;
}

const Wrapper = styled.div<MobileMenuVisibleProp>`
  z-index: 110;

  position: fixed;

  display: none;
  flex-direction: column;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: #fff;

  @media (max-width: 991px) {
    display: ${props => props.mobileMenuVisible ? "flex" : ""};
    overflow: auto;
  }
`

const Header = styled.div`
  flex-shrink: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 80px;

  margin-bottom: 21px;

  padding: 0 20px;

  @media (max-width: 1450px) {
    height: 60px;
  }

  @media (max-width: 575px) {
    padding: 0 15px;
  }
`

const Logo = styled.img`
  width: 125px;

  @media (max-width: 575px) {
    width: 78px;
  }
`

const NavigatorMobileCloseButton = styled.div`
  position: relative;

  display: none;

  width: 26px;
  height: 26px;

  cursor: pointer;

  @media (max-width: 991px) {
    display: flex;
  }

  &::before,
  &::after {
    content: '';

    position: absolute;

    top: 50%;
    left: 50%;

    width: 100%;
    height: 2px;

    border-radius: 5px;

    background-color: #000;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
`

const List = styled.ul`
  list-style-type: none;

  display: flex;
  flex-direction: column;

  margin-bottom: 20px;
  padding: 0 20px;

  border-radius: 0;

  @media (max-width: 575px) {
    padding: 0 15px;
  }
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  white-space: nowrap;

  a {
    position: relative;

    display: flex;

    align-items: center;

    width: 100%;
    padding: 13px 0;

    font-size: 17px;
    font-family: ${Fonts.NexaRegular};
    line-height: 23px;
    text-decoration: none;

    border-bottom: 1px solid #e3e0e1;
    border-radius: 0;

    color: ${Colors.Secondary};

    &:hover .ant-col:last-child > div {
      background-color: ${Colors.Primary};
    }

    @media (max-width: 575px) {
      padding: 15px;
    }

    &.active {
      display: inline-flex;

      border-radius: 0;

      background-color: transparent;

      color: ${Colors.Primary};

      .ant-col:last-child > div {
        background-color: ${Colors.Primary};
      }
    }
  }

  a[disabled] {
    opacity: 0.5;
  }

  .ant-row {
    width: 100%;
  }
`;

const ItemIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 25px;
  height: 25px;

  border-radius: 22px;

  background-color: ${Colors.Black10};

  &:hover {
    background-color: ${Colors.Primary};
  }

  svg {
    font-size: 14px;
    color: #fff;
  }
`;

const Profile = styled.div`
  margin-top: auto;
`

export const Styled = {
  Wrapper,
  Header,
  Logo,
  List,
  Item,
  ItemIcon,
  NavigatorMobileCloseButton,
  Profile,
};
