import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TDictionaryDTO } from "./types/TDictionaryDTO";
import { formatBusinessType } from "./utils/formatBusinessType";
import { formatExperience } from "./utils/formatExperience";
import { sortCountries } from "./utils/sortCountries";

export type Dictionary = TDictionaryDTO | null;

export type DictionaryInitialState = {
  data: Dictionary;
};

const initialState: DictionaryInitialState = {
  data: null,
};

export const dictionarySlice = createSlice({
  name: "dictionaries",
  initialState: initialState,
  reducers: {
    dictionarySuccess: (state, action: PayloadAction<TDictionaryDTO>) => {
      state.data = {
        ...action.payload,
        countries: sortCountries(action.payload.countries),
        businessTypes: formatBusinessType(action.payload.businessTypes),
        sizes: formatExperience(action.payload.sizes),
      };
    },
  },
});

export const dictionaryActions = dictionarySlice.actions;
