import { useCallback, useEffect, useState } from "react";

type UseModal = () => {
  visible: boolean;
  open: VoidFunction;
  close: VoidFunction;
  handleToggle: (e: any) => void;
};

export const useModal: UseModal = () => {
  const [visible, setVisible] = useState(false);

  const open = useCallback(() => {
    setVisible(true);
  }, [setVisible]);

  const close = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const handleToggle = (e: Event) => {
    e.stopPropagation();
    setVisible(!visible);
  };

  useEffect(() => {
    window.addEventListener("click", close);

    return () => {
      window.removeEventListener("click", close);
    };
  }, [close]);

  return {
    visible,
    open,
    close,
    handleToggle,
  };
};
