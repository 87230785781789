/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CountryEntity } from './CountryEntity';
import type { CurrencyEntity } from './CurrencyEntity';
import type { MerchantAddressData } from './MerchantAddressData';
import type { MerchantContactData } from './MerchantContactData';
import type { PartnerEntity } from './PartnerEntity';
import type { ProfileEntity } from './ProfileEntity';
import type { SettlementDetailsEntity } from './SettlementDetailsEntity';
import type { VACKernelEntity } from './VACKernelEntity';
import type { MerchantDocumentsData } from "./MerchantDocumentsData";

export type MerchantEntity = {
    bankAccountHolderName?: string;
    bankAccountNumber?: string;
    cctExternalId?: number;
    cdxExternalId?: string;
    country?: CountryEntity;
    created?: string;
    currency?: CurrencyEntity;
    dba?: string;
    email?: string;
    feePercent?: number;
    fsrvExternalId?: string;
    iban?: string;
    id?: number;
    kind?: MerchantEntity.kind;
    mcc?: string;
    merchantContactData?: MerchantContactData;
    merchantName?: string;
    merchantNumber?: string;
    mid?: string;
    modified?: string;
    officeAddressData?: MerchantAddressData;
    originType?: MerchantEntity.originType;
    partner?: PartnerEntity;
    phoneNumber?: string;
    profile?: ProfileEntity;
    registrationAddressData?: MerchantAddressData;
    settlementDebtAmount?: number;
    settlementDetails?: Array<SettlementDetailsEntity>;
    siteUrl?: string;
    sortCode?: string;
    status?: MerchantEntity.status;
    tidPrefix?: string;
    timeZone?: string;
    vacKernel?: VACKernelEntity;
    vacMerchantProfileId?: string;
    version?: number;
    merchantDocumentsData?: MerchantDocumentsData
};

export namespace MerchantEntity {

    export enum kind {
        COMPANY = 'COMPANY',
        SOLE_TRADER = 'SOLE_TRADER',
    }

    export enum originType {
        EXTERNAL = 'EXTERNAL',
        INTERNAL = 'INTERNAL',
    }

    export enum status {
        ACTIVATED = 'ACTIVATED',
        NEW = 'NEW',
        SUSPENDED = 'SUSPENDED',
    }


}
