import { Divider as AntdDivider } from "antd";
import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

const Title = styled.p`
  max-width: 500px;

  font-size: 25px;
  line-height: 30px;
  font-family: ${Fonts.NexaRegular};
  color: ${Colors.Primary};

  @media (max-width: 575px) {
    font-size: 18px;
    line-height: 20px;
  }
`;

const ScrollView = styled.div`
  max-height: 480px;

  font-family: ${Fonts.NexaRegular};
  font-size: 16px;
  line-height: 23px;

  color: #4d4d4d;

  overflow: auto;

  @media (max-width: 575px) {
    font-size: 16px;
    line-height: 20px;
  }

  p {
    margin-bottom: 23px;

    @media (max-width: 575px) {
      margin-bottom: 20px;
    }
  }

  ul {
    padding-left: 0;
    list-style-type: none;
  }

  li {
    position: relative;
    margin-bottom: 21px;
    padding-left: 20px;

    @media (max-width: 575px) {
      margin-bottom: 10px;
      padding-left: 15px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 5px;
      left: 0;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #7f868b;

      @media (max-width: 575px) {
        width: 6px;
        height: 6px;
      }
    }
  }
`;

const Subtitle = styled.p`
  font-family: ${Fonts.NexaRegular};
  font-size: 16px;
  line-height: 23px;

  color: #283549;
`;

const Headline = styled.p`
  margin-bottom: 17px !important;

  font-family: ${Fonts.NexaRegular};
  font-size: 16px;
  line-height: 23px;

  color: #283549;

  @media (max-width: 575px) {
    margin-bottom: 13px !important;
  }
`;

const List = styled.div`
  ul {
    columns: 2;
    column-gap: 50px;

    @media (max-width: 575px) {
      columns: 1;
    }
  }
`;

const Divider = styled(AntdDivider)`
  margin-top: 50px;
  margin-bottom: 30px;

  @media (max-width: 575px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export const Styled = {
  ScrollView,
  Title,
  Subtitle,
  Headline,
  List,
  Divider,
};
