import { api } from "./api";

export interface ISekumpulApi {
  upload: (formData?: {
    file?: Blob;
  }) => Promise<string>;
}

class SekumpulApi {
  api: ISekumpulApi = api;

  constructor() {
    this.api = api;
  }
}

export const sekumpulApi = new SekumpulApi();
