import styled, { css } from "styled-components";
import { Row } from "antd";
import { Fonts } from "styles/fonts";
import { Colors } from "styles/colors";

const LargeGrid = styled.div`
  @media (max-width: 1450px) {
    display: none;
  }
`;

const MediumGrid = styled.div`
  display: none;

  @media (max-width: 1450px) {
    display: block;
  }

  @media (max-width: 1050px) {
    display: none;
  }
`

const Grid = styled.div`
  display: none;

  @media (max-width: 1050px) {
    display: block;
  }
`;

const MainRow = styled(Row)`
  padding: 25px 0 60px;

  @media (max-width: 1450px) {
    padding: 20px 0 40px;
  }
`;

const RightColRow = styled(Row)`
  flex-direction: column;
  height: 100%;
`;

const Title = css`
  position: relative;

  margin-bottom: 18px;

  font-family: ${Fonts.NexaRegular};
  font-size: 25px;
  line-height: 100%;

  color: #08243b;

  @media (max-width: 1450px) {
    margin-bottom: 5px;
    font-size: 20px;
  }

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const Description = css`
  margin-bottom: 10px;

  font-family: ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 23px;

  color: ${Colors.GrayBlue};

  @media (max-width: 1450px) {
    margin-bottom: 17px;
    font-size: 14px;
    line-height: 20px;
  }
`

export const Styled = {
  LargeGrid,
  MediumGrid,
  Grid,
  MainRow,
  RightColRow,
};

export const StyledCss = {
  Title,
  Description,
}