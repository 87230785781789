import { CancelablePromise } from "generated/core/CancelablePromise";
import { api } from "./api";
import {
  MerchantCountDTO,
  MerchantDTO,
  SettlementDetailsAttemptUpdateDTO,
} from "generated";

export interface IMerchantApi {
  getMerchants: (
    limit: number,
    offset: number,
    name?: string
  ) => CancelablePromise<Array<MerchantDTO>>;
  getMerchantsPartnerCount: () => CancelablePromise<MerchantCountDTO>;
  me: () => CancelablePromise<MerchantDTO>;
  updateMerchant: (
    requestBody?: MerchantDTO
  ) => CancelablePromise<MerchantDTO | any>;
  updateMerchantSettlement: (token: string) => CancelablePromise<MerchantDTO>;
  attemptMerchantSettlement: (
    requestBody?: SettlementDetailsAttemptUpdateDTO
  ) => CancelablePromise<MerchantDTO | any>;
}

class MerchantApi {
  api: IMerchantApi = api;

  constructor() {
    this.api = api;
  }
}

export const merchantApi = new MerchantApi();
