import { AppDispatch } from "data-layer/store/store";
import { profileApi } from "data-layer/profile/api";
import { profileSlice } from "data-layer/profile/slice";
import { CreateUpdateProfileDTO } from "generated/models/CreateUpdateProfileDTO";
import { loaderSlice } from "data-layer/common/loaderSlice";
import { history } from "data-layer/../router/AppRouter";
import { employeeSlice } from "data-layer/employee/slice";
import {
  getApplicationsThunk,
  persistApplicationThunk,
} from "data-layer/application/thunks";
import { applicationSlice } from "data-layer/application/slice";
import { ParterProfileParams } from "./types/ParterProfileParams";
import { getMeThunk } from "data-layer/merchant/thunks";
import { merchantSlice } from "data-layer/merchant/slice";
import { handleNetworkError } from "utils/handleNetworkError";

export const getProfile = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(profileSlice.actions.getProfileRequest());
    try {
      const result = await profileApi.api.getProfile();
      dispatch(profileSlice.actions.getProfileSuccess(result));
    } catch (e) {
      dispatch(profileSlice.actions.getProfileFailure());
    }
  };
};

export const createProfileThunk = (
  data: CreateUpdateProfileDTO & { values: any },
  cb?: VoidFunction
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      const { values, ...rest } = data;

      const result = await profileApi.api.createProfile(rest);
      dispatch(profileSlice.actions.createProfileSuccess(result));

      dispatch(applicationSlice.actions.clearApplication());
      dispatch(merchantSlice.actions.clearMerchant());

      // wtf? test remove
      // history.push("/onboarding");

      await dispatch(
        persistApplicationThunk({
          fields: values,
          version: 0,
        })
      );

      if (cb) {
        cb();
      }
    } catch (e) {
      handleNetworkError(e, "Profile creation error");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};

export const editProfileThunk = (
  id: number,
  data: CreateUpdateProfileDTO & { values: any },
  newApplicationId: number,
  cb?: VoidFunction
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      const { values, ...rest } = data;

      const result = await profileApi.api.editProfile(id, rest);
      dispatch(profileSlice.actions.editProfileSuccess(result));

      // history.push("/onboarding");

      await dispatch(
        persistApplicationThunk({
          id: newApplicationId,
          fields: values,
        })
      );

      if (cb) {
        cb();
      }
    } catch (e) {
      handleNetworkError(e, "Profile editing error");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};

export const selectProfile = (profileId: number) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());
    history.push("/dashboard");

    try {
      await profileApi.api.selectProfile(profileId);
      dispatch(profileSlice.actions.selectProfileSuccess({ id: profileId }));

      // dispatch(applicationSlice.actions.clearApplication());
      // dispatch(merchantSlice.actions.clearMerchant());
      await dispatch(getApplicationsThunk());
      await dispatch(getMeThunk());
    } catch (e) {
      handleNetworkError(e, "Profile selection error");
      dispatch(employeeSlice.actions.logout());
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};

export const getPartherProfileThunk = (params: ParterProfileParams) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      const { states, partnerId, limit, offset, from, to, companyName } =
        params;
      const result = await profileApi.api.getPartherProfile(
        states,
        partnerId,
        limit,
        offset,
        from,
        to,
        companyName
      );

      dispatch(profileSlice.actions.getPartnerProfileSuccess(result));
    } catch (e) {
      dispatch(profileSlice.actions.getPartnerProfileFailure());
      handleNetworkError(e, "Profile loading error");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};
