import { ToggleEmployeePermissionDTO } from "generated";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { togglePermissionThunk } from "../thunks";

type UseTogglePermission = () => {
  togglePermission: (body: ToggleEmployeePermissionDTO) => void;
};

export const useTogglePermission: UseTogglePermission = () => {
  const dispatch = useDispatch();

  const togglePermission = useCallback(
    (body) => {
      dispatch(togglePermissionThunk(body));
    },
    [dispatch]
  );

  return {
    togglePermission,
  };
};
