import styled from "styled-components";
import { StyledCss } from "../../styles";

const Title = styled.p`
  ${StyledCss.Title}

  color: #0000001A;
`;

const ComingSoonWrapper = styled.div`
  height: 100%;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const Styled = {
  Title,
  ComingSoonWrapper,
};
