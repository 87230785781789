import { useSelector } from "react-redux";
import { IMerchant } from "../";
import {
  isFetchingMerchantPartnerCountSelector,
  isFetchingMerchantsSelector,
  isFetchingMeSelector,
  merchantPartnerCountDataSelector,
  merchantsSelector,
  meSelector,
} from "../selectors";
import { useAttemptSettlements } from "./useAttemptSettlements";
import { useGetMe } from "./useGetMe";
import { useGetMerchantPartnerCount } from "./useGetMerchantPartnerCount";
import { useGetMerchants } from "./useGetMerchants";
import { useUpdateMerchant } from "./useUpdateMerchant";
import { useUpdateSettlements } from "./useUpdateSettlements";

export const useMerchant = (): IMerchant => {
  const { getMerchantPartnerCount } = useGetMerchantPartnerCount();
  const dataMerchantPartnerCount = useSelector(
    merchantPartnerCountDataSelector
  );
  const isFetchingMerchantPartnerCount = useSelector(
    isFetchingMerchantPartnerCountSelector
  );

  const isFetchingMe = useSelector(isFetchingMeSelector);
  const me = useSelector(meSelector);

  const { getMe } = useGetMe();

  const { getMerchants } = useGetMerchants();
  const merchants = useSelector(merchantsSelector);
  const isFetchingMerchants = useSelector(isFetchingMerchantsSelector);

  const { updateMerchant } = useUpdateMerchant();
  const { attemptSettlements } = useAttemptSettlements();
  const { updateSettlements } = useUpdateSettlements();

  return {
    dataMerchantPartnerCount,
    isFetchingMerchantPartnerCount,
    getMerchantPartnerCount,

    isFetchingMe,
    me,
    getMe,

    getMerchants,
    merchants,
    isFetchingMerchants,

    updateMerchant,
    attemptSettlements,
    updateSettlements,
  };
};
