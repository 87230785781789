import { NavLink } from "react-router-dom";
import { Styled } from "./styles";
import { Link } from "react-router-dom";
import { ProfileMenuSc } from "scenarios/partner/ProfileMenuSc";
import { SimpleLogo } from "ui";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";

type NavigatorMobileProps = {
  onClose: VoidFunction;
  mobileMenuVisible: boolean;
};

export const NavigatorMobile: React.FC<NavigatorMobileProps> = (props) => {
  const { onClose, mobileMenuVisible } = props;

  return (
    <Styled.Wrapper mobileMenuVisible={mobileMenuVisible}>
      <Styled.Header>
        <Link to="/dashboard" onClick={onClose}>
          <SimpleLogo />
        </Link>
        <Styled.NavigatorMobileCloseButton onClick={onClose} />
      </Styled.Header>

      <Styled.List>
        <Styled.Item>
          <NavLink
            end
            to="/dashboard"
            className={({ isActive }) => (isActive ? "active" : "")}
            onClick={onClose}
          >
            <Row justify="space-between">
              <Col>Overview</Col>
              <Col>
                <Styled.ItemIcon>
                  <ArrowRightOutlined />
                </Styled.ItemIcon>
              </Col>
            </Row>
          </NavLink>
        </Styled.Item>
        <Styled.Item>
          <NavLink
            to="/dashboard/onboarding"
            className={({ isActive }) => (isActive ? "active" : "")}
            onClick={onClose}
          >
            <Row justify="space-between">
              <Col>Onboarding</Col>
              <Col>
                <Styled.ItemIcon>
                  <ArrowRightOutlined />
                </Styled.ItemIcon>
              </Col>
            </Row>
          </NavLink>
        </Styled.Item>
        <Styled.Item>
          <NavLink
            to="/dashboard/merchants"
            className={({ isActive }) => (isActive ? "active" : "")}
            onClick={onClose}
          >
            <Row justify="space-between">
              <Col>Merchants</Col>
              <Col>
                <Styled.ItemIcon>
                  <ArrowRightOutlined />
                </Styled.ItemIcon>
              </Col>
            </Row>
          </NavLink>
        </Styled.Item>
        <Styled.Item>
        <NavLink
          to="/dashboard/settings/bank-details"
          className={({ isActive }) => (isActive ? "active" : "")}
          onClick={onClose}
        >
          <Row justify="space-between">
            <Col>Settlement</Col>
            <Col>
              <Styled.ItemIcon>
                <ArrowRightOutlined />
              </Styled.ItemIcon>
            </Col>
          </Row>
          </NavLink>
        </Styled.Item>
      </Styled.List>

      <Styled.Profile>
        <ProfileMenuSc closeMenu={onClose} />
      </Styled.Profile>
    </Styled.Wrapper>
  );
};
