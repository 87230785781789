import {
  DashBoardHeaderSc,
  DashBoardContentSc,
  FooterSc,
} from "scenarios/seller";
import { Styled } from "./styles";
import { useLocation } from "react-router-dom";

export function DashBoardSc() {
  const { pathname } = useLocation();

  return (
    <Styled.Wrapper pathname={pathname}>
      <DashBoardHeaderSc />
      <DashBoardContentSc />
      <FooterSc />
    </Styled.Wrapper>
  );
}
