import { useCallback } from "react";
import { ParseImportFileResultDTO } from "generated";
import { useMerchantImport } from "data-layer/merchantImport";

type UseSubmit = () => {
  handleSubmit: (
    value: File,
    format: string,
    onParse: (data: ParseImportFileResultDTO) => void
  ) => void;
};

export const useSubmit: UseSubmit = () => {
  const { parseFile } = useMerchantImport();

  const handleSubmit = useCallback(
    (value, format, onParse) => {
      parseFile({
        files: [value],
      }, format, onParse);
    },
    [parseFile]
  );

  return {
    handleSubmit,
  };
};
