import { useProfile } from "data-layer/profile";
import { useEffect } from "react";
import { Filters } from "./useInitParams";

type UseGetOnboardings = (filters: Filters | null) => void;

export const useGetOnboardings: UseGetOnboardings = (filters) => {
  const { getPartherProfile } = useProfile();

  useEffect(() => {
    if (!filters) {
      return;
    }

    getPartherProfile(filters);
  }, [filters]);
};
