import { Col, Row } from "antd";
import { QuestionOutlined } from "@ant-design/icons";
import { NotificationsSc } from "scenarios/seller";
import { Styled } from "./styles";
import { history } from "data-layer/../router/AppRouter";
import "./style.css";

export const Controls: React.FC = () => {
  return (
    <Row gutter={[{ xs: 10, sm: 10, xxl: 15 }, 0]} wrap={false}>
      <Col>
        <Styled.Control
          onClick={() => history.push("/dashboard/support/faq")}
        >
          <QuestionOutlined />
        </Styled.Control>
      </Col>
      <Col>
        <NotificationsSc />
      </Col>
    </Row>
  );
};
