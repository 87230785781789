import { useApplication } from "data-layer/application";
import { TypeOfBusinessCompanyExcludedValues } from "data-layer/application/types";
import { useProfile } from "data-layer/profile";
import { CreateUpdateProfileDTO } from "generated";

type UseCompanyProfile = () => {
  createCompanyProfile: (
    values: TypeOfBusinessCompanyExcludedValues,
    nextStep: VoidFunction
  ) => void;
  editCompanyProfile: (
    values: TypeOfBusinessCompanyExcludedValues,
    nextStep: VoidFunction
  ) => void;
};

export const useCompanyProfile: UseCompanyProfile = () => {
  const { createProfile, editProfile, selectedProfile } = useProfile();
  const { newApplication } = useApplication();

  const createCompanyProfile = (
    values: TypeOfBusinessCompanyExcludedValues,
    nextStep: VoidFunction
  ) => {
    createProfile(
      {
        kind: CreateUpdateProfileDTO.kind.COMPANY,
        name: values.COMPANY_NAME!,
        values,
      },
      nextStep
    );
  };

  const editCompanyProfile = (
    values: TypeOfBusinessCompanyExcludedValues,
    nextStep: VoidFunction
  ) => {
    editProfile(
      selectedProfile?.id!,
      {
        kind: CreateUpdateProfileDTO.kind.COMPANY,
        name: values.COMPANY_NAME!,
        values,
      },
      newApplication.id,
      nextStep
    );
  };

  return {
    createCompanyProfile,
    editCompanyProfile,
  };
};
