import { Styled } from "./styles";
import { Button } from "components";
import { Link } from "react-router-dom";
import { Typography, PageLogo } from "ui";
import { Role } from "data-layer/common/testRoleSlice";

export const ConfirmedEmailSc: React.FC = () => {
  return (
    <Styled.Wrapper>
      <Styled.Background />

      <Styled.Content>
        <Styled.ContentContainer>
          <PageLogo role={Role.Merchant} />
          <Typography.H1>
            Thank you for verifying your email address
          </Typography.H1>

          <Styled.Description>
            Congratulations, you confirmed your email. We are waiting you in the
            Dashboard personal account.
          </Styled.Description>

          <Styled.SubmitButton>
            <Link to="/signin">
              <Button type="primary-transparent" block>
                Sign in
              </Button>
            </Link>
          </Styled.SubmitButton>
        </Styled.ContentContainer>
      </Styled.Content>
    </Styled.Wrapper>
  );
};
