import styled from "styled-components";
import { H1 as _H1 } from "ui";

export const Wrapper = styled.div`
  padding: 20px;
`;

export const Item = styled.div`
  margin-bottom: 50px;
  background-color: #eee;
`;

export const CodeWrapper = styled.div`
  border: 1px solid #000;
`;

export const Code = styled.code``;

const ColorItem = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 30px;
  margin: 15px 0;
  background-color: ${(props) => props.color};
  color: #fff;
`;

export const Styled = {
  Wrapper,
  Item,
  CodeWrapper,
  Code,
  ColorItem,
};
