import { Button, Modal } from "components";
import { Styled } from "./styles";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useCommon } from "data-layer/common";

export const CommingSoonSc: React.FC = () => {
  const { isCommingSoonModalVisible, hideCommingSoonModal } = useCommon();

  return (
    <Modal
      visible={isCommingSoonModalVisible}
      onCancel={hideCommingSoonModal}
      footer={null}
    >
      <Styled.Wrapper>
        <Styled.Icon>
          <Styled.Svg>
            <ExclamationCircleOutlined />
          </Styled.Svg>
        </Styled.Icon>
        <Styled.Title>Comming soon</Styled.Title>
        <Styled.Description>
          We will be celebrating the launch this page very soon
        </Styled.Description>
        <Button type="primary" block onClick={hideCommingSoonModal}>Back to last page</Button>
      </Styled.Wrapper>
    </Modal>
  );
};
