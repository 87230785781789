import styled from "styled-components";

// const LargeGrid = styled.div`
//   @media (max-width: 1300px) {
//     display: none;
//   }
// `;

// const MobileGrid = styled.div`
//   display: none;

//   @media (max-width: 1300px) {
//     display: block;
//   }

//   .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
//     height: 32px !important;
//   }

//   .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
//     height: 32px !important;
//   }

//   .ant-select-selector {
//     border: 0.5px solid #0000000A;
//     border-radius: 16px !important;
//     box-shadow: 0px 3px 8px #0000001F;
//     background: #FFFFFF;
//   }

//   .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
//     padding: 0 16px !important;
//   }

//   .ant-select-selection-placeholder, .ant-select-selection-item {
//     padding-top: 8px !important;
//     padding-bottom: 8px !important;
//   }

//   .ant-select-arrow {
//     top: 50%;
//     transform: scale(0.8) translateY(-2px);
//   }
// `;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 67px;

  @media (max-width: 1550px) {
    margin-top: 52px;
  }

  .button-tabs .ant-tabs-tab {
    padding-right: 10px;
    padding-left: 10px;
  }

  .button-tabs .ant-tabs-nav-list {
    @media (max-width: 575px) {
      width: 100%;
      overflow: auto;
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: inherit;
  }

  .ant-select-selection-item {
    padding: 0;
  }
`;

export const Styled = {
  Wrapper,
  // LargeGrid,
  // MobileGrid,
  // PeriodPaper,
};
