import { Col, Row } from "antd";
import { Button, FormItem, Modal } from "components";
import { Styled } from "./styles";
import { MessageOutlined } from "@ant-design/icons";
import * as Yup from "yup";
import { Formik } from "formik";
import { Input } from "formik-antd";
import { useState } from "react";

const schema = Yup.object().shape({
  phone: Yup.string().required("Required"),
});

type Values = {
  phone: string;
};

const initialData: Values = {
  phone: "",
};

type ShareSmsModalProps = {
  onSubmit: Function;
  onClose: VoidFunction;
};

export const ShareSmsModal: React.FC<ShareSmsModalProps> = (props) => {
  const { onSubmit, onClose } = props;

  const [visible, setVisible] = useState(false);

  const handleOpenModal = () => {
    setVisible(true);
  }

  const handleCloseModal = () => {
    setVisible(false);
  }

  return (
    <>
      <Button block onClick={() => {
        onClose();
        handleOpenModal()
      }}>
        Send via SMS
      </Button>

      <Modal visible={visible} onCancel={handleCloseModal} footer={null}>
        <Styled.Wrapper>
          <Styled.Icon>
            <Styled.Svg>
              <MessageOutlined />
            </Styled.Svg>
          </Styled.Icon>
          <Styled.Content>
            <Styled.Title>Share via SMS</Styled.Title>
            <Styled.Text>
              Please enter a phone number to send payment info.
            </Styled.Text>
          </Styled.Content>

          <Formik
            initialValues={initialData}
            validationSchema={schema}
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize
            onSubmit={(values) => {
              onSubmit(values);
              handleCloseModal();
            }}
          >
            {({ errors, handleSubmit }) => {
              return (
                <div>
                  <Row gutter={25}>
                    <Col span={24}>
                      <FormItem label={null} error={errors.phone}>
                        <Input name="phone" placeholder="Enter phone number" />
                      </FormItem>
                    </Col>
                  </Row>

                  <Styled.Button>
                    <Button
                      type="primary"
                      block
                      onClick={() => handleSubmit()}
                    >
                      Send
                    </Button>
                  </Styled.Button>
                </div>
              );
            }}
          </Formik>
        </Styled.Wrapper>
      </Modal>
    </>
  );
};
