import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

type ActiveProp = {
  active: boolean;
}

const HeaderMobile = styled.div<ActiveProp>`
  display: none;

  padding: 30px 20px 25px;

  font-size: 20px;
  line-height: 14px;

  font-family: ${Fonts.NexaRegular};

  overflow: hidden;

  color: #8096A7;

  cursor: pointer;

  @media (max-width: 575px) {
    padding: 30px 15px 25px;
  }

  &::after {
    content: '';

    position: absolute;

    top: ${props => props.active ? '25px' : '32px'};
    right: 20px;

    width: 12px;
    height: 12px;

    border-bottom: 2px solid ${Colors.Primary};
    border-right: 2px solid ${Colors.Primary};

    transform: ${props => props.active ? 'rotate(45deg)' : 'rotate(-135deg)'};

    @media (max-width: 575px) {
      right: 15px;
    }
  }

  @media (max-width: 991px) {
    display: block;
  }
`

const Wrapper = styled.div`
  flex-grow: 1;

  display: flex;

  width: 100%;
  max-width: 1920px;

  margin: 0 auto;

  @media (max-width: 991px) {
    display: block;
  }
`;

const Content = styled.div<ActiveProp>`
  @media (max-width: 991px) {
    display: ${props => props.active ? "block" : "none"};
    padding-top: 10px;
    padding-bottom: 50px;
  }
`

const Sidebar = styled.div`
  position: relative;
  z-index: 1;

  flex-shrink: 0;

  width: 350px;

  padding-top: 42px;

  background-color: ${Colors.Secondary};

  @media (max-width: 991px) {
    position: fixed;
    z-index: 100;

    bottom: 0;
    left: 0;

    width: 100vw;
    max-height: calc(100vh - 200px);

    padding-top: 0;

    border-radius: 15px 15px 0px 0px;
    overflow: auto;
  }

  &::before {
    content: "";

    position: absolute;

    z-index: -1;

    top: 0;
    right: 0;

    width: 2000px;
    height: 100%;

    background-color: ${Colors.Secondary};
  }
`;

const SidebarTitle = styled.p`
  margin-bottom: 0;
  padding-bottom: 7px;

  font-family: ${Fonts.NexaRegular};
  font-size: 20px;
  line-height: 15px;

  color: #8096a7;

  @media (max-width: 1199px) {
    font-size: 17px;
  }

  @media (max-width: 991px) {
    padding: 0 20px 7px;
  }

  @media (max-width: 575px) {
    padding: 0 15px 7px;
  }
`;

type SelectedProp = {
  selected?: boolean;
};

const SidebarLink = styled.p<SelectedProp>`
  position: relative;

  z-index: 1;

  margin-bottom: 0;

  border-bottom: 1px solid #1a2233;

  color: #ffffff;

  cursor: pointer;

  @media (max-width: 1919px) {
    width: calc(100% + 78px);
    margin-left: -78px;
  }

  @media (max-width: 991px) {
    width: 100%;
    margin-left: 0;
  }

  a {
    display: block;

    padding: 15px 0;

    font-family: ${Fonts.NexaRegular};
    font-size: 20px;
    line-height: 20px;
    text-decoration: none;

    color: #fff;

    @media (max-width: 1919px) {
      padding-left: 78px;
    }

    @media (max-width: 1199px) {
      padding: 10px 0 9px;
      padding-left: 78px;
      font-size: 17px;
    }

    @media (max-width: 991px) {
      padding: 10px 20px 9px;
    }

    @media (max-width: 575px) {
      padding: 10px 15px 9px;
    }

    &.active {
      background: #8096A7;

      &::before {
        content: "";

        z-index: -1;

        position: absolute;

        top: 0;
        right: 0;
        left: -2000px;

        height: 100%;

        background: #8096A7;

        @media (max-width: 1919px) {
          content: none;
        }
      }
    }
  }

  &::before {
    content: "";

    z-index: -1;

    position: absolute;

    top: 0;
    right: 0;
    left: -2000px;

    height: 100%;

    background: ${(props) => (props.selected ? "#8096A7" : "transparent")};
  }
`;

export const Styled = {
  Wrapper,
  Content,
  Sidebar,
  HeaderMobile,
  SidebarTitle,
  SidebarLink,
};
