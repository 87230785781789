import { Col } from "antd";
import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

type ActiveProp = {
  active: boolean;
};

type VisibleProp = {
  visible: boolean;
}

// PROFILE

const Wrapper = styled.div<ActiveProp>`
  position: relative;

  align-self: stretch;

  display: flex;
  align-items: center;

  width: 273px;
  height: 50px;
  max-height: 100%;

  padding: 0;

  border: 1px solid ${Colors.LightGray};
  border-radius: 25px;

  background-color: ${props => props.active ? Colors.Primary : "transparent"};

  cursor: pointer;

  @media (max-width: 1450px) {
    width: 200px;
    height: 30px;
  }

  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: auto;

    border: none;

    background-color: transparent;
  }

  &:hover {
    border-color: ${Colors.Primary};

    @media (max-width: 991px) {
      border-color: ${Colors.LightGray};
    }
  }
`;

const Header = styled.div<ActiveProp>`
  position: absolute;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 100%;

  padding: 2px 0 0 10px;

  @media (max-width: 991px) {
    display: none;
    // position: static;

    // height: auto;

    // padding: 8px 48px 5px;
    // padding-left: 24px;

    // & > div {
    //   width: 100%;
    // }
  }

  &::after {
    content: "";

    position: absolute;

    top: 13px;
    right: 26px;

    width: 14px;
    height: 14px;

    border-right: ${props => props.active ? "2px solid #fff" : `2px solid ${Colors.Primary}`};
    border-bottom: ${props => props.active ? "2px solid #fff" : `2px solid ${Colors.Primary}`};

    transform: ${(props) =>
      props.active ? "translateY(6px) rotate(-135deg)" : "rotate(45deg)"};

    @media (max-width: 1450px) {
      top: 7px;
      right: 16px;

      width: 10px;
      height: 10px;
    }

    @media (max-width: 991px) {
      display: none;
    }
  }
`;

const Icon = styled(Col)<ActiveProp>`
  position: relative;

  top: -1px;

  font-size: 30px;

  color: ${props => props.active ? "#fff" : "Colors.Secondary"};

  @media (max-width: 1450px) {
    padding: 0 6px !important;
    font-size: 18px;
  }

  @media (max-width: 991px) {
    color: #8096A7;
  }
`;

const Title = styled(Col)<ActiveProp>`
  display: block;

  max-width: 170px;

  font-family: ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 20px;

  color: ${props => props.active ? "#fff" : "#08243B"};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (max-width: 1450px) {
    font-size: 14px;
  }

  @media (max-width: 991px) {
    max-width: none;

    padding: 0 6px !important;
    padding-top: 2px !important;

    font-size: 20px;

    color: ${Colors.Secondary};
  }
`;

// CONTENT

const Content = styled.div<VisibleProp>`
  z-index: 1;

  position: absolute;

  top: calc(100% + 29px);
  right: 0;

  display: ${props => props.visible ? "block" : "none"};

  width: 375px;

  border-radius: 8px;

  background-color: #fff;
  box-shadow: 0px 10px 99px #0000004D;

  @media (max-width: 991px) {
    position: static;

    display: block;

    width: 100%;

    padding: 10px 15px 30px;

    border-radius: 0;

    box-shadow: none;
  }

  &::before {
    content: '';

    position: absolute;

    bottom: 100%;
    right: 25px;

    border-bottom: 15px solid #fff;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;

    @media (max-width: 991px) {
      display: none;
    }
  }
`;

// const AddBusiness = styled.div`
//   display: flex;
//   align-items: center;

//   margin: 0 -24px;
//   margin-bottom: 23px;
//   padding: 9px 24px;

//   background-color: #fe8e75;

//   color: #fff;

//   @media (max-width: 1450px) {
//     margin: 0 -44px;
//     margin-bottom: 29px;
//     padding: 12px 44px 11px;
//   }

//   @media (max-width: 1199px) {
//     margin: 0 -20px;
//     margin-bottom: 29px;
//     padding: 12px 20px 11px;
//   }

//   & > span + span {
//     padding-top: 5px;

//     font-family: ${Fonts.NexaRegular};
//     font-size: 18px;
//     line-height: 18px;

//     @media (max-width: 1450px) {
//       font-size: 20px;
//       line-height: 22px;
//     }
//   }

//   svg {
//     margin-right: 14px;
//     font-size: 23px;
//   }
// `;

const ContentHeader = styled.div`
  padding: 16px 20px;
  border-bottom: 1px solid #d3d3d3;
  
  @media (max-width: 991px) {
    padding: 0 16px 12px;
  }
`

const SelectedItemTitle = styled.div`
  margin-bottom: 3px;

  font-family: ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 20px;

  color: ${Colors.Black};

  @media (max-width: 991px) {
    margin-bottom: 0;
    font-size: 14px;
  }
`;

const SelectedItemStatus = styled.div`
  margin-bottom: 0px;

  font-family: ${Fonts.NexaRegular};
  font-size: 14px;
  line-height: 100%;

  color: ${Colors.Red};
`;

// const List = styled.div`
//   margin-bottom: 28px;
//   padding: 0 10px;

//   @media (max-width: 1450px) {
//     max-height: 300px;
//     // margin: 0 -44px 35px;
//     // padding: 0 44px;
//     overflow: auto;
//   }

//   @media (max-width: 1199px) {
//     // margin: 0 -20px 35px;
//     // padding: 0 20px;
//   }
// `;

// type ListItemProps = {
//   selected: boolean;
// };

// const ListItem = styled.p<ListItemProps>`
//   // margin: 0 -24px;
//   // padding: 13px 24px 10px;

//   font-family: ${Fonts.NexaRegular};
//   font-size: 18px;
//   line-height: 15px;

//   border-top: 1px solid #1a2233;

//   background-color: ${props => props.selected ? "#FE8E7566" : "#283549"};

//   color: #fff;

//   @media (max-width: 1450px) {
//     // margin: 0 -44px;
//     // padding: 15px 44px 12px;
//     font-size: 20px;
//     line-height: 22px;
//     border-color: #e1e0e3;
//     background-color: ${props => props.selected ? "#FE8E7566" : "#fff"};
//     color: #283549;
//   }

//   @media (max-width: 1199px) {
//     // margin: 0 -20px;
//     // padding: 15px 20px 12px;
//   }

//   &:last-child {
//     border-bottom: 1px solid #1a2233;

//     @media (max-width: 1450px) {
//       border-bottom: none;
//     }
//   }
// `;

const ContentBody = styled.div`
  padding: 10px 10px 20px;

  @media (max-width: 991px) {
    padding: 15px 0 0;
  }
`

const ContentGroup = styled.div`
  border-radius: 8px;

  font-family: ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 20px;

  background-color: #f2f2f2;
  color: #08243B;

  overflow: hidden;

  @media (max-width: 991px) {
    font-size: 14px;
  }
`

const ContentGroupItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 14px 16px 8px;

  border-bottom: 1px solid #d3d3d3;

  @media (max-width: 991px) {
    padding: 10px 16px 7px;
  }

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #e5e5e5;
  }

  svg {
    font-size: 25px;
    color: #08243B;

    @media (max-width: 991px) {
      font-size: 22px;
    }
  }
`

export const Styled = {
  Wrapper,
  Header,
  Icon,
  Title,
  Content,
  // AddBusiness,

  ContentHeader,
  SelectedItemTitle,
  SelectedItemStatus,

  // List,
  // ListItem,
  ContentBody,
  ContentGroup,
  ContentGroupItem,
};
