import styled from "styled-components";

const Logo = styled.img`
  width: 125px;

  @media (max-width: 1450px) {
    width: 80px;
  }

  @media (max-width: 575px) {
    width: 78px;
  }
`;

const DCLogo = styled.img`
  width: 125px;

  @media (max-width: 1450px) {
    width: 80px;
  }

  @media (max-width: 575px) {
    width: 78px;
  }
`;

const ArivPayLogo = styled.img`
  width: 125px;

  @media (max-width: 1450px) {
    width: 80px;
  }

  @media (max-width: 575px) {
    width: 78px;
  }
`;

const BrandedLogo = styled.img`
  max-width: 125px;
  max-height: 50px;
  width: auto;
  height: auto;

  @media (max-width: 1450px) {
    max-width: 80px;
    max-height: 35px;
  }

  @media (max-width: 575px) {
    max-width: 78px;
    max-height: 35px;
  }
`

export const Styled = {
  Logo,
  DCLogo,
  ArivPayLogo,
  BrandedLogo,
};
