import { Outlet } from "react-router-dom";
import { SignUpForm } from "./SignUpForm";
// import { SignUpConfirm } from "./SignUpConfirm";

const SignUpWrapper: React.FC = () => {
  return <Outlet />;
};

export const SignUpSc = {
  Wrapper: SignUpWrapper,
  Form: SignUpForm,
  // Confirm: SignUpConfirm,
};
