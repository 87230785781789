import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

export const H1 = styled.p`
  margin-top: 0;
  margin-bottom: 30px;

  font-family: ${Fonts.NexaRegular};
  font-size: 50px;
  line-height: 120%;
  letter-spacing: 0px;

  color: ${Colors.Black};

  @media (max-width: 1199px) {
    margin-bottom: 35px;
    font-size: 40px;
  }

  @media (max-width: 575px) {
    margin-bottom: 32px;
    font-size: 35px;
    line-height: 114%;
  }
`;

export const H2 = styled.p`
  margin-top: 0;
  margin-bottom: 0;

  font-family ${Fonts.NexaRegular};
  font-size: 25px;
  line-height: 100%;
  letter-spacing: 0px;

  color: ${Colors.Black};

  @media (max-width: 1199px) {
    font-size: 20px;
  }
`;

export const Body1 = styled.p`
  margin-top: 0;
  margin-bottom: 0;

  font-family ${Fonts.NexaRegular};
  font-size: 20px;
  line-height: 100%;
  letter-spacing: 0px;

  color: ${Colors.Black};

  @media (max-width: 1199px) {
    font-size: 17px;
  }
`;

export const Body2 = styled.p`
  margin-top: 0;
  margin-bottom: 0;

  font-family ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 100%;
  letter-spacing: 0px;

  color: ${Colors.Black};

  @media (max-width: 1199px) {
    font-size: 14px;
  }
`;

export const Caption = styled.p`
  margin-top: 0;
  margin-bottom: 0;

  font-family ${Fonts.NexaRegular};
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0px;

  color: ${Colors.Black};

  @media (max-width: 1199px) {
    font-size: 12px;
  }
`;

export const Styled = {
  H1,
  H2,
  Body1,
  Body2,
  Caption,
};
