/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SysI18NDTO } from '../models/SysI18NDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { network } from 'services/network';

export class SysI18NControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Update locale
     * Update locale
     * @param requestBody
     * @returns SysI18NDTO
     * @returns any Created
     * @throws ApiError
     */
    public updateUsingPut(
        requestBody?: SysI18NDTO,
    ): CancelablePromise<SysI18NDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'PUT',
                url: '/system/locales',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Create locale
     * Create locale
     * @param requestBody
     * @returns SysI18NDTO
     * @returns any Created
     * @throws ApiError
     */
    public createUsingPost(
        requestBody?: SysI18NDTO,
    ): CancelablePromise<SysI18NDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/system/locales',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Find locales
     * Find locales (LIKE)
     * @param limit limit
     * @param offset offset
     * @param id id
     * @param locale locale
     * @param value value
     * @returns SysI18NDTO
     * @throws ApiError
     */
    public findUsingGet(
        limit: number,
        offset: number,
        id?: string,
        locale?: string,
        value?: string,
    ): CancelablePromise<Array<SysI18NDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/system/locales/find',
                query: {
                    'id': id,
                    'locale': locale,
                    'value': value,
                    'limit': limit,
                    'offset': offset,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Delete locale
     * Delete locale
     * @param id id
     * @returns any
     * @throws ApiError
     */
    public deleteUsingDelete(
        id: string,
    ): CancelablePromise<any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'DELETE',
                url: '/system/locales/{id}',
                path: {
                    'id': id,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                },
            })
        });
    }

}