/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ApplicationEntity } from './ApplicationEntity';
import type { CountryEntity } from './CountryEntity';
import type { DocumentRequestEntity } from './DocumentRequestEntity';
import type { MerchantEntity } from './MerchantEntity';
import type { PartnerEntity } from './PartnerEntity';
import type { UserEntity } from './UserEntity';

export type ProfileEntity = {
    applications?: Array<ApplicationEntity>;
    country?: CountryEntity;
    created?: string;
    documentRequests?: Array<DocumentRequestEntity>;
    id?: number;
    kind?: ProfileEntity.kind;
    merchant?: MerchantEntity;
    modified?: string;
    name?: string;
    partner?: PartnerEntity;
    selected?: boolean;
    status?: ProfileEntity.status;
    user?: UserEntity;
    version?: number;
};

export namespace ProfileEntity {

    export enum kind {
        COMPANY = 'COMPANY',
        SOLE_TRADER = 'SOLE_TRADER',
    }

    export enum status {
        APPROVED = 'APPROVED',
        BLOCKED = 'BLOCKED',
        DOCUMENT_REQUESTED = 'DOCUMENT_REQUESTED',
        NOT_FINISHED = 'NOT_FINISHED',
        NOT_STARTED = 'NOT_STARTED',
        REJECTED = 'REJECTED',
        UNDER_REVIEW = 'UNDER_REVIEW',
    }


}
