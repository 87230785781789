import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { markAsReadThunk } from "../thunks";

type UseMarkAsRead = () => {
  markAsRead: (id: number) => void;
};

export const useMarkAsRead: UseMarkAsRead = () => {
  const dispatch = useDispatch();

  const markAsRead = useCallback(
    (id) => {
      dispatch(markAsReadThunk(id));
    },
    [dispatch]
  );

  return {
    markAsRead,
  };
};
