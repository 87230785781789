/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ComplianceTaskDTO } from '../models/ComplianceTaskDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { network } from 'services/network';

export class ComplianceControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Run compliance checks
     * Run compliance checks
     * @param profileId profileId
     * @returns ComplianceTaskDTO
     * @returns any Created
     * @throws ApiError
     */
    public runComplianceChecksUsingPost(
        profileId: number,
    ): CancelablePromise<ComplianceTaskDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/compliance/run-compliance-checks',
                query: {
                    'profileId': profileId,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Find compliance checks
     * Find compliance checks
     * @param limit limit
     * @param offset offset
     * @param statuses statuses
     * @param profileIds profileIds
     * @param types types
     * @returns ComplianceTaskDTO
     * @throws ApiError
     */
    public findTasksUsingGet(
        limit: number,
        offset: number,
        statuses?: 'FAILED' | 'FINISHED' | 'IN_PROGRESS' | 'NEW',
        profileIds?: number,
        types?: 'AML_LEXIS_NEXIS',
    ): CancelablePromise<Array<ComplianceTaskDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/compliance/tasks',
                query: {
                    'statuses': statuses,
                    'profileIds': profileIds,
                    'types': types,
                    'limit': limit,
                    'offset': offset,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

}