/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JwtResponse } from '../models/JwtResponse';
import type { LoginRequest } from '../models/LoginRequest';
import type { ResponseEntity } from '../models/ResponseEntity';
import type { SignupRequestDTO } from '../models/SignupRequestDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { network } from 'services/network';

export class AuthControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Confirm email
     * Confirm email
     * @param token token
     * @param isPartner isPartner
     * @returns any
     * @throws ApiError
     */
    public confirmEmailUsingPost(
        token: string,
        isPartner?: boolean,
    ): CancelablePromise<any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/auth/confirm-email',
                query: {
                    'token': token,
                    'isPartner': isPartner,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Request reset password
     * Send message to passed email with link for resetting password
     * @param recaptchaResponse recaptchaResponse
     * @param email email
     * @param isPartner isPartner
     * @returns any
     * @throws ApiError
     */
    public requestResetPasswordUsingPost(
        recaptchaResponse: string,
        email: string,
        isPartner?: boolean,
    ): CancelablePromise<any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/auth/request-reset-password',
                query: {
                    'recaptchaResponse': recaptchaResponse,
                    'email': email,
                    'isPartner': isPartner,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Resend confirmation link
     * Resend confirmation link
     * @param userId userId
     * @param isPartner isPartner
     * @returns any
     * @throws ApiError
     */
    public resendConfirmationLinkUsingPost(
        userId: number,
        isPartner?: boolean,
    ): CancelablePromise<any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/auth/resend-confirmation-link/{userId}',
                path: {
                    'userId': userId,
                },
                query: {
                    'isPartner': isPartner,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Resend OTP code
     * Resend OTP code
     * @param email email
     * @returns any
     * @throws ApiError
     */
    public resendOtpCodeUsingPost(
        email: string,
    ): CancelablePromise<any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/auth/resend-otp-code',
                query: {
                    'email': email,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Reset password
     * Reset password by token
     * @param token token
     * @param password password
     * @param isPartner isPartner
     * @returns any
     * @throws ApiError
     */
    public resetPasswordUsingPost(
        token: string,
        password: string,
        isPartner?: boolean,
    ): CancelablePromise<any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/auth/reset-password',
                query: {
                    'token': token,
                    'password': password,
                    'isPartner': isPartner,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Set password
     * Set password for USER (by ADMIN hands)
     * @param userId userId
     * @param password password
     * @returns any
     * @throws ApiError
     */
    public setPasswordUsingPost(
        userId: number,
        password: string,
    ): CancelablePromise<any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/auth/set-password',
                query: {
                    'userId': userId,
                    'password': password,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Sign in
     * Sign in
     * @param requestBody
     * @returns JwtResponse
     * @returns any Created
     * @throws ApiError
     */
    public loginUsingPost(
        requestBody?: LoginRequest,
    ): CancelablePromise<JwtResponse | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/auth/signin',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Sign up
     * Sign up
     * @param requestBody
     * @returns ResponseEntity
     * @returns any Created
     * @throws ApiError
     */
    public registrationUsingPost(
        requestBody?: SignupRequestDTO,
    ): CancelablePromise<ResponseEntity | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/auth/signup',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

}