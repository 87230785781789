import { appClientSunset } from "services";
import { appClient } from "services";
import { IAuthApi } from "./index";

export const api: IAuthApi = {
  signUp: (data) => {
    return appClientSunset.authController.registrationUsingPost(data);
  },

  signIn: (data) => {
    return appClientSunset.authController.loginUsingPost(data);
  },
  resendOtpCode: (email: string) => {
    return appClientSunset.authController.resendOtpCodeUsingPost(email)
  },
  requestResetPassword: (recaptchaResponse: string, email: string, isPartner = false) => {
    return appClientSunset.authController.requestResetPasswordUsingPost(recaptchaResponse, email, isPartner);
  },
  changePassword: (token: string, password: string, isPartner = false) => {
    return appClientSunset.authController.resetPasswordUsingPost(
      token,
      password,
      isPartner,
    );
  },
  confirmEmail: (token: string, isPartner = false) => {
    return appClientSunset.authController.confirmEmailUsingPost(token, isPartner);
  },
  resendConfirmationLink: (id) => {
    return appClientSunset.authController.resendConfirmationLinkUsingPost(id);
  },

  updateFrtWeb: (frt: string) => {
    return appClient.userController.updateUserFirebaseRegistrationTokenWebUsingPut(
      frt
    );
  },
  updateFrtMobile: (frt: string) => {
    return appClient.userController.updateUserFirebaseRegistrationTokenUsingPut(
      frt
    );
  },
  userMe: () => {
    return appClient.userController.getMeUsingGet();
  },
};
