import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { confirmEmailSellerThunk } from "data-layer/employee/thunks";

type UseSellerConfirmEmail = () => {
  confirmEmailSeller: (token: string) => void;
};

export const useSellerConfirmEmail: UseSellerConfirmEmail = () => {
  const dispatch = useDispatch();

  const confirmEmailSeller = useCallback(
    (token: string) => {
      dispatch(confirmEmailSellerThunk(token));
    },
    [dispatch]
  );

  return {
    confirmEmailSeller,
  };
};
