import { CancelablePromise, EmployeePermissionDTO } from "generated";
import { api } from "./api";

export interface IEmployeePermissionsApi {
  getEmployeePermissions: () => CancelablePromise<Array<EmployeePermissionDTO>>
}

class EmployeePermissionsApi {
  api: IEmployeePermissionsApi = api;

  constructor() {
    if (process.env.REACT_APP_MOCK_ENABLED === "true") {
      this.api = api;
      return;
    }

    this.api = api;
  }
}

export const employeePermissionsApi = new EmployeePermissionsApi();
