import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { notificactionsActions } from "../notificactionsSlice";

type UseNotifications = () => {
  showModalStart: VoidFunction;
  hideModalStart: VoidFunction;
};

export const useNotifications: UseNotifications = () => {
  const dispatch = useDispatch();

  const showModalStart = useCallback((): void => {
    dispatch(notificactionsActions.showModalStartNotification());
  }, [dispatch]);

  const hideModalStart = useCallback((): void => {
    dispatch(notificactionsActions.hideModalStartNotification());
  }, [dispatch]);

  return {
    showModalStart,
    hideModalStart,
  };
};
