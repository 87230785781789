import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TransactionRestDTO, TransactionsSellerDashboardDTO } from "generated";

export type Transactions = TransactionRestDTO[] | [];
export type TransactionsSeller = TransactionsSellerDashboardDTO[] | [];

export type TransactionsInitialState = {
  data: Transactions;
  isFetching: boolean;

  dataAmount: TransactionsSeller;
  isFetchingAmount: boolean;

  dataAverage: TransactionsSeller;
  isFetchingAverage: boolean;

  dataQuantity: TransactionsSeller;
  isFetchingQuantity: boolean;
};

const initialState: TransactionsInitialState = {
  data: [],
  isFetching: false,

  dataAmount: [],
  isFetchingAmount: false,

  dataAverage: [],
  isFetchingAverage: false,

  dataQuantity: [],
  isFetchingQuantity: false,
};

export const transactionsSlice = createSlice({
  name: "transactions",
  initialState: initialState,
  reducers: {
    getTransactionsRequest: (state) => {
      state.isFetching = true;
    },
    getTransactionsSuccess: (
      state,
      action: PayloadAction<Array<TransactionRestDTO>>
    ) => {
      state.data = action.payload;
      state.isFetching = false;
    },
    getTransactionsFailure: (state) => {
      state.isFetching = false;
    },

    getTransactionsAmountRequest: (state) => {
      state.isFetchingAmount = true;
    },
    getTransactionsAmountSuccess: (
      state,
      action: PayloadAction<Array<TransactionsSellerDashboardDTO>>
    ) => {
      state.dataAmount = action.payload;
      state.isFetchingAmount = false;
    },
    getTransactionsAmountFailure: (state) => {
      state.isFetchingAmount = false;
    },

    getTransactionsAverageRequest: (state) => {
      state.isFetchingAverage = true;
    },
    getTransactionsAverageSuccess: (
      state,
      action: PayloadAction<Array<TransactionsSellerDashboardDTO>>
    ) => {
      state.dataAverage = action.payload;
      state.isFetchingAverage = false;
    },
    getTransactionsAverageFailure: (state) => {
      state.isFetchingAverage = false;
    },

    getTransactionsQuantityRequest: (state) => {
      state.isFetchingQuantity = true;
    },
    getTransactionsQuantitySuccess: (
      state,
      action: PayloadAction<Array<TransactionsSellerDashboardDTO>>
    ) => {
      state.dataQuantity = action.payload;
      state.isFetchingQuantity = false;
    },
    getTransactionsQuantityFailure: (state) => {
      state.isFetchingQuantity = false;
    },
  },
});

export const transactionsActions = transactionsSlice.actions;
