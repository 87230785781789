import { CompanyItemDTO } from "generated";
import { useCallback } from "react";
import { сompanyApi } from "../api";

type UseGetCompany = () => {
  getCompany: (data: CompanyItemDTO) => void;
};

export const useGetCompany: UseGetCompany = () => {
  const getCompany = useCallback(async (data) => {
    try {
      const result = await сompanyApi.api.getCompany(data);
      return result;
    } catch (e) {

    } finally {}
  }, []);

  return {
    getCompany,
  };
};
