import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Role, testRoleSlice } from "../testRoleSlice";

type UseSetTestRole = () => {
  setTestRole: (role: Role) => void;
};

export const useSetTestRole: UseSetTestRole = () => {
  const dispatch = useDispatch();

  const setTestRole = useCallback(
    (role: Role): void => {
      dispatch(testRoleSlice.actions.setTestRole(role));
    },
    [dispatch]
  );

  return {
    setTestRole,
  };
};
