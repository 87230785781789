import { appClient } from "services";
import { ITransactionsApi } from "./index";

export const api: ITransactionsApi = {
  getTransactions: (
    limit,
    offset,
    types,
    amountMax,
    amountMin,
    employeeIds,
    employeeName,
    from,
    merchantIds,
    orderBy0Direction,
    orderBy0Field,
    partnerIds,
    payoutIds,
    to
  ) => {
    return appClient.transactionController.findAllAsMerchantUsingGet(
      limit,
      offset,
      types,
      amountMax,
      amountMin,
      employeeIds,
      employeeName,
      from,
      merchantIds,
      orderBy0Direction,
      orderBy0Field,
      partnerIds,
      payoutIds,
      to
    );
  },
  getTransactionsAmount: (
    merchantIds,
    from,
    to,
    aggregateBy,
    aggregateTime
  ) => {
    return appClient.transactionController.getTransactionsAmountUsingGet(
      merchantIds,
      from,
      to,
      aggregateBy,
      aggregateTime
    );
  },
  getTransactionsAverage: (
    merchantIds,
    from,
    to,
    aggregateBy,
    aggregateTime
  ) => {
    return appClient.transactionController.getTransactionsAverageUsingGet(
      merchantIds,
      from,
      to,
      aggregateBy,
      aggregateTime
    );
  },
};
