import {
  SignupRequestDTO,
  LoginRequest,
  JwtResponse,
  ResponseEntity,
} from "generatedSunset";
import { UserDTO } from "generated";
import { CancelablePromise } from "generatedSunset/core/CancelablePromise";
import { api } from "./api";

export interface IAuthApi {
  signUp: (data: SignupRequestDTO) => CancelablePromise<ResponseEntity | any>;
  signIn: (data: LoginRequest) => CancelablePromise<JwtResponse | any>;
  resendOtpCode: (email: string)=> CancelablePromise<any>;
  requestResetPassword: (recaptchaResponse: string, email: string, isPartner?: boolean) => CancelablePromise<any>;
  changePassword: (token: string, password: string, isPartner?: boolean) => CancelablePromise<any>;
  confirmEmail: (token: string, isPartner?: boolean) => CancelablePromise<any>;
  updateFrtWeb: (frt: string) => CancelablePromise<UserDTO | any>;
  updateFrtMobile: (frt: string) => CancelablePromise<UserDTO | any>;
  userMe: () => CancelablePromise<UserDTO>;
  resendConfirmationLink: (userId: number) => CancelablePromise<any>;
}

class AuthApi {
  api: IAuthApi = api;

  constructor() {
    if (process.env.REACT_APP_MOCK_ENABLED === "true") {
      this.api = api;
      return;
    }

    this.api = api;
  }
}

export const authApi = new AuthApi();
