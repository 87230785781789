import { ColumnsType } from "antd/lib/table";
import { FlatApplicationDTO, ParseImportFileResultDTO } from "generated";

type UseColumns = (data: ParseImportFileResultDTO | null) => {
  columns: ColumnsType<FlatApplicationDTO> | [];
};

export const useColumns: UseColumns = (
  data: ParseImportFileResultDTO | null
) => {
  const columns: ColumnsType<FlatApplicationDTO> = [];

  if (data?.parsed?.length) {
    Object.keys(data.parsed[0]).forEach((key) => {
      columns.push({
        title: key,
        dataIndex: key,
        key,
      });
    });
  }

  return {
    columns,
  };
};
