import { loaderSlice } from "data-layer/common/loaderSlice";
import { selectedProfileSelector } from "data-layer/profile/selectors";
import { AppDispatch, AppState } from "data-layer/store/store";
import {
  MerchantDTO,
  ProfileDTO,
  SettlementDetailsAttemptUpdateDTO,
} from "generated";
import { handleNetworkError } from "utils/handleNetworkError";
import { showSuccess } from "utils/showSuccess";
import { showWarning } from "utils/showWarning";
import { merchantApi } from "./api";
import { merchantSlice } from "./slice";
import { MerchantsParams } from "./types/MerchantsParams";

export const getMerchantsThunk = (params: MerchantsParams) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      dispatch(merchantSlice.actions.getMerchantsRequest());
      const { limit, offset, name } = params;
      const result = await merchantApi.api.getMerchants(limit, offset, name);

      dispatch(merchantSlice.actions.getMerchantsSuccess(result));
    } catch (e) {
      handleNetworkError(e, "Merchants loading error");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
      dispatch(merchantSlice.actions.getMerchantsFailure());
    }
  };
};

export const getMerchantPartnerCountThunk = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      dispatch(merchantSlice.actions.getMerchantPartnerCountRequest());
      const result = await merchantApi.api.getMerchantsPartnerCount();

      dispatch(merchantSlice.actions.getMerchantPartnerCountSuccess(result));
    } catch (e) {
      handleNetworkError(e, "Merchant loading error");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
      dispatch(merchantSlice.actions.getMerchantPartnerCountFailure());
    }
  };
};

export const getMeThunk = () => {
  return async (dispatch: AppDispatch, getState: () => AppState) => {
    dispatch(merchantSlice.actions.clearMerchant());

    const state = getState();
    const selectedProfile = selectedProfileSelector(state);

    if (selectedProfile?.status !== ProfileDTO.status.APPROVED) {
      return;
    }

    dispatch(loaderSlice.actions.showLoader());

    try {
      dispatch(merchantSlice.actions.getMeRequst());
      const result = await merchantApi.api.me();

      dispatch(merchantSlice.actions.getMeSuccess(result));
    } catch (e) {
      throw e;
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
      dispatch(merchantSlice.actions.getMeFailure());
    }
  };
};

export const updateMerchantThunk = (body: MerchantDTO) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      const result = await merchantApi.api.updateMerchant(body);

      dispatch(merchantSlice.actions.updateMeSuccess(result));
      showSuccess("Merchant changed");
    } catch (e) {
      handleNetworkError(e, "Update error");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};

export const updateSettlementsThunk = (token: string, cb?: VoidFunction) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      const result = await merchantApi.api.updateMerchantSettlement(token);

      dispatch(merchantSlice.actions.updateMeSuccess(result));
      showSuccess("Bank account information updated");
    } catch (e) {
      handleNetworkError(e, "Update error");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
      if (cb) {
        cb();
      }
    }
  };
};

export const attemptSettlementsThunk = (
  body: SettlementDetailsAttemptUpdateDTO,
  cb?: VoidFunction
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      await merchantApi.api.attemptMerchantSettlement(body);

      if (cb) {
        cb();
      }
      showWarning(
        "Check your email to complete verification of your new bank account"
      );
    } catch (e) {
      handleNetworkError(e, "Update error");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};
