import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";
import { formItemHeight } from "ui/style-guide/formItem";
import { FormItemHintAlign } from ".";

type ErrorProp = {
  error?: boolean;
  longError?: boolean;
};

const Wrapper = styled.div<ErrorProp>`
  position: relative;
  padding-bottom: 26px;

  @media (max-width: 1199px) {
    padding-bottom: 23px;
  }

  .ant-select,
  .ant-picker,
  .ant-input-affix-wrapper,
  input {
    height: ${formItemHeight}px;

    border-color: ${(props) => (props.error ? Colors.Red : "#00000014")};

    @media (max-width: 1199px) {
      height: 40px;
    }
  }

  .ant-picker {
    width: 100%;
  }

  .ant-input-affix-wrapper {
    padding: 0 16px;
    padding-right: ${(props) => (props.error && props.longError ? 30 : 16)}px;

    border-width: 1px;
    border-style: solid;
    box-shadow: 0px 0.5px 0px #0000000f;

    background-color: #fff;

    input {
      padding-top: 12px;
      padding-bottom: 7px;
      border: none;
      box-shadow: none;
      background-color: transparent;
    }

    .ant-select-selector {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  .ant-select-auto-complete .ant-select-selector {
    padding-left: 0 !important;
  }

  .ant-select-selector {
    height: 100% !important;

    .ant-input-affix-wrapper {
      border: none;
      box-shadow: none;
      background-color: transparent;
    }
  }

  .ant-input-affix-wrapper input {
    height: 100% !important;
  }

  .ant-picker-input {
    input {
      height: 100%;
    }
  }

  .ant-select-selector {
    padding-left: 16px !important;
  }

  .ant-select,
  input,
  textarea {
    font-family: ${Fonts.NexaRegular};
    font-size: 20px;
    line-height: 100%;

    color: ${Colors.Black};

    @media (max-width: 1199px) {
      font-size: 17px;
    }

    ::placeholder {
      color: #00000040;
    }
    :-ms-input-placeholder {
      color: #00000040;
    }
    ::-ms-input-placeholder {
      color: #00000040;
    }
  }

  .ant-select {
    width: 100%;
  }

  input,
  textarea {
    padding-left: 16px;
    padding-right: ${(props) => (props.error && props.longError ? 45 : 16)}px;
    border-radius: 8px;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
`;

const Label = styled.p`
  margin-bottom: 6px;

  font-family: ${Fonts.NexaRegular};
  font-size: 14px;
  line-height: 18px;

  color: #8096a7;

  @media (max-width: 1199px) {
    margin-bottom: 6px;
    font-size: 12px;
    line-height: 100%;
  }
`;

const TopAction = styled.p`
  margin-bottom: 6px;

  font-family: ${Fonts.NexaRegular};
  font-size: 14px;
  line-height: 18px;

  color: #8096a7;

  @media (max-width: 1199px) {
    margin-bottom: 6px;
    font-size: 12px;
    line-height: 100%;
  }
`;

const InputWrapper = styled.div`
  position: relative;
`;

type HintProps = {
  danger?: boolean;
  align?: FormItemHintAlign;
};

const Hint = styled.div`
  position: absolute;

  top: calc(100% - 18px);
  left: 0;

  width: 100%;
`;

const HintText = styled.p<HintProps>`
  margin-bottom: 0px;

  font-family: ${Fonts.NexaRegular};
  font-size: 14px;
  line-height: 130%;
  text-align: right;
  letter-spacing: 0;

  color: ${(props) => (props.danger ? Colors.Red : "#3C3C4366")};

  @media (max-width: 1199px) {
    font-size: 12px;
  }
`;

const ErrorIcon = styled.div`
  position: absolute;
  top: 16px;
  right: 22px;

  svg {
    font-size: 20px;
    color: ${Colors.Red};
    cursor: pointer;
  }
`;

const ErrorPopoverContent = styled.div`
  font-family: ${Fonts.NexaRegular};
  font-size: 12px;
  line-height: 16px;

  color: #000000;
`;

const inputSuggestion = styled.div`
  margin-bottom: 6px;

  font-family: ${Fonts.NexaRegular};
  font-size: 14px;
  line-height: 18px;

  color: #8096a7;

  @media (max-width: 1199px) {
    margin-bottom: 6px;
    font-size: 12px;
    line-height: 100%;
  }
`;


export const Styled = {
  Wrapper,
  Label,
  TopAction,
  InputWrapper,
  Hint,
  HintText,
  ErrorIcon,
  ErrorPopoverContent,
  inputSuggestion,
};
