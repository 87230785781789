import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { documentsActions } from "../slice";

type UseModal = () => {
  showModal: (id: number) => void;
  closeModal: () => void;
};

export const useModal: UseModal = () => {
  const dispatch = useDispatch();

  const showModal = useCallback(
    (id: number) => {
      dispatch(documentsActions.setActiveModal(id));
    },
    [dispatch]
  );

  const closeModal = useCallback(() => {
    dispatch(documentsActions.closeActiveModal());
  }, [dispatch]);

  return {
    showModal,
    closeModal,
  };
};
