import { Navigate } from "react-router-dom";
import { useEmployee } from "data-layer/employee";
import { PrivateRouteProps } from "./types";
import { showError } from "utils/showError";
import { useEffect } from "react";

export const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
  const { children, accessRole } = props;
  const { employee } = useEmployee();

  useEffect(() => {
    if (
      employee.authorized &&
      accessRole &&
      employee.user &&
      !employee.user.roles?.find((role) => role.name === accessRole)
    ) {
      showError("Sorry you don't have access to the app");
    }
  }, [employee]);

  if (!employee.authorized) {
    return (
      <Navigate
        to="/signin"
        state={{ from: location.pathname + location.search }}
      />
    );
  }

  // если проверяем по роли, смотрим есть ли у пользователя данная роль
  if (
    accessRole &&
    employee.user &&
    !employee.user.roles?.find((role) => role.name === accessRole)
  ) {
    return <Navigate to="/signin" />;
  }

  return children;
};
