/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { network } from 'services/network';

export class CdxWebhookControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * immediateSetupHandler
     * @param requestBody
     * @returns string OK
     * @returns any Created
     * @throws ApiError
     */
    public immediateSetupHandlerUsingPost(
        requestBody?: string,
    ): CancelablePromise<string | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/webhook/cdx/is',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

}