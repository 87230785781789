import { notification } from "antd";
import { IconType } from "antd/lib/notification";
import { AppDispatch } from "data-layer/store/store";
import { AbstractEventVO } from "generated";
import { showError } from "utils/showError";
import { whiteListToShow } from ".";
import { notificationsApi } from "./api";
import { getNotificationData, NotificationType } from "./getNotificationData";
import { notificationsSlice } from "./slice";
import { NotificationsParams } from "./types/NotificationsParams";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "data-layer/store";
import { ConfigProvider } from "antd";
import enGb from "antd/lib/locale/en_GB";

const buttonStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minWidth: 150,
  height: 30,
  marginTop: 15,
  paddingTop: 3,
  fontFamily: "Nexa Regular",
  fontSize: 16,
  lineHeight: "30px",
  border: "1px solid #FE8E75",
  borderRadius: 25,
  background: "#FFFFFF",
  color: "#FE8E75",
  cursor: "pointer",
};

const getNotificationType = (_type: NotificationType): IconType | undefined => {
  switch (_type) {
    case NotificationType.Default: {
      return undefined;
    }
    case NotificationType.Warning: {
      return "warning";
    }
    case NotificationType.Success: {
      return "success";
    }
    case NotificationType.Error: {
      return "error";
    }
    default: {
      return undefined;
    }
  }
};

const closeNotification = (key: string) => {
  notification.close(key);
}

const getIconColor = (_type: NotificationType): string => {
  switch (_type) {
    case NotificationType.Default: {
      return "#E5E5E5";
    }
    case NotificationType.Warning: {
      return "#FF9500";
    }
    case NotificationType.Success: {
      return "#30D158";
    }
    case NotificationType.Error: {
      return "#FF3B30";
    }
    default: {
      return "#E5E5E5";
    }
  }
};

export const getNotificationsThunk = (params: NotificationsParams) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(notificationsSlice.actions.getNotificationsRequest());

      const { limit, offset } = params;
      const result = await notificationsApi.api.getNotifications(limit, offset);

      dispatch(notificationsSlice.actions.getNotificationsSuccess(result));
    } catch (e) {
      showError("Notifications loading error", e?.body?.error || e.message);
    } finally {
      dispatch(notificationsSlice.actions.getNotificationsFailure());
    }
  };
};

export const showNotificationThunk = (_payload: any) => {
  return () => {
    const rawEvent = _payload?.data?.rawEvent;
    const id = _payload.messageId;

    let payload;
    if (rawEvent) {
      try {
        payload = JSON.parse(rawEvent) as AbstractEventVO;

        if (!payload || !whiteListToShow.includes(payload.action!)) {
          return;
        }
      } catch {}
    }

    const data = getNotificationData(undefined, payload);

    if (!data) {
      return null;
    }

    const { title, description, type, action } = data;

    const iconStyle = {
      width: 15,
      height: 15,
      borderRadius: "50%",
      backgroundColor: getIconColor(type),
    };

    notification.open({
      key: id,
      message: title,
      description: (
        <ReduxProvider store={store}>
          <ConfigProvider locale={enGb}>
            <div>
              {description && <div>{description}</div>}
              {action ? (
                action.Element ? (
                  <action.Element onClose={() => closeNotification(id)} />
                ) : (
                  <button
                    style={buttonStyle}
                    onClick={() => {
                      action.cb!()
                      closeNotification(id)
                    }}
                  >
                    {action.title}
                  </button>
                )
              ) : null}
            </div>
          </ConfigProvider>
        </ReduxProvider>
      ),
      type: getNotificationType(type),
      icon: <div style={iconStyle} />,
      duration: 10,
    });
  };
};

export const markAsReadThunk = (id: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(notificationsSlice.actions.markAsReadRequest(id));

      const result = await notificationsApi.api.markAsRead(id);

      dispatch(notificationsSlice.actions.markAsReadSuccess(result));
    } catch (e) {
    } finally {
      dispatch(notificationsSlice.actions.markAsReadFailure(id));
    }
  };
};

export const markAllAsReadThunk = (ids?: number[]) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(notificationsSlice.actions.markAllAsReadRequest());

      await notificationsApi.api.markAllAsRead(ids);

      dispatch(notificationsSlice.actions.markAllAsReadSuccess());
    } catch (e) {
    } finally {
      dispatch(notificationsSlice.actions.markAllAsReadFailure());
    }
  };
};
