import { CreateUpdateProfileDTO, ProfileDTO } from "generated";
import { BusinessTypes } from "common/onboarding/schema";
import { AppInputs } from "generated/modelsWrapper/AppInputs";
import {
  TypeOfBusinessCompanyExcludedValues,
  TypeOfBusinessCompanyValues,
  TypeOfBusinessSoloTraderExcludedValues,
  TypeOfBusinessSoloTraderValues,
  TypeOfBusinessValues,
} from "data-layer/application/types";

export const excludeCompanyValues = (
  _values: TypeOfBusinessCompanyValues
): TypeOfBusinessCompanyExcludedValues => {
  const values: TypeOfBusinessCompanyExcludedValues = {
    [AppInputs.COMPANY_NAME]: _values.COMPANY_NAME,
  };

  if (_values[AppInputs.ACCOUNT_NUMBER]) {
    values[AppInputs.ACCOUNT_NUMBER] = _values.ACCOUNT_NUMBER;
  }

  if (_values[AppInputs.COMPANY_NUMBER]) {
    values[AppInputs.COMPANY_NUMBER] = _values.COMPANY_NUMBER;
  }

  if (_values[AppInputs.COUNTRY]) {
    values[AppInputs.COUNTRY] = _values.COUNTRY;
  }

  if (_values[AppInputs.CITY]) {
    values[AppInputs.CITY] = _values.CITY;
  }

  if (_values[AppInputs.POSTAL_CODE]) {
    values[AppInputs.POSTAL_CODE] = _values.POSTAL_CODE;
  }

  if (_values[AppInputs.SORT_CODE]) {
    values[AppInputs.SORT_CODE] = _values.SORT_CODE;
  }

  if (_values[AppInputs.ADDRESS_LINE_1]) {
    values[AppInputs.ADDRESS_LINE_1] = _values.ADDRESS_LINE_1;
  }

  if (_values[AppInputs.ADDRESS_LINE_2]) {
    values[AppInputs.ADDRESS_LINE_2] = _values.ADDRESS_LINE_2;
  }

  if (_values[AppInputs.PERSONS]) {
    values[AppInputs.PERSONS] = _values.PERSONS;
  }

  return values;
};

export const excludeSoloTraderValues = (
  _values: TypeOfBusinessSoloTraderValues
): TypeOfBusinessSoloTraderExcludedValues => {
  const values = {
    [AppInputs.COMPANY_NAME]: _values.COMPANY_NAME,
  };

  return values;
};

export const isTheSameProfileKind = (
  profile: ProfileDTO,
  values: TypeOfBusinessValues
): boolean => {
  let isTheSameProfileKind = false;

  if (
    profile?.kind === CreateUpdateProfileDTO.kind.COMPANY &&
    values.typeOfBusiness === BusinessTypes.Company
  ) {
    isTheSameProfileKind = true;
  }

  if (
    profile?.kind === CreateUpdateProfileDTO.kind.SOLE_TRADER &&
    values.typeOfBusiness === BusinessTypes.SoleTrader
  ) {
    isTheSameProfileKind = true;
  }

  return isTheSameProfileKind;
};
