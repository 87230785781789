import { DictionaryOptionDTO } from "generated";

type Size = {
  id: string;
  text: string;
};

export type FormatedExperience = Size[] | [];

const getTextBySizeId = (id: string): string => {
  switch (id) {
    case "SMALL": {
      return "6 months or less";
    }
    case "MIDDLE": {
      return "6 months - 1 year";
    }
    case "LARGE": {
      return "1 year - 3 years";
    }
    case "EXTRA_LARGE": {
      return "3 years or more";
    }
  }

  return "";
};

export const formatExperience = (
  sizes?: DictionaryOptionDTO[] | []
): FormatedExperience => {
  if (!sizes?.length) {
    return [];
  }

  return sizes.map((size: { [id: string]: string }) => ({
    id: size.id,
    text: getTextBySizeId(size.id),
  }));
};
