import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { syncMerchantThunk } from "../thunks";

type UseSyncMerchant = () => {
  syncMerchant: (onSuccess?: VoidFunction, onFailure?: VoidFunction) => void;
};

export const useSyncMerchant: UseSyncMerchant = () => {
  const dispatch = useDispatch();

  const syncMerchant = useCallback(
    (onSuccess?: VoidFunction, onFailure?: VoidFunction): void => {
      dispatch(syncMerchantThunk(onSuccess, onFailure));
    },
    [dispatch]
  );

  return {
    syncMerchant,
  };
};
