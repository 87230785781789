import {useRoutes, Navigate, Outlet} from "react-router-dom";
import {
    SupportSc,
    PasswordChangeSc,
    PasswordRecoverySc,
    StyleGuideSc,
    InitComponentSc,
    AccessDeniedSc,
    ConfirmBankDetailsSc,
} from "scenarios/common";
import {
    SignUpSc,
    DashBoardSc,
    OverviewSc,
    OnboardingSc,
    OnboardingGuideSc,
    OnboardingFinishSc,
    SyncSc,
    PayoutsSc,
    TransactionsSc,
    EmployeesSc,
    PaymentLinkSc,
    PrivateSellerRoute,
    SettingsSc,
    ConfirmEmailSc,
    ConfirmedEmailSc,
    LoginSc,
    OnboardingNotAvailableSc,
} from "scenarios/seller";
import {FAQ} from "scenarios/common/SupportSc/components";
import {PrivateRoute} from "./PrivateRoute";
import {useEmployee, UserRole} from "data-layer/employee";
import {useProfile} from "data-layer/profile";
import {Helmet} from "react-helmet";
import {ProfileDTO, UserDTO} from "generated";
import {Role} from "data-layer/common/testRoleSlice";

const OnboardingPrivateRoute: React.FC<{ children: JSX.Element }> = (props) => {
    const {selectedProfile} = useProfile();

    const { employee } = useEmployee();
    
    if (employee.user?.status === UserDTO.status.INVALID_INVITATION_CODE) {
        return <Navigate to="/onboarding-not-available"/>;
    }

    if (selectedProfile?.status === ProfileDTO.status.APPROVED) {
        return <Navigate to="/dashboard"/>;
    }

    if (
        selectedProfile?.status === ProfileDTO.status.REJECTED ||
        selectedProfile?.status === ProfileDTO.status.BLOCKED ||
        selectedProfile?.status === ProfileDTO.status.UNDER_REVIEW ||
        selectedProfile?.status === ProfileDTO.status.DOCUMENT_REQUESTED
    ) {
        return <Navigate to="/onboarding-finish"/>;
    }

    return props.children;
};

const OnboardingFinishPrivateRoute: React.FC<{ children: JSX.Element }> = (
    props
) => {
    const {selectedProfile} = useProfile();

    if (selectedProfile?.status === ProfileDTO.status.APPROVED) {
        return <Navigate to="/dashboard"/>;
    }

    // todo redirect to onboarding cases

    return props.children;
};

export const SellerConfig = () => {
    const merchantRoutes = [
        {
            path: "sync",
            element: <SyncSc/>,
        },
        {
            path: "/",
            element: <Navigate to="/dashboard"/>,
        },
        {
            path: "dashboard",
            element: (
                <PrivateSellerRoute>
                    <DashBoardSc/>
                </PrivateSellerRoute>
            ),
            children: [
                {
                    element: <OverviewSc/>,
                    index: true,
                },
                {
                    path: "reports",
                    element: (
                        <>
                            <Helmet>
                                <title>Transactions | Dapio</title>
                            </Helmet>
                            <TransactionsSc/>
                        </>
                    ),
                },
                {
                    path: "payouts",
                    element: (
                        <>
                            <Helmet>
                                <title>Payouts | Dapio</title>
                            </Helmet>
                            <PayoutsSc/>
                        </>
                    ),
                },
                {
                    path: "employees",
                    element: (
                        <>
                            <Helmet>
                                <title>Employees | Dapio</title>
                            </Helmet>
                            <EmployeesSc/>
                        </>
                    ),
                },
                {
                    path: "payment-links",
                    element: (
                        <>
                            <Helmet>
                                <title>Payment Links | Dapio</title>
                            </Helmet>
                            <PaymentLinkSc/>
                        </>
                    ),
                },
                {
                    path: "support",
                    element: (
                        <>
                            <Helmet>
                                <title>Support | Dapio</title>
                            </Helmet>
                            <SupportSc/>
                        </>
                    ),
                    children: [
                        {
                            path: "faq",
                            element: <FAQ/>,
                        },
                    ],
                },
                {
                    path: "settings",
                    element: (
                        <>
                            <Helmet>
                                <title>Settings | Dapio</title>
                            </Helmet>
                            <SettingsSc.Wrapper/>
                        </>
                    ),
                    children: [
                        {
                            path: "",
                            element: <Navigate to="bank-details"/>,
                        },
                        {
                            path: "bank-details",
                            element: <SettingsSc.Settlement/>,
                        },
                        {
                            path: "merchant-documents",
                            element: <SettingsSc.MerchantDocuments/>,
                        },
                    ],
                },
            ],
        },
        {
            path: "onboarding",
            element: (
                <>
                    <Helmet>
                        <title>Onboarding | Dapio</title>
                    </Helmet>
                    <OnboardingPrivateRoute>
                        <OnboardingSc/>
                    </OnboardingPrivateRoute>
                </>
            ),
        },
        {
            path: "onboarding-guide",
            element: (
                <>
                    <Helmet>
                        <title>Onboarding | Dapio</title>
                    </Helmet>
                    <OnboardingPrivateRoute>
                        <OnboardingGuideSc/>
                    </OnboardingPrivateRoute>
                </>
            ),
        },
        {
            path: "onboarding-finish",
            element: (
                <>
                    <Helmet>
                        <title>Onboarding | Dapio</title>
                    </Helmet>
                    <OnboardingFinishPrivateRoute>
                        <OnboardingFinishSc/>
                    </OnboardingFinishPrivateRoute>
                </>
            ),
        },
        {
            path: "onboarding-not-available",
            element: (
                <>
                    <Helmet>
                        <title>Onboarding | Dapio</title>
                    </Helmet>
                    <OnboardingNotAvailableSc/>
                </>
            ),
        },
    ];

    let config = useRoutes([
        {
            path: "/",
            element: (
                <>
                    <Helmet>
                        <title>Dapio</title>
                    </Helmet>
                    <InitComponentSc roleAccess={UserRole.MERCHANT}>
                        <PrivateRoute accessRole={UserRole.MERCHANT}>
                            <Outlet/>
                        </PrivateRoute>
                    </InitComponentSc>
                </>
            ),
            children: merchantRoutes,
        },
        {
            path: "/signin",
            element: (
                <>
                    <Helmet>
                        <title>Login | Dapio</title>
                    </Helmet>
                    <LoginSc/>
                </>
            ),
        },
        {
            path: "/signup",
            element: (
                <>
                    <Helmet>
                        <title>Sign up | Dapio</title>
                    </Helmet>
                    <SignUpSc.Wrapper/>
                </>
            ),
            children: [
                {
                    element: <SignUpSc.Form/>,
                    index: true,
                },
            ],
        },
        {
            path: "/confirm-email",
            element: (
                <>
                    <Helmet>
                        <title>Confirm email | Dapio</title>
                    </Helmet>
                    <ConfirmEmailSc/>
                </>
            ),
        },
        {
            path: "/confirmed-email",
            element: (
                <>
                    <Helmet>
                        <title>Confirm email | Dapio</title>
                    </Helmet>
                    <ConfirmedEmailSc/>
                </>
            ),
        },
        {
            path: "/password-recovery",
            element: (
                <>
                    <Helmet>
                        <title>Reset password | Dapio</title>
                    </Helmet>
                    <PasswordRecoverySc.Wrapper role={Role.Merchant}/>
                </>
            ),
            children: [
                {
                    element: <PasswordRecoverySc.Form/>,
                    index: true,
                },
                {
                    path: "confirm",
                    element: <PasswordRecoverySc.Confirm/>,
                },
            ],
        },
        {
            path: "/password-reset",
            element: (
                <>
                    <Helmet>
                        <title>Reset password | Dapio</title>
                    </Helmet>
                    <PasswordChangeSc.Wrapper role={Role.Merchant}/>
                </>
            ),
            children: [
                {
                    element: <PasswordChangeSc.Form/>,
                    index: true,
                },
                {
                    path: "confirm",
                    element: <PasswordChangeSc.Confirm/>,
                },
            ],
        },
        {
            path: "/style-guide",
            element: (
                <>
                    <Helmet>
                        <title>Style guide | Dapio</title>
                    </Helmet>
                    <StyleGuideSc/>
                </>
            ),
        },
        {
            path: "/access-denied",
            element: (
                <>
                    <Helmet>
                        <title>Access Denied | Dapio</title>
                    </Helmet>
                    <AccessDeniedSc/>
                </>
            ),
        },
        {
            path: "/settlement-details-update-confirm",
            element: <ConfirmBankDetailsSc/>,
        },
    ]);

    return config;
};
