import { validatePhone } from "components/PhoneInput/_index";
import { AppInputs } from "generated/modelsWrapper/AppInputs";
import { validateEmail } from "utils/validateEmail";
import * as Yup from "yup";

const addressPattern = /^[-A-Za-z0-9.<(+&*),%_?:'=| ]+$/;
const cityPattern = /^[-A-Za-z0-9_.():&/*<=‘?%,+ ]+$/;
const zipPattern = /^[-A-Za-z0-9_.*():&/<=’?%,+ ]+$/;

export const personalDetailsSchema = Yup.object().shape({
  [AppInputs.PERSON_FIRST_NAME]: Yup.string().required("Required"),
  [AppInputs.PERSON_LAST_NAME]: Yup.string().required("Required"),
  [AppInputs.PERSON_DOB]: Yup.string().required("Required"),

  [AppInputs.PERSON_PHONE_NUMBER]: Yup.string()
    .required("Required")
    .test("check format", "Invalid phone number", (value?: string) => {
      return validatePhone(value);
    }),
  [AppInputs.PERSON_EMAIL]: Yup.string()
    .required("Required")
    .test("email", "Invalid email", (value?: string) => {
      return validateEmail(value);
    }),

  [AppInputs.PERSON_DOB]: Yup.date()
    .required()
    .test('dob', 'You must be over 18 years old', function (value, ctx) {
      const dob = new Date(value!);
      const validDate = new Date();
      const valid = validDate.getFullYear() - dob.getFullYear() >= 18;
      return !valid ? ctx.createError() : valid; 
    }),

  [AppInputs.PERSON_ADDRESS_LINE_1]: Yup.string()
    .required("Required")
    .max(50, "Max 50 characters")
    .matches(addressPattern, "Incorrect value"),
  [AppInputs.PERSON_ADDRESS_LINE_2]: Yup.string()
    .max(50, "Max 50 characters")
    .matches(addressPattern, "Incorrect value"),

  [AppInputs.PERSON_CITY]: Yup.string()
    .required("Required")
    .max(50, "Max 50 characters")
    .matches(cityPattern, "Incorrect value"),
  [AppInputs.PERSON_POSTAL_CODE]: Yup.string()
    .required("Required")
    .max(20, "Max 20 characters")
    .matches(zipPattern, "Incorrect value"),
});
