import { useSelector } from "react-redux";
import { IProfile } from "../";
import { useGetProfile } from "./useGetProfile";
import { useCreateProfile } from "./useCreateProfile";
import { useSelectProfile } from "./useSelectProfile";
import {
  profileSelector,
  profilesSelector,
  selectedProfileSelector,
  dataProfilesForPartnerSelector,
} from "../selectors";
import { useGetPartnerProfile } from "./useGetPartnerProfile";
import { useEditProfile } from "./useEditProfile";

export const useProfile = (): IProfile => {
  const { getProfile } = useGetProfile();
  const { getPartherProfile } = useGetPartnerProfile();
  const { createProfile } = useCreateProfile();
  const { editProfile } = useEditProfile();
  const { selectProfile } = useSelectProfile();
  const profile = useSelector(profileSelector);
  const profiles = useSelector(profilesSelector);
  const dataProfilesForPartner = useSelector(dataProfilesForPartnerSelector);
  const selectedProfile = useSelector(selectedProfileSelector);

  return {
    getProfile,
    getPartherProfile,
    createProfile,
    editProfile,
    selectProfile,
    profile,
    profiles,
    dataProfilesForPartner,
    selectedProfile,
  };
};
