import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { updateFrtWebThunk } from "data-layer/employee/thunks";

type UseUpdateFrtWeb = () => {
  updateFrtWeb: (token: string) => void;
};

export const useUpdateFrtWeb: UseUpdateFrtWeb = () => {
  const dispatch = useDispatch();

  const updateFrtWeb = useCallback(
    (frt: string) => {
      dispatch(updateFrtWebThunk(frt));
    },
    [dispatch]
  );

  return {
    updateFrtWeb,
  };
};
