import styled from "styled-components";
import { Fonts } from "../../../../../styles/fonts";

const MainTitle = styled.h2`
  font-family: ${Fonts.NexaRegular};
  font-size: 25px;
  line-height: 160%;
  margin-bottom: 27px;

  @media (max-width: 1550px) {
    font-size: 20px;
    line-height: 25px;
  }
`;

const List = styled.div`
  padding-left: 20px;

  @media (max-width: 767px) {
    padding-left: 0;
  }
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 45px;
  height: 45px;

  margin-bottom: 25px;

  border-radius: 10px;

  background-color: #fe8e7533;

  @media (max-width: 1550px) {
    width: 35px;
    height: 35px;
    margin-bottom: 10px;
  }

  svg {
    @media (max-width: 1550px) {
      font-size: 20px;
    }
  }
`;

const CountTitle = styled.h3`
  margin-bottom: 5px;
  font-family: ${Fonts.NexaLight};
  font-size: 50px;
  line-height: 40px;

  @media (max-width: 1550px) {
    margin-bottom: 0;
    font-family: ${Fonts.NexaLight};
    font-size: 30px;
    line-height: 40px;
  }
`;

const StatusLabel = styled.p`
  max-width: 170px;
  margin-bottom: 0px;
  font-family: ${Fonts.NexaRegular};
  font-size: 20px;
  line-height: 25px;
  color: #8096a7;

  @media (max-width: 1550px) {
    font-size: 17px;
    line-height: 23px;
  }
`;

export const Styled = {
  MainTitle,
  List,
  IconWrapper,
  CountTitle,
  StatusLabel,
};
