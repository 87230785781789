import { Spin, Row, Col } from "antd";
import { Styled } from "./styles";
import { AttachmentDTO } from "generated/models/AttachmentDTO";
import { FolderOutlined, UploadOutlined } from "@ant-design/icons";
import React from "react";

export enum InputFileType {
  Primary = "Primary",
}

type InputFileDefaultViewProps = {
  name: string;
  loading: boolean;
  handleFile: (e: any) => void;
  uploadButtonHide?: boolean;
  value: number;
  type?: InputFileType;
  removeButtonHide?: boolean;
  file: AttachmentDTO;
  removeFile: (e: any) => void;
};

export const InputFileDefaultView: React.FC<InputFileDefaultViewProps> =
  React.forwardRef((props, ref) => {
    const {
      name,
      loading,
      handleFile,
      uploadButtonHide,
      value,
      type,
      file,
      removeButtonHide,
      removeFile,
    } = props;

    return (
      <>
        <input type="hidden" name={name} disabled={loading} />
        <Row gutter={{ xs: 15, sm: 15, xl: 20 }}>
          <Col style={{ position: "relative" }} flex={1}>
            <Row gutter={{ xs: 15, sm: 15, xl: 20 }}>
              <Styled.Input
                type="file"
                name={`${name}_file`}
                onChange={handleFile}
                disabled={loading || uploadButtonHide}
                ref={ref}
              />
              <Col flex={1}>
                <Styled.Field active={Boolean(value)} type={type}>
                  <Styled.FieldText>
                    {(file && file.originalFileName) || "Choose file"}
                  </Styled.FieldText>

                  {loading && (
                    <Styled.Loader>
                      <Spin />
                    </Styled.Loader>
                  )}
                  <FolderOutlined />
                </Styled.Field>
              </Col>
              {uploadButtonHide !== true && (
                <Col>
                  <Styled.Icon type={type}>
                    <UploadOutlined />
                  </Styled.Icon>
                </Col>
              )}
            </Row>
          </Col>
          {removeButtonHide !== true && (
            <Col>
              <Styled.RemoveButton
                type={type}
                onClick={removeFile}
                disabled={!value}
              />
            </Col>
          )}
        </Row>
      </>
    );
  });
