import React from "react";
import { BusinessActivitesStep } from "./components/BusinessActivitiesStep";
import { SoleTraderDescriptionStep } from "./components/DescriptionStep/SoleTraderDescriptionStep";
import { PersonalDetailsStep } from "./components/PersonalDetailsStep";
import { SoloTraderStepProps, StepProps } from "./OnboardingSc";
import { ReviewSoloTraderStep } from "./components/ReviewStep/ReviewStepSoloTrader";
import { IdentityVerificationSoloTraderStep } from "./components/IdentityVerificationStep";

type ContentProps = SoloTraderStepProps &
  StepProps & {
    current: number;
    changeStep: (index: number) => void;
  };

const Content: React.FC<ContentProps> = (props) => {
  const { initData, prevStep, nextStep, changeStep, current } = props;

  return (
    <>
      {current === 1 && (
        <SoleTraderDescriptionStep
          initData={initData}
          prevStep={prevStep}
          nextStep={nextStep}
        />
      )}
      {current === 2 && (
        <BusinessActivitesStep
          initData={initData}
          prevStep={prevStep}
          nextStep={nextStep}
        />
      )}
      {current === 3 && (
        <PersonalDetailsStep
          initData={initData}
          prevStep={prevStep}
          nextStep={nextStep}
        />
      )}
      {current === 4 && (
        <IdentityVerificationSoloTraderStep
          initData={initData}
          prevStep={prevStep}
          nextStep={nextStep}
        />
      )}
      {current === 5 && (
        <ReviewSoloTraderStep
          initData={initData}
          changeStep={changeStep}
          prevStep={prevStep}
          nextStep={nextStep}
        />
      )}
    </>
  );
};

export const OnboardingSoloTraderSteps = {
  Content: Content,
};
