import { api } from "./api";
import { CancelablePromise } from "generated/core/CancelablePromise";
import type { AttachmentDTO } from "generated/models/AttachmentDTO";

export interface IAttachemtApi {
  uploadFiles: (formData?: {
    files?: Array<Blob>;
  }) => CancelablePromise<Array<AttachmentDTO> | any>;
  getFile: (attachmentId: number) => CancelablePromise<AttachmentDTO>;
}

class AttachmentApi {
  api: IAttachemtApi = api;

  constructor() {
    if (process.env.REACT_APP_MOCK_ENABLED === "true") {
      this.api = api;
      return;
    }

    this.api = api;
  }
}

export const attachmentApi = new AttachmentApi();
