import { useToggle } from "common/useToggle";
import { NavLink, Outlet } from "react-router-dom";
import { Styled } from "./styles";

export const SupportSc: React.FC = () => {
  const { active, handleToggle } = useToggle();

  return (
    <div className="container">
      <Styled.Wrapper>
        <Styled.Sidebar>
          <Styled.HeaderMobile onClick={handleToggle} active={active}>
            Show Filters
          </Styled.HeaderMobile>
          <Styled.Content active={active}>
            <Styled.SidebarTitle>Support</Styled.SidebarTitle>
            <Styled.SidebarLink>
              <NavLink
                to="faq"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                FAQ
              </NavLink>
            </Styled.SidebarLink>
            {/* <Styled.SidebarLink>
              <NavLink
                to="video"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Video tutorials
              </NavLink>
            </Styled.SidebarLink>{" "}
            <Styled.SidebarLink>
              <NavLink
                to="for-developers"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                For developers
              </NavLink>
            </Styled.SidebarLink> */}
          </Styled.Content>
        </Styled.Sidebar>
        <Outlet />
      </Styled.Wrapper>
    </div>
  );
};
