import { Spin } from "antd";
import { Styled } from "./styles";
import { useCommon } from "data-layer/common";
import ReactDOM from "react-dom";

export const LoaderSc: React.FC = () => {
  const { isLoaderVisible } = useCommon();

  if (!isLoaderVisible) return null;

  const modalRoot = document.getElementById('portal');


  return ReactDOM.createPortal(
    <Styled.Wrapper>
      <Spin size="large" />
    </Styled.Wrapper>,
    modalRoot
  );
};
