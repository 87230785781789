import { PartnerDTO, UpdatePartnerDTO } from "generated";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

type UseUpdatePartnerLogo = (
  partner: PartnerDTO,
  updatePartner: (body: UpdatePartnerDTO) => void
) => {
  updatePartnerLogo: (fileName: string | null) => void;
};

export const useUpdatePartnerLogo: UseUpdatePartnerLogo = (partner, updatePartner) => {
  const dispatch = useDispatch();

  const updatePartnerLogo = useCallback((fileName) => {
    const { id, currency, settlementDetailsData, ...rest } = partner;

    updatePartner({
      ...rest,
      id: id!,
      currencyId: currency?.id!,
      logoImageFilename: fileName,
    })
  }, [dispatch, partner]);

  return {
    updatePartnerLogo,
  };
};
