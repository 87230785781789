import { CancelablePromise } from "generated/core/CancelablePromise";
import { api } from "./api";
import { DictionaryDTO } from "generated/models/DictionaryDTO";

export interface IDictionaryApi {
  getDictionary: () => CancelablePromise<DictionaryDTO>;
}

class DictionaryApi {
  api: IDictionaryApi = api;

  constructor() {
    if (process.env.REACT_APP_MOCK_ENABLED === "true") {
      this.api = api;
      return;
    }

    this.api = api;
  }
}

export const dictionaryApi = new DictionaryApi();
