import { FieldArray, Formik, getIn } from "formik";
import { Col, Popover, Row } from "antd";
import React from "react";
import { Styled as OnboardingStyled } from "../../onboardingStyles";
import { AppInputs } from "generated/modelsWrapper/AppInputs";
import { OnboardingFormItem } from "../OnboardingFormItem";
import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { StepProps, CompanyStepProps } from "../../OnboardingSc";
import { usePersist } from "../../usePersist";
import {
  Button,
  FormikPatchTouched,
  FormItemHintAlign,
  InputFile,
  InputFileType,
} from "components";
import { identityVerificationCompanySchema } from "common/onboarding/schema/identityVerificationSchema";
import { Styled } from "./styles";
import "./styles.less";

const Content: React.FC = () => {
  return (
    <Styled.hintWrapper>
      <Styled.hintHeader>Document list</Styled.hintHeader>
      <Styled.hintContent>
        <Styled.hintContentGroup>
          <Styled.hintContentGroupTitle>
            Current signed passport
          </Styled.hintContentGroupTitle>
          <Styled.hintContentGroupText>
            Utility bill (gas, electric, satellite television, landline phone
            bill) issued within the last three months
          </Styled.hintContentGroupText>
        </Styled.hintContentGroup>

        <Styled.hintContentGroup>
          <Styled.hintContentGroupTitle>
            Original birth certificate (UK birth certificate issued within 12
            months of the date of birth in full form including those issued by
            UK authorities overseas such as Embassies High Commissions and HM
            Forces)
          </Styled.hintContentGroupTitle>
          <Styled.hintContentGroupText>
            Local authority council tax bill for the current council tax year
          </Styled.hintContentGroupText>
        </Styled.hintContentGroup>
      </Styled.hintContent>
    </Styled.hintWrapper>
  );
};

export const IdentityVerificationCompanyStep: React.FC<
  CompanyStepProps & StepProps
> = (props) => {
  const { persist } = usePersist();
  const { initData, prevStep, nextStep } = props;

  return (
    <div>
      <OnboardingStyled.Title>Identity verification</OnboardingStyled.Title>

      <Popover
        content={<Content />}
        trigger="click"
        placement="right"
        overlayClassName="identity-verification-popover"
      >
        <Styled.Link>What documents need to verification?</Styled.Link>
      </Popover>
      <Styled.Subtitle>
        Documents we will not accept include, but are not limited to
      </Styled.Subtitle>
      <Styled.List>
        <Styled.ListItem>- Provisional driving licence</Styled.ListItem>
        <Styled.ListItem>- Mobile phone bills</Styled.ListItem>
        <Styled.ListItem>- Credit card statements</Styled.ListItem>
      </Styled.List>

      <Formik
        initialValues={initData}
        validationSchema={identityVerificationCompanySchema}
        validateOnChange={true}
        validateOnMount={true}
        enableReinitialize
        onSubmit={(values) => {
          persist(values, nextStep);
        }}
      >
        {({
          errors,
          values,
          touched,
          handleSubmit,
          isValid,
          setFieldValue,
          setFieldTouched,
        }) => {
          return (
            <>
              <FormikPatchTouched />

              <FieldArray
                name={AppInputs.PERSONS}
                render={(arrayHelpers) => {
                  return (
                    <>
                      {(values.PERSONS || []).map((owner, index) => {
                        const nameBase = `${AppInputs.PERSONS}[${index}].`;

                        return (
                          <Styled.Group>
                            <Styled.GroupHeader>
                              <Row justify="space-between">
                                <Col>
                                  <Styled.GroupTitle>
                                    {owner.PERSON_FIRST_NAME}
                                  </Styled.GroupTitle>
                                  <Styled.GroupSubtitle>
                                    {owner.PERSON_LAST_NAME}
                                  </Styled.GroupSubtitle>
                                </Col>
                                <Col>
                                  <Popover
                                    content={<Content />}
                                    trigger="click"
                                    placement="right"
                                    overlayClassName="identity-verification-popover"
                                  >
                                    <InfoCircleOutlined
                                      style={{ fontSize: 30, color: "#8095a7" }}
                                    />
                                  </Popover>
                                </Col>
                              </Row>
                            </Styled.GroupHeader>

                            <Styled.GroupContent>
                              <Row gutter={[0, { xs: 15, sm: 15, md: 20 }]}>
                                <Styled.GroupContentItem span={24}>
                                  <OnboardingFormItem
                                    label={"Proof of identity"}
                                    error={
                                      touched &&
                                      touched.PERSONS &&
                                      touched.PERSONS[index] &&
                                      touched.PERSONS[index]
                                        .PROOF_IDENTITY_FIRST &&
                                      getIn(
                                        errors,
                                        `${nameBase}${AppInputs.PROOF_IDENTITY_FIRST}`
                                      )
                                    }
                                    hintAlign={FormItemHintAlign.Left}
                                  >
                                    <InputFile
                                      name={`${nameBase}${AppInputs.PROOF_IDENTITY_FIRST}`}
                                      value={owner.PROOF_IDENTITY_FIRST}
                                      handleChange={(value: number) => {
                                        setFieldValue(
                                          `${nameBase}${AppInputs.PROOF_IDENTITY_FIRST}`,
                                          value,
                                          true
                                        );
                                      }}
                                      type={InputFileType.Primary}
                                      handleRemove={() => {
                                        setFieldValue(
                                          `${nameBase}${AppInputs.PROOF_IDENTITY_FIRST}`,
                                          undefined,
                                          true
                                        );
                                        setFieldTouched(
                                          `${nameBase}${AppInputs.PROOF_IDENTITY_FIRST}`,
                                          true,
                                          false
                                        );
                                      }}
                                    />
                                  </OnboardingFormItem>
                                </Styled.GroupContentItem>

                                <Styled.GroupContentItem span={24}>
                                  <OnboardingFormItem
                                    label={"Proof of address"}
                                    error={
                                      touched &&
                                      touched.PERSONS &&
                                      touched.PERSONS[index] &&
                                      touched.PERSONS[index].PROOF_ADDRESS &&
                                      getIn(
                                        errors,
                                        `${nameBase}${AppInputs.PROOF_ADDRESS}`
                                      )
                                    }
                                    hintAlign={FormItemHintAlign.Left}
                                  >
                                    <InputFile
                                      name={`${nameBase}${AppInputs.PROOF_ADDRESS}`}
                                      value={owner.PROOF_ADDRESS}
                                      handleChange={(value: number) => {
                                        setFieldValue(
                                          `${nameBase}${AppInputs.PROOF_ADDRESS}`,
                                          value,
                                          true
                                        );
                                      }}
                                      type={InputFileType.Primary}
                                      handleRemove={() => {
                                        setFieldValue(
                                          `${nameBase}${AppInputs.PROOF_ADDRESS}`,
                                          undefined,
                                          true
                                        );
                                        setFieldTouched(
                                          `${nameBase}${AppInputs.PROOF_ADDRESS}`,
                                          true,
                                          false
                                        );
                                      }}
                                    />
                                  </OnboardingFormItem>
                                </Styled.GroupContentItem>
                              </Row>
                            </Styled.GroupContent>
                          </Styled.Group>
                        );
                      })}

                      <OnboardingStyled.Footer>
                        <Row gutter={{ xs: 10, sm: 10, md: 25 }}>
                          <Col>
                            <Button
                              type="secondary-transparent"
                              onClick={() => prevStep()}
                            >
                              Back
                            </Button>
                          </Col>
                          <Col flex={1}>
                            <Button
                              type="primary-transparent"
                              onClick={() => handleSubmit()}
                              disabled={!isValid}
                              block
                            >
                              Next
                            </Button>
                          </Col>
                        </Row>
                      </OnboardingStyled.Footer>
                    </>
                  );
                }}
              />
            </>
          );
        }}
      </Formik>
    </div>
  );
};
