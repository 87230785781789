import { useRef, useState } from "react";
import { useInitFile } from "./useInitFile";
import { useHandleFile } from "./useHandleFile";
import { AttachmentDTO } from "generated/models/AttachmentDTO";
import { useRemoveFile } from "./useRemoveFile";
import { InputFileDefaultView } from "./InputFileDefaultView";

export enum InputFileType {
  Primary = "Primary",
}

type InputFileProps = {
  name: string;
  handleChange?: (value: number, file: AttachmentDTO) => void;
  value: number;
  // when we are prefetching some files
  fileValue?: AttachmentDTO;
  type?: InputFileType;
  removeButtonHide?: boolean;
  uploadButtonHide?: boolean;
  handleRemove?: VoidFunction;
};

export const InputFile: React.FC<InputFileProps> = (props) => {
  const {
    name,
    handleChange,
    value,
    fileValue = {},
    type,
    removeButtonHide,
    uploadButtonHide,
    handleRemove,
  } = props;

  const ref = useRef();

  const resetInputValue = () => {
    ref.current.value = "";
  };

  const [loading, setLoading] = useState(false);

  const { file, setFile } = useInitFile(value, fileValue, setLoading);

  const { handleFile } = useHandleFile(setLoading, setFile, handleChange);

  const { removeFileDefault } = useRemoveFile(setFile, resetInputValue, handleRemove);

  return (
    <InputFileDefaultView
      name={name}
      loading={loading}
      handleFile={handleFile}
      type={type}
      value={value}
      file={file}
      removeButtonHide={removeButtonHide}
      removeFile={removeFileDefault}
      uploadButtonHide={uploadButtonHide}
      ref={ref}
    />
  );

  // return (
  //   <>
  //     <input type="hidden" name={name} disabled={loading} />
  //     <Row gutter={{ xs: 15, sm: 15, xl: 20 }}>
  //       <Col style={{ position: "relative" }} flex={1}>
  //         <Row gutter={{ xs: 15, sm: 15, xl: 20 }}>
  //           <Styled.Input
  //             type="file"
  //             name={`${name}_file`}
  //             onChange={handleFile}
  //             disabled={loading || uploadButtonHide}
  //             ref={ref}
  //           />
  //           <Col flex={1}>
  //             <Styled.Field active={Boolean(value)} type={type}>
  //               <Styled.FieldText>
  //                 {(file && file.originalFileName) || "Choose file"}
  //               </Styled.FieldText>

  //               {loading && (
  //                 <Styled.Loader>
  //                   <Spin />
  //                 </Styled.Loader>
  //               )}
  //               <FolderOutlined />
  //             </Styled.Field>
  //           </Col>
  //           {uploadButtonHide !== true && (
  //             <Col>
  //               <Styled.Icon type={type}>
  //                 <UploadOutlined />
  //               </Styled.Icon>
  //             </Col>
  //           )}
  //         </Row>
  //       </Col>
  //       {removeButtonHide !== true && (
  //         <Col>
  //           <Styled.RemoveButton
  //             type={type}
  //             onClick={removeFile}
  //             disabled={!value}
  //           />
  //         </Col>
  //       )}
  //     </Row>
  //   </>
  // );
};
