/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EmployeePermissionDTO } from './EmployeePermissionDTO';
import type { MerchantDTO } from './MerchantDTO';

export type EmployeeDTO = {
    address: string;
    cctExternalId?: number;
    cctTerminalExternalId?: number;
    city: string;
    email: string;
    id: number;
    merchant?: MerchantDTO;
    name: string;
    permissions?: Array<EmployeePermissionDTO>;
    postalCode: string;
    status: EmployeeDTO.status;
    surname: string;
    tid?: string;
    vacStoreProfileId?: string;
    vacStoreProfileName?: string;
};

export namespace EmployeeDTO {

    export enum status {
        ACTIVATED = 'ACTIVATED',
        EXPORTED = 'EXPORTED',
        EXTERNAL_SYSTEM_NOT_READY = 'EXTERNAL_SYSTEM_NOT_READY',
        NEW = 'NEW',
        SUSPENDED = 'SUSPENDED',
        ZERO_TRANSACTION = 'ZERO_TRANSACTION',
    }


}
