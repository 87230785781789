/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImportFlatApplicationRequestDTO } from '../models/ImportFlatApplicationRequestDTO';
import type { ImportingFileResultDTO } from '../models/ImportingFileResultDTO';
import type { ParseImportFileResultDTO } from '../models/ParseImportFileResultDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { network } from 'services/network';

export class MerchantImportControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Import merchant
     * Import merchant
     * @param requestBody
     * @returns ImportingFileResultDTO
     * @returns any Created
     * @throws ApiError
     */
    public importDataUsingPost(
        requestBody?: ImportFlatApplicationRequestDTO,
    ): CancelablePromise<ImportingFileResultDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/merchants/import',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Parse merchant from CSV
     * Parse merchant from CSV
     * @param formData
     * @returns ParseImportFileResultDTO
     * @returns any Created
     * @throws ApiError
     */
    public parseCsvUsingPost(
        formData?: {
            files: Array<Blob>;
            token?: string;
        },
    ): CancelablePromise<ParseImportFileResultDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/merchants/import/parse/csv',
                formData: formData,
                mediaType: 'multipart/form-data',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Parse merchant from XLS
     * Parse merchant from XLS
     * @param formData
     * @returns ParseImportFileResultDTO
     * @returns any Created
     * @throws ApiError
     */
    public parseXlsUsingPost(
        formData?: {
            files: Array<Blob>;
            token?: string;
        },
    ): CancelablePromise<ParseImportFileResultDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/merchants/import/parse/xls',
                formData: formData,
                mediaType: 'multipart/form-data',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Parse merchant from XLSX
     * Parse merchant from XLSX
     * @param formData
     * @returns ParseImportFileResultDTO
     * @returns any Created
     * @throws ApiError
     */
    public parseXlsxUsingPost(
        formData?: {
            files: Array<Blob>;
            token?: string;
        },
    ): CancelablePromise<ParseImportFileResultDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/merchants/import/parse/xlsx',
                formData: formData,
                mediaType: 'multipart/form-data',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

}