import { Link } from "react-router-dom";
import { Styled } from "../../styles";
import { PageLogo, Typography } from "ui";
import styled from "styled-components";
import { Role } from "data-layer/common/testRoleSlice";
import { useEmployee } from "data-layer/employee";

const H1 = styled(Typography.H1)`
  span {
    display: block;
  }
`;

export const OnboardingRejected: React.FC = () => {
  const { employee } = useEmployee();

  return (
    <Styled.Wrapper>
      <Styled.Background />
      <Styled.Content>
        <Styled.ContentContainer>
          <Link to="/dashboard">
            <PageLogo role={Role.Merchant} partnerId={employee?.user?.inviterPartnerId} />
          </Link>
          <H1>
            Your application <span>has been rejected!</span>
          </H1>
          <Styled.Description>
            <p>
              Unfortunately, we can’t offer you a Dapio account at this time.
            </p>
            <p>
              We’re afraid we can’t go intro more detail about why we can’t
              offer you an account.
            </p>
            <p>
              We access every application using the details you’ve given us, as
              well as any information we get from the credit reference agency we
              use (Experian) or from anti-fraud services like Comply Advantage
            </p>
          </Styled.Description>
        </Styled.ContentContainer>
      </Styled.Content>
    </Styled.Wrapper>
  );
};
