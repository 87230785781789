/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AbstractEventVO } from './AbstractEventVO';

export type PartnerSettlementDetailsUpdatedVO = (AbstractEventVO & {
    action?: string;
    eventDate?: string;
    partnerId?: number;
    process?: PartnerSettlementDetailsUpdatedVO.process;
});

export namespace PartnerSettlementDetailsUpdatedVO {

    export enum process {
        APPLICATION = 'APPLICATION',
        AUTH = 'AUTH',
        DOCUMENT_REQUEST = 'DOCUMENT_REQUEST',
        EMPLOYEE = 'EMPLOYEE',
        MERCHANT = 'MERCHANT',
        PARTNER = 'PARTNER',
        PAYOUT = 'PAYOUT',
        PROOF_PERSON = 'PROOF_PERSON',
        USER = 'USER',
    }


}
