import { Row, Col } from "antd";
import { TextBlock, Button } from "components";
import { Styled } from "./styles";
import { history } from "data-layer/../router/AppRouter";
import { Typography } from "ui";

export const WelcomeView: React.FC = () => {
  return (
    <Styled.Wrapper>
      <Typography.H1>
        Tell us about <TextBlock>your business</TextBlock>
      </Typography.H1>
      <Styled.Description>
        Before you can start taking payments, we need a few details. We do this
        to comply with the law and to verify your identity.
      </Styled.Description>
      <Styled.Description>
        Your rights over your personal information and how it’s used is shown in
        our{" "}
        <a href="https://dapio.com/privacy" target="_blank">
          Data Privacy Policy
        </a>{" "}
        &{" "}
        <a href="https://dapio.com/terms_and_conditions" target="_blank">
          Terms & Conditions
        </a>
      </Styled.Description>
      <Styled.Note>
        When clicking here, I state that I have read and understood the Terms &
        Conditions, Privacy Policy and Commercials Terms
      </Styled.Note>
      <Button
        type="primary-transparent"
        block
        onClick={() => history.push("/onboarding")}
      >
        Agree and Start Registration
      </Button>
    </Styled.Wrapper>
  );
};
