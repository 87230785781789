import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { clearUser } from "utils/clearUser";
import { employeeSlice } from "../slice";
import { history } from "data-layer/../router/AppRouter";

type UseLogout = () => {
  logout: VoidFunction;
};

export const useLogout: UseLogout = () => {
  const dispatch = useDispatch();

  const logout = useCallback(() => {
    clearUser();
    dispatch(employeeSlice.actions.logout());
    history.push("/signin");
  }, [dispatch]);

  return {
    logout,
  };
};
