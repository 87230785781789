import { MerchantDTO } from "generated";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { updateMerchantThunk } from "../thunks";

type UseUpdateMerchant = () => {
  updateMerchant: (body: MerchantDTO) => void;
};

export const useUpdateMerchant: UseUpdateMerchant = () => {
  const dispatch = useDispatch();

  const updateMerchant = useCallback(
    (body) => {
      dispatch(updateMerchantThunk(body));
    },
    [dispatch]
  );

  return {
    updateMerchant,
  };
};
