import { Formik } from "formik";
import { Col, Row } from "antd";
import React from "react";
import { Styled as OnboardingStyled } from "../../onboardingStyles";
import { Styled } from "./styles";
import { Radio } from "formik-antd";
import { AppInputs } from "generated/modelsWrapper/AppInputs";
import { OnboardingFormItem } from "../OnboardingFormItem";
import { businessActivitiesSchema } from "common/onboarding/schema";
import {
  AverageTxValue,
  AverageTxValueMap,
  ExpectedTurnover,
  ExpectedTurnoverMap,
  PaymentHistory,
  PaymentHistoryMap,
} from "data-layer/application/types";
import { StepProps, StepComponentProps } from "../../OnboardingSc";
import { usePersist } from "../../usePersist";
import { Button, FormikPatchTouched } from "components";
import { getFormikError } from "utils/getFormikError";

export const BusinessActivitesStep: React.FC<StepComponentProps & StepProps> = (
  props
) => {
  const { persist } = usePersist();
  const { initData, prevStep, nextStep } = props;

  return (
    <div>
      <OnboardingStyled.Title>Business activities</OnboardingStyled.Title>
      <Formik
        initialValues={initData}
        validationSchema={businessActivitiesSchema}
        validateOnChange={true}
        validateOnMount={true}
        enableReinitialize
        onSubmit={(values) => {
          persist(values, nextStep);
        }}
      >
        {({ errors, touched, handleSubmit, isValid }) => {
          return (
            <>
              <FormikPatchTouched />

              <OnboardingStyled.Content margin="small">
                <Row gutter={[0, { xs: 18, sm: 18, xl: 25 }]}>
                  <Col span={24}>
                    <OnboardingFormItem
                      label={
                        <Styled.Label>
                          <span>What’s your expected annual turnover?</span>
                        </Styled.Label>
                      }
                      error={getFormikError(
                        AppInputs.EXPECTED_TURNOVER,
                        errors,
                        touched
                      )}
                    >
                      <Styled.GroupWrapper>
                        <Radio.Group
                          name={AppInputs.EXPECTED_TURNOVER}
                          style={{ width: "100%" }}
                        >
                          <Row>
                            <Col span={24}>
                              <Radio
                                name={AppInputs.EXPECTED_TURNOVER}
                                value={ExpectedTurnover.SMALL}
                              >
                                {ExpectedTurnoverMap.SMALL}
                              </Radio>
                            </Col>
                            <Col span={24}>
                              <Radio
                                name={AppInputs.EXPECTED_TURNOVER}
                                value={ExpectedTurnover.MIDDLE}
                              >
                                {ExpectedTurnoverMap.MIDDLE}
                              </Radio>
                            </Col>
                            <Col span={24}>
                              <Radio
                                name={AppInputs.EXPECTED_TURNOVER}
                                value={ExpectedTurnover.LARGE}
                              >
                                {ExpectedTurnoverMap.LARGE}
                              </Radio>
                            </Col>
                            <Col span={24}>
                              <Radio
                                name={AppInputs.EXPECTED_TURNOVER}
                                value={ExpectedTurnover.EXTRA_LARGE}
                              >
                                {ExpectedTurnoverMap.EXTRA_LARGE}
                              </Radio>
                            </Col>
                          </Row>
                        </Radio.Group>
                      </Styled.GroupWrapper>
                    </OnboardingFormItem>
                  </Col>
                  <Col span={24}>
                    <OnboardingFormItem
                      label={
                        <Styled.Label>
                          <span>
                            How long have you been processing card payments?
                          </span>
                        </Styled.Label>
                      }
                      error={getFormikError(
                        AppInputs.PAYMENT_HISTORY,
                        errors,
                        touched
                      )}
                    >
                      <Styled.GroupWrapper>
                        <Radio.Group
                          name={AppInputs.PAYMENT_HISTORY}
                          style={{ width: "100%" }}
                        >
                          <Row>
                            <Col span={24}>
                              <Radio
                                name={AppInputs.PAYMENT_HISTORY}
                                value={PaymentHistory.NONE}
                              >
                                {PaymentHistoryMap.NONE}
                              </Radio>
                            </Col>
                            <Col span={24}>
                              <Radio
                                name={AppInputs.PAYMENT_HISTORY}
                                value={PaymentHistory.SMALL}
                              >
                                {PaymentHistoryMap.SMALL}
                              </Radio>
                            </Col>
                            <Col span={24}>
                              <Radio
                                name={AppInputs.PAYMENT_HISTORY}
                                value={PaymentHistory.MIDDLE}
                              >
                                {PaymentHistoryMap.MIDDLE}
                              </Radio>
                            </Col>
                            <Col span={24}>
                              <Radio
                                name={AppInputs.PAYMENT_HISTORY}
                                value={PaymentHistory.LARGE}
                              >
                                {PaymentHistoryMap.LARGE}
                              </Radio>
                            </Col>
                            <Col span={24}>
                              <Radio
                                name={AppInputs.PAYMENT_HISTORY}
                                value={PaymentHistory.EXTRA_LARGE}
                              >
                                {PaymentHistoryMap.EXTRA_LARGE}
                              </Radio>
                            </Col>
                          </Row>
                        </Radio.Group>
                      </Styled.GroupWrapper>
                    </OnboardingFormItem>
                  </Col>
                  <Col span={24}>
                    <OnboardingFormItem
                      label={
                        <Styled.Label>
                          <span>
                            How much do you estimate an average card payment
                            with Dapio to be?
                          </span>
                        </Styled.Label>
                      }
                      error={getFormikError(
                        AppInputs.AVERAGE_TX_VALUE,
                        errors,
                        touched
                      )}
                    >
                      <Styled.GroupWrapper>
                        <Radio.Group
                          name={AppInputs.AVERAGE_TX_VALUE}
                          style={{ width: "100%" }}
                        >
                          <Row>
                            <Col span={24}>
                              <Radio
                                name={AppInputs.AVERAGE_TX_VALUE}
                                value={AverageTxValue.SMALL}
                              >
                                {AverageTxValueMap.SMALL}
                              </Radio>
                            </Col>
                            <Col span={24}>
                              <Radio
                                name={AppInputs.AVERAGE_TX_VALUE}
                                value={AverageTxValue.MIDDLE}
                              >
                                {AverageTxValueMap.MIDDLE}
                              </Radio>
                            </Col>
                            <Col span={24}>
                              <Radio
                                name={AppInputs.AVERAGE_TX_VALUE}
                                value={AverageTxValue.LARGE}
                              >
                                {AverageTxValueMap.LARGE}
                              </Radio>
                            </Col>
                          </Row>
                        </Radio.Group>
                      </Styled.GroupWrapper>
                    </OnboardingFormItem>
                  </Col>
                </Row>
              </OnboardingStyled.Content>

              <OnboardingStyled.Footer>
                <Row gutter={{ xs: 10, sm: 10, md: 25 }}>
                  <Col>
                    <Button
                      type="secondary-transparent"
                      onClick={() => prevStep()}
                    >
                      Back
                    </Button>
                  </Col>
                  <Col flex={1}>
                    <Button
                      type="primary-transparent"
                      onClick={() => handleSubmit()}
                      disabled={!isValid}
                      block
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </OnboardingStyled.Footer>
            </>
          );
        }}
      </Formik>
    </div>
  );
};
