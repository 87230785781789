import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Row, Col } from "antd";
import { Input, Checkbox } from "formik-antd";
import { Button, FormItem } from "components";
import { Styled } from "./styles";
import { useEmployee } from "data-layer/employee";
import { SignupRequestDTO } from "generatedSunset";
import { history } from "data-layer/../router/AppRouter";
import { Typography, PageLogo } from "ui";
import { validateEmail } from "utils/validateEmail";
import { Role } from "data-layer/common/testRoleSlice";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from "react";

interface Values extends SignupRequestDTO {
  password_repeated: string;
}

const addressPattern = /^[-A-Za-z0-9.<(+&*),%_?:'=| ]+$/;

const SignupSchema = Yup.object().shape({
  name: Yup.string()
      .required("Required")
      .max(255, "Max length must be 255 symbols"),
  surname: Yup.string()
      .required("Required")
      .max(255, "Max length must be 255 symbols"),
  partnerName: Yup.string()
      .required("Required")
      .max(255, "Max length must be 255 symbols"),
  email: Yup.string()
      .required("Required")
      .max(255, "Max length must be 255 symbols")
      .test("email", "Invalid email", (value?: string) => {
      return validateEmail(value);
    }),
  partnerAddress: Yup.string()
    .required("Required")
    .max(50, "Max 50 characters")
    .matches(addressPattern, "Incorrect value"),
  password: Yup.string()
      .required("Required")
      .max(255, "Max length must be 255 symbols")
      .min(12, "Password should be 12 chars minimum."),
  password_repeated: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  subscribe: Yup.boolean(),
});

const initialValues: Values = {
  name: "",
  surname: "",
  partnerName: "",
  email: "",
  password: "",
  password_repeated: "",
  subscribe: true,
};

export const SignUpForm: React.FC = () => {
  const { signUp } = useEmployee();

  const recaptchaRef = useRef(null);

  return (
    <Styled.Wrapper>
      <Styled.Background />
      <Styled.Content>
        <Styled.ContentContainer>
          <PageLogo role={Role.Partner} />
          <Typography.H1>Sign up</Typography.H1>

          <Formik
            initialValues={initialValues}
            validationSchema={SignupSchema}
            onSubmit={async (values: Values) => {
              const recaptchaResponse = await recaptchaRef?.current?.executeAsync();

              const { password_repeated, ...restData } = values;

              signUp({
                ...restData,
                recaptchaResponse
              });

              recaptchaRef?.current?.reset();
            }}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({ handleSubmit, errors }) => (
              <Form
                onSubmit={handleSubmit}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
              >
                <Row gutter={[{ xs: 16, sm: 16, xl: 25 }, { xl: 5 }]}>
                  <Col lg={12} span={24}>
                    <FormItem label="First Name" error={errors.name}>
                      <Input name="name" />
                    </FormItem>
                  </Col>
                  <Col lg={12} span={24}>
                    <FormItem label="Last Name" error={errors.surname}>
                      <Input name="surname" />
                    </FormItem>
                  </Col>
                  <Col lg={12} span={24}>
                    <FormItem label="Legal entity name" error={errors.partnerName}>
                      <Input name="partnerName" />
                    </FormItem>
                  </Col>
                  <Col lg={12} span={24}>
                    <FormItem label="Email address" error={errors.email}>
                      <Input name="email" placeholder="example@domain.com" />
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <FormItem label="Address" error={errors.partnerAddress}>
                      <Input
                        name="partnerAddress"
                        placeholder="E.g. ‘CR0 3RL’ or ’36 Factory Lane’"
                      />
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <Row gutter={{ xs: 16, sm: 16, xl: 25 }}>
                      <Col lg={12} span={24}>
                        <FormItem
                          label="Create password"
                          hint="At least 12 characters"
                          error={errors.password}
                        >
                          <Input.Password name="password" type="password" />
                        </FormItem>
                      </Col>
                      <Col lg={12} span={24}>
                        <FormItem
                          label="Repeat password"
                          error={errors.password_repeated}
                        >
                          <Input.Password
                            name="password_repeated"
                            type="password"
                          />
                        </FormItem>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Styled.Divider />

                <Row>
                  <Col xs={24} xl={18} span={18}>
                    <Checkbox name="subscribe">
                      I want to subscribe to monthly updates and receive
                      personalised offers.
                    </Checkbox>
                  </Col>
                </Row>

                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
                />

                <Styled.SubmitButton>
                  <Button
                    block
                    onClick={() => handleSubmit()}
                    type="primary"
                  >
                    Sign Up
                  </Button>
                </Styled.SubmitButton>

                <Styled.Footer>
                  <p>
                    If you have Dapio partner, please go to{" "}
                    <span onClick={() => history.push("/signin")}>
                      sign in page
                    </span>
                  </p>

                  <p>
                    If you're having trouble signing up, contact our{" "}
                    <a href="mailto:help@dapio.com">support team</a>.
                  </p>
                </Styled.Footer>
              </Form>
            )}
          </Formik>
        </Styled.ContentContainer>
      </Styled.Content>
    </Styled.Wrapper>
  );
};
