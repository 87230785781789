import styled from "styled-components";
import { PaperProps, Size } from "./Paper";
import { PaperInfoBlockProps } from "./PaperInfoBlock";
import { Fonts } from "../../styles/fonts";

const paddingsBySize: {
  [keyof in Size]: number;
} = {
  small: 10,
  medium: 20,
  large: 50,
};

const getWrapperPadding = (size: Size) => {
  if (size === "small") {
    return "14px 20px ";
  } else return `${paddingsBySize[size]}px`;
};

const Wrapper = styled.div<PaperProps>`
  display: flex;
  flex-direction: column;

  padding: ${(props) => (props.size ? getWrapperPadding(props.size) : "25px 20px")};

  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;

  @media (max-width: 1199px) {
    padding: ${(props) => (props.size ? getWrapperPadding(props.size) : "20px 16px")};
    border-radius: 5px;
  }

  .ant-tabs {
    flex-grow: 1;
  }
`;

const PaperInfoBlockWrapper = styled(Wrapper)<PaperInfoBlockProps>`
  height: ${(props) => (props.height ? props.height : "auto")};
  width: ${(props) => (props.width ? props.width : "auto")};
`;

const Title = styled.p`
  font-family: ${Fonts.Gotham};
  font-size: 35px;
  line-height: 45px;
  color: #08243b;
`;

export const Styled = {
  Wrapper,
  PaperInfoBlockWrapper,
  Title,
};
