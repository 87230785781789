import { AppState } from "../store";

export const isLoaderVisibleSelector = (state: AppState) =>
  state.common.loader.visible;

export const isSynchronizedSelector = (state: AppState) =>
  state.common.sync.synchronized;

export const isCommingSoonModalVisibleSelector = (state: AppState) =>
  state.common.commingSoonModal.visible;

export const isModalStartVisibleSelector = (state: AppState) =>
  state.common.notifications.isModalStartVisible;

export const testRoleSelector = (state: AppState) =>
  state.common.testRole.testRole;
