import { Button, Modal } from "components";
import { CopyLink } from "components/CopyLink";
import { Styled } from "./styles";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { PaymentLinkDTO } from "generated";
import { ShareEmailModal } from "../../../ShareEmailModal";
import { ShareSmsModal } from "../../../ShareSmsModal";

type LinkCreatedModal = {
  visible: boolean;
  onClose: VoidFunction;
  data: PaymentLinkDTO | null;
};

export const LinkCreatedModal: React.FC<LinkCreatedModal> = (props) => {
  const { visible, onClose, data } = props;

  return (
    <>
      <Modal visible={visible} onCancel={onClose} footer={null}>
        <Styled.Wrapper>
          <Styled.Icon>
            <Styled.Svg>
              <CheckCircleOutlined />
            </Styled.Svg>
          </Styled.Icon>

          <Styled.Title>Link created!</Styled.Title>

          <Styled.Text>Please share this link with the customer</Styled.Text>

          <Styled.Copy>
            <CopyLink
              link={data?.target! || ""}
              placeholder={data?.target! || ""}
            />
          </Styled.Copy>

          <Row gutter={[25, { xs: 10, sm: 20 }]}>
            <Col xs={24} sm={12}>
              <ShareSmsModal
                onClose={onClose}
                onSubmit={() => console.log("submit")}
              />
            </Col>
            <Col xs={24} sm={12}>
              <ShareEmailModal
                onClose={onClose}
                onSubmit={() => console.log("submit")}
              />
            </Col>
            <Col xs={24} sm={24}>
              <Button block type="primary" onClick={onClose}>
                Done
              </Button>
            </Col>
          </Row>
        </Styled.Wrapper>
      </Modal>
    </>
  );
};
