import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { finishApplicationThunk } from "data-layer/application/thunks";

type UseFinishApplication = () => {
  finishApplication: () => void;
};

export const useFinishApplication: UseFinishApplication = () => {
  const dispatch = useDispatch();

  const finishApplication = useCallback(() => {
    dispatch(finishApplicationThunk());
  }, [dispatch]);

  return {
    finishApplication,
  };
};
