/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CRMDTO } from '../models/CRMDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { network } from 'services/network';

export class CrmControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * CRM
     * CRM
     * @param partnerIds partnerIds
     * @param email email
     * @param companyName companyName
     * @param from from
     * @param to to
     * @returns CRMDTO
     * @throws ApiError
     */
    public findUsingGet(
        partnerIds?: number,
        email?: string,
        companyName?: string,
        from?: string,
        to?: string,
    ): CancelablePromise<Array<CRMDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/crm',
                query: {
                    'partnerIds': partnerIds,
                    'email': email,
                    'companyName': companyName,
                    'from': from,
                    'to': to,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

}