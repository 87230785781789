import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";
import bg from "./bg.jpg";

const Wrapper = styled.div`
  display: flex;

  min-height: 100vh;
`;

const Background = styled.div`
  flex-shrink: 0;

  position: relative;

  width: 47%;
  min-height: 100vh;

  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right center;

  overflow: hidden;

  @media (max-width: 1700px) {
    background-position: center;
  }

  @media (max-width: 1199px) {
    width: 440px;
  }

  @media (max-width: 991px) {
    display: none;
  }
`;

const Content = styled.div`
  flex-shrink: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 53%;
  min-height: 100vh;

  padding: 100px 72px;

  @media (max-width: 1199px) {
    flex-grow: 1;
    width: auto;
    padding: 50px 55px;
  }

  @media (max-width: 991px) {
    width: 100%;
  }

  @media (max-width: 575px) {
    padding: 50px 25px;
  }
`;

const ContentContainer = styled.div`
  max-width: 500px;

  @media (max-width: 1199px) {
    max-width: 377px;
  }

  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;

const SubmitButton = styled.div<{isNeedOTPCode?: boolean}>`
  margin-top: ${props => props.isNeedOTPCode ? 21 : 47}px;
  margin-bottom: 32px;

  @media (max-width: 1199px) {
    margin-top: ${props => props.isNeedOTPCode ? 11 : 37}px;
  }
`;

const Footer = styled.div`
  font-family: ${Fonts.NexaRegular};
  font-size: 14px;
  line-height: 20px;

  color: ${Colors.GrayBlue};

  @media (max-width: 1199px) {
    font-size: 12px;
    line-height: 16px;
  }

  @media (max-width: 575px) {
    margin: 0 auto;
    text-align: center;
  }

  p {
    margin-bottom: 20px;

    @media (max-width: 1199px) {
      margin-bottom: 12px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  span {
    text-decoration: underline;
    color: ${Colors.Blue};
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
`;

const ForgotPassword = styled.span`
  cursor: pointer;
`;

const OTPTitle = styled.div`
  margin-top: 27px;
  margin-bottom: 4px;
  padding-top: 27px;

  font-family: ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 135%;

  border-top: 1px solid #78788033;
  color: #000000;

  @media (max-width: 1199px) {
    margin-bottom: 4px;
    padding-top: 22px;
    font-size: 14px;
  }

  @media (max-width: 575px) {
    margin-top: 16px;
  }
`;

const OTPDescription = styled.div`
  margin-bottom: 20px;

  font-family: ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 23px;

  color: ${Colors.GrayBlue};

  @media (max-width: 1199px) {
    font-family: ${Fonts.NexaRegular};
    font-size: 12px;
    line-height: 16px;
  }

  span {
    white-space: nowrap;
  }
`;

const OTPInputWrapper = styled.div`
  max-width: 350px;

  @media (max-width: 1199px) {
    max-width: 322px;
  }
`

const ResendOTP = styled.button`
  position: relative;
  margin-top: 9px;
  padding: 0;

  font-family: ${Fonts.NexaRegular};
  font-size: 14px;
  line-height: 100%;
  text-align: right;

  border: none;

  background: transparent;
  color: ${Colors.Primary};

  cursor: pointer;

  @media (max-width: 1199px) {
    margin-top: 3px;

    font-size: 12px;
    line-height: 16px;
  }

  span {
    position: absolute;
    top: -5px;
    right: calc(100% + 5px);
    font-size: 18px;
  }
`

export const Styled = {
  Wrapper,
  Background,
  Content,
  ContentContainer,
  SubmitButton,
  Footer,
  ForgotPassword,

  OTPTitle,
  OTPDescription,
  OTPInputWrapper,
  ResendOTP,
};
