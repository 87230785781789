import { Styled } from "./styles";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Col, Popover, Row } from "antd";
import "./styles.less";

export type FormItemProps = {
  label: string | React.ReactNode;
  hint?: string | React.ReactNode;
  topAction?: string | React.ReactNode;
  error?: string;
  longError?: boolean;
  hintAlign?: FormItemHintAlign;
  showError?: boolean;
};

export enum FormItemHintAlign {
  Left = "left",
}

export const FormItem: React.FC<FormItemProps> = (props) => {
  const {
    label,
    hint,
    topAction,
    error = "",
    hintAlign,
    longError,
    children,
    showError = true,
  } = props;

  return (
    <Styled.Wrapper error={Boolean(error)} longError={longError}>
      <Row justify="space-between" style={{ width: "100%" }}>
        {/* <Col>{label && <Styled.Label>{label}</Styled.Label>}</Col> */}
        {label && typeof label != "string" ? (
          <Col flex={1}>{label && label}</Col>
        ) : (
          <Col flex={1}>{label && <Styled.Label>{label}</Styled.Label>}</Col>
        )}
        {topAction && (
          <Col>
            <Styled.TopAction>{topAction}</Styled.TopAction>
          </Col>
        )}
      </Row>

      <Styled.InputWrapper>
        {children}
        {error && typeof error === "string" && showError && longError && (
          <Popover
            content={
              <Styled.ErrorPopoverContent style={{ maxWidth: 291 }}>
                {error}
              </Styled.ErrorPopoverContent>
            }
            trigger="click"
            overlayClassName="form-item-error-popover"
          >
            <Styled.ErrorIcon>
              <InfoCircleOutlined />
            </Styled.ErrorIcon>
          </Popover>
        )}
      </Styled.InputWrapper>

      {error && typeof error === "string" && showError && !longError ? (
        <Styled.Hint>
          <Styled.HintText align={hintAlign} danger>
            {error}
          </Styled.HintText>
        </Styled.Hint>
      ) : hint ? (
        <Styled.Hint>
          <Styled.HintText align={hintAlign}>{hint}</Styled.HintText>
        </Styled.Hint>
      ) : null}
    </Styled.Wrapper>
  );
};
