import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { requestResetPasswordThunk } from "data-layer/employee/thunks";

type UseResetPassword = () => {
  resetPassword: (recaptchaResponse: string, email: string, isPartner?: boolean) => void;
};

export const useResetPassword: UseResetPassword = () => {
  const dispatch = useDispatch();

  const resetPassword = useCallback(
    (recaptchaResponse: string, email: string, isPartner?: boolean) => {
      dispatch(requestResetPasswordThunk(recaptchaResponse, email, isPartner));
    },
    [dispatch]
  );

  return {
    resetPassword,
  };
};
