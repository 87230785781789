import { Row, Spin } from "antd";
import { SvgIcon, TabsNav } from "components";

import {
  AreaChart,
  Area,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import { Styled } from "./styles";

import { ReactComponent as CalendarSvg } from "assets/calendar.svg";
import { useTabs } from "common/tabsNav/useTabsNav";
import { IOverview } from "../../OverviewSc";
import { Provider } from "../../hooks/useGetTransactions";
import moment from "moment";
import { useTransactions } from "data-layer/transaction";
import { formatDecimal } from "utils/numberFormatter";

interface IRevenueSc extends IOverview {}

enum CountWeeks {
  One = 1,
  Two = 2,
}

const renderChart = (
  rangeChartData: {
    payByLinkAverage: number;
    tapToPayAverage: number;
    name: string;
  }[],
  provider: Provider
) => {
  return (
    <ResponsiveContainer>
      <AreaChart data={rangeChartData}>
        <defs>
          <linearGradient id="colorTTP" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#FE8E75" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#FE8E75" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorPBL" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8096A7" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#8096A7" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="name" />
        <CartesianGrid strokeDasharray="3 3" horizontal={false} />
        <Tooltip />
        {provider === "all" && (
          <>
            <Area
              type="monotone"
              dataKey="tapToPayAverage"
              stroke="#FE8E75"
              fillOpacity={1}
              fill="url(#colorTTP)"
            />
            <Area
              type="monotone"
              dataKey="payByLinkAverage"
              stroke="#8096A7"
              fillOpacity={1}
              fill="url(#colorPBL)"
            />
          </>
        )}
        {provider === "ttp" && (
          <Area
            type="monotone"
            dataKey="tapToPayAverage"
            stroke="#FE8E75"
            fillOpacity={1}
            fill="url(#colorTTP)"
          />
        )}
        {provider === "pbl" && (
          <Area
            type="monotone"
            dataKey="payByLinkAverage"
            stroke="#8096A7"
            fillOpacity={1}
            fill="url(#colorPBL)"
          />
        )}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export const Revenue: React.FC<IRevenueSc> = ({
  handleChangeRevenueDateRange,
  averageTransactionValue,
  revenueDateRange,
  totalSalesValue,
  rangeChartData,
}) => {
  const { active: countWeeks, handleChangeTab } = useTabs(CountWeeks.One);
  const { isFetchingAmount, isFetchingAverage } = useTransactions();

  const onChangeTab = (key: number) => {
    handleChangeRevenueDateRange(key * 7);
    handleChangeTab(key);
  };

  const isLoading = isFetchingAmount || isFetchingAverage;

  return (
    <Styled.Paper style={{ position: "relative", height: "100%" }}>
      <Styled.RevenueWrapper>
        <Row gutter={[{ xs: 25, sm: 25, xl: 50 }, 0]} wrap={false}>
          <Styled.ReveneuInfoCol>
            <Styled.Title>Revenue</Styled.Title>
            <Styled.StatsRow>
              <Styled.StatBlock>
                <span>
                  {formatDecimal({ value: averageTransactionValue })}
                </span>
                <span>average transaction value</span>
              </Styled.StatBlock>
              <Styled.StatBlock>
                <span>
                  {formatDecimal({ value: totalSalesValue })}
                </span>
                <span>total sales</span>
              </Styled.StatBlock>
            </Styled.StatsRow>

            <TabsNav.Wrapper active={countWeeks} handleChangeTab={onChangeTab}>
              <TabsNav.Button keyIndex={CountWeeks.One} children="7 days" />
              <TabsNav.Button keyIndex={CountWeeks.Two} children="14 days" />
            </TabsNav.Wrapper>
          </Styled.ReveneuInfoCol>

          <Styled.ReveneuChartCol>
            {/* <Tabs
              defaultActiveKey="1"
              onChange={handleChangeProvider}
              tabPosition="top"
              className="button-tabs revenue-tabs"
              style={{ margin: "0 auto" }}
            >
              <TabPane tab="All" key="all" style={{ height: 270 }}> */}
            <Styled.Chart>{renderChart(rangeChartData, "all")}</Styled.Chart>
            {/* </TabPane>
              <TabPane tab="Tap to Pay" key="TapToPay" style={{ height: 270 }}>
                {renderChart(rangeChartData, "ttp")}
              </TabPane>
              <TabPane
                tab="Pay by Link"
                key="PayByLink"
                style={{ height: 270 }}
              >
                {renderChart(rangeChartData, "pbl")}
              </TabPane>
            </Tabs> */}
            <Styled.ReportDate>
              <span>
                {moment().subtract(revenueDateRange, "days").format("D MMMM")} -
                {moment().format("D MMMM")}
              </span>
              <Styled.Icon>
                <SvgIcon svg={CalendarSvg} />
              </Styled.Icon>
            </Styled.ReportDate>
          </Styled.ReveneuChartCol>
        </Row>

        {isLoading && (
          <Styled.Spin>
            <Spin />
          </Styled.Spin>
        )}
      </Styled.RevenueWrapper>
    </Styled.Paper>
  );
};

// import { Bar } from "react-chartjs-2";

// export const options = {
//   responsive: true,
//   plugins: {
//     legend: {
//       display: false,
//     },
//     datalabels: {
//       display: true,
//       color: "#fff",
//       anchor: "end",
//       align: "start",
//       offset: 15,
//     },
//   },
//   maintainAspectRatio: false,
//   scales: {
//     y: {
//       display: false,
//     },
//     x: {
//       grid: {
//         display: false,
//       },
//     },
//     r: {
//       ticks: {
//         backdropPadding: {
//           x: 0,
//           y: 0,
//         },
//       },
//     },
//   },
//   grid: {},
// };

// export const data = {
//   labels,
//   datasets: [
//     {
//       label: "Dataset 1",
//       data: values,
//       backgroundColor: "#2950AFCC",
//     },
//   ],
// };

// export const RevenueSc: React.FC = () => {
//   return (
//     <Paper>
//       <Typography.Title level={2}>Revenue</Typography.Title>
//       <div style={style}>
//         {/* @ts-ignore */}
//         <Bar options={options} data={data} />
//       </div>
//       <Row gutter={10}>
//         <Col>
//           <Button onClick={() => null}>7 days</Button>
//         </Col>
//         <Col>
//           <Button onClick={() => null}>14 days</Button>
//         </Col>
//       </Row>
//     </Paper>
//   );
// };
