import { Styled } from "./styles";
import { DownloadOutlined } from "@ant-design/icons";

type DownloadLinkProps = {
  link: string;
  placeholder: string;
};

export const DownloadLink: React.FC<DownloadLinkProps> = (props) => {
  const { link, placeholder } = props;

  return (
    <Styled.Field>
      <Styled.FieldText>
        <a href={link} target="_blank" download>
          {placeholder}
            <Styled.Icon>
                <DownloadOutlined />
            </Styled.Icon>
        </a>
      </Styled.FieldText>

    </Styled.Field>
  );
};
