import notFound from "assets/not-found.svg";
import { Styled } from "./styles";

export const NotFound = () => {
  return (
    <Styled.Wrapper>
      <Styled.WrapperImg>
        <Styled.Img src={notFound} />
      </Styled.WrapperImg>
      <Styled.P>No results found!</Styled.P>
    </Styled.Wrapper>
  );
};
