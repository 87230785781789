import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Row, Col } from "antd";
import { Input, Checkbox, Select } from "formik-antd";
import { Button, FormItem } from "components";
import { Styled } from "./styles";
import { useEmployee } from "data-layer/employee";
import { SignupRequestDTO } from "generatedSunset";
import { history } from "data-layer/../router/AppRouter";
import { useSearchParams } from "react-router-dom";
import { PageLogo, Typography } from "ui";
import { validateEmail } from "utils/validateEmail";
import { Role } from "data-layer/common/testRoleSlice";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from "react";
import { useDictionary } from "data-layer/dictionary";
import { useEffect } from "react";


interface Values extends SignupRequestDTO {
  password_repeated: string;
  agreement: boolean;
}

const SignupSchema = Yup.object().shape({
  name: Yup.string()
      .required("Required")
      .max(255, "Max length must be 255 symbols"),
  surname: Yup.string()
      .required("Required")
      .max(255, "Max length must be 255 symbols"),
  email: Yup.string()
      .required("Required")
      .max(255, "Max length must be 255 symbols")
      .test("email", "Invalid email", (value?: string) => {
      return validateEmail(value);
    }),
  invitationCode: Yup.string(),
  password: Yup.string()
      .required("Required")
      .max(255, "Max length must be 255 symbols")
      .min(12, "Password should be 12 chars minimum."),
  password_repeated: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  agreement: Yup.bool().oneOf([true], "Field must be checked"),
  subscribe: Yup.boolean(),
  countryId: Yup.number().notRequired(),
});

export const SignUpForm: React.FC = () => {
  const { signUp } = useEmployee();
  const { countries, getDictionary } = useDictionary();

  const recaptchaRef = useRef(null);

  const [searchParams] = useSearchParams();

  const partnerId = searchParams.get("partnerId");
  const firstName = searchParams.get("firstName") || "";
  const lastName = searchParams.get("lastName") || "";
  const merchantName = searchParams.get("merchantName") || "";
  const email = searchParams.get("email") || "";
  const invitationCode = searchParams.get("invitationCode") || "";

  const fromApp = searchParams.get("fromApp");

  useEffect(() => {
    getDictionary();
  }, []);

  const initialValues: Values = {
    name: firstName,
    surname: lastName,
    email: email,
    invitationCode,
    password: "",
    password_repeated: "",
    agreement: false,
    subscribe: true,
  };

  return (
    <Styled.Wrapper>
      <Styled.Background />
      <Styled.Content>
        <Styled.ContentContainer>
          <PageLogo role={Role.Merchant} partnerId={partnerId} />
          <Typography.H1>Sign up</Typography.H1>

          <Formik
            initialValues={initialValues}
            validationSchema={SignupSchema}
            onSubmit={async (values: Values) => {
              const recaptchaResponse = await recaptchaRef?.current?.executeAsync();

              const { password_repeated, agreement, ...restData } = values;

              const dataToSend = {
                ...restData,
              };

              if (partnerId) {
                dataToSend.partnerRefId = Number(partnerId);
              }

              if (merchantName) {
                dataToSend.extraData = {
                  merchantName,
                };
              }

              signUp({
                ...dataToSend,
                recaptchaResponse
              });

              recaptchaRef?.current?.reset();
            }}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({ handleSubmit, errors }) => (
              <Form
                onSubmit={handleSubmit}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
              >
                <Row gutter={[{ xs: 16, sm: 16, xl: 25 }, { xl: 5 }]}>
                  <Col lg={12} span={24}>
                    <FormItem label="First Name" error={errors.name}>
                      <Input name="name" />
                    </FormItem>
                  </Col>
                  <Col lg={12} span={24}>
                    <FormItem label="Last Name" error={errors.surname}>
                      <Input name="surname" />
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <FormItem label="Email address" error={errors.email}>
                      <Input name="email" placeholder="example@domain.com" />
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <FormItem label="Invitation code" error={errors.invitationCode}>
                      <Input name="invitationCode" />
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <FormItem
                      label="What’s your business location?"
                      error={errors.countryId}
                    >
                      <Select
                        showSearch={true}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        name="countryId"
                        placeholder="Please select..."
                      >
                        {countries?.map((item) => {
                          return (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <Row gutter={{ xs: 16, sm: 16, xl: 25 }}>
                      <Col lg={12} span={24}>
                        <FormItem
                          label="Create password"
                          hint="At least 12 characters"
                          error={errors.password}
                        >
                          <Input.Password name="password" type="password" />
                        </FormItem>
                      </Col>
                      <Col lg={12} span={24}>
                        <FormItem
                          label="Repeat password"
                          error={errors.password_repeated}
                        >
                          <Input.Password
                            name="password_repeated"
                            type="password"
                          />
                        </FormItem>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Styled.Divider />

                <Row gutter={[0, { xs: 5, sm: 5, xl: 13 }]}>
                  <Col xs={24} xl={20} span={20}>
                    <Checkbox name="agreement">
                      By clicking here you agree with the
                      <br />{" "}
                      <a
                        href="https://dapio.com/terms_and_conditions"
                        target="_blank"
                      >
                        Terms and Conditions
                      </a>{" "}
                      and{" "}
                      <a href="https://dapio.com/privacy" target="_blank">
                        Privacy Policy
                      </a>
                    </Checkbox>
                    {errors?.agreement && (
                      <Styled.Error>{errors.agreement}</Styled.Error>
                    )}
                  </Col>
                  <Col xs={24} xl={18} span={18}>
                    <Checkbox name="subscribe">
                      I want to subscribe to monthly updates and receive
                      personalised offers.
                    </Checkbox>
                  </Col>
                </Row>

                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
                />

                <Styled.SubmitButton>
                  <Button
                    block
                    onClick={() => handleSubmit()}
                    type="primary-transparent"
                  >
                    Sign Up
                  </Button>
                </Styled.SubmitButton>

                <Styled.Footer>
                  {fromApp != "true" && (
                    <p>
                      If you already have a Dapio account, please{" "}
                      <span onClick={() => history.push("/signin")}>
                        sign in
                      </span>
                      .
                    </p>
                  )}

                  <p>
                    If you're having trouble signing up, contact our{" "}
                    <a href="mailto:help@dapio.com">support team</a>.
                  </p>
                </Styled.Footer>
              </Form>
            )}
          </Formik>
        </Styled.ContentContainer>
      </Styled.Content>
    </Styled.Wrapper>
  );
};
