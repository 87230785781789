/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AttachmentEntity } from './AttachmentEntity';
import type { ProfileEntity } from './ProfileEntity';
import type { UserEntity } from './UserEntity';

export type DocumentRequestEntity = {
    attachments?: Array<AttachmentEntity>;
    created?: string;
    createdBy?: UserEntity;
    id?: number;
    message?: string;
    modified?: string;
    profile?: ProfileEntity;
    reviewedBy?: UserEntity;
    state?: DocumentRequestEntity.state;
    version?: number;
};

export namespace DocumentRequestEntity {

    export enum state {
        APPROVED = 'APPROVED',
        NEW = 'NEW',
        REJECTED = 'REJECTED',
        REVIEWING = 'REVIEWING',
    }


}
