import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { editProfileThunk } from "data-layer/profile/thunks";
import { CreateUpdateProfileDTO } from "generated/models/CreateUpdateProfileDTO";

type UseEditProfile = () => {
  editProfile: (
    id: number,
    data: CreateUpdateProfileDTO & { values: any },
    newApplicationId: number,
    cb?: VoidFunction
  ) => void;
};

export const useEditProfile: UseEditProfile = () => {
  const dispatch = useDispatch();

  const editProfile = useCallback(
    (id, data, newApplicationId, cb) => {
      dispatch(editProfileThunk(id, data, newApplicationId, cb));
    },
    [dispatch]
  );

  return {
    editProfile,
  };
};
