import styled from "styled-components";
import { Fonts } from "styles/fonts";

const Wrapper = styled.div`
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 50px;

  padding-left: 15px;
  padding-right: 50px;

  font-family: ${Fonts.NexaRegular};

  border: 2px solid #D8D9DD;
  border-radius: 10px;

  background-color: #fff;
  color: #2950af;

  @media (max-width: 1199px) {
    height: 40px;
    font-size: 16px;
  }
`;

const Text = styled.div`
  display: inline-flex;
  align-items: center;

  width: 100%;
  height: 100%;
`

const TextInner = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 20px;
  line-height: 20px;
`

const Icon = styled.div`
  position: absolute;

  top: 50%;
  right: 18px;

  display: flex;
  align-items: center;

  font-size: 24px;

  cursor: pointer;

  transform: translateY(-50%);

  svg {
    font-size: 22px;
    color: #80868B;
  }
`;

export const Styled = {
  Wrapper,
  Text,
  TextInner,
  Icon,
};
