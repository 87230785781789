import { Formik, Form } from "formik";
import { Row, Col } from "antd";
import { Input } from "formik-antd";
import { Button, FormItem } from "components";
import { PasswordChangeSchema, initialValues } from "./PasswordChangeSc";
import { Styled } from "./styles";
import { useEmployee } from "data-layer/employee";
import { useSearchParams } from "react-router-dom";
import { Typography } from "ui";

export const PasswordChangeForm: React.FC<{isPartner?: boolean}> = (props) => {
  const { changePassword } = useEmployee();

  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");

  return (
    <>
      <Typography.H1>Reset password</Typography.H1>

      <Formik
        initialValues={initialValues}
        validationSchema={PasswordChangeSchema}
        onSubmit={(values) => {
          changePassword(token || "", values.password, props.isPartner);
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ handleSubmit, errors }) => (
          <Styled.FormContainer>
            <Form
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
            >
              <Row gutter={[0, { xl: 5 }]}>
                <Col span={24}>
                  <FormItem
                    label="New password"
                    hint="At least 12 characters"
                    error={errors.password}
                  >
                    <Input.Password name="password" type="password" />
                  </FormItem>
                </Col>
                <Col span={24}>
                  <FormItem
                    label="Repeat password"
                    error={errors.password_repeated}
                  >
                    <Input.Password name="password_repeated" type="password" />
                  </FormItem>
                </Col>
              </Row>

              <Styled.SubmitButton>
                <Button
                  block
                  onClick={() => handleSubmit()}
                  type="primary-transparent"
                >
                  Save new password
                </Button>
              </Styled.SubmitButton>

              <Styled.Footer>
                If you're having trouble resetting your password, contact our{" "}
                <a href="mailto:help@dapio.com">support team</a>.
              </Styled.Footer>
            </Form>
          </Styled.FormContainer>
        )}
      </Formik>
    </>
  );
};
