import { useCallback } from "react";
import { attachmentApi } from "data-layer/attachments/api";
import { AttachmentDTO } from "generated/models/AttachmentDTO";
import { showError } from "utils/showError";
import { ApiError } from "generated";

type UseHandleFile = (
  setLoading: (value: boolean) => void,
  setFile: (file: AttachmentDTO) => void,
  cb?: (value: number, file: AttachmentDTO) => void
) => {
  handleFile: (e: any) => void;
};

export const useHandleFile: UseHandleFile = (setLoading, setFile, cb) => {
  const handleFile = useCallback(
    async (e) => {
      const file = e.target.files[0];
      const type = file.type;
      const fileSize = file.size / 1024 / 1024; // in MiB

      if (fileSize > 20) {
        showError("Load file error", "File must be less than 2mb");
        return;
      }

      setLoading(true);

      try {
        const result = await attachmentApi.api.uploadFiles({
          files: [file],
        });
        setFile(result[0]);
        if (cb) {
          cb(result[0].id, result[0]);
        }
      } catch (e: unknown) {
        if (e instanceof ApiError) {
          showError("Load file error", e?.body?.error || e.message);
        }
        
      } finally {
        setLoading(false);
      }
    },
    [setLoading]
  );

  return {
    handleFile,
  };
};
