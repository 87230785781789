import { Popover } from "antd";
import { Notification } from "./components";
import { ReactComponent as BellSvg } from "assets/bell-ringing.svg";
import { Styled } from "./styles";
import { SvgIcon } from "components";
import { useNotifications, whiteListToBell } from "data-layer/notifications";
import { useModal } from "./useModal";
import { useGetNotifications } from "./useGetNotifications";
import React, { useEffect, useRef, useState } from "react";
import { useMobileModal } from "./useMobileModal";
import { useProfile } from "data-layer/profile";
import { ProfileDTO } from "generated";
import { NotificationNotFinished } from "./NotificationNotFinished";
import { Colors } from "styles/colors";
import { useHover } from "common/useHover";
// import { DocumentsRequestSc } from "scenarios/seller";

type ContentProps = {
  onClose?: VoidFunction;
};

export type Rect = {
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
  x: number;
  y: number;
};

const Content: React.FC<ContentProps> = (props) => {
  const { onClose } = props;
  const { notifications, markAllAsRead } = useNotifications();
  const { selectedProfile } = useProfile();

  const ref = useRef();
  const [parentRect, setParentRect] = useState<Rect | null>(null);

  useEffect(() => {
    const rect = ref.current?.getBoundingClientRect();
    setParentRect(rect);
  }, [ref.current]);

  return (
    <Styled.Content>
      <Styled.ContentClose onClick={onClose} />
      <Styled.ContentTitle>Notifications</Styled.ContentTitle>
      <Styled.ContentScroll ref={ref}>
        {selectedProfile?.status === ProfileDTO.status.NOT_FINISHED && (
          <NotificationNotFinished />
        )}

        {notifications.map((notification, index) => {
          if (!whiteListToBell.includes(notification.event?.action!)) {
            return null;
          }

          return (
            <Notification
              key={notification.id!}
              notification={notification}
              parentRect={parentRect}
            />
          );
        })}
      </Styled.ContentScroll>
      <Styled.ReadButton onClick={() => markAllAsRead()}>
        Mark all as read
      </Styled.ReadButton>
    </Styled.Content>
  );
};

export function NotificationsSc() {
  const { visible, handleToggle, close } = useModal();
  const { hover, handleToggle: handleHoverToggle } = useHover();

  const {
    visible: visibleMobileNotifications,
    handleToggle: handleMobileNotificationsTogggle,
  } = useMobileModal();

  const { haveUnreadNotification } = useNotifications();
  useGetNotifications();

  return (
    <>
      <Styled.NotificationsDesktop>
        <Popover
          content={<Content onClose={close} />}
          trigger="click"
          visible={visible}
          overlayClassName="notifications-popover"
        >
          <Styled.Control
            onClick={handleToggle}
            onMouseEnter={handleHoverToggle}
            onMouseLeave={handleHoverToggle}
          >
            <SvgIcon
              svg={BellSvg}
              style={{ color: hover ? "#fff" : Colors.Secondary }}
            />
            {haveUnreadNotification && <Styled.StatusActive />}
          </Styled.Control>
        </Popover>
      </Styled.NotificationsDesktop>

      <Styled.MobileNotifications>
        <Styled.Control
          onClick={handleMobileNotificationsTogggle}
          onMouseEnter={handleHoverToggle}
          onMouseLeave={handleHoverToggle}
        >
          <SvgIcon
            svg={BellSvg}
            style={{ fontSize: 22, color: hover ? "#fff" : Colors.Secondary }}
          />
          {haveUnreadNotification && <Styled.StatusActive />}
        </Styled.Control>
        {visibleMobileNotifications && (
          <Content onClose={handleMobileNotificationsTogggle} />
        )}
      </Styled.MobileNotifications>

      {/* <DocumentsRequestSc /> */}
    </>
  );
}
