import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { getApplicationCountThunk } from "../thunks";

type UseGetApplicationCount = () => {
  getApplicationCount: () => void;
};

export const useGetCountApplication: UseGetApplicationCount = () => {
  const dispatch = useDispatch();

  const getApplicationCount = useCallback(() => {
    dispatch(getApplicationCountThunk());
  }, [dispatch]);

  return {
    getApplicationCount,
  };
};
