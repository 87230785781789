import { usePartner } from "data-layer/partner";
import { SettlementDetailsAttemptUpdateDTO } from "generated";

type UseSubmit = () => {
  onSubmit: (body: SettlementDetailsAttemptUpdateDTO, cb: VoidFunction) => void;
};

export const useSubmit: UseSubmit = () => {
  const { attemptSettlements } = usePartner();

  const onSubmit = (body: SettlementDetailsAttemptUpdateDTO, cb: VoidFunction) => {
    attemptSettlements(body, cb);
  };

  return {
    onSubmit,
  };
};
