import styled from "styled-components";
import { Colors } from "styles/colors";

const Header = styled.div`
  z-index: 5;

  position: fixed;

  top: 0;
  left: 0;

  display: flex;
  align-items: center;

  width: 100%;
  height: 80px;

  border-bottom: 2px solid #e5e5e5;

  background-color: #fff;

  @media (max-width: 1450px) {
    height: 60px;
  }
`;

const Control = styled.div`
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 50px;
  height: 50px;

  border: 1px solid ${Colors.LightGray};
  border-radius: 50%;

  cursor: pointer;

  @media (max-width: 1450px) {
    width: 30px;
    height: 30px;
  }

  &:hover {
    border-color: ${Colors.Primary};
  }

  svg {
    font-size: 24px;
    color: ${Colors.Secondary};

    @media (max-width: 1450px) {
      font-size: 15px;
    }
  }
`;

export const Styled = {
  Header,
  Control,
};
