/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SetEmployeeStatusDTO = {
    employeeId: number;
    employeeStatus: SetEmployeeStatusDTO.employeeStatus;
};

export namespace SetEmployeeStatusDTO {

    export enum employeeStatus {
        ACTIVATED = 'ACTIVATED',
        EXPORTED = 'EXPORTED',
        EXTERNAL_SYSTEM_NOT_READY = 'EXTERNAL_SYSTEM_NOT_READY',
        NEW = 'NEW',
        SUSPENDED = 'SUSPENDED',
        ZERO_TRANSACTION = 'ZERO_TRANSACTION',
    }


}
