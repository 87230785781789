import React from "react";
import { Col, Row } from "antd";
import { UserCountItem } from "../UserCount/UserCountItem";
import { ReactComponent as DevicesSvg } from "../../../../../assets/PartnerOverview/devices.svg";
import { ReactComponent as ActiveMerchSvg } from "../../../../../assets/PartnerOverview/activeMerch.svg";
import { ReactComponent as ChargebackSvg } from "../../../../../assets/PartnerOverview/equalizer.svg";
import { ActivityItem } from "./ActivityItem";
import { Styled } from "./styled";
import { usePartner } from "data-layer/partner";

export const BusinessActivity: React.FC = () => {
  const { partnerState } = usePartner();

  const { isTurnoverPercentageLoading } = partnerState;

  const {
    chargebackRate,
    partnersRevenue,
    retentionRate,
    transactionsActiveDevices,
    transactionsActiveMerchants,
    transactionsAvg,
    transactionsSum,
  } = partnerState.turnoverPercentageData || {};

  return (
    <Styled.Wrapper>
      <Styled.MainTitle>Business Activity</Styled.MainTitle>

      <Row gutter={[0, 30]}>
        <Col span={24}>
          <Row gutter={[0, 11]}>
            <Col span={24}>
              <ActivityItem
                title={transactionsSum}
                text={"Total transaction value"}
                isLoading={isTurnoverPercentageLoading}
              />
            </Col>
            <Col span={24}>
              <ActivityItem
                title={transactionsAvg}
                text={"Average transaction value"}
                isLoading={isTurnoverPercentageLoading}
              />
            </Col>
            <Col span={24}>
              <ActivityItem
                text={"Partner’s revenue"}
                title={
                  typeof partnersRevenue === "number" ? partnersRevenue : 0
                }
                isLoading={isTurnoverPercentageLoading}
              />
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Styled.List>
            <Row gutter={[16, 45]}>
              {/* <Col xs={12} sm={8} xl={12} xxl={8}>
              <UserCountItem
                text={"Active merchants"}
                title={transactionsActiveMerchants}
                svg={ActiveMerchSvg}
                isLoading={isTurnoverPercentageLoading}
              />
            </Col> */}
              {/* <Col xs={12} sm={8} xl={12} xxl={8}>
              <UserCountItem
                text={"Active devices"}
                title={transactionsActiveDevices}
                svg={DevicesSvg}
                isLoading={isTurnoverPercentageLoading}
              />
            </Col> */}
              <Col xs={12} sm={12} xl={12}>
                <UserCountItem
                  text={"Chargeback rate"}
                  title={`${chargebackRate || ""}` + "%"}
                  svg={ChargebackSvg}
                  isLoading={isTurnoverPercentageLoading}
                />
              </Col>
              <Col xs={12} sm={12} xl={12}>
                <UserCountItem
                  text={"Retention rate"}
                  title={`${retentionRate || ""}` + "%"}
                  svg={ChargebackSvg}
                  isLoading={isTurnoverPercentageLoading}
                />
              </Col>
            </Row>
          </Styled.List>
        </Col>
      </Row>
    </Styled.Wrapper>
  );
};
