import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { getMerchantPartnerCountThunk } from "../thunks";

type UseGetMerchantPartnerCount = () => {
  getMerchantPartnerCount: () => void;
};

export const useGetMerchantPartnerCount: UseGetMerchantPartnerCount = () => {
  const dispatch = useDispatch();

  const getMerchantPartnerCount = useCallback(() => {
    dispatch(getMerchantPartnerCountThunk());
  }, [dispatch]);

  return {
    getMerchantPartnerCount,
  };
};
