import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export type Filters = {
  limit: number
  offset: number
}

type UsePagination = (filters: Filters | null) => {
  page: number;
  handleNext: VoidFunction;
  handlePrev: VoidFunction;
  handleFirstPage: VoidFunction;
};

export const usePagination: UsePagination = (filters) => {
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!filters) {
      return;
    }

    if (filters.offset % filters.limit !== 0) {
      handleFirstPage();
      return;      
    }

    let newPage = filters.offset / filters.limit + 1;

    setPage(newPage);
  }, [filters, setPage]);

  const handleNext = useCallback(() => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      offset: String(page * filters!.limit),
    });
  }, [searchParams, setSearchParams, page, filters]);

  const handlePrev = useCallback(() => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      offset: String((page - 2) * filters!.limit),
    });
  }, [searchParams, setSearchParams, page, filters]);

  const handleFirstPage = useCallback(() => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      offset: "0",
    });
  }, [searchParams, setSearchParams]);

  return {
    page,
    handleNext,
    handlePrev,
    handleFirstPage,
  };
};
