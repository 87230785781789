import { Col, Row } from "antd";
import { Styled } from "./styles";
import { useToggle } from "common/useToggle";
import { ParterProfileParams } from "data-layer/profile/types/ParterProfileParams";
import { SearchByName } from "./components/SearchByCustomerName";
import { BulkUpload } from "./components/SearchByCustomerName/BulkUpload/BulkUpload";

type FiltersProps = {
  filters: ParterProfileParams;
};

export const Filters: React.FC<FiltersProps> = (props) => {
  const { active, handleToggle } = useToggle();

  return (
    <div>
      <Styled.HeaderMobile onClick={handleToggle} active={active}>
        Filters
      </Styled.HeaderMobile>
      <Styled.Content active={active}>
        <Row gutter={[0, { xs: 32, sm: 32, xl: 42 }]}>
          <Col span={24}>
            <BulkUpload />
          </Col>

          <Col span={24}>
            <Styled.Title>Search</Styled.Title>
            <SearchByName />
          </Col>
        </Row>
      </Styled.Content>
    </div>
  );
};
