import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";
import { InputFileType } from "./InputFile";

type ActiveProp = {
  active: boolean;
};

type TypeProp = {
  type?: InputFileType;
};

const Input = styled.input`
  opacity: 0;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const Label = styled.label`
  position: relative;
`;

const Field = styled.div<ActiveProp & TypeProp>`
  position: relative;

  display: flex;
  align-items: center;

  width: 100%;
  height: 50px;

  padding-left: 20px;
  padding-right: 64px;

  font-family: ${Fonts.NexaRegular};
  font-size: 16px;
  line-height: 20px;

  border: ${(props) => {
    if (props.type === InputFileType.Primary) {
      return "2px solid #FE8E75";
    }

    return props.active ? "none" : "2px solid #FFFFFF";
  }};

  border-radius: 25px;

  background: ${(props) => {
    if (props.type === InputFileType.Primary) {
      return "#FE8E7566";
    }

    return props.active ? "#fff" : "#FFFFFF66";
  }};
  color: #4d4d4d;

  @media (max-width: 1199px) {
    height: 40px;
    padding-left: 15px;
    padding-right: 50px;
    font-size: 14px;
  }

  svg {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    font-size: 24px;

    @media (max-width: 1199px) {
      right: 19px;
      font-size: 20px;
    }
  }
`;

const FieldText = styled.div`
  position: absolute;

  top: 50%;
  left: 0;

  width: calc(100%);
  padding-left: 20px;
  padding-right: 64px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  transform: translateY(-50%) translateY(2px);
`

const Loader = styled.div`
  position: absolute;
  top: 14px;
  right: 70px;

  @media (max-width: 1199px) {
    top: 10px;
    right: 48px;
  }

  svg {
    @media (max-width: 1199px) {
      font-size: 15px;
    }
  }
`;

const Icon = styled.div<TypeProp>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 50px;
  height: 50px;

  font-size: 24px;

  background: ${(props) => {
    if (props.type === InputFileType.Primary) {
      return "#FE8E7566";
    }

    return "#ffffff66";
  }};

  border: ${(props) => {
    if (props.type === InputFileType.Primary) {
      return "2px solid #FE8E75";
    }

    return "2px solid #FFFFFF";
  }};

  border-radius: 25px;

  @media (max-width: 1199px) {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
`;

const RemoveButton = styled.button<TypeProp>`
  position: relative;

  width: 50px;
  height: 50px;

  border: 2px solid #fff;
  border-radius: 50%;

  background: ${(props) => {
    if (props.type === InputFileType.Primary) {
      return "#7878800D";
    }

    return "#ffffff66";
  }};

  border: ${(props) => {
    if (props.type === InputFileType.Primary) {
      return "2px solid #78788033";
    }

    return "2px solid #fff";
  }};

  cursor: pointer;

  @media (max-width: 1199px) {
    width: 40px;
    height: 40px;
  }

  &::after,
  &::before {
    content: "";

    position: absolute;

    top: 50%;
    left: 50%;

    width: 22px;
    height: 2px;

    background-color: ${(props) => {
      if (props.type === InputFileType.Primary) {
        return "#d1d0d5";
      }
  
      return Colors.Primary;
    }};

    @media (max-width: 1199px) {
      width: 19px;
    }
  }

  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

const Text = styled.div`
  max-width: 180px;
  font-family: ${Fonts.NexaRegular};
  font-size: 14px;
  line-height: 20px;
  color: #1d1d1f;

  p {
    margin-bottom: 0;
  }
`;

const Error = styled.p`
  width: 100%;
  font-family: Circe;
  font-size: 14px;
  color: #ea4236;
`;

export const Styled = {
  Input,
  Label,
  Field,
  FieldText,
  Loader,
  Icon,
  RemoveButton,
  Text,
  Error,
};
