import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { turnoverPercentageThunk } from "../thunks";

type UseTurnoverPercentage = () => {
  getTurnoverPercentage: (from: string, to: string) => void;
};

export const useTurnoverPercentage: UseTurnoverPercentage = () => {
  const dispatch = useDispatch();

  const getTurnoverPercentage = useCallback(
    (from: string, to: string) => {
      dispatch(turnoverPercentageThunk(from, to));
    },
    [dispatch]
  );

  return {
    getTurnoverPercentage,
  };
};
