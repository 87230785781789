import { TransactionRestDTO } from "generated";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { TransactionParams } from "data-layer/transaction/types/TransactionParams";
import moment from "moment";
import { keysEqual } from "utils/keysEqual";

type SearchParamsFullfiled = {
  from: string;
  to: string;
  types: string;
  limit: string;
  offset: string;
};

export type UseInitParams = () => {
  filters: TransactionParams | null;
};

export const DEFAULT_LIMIT = 20;

export const useInitParams: UseInitParams = () => {
  const [filters, setFilters] = useState<TransactionParams | null>(null);
  const [searchParams, setSearchParams] = useSearchParams({});

  const hasEmptyRequiredParam = (params: { [key: string]: string | null }) => {
    let hasEmptyParams = false;

    Object.keys(params).forEach((key: string) => {
      if (!params[key]) {
        hasEmptyParams = true;
      }
    });

    return hasEmptyParams;
  };

  const hasDifferent = (filters: TransactionParams, nextFilters: TransactionParams) => {
    if (!keysEqual(filters, nextFilters)) {
      return true;
    }

    let hasDifferent = false;

    Object.keys(nextFilters).map((key) => {
      if (key === "types") {
        if (nextFilters[key].length !== filters[key].length) {
          hasDifferent = true;
        }

        if (nextFilters[key].length === 1) {
          if (nextFilters[key][0] !== filters[key][0]) {
            hasDifferent = true;
          }
        }

        return;
      }

      if (nextFilters[key] !== filters[key]) {
        hasDifferent = true;
      }
    });

    return hasDifferent;
  };

  const formatDataToState = (params: SearchParamsFullfiled): TransactionParams => {
    return {
      ...params,
      types: params.types.split(","),
      limit: Number(params.limit),
      offset: Number(params.offset),
    } as TransactionParams;
  };

  useEffect(() => {
    const from = searchParams.get("from");
    const to = searchParams.get("to");
    const types = searchParams.get("types");
    const limit = searchParams.get("limit");
    const offset = searchParams.get("offset");

    const nextSearchParams: SearchParamsFullfiled = {
      from: from || moment().subtract(7, "d").startOf("day").toISOString(),
      to: to || moment().endOf("day").toISOString(),
      types: types || TransactionRestDTO.type.PAYMENT,
      limit: String((limit && Number(limit)) || DEFAULT_LIMIT),
      offset: String((offset && Number(offset)) || 0),
    };

    if (
      hasEmptyRequiredParam({
        from,
        to,
        types,
        limit,
        offset,
      })
    ) {
      setSearchParams(nextSearchParams);
      return;
    }

    if (!filters) {
      setFilters(formatDataToState(nextSearchParams));
      return;
    }

    if (hasDifferent(filters, formatDataToState(nextSearchParams))) {
      setFilters(formatDataToState(nextSearchParams));
    }
  }, [searchParams]);

  return {
    filters,
    searchParams,
  };
};
