/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CountryEntity } from './CountryEntity';
import type { JsonNode } from './JsonNode';
import type { PartnerEntity } from './PartnerEntity';
import type { ProfileEntity } from './ProfileEntity';
import type { RoleEntity } from './RoleEntity';
import type { TokenEntity } from './TokenEntity';

export type UserEntity = {
    country?: CountryEntity;
    created?: string;
    crmStatus?: UserEntity.crmStatus;
    email?: string;
    emailConfirmationToken?: TokenEntity;
    extraData?: JsonNode;
    fullName?: string;
    id?: number;
    invitedBy?: PartnerEntity;
    lastFrt?: string;
    lastFrtWeb?: string;
    lastOtp?: string;
    modified?: string;
    name?: string;
    needChangePassword?: boolean;
    partner?: PartnerEntity;
    password?: string;
    profiles?: Array<ProfileEntity>;
    resetPasswordToken?: TokenEntity;
    roles?: Array<RoleEntity>;
    status?: UserEntity.status;
    subscribe?: boolean;
    surname?: string;
    verified?: boolean;
    version?: number;
};

export namespace UserEntity {

    export enum crmStatus {
        APPLICATION_APPROVED = 'APPLICATION_APPROVED',
        APPLICATION_REJECTED = 'APPLICATION_REJECTED',
        APPLICATION_SUBMITTED = 'APPLICATION_SUBMITTED',
        FIRST_EMPLOYEE_SETUP_DONE = 'FIRST_EMPLOYEE_SETUP_DONE',
        FIRST_PAYOUT_DONE = 'FIRST_PAYOUT_DONE',
        FIRST_TRANSACTION_DONE = 'FIRST_TRANSACTION_DONE',
        INVITED = 'INVITED',
        NONE = 'NONE',
        SIGNED_UP = 'SIGNED_UP',
    }

    export enum status {
        ACTIVATED = 'ACTIVATED',
        FAILED_LOGIN = 'FAILED_LOGIN',
        INVITED = 'INVITED',
        SUSPENDED = 'SUSPENDED',
    }


}
