import { Col, Row, Spin } from "antd";
import { Button, FormItem, FormItemHintAlign, Modal } from "components";
import { useCreateEmployee } from "data-layer/employees/hooks/useCreateEmployee";
import { Formik, Form } from "formik";
import { Input, Checkbox } from "formik-antd";
import { useState } from "react";
import * as Yup from "yup";
import { Styled } from "./styles";
import { UserAddOutlined } from "@ant-design/icons";
import { validateEmail } from "utils/validateEmail";
import { useEmployeePermissions } from "data-layer/employee-permissions";
import { splitText } from "utils/splitText";

type Values = {
  name: string;
  surname: string;
  email: string;

  address: string;
  city: string;
  postalCode: string;

  password: string;

  permissions: number[];

  confirm: boolean;
};

const initialValues: Values = {
  name: "",
  surname: "",
  email: "",

  address: "",
  city: "",
  postalCode: "",

  password: "",

  permissions: [],

  confirm: false,
};

const addressPattern = /^[-A-Za-z0-9., ]+$/;
const cityPattern = /^[A-Za-z ]+$/;
const zipPattern = /^[-A-Za-z0-9., ]+$/;

const addEmployeeSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  surname: Yup.string().required("Required"),
  email: Yup.string()
    .required("Required")
    .test("email", "Invalid email", (value?: string) => {
      return validateEmail(value);
    }),

  address: Yup.string()
    .required("Required")
    .max(40, "Max 40 characters")
    .matches(addressPattern, "Incorrect value"),
  city: Yup.string()
    .required("Required")
    .max(13, "Max 13 characters")
    .matches(cityPattern, "Incorrect value"),
  postalCode: Yup.string()
    .required("Required")
    .max(11, "Max 11 characters")
    .matches(zipPattern, "Incorrect value"),

  password: Yup.string()
    .required("Required")
    .min(12, "Password should be 12 chars minimum."),

  permissions: Yup.array().of(Yup.string()).min(1),

  confirm: Yup.boolean()
    .required("Field must be checked")
    .oneOf([true], "Field must be checked"),
});

export const AddEmployee: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { createEmployee } = useCreateEmployee();
  const { employeePermissions } = useEmployeePermissions();

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Styled.AddEmployeeButton>
        <Button
          type="primary"
          onClick={() => setIsModalVisible(!isModalVisible)}
          block
        >
          Add employee
        </Button>
      </Styled.AddEmployeeButton>
      <Modal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        className="add-employee-modal"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={addEmployeeSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values: Values) => {
            const { confirm, permissions, ...rest } = values;
            createEmployee({
              ...rest,
              permissions
            }, handleCloseModal);
          }}
        >
          {({ errors, submitForm, handleSubmit }) =>
            employeePermissions.isFetching ? (
              <Row justify="center">
                <Col>
                  <Spin size="large" style={{ margin: "30px auto 0" }} />
                </Col>
              </Row>
            ) : (
              <Form
                onSubmit={handleSubmit}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
              >
                <Row gutter={[0, { xs: 20, sm: 32 }]}>
                  <Col span={24}>
                    <Styled.Icon>
                      <UserAddOutlined />
                    </Styled.Icon>
                    <Styled.Title>Add an employee</Styled.Title>
                  </Col>
                  <Col span={24}>
                    <Row gutter={15}>
                      <Col span={24}>
                        <FormItem label="Name" error={errors.name}>
                          <Input name="name" />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem label="Surname" error={errors.surname}>
                          <Input name="surname" />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem label="Email" error={errors.email}>
                          <Input name="email" />
                        </FormItem>
                      </Col>

                      <Col span={24}>
                        <FormItem
                          label="Address of terminal usage"
                          error={errors.address}
                        >
                          <Input name="address" />
                        </FormItem>
                      </Col>
                      <Col xs={24} sm={12} md={12}>
                        <FormItem label="City" error={errors.city}>
                          <Input name="city" />
                        </FormItem>
                      </Col>
                      <Col xs={24} sm={12} md={12}>
                        <FormItem label="Post code" error={errors.postalCode}>
                          <Input name="postalCode" />
                        </FormItem>
                      </Col>

                      <Col span={24}>
                        <FormItem label="Password" error={errors.password}>
                          <Input.Password name="password" type="password" />
                        </FormItem>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Styled.PermissionsRow>
                      <Col span={24}>
                        <FormItem
                          label="Permissions"
                          error={errors.permissions}
                        >
                          <Styled.CheckboxGroup>
                            <Checkbox.Group
                              name={"permissions"}
                              options={
                                employeePermissions.data.length
                                  ? employeePermissions.data.map((item) => ({
                                      label: splitText(item.name!),
                                      value: item.id!,
                                    }))
                                  : undefined
                              }
                            />
                          </Styled.CheckboxGroup>

                          {/* {employeePermissions.data.map((item) => (
                              <Col>
                                <Checkbox name="permissions">
                                  {item.name}
                                </Checkbox>
                              </Col>
                            ))} */}
                        </FormItem>
                      </Col>
                      <Col span={20} xs={22}>
                        <FormItem
                          label={null}
                          error={errors.confirm}
                          hintAlign={FormItemHintAlign.Left}
                        >
                          <Checkbox name="confirm">
                            I confirm that this person is an employee of my
                            organization
                          </Checkbox>
                        </FormItem>
                      </Col>
                    </Styled.PermissionsRow>
                  </Col>
                </Row>
                <Button block type="primary" onClick={submitForm}>
                  Create employee
                </Button>
              </Form>
            )
          }
        </Formik>
      </Modal>
    </>
  );
};
