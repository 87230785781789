import { Styled } from "./styles";
import { CopyOutlined } from "@ant-design/icons";
import { useCallback } from "react";
import copy from "copy-to-clipboard";

type CopyLinkProps = {
  link: string;
  placeholder: string;
};

export const CopyLink: React.FC<CopyLinkProps> = (props) => {
  const { link, placeholder } = props;

  const handleClick = useCallback(() => {
    copy(link);
  }, [link]);

  return (
    <Styled.Wrapper>
      <Styled.Text>
        <Styled.TextInner>
          <a
            href={link}
            target="_blank"
            style={{ textDecoration: "underline" }}
          >
            {placeholder}
          </a>
        </Styled.TextInner>
      </Styled.Text>

      <Styled.Icon onClick={handleClick}>
        <CopyOutlined />
      </Styled.Icon>
    </Styled.Wrapper>
  );
};
