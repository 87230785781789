import { useEmployeePermissions } from "data-layer/employee-permissions";
import { useEffect } from "react";

type UseGetEmployeePermissions = () => void;

export const useGetEmployeePermissions: UseGetEmployeePermissions = () => {
  const { getEmployeePermissions } = useEmployeePermissions();

  useEffect(() => {
    getEmployeePermissions();
  }, []);
};
