import { Typography } from "ui";
import { Styled } from "./styles";

export const PasswordChangeConfirm: React.FC = () => {
  return (
    <>
      <Typography.H1>Reset password</Typography.H1>

      <Styled.ConfirmDescription>
        Your pin has been changed successfully. You can login to tap to pay
        using new pin.
      </Styled.ConfirmDescription>
    </>
  );
};
