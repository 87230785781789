import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "../store";

type Props = {
  id: string;
};

const idFromProps = (state: AppState, props: Props): string => props.id;

export const dictionariesSelector = (state: AppState) =>
  state.dictionaries.data;

export const businessTypesDictionarySelector = createSelector(
  dictionariesSelector,
  (data) => {
    return data?.businessTypes || [];
  }
);

export const businessCategoriesByBusinessTypeIdSelector = createSelector(
  [dictionariesSelector, idFromProps],
  (data, id) => {
    if (!data?.businessTypes) {
      return null;
    }

    const businessTypes = data.businessTypes.find((type) => type.id === id);

    return businessTypes?.subCategories;
  }
);

export const countriesDictionarySelector = createSelector(
  dictionariesSelector,
  (data) => {
    return data?.countries || [];
  }
);

export const sizesDictionarySelector = createSelector(
  dictionariesSelector,
  (data) => {
    return data?.sizes || [];
  }
);
