import { Col, Row } from "antd";
import { Button, Modal } from "components";
import { Styled } from "./styles";
import { HomeOutlined } from "@ant-design/icons";
import { PaymentLinkDTO } from "generated";
import { currencySymbolsMap } from "constants/currencies";

type PaymentLinkDetailModalProps = {
  visible: boolean;
  hideModal: VoidFunction;
  data: PaymentLinkDTO;
};

const formatDate = (date: PaymentLinkDTO["created"]) => {
  const dateFormated = new Date(date!).toLocaleDateString("en-US", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

  const timeFormated = new Date(date!).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  return `${dateFormated} / ${timeFormated}`;
};

export const PaymentLinkDetailModal: React.FC<PaymentLinkDetailModalProps> = (
  props
) => {
  const { visible, hideModal, data } = props;

  return (
    <>
      <Modal visible={visible} onCancel={hideModal} footer={null}>
        <Styled.Title>Payment link details</Styled.Title>

        <Styled.CompanyDetails>
          <Styled.CompanyDetailsIcon>
            <HomeOutlined />
          </Styled.CompanyDetailsIcon>
          <Styled.CompanyDetailsContent>
            <Styled.CompanyDetailsTitle>Payee</Styled.CompanyDetailsTitle>
            <Styled.CompanyDetailsText>
              <p>??? a.valley@example.com</p>
              <p>??? (458) 555-2863</p>
            </Styled.CompanyDetailsText>
          </Styled.CompanyDetailsContent>
        </Styled.CompanyDetails>

        <Styled.Table>
          <Row wrap={false} justify="space-between">
            <Col>Status:</Col>
            <Col>
              <span style={{ color: "#34C759", textTransform: "capitalize" }}>
                {data.status?.toLowerCase()}
              </span>
            </Col>
          </Row>
          <Row wrap={false} justify="space-between">
            <Col>Payment method:</Col>
            <Col>Pay by Link</Col>
          </Row>
          <Row wrap={false} justify="space-between">
            <Col>Transaction ID:</Col>
            <Col>{data.id}</Col>
          </Row>
          <Row wrap={false} justify="space-between">
            <Col>Payment time:</Col>
            <Col>{formatDate(data.created)}</Col>
          </Row>
          <Row wrap={false} justify="space-between">
            <Col>Creation time:</Col>
            <Col>{formatDate(data.created)}</Col>
          </Row>
        </Styled.Table>

        <Styled.AmountRow>
          <Row wrap={false} justify="space-between">
            <Col>
              <Styled.AmountTitle>Amount:</Styled.AmountTitle>
            </Col>
            <Col>
              <Styled.AmountValue>
                {currencySymbolsMap[data.currency!]} {data.amount?.toFixed(2)}
              </Styled.AmountValue>
            </Col>
          </Row>
        </Styled.AmountRow>

        <Row gutter={[25, { xs: 10, sm: 15, xl: 20 }]}>
          <Col xs={24} sm={12}>
            <Button block>Send via SMS</Button>
          </Col>
          <Col xs={24} sm={12}>
            <Button block>Send via Email</Button>
          </Col>
          <Col span={24}>
            <Button type="primary" block>
              Copy link to clipboard
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
