import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { getPayoutsThunk } from "../thunks";
import { PayoutsParams } from "../types/PayoutsParams";

type UseGetPayouts = () => {
  getPayouts: (params: PayoutsParams) => void;
};

export const useGetPayouts: UseGetPayouts = () => {
  const dispatch = useDispatch();

  const getPayouts = useCallback(
    (params) => {
      dispatch(getPayoutsThunk(params));
    },
    [dispatch]
  );

  return {
    getPayouts,
  };
};
