/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApplicationCountDTO } from '../models/ApplicationCountDTO';
import type { ApplicationDTO } from '../models/ApplicationDTO';
import type { ApplicationInputDTO } from '../models/ApplicationInputDTO';
import type { ApplicationReviewDTO } from '../models/ApplicationReviewDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { network } from 'services/network';

export class ApplicationControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all applications
     * Get all applications
     * @returns ApplicationDTO
     * @throws ApiError
     */
    public getAllUsingGet(): CancelablePromise<Array<ApplicationDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/application',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Get all applications (as admin)
     * Get all applications (as admin)
     * @param states states
     * @param updateDateFrom updateDateFrom
     * @param updateDateTo updateDateTo
     * @param companyName companyName
     * @param limit limit
     * @param offset offset
     * @returns ApplicationDTO
     * @throws ApiError
     */
    public getAllAdminUsingGet(
        states: 'APPROVED' | 'CHANGES_REQUESTED' | 'NEW' | 'REJECTED' | 'REVIEWING',
        updateDateFrom: string,
        updateDateTo: string,
        companyName: string,
        limit: number,
        offset: number,
    ): CancelablePromise<Array<ApplicationDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/application/admin',
                query: {
                    'states': states,
                    'updateDateFrom': updateDateFrom,
                    'updateDateTo': updateDateTo,
                    'companyName': companyName,
                    'limit': limit,
                    'offset': offset,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Get specific application (as admin)
     * Get specific application (as admin)
     * @param id id
     * @returns ApplicationDTO
     * @throws ApiError
     */
    public getByIdAsAdminUsingGet(
        id: number,
    ): CancelablePromise<ApplicationDTO> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/application/admin/{id}',
                path: {
                    'id': id,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Get count applications (partner)
     * Get count applications (partner)
     * @returns ApplicationCountDTO
     * @throws ApiError
     */
    public getCountByPartnerUsingGet(): CancelablePromise<ApplicationCountDTO> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/application/count/partner',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Finish application
     * Actually send application to review
     * @param requestBody
     * @returns ApplicationDTO
     * @returns any Created
     * @throws ApiError
     */
    public finishUsingPost(
        requestBody?: ApplicationInputDTO,
    ): CancelablePromise<ApplicationDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/application/finish',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Init new application
     * Inits new application
     * @returns ApplicationDTO
     * @returns any Created
     * @throws ApiError
     */
    public initUsingPost(): CancelablePromise<ApplicationDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/application/init',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Persist fields
     * Persist fields of application. If request sent without applicationId, then it created new application for current user (lazy application init)
     * @param requestBody
     * @returns ApplicationDTO
     * @returns any Created
     * @throws ApiError
     */
    public persistUsingPost(
        requestBody?: ApplicationInputDTO,
    ): CancelablePromise<ApplicationDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/application/persist',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Persist fields (as admin)
     * Persist fields of application. If request sent without applicationId, then it created new application for current user (lazy application init)
     * @param requestBody
     * @returns ApplicationDTO
     * @returns any Created
     * @throws ApiError
     */
    public persistAsAdminUsingPost(
        requestBody?: ApplicationInputDTO,
    ): CancelablePromise<ApplicationDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/application/persist/admin',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Get applications for specific profiles (as admin)
     * Get applications for specific profiles (as admin)
     * @param id id
     * @returns ApplicationDTO
     * @throws ApiError
     */
    public getByProfileIdUsingGet(
        id: number,
    ): CancelablePromise<Array<ApplicationDTO>> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'GET',
                url: '/application/profile/{id}',
                path: {
                    'id': id,
                },
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

    /**
     * Make decision for application
     * Approve or reject application
     * @param requestBody
     * @returns ApplicationDTO
     * @returns any Created
     * @throws ApiError
     */
    public reviewUsingPost(
        requestBody?: ApplicationReviewDTO,
    ): CancelablePromise<ApplicationDTO | any> {
        return network.request(() => {
            return this.httpRequest.request({

                method: 'POST',
                url: '/application/review',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                },
            })
        });
    }

}