import { Outlet } from "react-router-dom";
import { SignUpForm } from "./SignUpForm";
import { SignUpConfirm } from "./SignUpConfirm";
import React from "react";

const SignUpWrapper: React.FC = () => {
  return <Outlet />;
};

export const SignUpSc = {
  Wrapper: SignUpWrapper,
  Form: SignUpForm,
  Confirm: SignUpConfirm,
}
