import { AppDispatch, AppState } from "data-layer/store/store";
import {
  applicationActions,
  applicationSlice,
} from "data-layer/application/slice";
import { loaderActions, loaderSlice } from "data-layer/common/loaderSlice";
import { applicationApi } from "data-layer/application/api";
import type { ApplicationInputDTO } from "generated/models/ApplicationInputDTO";
import { history } from "data-layer/../router/AppRouter";
import { newApplicationSelector } from "./selectors";
import { showError } from "utils/showError";
import { selectedProfileSelector } from "data-layer/profile/selectors";
import { ProfileDTO } from "generated";
import { profileSlice } from "data-layer/profile/slice";
import { handleNetworkError } from "utils/handleNetworkError";

export const getApplicationsThunk = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(applicationSlice.actions.clearApplication());

    dispatch(loaderSlice.actions.showLoader());

    try {
      const result = await applicationApi.api.getApplications();
      dispatch(applicationSlice.actions.getApplicationsSuccess(result));
    } catch (e) {
      throw e;
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};

export const getApplicationCountThunk = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(loaderSlice.actions.showLoader());

    try {
      dispatch(applicationSlice.actions.getApplicationCountRequest());
      const result = await applicationApi.api.getCountByPartner();
      dispatch(applicationSlice.actions.getApplicationCountSuccess(result));
    } catch (e) {
      dispatch(applicationSlice.actions.getApplicationCountFailure());
      handleNetworkError(e, "Onboarding loading error");
    } finally {
      dispatch(loaderSlice.actions.hideLoader());
    }
  };
};

export const persistApplicationThunk = (
  data: ApplicationInputDTO,
  cb?: Function
) => {
  return async (dispatch: AppDispatch, getState: () => AppState) => {
    const state = getState();
    const selectedProfile = selectedProfileSelector(state);

    dispatch(loaderActions.showLoader());

    try {
      const result = await applicationApi.api.persist(data);

      if (selectedProfile?.status === ProfileDTO.status.NOT_STARTED) {
        dispatch(
          profileSlice.actions.setSelectedProfileStatus(
            ProfileDTO.status.NOT_FINISHED
          )
        );
      }

      dispatch(applicationActions.persistApplicationSuccess(result));

      if (cb) {
        cb();
      }
    } catch (e) {
      handleNetworkError(e, "Data sending error");
    } finally {
      dispatch(loaderActions.hideLoader());
    }
  };
};

export const finishApplicationThunk = () => {
  return async (dispatch: AppDispatch, getState: () => AppState) => {
    const state = getState();

    const newApplication = newApplicationSelector(state);
    dispatch(loaderActions.showLoader());

    try {
      let data = {
        id: newApplication!.id,
        fields: JSON.stringify(newApplication?.data),
      };
      const result = await applicationApi.api.finish(data);

      dispatch(
        profileSlice.actions.setSelectedProfileStatus(
          ProfileDTO.status.UNDER_REVIEW
        )
      );

      dispatch(applicationActions.setApplication(result));

      history.push("/onboarding-finish");
    } catch (e) {
      handleNetworkError(e, "Finish application error");
    } finally {
      dispatch(loaderActions.hideLoader());
    }
  };
};
