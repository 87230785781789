import { getNotificationData, NotificationType } from "data-layer/notifications/getNotificationData";
import { NotificationDTO } from "generated";
import { Rect } from "../../NotificationsSc";
import { formatTime } from "./formatTime";
import { Styled } from "./styles";
import { useCheckInViewport } from "./useCheckInViewport";

type NotificationProps = {
  notification: NotificationDTO;
  parentRect: Rect;
};

export const Notification: React.FC<NotificationProps> = (props) => {
  const { notification, parentRect } = props;

  const { inputRef } = useCheckInViewport(parentRect, notification);

  const data = getNotificationData(notification);

  if (!data) {
    return null;
  }

  const { title, description, created, type, action } = data;

  return (
    <Styled.Wrapper
      ref={inputRef}
      type={type || NotificationType.Default}
      readed={notification.readDate ? true : false}
    >
      <Styled.Icon type={type || NotificationType.Default} />
      <Styled.Content>
        <Styled.Header>
          {title && <Styled.Title>{title}</Styled.Title>}
          {created && (
            <Styled.Time>
              {formatTime(+new Date() - +new Date(created))}
            </Styled.Time>
          )}
        </Styled.Header>

        {description && <Styled.Description>{description}</Styled.Description>}

        {action && (
          action.Element ? (
            <action.Element />
          ) : (
            <Styled.Action onClick={action.cb}>{action.title}</Styled.Action>
          )
        )}
      </Styled.Content>
    </Styled.Wrapper>
  );
};
