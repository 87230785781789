import { ClassNameProp } from "ui/types";
import { Styled } from "./styles";
import { Role } from "data-layer/common/testRoleSlice";

import dapioLogo from "assets/dapio-logo.svg";
import dapioPoweredLogo from "assets/dapio-powered-logo.png";

import partnerLogo from "assets/logo-partner.svg";
import sellerLogo from "assets/logo-seller.svg";

import decimalFactorLogo from "assets/decimal-factor-logo.png";
import arivPayLogo from "assets/arivPay-logo.png";
import { usePartner } from "data-layer/partner";
import { sekumpulApiPath } from "services/network/api";
import { useEffect } from "react";

const DECIMAL_FACTOR_ID = "26950";
const ARIV_PAY_ID = "54469";

type PageLogoProps = ClassNameProp & {
  role?: Role;
  partnerId?: string | number | null;
};

export const PageLogo: React.FC<PageLogoProps> = (props) => {
  const { role, partnerId } = props;
  const { partnerState, findParterById } = usePartner();

  useEffect(() => {
    if (partnerId && !partnerState.invitedPartnerData) {
      findParterById(+partnerId);
    }
  }, [partnerId])

  const _partnerId = localStorage.getItem("partnerRefId");
  let _logoImageFilename = null;
  try {
    let img = localStorage.getItem("logoImageFilename")
    if (img) {
      _logoImageFilename = JSON.parse(img);
    }
  } catch(e) {}

  if (partnerState.invitedPartnerData?.logoImageFilename || _logoImageFilename) {
    return (
      <Styled.BrandedLogo>
        <img
          src={`${sekumpulApiPath}/?fileName=${partnerState.invitedPartnerData?.logoImageFilename || _logoImageFilename}`}
        />
        <Styled.ImagesBorder />
        <img src={dapioPoweredLogo} />
      </Styled.BrandedLogo>
    );
  }

  if (partnerId || _partnerId) {
    if (partnerId == ARIV_PAY_ID || _partnerId == ARIV_PAY_ID) {
      return (
        <Styled.ArivPayLogo>
          <img src={arivPayLogo} />
          <Styled.ImagesBorder />
          <img src={dapioPoweredLogo} />
        </Styled.ArivPayLogo>
      );
    }

    if (partnerId == DECIMAL_FACTOR_ID || _partnerId == DECIMAL_FACTOR_ID) {
      return (
        <Styled.DCLogo>
          <img src={decimalFactorLogo} />
          <Styled.ImagesBorder />
          <img src={dapioPoweredLogo} />
        </Styled.DCLogo>
      );
    }
  }

  if (role === Role.Merchant) {
    return <Styled.SellerLogo className={props.className} src={sellerLogo} />;
  }

  if (role === Role.Partner) {
    return <Styled.PartnerLogo className={props.className} src={partnerLogo} />;
  }

  return <Styled.Logo className={props.className} src={dapioLogo} />;
};
