import * as Yup from "yup";
import { useEmployee } from "data-layer/employee";
import { LoginScView } from "./LoginScView";
import { LoginRequest } from "generatedSunset";
import { validateEmail } from "utils/validateEmail";

export interface Values extends LoginRequest {}

export const SigninSchema = Yup.object().shape({
  email: Yup.string()
    .required("Required")
    .test("email", "Invalid email", (value?: string) => {
      return validateEmail(value);
    }),
  password: Yup.string()
    .required("Required")
    // .min(12, "Password should be 12 chars minimum."),
});

export const SigninSchemaWithOptCode = Yup.object().shape({
  email: Yup.string()
    .required("Required")
    .test("email", "Invalid email", (value?: string) => {
      return validateEmail(value);
    }),
  password: Yup.string()
    .required("Required"),
    // .min(12, "Password should be 12 chars minimum."),
  otpCode: Yup.string()
    .required("Required")
    .min(6, "Must be exactly 6 digits")
    .max(6, "Must be exactly 6 digits"),
});

export const initialValues: Values = {
  email: "",
  password: "",
};

export const LoginSc: React.FC = () => {
  const { signIn } = useEmployee();

  return <LoginScView onSubmit={signIn} />;
};
