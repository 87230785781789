/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MerchantDTO } from './MerchantDTO';

export type PayoutDTO = {
    amount?: number;
    created?: string;
    firstDate?: string;
    id?: number;
    lastDate?: string;
    merchant?: MerchantDTO;
    netAmount?: number;
    status?: PayoutDTO.status;
};

export namespace PayoutDTO {

    export enum status {
        APPROVED = 'APPROVED',
        CANCELLED = 'CANCELLED',
        IN_PROGRESS = 'IN_PROGRESS',
        NEW = 'NEW',
        PAID = 'PAID',
        REJECTED = 'REJECTED',
    }


}
