import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { ApplicationDTO } from "generated";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

type UseHandleChangeType = () => {
  handleChangeType: ((checkedValue: CheckboxValueType[]) => void) | undefined;
};

export const useHandleChangeType: UseHandleChangeType = () => {
  const [searchParams, setSearchParams] = useSearchParams({});

  const handleChangeType = useCallback(
    (value) => {
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        offset: "0",
        states:
          value.join(",") ||
          [
            ApplicationDTO.state.APPROVED,
            ApplicationDTO.state.CHANGES_REQUESTED,
            ApplicationDTO.state.NEW,
            ApplicationDTO.state.REJECTED,
            ApplicationDTO.state.REVIEWING,
          ].join(","),
      });
    },
    [searchParams, setSearchParams]
  );

  return { handleChangeType };
};
