import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NotificationDTO } from "generated";
import { whiteListToBell } from ".";

export type NotificationsInitialState = {
  isFetchingNotifications: boolean;
  notifications: NotificationDTO[] | [];
  pendingToRead: number[] | [];
  isPendingAllReading: boolean;
  haveUnreadNotification: boolean;
};

const initialState: NotificationsInitialState = {
  isFetchingNotifications: false,
  notifications: [],
  pendingToRead: [],
  isPendingAllReading: false,
  haveUnreadNotification: false,
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState: initialState,
  reducers: {
    getNotificationsRequest: (state) => {
      state.isFetchingNotifications = true;
    },
    getNotificationsSuccess: (
      state,
      action: PayloadAction<Array<NotificationDTO>>
    ) => {
      state.notifications = action.payload;
      state.isFetchingNotifications = false;

      let haveUnreadNotification = false;
      action.payload.forEach(item => {
        if (!whiteListToBell.includes(item.event?.action!)) {
          return;
        }

        if (!item.readDate) {
          haveUnreadNotification = true;
        }
      })
      state.haveUnreadNotification = haveUnreadNotification;
    },
    getNotificationsFailure: (state) => {
      state.isFetchingNotifications = false;
    },

    markAsReadRequest: (state, action: PayloadAction<number>) => {
      state.pendingToRead = [...state.pendingToRead, action.payload];
    },
    markAsReadSuccess: (state, action: PayloadAction<NotificationDTO>) => {
      state.pendingToRead = state.pendingToRead.filter(
        (id) => id !== action.payload
      );
      state.notifications = state.notifications.map((notification) => {
        if (notification?.id === action.payload.id) {
          return action.payload;
        }

        return notification;
      });
      
      let haveUnreadNotification = false;
      state.notifications.forEach(item => {
        if (!whiteListToBell.includes(item.event?.action!)) {
          return;
        }

        if (!item.readDate) {
          haveUnreadNotification = true;
        }
      })
      state.haveUnreadNotification = haveUnreadNotification;
    },
    markAsReadFailure: (state, action: PayloadAction<number>) => {
      state.pendingToRead = state.pendingToRead.filter(
        (id) => id !== action.payload
      );
    },

    markAllAsReadRequest: (state) => {
      state.isPendingAllReading = true;
    },
    markAllAsReadSuccess: (state) => {
      state.isPendingAllReading = false;
      state.notifications = state.notifications.map((notification) => {
        if (!notification.readDate) {
          notification.readDate = String(new Date());
        }

        return notification;
      });
      state.haveUnreadNotification = false;
    },
    markAllAsReadFailure: (state) => {
      state.isPendingAllReading = false;
    },
  },
});

export const notificationsActions = notificationsSlice.actions;
