import { useCallback, useState } from "react";

type UseHover = () => {
  hover: boolean;
  handleToggle: () => void;
};

export const useHover: UseHover = () => {
  const [hover, setHover] = useState(false);

  const handleToggle = useCallback(() => {
    setHover(!hover);
  }, [setHover, hover]);

  return {
    hover,
    handleToggle,
  };
};
