import { useEmployee } from "data-layer/employee";
import { Styled } from "./styles";
import { Typography, PageLogo } from "ui";
import { Header } from "../OnboardingSc/components/Header";

export const OnboardingNotAvailableSc: React.FC = () => {
  const { employee } = useEmployee();
  return (
    <Styled.Wrapper>
      <Header />

      <Styled.Background />

      <Styled.Content>
        <Styled.ContentContainer>
          <PageLogo />
          <Typography.H2>Dear {employee.user?.name} {employee.user?.surname},</Typography.H2>

          <Styled.Description>
            Thank you for expressing interest in registering your company with
            Dapio. Due to an overwhelming demand, we are currently experiencing
            a high volume of registrations from your country. As a result, we
            have temporarily reached our capacity to process new registrations.
          </Styled.Description>

          <Styled.Description>
            To ensure a smooth onboarding experience for our users, we have
            added you to our wait-list. Rest assured, we are actively working to
            expand our capacity and will notify you via email as soon as we are
            able to resume accepting registrations for creating a business
            profile.
          </Styled.Description>

          <Styled.Description>
            We appreciate your patience and understanding during this time. If
            you have any urgent inquiries or specific questions, please feel
            free to reach out to our support team.
          </Styled.Description>

          <Styled.Description>
            Thank you for choosing Dapio, and we look forward to assisting you
            in the near future.
          </Styled.Description>
        </Styled.ContentContainer>
      </Styled.Content>
    </Styled.Wrapper>
  );
};
