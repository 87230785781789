import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";
import bg from "./bg.png";

const Wrapper = styled.div`
  display: flex;

  min-height: 100vh;
`;

const Background = styled.div`
  flex-shrink: 0;

  position: relative;

  width: 47%;
  min-height: 100vh;

  background-image: url(${bg});
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${Colors.GrayBlue};

  overflow: hidden;

  @media (max-width: 1199px) {
    background-size: 250px;
    width: 440px;
  }

  @media (max-width: 991px) {
    display: none;
  }
`;

const Content = styled.div`
  flex-shrink: 0;

  display: flex;
  flex-direction: column;

  width: 57%;
  min-height: 100vh;

  padding: 100px 72px;

  @media (max-width: 1199px) {
    flex-grow: 1;
    width: auto;
    padding: 50px 55px;
  }

  @media (max-width: 991px) {
    width: 100%;
  }

  @media (max-width: 575px) {
    padding: 50px 25px;
  }
`;

const ContentContainer = styled.div`
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;

const FormContainer = styled.div`
  max-width: 500px;

  @media (max-width: 1199px) {
    max-width: 356px;
  }

  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;

const SubmitButton = styled.div`
  margin-top: 25px;
  margin-bottom: 32px;

  @media (max-width: 1199px) {
    margin-top: 15px;
  }
`;

const Footer = styled.div`
  max-width: 345px;

  font-family: ${Fonts.NexaRegular};
  font-size: 14px;
  line-height: 20px;

  color: ${Colors.Gray};

  @media (max-width: 1199px) {
    font-size: 12px;
    line-height: 16px;
  }

  @media (max-width: 575px) {
    margin: 0 auto;
    padding-top: 0;
    text-align: center;
  }
`;

const Status = styled.div`
  font-family: ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 135%;

  color: ${Colors.Black};

  @media (max-width: 1199px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const Styled = {
  Wrapper,
  Background,
  Content,
  ContentContainer,
  FormContainer,
  SubmitButton,
  Status,
  Footer,
};
