import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

const Wrapper = styled.div`
  max-width: 566px;

  margin: 0 auto;

  border-radius: 10px;
`;

const Content = styled.div`
  max-width: 240px;
  margin: 0 auto;
`

const Icon = styled.div`
  position: relative;

  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 65px;
  height: 65px;

  margin: 0 auto 21px;

  font-size: 25px;

  border-radius: 15px;

  background: #fff;

  box-shadow: 0px 3px 3px #0000001a;

  &::before {
    z-index: -1;

    content: "";

    position: absolute;

    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;

    background-color: #0000000d;

    border-radius: 10px;
  }
`;

const Svg = styled.div`
  font-size: 35px;

  color: ${Colors.Primary};
`;

const Title = styled.p`
  margin-bottom: 15px;

  font-family: ${Fonts.NexaRegular};
  font-size: 25px;
  line-height: 100%;

  text-align: center;

  color: ${Colors.Primary};
`;

const Text = styled.p`
  margin-bottom: 45px;

  font-size: 16px;
  line-height: 23px;
  font-family: ${Fonts.NexaRegular};
  text-align: center;

  color: #4D4D4D;
`

const Copy = styled.div`
  margin-bottom: 80px;
`

const Button = styled.div`
  padding-top: 55px;
`

export const Styled = {
  Wrapper,
  Content,
  Icon,
  Svg,
  Title,
  Text,
  Copy,
  Button,
};
