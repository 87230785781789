import { Row as AntdRow } from "antd";
import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

const Wrapper = styled.div`
  max-width: 566px;

  margin: 0 auto;

  border-radius: 10px;
`;

const Icon = styled.div`
  position: relative;

  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 65px;
  height: 65px;

  margin: 0 auto 21px;

  font-size: 25px;

  border-radius: 15px;

  background: #fff;

  box-shadow: 0px 3px 3px #0000001a;

  @media (max-width: 1199px) {
    width: 55px;
    height: 55px;
    margin-bottom: 20px;
  }

  &::before {
    z-index: -1;

    content: "";

    position: absolute;

    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;

    background-color: #0000000d;

    border-radius: 10px;
  }
`;

const Svg = styled.div`
  font-size: 35px;

  color: #283549;

  @media (max-width: 1199px) {
    font-size: 30px;
  }
`;

const Title = styled.p`
  margin-bottom: 30px;

  font-family: ${Fonts.NexaRegular};
  font-size: 25px;
  line-height: 100%;

  text-align: center;

  color: ${Colors.Primary};

  @media (max-width: 1199px) {
    margin-bottom: 25px;
    font-size: 20px;
  }
`;

const Header = styled.div`
  margin-bottom: 60px;

  @media (max-width: 1199px) {
    margin-bottom: 50px;
  }

  @media (max-width: 575px) {
    margin-bottom: 35px;
  }
`;

const Row = styled(AntdRow)`
  align-items: baseline;

  padding: 70px 30px 0;

  border-bottom: 2px solid #f5f5f5;

  @media (max-width: 1199px) {
    padding: 60px 20px 0;
  }

  @media (max-width: 575px) {
    padding: 50px 10px 0;
  }
`;

const RowTitle = styled.div`
  font-size: 20px;
  line-height: 100%;

  font-family: ${Fonts.NexaRegular};

  color: #283549;

  @media (max-width: 1199px) {
    font-size: 18px;
  }

  @media (max-width: 575px) {
    font-size: 16px;
  }
`;

const RowValue = styled.div`
  display: flex;
  align-items: baseline;

  font-family: ${Fonts.NexaRegular};
  font-size: 40px;
  line-height: 100%;

  color: #283549;

  @media (max-width: 1199px) {
    font-size: 35px;
  }

  @media (max-width: 575px) {
    font-size: 30px;
  }

  input {
    width: auto;
    min-width: 84px;

    margin-left: 8px;
    padding: 0;

    font-family: ${Fonts.NexaRegular};
    font-size: 40px;
    line-height: 100%;

    border: none !important;
    outline: none !important;
    box-shadow: none !important;

    color: #283549;

    @media (max-width: 1199px) {
      font-size: 35px;
    }

    @media (max-width: 575px) {
      font-size: 30px;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    -moz-appearance: textfield;
  }
`;

const Currency = styled.span`
  margin-right: 8px;
`;

const HiddenAmount = styled.span`
  position: absolute;

  white-space: nowrap;

  opacity: 0;
  visibility: hidden;

  pointer-events: none;
`;

const Button = styled.div`
  padding-top: 55px;

  @media (max-width: 1199px) {
    padding-top: 45px;
  }

  @media (max-width: 575px) {
    padding-top: 35px;
  }
`;

export const Styled = {
  Wrapper,
  Icon,
  Svg,
  Title,

  Header,
  Row,
  RowTitle,
  RowValue,
  Currency,

  HiddenAmount,
  Button,
};
