import { Spin } from "antd";
import { TableSc } from "scenarios/common";
import { Styled } from "./styles";
import { PaymentDetail } from "../PaymentDetail";
import { PaymentsParams } from "data-layer/payments/types/PaymentsParams";
import { usePayments } from "data-layer/payments";
import { usePaymentDetail } from "../usePaymentDetail";
import { PaymentLinkDTO } from "generated";
import { currencySymbolsMap } from "constants/currencies";

function formatAmount(amount: number) {
  return `${amount.toFixed(2)}`;
}

const columns = [
  {
    title: "Customer name",
    dataIndex: "customerName",
    key: "customerName",
    width: "18%",
  },
  {
    title: "Date",
    dataIndex: "created",
    key: "date",
    render: (date: Date) => {
      const dateFormated = new Date(date).toLocaleDateString("en-us", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });

      return <span>{dateFormated}</span>;
    },
    width: "18%",
  },
  {
    title: "Time",
    dataIndex: "created",
    key: "time",
    render: (date: string) => {
      const timeFormated = new Date(date).toLocaleTimeString();

      return <span>{timeFormated}</span>;
    },
    width: "18%",
  },
  {
    title: () => <Styled.AmountTitle>Amount</Styled.AmountTitle>,
    key: "amount",
    render: (record: PaymentLinkDTO) => (
      <Styled.AmountText>
        <Styled.AmountTextCurrency>{currencySymbolsMap[record.currency!]}</Styled.AmountTextCurrency>
        <span>{formatAmount(record.amount!)}</span>
      </Styled.AmountText>
    ),
    width: "18%",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: "18%",
  },
  {
    title: "",
    key: "paymentButtonInfo",
    render: () => <Styled.Arrow />,
    width: "10%",
  },
];

type TableProps = {
  filters: PaymentsParams;
};

export const Table: React.FC<TableProps> = (props) => {
  const { filters } = props;
  const { data, isFetching } = usePayments();

  const {
    data: detailData,
    visible,
    showDetailModal,
    hideModal,
  } = usePaymentDetail();

  if ((!data || !data.length) && isFetching) {
    return <Spin />;
  }

  if (!filters) {
    return <Spin />;
  }

  return (
    <>
      <Styled.TableWrapper>
        <TableSc
          data={data}
          filters={filters}
          columns={columns}
          scroll={{ x: 1400 }}
          onRow={(record: PaymentLinkDTO) => {
            return {
              onClick: () => {
                showDetailModal(record);
                console.log(record.id);
              },
            };
          }}
        />
      </Styled.TableWrapper>

      <PaymentDetail
        data={detailData}
        visible={visible}
        hideModal={hideModal}
      />
    </>
  );
};
