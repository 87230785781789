import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

type ActiveProp = {
  active: boolean;
};

const HeaderMobile = styled.div<ActiveProp>`
  display: none;

  padding: 13px 20px 10px;

  font-size: 14px;

  font-family: ${Fonts.NexaRegular};

  overflow: hidden;

  color: #000;

  cursor: pointer;

  @media (max-width: 767px) {
    display: block;
  }

  @media (max-width: 575px) {
    padding: 13px 15px 10px;
  }

  &::after {
    content: "";

    position: absolute;

    top: ${(props) => (props.active ? "15px" : "22px")};
    right: 20px;

    width: 12px;
    height: 12px;

    border-bottom: 2px solid ${Colors.Primary};
    border-right: 2px solid ${Colors.Primary};

    transform: ${(props) =>
      props.active ? "rotate(45deg)" : "rotate(-135deg)"};
  }
`;

const Content = styled.div<ActiveProp>`
  @media (max-width: 767px) {
    z-index: 10;

    position: absolute;

    top: 45px;
    left: 0;

    display: ${(props) => (props.active ? "block" : "none")};

    width: 100%;
    max-height: calc(100vh - 200px);

    padding-top: 6px;
    padding-bottom: 90px;

    border-top: 1px solid #00000040;
    border-bottom: 1px solid #00000040;

    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
    background-color: #fff;

    overflow-y: auto;
  }
`;

const AddButton = styled.div`
  position: relative;
  z-index: 1;

  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 50px;
  padding-right: 16px;

  font-family: ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 100%;

  border-bottom: 1px solid #00000040;

  cursor: pointer;

  @media (max-width: 1919px) {
    width: calc(100% + 78px);
    margin-left: -78px;
    padding-left: 78px;
  }

  @media (max-width: 1450px) {
    height: 40px;
    font-size: 14px;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-left: 0;
    padding: 0 20px;
  }

  @media (max-width: 575px) {
    padding: 0 15px;
  }

  svg {
    font-size: 20px;

    @media (max-width: 1450px) {
      font-size: 17px;
    }
  }

  & > span:first-child {
    padding-top: 2px;
  }
`;

const Title = styled.p`
  margin-bottom: 0;
  padding-bottom: 3px;

  font-family: ${Fonts.NexaRegular};
  font-size: 17px;

  color: #8096a7;

  border-bottom: 1px solid #00000040;

  @media (max-width: 1919px) {
    width: calc(100% + 78px);
    margin-left: -78px;
    padding-left: 78px;
  }

  @media (max-width: 1450px) {
    padding-bottom: 2px;
    font-size: 14px;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-left: 0;
    padding: 0 20px;
  }

  @media (max-width: 575px) {
    padding: 0 15px;
  }
`;

const FiltersCheckboxGroup = styled.div`
  .ant-checkbox-group {
    width: 100%;
  }

  .ant-checkbox-wrapper {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    margin-right: 0;
    margin-bottom: 0;
    padding: 6px 15px 6px 0;
    border-bottom: 1px solid #00000040;

    @media (max-width: 1919px) {
      width: calc(100% + 78px);
      margin-left: -78px;
      padding-left: 78px;
    }

    @media (max-width: 1450px) {
      padding: 4px 15px 4px 0;
      padding-left: 78px;
    }

    @media (max-width: 767px) {
      width: 100%;
      margin-left: 0;
      padding: 4px 20px;
    }

    @media (max-width: 575px) {
      padding: 4px 15px;
    }
  }

  span:nth-child(2) {
    flex-grow: 1;
    padding-left: 0;
    font-size: 17px !important;

    @media (max-width: 1450px) {
      font-size: 14px !important;
    }
  }
`;

const Period = styled.div`
  padding-bottom: 20px;
`;

const FiltersDatepicker = styled.div`
  border-bottom: 1px solid #00000040;

  @media (max-width: 1919px) {
    width: calc(100% + 78px);
    margin-left: -78px;
    padding-left: 78px;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-left: 0;
    padding: 0 20px;
  }

  @media (max-width: 575px) {
    padding: 0 15px;
  }

  .ant-picker {
    justify-content: flex-start;

    width: 100%;

    padding: 0;

    border: none;
    border-radius: 0;

    background-color: transparent;
  }

  .ant-picker-range-separator {
    width: 7px;
    height: 2px;

    margin: 0 3px;
    padding: 0;

    background-color: #000;

    @media (max-width: 1450px) {
      height: 1px;
    }

    span {
      display: none;
    }
  }

  .ant-picker-input {
    width: 95px;
    padding: 12px 0 7px;

    @media (max-width: 1450px) {
      width: 70px;
    }
  }

  input {
    font-family: ${Fonts.NexaRegular};
    font-size: 17px;

    color: #000;

    @media (max-width: 1450px) {
      font-size: 14px;
    }
  }

  .ant-picker-active-bar {
    display: none;
  }

  .ant-picker-suffix {
    flex-shrink: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 52px;
    height: 50px;
    margin-bottom: 0px;
    margin-left: auto;

    font-size: 30px;
    color: #76899b;

    @media (max-width: 1450px) {
      height: 40px;
    }
  }
`;

export const Styled = {
  HeaderMobile,
  Content,
  AddButton,
  Title,
  FiltersCheckboxGroup,
  Period,
  FiltersDatepicker,
};
