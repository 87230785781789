import { AppState } from "../store";

export const merchantSelector = (state: AppState) => state.merchant;

export const isFetchingMerchantPartnerCountSelector = (state: AppState) =>
  state.merchant.isFetchingPartnerCount;

export const merchantPartnerCountDataSelector = (state: AppState) =>
  state.merchant.dataPartnerCount;

export const isFetchingMeSelector = (state: AppState) =>
  state.merchant.isFetchingMe;

export const meSelector = (state: AppState) => state.merchant.me;

export const merchantsSelector = (state: AppState) => state.merchant.merchants;

export const isFetchingMerchantsSelector = (state: AppState) =>
  state.merchant.isFetchingMerchants;
