// @ts-nocheck

import { CountryData, getMask } from "./_CountryData";
import { rawCountries as _rawCountries } from "./_rawCountries";

const
  _enableAreaCodes: boolean | string[] = false,
  _enableTerritories: boolean | string[] = false,
  _regions: string | string[] = '',
  _onlyCountries: string[] = [],
  _preferredCountries: string[] = [],
  _excludeCountries: string[] = [],
  _preserveOrder: string[] = [],
  _masks = null,
  _priority = null,
  _areaCodes = null,
  _localization = {},
  _prefix = '+',
  _defaultMask = '... ... ... ... ..',
  _alwaysDefaultMask = false;

const
  _disableInitialCountryGuess = false,
  _country: string | number = '';

const startsWith = (string, target, position) => {
  const { length } = string

  position = position == null ? 0 : position

  if (position < 0) {
    position = 0
  }

  else if (position > length) {
    position = length
  }

  target = `${target}`

  return string.slice(position, position + target.length) == target
}

const guessSelectedCountry = (inputNumber, country: string | number, onlyCountries, hiddenAreaCodes) => {
  // if enableAreaCodes == false, try to search in hidden area codes to detect area code correctly
  // then search and insert main country which has this area code
  // https://github.com/bl00mber/react-phone-input-2/issues/201
  if (_enableAreaCodes === false) {
    let mainCode;

    hiddenAreaCodes.some(country => {
      if (startsWith(inputNumber, country.dialCode)) {
        onlyCountries.some(o => {
          if (country.iso2 === o.iso2 && o.mainCode) {
            mainCode = o;
            return true;
          }
        })
        return true;
      }
    })

    if (mainCode) return mainCode;
  }

  const secondBestGuess = onlyCountries.find(o => o.iso2 == country);
  if (inputNumber.trim() === '') return secondBestGuess;

  const bestGuess = onlyCountries.reduce((selectedCountry, country) => {
    if (startsWith(inputNumber, country.dialCode)) {
      if (country.dialCode.length > selectedCountry.dialCode.length) {
        return country;
      }
      if (country.dialCode.length === selectedCountry.dialCode.length && country.priority < selectedCountry.priority) {
        return country;
      }
    }
    return selectedCountry;
  }, {dialCode: '', priority: 10001}, this);

  if (!bestGuess.name) return secondBestGuess;

  return bestGuess;
};

export const validatePhone = (value: string = '') => {
  const { onlyCountries, preferredCountries, hiddenAreaCodes } = new CountryData(
    _enableAreaCodes,
    _enableTerritories,
    _regions,
    _onlyCountries,
    _preferredCountries,
    _excludeCountries,
    _preserveOrder,
    _masks,
    _priority,
    _areaCodes,
    _localization,
    _prefix,
    _defaultMask,
    _alwaysDefaultMask
  );

  const inputNumber = value ? value.replace(/\D/g, '') : '';

  if (inputNumber.length < 2) {
    return false;
  }

  const countryGuess = guessSelectedCountry(inputNumber.substring(0, 6), _country, onlyCountries, hiddenAreaCodes) || 0;

  const country = _rawCountries.find(item => item[3] == countryGuess.countryCode);

  // no validate without mask
  if (!country[4]) {
    return true;
  }

  const format = getMask(_prefix, country[3], country[4], _defaultMask, _alwaysDefaultMask);
  const formatCountNumbers = format.match((/\./g) || [])?.length;

  if (inputNumber.length !== formatCountNumbers) {
    return false;
  }

  return true;
}
