import styled from "styled-components";
import { Colors } from "styles/colors";
import { Table as _Table } from './components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media (max-width: 991px) {
    padding: 0 5px;
  }

  @media (max-width: 575px) {
    padding: 0;
  }
`

const Wrapper = styled.div`
  flex-grow: 1;

  display: flex;

  width: 100%;
  max-width: 1920px;

  margin: 0 auto;
`;

const Sidebar = styled.div`
  position: relative;
  z-index: 1;

  flex-shrink: 0;

  width: 350px;

  padding-top: 42px;

  background-color: ${Colors.Secondary};

  @media (max-width: 991px) {
    position: fixed;
    z-index: 100;

    bottom: 0;
    left: 0;

    width: 100vw;
    max-height: calc(100vh - 200px);

    padding-top: 0;

    border-radius: 15px 15px 0px 0px;
    overflow: auto;
  }

  &::before {
    content: '';

    position: absolute;

    top: 0;
    right: 0;

    width: 2000px;
    height: 100%;

    background-color: ${Colors.Secondary};

    @media (max-width: 991px) {
      content: none;
    }
  }
`;

const Content = styled.div`
  flex-grow: 1;

  padding: 40px 0 20px;

  overflow: hidden;

  @media (max-width: 991px) {
    padding: 30px 0 69px;
  }
`;

const Table = styled.div`
  .ant-table-wrapper {
    // 100vh - headerHeight - content paddingTop - paginationHeight - content paddingBottom
    max-height: calc(100vh - 80px - 40px - 76px - 20px);
    min-height: 50vh;
    overflow: auto;

    @media (max-width: 991px) {
      // 100vh - headerHeight - content paddingTop - paginationHeight - /*content paddingBottom*/ - filtersHeiht
      max-height: calc(100vh - 80px - 30px - 76px - 69px);
    }

    @media (max-width: 575px) {
      // 100vh - headerHeight - content paddingTop - paginationHeight - /*content paddingBottom*/ - filtersHeiht
      max-height: calc(100vh - 60px - 30px - 76px - 69px);
    }
  }

  // .ant-table-tbody .ant-table-row-level-0 .ant-table-cell:first-child,
  // .ant-table-thead .ant-table-cell:first-child {
  //   padding-left: 25px !important;

  //   @media (max-width: 991px) {
  //     padding-left: 15px !important;
  //   }
  // }
`

export const Styled = {
  Container,
  Wrapper,
  Sidebar,
  Content,
  Table,
};
