import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DocumentRequestDTO } from "generated";

export type Documents = [] | Array<DocumentRequestDTO>;

export type DocumentsInitialstate = {
  isProfileDataLoading: boolean;
  dataByProfile: Documents;

  dataById: {
    [key: string]: {
      loading: boolean;
      data: DocumentRequestDTO | null;
    };
  };

  activeModalId: number | null;
};

const initialState: DocumentsInitialstate = {
  isProfileDataLoading: false,
  dataByProfile: [],

  dataById: {},
  activeModalId: null,
};

export const documentSlice = createSlice({
  name: "documents",
  initialState: initialState,
  reducers: {
    getDocumentsByProfileRequest: (state) => {
      state.isProfileDataLoading = true;
    },
    getDocumentsByProfileSuccess: (
      state,
      action: PayloadAction<Array<DocumentRequestDTO>>
    ) => {
      state.isProfileDataLoading = false;
      state.dataByProfile = action.payload;
    },
    getDocumentsByProfileFailure: (state) => {
      state.isProfileDataLoading = false;
    },
    updateDocumentByProfile: (state, action: PayloadAction<DocumentRequestDTO>) => {
      const targetId = action.payload.id;

      state.dataByProfile = state.dataByProfile.map((item) => {
        if (item.id === targetId) {
          return action.payload;
        }

        return item;
      });
    },

    getDocumentByIdRequest: (state, action: PayloadAction<number>) => {
      state.dataById[action.payload] = {
        loading: true,
        data: null,
      };
    },
    getDocumentByIdSuccess: (
      state,
      action: PayloadAction<DocumentRequestDTO>
    ) => {
      state.dataById[action.payload.id] = {
        loading: false,
        data: action.payload,
      };
    },
    getDocumentByIdFailure: (state, action: PayloadAction<number>) => {
      const dataById = {
        ...state.dataById,
      };
      delete dataById[action.payload];
      state.dataById = dataById;
    },
    setActiveModal: (state, action: PayloadAction<number>) => {
      state.activeModalId = action.payload;
    },
    closeActiveModal: (state) => {
      state.activeModalId = null;
    },
  },
});

export const documentsActions = documentSlice.actions;
export const documentsReducer = documentSlice.reducer;
