import { CancelablePromise } from "generated/core/CancelablePromise";
import { api } from "./api";
import { SysI18NDTO } from "generated";

export interface ISys18Api {
  get(
    limit: number,
    offset: number,
    id?: string,
    locale?: string,
    value?: string
  ): CancelablePromise<Array<SysI18NDTO>>;
}

class Sys18Api {
  api: ISys18Api = api;

  constructor() {
    if (process.env.REACT_APP_MOCK_ENABLED === "true") {
      this.api = api;
      return;
    }

    this.api = api;
  }
}

export const sys18Api = new Sys18Api();
