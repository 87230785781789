import React, { ChangeEvent, useState } from "react";
import { Button, FormItem, Modal } from "components";
import { Styled } from "./styles";
import { LinkOutlined } from "@ant-design/icons";
import { Form, Formik } from "formik";
import { Col, Divider, Row } from "antd";
import { Checkbox, Input, InputNumber, Select } from "formik-antd";
import * as Yup from "yup";
import { usePartner } from "data-layer/partner";
import { validateEmail } from "utils/validateEmail";
import { parseStringToNumber } from "utils/isNumeric";

type CurrencyType = "GBP" | "EUR" | "USD";
enum Currency {
  GBP = "GBP",
  EUR = "EUR",
  USD = "USD",
}

type FeeType = "BLENDED_FEE" | "DETAILED_FEE";
enum Fee {
  BLENDED_FEE = "BLENDED_FEE",
  DETAILED_FEE = "DETAILED_FEE",
}

const BLENDED_FIX_FEE = 0;
const DETAILED_FIX_FEE = 0.1;

type FeeDetails = {
  enabled: boolean;
  feeTxPercentage: number;
  feeTxFix: number;
  feeType: FeeType;
};

interface IMerchantDetails {
  firstName: string;
  lastName: string;
  email: string;
  merchantName: string;
  merchantNumber?: string;
  personPosition?: string;
  addressLine1?: string;
  officeAddressLine1?: string;
}

interface IInviteMerchant extends IMerchantDetails {
  pmCurrency: CurrencyType;
  pmInStore: Omit<FeeDetails, "feeType">;
  pmOnline: Omit<FeeDetails, "feeType">;
  pmOnlineGlobal: Omit<FeeDetails, "feeTxPercentage" | "feeTxFix" | "feeType">;
}

interface IInviteMerchantForm extends IMerchantDetails {
  pmCurrency: CurrencyType;
  pmInStore: FeeDetails;
  pmOnline: FeeDetails;
  pmOnlineGlobal: Omit<FeeDetails, "feeTxPercentage" | "feeTxFix" | "feeType">;
}

const initialValues: IInviteMerchantForm = {
  firstName: "",
  lastName: "",
  email: "",
  merchantName: "",
  pmCurrency: Currency.GBP,
  pmInStore: {
    enabled: true,
    feeTxPercentage: 1.5,
    feeTxFix: BLENDED_FIX_FEE,
    feeType: Fee.BLENDED_FEE,
  },
  pmOnline: {
    enabled: false,
    feeTxPercentage: 2,
    feeTxFix: DETAILED_FIX_FEE,
    feeType: Fee.DETAILED_FEE,
  },
  pmOnlineGlobal: {
    enabled: false,
  },
};

const companyNameRegex = (value?: string) =>
  value ? /^[A-Za-z0-9=:?_%,()&+.<>;* ]+$/.test(value) : false;
const merchantNumberRegex = (value?: string | null) =>
  value ? /^[A-Za-z0-9]+$/.test(value) : false;
const personPositionRegex = (value?: string | null) =>
  value ? /^[A-Za-z0-9. ]+$/.test(value) : false;
const nameRegex = (value?: string) =>
  value ? /^[A-Za-z ]+$/.test(value) : false;
const addressRegex = (value?: string | null) => {
  console.log("sss: ", value);
  return value ? /^[A-Za-z0-9.<(+&*),%_?:'=| ]+$/.test(value) : true;
}

const submitMerchantSchema = Yup.object<
  Partial<Record<keyof IInviteMerchantForm, Yup.AnySchema>>
>().shape({
  firstName: Yup.string()
    .required("Required")
    .max(24)
    .test("Name regex check", "Invalid value", nameRegex),
  lastName: Yup.string()
    .required("Required")
    .max(24)
    .test("Name regex check", "Invalid value", nameRegex),
  email: Yup.string()
    .required("Required")
    .test("email", "Invalid email", (value?: string) => {
      return validateEmail(value);
    }),
  merchantName: Yup.string()
    .required("Required")
    .max(120)
    .test("CompanyName regex check", "Invalid value", companyNameRegex),
  merchantNumber: Yup.string()
    .nullable()
    .max(255)
    .test("Merchant regex check", "Invalid value", merchantNumberRegex),
  personPosition: Yup.string()
    .nullable()
    .max(20)
    .test("Position regex check", "Invalid value", personPositionRegex),
  addressLine1: Yup.string()
      .nullable()
      .max(50)
      .test("Address regex check", "Invalid value", addressRegex),
  officeAddressLine1: Yup.string()
    .nullable()
    .max(50)
    .test("Address regex check", "Invalid value", addressRegex),
  pmCurrency: Yup.string().oneOf(Object.values(Currency)).required("Required"),
  pmInStore: Yup.object().shape({
    enabled: Yup.boolean().default(initialValues.pmInStore.enabled),
    feeTxPercentage: Yup.number().default(
      initialValues.pmInStore.feeTxPercentage
    ),
    feeTxFix: Yup.number().default(initialValues.pmInStore.feeTxFix),
  }),
  pmOnline: Yup.object().shape({
    enabled: Yup.boolean().default(initialValues.pmOnline.enabled),
    feeTxPercentage: Yup.number().default(
      initialValues.pmOnline.feeTxPercentage
    ),
    feeTxFix: Yup.number().default(initialValues.pmOnline.feeTxFix),
  }),
  pmOnlineGlobal: Yup.object().shape({
    enabled: Yup.boolean().default(initialValues.pmOnlineGlobal.enabled),
  }),
});

type SubmitMerchantModalScType = {
  visible: boolean;
  onHide: VoidFunction;
};

export const SubmitMerchantModalSc: React.FC<SubmitMerchantModalScType> = (
  props
) => {
  const [inStoreFeeType, setInStoreFeeType] = useState<FeeType>(
    Fee.BLENDED_FEE
  );
  const [onlineFeeType, setOnlineFeeType] = useState<FeeType>(Fee.DETAILED_FEE);

  const [inStoreFixFee, setInStoreFixFee] = useState<number>(
    initialValues.pmInStore.feeTxFix
  );
  const [onlineFixFee, setOnlineFixFee] = useState<number>(
    initialValues.pmOnline.feeTxFix
  );

  const handleChangeInStoreFeeType = (value: FeeType) => {
    if (value === Fee.BLENDED_FEE) {
      setInStoreFixFee(BLENDED_FIX_FEE);
    } else if (value === Fee.DETAILED_FEE) {
      setInStoreFixFee(DETAILED_FIX_FEE);
    }

    setInStoreFeeType(value);
  };

  const handleChangeOnlineFeeType = (value: FeeType) => {
    if (value === Fee.BLENDED_FEE) {
      setOnlineFixFee(BLENDED_FIX_FEE);
    } else if (Fee.DETAILED_FEE) {
      setOnlineFixFee(DETAILED_FIX_FEE);
    }

    setOnlineFeeType(value);
  };

  const [inStoreHidden, setInStoreHidden] = useState<boolean>(true);
  const [onlineHidden, setOnlineHidden] = useState<boolean>(false);
  const [onlineGlobalHidden, setOnlineGlobalHidden] = useState<boolean>(false);

  const [registeredAddress, setRegisteredAddress] = useState<
    string | number | readonly string[] | undefined
  >(undefined);
  const [officeAddress, setOfficeAddress] = useState<
    string | number | readonly string[] | undefined
  >(undefined);
  const [asRegistrationAddress, setAsRegistrationAddress] =
    useState<boolean>(false);

  const handleRegisteredAddressChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setRegisteredAddress(event.target.value);
    if (asRegistrationAddress) {
      setOfficeAddress(event.target.value);
    }
  };

  const handleSameAddress = (checked: boolean) => {
    setAsRegistrationAddress(checked);
    if (checked) {
      setOfficeAddress(registeredAddress);
    } else {
      setOfficeAddress(undefined);
    }
  };

  const { visible, onHide } = props;
  const { invite } = usePartner();

  const onInvite = (data: IInviteMerchantForm) => {
    const inviteMerchant: IInviteMerchant = {
      firstName: data.firstName,
      lastName: data.lastName,
      merchantName: data.merchantName,
      merchantNumber: data.merchantNumber || null,
      personPosition: data.personPosition || null,
      addressLine1: data.addressLine1 || null,
      officeAddressLine1: asRegistrationAddress
        ? data.addressLine1 || null
        : data.officeAddressLine1 || null,
      email: data.email,
      pmCurrency: data.pmCurrency,
      pmInStore: {
        enabled: inStoreHidden,
        feeTxPercentage: data.pmInStore.feeTxPercentage,
        feeTxFix: inStoreFixFee,
      },
      pmOnline: {
        enabled: onlineHidden,
        feeTxPercentage: data.pmOnline.feeTxPercentage,
        feeTxFix: onlineFixFee,
      },
      pmOnlineGlobal: {
        enabled: onlineGlobalHidden,
      },
    };

    invite(inviteMerchant, onHide);
  };

  return (
    <Modal visible={visible} onCancel={onHide} footer={null}>
      <Styled.Wrapper>
        <Styled.Icon>
          <LinkOutlined style={{ fontSize: 35 }} />
        </Styled.Icon>
        <Styled.Title>Invite Merchant</Styled.Title>

        <Formik
          initialValues={initialValues}
          validationSchema={submitMerchantSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={onInvite}
        >
          {({ errors, submitForm, handleSubmit }) => (
            <Form
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
            >
              <Styled.Fields>
                <Row gutter={45}>
                  <Col span={12}>
                    <Styled.FeesTitle>Company Details</Styled.FeesTitle>
                    <Row>
                      <Col span={24}>
                        <FormItem label="First name" error={errors.firstName}>
                          <Input name="firstName" />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem label="Surname" error={errors.lastName}>
                          <Input name="lastName" />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem
                          label="Representative Role"
                          error={errors.personPosition}
                        >
                          <Input name="personPosition" />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem label="Email address" error={errors.email}>
                          <Input name="email" />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem
                          label="Company name"
                          error={errors.merchantName}
                        >
                          <Input name="merchantName" />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem
                          label="Company Registration Number"
                          error={errors.merchantNumber}
                        >
                          <Input name="merchantNumber" />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem
                          label="Registered Address"
                          error={errors.addressLine1}
                        >
                          <Input
                            name="addressLine1"
                            onChange={handleRegisteredAddressChange}
                          />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem label="Business Address" error={errors.officeAddressLine1}>
                          <Styled.Gray>
                            <Checkbox
                              id={"sameAsRegistered"}
                              name={"sameAsRegistered"}
                              onChange={(e) =>
                                handleSameAddress(e.target.checked)
                              }
                              disabled={registeredAddress === undefined}
                            />{" "}
                            same as Registered Address
                          </Styled.Gray>
                          <Input
                            value={officeAddress}
                            name="officeAddressLine1"
                            onChange={(event) => {
                              console.log(event.target.value);
                              setOfficeAddress(event.target.value);
                            }}
                            disabled={
                              asRegistrationAddress ||
                              registeredAddress === undefined
                            }
                          />
                        </FormItem>
                      </Col>
                    </Row>
                  </Col>

                  <Col span={12}>
                    <Styled.FeesTitle>Product & Pricing</Styled.FeesTitle>

                    <Row justify="space-between" align="bottom" gutter={5}>
                      <Divider orientation={"left"}>
                        <Styled.DividerTitle>
                          Processing currency
                        </Styled.DividerTitle>
                      </Divider>

                      <Col span={24}>
                        <FormItem
                          label="Select currency"
                          error={errors.pmCurrency}
                        >
                          <Select name="pmCurrency">
                            <Select.Option
                              key={Currency.GBP}
                              value={Currency.GBP}
                              defaultChecked
                            >
                              UK Pounds (GBP)
                            </Select.Option>
                            <Select.Option
                              key={Currency.EUR}
                              value={Currency.EUR}
                            >
                              Euro (EUR)
                            </Select.Option>
                            <Select.Option
                              key={Currency.USD}
                              value={Currency.USD}
                            >
                              US Dollars (USD)
                            </Select.Option>
                          </Select>
                        </FormItem>
                      </Col>
                    </Row>

                    <Row justify="space-between" align="bottom" gutter={5}>
                      <Divider orientation={"left"}>
                        <Styled.DividerTitle>
                          InStore UK+EU / Visa & MasterCard
                        </Styled.DividerTitle>
                      </Divider>
                      <Col span={2}>
                        <Styled.Switcher>
                          <Checkbox
                            checked={inStoreHidden}
                            onChange={(event) =>
                              setInStoreHidden(event.target.checked)
                            }
                            name={"pmInStore.enabled"}
                          />
                        </Styled.Switcher>
                      </Col>
                      <Col span={10}>
                        <FormItem label="Pricing model">
                          <Select
                            name={"pmInStore.feeType"}
                            onChange={handleChangeInStoreFeeType}
                            placeholder="Please select..."
                            disabled={!inStoreHidden}
                          >
                            <Select.Option
                              key={Fee.BLENDED_FEE}
                              value={Fee.BLENDED_FEE}
                              defaultChecked
                            >
                              Blended rate
                            </Select.Option>
                            <Select.Option
                              key={Fee.DETAILED_FEE}
                              value={Fee.DETAILED_FEE}
                            >
                              IC++
                            </Select.Option>
                          </Select>
                        </FormItem>
                      </Col>
                      {inStoreFeeType === Fee.BLENDED_FEE && (
                        <Col span={11}>
                          <FormItem label="Per cent fee">
                            <InputNumber
                              name="pmInStore.feeTxPercentage"
                              step={0.01}
                              max={5}
                              min={1.2}
                              formatter={(value) =>
                                `${parseStringToNumber(value).toFixed(2)}%`
                              }
                              parser={(value) => value!.replace("%", "")}
                              disabled={!inStoreHidden}
                            />
                          </FormItem>
                        </Col>
                      )}
                      {inStoreFeeType === Fee.DETAILED_FEE && (
                        <Col span={11}>
                          <Row
                            justify="space-between"
                            align="bottom"
                            gutter={5}
                          >
                            <Col span={12}>
                              <FormItem label="Per cent fee">
                                <InputNumber
                                  name="pmInStore.feeTxPercentage"
                                  step={0.01}
                                  max={100}
                                  min={0}
                                  formatter={(value) =>
                                    `${parseStringToNumber(value).toFixed(2)}%`
                                  }
                                  parser={(value) => value!.replace("%", "")}
                                  disabled={!inStoreHidden}
                                />
                              </FormItem>
                            </Col>
                            <Col span={12}>
                              <FormItem label="Auth fee">
                                <Input
                                  name="pmInStore.feeTxFix"
                                  disabled={!inStoreHidden}
                                  value={inStoreFixFee.toFixed(2)}
                                />
                              </FormItem>
                            </Col>
                          </Row>
                        </Col>
                      )}
                    </Row>

                    <Row justify="space-between" align="bottom" gutter={5}>
                      <Divider orientation={"left"}>
                        <Styled.DividerTitle>
                          Online UK+EU Visa MasterCard e-Com Payments
                        </Styled.DividerTitle>
                      </Divider>
                      <Col span={2}>
                        <Styled.Switcher>
                          <Checkbox
                            checked={onlineHidden}
                            onChange={(event) =>
                              setOnlineHidden(event.target.checked)
                            }
                            name={"pmOnline.enabled"}
                          />
                        </Styled.Switcher>
                      </Col>
                      <Col span={10}>
                        <FormItem label="Pricing model">
                          <Select
                            name={"pmOnline.feeType"}
                            onChange={handleChangeOnlineFeeType}
                            placeholder="Please select..."
                            disabled={!onlineHidden}
                          >
                            <Select.Option
                              key={Fee.BLENDED_FEE}
                              value={Fee.BLENDED_FEE}
                            >
                              Blended rate
                            </Select.Option>
                            <Select.Option
                              key={Fee.DETAILED_FEE}
                              value={Fee.DETAILED_FEE}
                              defaultChecked
                            >
                              IC++
                            </Select.Option>
                          </Select>
                        </FormItem>
                      </Col>
                      {onlineFeeType === Fee.BLENDED_FEE && (
                        <Col span={11}>
                          <FormItem label="Per cent fee">
                            <InputNumber
                              name="pmOnline.feeTxPercentage"
                              step={0.01}
                              max={100}
                              min={0}
                              formatter={(value) =>
                                `${parseStringToNumber(value).toFixed(2)}%`
                              }
                              parser={(value) => value!.replace("%", "")}
                              disabled={!onlineHidden}
                            />
                          </FormItem>
                        </Col>
                      )}
                      {onlineFeeType === Fee.DETAILED_FEE && (
                        <Col span={11}>
                          <Row
                            justify="space-between"
                            align="bottom"
                            gutter={5}
                          >
                            <Col span={12}>
                              <FormItem label="Per cent fee">
                                <InputNumber
                                  name="pmOnline.feeTxPercentage"
                                  step={0.01}
                                  max={100}
                                  min={0}
                                  formatter={(value) =>
                                    `${parseStringToNumber(value).toFixed(2)}%`
                                  }
                                  parser={(value) => value!.replace("%", "")}
                                  disabled={!onlineHidden}
                                />
                              </FormItem>
                            </Col>
                            <Col span={12}>
                              <FormItem label="Auth fee">
                                <Input
                                  disabled={!onlineHidden}
                                  name="pmOnline.feeTxFix"
                                  value={onlineFixFee.toFixed(2)}
                                />
                              </FormItem>
                            </Col>
                          </Row>
                        </Col>
                      )}
                    </Row>

                    <Row justify="space-between" align="bottom" gutter={5}>
                      <Divider orientation={"left"}>
                        <Styled.DividerTitle>
                          Online Global e-Com Payments AMEX DCI JCB UPI 80+ APMs
                        </Styled.DividerTitle>
                      </Divider>
                      <Col span={2}>
                        <Styled.Switcher>
                          <Checkbox
                            checked={onlineGlobalHidden}
                            onChange={(event) =>
                              setOnlineGlobalHidden(event.target.checked)
                            }
                            name={"pmOnlineGlobal.enabled"}
                          />
                        </Styled.Switcher>
                      </Col>
                      <Col span={8}>
                        <Styled.InFormTitle>
                          <a
                            href={`${process.env.PUBLIC_URL}/PricingSchedule.xlsx`}
                          >
                            Pricing Schedule
                          </a>
                        </Styled.InFormTitle>
                      </Col>
                      <Col span={11}></Col>
                    </Row>

                    <Row justify="space-between" align="bottom" gutter={5}>
                      <Divider orientation={"left"}>
                        <Styled.DividerTitle>
                          Partner Share Commission
                        </Styled.DividerTitle>
                      </Divider>

                      <Styled.Gray>
                        <ul>
                          <li hidden={!inStoreHidden}>
                            InStore UK+EU / Visa & MasterCard - 0.25%
                          </li>
                          <li hidden={!onlineHidden}>
                            Online UK+EU Visa MasterCard e-Com Payments - 0.50%
                          </li>
                          <li hidden={!onlineGlobalHidden}>
                            Online Global e-Com Payments AMEX DCI JCB UPI 80+
                            APMs - 0.00%
                          </li>
                        </ul>
                      </Styled.Gray>
                    </Row>
                  </Col>
                </Row>
              </Styled.Fields>

              <Button type="primary-transparent" block onClick={submitForm}>
                Send invite
              </Button>
            </Form>
          )}
        </Formik>
      </Styled.Wrapper>
    </Modal>
  );
};
