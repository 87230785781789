import { validatePhone } from "components/PhoneInput/_index";
import { AppInputs } from "generated/modelsWrapper/AppInputs";
import { validateEmail } from "utils/validateEmail";
import * as Yup from "yup";
import {UNITED_KINGDOM_ID} from "../../../data-layer/dictionary";

const addressPattern = /^[-A-Za-z0-9.<(+&*),%_?:'=| ]+$/;
const cityPattern = /^[-A-Za-z0-9_.():&/*<=‘?%,+ ]+$/;
const zipPattern = /^[-A-Za-z0-9_.*():&/<=’?%,+ ]+$/;
const DPAPattern = /[-A-Za-z0-9_.();:&=<+,%?’ ]+$/;

export const generalDescriptionSchema = Yup.object().shape({
  [AppInputs.COMPANY_NAME]: Yup.string().required("Required"),
  [AppInputs.VAT_NUMBER]: Yup.string().required("Required"),
  [AppInputs.COMPANY_STATUS]: Yup.string(),
  [AppInputs.DBA]: Yup.string()
    .required("Required")
    .max(18, "Max 18 characters")
    .matches(DPAPattern, "Incorrect value"),
  [AppInputs.COUNTRY]: Yup.number().required("Required"),
  [AppInputs.COUNTY]: Yup.string().when(AppInputs.COUNTRY, {
      is: UNITED_KINGDOM_ID,
      then: Yup.string()
          .required("Required")
          .max(20, "Max 20 characters")

  }),

  [AppInputs.ADDRESS_LINE_1]: Yup.string()
    .required("Required")
    .max(50, "Max 50 characters")
    .matches(addressPattern, "Incorrect value"),

  [AppInputs.ADDRESS_LINE_2]: Yup.string()
    .max(50, "Max 50 characters")
    .matches(addressPattern, "Incorrect value"),
  [AppInputs.CITY]: Yup.string()
    .required("Required")
    .max(50, "Max 50 characters")
    .matches(cityPattern, "Incorrect value"),
  [AppInputs.POSTAL_CODE]: Yup.string()
    .required("Required")
    .max(20, "Max 20 characters")
    .matches(zipPattern, "Incorrect value"),

  sameAddress: Yup.boolean(),
  [AppInputs.OFFICE_ADDRESS_LINE_1]: Yup.string().when("sameAddress", {
    is: false,
    then: Yup.string()
      .required("Required")
      .max(50, "Max 50 characters")
      .matches(addressPattern, "Incorrect value"),
  }),
  [AppInputs.OFFICE_ADDRESS_LINE_2]: Yup.string().when("sameAddress", {
    is: false,
    then: Yup.string()
      .max(50, "Max 50 characters")
      .matches(addressPattern, "Incorrect value"),
  }),
  [AppInputs.OFFICE_CITY]: Yup.string().when("sameAddress", {
    is: false,
    then: Yup.string()
      .required("Required")
      .max(50, "Max 50 characters")
      .matches(cityPattern, "Incorrect value"),
  }),
  [AppInputs.OFFICE_POSTAL_CODE]: Yup.string().when("sameAddress", {
    is: false,
    then: Yup.string()
      .required("Required")
      .max(20, "Max 20 characters")
      .matches(zipPattern, "Incorrect value"),
  }),
  [AppInputs.OFFICE_COUNTRY]: Yup.number().when("sameAddress", {
    is: false,
    then: Yup.number().required("Required"),
  }),
  [AppInputs.OFFICE_COUNTY]: Yup.string().when("sameAddress", {
      is: false,
      then: Yup.string().required("Required").max(20, "Max 20 characters"),
  }),

  sameContactPerson: Yup.boolean(),
  [AppInputs.PRIMARY_CONTACT_FIRST_NAME]: Yup.string().when(
    "sameContactPerson",
    {
      is: false,
      then: Yup.string().required("Required"),
    }
  ),
  [AppInputs.PRIMARY_CONTACT_LAST_NAME]: Yup.string().when(
    "sameContactPerson",
    {
      is: false,
      then: Yup.string().required("Required"),
    }
  ),
  [AppInputs.PRIMARY_CONTACT_PHONE_NUMBER]: Yup.string().when(
    "sameContactPerson",
    {
      is: false,
      then: Yup.string().required("Required"),
    }
  ),

  [AppInputs.TYPE_OF_BUSINESS]: Yup.string().required("Required"),
  [AppInputs.BUSINESS_EXPLANATION]: Yup.string().when(
    AppInputs.TYPE_OF_BUSINESS,
    {
      is: (value: string) => {
        return value === "OTHER";
      },
      then: Yup.string().required("Required"),
      otherwise: Yup.string().notRequired(),
    }
  ),
  [AppInputs.TOTAL_SALES]: Yup.number()
      .required("Required")
      .min(0),
  [AppInputs.PRODUCT_SERVICE_SOLD]: Yup.string()
      .required("Required")
      .max(250),

  [AppInputs.PHONE_NUMBER]: Yup.string()
    .required("Required")
    .test("check format", "Invalid phone number", (value?: string) => {
      return validatePhone(value);
    }),
  [AppInputs.EMAIL]: Yup.string()
    .required("Required")
    .test("email", "Invalid email", (value?: string) => {
      return validateEmail(value);
    }),
});

export const companyDescriptionSchema = Yup.object()
  .shape({
    [AppInputs.COMPANY_NUMBER]: Yup.string(),
  })
  .concat(generalDescriptionSchema);

export const soleTraderDescriptionSchema = Yup.object()
  .shape({
    [AppInputs.VAT_NUMBER]: Yup.string().required("Required"),
  })
  .concat(generalDescriptionSchema);
