import { useSelector } from "react-redux";
import { IEmployees } from "../";
import { isFetchingSelector, employeesDataSelector } from "../selectors";
import { useGetEmployees } from "./useGetEmployees";
import { useCreateEmployee } from "./useCreateEmployee";
import { useTogglePermission } from "./useTogglePermission";

export const useEmployees = (): IEmployees => {
  const { getEmployees } = useGetEmployees();
  const { createEmployee } = useCreateEmployee();
  const { togglePermission } = useTogglePermission();
  const data = useSelector(employeesDataSelector);
  const isFetching = useSelector(isFetchingSelector);

  return {
    getEmployees,
    createEmployee,
    togglePermission,
    data,
    isFetching,
  };
};
