import { createSlice } from "@reduxjs/toolkit";

export type commingSoonModalInitialstate = {
  visible: boolean;
};

const initialState: commingSoonModalInitialstate = {
  visible: false,
};

export const commingSoonModalSlice = createSlice({
  name: "commingSoon",
  initialState: initialState,
  reducers: {
    showModal: (state) => {
      state.visible = true;
    },
    hideModal: (state) => {
      state.visible = false;
    },
  },
});

export const commingSoonModalActions = commingSoonModalSlice.actions;
export const commingSoonModalReducer = commingSoonModalSlice.reducer;
