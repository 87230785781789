import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MerchantCountDTO, MerchantDTO } from "generated";

export type MerchantInitialState = {
  dataPartnerCount: number;
  isFetchingPartnerCount: boolean;

  isFetchingMe: boolean;
  me: MerchantDTO | null;

  isFetchingMerchants: boolean;
  merchants: MerchantDTO[] | [];
};

const initialState: MerchantInitialState = {
  dataPartnerCount: 0,
  isFetchingPartnerCount: false,
  me: null,
  isFetchingMe: false,

  isFetchingMerchants: false,
  merchants: [],
};

export const merchantSlice = createSlice({
  name: "merchant",
  initialState: initialState,
  reducers: {
    getMerchantsRequest: (state) => {
      state.isFetchingMerchants = true;
    },
    getMerchantsSuccess: (state, action: PayloadAction<Array<MerchantDTO>>) => {
      state.merchants = action.payload;
      state.isFetchingMerchants = false;
    },
    getMerchantsFailure: (state) => {
      state.isFetchingMerchants = false;
    },

    getMerchantPartnerCountRequest: (state) => {
      state.isFetchingPartnerCount = true;
    },
    getMerchantPartnerCountSuccess: (
      state,
      action: PayloadAction<MerchantCountDTO>
    ) => {
      state.dataPartnerCount = action.payload.active || 0;
      state.isFetchingPartnerCount = false;
    },
    getMerchantPartnerCountFailure: (state) => {
      state.isFetchingPartnerCount = false;
    },

    getMeRequst: (state) => {
      state.isFetchingMe = true;
    },
    getMeSuccess: (state, action: PayloadAction<MerchantDTO>) => {
      state.me = action.payload;
      state.isFetchingMe = false;
    },
    getMeFailure: (state) => {
      state.isFetchingMe = false;
    },

    clearMerchant: (state) => {
      state.me = null;
    },

    updateMeSuccess: (state, action: PayloadAction<MerchantDTO>) => {
      state.me = action.payload;
    },
  },
});

export const merchantActions = merchantSlice.actions;
