import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { getNotificationsThunk } from "../thunks";
import { NotificationsParams } from "../types/NotificationsParams";

type UseGetNotifications = () => {
  getNotifications: (params: NotificationsParams) => void;
};

export const useGetNotifications: UseGetNotifications = () => {
  const dispatch = useDispatch();

  const getNotifications = useCallback(
    (params) => {
      dispatch(getNotificationsThunk(params));
    },
    [dispatch]
  );

  return {
    getNotifications,
  };
};
