import { CreateEmployeeDTO, ToggleEmployeePermissionDTO } from "generated";
import { appClient } from "services";
import { IEmployeesApi } from "./index";

export const api: IEmployeesApi = {
  getEmployees: (limit: number, offset: number) => {
    return appClient.employeeController.findEmployeesUsingGet(limit, offset);
  },

  createEmployee: (body: CreateEmployeeDTO) => {
    return appClient.employeeController.createEmployeeUsingPost(body);
  },

  togglePermission: (body: ToggleEmployeePermissionDTO) => {
    return appClient.employeeController.togglePermissionUsingPost(body);
  },
};
