import { CountryDTO } from "generated";

export const sortCountries = (
  countries?: Array<CountryDTO>
): Array<CountryDTO> | [] => {
  if (!countries?.length) {
    return [];
  }

  return countries.slice().sort((a, b) => a.name!.localeCompare(b.name!));
};
