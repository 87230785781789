import styled from "styled-components";
import { Fonts } from "styles/fonts";
import bg from "./bg.jpg";

const Header = styled.div`
  height: 200px;
  background-image: url(${bg});
  background-size: cover;
`;

const Content = styled.div`
  padding: 30px 26px;
  text-align: center;
`;

const Title = styled.p`
  margin-bottom: 20px;

  font-family: ${Fonts.NexaRegular};
  font-size: 25px;
  line-height: 16px;

  color: #fe8e75;
`;

const Description = styled.p`
  margin-bottom: 74px;

  font-family: ${Fonts.NexaRegular};
  font-size: 20px;
  line-height: 25px;

  color: #4d4d4d;
`;

export const Styled = {
  Header,
  Content,
  Title,
  Description,
};
