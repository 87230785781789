import { useApplication } from "data-layer/application";
import { ApplicationValues } from "data-layer/application/types";
import { useEmployee } from "data-layer/employee";
import { ApplicationInputDTO } from "generated";
import { AppInputs } from "generated/modelsWrapper/AppInputs";

type UsePersist = () => {
  persist: (values: ApplicationValues, cb?: Function) => void;
};

export const usePersist: UsePersist = () => {
  const { persistApplication, newApplication } = useApplication();
  const { employee } = useEmployee();

  const persist = (values: ApplicationValues, cb?: Function) => {
    let dataToSend: ApplicationInputDTO = {
      id: newApplication.id,
      fields: {
        // ...newApplication.data,
      },
    } as ApplicationInputDTO;

    dataToSend.fields = {
      // ...dataToSend.fields,
      ...values,
    };

    if (values.sameAddress) {
      dataToSend.fields[AppInputs.OFFICE_COUNTRY] = dataToSend.fields[AppInputs.COUNTRY];
      dataToSend.fields[AppInputs.OFFICE_COUNTY] = dataToSend.fields[AppInputs.COUNTY];
      dataToSend.fields[AppInputs.OFFICE_CITY] = dataToSend.fields[AppInputs.CITY];
      dataToSend.fields[AppInputs.OFFICE_POSTAL_CODE] = dataToSend.fields[AppInputs.POSTAL_CODE];

      dataToSend.fields[AppInputs.OFFICE_ADDRESS_LINE_1] = dataToSend.fields[AppInputs.ADDRESS_LINE_1];
      dataToSend.fields[AppInputs.OFFICE_ADDRESS_LINE_2] = dataToSend.fields[AppInputs.ADDRESS_LINE_2];
    }

    if (values.sameContactPerson) {
      dataToSend.fields[AppInputs.PRIMARY_CONTACT_FIRST_NAME] = employee.user?.name;
      dataToSend.fields[AppInputs.PRIMARY_CONTACT_LAST_NAME] = employee.user?.surname;
      dataToSend.fields[AppInputs.PRIMARY_CONTACT_PHONE_NUMBER] = dataToSend.fields[AppInputs.PHONE_NUMBER];
    }

    persistApplication(dataToSend, cb);
  };

  return {
    persist,
  };
};
