import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { signIn as signInThunk } from "data-layer/employee/thunks";
import { LoginRequest } from "generatedSunset";
import { useLocation } from "react-router-dom";

type UseSignIn = () => {
  signIn: (data: LoginRequest) => void;
};

export const useSignIn: UseSignIn = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  const signIn = useCallback(
    (data: LoginRequest) => {
      dispatch(signInThunk(data, state));
    },
    [dispatch]
  );

  return {
    signIn,
  };
};
