import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

type UseChangeDate = () => {
  handleChangeDatePicker: (date: any) => void;
};

export const useChangeDate: UseChangeDate = () => {
  const [searchParams, setSearchParams] = useSearchParams({});

  const handleChangeDatePicker = useCallback(
    (date) => {
      if (date === null) {
        return;
      }

      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        offset: "0",
        from: date[0].toISOString(),
        to: date[1].toISOString(),
      });
    },
    [searchParams, setSearchParams]
  );

  return {
    handleChangeDatePicker,
  };
};
