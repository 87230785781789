import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";

const Wrapper = styled.div`
  position: relative;

  padding: 20px 16px;

  border: 1px solid ${Colors.LightGray};
  border-radius: 8px;

  @media (max-width: 767px) {
    padding: 12px 10px;
  }
`

const Input = styled.input`
  opacity: 0;

  position: absolute;

  z-index: 1;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  cursor: pointer;
`;

const Field = styled.div`
  position: relative;

  display: flex;
  align-items: center;

  width: 100%;
`;

const FieldText = styled.div`
  font-size: 17px;
  font-family: ${Fonts.NexaRegular};
  line-height: 100%;
  color: #000000;

  position: absolute;

  top: 50%;
  left: 0;

  width: calc(100%);

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  transform: translateY(-50%) translateY(3px);

  @media (max-width: 767px) {
    font-size: 14px;
  }
`

const RemoveButton = styled.button`
  position: relative;

  width: 20px;
  height: 20px;

  vertical-align: middle;

  border: none;
  border-radius: 50%;
  background-color: #bfbfbf;

  cursor: pointer;

  &::after,
  &::before {
    content: "";

    position: absolute;

    top: 50%;
    left: 50%;

    width: 12px;
    height: 2px;

    background-color: #fff;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:hover {
    background-color: #000;
  }
`;

const Loader = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.05);

  svg {
    @media (max-width: 1199px) {
      font-size: 15px;
    }
  }
`;

export const Styled = {
  Wrapper,

  Input,

  Field,
  FieldText,

  RemoveButton,

  Loader,
};
