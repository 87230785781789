import styled from "styled-components";
import bgFinish from "assets/bg-finish.png";

type WrapperProps = {
  pathname: string;
};

const getBgByUrl = (url: string): string | false => {
  switch (url) {
    case "/onboarding-finish": {
      return bgFinish;
    }
    default: {
      return false;
    }
  }
};

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;

  min-height: 100vh;

  padding-top: 80px;

  background-image: ${(props) => {
    let image = getBgByUrl(props.pathname);

    return image
      ? `linear-gradient(180deg, #1D1D1F 0%, rgba(0, 0, 0, 0.1) 100%), url(${image})`
      : "";
  }};
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed;

  @media (max-width: 1450px) {
    padding-top: 60px;
  }
`;

export const Styled = {
  Wrapper,
};
