import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuthByToken } from "./useAuthByToken";

type UseCheckAuthToken = () => {
  isAuthTokenChecked: boolean;
  authToken: string | null;
};

export const useCheckAuthToken: UseCheckAuthToken = () => {
  const [isAuthTokenChecked, setIsAuthTokenChecked] = useState(false);

  const [searchParams] = useSearchParams();
  const authToken = searchParams.get("authToken");

  useAuthByToken(authToken, setIsAuthTokenChecked);

  return {
    isAuthTokenChecked,
    authToken,
  }
};
