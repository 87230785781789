import styled from "styled-components";
import { Colors } from "styles/colors";
import { Fonts } from "styles/fonts";
import { NavLink } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Wrapper = styled.div`
  flex-grow: 1;

  display: flex;

  width: 100%;
  max-width: 1920px;

  margin: 0 auto;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const Filters = styled.div<{ active: boolean }>`
  position: relative;
  width: 273px;
  padding: 35px 0;

  font-family: ${Fonts.NexaRegular};
  font-size: 17px;
  line-height: 100%;

  border-right: 1px solid ${Colors.LightGray};

  @media (max-width: 767px) {
    width: auto;
    padding: 0;
    border-right: none;
  }
`;

const Title = styled.div<{ active: boolean }>`
  position: relative;

  display: flex;
  align-items: center;

  height: 44px;

  color: ${Colors.GrayBlue};

  @media (max-width: 767px) {
    margin: 0 -20px 22px;
    padding: 0 20px;

    font-size: 14px;
    line-height: 20px;
    font-family: ${Fonts.NexaRegular};

    border-bottom: 1px solid ${Colors.LightGray};

    color: #000000;

    cursor: pointer;
  }

  @media (max-width: 575px) {
    margin: 0 -15px 22px;
    padding: 0 15px;
  }

  &::before {
    content: "";

    position: absolute;

    bottom: 0;
    right: 0;
    left: 0;

    height: 1px;

    background-color: ${Colors.LightGray};

    @media (max-width: 1920px) {
      left: -78px;
    }

    @media (max-width: 767px) {
      content: none;
    }
  }

  &::after {
    content: none;

    position: absolute;

    top: ${props => props.active ? 17 : 11}px;
    right: 20px;

    width: 12px;
    height: 12px;

    border-right: 2px solid ${Colors.Primary};
    border-bottom: 2px solid ${Colors.Primary};

    transform: ${props => props.active ? 'rotate(-135deg)' : 'rotate(45deg)'};

    @media (max-width: 767px) {
      content: "";
    }

    @media (max-width: 575px) {
      right: 15px;
    }
  }
`;

const List = styled.div<{ active: boolean }>`
  @media (max-width: 767px) {
    position: absolute;
    z-index: 1;
    top: 44px;
    left: -20px;
    right: -20px;
    display: ${props => props.active ? 'block' : 'none'};
  }

  @media (max-width: 575px) {
    left: -15px;
    right: -15px;
  }
`

const Link = styled(NavLink)`
  position: relative;

  display: flex;
  align-items: center;

  height: 50px;

  text-decoration: none;

  color: #000000;
  cursor: pointer;

  @media (max-width: 767px) {
    width: 100%;
    height: 44px;
    padding: 0 20px;

    font-size: 14px;
    line-height: 20px;
    font-family: ${Fonts.NexaRegular};

    border-bottom: 1px solid ${Colors.LightGray};

    background: #f2f2f2;

    cursor: pointer;
  }

  @media (max-width: 575px) {
    padding: 0 15px;
  }

  &.active {
    border-right: 5px solid ${Colors.Primary};

    @media (max-width: 767px) {
      border: none;
    }
  }

  &:hover {
    color: inherit;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 1px;
    background-color: ${Colors.LightGray};

    @media (max-width: 1920px) {
      left: -78px;
    }

    @media (max-width: 767px) {
      content: none;
    }
  }

  &::after {
    content: none;

    position: absolute;

    top: 11px;
    right: calc(20px + 3px);

    width: 12px;
    height: 12px;

    border-right: 2px solid #8398aa;
    border-bottom: 2px solid #8398aa;

    transform: rotate(-45deg);

    @media (max-width: 767px) {
      content: "";
    }

    @media (max-width: 575px) {
      right: calc(15px + 3px);
    }
  }
`;

const Content = styled.div`
  flex-grow: 1;

  max-width: 571px;

  padding: 25px;

  overflow: hidden;

  @media (max-width: 767px) {
    padding: 0 0 30px;
  }
`;

export const Styled = {
  Container,
  Wrapper,

  Filters,
  Title,
  List,
  Link,

  Content,
};
